import * as LayoutState from '../state/LayoutState';
import { ResponsiveProperties } from './ResponsiveProperties';
import { LayoutVariantOption } from '../components/LayoutContainer';

export enum LogoSloganVariantOption {
    Large,
    Medium,
    Small,
}

export interface LogoSloganVariantInputs {
    layout: LayoutState.State;
}

export class LogoSloganVariant {
    private _s: LogoSloganVariantInputs;
    private _layoutVariant: LayoutVariantOption;
    constructor(
        s: LogoSloganVariantInputs,
        layoutVariant: LayoutVariantOption,
    ) {
        this._s = s;
        this._layoutVariant = layoutVariant;
    }

    get variant(): LogoSloganVariantOption {
        let option: LogoSloganVariantOption;

        switch (this._layoutVariant) {
            case LayoutVariantOption.Application:
                if (this._s.layout.viewportWidth < 1680) {
                    option = LogoSloganVariantOption.Small;
                } else {
                    option = LogoSloganVariantOption.Medium;
                }
                break;
            case LayoutVariantOption.StaticPage:
                if (this._s.layout.viewportWidth < 768) {
                    option = LogoSloganVariantOption.Small;
                } else if (
                    this._s.layout.viewportWidth <
                    ResponsiveProperties.desktopSize
                ) {
                    option = LogoSloganVariantOption.Medium;
                } else {
                    option = LogoSloganVariantOption.Large;
                }
                break;
            default:
                option = LogoSloganVariantOption.Small;
        }

        return option;
    }
    toString(): string {
        return LogoSloganVariantOption[this.variant];
    }
}
