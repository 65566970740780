import { icons } from './Icons';
import { IconProps } from './Icon';

export function SvgIcon({ width, height, icon }: SvgIconProps) {
    const iconSvg = typeof icon === 'string' ? icons[icon] : icon;
    return (
        <svg
            focusable="false"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox={`0 0 ${width} ${height}`}
            style={{ display: 'block' }}
        >
            {iconSvg}
        </svg>
    );
}

interface SvgIconProps extends IconProps {
    width: number;
    height: number;
}
