import { Localized } from '../../common/components/Localized';
import { InlineErrorBox } from '../../ui/modals/Confirmable';

export function RemoteRequestFailed(props: { httpStatusCode: number }) {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Anfrage fehlgeschlagen"
                    fr="Requête rejetée"
                    it="Richiesta fallita"
                    en="Request failed"
                />
            }
        >
            <p>
                <Localized
                    de="Es ist ein Kommunikationsproblem mit dem Server aufgetreten. Das kann ein vorübergehendes Problem sein. Bitte versuchen Sie, Ihre Anfrage in wenigen Augenblicken erneut einzureichen."
                    fr="Un problème de communication avec le serveur s'est produit. C'est peut-être un problème temporaire. Veuillez essayer de soumettre à nouveau votre demande dans quelques instants."
                    it="Si è verificato un problema di comunicazione con il server. Questo potrebbe essere un problema temporaneo. Vogliate riprovare tra qualche istante."
                    en="A server communication problem occured. That may be a temporary issue. Please try resubmitting your request in a few moments."
                />
            </p>
            <p>HTTP {props.httpStatusCode}</p>
        </InlineErrorBox>
    );
}
