import { MaterialButton, ButtonProps } from './MaterialButton';
import { css } from '@emotion/css';

interface Props {
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
}

export const TwoButtons = (p: Props) => (
    <div
        className={css({
            textAlign: 'right',
            width: '100%',
            padding: '24px',
        })}
    >
        {p.secondaryButton && (
            <div className={css({ display: 'inline', marginRight: '12px' })}>
                <MaterialButton {...p.secondaryButton} />
            </div>
        )}
        {p.primaryButton && (
            <MaterialButton primary={true} {...p.primaryButton} />
        )}
    </div>
);
