import { css } from '@emotion/css';

/**
 * This is an exact copy of the spinner used at index.ejs. It can be used as a loading
 * indicator for pages that have nothing to render, as it prevents showing two different
 * loaders (first when loading main.js and second when rendering the loading page)
 */
export function SpinnerAppLoad() {
    return (
        <div
            className={css({
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                '@media screen and (max-width: 768px)': {
                    backgroundColor: '#0066a6',
                },
            })}
        >
            <div
                className={css({
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                })}
            >
                <div
                    className={css({
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginLeft: '-50px',
                        marginTop: '-124px',
                        width: '100px',
                        height: '100px',
                    })}
                >
                    <svg
                        className={css({
                            animation: 'rotate 2s linear infinite',
                            height: '100px',
                            transformOrigin: 'center center',
                            width: '100px',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            margin: 'auto',
                            '@keyframes rotate': {
                                '100%': {
                                    transform: 'rotate(360deg)',
                                },
                            },
                        })}
                    >
                        <circle
                            className={css({
                                strokeDasharray: '1, 200',
                                strokeDashoffset: 0,
                                animation: 'dash 1.5s ease-in-out infinite',
                                strokeLinecap: 'round',
                                stroke: '#4f9adb',
                                '@keyframes dash': {
                                    '0%': {
                                        strokeDasharray: '1, 200',
                                        strokeDashoffset: 0,
                                    },
                                    '50%': {
                                        strokeDasharray: '89, 200',
                                        strokeDashoffset: '-35px',
                                    },
                                    '100%': {
                                        strokeDasharray: '89, 200',
                                        strokeDashoffset: '-124px',
                                    },
                                },
                            })}
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="3"
                            strokeMiterlimit="10"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}
