import { useState } from 'react';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import { logAction } from '../../../utils/ActionLog';
import {
    mobileNavigateAccount,
    mobileNavigateTransactions,
} from '../../actions/LayoutActions';
import { isProd } from '../../../utils/Env';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import { Localized } from '../../../common/components/Localized';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../../tb-ui/typo';
import { RedWarnIcon16 } from '../../../common/components/RedWarnIcon';
import { ParkingpayLogo } from '../../../ui/ParkingpayLogo';
import {
    TransactionList,
    useTransactionList,
} from '../../../transactions-list/TransactionsListContext';
import {
    RequestStatus,
    ServerRequestState,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';

export function MobileHeader() {
    const [transactionListState, refetchTransactionList] = useTransactionList();
    const hasWarning = useTransactionListWarningState(transactionListState);
    return (
        <HeaderBar>
            <HeaderMobileLogo hasWarning={hasWarning} />
            <HeaderMobilePageButtonTransactions
                showWarning={hasWarning}
                refetchTransactionList={refetchTransactionList}
            />
            <HeaderMobilePageButtonAccount />
        </HeaderBar>
    );
}

function useTransactionListWarningState(
    transactionListState: ServerRequestState<TransactionList, never>,
) {
    const [hasWarning, setHasWarning] = useState(
        transactionListState.status === RequestStatus.SUCCESS
            ? transactionListHasWarning(transactionListState.data)
            : false,
    );
    useServerSuccessEffect(transactionListState, transactionList => {
        setHasWarning(transactionListHasWarning(transactionList));
    });
    return hasWarning;
}

function transactionListHasWarning(transactionList: TransactionList) {
    return (
        transactionList.active.filter(s => s.type === 'permit' && s.showWarning)
            .length > 0 ||
        transactionList.future.filter(s => s.type === 'permit' && s.showWarning)
            .length > 0 ||
        transactionList.needTransaction.filter(
            s => s.type === 'permit' && s.showWarning,
        ).length > 0 ||
        transactionList.expired.filter(
            s => s.type === 'permit' && s.showWarning,
        ).length > 0
    );
}

function HeaderBar({ children }: { children: React.ReactNode }) {
    return (
        <div
            className={css({
                display: 'flex',
                height: '48px',
                maxWidth: '100vw',
                background: isProd() ? Colors.darkblue : Colors.yellow,
            })}
        >
            {children}
        </div>
    );
}

function HeaderMobileLogo({ hasWarning }: { hasWarning: boolean }) {
    const mediaQueryWithText = hasWarning
        ? {
              '@media(max-width: 383px)': {
                  display: 'none',
              },
          }
        : {
              '@media(max-width: 364px)': {
                  display: 'none',
              },
          };
    const mediaQueryWithoutText = hasWarning
        ? {
              '@media(min-width: 384px)': {
                  display: 'none',
              },
          }
        : {
              '@media(min-width: 365px)': {
                  display: 'none',
              },
          };

    return (
        <div
            className={css({
                paddingTop: '4px',
                height: '36px',
                marginLeft: '16px',
                marginRight: '4px',
                flexShrink: 0,
                flexGrow: 1,
            })}
        >
            <div
                className={css({
                    width: '134px',
                    height: '40px',
                    ...mediaQueryWithText,
                })}
            >
                <ParkingpayLogo withText />
            </div>
            <div
                className={css({
                    width: '40px',
                    height: '40px',
                    ...mediaQueryWithoutText,
                })}
            >
                <ParkingpayLogo />
            </div>
        </div>
    );
}

function HeaderMobilePageButtonTransactions({
    showWarning,
    refetchTransactionList,
}: {
    showWarning: boolean;
    refetchTransactionList: () => void;
}) {
    const update = useUpdate();
    return (
        <HeaderMobilePageButton
            onClick={() => {
                update(store => logAction(store, 'goto-park-list', null));
                refetchTransactionList();
                update(mobileNavigateTransactions);
            }}
        >
            <div
                className={css({
                    flexShrink: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                })}
            >
                <Localized
                    de="Transaktionen"
                    fr="Transactions"
                    it="Transazioni"
                    en="Transactions"
                />
            </div>
            {showWarning && <RedWarnIcon16 />}
        </HeaderMobilePageButton>
    );
}

function HeaderMobilePageButtonAccount() {
    const update = useUpdate();
    return (
        <HeaderMobilePageButton
            onClick={() => {
                update(store => logAction(store, 'goto-account', null));
                update(mobileNavigateAccount);
            }}
        >
            <Localized de="Konto" fr="Compte" it="Conto" en="Account" />
        </HeaderMobilePageButton>
    );
}

function HeaderMobilePageButton({
    onClick,
    children,
}: {
    onClick: ClickHandler;
    children: React.ReactNode;
}) {
    return (
        <Clickable
            element="div"
            className={css({
                background: Colors.white,
                borderRadius: '2px',
                margin: '9px 7px 9px 0',
                padding: '0 11px',
                ...Typo.caption,
                letterSpacing: '0.5px',
                color: Colors.darkblue,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '3px',
                overflow: 'hidden',
            })}
            onClick={onClick}
        >
            {children}
        </Clickable>
    );
}
