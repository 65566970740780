import { InputContext } from '../InputContext';
import { css } from '@emotion/css';
import { Typo } from '../../typo';
import { Colors } from 'dg-web-shared/ui/vars';
import React from 'react';

interface Props {
    visible: boolean;
    text: React.ReactChild;
    context: InputContext;
}

export class Hint extends React.Component<Props> {
    render() {
        const context = this.props.context;
        return (
            <div
                className={css([
                    {
                        cursor: 'text',
                        ...Typo.body,
                        position: 'absolute',
                        bottom: '32px',
                        height: '16px',
                        width: '100%',
                    },
                    context === InputContext.regular && {
                        color: Colors.rgba(Colors.action_w, 0.4),
                    },
                    context === InputContext.form && {
                        color: Colors.rgba(Colors.action_f, 0.4),
                    },
                    context === InputContext.inverted && {
                        color: Colors.rgba(Colors.action_b, 0.4),
                    },
                    !this.props.visible && {
                        visibility: 'hidden',
                    },
                ])}
            >
                {this.props.text}
            </div>
        );
    }
}

export var Factory = React.createFactory<Props>(Hint);
