import * as LayoutState from '../state/LayoutState';

interface ResponsivePropertiesInputs {
    layout: LayoutState.State;
}
export class ResponsiveProperties {
    public static desktopSize = 1224;
    public static dekstopLargeSize = 1680;
    private _s: ResponsivePropertiesInputs;

    constructor(s: ResponsivePropertiesInputs) {
        this._s = s;
    }

    get mobile(): boolean {
        return this._s.layout.viewportWidth < ResponsiveProperties.desktopSize;
    }

    get desktop(): boolean {
        return (
            this._s.layout.viewportWidth >= ResponsiveProperties.desktopSize &&
            this._s.layout.viewportWidth < ResponsiveProperties.dekstopLargeSize
        );
    }

    toString(): string {
        if (this.mobile) {
            return 'Mobile';
        } else if (this.desktop) {
            return 'Desktop';
        } else {
            return 'DesktopLarge';
        }
    }
}
