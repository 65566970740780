import * as CommonQueryActions from '../actions/CommonQueryActions';
import * as Http from '../../api/Http';
import * as Flux from 'dg-web-shared/lib/Flux';
import * as ServerState from './ServerState';
import * as ServerStateSlice from './ServerStateSlice';
import { Response } from 'dg-web-shared/lib/HttpResponse';

export type AddressCountry = Http.AddressCountry;

export type State = ServerState.State<AddressCountry[], void>;
type EmptyData = ServerStateSlice.EmptyData<AddressCountry[], void>;

function compareLicensePlateCountries(
    language: string,
): (a: AddressCountry, b: AddressCountry) => number {
    return function (a: AddressCountry, b: AddressCountry): number {
        return a.name[language].localeCompare(b.name[language]);
    };
}

function bodyParser(
    data: AddressCountry[],
    language: string,
): AddressCountry[] {
    return data.sort(compareLicensePlateCountries(language));
}

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<
    AddressCountry[],
    void
> {
    key(): string {
        return 'common-AddressCountriesState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(CommonQueryActions.receiveAddressCountries);
        }
    }

    getEmptyData(): EmptyData {
        return { data: [], errorData: undefined };
    }

    writeRequest(res: Response, language: string): void {
        if (res.pending) {
            this.writePending();
        } else if (res.statusCode.cls.success) {
            this.writeSuccess(res);
            this.set((s: State): State => {
                // parse
                if (res.body) {
                    s.data = bodyParser(<any>res.body, language);
                }
                return s;
            });
        } else {
            this.writeError(res);
        }
    }
}
