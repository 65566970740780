import * as LayoutState from '../state/LayoutState';
import { ApplicationLayout } from './ApplicationLayout';
import { StaticPageLayout } from './StaticPageLayout';
import { LayoutVariantOption } from '../components/LayoutContainer';

export namespace columnsContainer {
    interface WidthInputs {
        layout: LayoutState.State;
    }
    export function width(
        s: WidthInputs,
        layoutVariant: LayoutVariantOption,
    ): number {
        if (layoutVariant === LayoutVariantOption.Application) {
            const layout = new ApplicationLayout(s);
            return layout.columnsContainerWidth;
        } else if (layoutVariant === LayoutVariantOption.StaticPage) {
            const layout = new StaticPageLayout(s);
            return layout.columnsContainerWidth;
        }
        return 0;
    }
}
