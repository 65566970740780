import { css } from '@emotion/css';
import { paper } from '../../../../../tb-ui/paper';

const img = require('../assets/parking-badge.png');

export enum ParkingBadgeImageSize {
    SMALL = 'SMALL',
    LARGE = 'LARGE',
}

export const ParkingBadgeImage = ({
    imageSize,
    noFloat,
}: {
    imageSize: ParkingBadgeImageSize;
    noFloat?: true;
}) => (
    <img
        className={css({
            float: noFloat ? 'none' : 'left',
            width: imageSize === ParkingBadgeImageSize.LARGE ? '140px' : '80px',
            ...paper(4),
            borderRadius:
                imageSize === ParkingBadgeImageSize.LARGE ? '8px' : '4px',
            marginTop:
                imageSize === ParkingBadgeImageSize.LARGE ? undefined : '16px',
        })}
        src={img}
    />
);
