import { Maybe, isDefined } from './MaybeV2';
import { Response } from './HttpResponse';
import * as superagent from 'superagent';
import * as Flux from './Flux';
import * as AbstractServerStateSlice from './AbstractLegacyServerStateSlice';
import debounce from 'debounce';
import * as HttpResponse from './HttpResponse';

import { shallowEqual } from './Object';

export interface ContextualState<D>
    extends AbstractServerStateSlice.BaseAbstractLegacyServerState {
    data: Maybe<D>;
}

export interface GeneratorFunctions<C, D> {
    get: (store: Flux.Store, context: Maybe<C>) => ContextualState<D>;
    getState: (store: Flux.Store) => ContextualState<D>;
    refetchSameContext: (store: Flux.Store, clearData: boolean) => string;
}

export type RequestGen<C> = (context: Maybe<C>) => superagent.Request<any, any>;

interface GeneratorArgs<C, D> {
    key: string;
    requestGenerator: RequestGen<C>;
    request: (
        reqGen: RequestGen<C>,
        requestUpdate: (store: Flux.Store, res: Response) => string,
    ) => Flux.Update<C>;
    parseBody: (body: any) => D;
    onRequest?: Maybe<(store: Flux.Store, state: ContextualState<D>) => void>;
    debounceTime?: Maybe<number>;
    refreshIntervalSecs?: number | null | undefined;
}

export interface ServerState<C, D>
    extends AbstractServerStateSlice.AbstractLegacyServerState<Maybe<D>> {
    data: Maybe<D>;
    context: Maybe<C>;
}

export const generateContextualState = <C extends { [key: string]: any }, D>(
    args: GeneratorArgs<C, D>,
): GeneratorFunctions<C, D> => {
    class ServerStateSlice extends AbstractServerStateSlice.AbstractLegacyServerStateSlice<
        ServerState<C, D>,
        D
    > {
        key(): string {
            return args.key;
        }

        getEmptyData(): D | null {
            return null;
        }

        parseBody(body: any): D {
            return args.parseBody(body);
        }

        sideEffects(store: Flux.Store): void {
            if (isDefined(this.state.context) && this.state.shouldFetch) {
                store.update(
                    args.request(
                        args.requestGenerator,
                        (store: Flux.Store, res: Response): string => {
                            this.writeRequest(res);
                            if (args.onRequest) {
                                args.onRequest(store, this.state);
                            }
                            return this.key() + '-fetch';
                        },
                    ),
                    this.state.context,
                );
            }
        }

        getRefreshIntervalSecs(): number {
            return args.refreshIntervalSecs ? args.refreshIntervalSecs : 0;
        }

        getInitialState(): ServerState<C, D> {
            return {
                pending: false,
                shouldFetch: false,
                data: null,
                statusCode: new HttpResponse.StatusCode(null),
                attempts: 0,
                request: null,
                lastSuccessfulFetch: 0,
                context: null,
            };
        }

        protected writeSuccess(res: HttpResponse.Response): void {
            super.writeSuccess(res);
            this.set((s: ServerState<C, D>): ServerState<C, D> => {
                s.data = res.body ? this.parseBody(res.body) : null;
                return s;
            });
        }

        resetWithContext(context: Maybe<C>, store: Flux.Store): void {
            /* refetch if:
                - context is newly set
                - context is not shallow equal to old context
                - shouldFetch is already true
            */
            const shouldFetch =
                context &&
                (!this.state.context ||
                    !shallowEqual(this.state.context, context));

            const shouldDiscard = !!this.state.context && !context;

            if (shouldFetch) {
                this.set((s: ServerState<C, D>): ServerState<C, D> => {
                    s.shouldFetch = true;
                    s.context = context;
                    s.data = null;
                    return s;
                });
                store.emitChange();
            } else if (shouldDiscard) {
                this.set((s: ServerState<C, D>): ServerState<C, D> => {
                    if (s.pending && s.request) {
                        s.request.abort();
                    }
                    s.pending = false;
                    s.shouldFetch = false;
                    s.context = null;
                    s.data = null;
                    return s;
                });
                store.emitChange();
            }
        }
    }

    const refetchStateIfNecessarySync = (
        store: Flux.Store,
        context: Maybe<C>,
    ) => {
        new ServerStateSlice(store).resetWithContext(context, store);
    };

    const refetchStateIfNecessary = debounce(
        refetchStateIfNecessarySync,
        args.debounceTime || 10,
    );

    const getStateObject = (store: Flux.Store): ContextualState<D> => {
        const s = new ServerStateSlice(store).state;
        return {
            pending: s.pending,
            data: s.data,
            statusCode: s.statusCode,
        };
    };

    return {
        get: (store: Flux.Store, newContext: Maybe<C>) => {
            refetchStateIfNecessary(store, newContext);
            return getStateObject(store);
        },
        getState: (store: Flux.Store) => getStateObject(store),
        refetchSameContext: (store: Flux.Store, clearData: boolean): string => {
            new ServerStateSlice(store).set(
                (s: ServerState<C, D>): ServerState<C, D> => {
                    s.shouldFetch = true;
                    if (clearData) {
                        s.data = null;
                    }
                    return s;
                },
            );
            return args.key + '-refetchSameContext';
        },
    };
};
