/* tslint:disable:no-unused-variable */
/* tslint:enable:no-unused-variable */
import { NewsletterUnsubscribePageTexts } from '../components/NewsletterUnsubscribePage';
import Text = require('../../../common/i18n/Text');

/* tslint:disable:typedef max-line-length */
export var texts: Text.Translations<NewsletterUnsubscribePageTexts> = {
    de: {
        button: (email: string): string => {
            return `${email} abmelden`;
        },
        label: (): string => {
            return 'Newsletter abmelden';
        },
        success: (): string => {
            return 'Sie haben sich erfolgreich aus dem Newsletter ausgetragen.';
        },
        failure: (): string => {
            return 'Ein Fehler ist aufgetreten; bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unseren Helpdesk.';
        },
    },
    fr: {
        button: (email: string): string => {
            return `Désabonner ${email}`;
        },
        label: (): string => {
            return 'Annulation de la newsletter';
        },
        success: (): string => {
            return "Vous avez annulé avec succès l'abonnement à la newsletter.";
        },
        failure: (): string => {
            return "Une erreur s'est produite; veuillez réessayer. Si le problème persiste, veuillez contacter notre helpdesk.";
        },
    },
    it: {
        button: (email: string): string => {
            return `Disdire ${email}`;
        },
        label: (): string => {
            return 'Disdetta newsletter';
        },
        success: (): string => {
            return "Ha cancellato con successo l'iscrizione alla newsletter.";
        },
        failure: (): string => {
            return 'Si è verificato un errore; la preghiamo di riprovare. Se il problema persiste contatti il nostro helpdesk.';
        },
    },
    en: {
        button: (email: string): string => {
            return `Unsubscribe ${email}`;
        },
        label: (): string => {
            return 'Newsletter Unsubscribe';
        },
        success: (): string => {
            return 'You successfully unsubscribed from our newsletter.';
        },
        failure: (): string => {
            return 'An has error occured; please try again. If the problem persists, contact our helpdesk.';
        },
    },
};
/* tslint:enable:typedef max-line-length */
