import { rgba as libRgba } from '../../utils/src/Rgba';

export namespace Colors {
    export const rgba: (hex: string, opacity: number) => string = libRgba;
    export const white = '#FFFFFF';
    export const black = '#000000';
    export const typo1 = '#424242';
    export const blue = '#0066A6';
    export const darkblue = '#132F69';
    export const form = '#CFE7FF';
    export const red = '#ED1C2D';
    export const buttonRed = '#D0021B';
    export const buttonGreen = '#55C428';
    export const action_w = '#414141';
    export const action_f = '#2C5B85';
    export const action_b = '#FFFFFF';
    export const error = '#B40000';
    export const error_bg = '#F9EBEB';
    export const error_b = '#FF943C';
    export const content = '#F4F4F4';
    export const confirmation = '#7CB342';
    export const confirmation_bg = '#EBF9EB';
    export const background = '#F5F5F5';
    export const inactiveTab = '#055796';
    export const firstTab = '#0A4A87';
    export const contentHover = '#E6E6E6';
    export const formHover = '#D9ECFF';
    export const lightblue = '#2E91DF';
    export const middleblue = '#0067A3';
    export const yellow = '#FFAE00';
    export const green = '#459623';
    export const semanticBorder = '#D4D4D5';
    export const adminGlobal = '#2185D0';
    export const adminOperator = '#00B5AD';
    export const lightgrey = rgba(black, 0.08);
    export const grey = rgba(black, 0.5);
    export const darkgrey = action_w;
    export const blackGradientStart = '#373737';
    export const blackGradient = `linear-gradient(to bottom, ${blackGradientStart}, ${black})`;
    export const backgroundGray = '#999';
    export const grassGreen = '#459623';
    export const disabledButtonGrey = '#AAAAAA';
    export const fPendent = '#FFAE00';
    export const fPendentC = '#333333';
}
export const rgba: (hex: string, opacity: number) => string = libRgba;
