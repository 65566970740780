import { css } from '@emotion/css';

import { selectState } from 'dg-web-shared/lib/Flux';
import { JsxMessage } from 'dg-web-shared/lib/Localized';
import { PaymentMethodEnum } from '../../api/Http';
import { Localized } from '../../common/components/Localized';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { BankTransfer, OnlinePaymentSelection } from './ActionableItem';
import { LsvTip } from './e-payment-top-up/LsvTip';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { SelectedPaymentFlow } from '../../common/payment/Payment';
import { logAction } from '../../utils/ActionLog';
import { DirectDebitPaymentMethodNavigation } from '../payment-method/direct-debit/DirectDebitPaymentMethodNavigation';
import { DirectDebitSlideIns } from '../payment-method/direct-debit/DirectDebitMethod';
import * as SettingsState from '../../common/state/SettingsState';
import { PaymentMethodStatus } from '../payment-method/PaymentMethodStatus';
import {
    SavedCreditCardAliasResponse,
    SavedCreditCardAliasResponseTag,
} from 'dg-web-shared/model/PaymentAlias';

export const TopUpBalanceMethodsSlideIn = portalSlideIn(
    TopUpBalanceMethodsStatelessWrapper,
);

function TopUpBalanceMethodsStatelessWrapper(props: Props) {
    return <TopUpBalanceMethods {...props} />;
}

const TopUpBalanceMethods = selectState<
    Props,
    {
        paymentType: PaymentTypeState.State;
        loginState: CurrentLoginState.State;
        directDebitNavigationState: DirectDebitPaymentMethodNavigation.State;
        settings: SettingsState.State;
    }
>(
    store => ({
        paymentType: new PaymentTypeState.StateSlice(store).state,
        loginState: CurrentLoginState.get(store),
        directDebitNavigationState:
            DirectDebitPaymentMethodNavigation.get(store),
        settings: new SettingsState.StateSlice(store).state,
    }),
    props => {
        function nav(selected: SelectedPaymentFlow) {
            props.update(store =>
                logAction(store, 'topup-navigation', selected),
            );
            props.setPaymentCategory(selected);
        }

        function updateDirectDebitFormState(open: boolean) {
            props.update(DirectDebitPaymentMethodNavigation.stateWrite, {
                open: open,
            });
        }

        const aliasData =
            props.ccAlias.tag === SavedCreditCardAliasResponseTag.FOUND
                ? props.ccAlias.creditCardAlias
                : null;

        return (
            <div
                className={css({
                    ...Typo.robotoRegular,
                })}
            >
                <GroupHeader
                    title={'Online'}
                    description={{
                        de: (
                            <span>
                                Der Kontostand wird <strong>sofort</strong> nach
                                der Kontoladung aktualisiert.
                            </span>
                        ),
                        fr: (
                            <span>
                                Le solde du compte est mis à jour{' '}
                                <strong>immédiatement</strong> après
                                l&apos;alimentation.
                            </span>
                        ),
                        it: (
                            <span>
                                Il saldo del conto verrà aggiornato{' '}
                                <strong>immediatamente</strong> dopo la
                                ricarica.
                            </span>
                        ),
                        en: (
                            <span>
                                Upon top up, your account balance will be
                                updated <strong>immediately</strong>.
                            </span>
                        ),
                    }}
                />
                <OnlinePaymentSelection
                    currentAlias={aliasData}
                    onClick={(p: SelectedPaymentFlow) => nav(p)}
                />
                <GroupHeader
                    title={'Offline'}
                    description={{
                        de: (
                            <span>
                                Der Kontostand wird{' '}
                                <strong>erst nach Eingang</strong> Ihrer Zahlung
                                aktualisiert (es kann 3 bis 5 Arbeitstage
                                dauern).
                            </span>
                        ),
                        fr: (
                            <span>
                                Le solde du compte sera mis à jour{' '}
                                <strong>seulement après réception</strong> de
                                votre paiement (cela peut prendre de 3 à 5 jours
                                ouvrables).
                            </span>
                        ),
                        it: (
                            <span>
                                Il saldo del conto verrà aggiornato{' '}
                                <strong>solo dopo la ricezione</strong> del suo
                                pagamento (possono essere necessari da 3 a 5
                                giorni lavorativi).
                            </span>
                        ),
                        en: (
                            <span>
                                The account balance will be updated{' '}
                                <strong>only upon receipt</strong> of your
                                payment (it may take 3 to 5 working days).
                            </span>
                        ),
                    }}
                />
                <BankTransfer
                    onClick={() => nav(SelectedPaymentFlow.BANK_TRANSFER)}
                />
                {props.paymentType.data.activePaymentMethod
                    .activePaymentType === PaymentMethodEnum.PREPAID &&
                    !props.paymentType.data.paymentMethodChangeRequest && (
                        <>
                            <DirectDebitSlideIns
                                status={PaymentMethodStatus.INACTIVE}
                                update={props.update}
                                backToList={() =>
                                    updateDirectDebitFormState(false)
                                }
                                open={props.directDebitNavigationState.open}
                            />
                            <LsvTip
                                onNavigateToDirectDebitPaymentMethod={() =>
                                    updateDirectDebitFormState(true)
                                }
                            />
                        </>
                    )}
            </div>
        );
    },
);

export function GroupHeader({
    title,
    description,
}: {
    title: JSX.Element | string;
    description: JsxMessage;
}) {
    return (
        <div className={css({ display: 'flex', flexDirection: 'column' })}>
            <div
                className={css({
                    textTransform: 'uppercase',
                    padding: '5px 24px',
                    backgroundColor: '#999',
                    color: Colors.white,
                    display: 'flex',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    ...Typo.robotoBold,
                })}
            >
                {title}
            </div>

            <div
                className={css({
                    padding: '10px 24px',
                    backgroundColor: Colors.contentHover,
                    fontSize: '14px',
                    lineHeight: 1.5,
                    ...Typo.robotoRegular,
                    color: Colors.action_w,
                })}
            >
                <Localized {...description} />
            </div>
        </div>
    );
}

interface Props {
    setPaymentCategory: (p: SelectedPaymentFlow) => void;
    ccAlias: SavedCreditCardAliasResponse;
}
