import { Localized } from '../../common/components/Localized';
import { ModalErrorBox } from '../../ui/modals/Confirmable';
import { PaymentMethodEnum } from '../../api/Http';

export function AddressFormError(props: {
    onClose: () => void;
    paymentMethod: PaymentMethodEnum;
}): JSX.Element {
    return (
        <>
            <ModalErrorBox
                titleCaption={
                    <Localized
                        de="Änderung nicht möglich"
                        fr="Modification impossible"
                        it="Il cambiamento non è possibile"
                        en="Change not possible"
                    />
                }
                confirmCallback={props.onClose}
            >
                <p>
                    {props.paymentMethod == PaymentMethodEnum.LSV && (
                        <LSVPaymentNotPossibleMessage />
                    )}
                    {props.paymentMethod == PaymentMethodEnum.INVOICE && (
                        <InvoicePaymentNotPossibleMessage />
                    )}
                </p>
                <p>
                    <Localized
                        de="Um das Land zu ändern, müssen sie zuerst eine neue Zahlungsweise wählen."
                        fr="Pour changer de pays, vous devez d'abord choisir un nouveau mode de paiement."
                        it="Per poter cambiare il paese, dovete prima scegliere una nuova modalità di pagamento."
                        en="You'll have to choose a new payment method in order to change the country."
                    />
                </p>
            </ModalErrorBox>
        </>
    );
}

export function LSVPaymentNotPossibleMessage(): JSX.Element {
    return (
        <Localized
            de="Die Zahlungsweise «Lastschrift» ist nur in der Schweiz und in Liechtenstein möglich."
            fr="Le mode de paiement «prélèvement automatique» n'est disponible qu'en Suisse et au Liechtenstein."
            it="La modalità di pagamento «addebito diretto» è disponibile solo in Svizzera e nel Liechtenstein."
            en="The direct debit payment method is only available in Switzerland and Liechtenstein."
        />
    );
}

export function InvoicePaymentNotPossibleMessage(): JSX.Element {
    return (
        <Localized
            de="Die Zahlungsweise «auf Rechnung» ist nur in der Schweiz und in Liechtenstein möglich."
            fr="Le mode de paiement «sur facture» n'est disponible qu'en Suisse et au Liechtenstein."
            it="La modalità di pagamento «contro fattura» è disponibile solo in Svizzera e nel Liechtenstein."
            en="The invoice payment method is only available in Switzerland and Liechtenstein."
        />
    );
}
