import * as Flux from 'dg-web-shared/lib/Flux';
import { Message } from 'dg-web-shared/lib/Localized';
import * as AppActions from '../../app/actions/AppActions';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { EmailEditFormSlideIn } from './EmailEditForm';
import { GenderEditFormSlideIn, genderMessages } from './GenderEditForm';
import { NameEditFormSlideIn } from './NameEditForm';
import { PasswordEditFormSlideIn } from './PasswordEditForm';
import { derivePhoneNumberState, PhoneEditFormSlideIn } from './PhoneEditForm';
import { RequestedEmailReviewSlideIn } from './RequestedEmailReview';
import { FieldItemBlock } from '../root/components/ItemBlock';
import { ActionBlock } from '../root/components/ActionBlock';
import { PhoneNumbers } from './KycTypes';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import { RecentsEditFormSlideIn } from './RecentsToggleForm';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { useState } from 'react';

export const CurrentLoginFormsSlideIn = portalSlideIn<{
    currentLogin: CurrentLoginState.State;
    allState: Flux.Store;
}>(CurrentLoginForms);

enum View {
    MENU = 'MENU',
    GENDER_EDIT_FORM = 'GENDER_EDIT_FORM',
    NAME_EDIT_FORM = 'NAME_EDIT_FORM',
    EMAIL_EDIT_FORM = 'EMAIL_EDIT_FORM',
    REQUESTED_EMAIL_REVIEW = 'REQUESTED_EMAIL_REVIEW',
    PASSWORD_EDIT_FORM = 'PASSWORD_EDIT_FORM',
    PHONE_EDIT_FORM = 'PHONE_EDIT_FORM',
    RECENTS_EDIT_FORM = 'RECENTS_EDIT_FORM',
}

function CurrentLoginForms(props: {
    currentLogin: CurrentLoginState.State;
    allState: Flux.Store;
}): JSX.Element | null {
    const [view, setView] = useState(View.MENU);
    const [phoneNumberTitle, setPhoneNumberTitle] = useState<React.ReactNode>(
        <Localized {...messages.phoneNumber} />,
    );

    const backToMenu = setView.bind(null, View.MENU);

    const [phoneNumberState, refetchPhoneNumbers] = useServerFetch<
        PhoneNumbers,
        Record<string, never>
    >(
        () => ({
            url: `/ui-api/customer-account/kyc/current`,
        }),
        {},
    );

    return (
        <>
            <FieldItemBlock
                onClick={() => {
                    setView(View.GENDER_EDIT_FORM);
                }}
                label={<Localized {...messages.gender} />}
                content={
                    props.currentLogin.data?.gender && (
                        <Localized
                            {...genderMessages.title(
                                props.currentLogin.data.gender,
                            )}
                        />
                    )
                }
            />

            <FieldItemBlock
                onClick={() => {
                    setView(View.NAME_EDIT_FORM);
                }}
                label={<Localized {...messages.name} />}
                content={
                    props.currentLogin.data?.firstName &&
                    props.currentLogin.data?.lastName &&
                    props.currentLogin.data.firstName +
                        ' ' +
                        props.currentLogin.data.lastName
                }
            />

            <RequestEmailChangeMenuItem
                onClick={setView.bind(
                    null,
                    props.currentLogin.data?.requestedEmail
                        ? View.REQUESTED_EMAIL_REVIEW
                        : View.EMAIL_EDIT_FORM,
                )}
                email={props.currentLogin.data?.email ?? null}
                requestedEmail={props.currentLogin.data?.requestedEmail ?? null}
            />

            <FieldItemBlock
                onClick={() => {
                    setView(View.PASSWORD_EDIT_FORM);
                }}
                label={<Localized {...messages.password} />}
                content="••••••••"
            />

            {props.currentLogin?.data?.isCompany === false && (
                <RequestMobileNumberChangeMenuItem
                    onClick={() => {
                        setView(View.PHONE_EDIT_FORM);
                    }}
                    phoneNumbers={phoneNumberState.data}
                />
            )}

            <FieldItemBlock
                onClick={() => {
                    setView(View.RECENTS_EDIT_FORM);
                }}
                label={<RecentsTitle />}
                content={
                    !props.currentLogin.data ? (
                        ''
                    ) : props.currentLogin.data.showRecents ? (
                        <Localized
                            de="Aktiv"
                            fr="Actif"
                            it="Attivo"
                            en="Active"
                        />
                    ) : (
                        <Localized
                            de="Inaktiv"
                            fr="Inactif"
                            it="Inattivo"
                            en="Inactive"
                        />
                    )
                }
            />

            {props.currentLogin.data && (
                <>
                    <GenderEditFormSlideIn
                        title={<Localized {...messages.gender} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.GENDER_EDIT_FORM}
                        onClose={backToMenu}
                        currentLogin={props.currentLogin.data}
                    />

                    <NameEditFormSlideIn
                        title={<Localized {...messages.name} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.NAME_EDIT_FORM}
                        onClose={backToMenu}
                        currentLogin={props.currentLogin.data}
                    />

                    <EmailEditFormSlideIn
                        title={<Localized {...messages.changeEmail} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.EMAIL_EDIT_FORM}
                        onClose={backToMenu}
                        currentLogin={props.currentLogin.data}
                    />

                    <RequestedEmailReviewSlideIn
                        title={<Localized {...messages.requestedEmailReview} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.REQUESTED_EMAIL_REVIEW}
                        onClose={backToMenu}
                        currentLogin={props.currentLogin.data}
                    />

                    <PasswordEditFormSlideIn
                        title={<Localized {...messages.changePassword} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.PASSWORD_EDIT_FORM}
                        onClose={backToMenu}
                        currentLogin={props.currentLogin.data}
                    />

                    <PhoneEditFormSlideIn
                        title={phoneNumberTitle}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.PHONE_EDIT_FORM}
                        onClose={backToMenu}
                        currentLogin={props.currentLogin.data}
                        phoneNumberState={derivePhoneNumberState(
                            phoneNumberState.data,
                        )}
                        refetch={() => {
                            refetchPhoneNumbers();
                            props.allState.update(store => {
                                const accountSlice =
                                    new AccountBalanceState.StateSlice(store);
                                accountSlice.reset();
                                return 'triggerAccountBalanceSliceUpdateFromMobileNumber';
                            });
                        }}
                        setTitle={(title: React.ReactNode) =>
                            setPhoneNumberTitle(title)
                        }
                    />

                    <RecentsEditFormSlideIn
                        title={<RecentsTitle />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.RECENTS_EDIT_FORM}
                        onClose={backToMenu}
                        currentLogin={props.currentLogin.data}
                    />
                </>
            )}

            <ActionBlock
                title={
                    <Localized
                        de="Abmelden"
                        fr="Quitter"
                        it="Esci"
                        en="Logout"
                    />
                }
                icon={'logout'}
                onClick={() => {
                    props.allState.update(AppActions.logout);
                }}
            />
        </>
    );
}

function NotConfirmed(props: { what: string }) {
    return (
        <>
            <i>
                <Localized
                    de={`${props.what} - nicht bestätigt`}
                    fr={`${props.what} - pas confirmé`}
                    it={`${props.what} - non confermato`}
                    en={`${props.what} - not confirmed`}
                />
            </i>
        </>
    );
}

function RequestEmailChangeMenuItem(props: {
    onClick: () => void;
    email: string | null;
    requestedEmail: string | null;
}) {
    return (
        <FieldItemBlock
            onClick={props.onClick}
            label={
                <Localized de="E-Mail" fr="E-mail" it="E-mail" en="E-mail" />
            }
            content={
                props.requestedEmail ? (
                    <NotConfirmed what={props.requestedEmail} />
                ) : (
                    props.email
                )
            }
        />
    );
}

function RequestMobileNumberChangeMenuItem(props: {
    onClick: () => void;
    phoneNumbers: PhoneNumbers | null;
}) {
    return (
        <FieldItemBlock
            onClick={props.onClick}
            label={<Localized {...messages.phoneNumber} />}
            content={
                props.phoneNumbers ? (
                    props.phoneNumbers.requestedPhoneNumber ? (
                        <NotConfirmed
                            what={props.phoneNumbers.requestedPhoneNumber}
                        />
                    ) : (
                        props.phoneNumbers.activePhoneNumber
                    )
                ) : null
            }
        />
    );
}

export interface CurrentLoginFormsVisibilityState {
    expanded: boolean;
}

export class CurrentLoginFormsVisibilityStateSlice extends Flux.StateSlice<CurrentLoginFormsVisibilityState> {
    key(): string {
        return 'account-current-login-CurrentLogin';
    }

    sideEffects(): void {
        return;
    }

    getInitialState(): CurrentLoginFormsVisibilityState {
        return {
            expanded: false,
        };
    }

    toggle(): void {
        this.set(
            (
                s: CurrentLoginFormsVisibilityState,
            ): CurrentLoginFormsVisibilityState => {
                s.expanded = !s.expanded;
                return s;
            },
        );
    }
}

export function toggleCurrentLoginFormsVisibility(): Flux.Write {
    return (store: Flux.Store): void => {
        new CurrentLoginFormsVisibilityStateSlice(store).toggle();
    };
}

const messages: Messages = {
    changeEmail: {
        de: 'E-Mail ändern',
        fr: "Modifier l'e-mail",
        it: 'Modifica e-mail',
        en: 'Change e-mail',
    },
    changePassword: {
        de: 'Passwort ändern',
        fr: 'Changer mot de passe',
        it: 'Modifica password',
        en: 'Change password',
    },
    gender: {
        de: 'Anrede',
        fr: 'Titre',
        it: 'Titolo',
        en: 'Title',
    },
    name: {
        de: 'Vorname / Nachname',
        fr: 'Prénom / Nom',
        it: 'Nome / Cognome',
        en: 'First name / Last name',
    },
    password: {
        de: 'Passwort',
        fr: 'Mot de passe',
        it: 'Password',
        en: 'Password',
    },
    phoneNumber: {
        de: 'Mobiltelefon',
        fr: 'Portable',
        it: 'Cellulare',
        en: 'Mobile',
    },
    requestedEmailReview: {
        de: 'E-Mail-Änderung pendent',
        fr: 'Changement e-mail en attente',
        it: 'Modifica e-mail in sospeso',
        en: 'E-mail change pending',
    },
};

type Messages = {
    changeEmail: Message;
    changePassword: Message;
    gender: Message;
    name: Message;
    password: Message;
    phoneNumber: Message;
    requestedEmailReview: Message;
};

function RecentsTitle() {
    return (
        <Localized
            de="Kürzlich verwendete"
            fr="Les récents"
            it="Recenti"
            en="Recents"
        />
    );
}
