import * as Text from './Text';

interface Texts {
    Email: Text.Translation;
    Zone: Text.Translation;
    LicensePlate: Text.Translation;
    LicensePlateType: Text.Translation;
    LicensePlateCountryCode: Text.Translation;
    Vehicle: Text.Translation;
    Remarks: Text.Translation;
    NoRemarksPresent: Text.Translation;
    Operator: Text.Translation;
    Badge: Text.Translation;
    BadgeNumber: Text.Translation;
    BadgeType: Text.Translation;
    ErrorTryAgainOrHelpdesk: Text.Translation;
    TryAgainOrHelpdesk: Text.Translation;
    Save: Text.Translation;
    Cancel: Text.Translation;
    MandatoryField: Text.Translation;
    User: Text.Translation;
    Password: Text.Translation;
    ConfirmPassword: Text.Translation;
    ChangePassword: Text.Translation;
    Location: Text.Translation;
    Transaction: Text.Translation;
    Permit: Text.Translation;
    PermitType: Text.Translation;
    Zipcode: Text.Translation;
    City: Text.Translation;
    ZipcodeCity: Text.Translation;
    ParkOption: Text.Translation;
    PrivateAccount: Text.Translation;
    PrivateAccountInfo: Text.Translation;
    CompanyAccount: Text.Translation;
    CompanyAccountInfo: Text.Translation;
}

export const texts: Text.Translations<Texts> = {
    de: {
        Email: () => 'E-Mail',
        Zone: () => 'Zone',
        LicensePlate: () => 'Kennzeichen',
        LicensePlateType: () => 'Schildart',
        LicensePlateCountryCode: () => 'Ländercode',
        Vehicle: () => 'Fahrzeug',
        Remarks: () => 'Bezeichnung',
        NoRemarksPresent: () => 'Keine Bezeichnung vorhanden',
        Operator: () => 'Betreiber',
        Badge: () => 'Badge',
        BadgeType: () => 'Badge Typ',
        BadgeNumber: () => 'Badge Nummer',
        ErrorTryAgainOrHelpdesk: () =>
            'Es ist ein Fehler aufgetreten. ' + texts.de.TryAgainOrHelpdesk(),
        TryAgainOrHelpdesk: () =>
            'Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk.',
        Save: () => 'Speichern',
        Cancel: () => 'Abbrechen',
        MandatoryField: () => 'Pflichtfeld',
        User: () => 'E-Mail',
        Password: () => 'Passwort',
        ConfirmPassword: () => 'Passwort bestätigen',
        ChangePassword: () => 'Passwort ändern',
        Location: () => 'Standort',
        Transaction: () => 'Parkvorgang',
        Permit: () => 'Bewilligung',
        PermitType: () => 'Bewilligungsart',
        Zipcode: () => 'PLZ',
        City: () => 'Ort',
        ZipcodeCity: () => 'PLZ / Ort',
        ParkOption: () => 'Zone / Parking',
        PrivateAccount: () => 'Privatkonto',
        PrivateAccountInfo: () => 'Natürliche Personen',
        CompanyAccount: () => 'Firmenkonto',
        CompanyAccountInfo: () => 'Juristische Personen',
    },
    fr: {
        Email: () => 'E-mail',
        Zone: () => 'Zone',
        LicensePlate: () => 'Immatriculation',
        LicensePlateType: () => 'Catégorie',
        LicensePlateCountryCode: () => 'Pays',
        Vehicle: () => 'Véhicule',
        Remarks: () => 'Description',
        NoRemarksPresent: () => 'Aucune description disponible',
        Operator: () => 'Exploitant',
        Badge: () => 'Badge',
        BadgeType: () => 'Type de badge',
        BadgeNumber: () => 'Numéro de badge',
        ErrorTryAgainOrHelpdesk: () =>
            'Une erreur est survenue. ' + texts.fr.TryAgainOrHelpdesk(),
        TryAgainOrHelpdesk: () =>
            'Merci de réessayer plus tard. Si le problème persiste, merci de l’annoncer à notre Helpdesk.',
        Save: () => 'Enregistrer',
        Cancel: () => 'Annuler',
        MandatoryField: () => 'Ce champ est obligatoire',
        User: () => 'E-mail',
        Password: () => 'Mot de passe',
        ConfirmPassword: () => 'Confirmer le mot de passe',
        ChangePassword: () => 'Changer mot de passe',
        Location: () => 'Lieu',
        Transaction: () => 'Procédure',
        Permit: () => 'Autorisation',
        PermitType: () => "Type d'autorisation",
        Zipcode: () => 'NPA',
        City: () => 'Lieu',
        ZipcodeCity: () => 'NPA / Localité',
        ParkOption: () => 'Zone / Parking',
        PrivateAccount: () => 'Compte privé',
        PrivateAccountInfo: () => 'Personne physique',
        CompanyAccount: () => 'Compte professionnel',
        CompanyAccountInfo: () => 'Personne morale',
    },
    it: {
        Email: () => 'E-mail',
        Zone: () => 'Zona',
        LicensePlate: () => 'Targa',
        LicensePlateType: () => 'Categoria',
        LicensePlateCountryCode: () => 'Paese',
        Vehicle: () => 'Veicolo',
        Remarks: () => 'Descrizione',
        NoRemarksPresent: () => 'Nessuna descrizione',
        Operator: () => 'Gestore',
        Badge: () => 'Badge',
        BadgeType: () => 'Tipo di badge',
        BadgeNumber: () => 'Numero di badge',
        ErrorTryAgainOrHelpdesk: () =>
            'Si è verificato un errore. ' + texts.it.TryAgainOrHelpdesk(),
        TryAgainOrHelpdesk: () =>
            'Vogliate riprovare più tardi. Se il problema dovesse persistere, contattate il nostro Helpdesk.',
        Save: () => 'Salva',
        Cancel: () => 'Annulla',
        MandatoryField: () => 'Campo obbligatorio',
        User: () => 'E-mail',
        Password: () => 'Password',
        ConfirmPassword: () => 'Conferma password',
        ChangePassword: () => 'Modifica password',
        Location: () => 'Luogo',
        Transaction: () => 'Procedura',
        Permit: () => 'Autorizzazione',
        PermitType: () => "Tipo d'autorizzazione",
        Zipcode: () => 'NAP',
        City: () => 'Località',
        ZipcodeCity: () => 'NAP / Località',
        ParkOption: () => 'Zona / Parcheggio',
        PrivateAccount: () => 'Conto privato',
        PrivateAccountInfo: () => 'Persone fisiche',
        CompanyAccount: () => 'Conto aziendale',
        CompanyAccountInfo: () => 'Persone giuridiche',
    },
    en: {
        Email: () => 'E-mail',
        Zone: () => 'Zone',
        LicensePlate: () => 'License plate',
        LicensePlateType: () => 'Category',
        LicensePlateCountryCode: () => 'Country',
        Vehicle: () => 'Vehicle',
        Remarks: () => 'Description',
        NoRemarksPresent: () => 'No description',
        Operator: () => 'Operator',
        Badge: () => 'Badge',
        BadgeType: () => 'Badge type',
        BadgeNumber: () => 'Badge number',
        ErrorTryAgainOrHelpdesk: () =>
            'An error has occurred. ' + texts.en.TryAgainOrHelpdesk(),
        TryAgainOrHelpdesk: () =>
            'Please try again later. If the problem persists, please contact our Helpdesk.',
        Save: () => 'Save',
        Cancel: () => 'Cancel',
        MandatoryField: () => 'Mandatory field',
        User: () => 'E-mail',
        Password: () => 'Password',
        ConfirmPassword: () => 'Confirm password',
        ChangePassword: () => 'Change password',
        Location: () => 'Location',
        Transaction: () => 'Procedure',
        Permit: () => 'Permit',
        PermitType: () => 'Permit type',
        Zipcode: () => 'ZIP',
        City: () => 'City',
        ZipcodeCity: () => 'ZIP / City',
        ParkOption: () => 'Zone / Parking',
        PrivateAccount: () => 'Private account',
        PrivateAccountInfo: () => 'Natural persons',
        CompanyAccount: () => 'Company account',
        CompanyAccountInfo: () => 'Legal persons',
    },
};

interface AddressTexts {
    Gender: Text.Translation;
    FirstName: Text.Translation;
    LastName: Text.Translation;
    Street1: Text.Translation;
    StreetAdditionalInfo: Text.Translation;
    Company1: Text.Translation;
    Company2: Text.Translation;
    ZipCode: Text.Translation;
    City: Text.Translation;
    Country: Text.Translation;
    Mr: Text.Translation;
    Ms: Text.Translation;
    Line1: Text.Translation;
    Line2: Text.Translation;
    Address: Text.Translation;
    ShippingAddress: Text.Translation;
    CorrespondenceLanguage: Text.Translation;
    InvoiceOnly: Text.Translation;
}

export const addressTexts: Text.Translations<AddressTexts> = {
    de: {
        Gender: () => 'Anrede',
        FirstName: () => 'Vorname',
        LastName: () => 'Nachname',
        Street1: () => 'Strasse',
        StreetAdditionalInfo: () => 'Zusatzzeile Strasse',
        Company1: () => 'Firma',
        Company2: () => 'Zusatzzeile Firma',
        ZipCode: () => 'PLZ',
        City: () => 'Ort',
        Country: () => 'Land',
        Mr: () => 'Herr',
        Ms: () => 'Frau',
        Line1: () => 'Zusatzzeile',
        Line2: () => 'Strasse',
        Address: () => 'Adresse',
        ShippingAddress: () => 'Versandadresse',
        CorrespondenceLanguage: () => 'Korrespondenzsprache',
        InvoiceOnly: () => 'Nur Rechnung',
    },
    fr: {
        Gender: () => 'Titre',
        FirstName: () => 'Prénom',
        LastName: () => 'Nom',
        Street1: () => 'Rue',
        StreetAdditionalInfo: () => 'ligne supplémentaire rue',
        Company1: () => 'Entreprise',
        Company2: () => 'ligne supplémentaire entreprise',
        ZipCode: () => 'NPA',
        City: () => 'Lieu',
        Country: () => 'Pays',
        Mr: () => 'Monsieur',
        Ms: () => 'Madame',
        Line1: () => 'ligne supplémentaire ',
        Line2: () => 'Rue',
        Address: () => 'Adresse',
        ShippingAddress: () => 'Adresse de livraison',
        CorrespondenceLanguage: () => 'Langue de correspondance',
        InvoiceOnly: () => 'Seulement factures',
    },
    it: {
        Gender: () => 'Titolo',
        FirstName: () => 'Nome',
        LastName: () => 'Cognome',
        Street1: () => 'Indirizzo',
        StreetAdditionalInfo: () => 'Riga suppl. indirizzo',
        Company1: () => 'Società',
        Company2: () => 'Riga suppl. società',
        ZipCode: () => 'NAP',
        City: () => 'Località',
        Country: () => 'Paese',
        Mr: () => 'Signor',
        Ms: () => 'Signora',
        Line1: () => 'Riga suppl.',
        Line2: () => 'Indirizzo',
        Address: () => 'Indirizzo',
        ShippingAddress: () => "Indirizzo d'invio",
        CorrespondenceLanguage: () => 'Lingua di corrispondenza',
        InvoiceOnly: () => 'Solo fatture',
    },
    en: {
        Gender: () => 'Title',
        FirstName: () => 'Firstname',
        LastName: () => 'Lastname',
        Street1: () => 'Street',
        StreetAdditionalInfo: () => 'Add. street',
        Company1: () => 'Company',
        Company2: () => 'Add. company',
        ZipCode: () => 'ZIP',
        City: () => 'City',
        Country: () => 'Country',
        Mr: () => 'Mr',
        Ms: () => 'Ms',
        Line1: () => 'Address',
        Line2: () => 'Add. address',
        Address: () => 'Address',
        ShippingAddress: () => 'Shipping address',
        CorrespondenceLanguage: () => 'Correspondence language',
        InvoiceOnly: () => 'Invoice only',
    },
};

export const durationTexts = {
    monthsShort: { de: 'Monate', fr: 'mois', it: 'mesi', en: 'months' },
    dayShort: { de: 'Tag', fr: 'j', it: 'g', en: 'day' },
    daysShort: { de: 'Tage', fr: 'j', it: 'g', en: 'days' },
    hoursShort: { de: 'Std.', fr: 'h', it: 'h', en: 'h' },
    minutesShort: { de: 'Min.', fr: 'min.', it: 'min.', en: 'min.' },
    secondsShort: { de: 'Sek.', fr: 'sec.', it: 'sec.', en: 'sec.' },
};

export const surchargeTexts = {
    de: 'zzgl. 4,85% Gebühr für die Nutzung der App',
    fr: "4.85% frais d'utilisation de l'application en sus",
    it: "più 4.85% spese d'utilizzo dell'applicazione",
    en: 'plus 4.85% application usage fee',
};

export const mandatoryField = {
    de: 'Pflichtfeld',
    fr: 'Champ obligatoire',
    it: 'Campo obbligatorio',
    en: 'Mandatory field',
};

export const firstName = {
    de: 'Vorname',
    fr: 'Prénom',
    it: 'Nome',
    en: 'Firstname',
};

export const lastName = {
    de: 'Nachname',
    fr: 'Nom',
    it: 'Cognome',
    en: 'Lastname',
};

export const save = {
    de: 'Speichern',
    fr: 'Enregistrer',
    it: 'Salva',
    en: 'Save',
};

export const cancel = {
    de: 'Abbrechen',
    fr: 'Annuler',
    it: 'Annulla',
    en: 'Cancel',
};

export const street = {
    de: 'Strasse',
    fr: 'Rue',
    it: 'Indirizzo',
    en: 'Street',
};

export const zip = {
    de: 'PLZ',
    fr: 'NPA',
    it: 'NAP',
    en: 'ZIP',
};

export const city = {
    de: 'Ort',
    fr: 'Lieu',
    it: 'Località',
    en: 'City',
};

export const country = {
    de: 'Land',
    fr: 'Lieu',
    it: 'Nazione',
    en: 'Country',
};
