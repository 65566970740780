import * as Flux from 'dg-web-shared/lib/Flux';
import * as WriteStateSlice from '../../../common/state/WriteStateSlice';

export type ResponseState = WriteStateSlice.State;

export interface State {
    response: ResponseState;
    source: Origin;
}

export function getMetaState(store: Flux.Store): State {
    const localState = new LocalStateSlice(store).state;
    return {
        source: localState.origin,
        response: new ResponseStateSlice(store).state,
    };
}

export interface LocalState {
    origin: Origin;
}

export enum Origin {
    NONE,
    COMPANY,
    PERSON,
    ADDRESS,
    SHIPPING_ADDRESS,
    CORRESPONDENCE_LANGUAGE,
    BILLING_REFERENCE,
}

export class LocalStateSlice extends Flux.StateSlice<LocalState> {
    key(): string {
        return 'account-meta-MetaStateLocal';
    }
    getInitialState(): LocalState {
        return {
            origin: Origin.NONE,
        };
    }

    sideEffects(): null {
        return null;
    }

    writeOrigin(source: Origin): void {
        this.set((s: LocalState) => {
            s.origin = source;
            return s;
        });
    }
}

export class ResponseStateSlice extends WriteStateSlice.StateSlice {
    key(): string {
        return 'account-meta-MetaStateResponse';
    }
}
