import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit';
import { setSentryScope } from '../../../utils/SentryScope.ts';
import { DateTime } from 'luxon';
import { createContext, useContext, useState } from 'react';

export interface PermitPayload {
    fromDate: DateTime | null;
    duration: number | null;
    durationType: DurationType | null;
    toDate: DateTime | null;
    useReminder: boolean;
    selectedZoneIds: number[];
}

interface PermitPayloadContext {
    payload: PermitPayload;
    selectedPermitTypeId: number | null;
    setSelectedPermitTypeId: React.Dispatch<
        React.SetStateAction<number | null>
    >;
    setPayload: React.Dispatch<React.SetStateAction<PermitPayload>>;
    resetPermitPayload: () => void;
}

const initialState: PermitPayload = {
    fromDate: null,
    duration: null,
    durationType: null,
    toDate: null,
    useReminder: false,
    selectedZoneIds: [],
};

const CTX = createContext<PermitPayloadContext>({
    payload: initialState,
    setPayload: () => initialState,
    selectedPermitTypeId: null,
    setSelectedPermitTypeId: () => null,
    resetPermitPayload: () => {},
});

export function PermitPayloadContextProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [permit, setPermit] = useState(initialState);
    const [selectedPermitTypeId, setSelectedPermitTypeId] = useState<
        number | null
    >(null);

    function reset() {
        setPermit(initialState);
        setSelectedPermitTypeId(null);
        setSentryScope('selectedPermit', null);
    }

    const setSelectedPermitTypeIdAndResetPayload: typeof setSelectedPermitTypeId =
        arg => {
            setSelectedPermitTypeId(arg);
            setPermit(p => ({
                ...p,
                fromDate: null,
                duration: null,
                durationType: null,
                toDate: null,
            }));
        };

    return (
        <CTX.Provider
            value={{
                payload: permit,
                selectedPermitTypeId,
                setSelectedPermitTypeId: setSelectedPermitTypeIdAndResetPayload,
                setPayload: setPermit,
                resetPermitPayload: reset,
            }}
        >
            {children}
        </CTX.Provider>
    );
}

export function usePermitPayloadContext() {
    return useContext(CTX);
}
