import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../FullWidthBottomButton';
import { Localized } from '../Localized';
import {
    ProcessStep,
    TicketContent,
    TicketContentText,
    InfoFieldWithIcon,
    TicketInfoHeader,
    TicketTitle,
} from './TicketPlusLayout';
import { TicketPlusTicketEntry } from './TicketPlusTicketEntry';

export const TicketPlusApprovalRequired = ({
    onOpenZoneInfo,
    zoneName,
    ticketCreatedAt,
}: {
    onOpenZoneInfo: () => void;
    zoneName: string;
    ticketCreatedAt: string;
}) => (
    <TicketContent>
        <TicketInfoHeader>
            <Localized de="Parking" fr="Parking" it="Parcheggio" en="Parking" />
        </TicketInfoHeader>
        <InfoFieldWithIcon onClick={onOpenZoneInfo}>
            {zoneName}
        </InfoFieldWithIcon>
        <TicketPlusTicketEntry createdAt={ticketCreatedAt} />
        <TicketTitle>
            <Localized
                de="So einfach funktioniert’s:"
                fr="C'est très simple:"
                it="È semplicissimo:"
                en="It's that simple:"
            />
        </TicketTitle>
        <TicketContentText>
            <ProcessStep icon="ticket">
                <Localized
                    de="Geben Sie die Bezahlung des Tickets durch den Button unten frei."
                    fr="Autorise le paiement du ticket en cliquant sur le bouton ci-dessous."
                    it="Autorizzi il pagamento del ticket, cliccando sul pulsante qui sotto."
                    en="Authorise payment of the ticket by clicking on the button below."
                />
            </ProcessStep>
        </TicketContentText>
        <TicketContentText>
            <ProcessStep icon="barrier">
                <Localized
                    de="Stecken Sie beim Verlassen das Ticket wie gewohnt in die Ausfahrtstation."
                    fr="En sortant, insèrer le ticket comme d'habitude dans la station de sortie."
                    it="Quando se ne va, inserisca il ticket nella stazione d'uscita come di consueto."
                    en="When leaving, insert the ticket into the exit station as usual."
                />
                <br />
                <br />
                <Localized
                    de="Der fällige Betrag wird abgebucht – gute Fahrt!"
                    fr="Le montant dû est débité - bonne route!"
                    it="L'importo dovuto verrà addebitato - buon viaggio!"
                    en="The amount due will be debited - have a good trip!"
                />
            </ProcessStep>
        </TicketContentText>
        <TicketTitle paddingBottom>
            <Localized
                de="Den Weg zur Kasse sparen!"
                fr="Éviter de passer à la caisse!"
                it="Eviti di passare alla cassa!"
                en="Save the way to the cash desk!"
            />
        </TicketTitle>
    </TicketContent>
);

export const PayTicketButton = ({
    onClick,
    loading,
    disabled,
}: {
    onClick: () => void;
    loading: boolean;
    disabled?: boolean;
}) => (
    <FullWidthBottomButton
        color={FullWidthBottomButtonColor.GREEN}
        onClick={onClick}
        disabled={disabled || loading}
        buttonPressedColor={'rgba(85, 196, 40, 0.8)'}
        label={
            loading
                ? {
                      de: 'Zahlung wird gestartet...',
                      fr: 'Lancement du paiement...',
                      it: 'Avvio pagamento...',
                      en: 'Initiating payment...',
                  }
                : {
                      de: 'Ticket bezahlen',
                      fr: 'Payer le ticket',
                      it: 'Paga ticket',
                      en: 'Pay ticket',
                  }
        }
    />
);
