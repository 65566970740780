import { useStore } from 'dg-web-shared/lib/Flux';
import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { Helpdesk } from './Helpdesk';
import { Address } from './Address';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../style/typo';
import { Localized } from '../../../common/components/Localized';

export function AccountDeletedPage({ saldo }: { saldo: boolean }) {
    const { store } = useStore(() => null);

    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <div
                    className={css({
                        maxWidth: '480px',
                        color: Colors.typo1,
                        ...Typo.body,
                    })}
                >
                    {saldo ? (
                        <Localized
                            de={
                                'Ihr Parkingpay-Konto wurde erfolgreich gekündigt. Das Restsaldo wird Anfang nächsten Monats auf Ihr Bank-/Postkonto überwiesen.'
                            }
                            fr={
                                'Votre compte Parkingpay a été annulé. Le solde résiduel sera viré sur votre compte bancaire/postal au début du mois prochain.'
                            }
                            it={
                                "Il suo conto Parkingpay é stato disdetto. Il saldo rimanente verrà bonificato sul suo conto bancario/postale all'inizio del mese prossimo."
                            }
                            en={
                                'Your Parkingpay account has been canceled. The remaining balance will be transferred to your bank/postal account at the beginning of next month.'
                            }
                        />
                    ) : (
                        <Localized
                            de={
                                'Ihr Parkingpay-Konto wurde erfolgreich gekündigt.'
                            }
                            fr={'Votre compte Parkingpay a été annulé.'}
                            it={'Il suo conto Parkingpay é stato disdetto.'}
                            en={'Your Parkingpay account has been canceled.'}
                        />
                    )}
                </div>
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}
