import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import * as SettingsState from '../../../common/state/SettingsState';
import {
    ButtonText,
    ModalAlertBox,
    ModalErrorBox,
    ModalSuccessBox,
} from '../../../ui/modals/Confirmable';
import { Localized } from '../../../common/components/Localized';
import { Updater, useStore } from 'dg-web-shared/lib/Flux';
import {
    settleTwint,
    twintAbortRequest,
    useRequests,
} from './TwintStateComponent';
import { navigateToPermitDetails } from '../../../park-create/actions/PermitActions';
import * as ParkOptionListState from '../../../park-create/state/ParkOptionListState';
import { TimeSelectionWheelTick } from '../../../park-create/components/zone-transaction/ZoneEnforcedCheckin';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import { usePaymentTypeState } from '../../../common/state/PaymentTypeState';
import { logAction } from '../../../utils/ActionLog';
import { SavedCreditCardState } from '../../shared/SavedCreditCardAliasState';
import * as LayoutActions from '../../../layout/actions/LayoutActions';
import * as LayoutState from '../../../layout/state/LayoutState';
import { ResponsiveProperties } from '../../../layout/utils/ResponsiveProperties';
import {
    abortTwintTransaction,
    PairingState,
    PendingTwintPairing,
    TwintPendingState,
    useTwintState,
} from 'dg-web-shared/common/utils/TwintPairing';
import {
    TwintParkingpayPaymentStatesKnown,
    TwintParkingpayPendingState,
    TwintParkingpayUofStatesKnown,
    UiPaymentOrigin,
} from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import { useTransactionListRefetch } from '../../../transactions-list/TransactionsListContext';
import { useNavigate } from 'react-router-dom';
import { TicketRoutes } from '../../../park-create/ticket/TicketRoutes';
import { useEffect, useState } from 'react';

export function PendingPairing({
    pendingTwintPairing: { pairingToken, paymentAttemptId, mode },
}: {
    pendingTwintPairing: PendingTwintPairing.State;
}) {
    const navigate = useNavigate();
    const { storeState, update, store } = useStore(s => ({
        language: new SettingsState.StateSlice(s).state.language,
        layout: new LayoutState.StateSlice(s).state,
    }));
    const refetchTransactionList = useTransactionListRefetch();

    const pairingState = useTwintState<TwintParkingpayPendingState>(
        pairingToken,
        paymentAttemptId,
        mode,
        useRequests,
    );

    const backendStatus = pairingState.state;
    useEffect(() => {
        switch (pairingState.mode) {
            case PendingTwintPairing.TwintOrderMethodType.COMBINED:
            case PendingTwintPairing.TwintOrderMethodType.ORDER:
                if (backendStatus === PairingState.CONFIRMED_BY_USER) {
                    settleTwint(
                        store,
                        pairingToken,
                        paymentAttemptId,
                        refetchTransactionList,
                        mode,
                    );
                }

                if (pairingState.state === PairingState.SUCCESS) {
                    // Reset customer pairingState
                    update(store => {
                        new AccountBalanceState.StateSlice(store).reset();

                        // Reset credit card state for combined case
                        new PaymentTypeState.StateSlice(store).reset();
                        SavedCreditCardState.refetch(store);
                        TimeSelectionWheelTick.tick(store);
                        switch (pairingState.uiPaymentOrigin) {
                            case UiPaymentOrigin.TOPUP_FROM_PERMIT: {
                                refetchTransactionList();
                                ParkOptionListState.Selection.setVariant(
                                    store,
                                    null,
                                );
                                navigateToPermitDetails(
                                    store,
                                    pairingState.permitId,
                                );
                                break;
                            }
                            case UiPaymentOrigin.TOPUP_FROM_TICKET: {
                                navigate(
                                    `/ticket/${pairingState.ticketString}/${TicketRoutes.topupActivationSuccessful}`,
                                );
                                break;
                            }
                            case UiPaymentOrigin.TOPUP_FROM_CHECKIN:
                            case UiPaymentOrigin.TOPUP_FROM_ACCOUNT:
                                break;
                        }
                        return 'reset-balance';
                    });
                }
                break;
            case PendingTwintPairing.TwintOrderMethodType.UOF:
                if (pairingState.state === PairingState.SUCCESS) {
                    update(SavedCreditCardState.refetch);
                    update(s => {
                        new PaymentTypeState.StateSlice(s).reset();
                        return 'reset-payment-type-state';
                    });

                    switch (pairingState.uiPaymentOrigin) {
                        case UiPaymentOrigin.STORE_ALIAS_FROM_TICKET: {
                            navigate(
                                `/ticket/${pairingState.ticketString}/${TicketRoutes.aliasActivationSuccessful}`,
                            );
                            break;
                        }
                        case UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN:
                        case UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT: {
                            // refetch parking-wheel state as we may display a box there to add a payment method
                            update(TimeSelectionWheelTick.tick);

                            // navigate back to park column
                            const responsiveProps = new ResponsiveProperties({
                                layout: storeState.layout,
                            });
                            if (responsiveProps.mobile) {
                                update(LayoutActions.mobileNavigatePark);
                            }
                            break;
                        }
                    }
                }
                break;
        }
    }, [backendStatus]);

    switch (pairingState.state) {
        case PairingState.SUCCESS:
            return <Success update={update} pairingState={pairingState} />;

        case PairingState.ABORTED:
            return (
                <ModalErrorBox
                    titleCaption={title(pairingState)}
                    confirmCallback={() => update(PendingTwintPairing.reset)}
                >
                    <Aborted pairingState={pairingState} />
                </ModalErrorBox>
            );

        case PairingState.CREATED:
            return (
                <PendingAlertBox
                    mode={mode}
                    pairingToken={pairingToken}
                    paymentAttemptId={paymentAttemptId}
                    pairingState={pairingState}
                    isAuthorized={false}
                />
            );

        case PairingState.UNKNOWN:
        case PairingState.CONFIRMED_BY_USER:
            return (
                <PendingAlertBox
                    mode={mode}
                    pairingToken={pairingToken}
                    paymentAttemptId={paymentAttemptId}
                    pairingState={pairingState}
                    isAuthorized={true}
                />
            );
    }
}

function PendingAlertBox({
    mode,
    pairingToken,
    paymentAttemptId,
    pairingState,
    isAuthorized,
}: {
    mode: PendingTwintPairing.TwintOrderMethodType;
    pairingToken: string | null;
    paymentAttemptId: number | null;
    pairingState: TwintPendingState;
    isAuthorized: boolean;
}) {
    const [counter, setCounter] = useState(0);
    const [abortPending, setAbortPending] = useState(false);
    const disabled = counter < 10;
    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(counter => counter + 1);
            if (counter > 10) {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (
        <ModalAlertBox
            titleCaption={title(pairingState)}
            confirmCaption={ButtonText.CANCEL}
            confirmCallback={() => {
                logAction(null, 'twint-abort-pending-box', {
                    mode: mode,
                    pairingToken: pairingToken,
                    pairingState: pairingState,
                });
                abortTwintTransaction(
                    twintAbortRequest(pairingToken, paymentAttemptId, mode),
                );
                setAbortPending(true);
            }}
            confirmDisabled={disabled || abortPending}
            isPending={
                pairingState.state === PairingState.UNKNOWN || abortPending
            }
        >
            {isAuthorized ? (
                <AuthorizedContent pairingState={pairingState} />
            ) : (
                <UnauthorizedContent
                    pairingState={pairingState}
                    disabled={disabled}
                />
            )}
        </ModalAlertBox>
    );
}

function AuthorizedContent({
    pairingState,
}: {
    pairingState: TwintPendingState;
}) {
    switch (pairingState.mode) {
        case PendingTwintPairing.TwintOrderMethodType.UOF:
            return (
                <p>
                    <UoFAuthorized />
                </p>
            );
        case PendingTwintPairing.TwintOrderMethodType.COMBINED:
        case PendingTwintPairing.TwintOrderMethodType.ORDER:
            return (
                <p>
                    <PaymentAuthorized />
                </p>
            );
    }
}

function UnauthorizedContent({
    pairingState,
    disabled,
}: {
    pairingState: TwintPendingState;
    disabled: boolean;
}) {
    if (disabled) {
        switch (pairingState.mode) {
            case PendingTwintPairing.TwintOrderMethodType.ORDER:
            case PendingTwintPairing.TwintOrderMethodType.COMBINED:
                return (
                    <p>
                        <Localized
                            de="Die Zahlung muss in der TWINT-App bestätigt werden."
                            fr="Le paiement doit être confirmé dans l'app TWINT."
                            it="Il pagamento deve essere confermato nell'app TWINT."
                            en="The payment must be confirmed in the TWINT app."
                        />
                    </p>
                );
            case PendingTwintPairing.TwintOrderMethodType.UOF:
                return (
                    <p>
                        <Localized
                            de="Die Aktivierung muss in der TWINT-App bestätigt werden."
                            fr="L'activation doit être confirmée dans l'app TWINT."
                            it="L’attivazione deve essere confermata nell'app TWINT."
                            en="The activation must be confirmed in the TWINT app."
                        />
                    </p>
                );
        }
    } else {
        switch (pairingState.mode) {
            case PendingTwintPairing.TwintOrderMethodType.ORDER:
            case PendingTwintPairing.TwintOrderMethodType.COMBINED:
                return (
                    <>
                        <ConfirmationMissing />
                        <p>
                            <Localized
                                de="Sie können entweder warten oder die Zahlung abbrechen."
                                fr="Vous pouvez soit attendre, soit annuler le paiement."
                                it="Può attendere oppure annullare il pagamento."
                                en="You can either wait or cancel the payment."
                            />
                        </p>
                    </>
                );
            case PendingTwintPairing.TwintOrderMethodType.UOF:
                return (
                    <>
                        <ConfirmationMissing />
                        <p>
                            <Localized
                                de="Sie können entweder warten oder die Aktivierung abbrechen."
                                fr="Vous pouvez soit attendre, soit annuler l’activation."
                                it="Può attendere oppure annullare l’attivazione."
                                en="You can either wait or cancel the activation."
                            />
                        </p>
                    </>
                );
        }
    }
}
const ConfirmationMissing = () => (
    <p>
        <Localized
            de="Wir haben noch keine Bestätigung von der TWINT-App erhalten."
            fr="Nous n'avons pas encore reçu une confirmation de l'app TWINT."
            it="Non abbiamo ancora ricevuto una conferma dall'app TWINT."
            en="We have not yet received a confirmation from the TWINT app."
        />
    </p>
);

const PaymentAuthorized = () => (
    <Localized
        de="Die Zahlung wird bearbeitet..."
        fr="Le paiement sera traité..."
        it="Il pagamento viene elaborato..."
        en="The payment will be processed..."
    />
);

const UoFAuthorized = () => (
    <Localized
        de="Die Aktivierung wird bearbeitet..."
        fr="L'activation sera traité..."
        it="L'attivazione viene elaborata..."
        en="The activation will be processed..."
    />
);

function Success({
    update,
    pairingState,
}: {
    update: Updater;
    pairingState:
        | TwintParkingpayUofStatesKnown
        | TwintParkingpayPaymentStatesKnown;
}) {
    switch (pairingState.mode) {
        case PendingTwintPairing.TwintOrderMethodType.ORDER:
        case PendingTwintPairing.TwintOrderMethodType.COMBINED:
            switch (pairingState.uiPaymentOrigin) {
                case UiPaymentOrigin.TOPUP_FROM_PERMIT:
                case UiPaymentOrigin.TOPUP_FROM_TICKET:
                    return null;
                case UiPaymentOrigin.TOPUP_FROM_ACCOUNT:
                case UiPaymentOrigin.TOPUP_FROM_CHECKIN:
                    return <SuccessfulPayment update={update} />;
            }
        // eslint-disable-next-line no-fallthrough
        case PendingTwintPairing.TwintOrderMethodType.UOF:
            switch (pairingState.uiPaymentOrigin) {
                case UiPaymentOrigin.STORE_ALIAS_FROM_TICKET:
                    return null;
                case UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT:
                case UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN:
                    return <SuccessfulUoF update={update} />;
            }
    }
}

const SuccessfulUoF = (p: { update: Updater }) => {
    const resetPaymentType = usePaymentTypeState()[1];
    return (
        <ModalSuccessBox
            titleCaption={uofPairing}
            confirmCallback={() => {
                resetPaymentType();
                p.update(PendingTwintPairing.reset);
            }}
        >
            <>
                <p>
                    <Localized
                        de="Die Aktivierung war erfolgreich."
                        fr="L'activation a réussie."
                        it="L'attivazione ha avuto successo."
                        en="The activation was successful."
                    />
                </p>
                <p>
                    <Localized
                        de="Ihre TWINT App ist jetzt mit diesem Parkingpay-Konto verbunden."
                        fr="Votre App TWINT est maintenant connectée à ce compte Parkingpay."
                        it="La sua App TWINT è ora collegata a questo conto Parkingpay."
                        en="Your TWINT App is now connected to this Parkingpay account."
                    />
                </p>
            </>
        </ModalSuccessBox>
    );
};

const SuccessfulPayment = (p: { update: Updater }) => (
    <ModalSuccessBox
        titleCaption={onlinePayment}
        confirmCallback={() => {
            p.update(PendingTwintPairing.reset);
        }}
    >
        <p>
            <Localized
                de="Die Zahlung war erfolgreich."
                fr="Le paiement a réussi."
                it="Il pagamento è stato eseguito."
                en="The payment was successful."
            />
        </p>
        <p>
            <Localized
                de="Ihr Parkingpay-Konto wurde aufgeladen."
                fr="Votre compte Parkingpay a été rechargé."
                it="Il suo conto Parkingpay è stato ricaricato."
                en="Your Parkingpay account was topped up."
            />
        </p>
    </ModalSuccessBox>
);

const Aborted = (p: { pairingState: TwintPendingState }) => {
    switch (p.pairingState.mode) {
        case PendingTwintPairing.TwintOrderMethodType.ORDER:
        case PendingTwintPairing.TwintOrderMethodType.COMBINED:
            return (
                <>
                    <p>
                        <Localized
                            de="Die Zahlung wurde abgebrochen."
                            fr="Le paiement a été annulé."
                            it="Il pagamento è stato annullato."
                            en="The payment has been canceled."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Ihre TWINT App wurde nicht belastet."
                            fr="Votre app TWINT n'a PAS été débité."
                            it="La sua app TWINT non è stata addebiata."
                            en="Your TWINT app hasn't been charged."
                        />
                    </p>
                </>
            );
        case PendingTwintPairing.TwintOrderMethodType.UOF:
            return (
                <>
                    <p>
                        <Localized
                            de="Die Aktivierung wurde abgebrochen."
                            fr="L'activation a été annulée."
                            it="L'attivazione è stata annullata."
                            en="The activation has been canceled."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Ihre TWINT App wurde nicht mit diesem Parkingpay-Konto verbunden."
                            fr="Votre pp TWINT n'a pas été connectée à ce compte Parkingpay."
                            it="La sua app TWINT non é stata collegata a questo conto Parkingpay."
                            en="Your TWINT app wasn't connected to this Parkingpay account."
                        />
                    </p>
                </>
            );
    }
};

const title = (pairingState: TwintPendingState) => {
    switch (pairingState.mode) {
        case PendingTwintPairing.TwintOrderMethodType.ORDER:
        case PendingTwintPairing.TwintOrderMethodType.COMBINED:
            return onlinePayment;
        case PendingTwintPairing.TwintOrderMethodType.UOF:
            return uofPairing;
    }
};

const onlinePayment = (
    <Localized
        de="Online-Zahlung"
        fr="Paiement en ligne"
        it="Pagamento online"
        en="Online payment"
    />
);

const uofPairing = (
    <Localized
        de="TWINT Aktivierung"
        fr="Activation TWINT"
        it="Attivazione TWINT"
        en="TWINT Activation"
    />
);
