import { css } from '@emotion/css';
import { SvgIcon } from './SvgIcon';

export interface IconProps {
    icon: React.ReactNode;
    className?: string;
}

export function Icon16(props: IconProps): JSX.Element {
    return (
        <div className={css({ width: '16px', height: '16px' })}>
            <SvgIcon {...props} width={16} height={16} />
        </div>
    );
}

export function Icon24(props: IconProps): JSX.Element {
    return (
        <div className={css({ width: '24px', height: '24px' })}>
            <SvgIcon {...props} width={24} height={24} />
        </div>
    );
}

export function Icon(props: IconProps): JSX.Element {
    return (
        <div className={css({ width: '24px', height: '24px' })}>
            <SvgIcon {...props} width={24} height={24} />
        </div>
    );
}
