import { selectState } from 'dg-web-shared/lib/Flux';
import {
    JsxMessage,
    Language,
    languageFromString,
    Message,
} from 'dg-web-shared/lib/Localized';
import * as SettingsState from '../state/SettingsState';

export const Localized = selectState<
    Message | JsxMessage,
    { language: Language }
>(
    store => ({
        language: languageFromString(
            new SettingsState.StateSlice(store).state.language,
        ),
    }),
    props => <>{props[props.language]}</>,
);
