import { ZoneInfoSlideInTitle } from '../../common/components/ticketplus/TicketPlusGenericTexts';
import { QuickCheckoutSlideIn } from '../common/QuickCheckoutSlideIn';
import {
    QuickCheckoutContent,
    QuickCheckoutHeader,
} from '../common/QuickCheckoutLayout';
import { Localized } from '../../common/components/Localized';
import { QuickCheckoutLabel } from '../common/QuickCheckoutUiComponents';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useZoneInfoOutletContext } from './QuickCheckoutTicket';

export function QuickCheckoutZoneInfoSlideIn() {
    const { zoneInfo } = useZoneInfoOutletContext();
    const [isOpen, setOpen] = useState(true);
    const navigate = useNavigate();

    if (!zoneInfo) {
        return null;
    }

    return (
        <QuickCheckoutSlideIn open={isOpen} onClosed={() => navigate('..')}>
            <QuickCheckoutHeader onClose={() => setOpen(false)}>
                <ZoneInfoSlideInTitle />
            </QuickCheckoutHeader>
            <QuickCheckoutContent padding={'x-only'}>
                <QuickCheckoutLabel noPaddingTop>
                    <Localized de="Name" fr="Nom" it="Nome" en="Name" />
                </QuickCheckoutLabel>
                {zoneInfo.name}
                <QuickCheckoutLabel>
                    <Localized
                        de="Betreiber"
                        fr="Exploitant"
                        it="Gestore"
                        en="Operator"
                    />
                </QuickCheckoutLabel>
                {zoneInfo.operatorName}
                <br />
                {zoneInfo.zipCode} {zoneInfo.city}
            </QuickCheckoutContent>
        </QuickCheckoutSlideIn>
    );
}
