/**
 * Watch out somehow typescript doesn't properly validate these params… Make sure to add in `getDatatransHandler()`
 */
import { DEVICE_UUID } from './utils/ActionLog';

type TwintHandler = (pairingToken: number) => void;
type TwintUoFHandler = (pairingToken: string) => void;
type QRCodeScanner = () => void;

export type Environment =
    | 'prod'
    | 'test'
    | 'dev1'
    | 'dev2'
    | 'dev3'
    | 'dev4'
    | 'ebi';

type AppEnvHandler = (env: Environment) => void;

interface NativeBridge {
    scanQRCode: QRCodeScanner | null;
    requestFCMToken: () => void;
    notificationSettings: () => void;
    twint: TwintHandler | null;
    twintUoF: TwintUoFHandler | null;
    appEnv: AppEnvHandler | null;
    appTokenV2: (
        appToken: string | null,
        uuid: string,
        version: string,
    ) => void;
}

export const getBridge = (): NativeBridge | null => {
    const w: any = <any>window;
    if (w.android) {
        return w.android;
    } else if (w.webkit && w.webkit.messageHandlers) {
        return {
            scanQRCode: scanQRCodeHandler(),
            requestFCMToken: getRequestFCMTokenHandler(),
            notificationSettings: getNotificationSettingsHandler(),
            twint: getTwintHandler(),
            twintUoF: getStartUofHandler(),
            appEnv: getAppEnvHandler(),
            appTokenV2: getAppTokenHandler(),
        };
    } else {
        return null;
    }
};

export function isRunningInNativeWrapper() {
    return Boolean(getBridge());
}

export function setAppToken(appToken: string | null) {
    const bridge = getBridge();
    if (bridge && bridge.appTokenV2) {
        bridge.appTokenV2(appToken, DEVICE_UUID, PCS_VERSION);
    }
}

function scanQRCodeHandler(): QRCodeScanner | null {
    const w: any = <any>window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.scanQRCode
    ) {
        return () => w.webkit.messageHandlers.scanQRCode.postMessage(null);
    }
    return null;
}

function getRequestFCMTokenHandler() {
    const w: any = <any>window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.requestFCMToken
    ) {
        return () => w.webkit.messageHandlers.requestFCMToken.postMessage(null);
    }
    return () => null;
}

function getNotificationSettingsHandler() {
    const w: any = <any>window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.notificationSettings
    ) {
        return () =>
            w.webkit.messageHandlers.notificationSettings.postMessage(null);
    }
    return () => null;
}
function getAppTokenHandler() {
    const w: any = <any>window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.appToken
    ) {
        return (appToken: string | null, uuid: string, appVersion: string) =>
            w.webkit.messageHandlers.appToken.postMessage({
                appToken,
                uuid,
                appVersion,
            });
    }
    return () => null;
}

function getTwintHandler(): TwintHandler | null {
    const w: any = <any>window;
    const hasTwintHandler =
        w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.twint;
    if (hasTwintHandler) {
        return (pairingToken: number) =>
            w.webkit.messageHandlers.twint.postMessage({
                pairingToken,
            });
    }
    return null;
}

function getStartUofHandler(): TwintUoFHandler | null {
    const w: any = <any>window;
    const hasStartUofHandler =
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.twintUoF;
    if (hasStartUofHandler) {
        return (pairingToken: string) => {
            w.webkit.messageHandlers.twintUoF.postMessage({
                pairingToken,
            });
        };
    }
    return null;
}

function getAppEnvHandler(): AppEnvHandler | null {
    const w: any = <any>window;
    const hasAppEnv =
        w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.appEnv;
    if (hasAppEnv) {
        return (env: Environment) =>
            w.webkit.messageHandlers.appEnv.postMessage(env);
    }
    return null;
}
