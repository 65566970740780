import * as Text from '../../common/i18n/Text';
import * as AdditionalZones from '../components/permit/Zones';
import * as DateSelectionSlideIns from '../components/permit/DateSelectionSlideins';
import * as PermitConfig from '../components/permit/PermitConfig';
import * as PermitPurchase from '../components/permit/PermitPurchase';
import * as WhitelistRequest from '../components/permit/WhitelistRequest';

export const validityIntervalTexts: Text.Translations<DateSelectionSlideIns.Texts> =
    {
        de: {
            FromDate: () => 'Gültig ab',
            ToDate: () => 'Gültig bis',
            ExpirationDate: () => 'Gültig bis',
            Duration: () => 'Dauer',
            Hours: (i: number) => (i === 1 ? `Stunde` : `Stunden`),
            Days: (i: number) => (i === 1 ? 'Tag' : 'Tage'),
            Weeks: (i: number) => (i === 1 ? 'Woche' : 'Wochen'),
            Months: (i: number) => (i === 1 ? 'Monat' : 'Monate'),
            Years: (i: number) => (i === 1 ? 'Jahr' : 'Jahre'),
        },

        fr: {
            Hours: (i: number) => (i === 1 ? `heure` : `heures`),
            FromDate: () => 'Début',
            ToDate: () => 'Fin',
            ExpirationDate: () => 'Fin',
            Duration: () => 'Durée',
            Days: (i: number) => (i === 1 ? 'jour' : 'jours'),
            Weeks: (i: number) => (i === 1 ? 'semaine' : 'semaines'),
            Months: (i: number) => (i === 1 ? 'mois' : 'mois'),
            Years: (i: number) => (i === 1 ? 'année' : 'ans'),
        },

        it: {
            Hours: (i: number) => (i === 1 ? `ora` : `ore`),
            FromDate: () => 'Inizio',
            ToDate: () => 'Fine',
            ExpirationDate: () => 'Fine',
            Duration: () => 'Durata',
            Days: (i: number) => (i === 1 ? 'giorno' : 'giorni'),
            Weeks: (i: number) => (i === 1 ? 'settimana' : 'settimane'),
            Months: (i: number) => (i === 1 ? 'mese' : 'mesi'),
            Years: (i: number) => (i === 1 ? 'anno' : 'anni'),
        },

        en: {
            Hours: (i: number) => (i === 1 ? `hour` : `hours`),
            FromDate: () => 'Start',
            ToDate: () => 'End',
            ExpirationDate: () => 'End',
            Duration: () => 'Duration',
            Days: (i: number) => (i === 1 ? 'day' : 'day'),
            Weeks: (i: number) => (i === 1 ? 'week' : 'weeks'),
            Months: (i: number) => (i === 1 ? 'month' : 'months'),
            Years: (i: number) => (i === 1 ? 'year' : 'years'),
        },
    };

export const configTexts: Text.Translations<PermitConfig.Texts> = {
    de: {
        Price: () => 'Preis',
        AdditionalInformation: () => 'Zusätzliche Informationen',
        ValidTo: () => 'Gültig bis',
        ReminderLabel: () => 'Ablaufserinnerung verschicken',
        ReminderCaption: (days: number, email: string) =>
            `${days} Tage vor Ablauf an ${email}`,
    },

    fr: {
        ReminderLabel: () => 'Envoyer un rappel',
        Price: () => 'Prix',
        AdditionalInformation: () => 'Informations complémentaires',
        ValidTo: () => 'Fin',
        ReminderCaption: (days: number, email: string) =>
            `${days} jours avant l'échéance à ${email}`,
    },

    it: {
        ReminderLabel: () => 'Inviare promemoria',
        Price: () => 'Prezzo',
        AdditionalInformation: () => 'Informazioni supplementari',
        ValidTo: () => 'Fine',
        ReminderCaption: (days: number, email: string) =>
            `${days} giorni prima della scadenza a ${email}`,
    },

    en: {
        ReminderLabel: () => 'Send reminder',
        Price: () => 'Price',
        AdditionalInformation: () => 'Additional information',
        ValidTo: () => 'End',
        ReminderCaption: (days: number, email: string) =>
            `${days} days before the expiration to ${email}`,
    },
};

export const additionalZonesTexts: Text.Translations<AdditionalZones.Texts> = {
    de: {
        Zones: (i: number) => (i === 1 ? 'Zone' : 'Zonen'),
    },

    fr: {
        Zones: (i: number) => (i === 1 ? 'Zone' : 'Zones'),
    },
    it: {
        Zones: (i: number) => (i === 1 ? 'Zona' : 'Zone'),
    },
    en: {
        Zones: (i: number) => (i === 1 ? 'Zone' : 'Zones'),
    },
};

export const permitPurchaseTexts: Text.Translations<PermitPurchase.Texts> = {
    de: {
        PrintOutRequired: () =>
            'Sie müssen den Beleg für diese Bewilligung hinter der Windschutzscheibe hinterlegen, ' +
            'um nicht gebüsst zu werden',
        NeedsVignette: () =>
            'Sie können diese Bewilligung nicht kaufen, weil dem ausgewählten Kennzeichen keine Vignette zugeordnet ist. ' +
            'Bitte loggen Sie sich ein bzw. registrieren Sie sich, wenn Sie noch über kein Parkingpay-Konto verfügen und bestellen Sie eine Vignette für das entsprechende Kennzeichen',
        RequiresAccount: () =>
            'Der Kauf dieser Bewilligung muss beantragt werden. Sie müssen sich dazu einloggen bzw. sich registrieren, sofern Sie noch über kein Konto bei Parkingpay verfügen.',
        TypeNotAllowed: () =>
            'Diese Bewilligungsart ist für den gewählten Fahrzeugstyp nicht zugelassen.',
        Close: () => 'Schliessen',
        ApplicationNeededBadge: () =>
            'Dieser Badge ist für die gewählte Bewilligung nicht freigeschaltet und muss beantragt werden.',
        ToGatePermit: () => 'Bewilligung anzeigen',
        LpAlreadyRequested: (operator: string) =>
            `Ein Antrag zu diesem Kennzeichen ist noch pendent. Für weitere Fragen oder Unklarheiten wenden Sie sich direkt an den Betreiber ${operator}.`,
    },

    fr: {
        PrintOutRequired: () =>
            'Vous devez déposer le document pour cette autorisation derrière le bare-brise afin de ne pas être verbalisé.',
        NeedsVignette: () =>
            'Vous ne pouvez pas acheter l’autorisation, car l’immatriculation sélectionnée n’est pas associée à une vignette. ' +
            'Merci de vous connecter, respectivement vous inscrire si vous n’avez pas de compte, à Parkingpay et commander une vignette pour l’immatriculation correspondante. ',
        RequiresAccount: () =>
            ' L’achat de l’autorisation est soumise à conditions. Vous devez vous connecter, respectivement vous inscrire si vous n’avez pas de compte, à Parkingpay.',
        ApplicationNeededBadge: () =>
            'Ce badge ne permet pas l’achat de l’autorisation et nécessite une demande.',
        ToGatePermit: () => "Afficher l'autorisation",
        TypeNotAllowed: () =>
            "Cet type d'autorisation n'est pas permis pour le type de véhicule sélectionné.",
        Close: () => 'Fermer',
        LpAlreadyRequested: (operator: string) =>
            `Une demande pour cette immatriculation est encore en cours de traitement. Pour toutes questions ou doutes contacter directement l'exploitant ${operator}.`,
    },

    it: {
        PrintOutRequired: () =>
            'Per non essere multati dovete esporre il documento stampato di questa autorizzazione in modo visibile dietro il parabrezza.',
        NeedsVignette: () =>
            "Non potete acquistare quest'autorizzazione, in quanto il numero di targa selezionato non dispone di una vignetta. " +
            'Se non dispone ancora di un conto Parkingpay deve prima registrasi, altrimenti può semplicemente accedere al suo conto esistente e comandare una vignetta per il rispettivo numero di targa.',
        RequiresAccount: () =>
            "Per acquistare quest'autorizzazione dev'essere inoltrata una richiesta. Se non dispone ancora di un conto Parkingpay deve prima registrarsi, altrimenti può semplicemente accedere al suo conto esistente ed inoltrare la richiesta.",
        TypeNotAllowed: () =>
            'Questo tipo di autorizzazione non è abilitato per il tipo di veicolo selezionato.',
        Close: () => 'Chiudi',
        ApplicationNeededBadge: () =>
            "Questo badge non è abilitato per l'autorizzazione di parcheggio selezionata e quindi va inoltrata una richiesta.",
        ToGatePermit: () => 'Mostra autorizzazione',
        LpAlreadyRequested: (operator: string) =>
            `Una richiesta per questo numero di targa è ancora in sospeso. In caso di domande o dubbi rivolgetevi direttamente al gestore ${operator}.`,
    },

    en: {
        PrintOutRequired: () =>
            'In order not to be fined, you must display the printed document of this permit in a visible manner behind the windscreen.',
        NeedsVignette: () =>
            'You cannot purchase this permit because the license plate you have selected does not have a vignette. ' +
            'If you do not yet have a Parkingpay account, you must register first, otherwise you can simply access your existing account and command a vignette for the respective licence plate.',
        RequiresAccount: () =>
            'A request must be made to purchase this permit. If you do not yet have a Parkingpay account, you must first register, otherwise you can simply access your existing account and submit your request.',
        TypeNotAllowed: () =>
            'This type of permit is not enabled for the selected vehicle type.',
        Close: () => 'Close',
        ApplicationNeededBadge: () =>
            'This badge is not enabled for the selected permit and therefore a request must be made.',
        ToGatePermit: () => 'Show permit',
        LpAlreadyRequested: (operator: string) =>
            `A request for this license plate is still pending. If you have any questions or concerns, please contact the operator ${operator} directly.`,
    },
};

export const whitelistRequestTexts: Text.Translations<WhitelistRequest.Texts> =
    {
        de: {
            bodyTransaction: (lp: string) =>
                `Das Kennzeichen ${lp} ist für einen Parkvorgang in der gewählten Zone nicht freigeschaltet und muss zuerst beantragt werden.`,
            bodySingular: (lp: string) =>
                `Das Kennzeichen ${lp} ist für die gewählte Bewilligung nicht freigeschaltet und muss zuerst beantragt werden.`,
            bodyPlural: (lps: string[]) =>
                `Die Kennzeichen ${lps.join(
                    ', ',
                )} sind für die gewählte Bewilligung nicht freigeschaltet und müssen zuerst beantragt werden.`,
            button: () => 'Beantragen',
            operatorInfo: (operatorName: string) =>
                `Für weitere Fragen oder bei Unklarheiten wenden Sie sich direkt an den Betreiber ${operatorName}.`,
            pendingInfo: () =>
                `Der Antrag ist vom Betreiber noch nicht bearbeitet worden.`,

            pendingLpInfoSingular: (lp: string) =>
                `Das Kennzeichen ${lp} ist für die gewählte Bewilligung nicht freigeschaltet.`,
            pendingLpInfoPlural: (lps: string[]) =>
                `Die Kennzeichen ${lps.join(
                    ', ',
                )} sind für die gewählte Bewilligung nicht freigeschaltet.`,
        },
        fr: {
            bodyTransaction: (lp: string) =>
                `L'immatriculation ${lp} ne dispose pas de l'approbation nécessaire pour démarrer une procedure dans la zone sélectionnée. Vous devez d'abord faire une demande.`,
            bodySingular: (lp: string) =>
                `L'immatriculation ${lp} ne dispose pas de l'approbation nécessaire pour l'achat de l'autorisation. Vous devez d'abord faire une demande.`,
            bodyPlural: (lps: string[]) =>
                `Les immatriculations ${lps.join(
                    ', ',
                )} ne disposent pas de l'approbation nécessaire pour l'achat de l'autorisation. Vous devez d'abord faire une demande.`,
            button: () => 'Appliquer',
            operatorInfo: (operatorName: string) =>
                `Pour toutes questions ou doutes contactez l'exploitant ${operatorName}.`,
            pendingInfo: () =>
                `La demande est encore en cours de traitement par l'exploitant.`,

            pendingLpInfoSingular: (lp: string) =>
                `L'immatriculation ${lp} ne dispose pas de l'approbation nécessaire pour l'achat de l'autorisation.`,
            pendingLpInfoPlural: (lps: string[]) =>
                `Les immatriculations ${lps.join(
                    ', ',
                )} ne disposent pas de l'approbation nécessaire pour l'achat de l'autorisation.`,
        },
        it: {
            bodyTransaction: (lp: string) =>
                `La targa ${lp} non dispone dell'approvazione necessaria per avviare una procedura nella zona selezionata. Deve quindi prima inoltrare una richiesta.`,
            bodySingular: (lp: string) =>
                `Il numero di targa ${lp} non dispone dell'approvazione necessaria per l'acquisto dell'autorizzazione selezionata. Deve quindi prima inoltrare una richiesta.`,
            bodyPlural: (lps: string[]) =>
                `I numeri di targa ${lps.join(
                    ', ',
                )} non dispongono dell'approvazione necessaria per l'acquisto dell'autorizzazione selezionata. Deve quindi prima inoltrare una richiesta.`,
            button: () => 'Inoltra richiesta',
            operatorInfo: (operatorName: string) =>
                `In caso di domande o dubbi si rivolga direttamente al gestore ${operatorName}.`,
            pendingInfo: () =>
                `La richiesta non è ancora stata elaborata da parte del gestore.`,
            pendingLpInfoSingular: (lp: string) =>
                `Il numero di targa ${lp} non dispone dell'approvazione necessaria per l'acquisto dell'autorizzazione selezionata.`,
            pendingLpInfoPlural: (lps: string[]) =>
                `I numeri di targa ${lps.join(
                    ', ',
                )} non dispongono dell'approvazione necessaria per l'acquisto dell'autorizzazione selezionata.`,
        },
        en: {
            bodyTransaction: (lp: string) =>
                `The license plate ${lp} does not have the necessary approval to initiate a procedure in the selected zone. You must therefore first submit a request.`,
            bodySingular: (lp: string) =>
                `The license plate ${lp} does not have the necessary approval to purchase the selected permit. You must therefore first submit a request.`,
            bodyPlural: (lps: string[]) =>
                `The license plates ${lps.join(
                    ', ',
                )} do not have the necessary approval to purchase the selected permit. You must therefore submit a request first.`,
            button: () => 'Submit request',
            operatorInfo: (operatorName: string) =>
                `If you have any questions or concerns, please contact the operator ${operatorName} directly.`,
            pendingInfo: () =>
                `The request has not yet been processed by the operator.`,

            pendingLpInfoSingular: (lp: string) =>
                `The license plate ${lp} does not have the necessary approval to purchase the selected permit.`,
            pendingLpInfoPlural: (lps: string[]) =>
                `The license plates ${lps.join(
                    ', ',
                )} do not have the necessary approval to purchase the selected permit.`,
        },
    };
