import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { icons } from 'dg-web-shared/ui/icons/Icons';
import { Icon24 } from 'dg-web-shared/ui/icons/Icon';

export function RedWarnIcon24() {
    return (
        <span className={css({ color: Colors.red })}>
            <Icon24 icon="warn" />
        </span>
    );
}

export function RedWarnIcon16() {
    return (
        <span className={css({ color: Colors.red })}>
            <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox={`0 0 24 24`}
                style={{ display: 'block' }}
            >
                {icons.warn}
            </svg>
        </span>
    );
}
