import { useNavigate } from 'react-router-dom';
import { ModalErrorBox } from '../../ui/modals/Confirmable';

import { Localized } from '../../common/components/Localized';

export function InvalidQrCodeScanModal() {
    const navigate = useNavigate();
    return (
        <ModalErrorBox
            confirmCallback={() => navigate('/')}
            titleCaption={
                <Localized
                    de="Ungültiger QR-Code"
                    fr="Code QR invalide"
                    it="Codice QR non valido"
                    en="Invalid QR code"
                />
            }
        >
            <p>
                <Localized
                    de="Der gescannte QR-Code enspricht keinem gültigen Format."
                    fr="Le code QR scanné ne correspond à aucun format valide."
                    it="Il codice QR scansionato non corrisponde a un formato valido."
                    en="The scanned QR code does not correspond to a valid format."
                />
            </p>
        </ModalErrorBox>
    );
}
