import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../../tb-ui/typo';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export function ErrorBlock(props: { text: React.ReactNode }) {
    return (
        <div
            className={css({
                position: 'relative',
                background: Colors.error_bg,
                border: `solid 1px ${Colors.rgba(Colors.error, 0.5)}`,
                paddingLeft: '64px',
                paddingRight: '24px',
            })}
        >
            <div
                className={css({
                    position: 'absolute',
                    color: Colors.error,
                    top: '3px',
                    width: '48px',
                    height: '48px',
                    paddingLeft: '12px',
                    paddingTop: '12px',
                    left: '8px',
                })}
            >
                <Icon icon="error" />
            </div>
            <div
                className={css({
                    ...Typo.body,
                    color: Colors.error,
                    paddingTop: `${20 - Typo.bodyTopCorrection}px`,
                    marginBottom: '25px',
                })}
            >
                {props.text}
            </div>
        </div>
    );
}
