import { Localized } from '../../common/components/Localized';

import { SlideInForm } from '../root/components/SlideInForm';

export function PaymentMethodFormBlock(props: Props) {
    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: props.onCancel,
            }}
            primaryButton={{
                label:
                    props.isPending === true ? (
                        <Localized
                            de="Beantragen..."
                            fr="Demander..."
                            it="Richiedi..."
                            en="Request..."
                        />
                    ) : (
                        <Localized
                            de="Beantragen"
                            fr="Demander"
                            it="Richiedi"
                            en="Request"
                        />
                    ),
                onClick: props.onSubmit,
                disabled: !props.submitEnabled || props.isPending,
            }}
        >
            {props.children}
        </SlideInForm>
    );
}

export interface Props {
    submitEnabled: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    children: React.ReactNode;
    isPending?: boolean;
}
