import { Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2';
import { Translation } from 'dg-web-shared/lib/Text';
import * as LicensePlateValidation from 'dg-web-shared/lib/LicensePlateValidation';
import * as FieldTexts from '../../common/i18n/FieldTexts';
import Text = require('../../common/i18n/Text');

type Validator = (value: string | null, language: string) => string | null;

export namespace Validators {
    export const NotEmpty = (v: string | number | null, language: string) =>
        !!v && typeof v === 'string' && v.length > 0
            ? null
            : !!v && typeof v === 'number'
              ? null
              : validationTexts[language].requiredField();

    export const ZipCode = (v: string | null, language: string) =>
        !!v && v.length >= 4 && v.length <= 10
            ? null
            : validationTexts[language].invalidZipCode();

    export const LicensePlate =
        (lp: LicensePlateValidation.LicensePlate) =>
        (_: string | null, language: string) =>
            LicensePlateValidation.licensePlateErrorText(
                lp,
                FieldTexts.licensePlateTexts[language],
            );
}

export type Form<S> = { [F in keyof S]?: Validator[] };

type ErrorTexts<S> = { [F in keyof S]?: Maybe<string> };
type Validate<S> = {
    isValid: boolean;
    errorTexts: ErrorTexts<S>;
};
export const validate = <S>(
    values: { [F in keyof S]?: any },
    form: Form<S>,
    language: string,
): Validate<S> => {
    const errorTexts: ErrorTexts<S> = {};
    for (const fieldName in form) {
        if (form.hasOwnProperty(fieldName)) {
            const value = values[fieldName];
            const errorText = thenElse(
                ((form[fieldName] || []) as Validator[]).find(
                    validator => !!validator(value, language),
                ),
                v => v(values[fieldName], language),
                null,
            );

            if (!!errorText && errorText !== '') {
                errorTexts[fieldName] = errorText;
            }
        }
    }
    return {
        isValid: Object.keys(errorTexts).length === 0,
        errorTexts: errorTexts,
    };
};

export const errorText = <S>(
    show: boolean,
    errors: ErrorTexts<S>,
    get: (errors: ErrorTexts<S>) => Maybe<string>,
) => (!!get(errors) && show ? get(errors) : null);

interface ValidationTexts {
    requiredField: Translation;
    invalidZipCode: Translation;
}

const validationTexts: Text.Translations<ValidationTexts> = {
    de: {
        requiredField: () => 'Pflichtfeld',
        invalidZipCode: () => 'Ungültige PLZ',
    },
    fr: {
        requiredField: () => 'Champ obligatoire',
        invalidZipCode: () => 'NPA pas valable',
    },
    it: {
        requiredField: () => 'Campo obbligatorio',
        invalidZipCode: () => 'NAP non valido',
    },
    en: {
        requiredField: () => 'Mandatory field',
        invalidZipCode: () => 'Invalid ZIP',
    },
};
