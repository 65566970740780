import * as SettingsState from '../../common/state/SettingsState';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    Language,
    languageFromString,
    makeLocalizedText,
} from 'dg-web-shared/lib/Localized';
import { Localized } from '../../common/components/Localized';
import { css } from '@emotion/css';
import { InfoFieldWithIcon } from '../../common/components/ticketplus/TicketPlusLayout';
import {
    LicensePlateType,
    PermitPurchaseFormFields,
    PermitPurchaseFormValues,
    PermittypeFormSuccessResponse,
} from './QuickCheckoutPermittypeTypes';
import {
    permitDurationText,
    PermitDurationType,
} from 'dg-web-shared/common/models/PermitDurationType';
import {
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Car, Motorcycle } from 'dg-web-shared/ui/icons/IconsScalable';
import {
    FormFieldValidationError,
    QuickCheckoutInlineWarning,
    QuickCheckoutInput,
    QuickCheckoutInputLabel,
    QuickCheckoutLabel,
} from '../common/QuickCheckoutUiComponents';
import { DurationSelectionInput } from './QuickCheckoutPermittypeFormDurationSelection';
import { QuickCheckoutTosCheckbox } from '../common/QuickCheckoutTosCheckbox';
import {
    QuickCheckoutContent,
    QuickCheckoutFooter,
} from '../common/QuickCheckoutLayout';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../common/components/FullWidthBottomButton';
import { QuickCheckoutConstants } from '../common/QuickCheckoutConstants';
import styled from '@emotion/styled';
import { ChangeHandler, Control, Controller, useForm } from 'react-hook-form';
import * as emailValidator from 'email-validator';
import { getLicensePlateStates } from 'dg-web-shared/lib/LicensePlateValidation';
import { stripNonNumericAndNoLetterCharactersAllowUmlauts } from 'dg-web-shared/lib/StringConversions';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import { QuickCheckoutRequestErrorMessage } from '../common/QuickCheckoutRequestErrorMessage';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';

export function QuickCheckoutPermittypeForm({
    permittype,
}: {
    permittype: PermittypeFormSuccessResponse;
}) {
    const { storeState } = useStore(store => ({
        language: languageFromString(
            new SettingsState.StateSlice(store).state.language,
        ),
    }));

    const [permitOrderState, startPermitOrder, resetPermitOrderState] =
        useServerWrite<
            PermitPurchaseFormValues,
            { redirectUri: string },
            ValidationData
        >(payload => ({
            url: `/ui-api/quickcheckout/permittype/${payload.permitUrlIdentification}`,
        }));

    useServerSuccessEffect(permitOrderState, data => {
        window.location.href = data.redirectUri;
    });

    const redirectPending =
        permitOrderState.status === RequestStatus.PENDING ||
        permitOrderState.status === RequestStatus.SUCCESS;

    const {
        form: {
            trigger,
            formState: { isValid, errors },
            control,
            handleSubmit,
        },
        licensePlateNrRegister,
        emailRegister,
        watched,
    } = usePermitPurchaseForm(permittype, storeState.language);
    const navigate = useNavigate();

    const isDayOrWeekSelected =
        watched.duration !== null &&
        (watched.duration.type === PermitDurationType.day ||
            watched.duration.type === PermitDurationType.week);

    useEffect(() => {
        trigger(PermitPurchaseFormFields.licensePlateNr);
    }, [watched.licensePlateCountry]);

    return (
        <>
            <QuickCheckoutContent padding="x-only">
                <QuickCheckoutLabel>
                    <Localized
                        de="Beschreibung"
                        fr="Description"
                        it="Descrizione"
                        en="Description"
                    />
                </QuickCheckoutLabel>
                <InfoFieldWithIcon onClick={() => navigate('details')}>
                    {permittype.name}
                </InfoFieldWithIcon>
                {permittype.additionalInfo && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="Zusätzliche Informationen"
                                fr="Informations complémentaires"
                                it="Informazioni supplementari"
                                en="Additional information"
                            />
                        </QuickCheckoutLabel>
                        <div
                            className={css({
                                whiteSpace: 'pre-wrap',
                            })}
                        >
                            {permittype.additionalInfo}
                        </div>
                    </>
                )}
                {!permittype.permitIsChangeable && (
                    <QuickCheckoutInlineWarning marginTop="5px">
                        <Localized {...permittype.operatorEditPolicyText} />
                    </QuickCheckoutInlineWarning>
                )}
                <QuickCheckoutInputLabel
                    htmlFor={PermitPurchaseFormFields.licensePlateNr}
                >
                    <Localized
                        de="Fahrzeug"
                        fr="Véhicule"
                        it="Veicolo"
                        en="Vehicle"
                    />
                </QuickCheckoutInputLabel>
                <div
                    className={css({
                        display: 'flex',
                        flexShrink: 0,
                        gap: '10px',
                    })}
                >
                    <QuickCheckoutInput
                        as="div"
                        onClick={() => navigate('vehicle-country-selection')}
                        onChange={e => e.target.value}
                        noPadding
                        className={css({
                            justifyContent: 'center',
                            cursor: 'pointer',
                        })}
                    >
                        {watched.licensePlateCountry}
                    </QuickCheckoutInput>
                    <QuickCheckoutInput
                        type="text"
                        className={css({
                            flexGrow: 1,
                        })}
                        placeholder={makeLocalizedText(storeState.language)({
                            de: 'Kennzeichen',
                            fr: 'Plaque',
                            it: 'Numero di targa',
                            en: 'License plate',
                        })}
                        {...licensePlateNrRegister}
                    />
                    <QuickCheckoutInput
                        as="div"
                        onClick={() => navigate('vehicle-type-selection')}
                        noPadding
                        className={css({
                            justifyContent: 'center',
                            cursor: 'pointer',
                        })}
                    >
                        <LicensePlateTypeIcon
                            licensePlateType={watched.licensePlateType}
                        />
                    </QuickCheckoutInput>
                </div>
                <FormFieldValidationError
                    errors={errors}
                    name={PermitPurchaseFormFields.licensePlateNr}
                />
                <QuickCheckoutInputLabel
                    htmlFor={PermitPurchaseFormFields.email}
                >
                    <Localized
                        de="E-Mail (optional, für den Versand der Quittung)"
                        fr="E-mail (facultatif, pour l'envoi du reçu)"
                        it="E-mail (facoltativo, per l'invio della ricevuta)"
                        en="E-mail (optional, for the receipt delivery)"
                    />
                </QuickCheckoutInputLabel>
                <QuickCheckoutInput
                    type="email"
                    placeholder="E-mail"
                    className={css({
                        width: '100%',
                    })}
                    {...emailRegister}
                />
                <FormFieldValidationError
                    errors={errors}
                    name={PermitPurchaseFormFields.email}
                />
                {permittype.allowedDurations.length === 1 && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="Dauer"
                                fr="Durée"
                                it="Durata"
                                en="Duration"
                            />
                        </QuickCheckoutLabel>
                        <div>
                            {permittype.allowedDurations[0].quantity}{' '}
                            <Localized
                                {...permitDurationText(
                                    permittype.allowedDurations[0].type,
                                    permittype.allowedDurations[0].quantity,
                                )}
                            />
                        </div>
                        {!permittype.minimalDailyDuration24h &&
                            isDayOrWeekSelected && (
                                <QuickCheckoutInlineWarning marginTop="5px">
                                    <TerminatesAtMidnightWarningText />
                                </QuickCheckoutInlineWarning>
                            )}
                        <QuickCheckoutLabel>
                            <Localized
                                de="Preis"
                                fr="Prix"
                                it="Prezzo"
                                en="Price"
                            />
                        </QuickCheckoutLabel>
                        <div>
                            {NumberFormatter.numberToPrice(
                                permittype.allowedDurations[0].price,
                            )}
                        </div>
                    </>
                )}
                {permittype.allowedDurations.length > 1 && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="Dauer"
                                fr="Durée"
                                it="Durata"
                                en="Duration"
                            />
                        </QuickCheckoutLabel>
                        {!permittype.minimalDailyDuration24h &&
                            isDayOrWeekSelected && (
                                <QuickCheckoutInlineWarning marginBottom="10px">
                                    <TerminatesAtMidnightWarningText />
                                </QuickCheckoutInlineWarning>
                            )}
                        <Controller
                            name={PermitPurchaseFormFields.duration}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <DurationSelectionInput
                                    selectedDuration={value}
                                    setSelectedDuration={onChange}
                                    options={permittype.allowedDurations}
                                />
                            )}
                        />
                    </>
                )}
            </QuickCheckoutContent>
            <QuickCheckoutFooter>
                {!permittype.tosAndPrivacyPolicyAccepted ? (
                    <Controller
                        name={
                            PermitPurchaseFormFields.tosAndPrivacyPolicyAccepted
                        }
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <QuickCheckoutTosCheckbox
                                selected={value}
                                setSelected={onChange}
                            />
                        )}
                    />
                ) : (
                    <ButtonSpacer />
                )}
                <FullWidthBottomButton
                    color={FullWidthBottomButtonColor.GREEN}
                    onClick={handleSubmit(startPermitOrder)}
                    disabled={!isValid || redirectPending}
                    buttonPressedColor={'rgba(85, 196, 40, 0.8)'}
                    label={
                        redirectPending
                            ? {
                                  de: 'Zahlung wird gestartet...',
                                  fr: 'Lancement du paiement...',
                                  it: 'Avvio pagamento...',
                                  en: 'Initiating payment...',
                              }
                            : {
                                  de: 'Bewilligung kaufen',
                                  fr: 'Acheter autorisation',
                                  it: 'Acquista autorizzazione',
                                  en: 'Purchase permit',
                              }
                    }
                />
            </QuickCheckoutFooter>
            <QuickCheckoutRequestErrorMessage
                title={
                    <Localized
                        de="Kauf fehlgeschlagen"
                        fr="Échec de l'achat"
                        it="Acquisto fallito"
                        en="Purchase failed"
                    />
                }
                onConfirm={resetPermitOrderState}
                requestState={permitOrderState}
            />
            <PermittypeFormOutlet context={{ permittype, control }} />
        </>
    );
}

function TerminatesAtMidnightWarningText() {
    return (
        <Localized
            de="Diese Bewilligung ist nur für Kalendertage gültig und endet daher um Mitternacht."
            fr="Cette autorisation n'est valable que pour des jours calendaires et est donc terminée à minuit."
            it="Questa autorizzazione è valida solo per giorni di calendario e viene quindi terminata a mezzanotte."
            en="This permit is only valid for calendar days and is therefore terminated at midnight."
        />
    );
}

function usePermitPurchaseForm(
    permittype: PermittypeFormSuccessResponse,
    language: Language,
) {
    const form = useForm<PermitPurchaseFormValues>({
        mode: 'onTouched',
        defaultValues: {
            [PermitPurchaseFormFields.permitUrlIdentification]:
                permittype.permitUrlIdentification,
            [PermitPurchaseFormFields.licensePlateNr]: '',
            [PermitPurchaseFormFields.licensePlateType]: LicensePlateType.CAR,
            [PermitPurchaseFormFields.licensePlateCountry]: 'CH',
            [PermitPurchaseFormFields.email]: '',
            [PermitPurchaseFormFields.duration]:
                permittype.allowedDurations.length === 1
                    ? permittype.allowedDurations[0]
                    : null,
            [PermitPurchaseFormFields.tosAndPrivacyPolicyAccepted]:
                permittype.tosAndPrivacyPolicyAccepted,
        },
    });
    const licensePlateCountry = form.watch(
        PermitPurchaseFormFields.licensePlateCountry,
    );
    const licensePlateType = form.watch(
        PermitPurchaseFormFields.licensePlateType,
    );
    const duration = form.watch(PermitPurchaseFormFields.duration);

    const emailRegister = form.register(PermitPurchaseFormFields.email, {
        validate: v => {
            const isValidEmail = emailValidator.validate(v);
            if (isValidEmail || !v) {
                return true;
            } else {
                return makeLocalizedText(language)({
                    de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
                    fr: 'Veuillez saisir une adresse e-mail valide.',
                    it: 'Si prega di inserire un indirizzo email valido.',
                    en: 'Please enter a valid email address.',
                });
            }
        },
    });

    const originalLicensePlateRegister = form.register(
        PermitPurchaseFormFields.licensePlateNr,
        {
            required: true,
            validate: lp => {
                const lpErrors = getLicensePlateStates({
                    licensePlateCountryId: licensePlateCountry,
                    licensePlateNr: lp,
                });
                if (lpErrors.length < 1 || !lp) {
                    return true;
                } else {
                    return makeLocalizedText(language)({
                        de: 'Bitte geben Sie ein gültiges Kennzeichen ein.',
                        fr: "Veuillez saisir une plaque d'immatriculation valide.",
                        it: 'Si prega di inserire una targa valida.',
                        en: 'Please enter a valid license plate.',
                    });
                }
            },
        },
    );
    const customOnChange: ChangeHandler = async event => {
        const target = event.target;
        if (typeof target.value === 'string' && target.value) {
            target.value = stripNonNumericAndNoLetterCharactersAllowUmlauts(
                target.value.toUpperCase(),
            );
        }
        originalLicensePlateRegister.onChange(event);
    };
    const licensePlateNrRegister = {
        ...originalLicensePlateRegister,
        onChange: customOnChange,
    };

    return {
        form,
        licensePlateNrRegister,
        emailRegister,
        watched: { licensePlateCountry, licensePlateType, duration },
    };
}

const ButtonSpacer = styled.div({
    marginTop: QuickCheckoutConstants.BASE_PADDING,
    width: '100%',
});

function LicensePlateTypeIcon({
    licensePlateType,
}: {
    licensePlateType: LicensePlateType;
}) {
    const style = css({
        height: '28px',
        width: '28px',
    });
    switch (licensePlateType) {
        case LicensePlateType.CAR:
            return (
                <div className={style}>
                    <Car />
                </div>
            );
        case LicensePlateType.MOTORCYCLE:
            return (
                <div className={style}>
                    <Motorcycle />
                </div>
            );
    }
}

export function usePermittypeFormOutletContext() {
    return useOutletContext<PermittypeFormOutletContext>();
}

function PermittypeFormOutlet({
    context,
}: {
    context: PermittypeFormOutletContext;
}) {
    return <Outlet context={context} />;
}

type PermittypeFormOutletContext = {
    permittype: PermittypeFormSuccessResponse;
    control: Control<PermitPurchaseFormValues>;
};
