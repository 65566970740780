import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import * as ParkOptionListState from './park-create/state/ParkOptionListState';
import * as Sentry from '@sentry/browser';

function afterUpdateHandler(
    store: Flux.Store,
    name: string,
    args: Maybe<any>,
): void {
    const optionList = ParkOptionListState.Selection.get(store);
    Sentry.configureScope(scope => {
        scope.setContext('selection state', {
            value: {
                selectedCity: optionList.selectedZipCode,
                selectedParkOption: optionList.selectedParkOptionId,
            },
        });
    });
}

export const flux = new Flux.Store(afterUpdateHandler);
