import { useEffect } from 'react';

export function useDocumentVisibilityEffect(
    onVisibilityChange: (visibilityState: DocumentVisibilityState) => void,
    deps?: ReadonlyArray<unknown>,
) {
    useEffect(() => {
        const eventListener = () => {
            onVisibilityChange(document.visibilityState);
        };
        document.addEventListener('visibilitychange', eventListener);
        return () =>
            document.removeEventListener('visibilitychange', eventListener);
    }, deps ?? []);
}
