import { css } from '@emotion/css';

import { InputContext } from './InputContext';
import * as Hint from './internal/Hint';
import * as Input from './internal/Input';
import * as InputError from './internal/InputError';
import * as Label from './internal/Label';
import * as Underline from './internal/Underline';
import React from 'react';
import { ClickableFactory } from 'dg-web-shared/ui/Clickable';

export interface Props {
    errorText?: React.ReactChild | null;
    labelText?: React.ReactChild;
    hintText?: React.ReactChild;
    maxChars?: number;
    context?: InputContext;
    autoCapitalize?: string;
    autoCorrect?: string;
    name?: string;
    autocomplete?: string;
    onChange: ((value: string) => void) | undefined;
    onEnter?: () => void;
    onBlur?: () => void;
    onFocus?: () => void;
    inputType?: string;
    value: string | null;
    key?: string;
    tabIndex?: number;
    inputStyle?: any;
    useAutoCorrectInput?: boolean;
    readOnly?: boolean | undefined;
    autoFocus?: boolean;
    required?: boolean;
}

interface State {
    focused: boolean;
}

export function hintIsVisible(props: Props, focused: boolean): boolean {
    if (!props.hintText) {
        return false;
    }
    if (props.value) {
        return false;
    }
    if (props.labelText && !focused) {
        return false;
    }
    return true;
}

export function getUnderlineStatus(
    focused: boolean,
    errorText: React.ReactChild | null,
): Underline.UnderlineStatus {
    if (errorText) {
        return Underline.UnderlineStatus.error;
    }

    return focused
        ? Underline.UnderlineStatus.focus
        : Underline.UnderlineStatus.blur;
}

export class TextField extends React.Component<Props, State> {
    static displayName = 'TextField';
    static defaultProps: Props = {
        context: InputContext.form,
        labelText: '',
        hintText: '',
        errorText: '',
        maxChars: 50,
        inputType: 'text',
        value: null,
        onChange: undefined,
    };

    refs: any;

    constructor(props: Props) {
        super(props);
        this.state = { focused: false };
    }

    handleClick(): void {
        if (!this.state.focused) {
            this.refs.input.focus();
            this.setState({ focused: true });
        }
    }

    componentDidMount() {
        if (this.props.autoFocus === true) {
            if (this.refs.input) {
                this.refs.input.focus();
            }
        }
    }

    render() {
        return ClickableFactory(
            {
                element: 'div',
                className: css({
                    marginTop: '8px',
                    display: 'block',
                    position: 'relative',
                }),
                onClick: (): void => this.handleClick(),
            },
            Label.Factory({
                text: this.props.labelText || '',
                context: this.props.context || InputContext.form,
                focused: this.state.focused,
                elevated: this.state.focused || !!this.props.value,
            }),
            Hint.Factory({
                visible: hintIsVisible(this.props, this.state.focused),
                context: this.props.context || InputContext.form,
                text: this.props.hintText || '',
            }),
            Input.Factory({
                ref: 'input' as any, // TODO: Fix with react upgrade
                inputType: this.props.inputType || 'text',
                context: this.props.context || InputContext.form,
                maxChars: this.props.maxChars || 40,
                autoCapitalize: this.props.autoCapitalize,
                autoCorrect: this.props.autoCorrect,
                name: this.props.name,
                autocomplete: this.props.autocomplete,
                autoFocus: this.props.autoFocus,
                disabled: this.props.readOnly,
                required: this.props.required,
                onBlur: () => {
                    this.setState({ focused: false });
                    if (this.props.onBlur) {
                        this.props.onBlur();
                    }
                },
                onFocus: () => {
                    this.setState({ focused: true });
                    if (this.props.onFocus) {
                        this.props.onFocus();
                    }
                },
                onChange:
                    this.props.onChange ||
                    ((): null => {
                        return null;
                    }),
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>): void => {
                    if (this.props.onEnter && e.key === 'Enter') {
                        this.props.onEnter();
                    }
                },
                value: this.props.value || '',
                tabIndex: this.props.tabIndex,
                style: this.props.inputStyle,
                useAutoCorrectInput: this.props.useAutoCorrectInput,
            }),
            !this.props.readOnly &&
                Underline.Factory({
                    status: getUnderlineStatus(
                        this.state.focused,
                        this.props.errorText || null,
                    ),
                    context: this.props.context || InputContext.form,
                }),
            InputError.Factory({
                context: this.props.context || InputContext.form,
                text: this.props.errorText || '',
            }),
        );
    }
}

export const Factory = React.createFactory<Props>(TextField);
