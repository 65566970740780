import * as Flux from 'dg-web-shared/lib/Flux';

export interface State {
    viewportWidth: number;
    viewportHeight: number;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'layout-Layout';
    }
    getInitialState(): State {
        return {
            viewportWidth: 0,
            viewportHeight: 0,
        };
    }
    sideEffects(): void {
        return;
    }
    writeViewport(viewportWidth: number, viewportHeight: number): void {
        this.set((s: State): State => {
            s.viewportWidth = viewportWidth;
            s.viewportHeight = viewportHeight;
            return s;
        });
    }
}

export namespace Mobile {
    export interface State {
        transactionSlideinOpen: boolean;
        accountSlideinOpen: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'layout/LayoutState.Mobile',
        {
            transactionSlideinOpen: false,
            accountSlideinOpen: false,
        },
    );
}
