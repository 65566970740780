import { useStore } from 'dg-web-shared/lib/Flux';
import { languageFromString } from 'dg-web-shared/lib/Localized';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import { SpinnerCanvas } from '../../../../../pc-ui/md/layout/SpinnerCanvas';
import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as SettingsState from '../../../common/state/SettingsState';
import { showReminderSlideIn } from '../../current-login/actions/CurrentLoginActions';
import { CurrentLogin } from '../../current-login/components/CurrentLogin';
import { OtherLoginsMenu } from '../../logins/OtherLoginsMenu';
import { Meta } from '../../meta/components/Meta';
import * as MetaServerState from '../../meta/state/MetaServerState';
import { PaymentMethodMenu } from '../../payment-method/PaymentMethodMenu';
import { MonthlyReportsMenu } from '../../statement/MonthlyReportsMenu';
import { TopUpBalanceMenu } from '../../top-up-balance/TopUpBalanceMenu';
import { VehiclesMenu } from '../../vehicles/VehiclesMenu';
import { getBridge } from '../../../Native';
import { ChooseEnv } from './ChooseEnv';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import { PaymentMethodEnum } from '../../../api/Http';
import { MainMenuItemBlock } from './ItemBlock';
import { VoucherTopUp } from '../../top-up-balance/VoucherTopUp';
import { InvoicesMenu } from '../../invoices/InvoicesMenu';
import { Localized } from '../../../common/components/Localized';
import { useEffect } from 'react';

const getActiveReminderCount = (
    cl: Maybe<CurrentLoginState.CurrentLogin>,
    creditIsAllowed: boolean,
): number => {
    let count = 0;
    if (cl) {
        if (
            cl.device !== null &&
            cl.device.fcmToken !== null &&
            !!cl.device.status
        ) {
            count += 1;
        }
        if (cl.runningTransactionsSummaryEnabled) {
            count += 1;
        }
        if (cl.sendRunningTransactionsAutoTerminate) {
            count += 1;
        }
        if (cl.balanceWarningEnabled && !creditIsAllowed) {
            count += 1;
        }
        if (cl.newsletterStatus === 'SUBSCRIBED') {
            count += 1;
        }
    }
    return count;
};

type ReminderProps = {
    cl: Maybe<CurrentLoginState.CurrentLogin>;
    language: string;
};

export const Reminder = (p: ReminderProps): JSX.Element => {
    const { storeState } = useStore(s => ({
        balance: new AccountBalanceState.StateSlice(s).state,
        allState: s,
    }));

    const activeReminderCount = getActiveReminderCount(
        p.cl,
        storeState.balance.data.creditIsAllowed,
    );

    return (
        <MainMenuItemBlock
            title={
                <Localized
                    de="Benachrichtigungen"
                    fr="Notifications"
                    it="Notifiche"
                    en="Notifications"
                />
            }
            subTitle={
                activeReminderCount === 0 ? (
                    '—'
                ) : (
                    <Localized
                        de={`${activeReminderCount} aktive`}
                        fr={`${activeReminderCount} actif${
                            activeReminderCount > 1 ? 's' : ''
                        }`}
                        it={`${activeReminderCount} ${
                            activeReminderCount === 1 ? 'attiva' : 'attive'
                        }`}
                        en={`${activeReminderCount} active`}
                    />
                )
            }
            onClick={() =>
                storeState.allState.legacyUpdater(showReminderSlideIn)
            }
        />
    );
};

export function AccountAdmin() {
    const { storeState, update } = useStore(store => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            currentLogin: CurrentLoginState.get(store),
            paymentType: new PaymentTypeState.StateSlice(store).state,
            // Prefetching state
            metaServer: new MetaServerState.StateSlice(store).state,
            accountBalance: new AccountBalanceState.StateSlice(store).state,
        };
    });

    useEffect(() => {
        // Makes sure we refetch everytime we open the slide in
        if (!storeState.accountBalance.pending) {
            update(s => {
                new AccountBalanceState.StateSlice(s).reset();
                return 'reset-payment-type-and-account-balance-state';
            });
        }
    }, []);

    const canChooseEnv =
        storeState.currentLogin.data?.canChooseEnv && getBridge()?.appEnv;
    const activePaymentType =
        storeState.paymentType.data.activePaymentMethod.activePaymentType;
    const hasInvoices = storeState.metaServer.data.hasInvoices;
    const accountBalance = storeState.accountBalance.data.balance;
    const topupIsAllowedBecauseOfNegativeSaldo =
        (activePaymentType === PaymentMethodEnum.DIRECT ||
            activePaymentType === PaymentMethodEnum.LSV) &&
        accountBalance < 0;
    const language = languageFromString(storeState.settings.language);

    return (
        <SpinnerCanvas
            showSpinner={
                storeState.accountBalance.pending ||
                storeState.metaServer.pending
            }
        >
            <CurrentLogin />
            <Reminder
                language={storeState.settings.language}
                cl={storeState.currentLogin.data}
            />
            <Meta />
            <PaymentMethodMenu />
            {(hasInvoices ||
                activePaymentType === PaymentMethodEnum.INVOICE) && (
                <InvoicesMenu />
            )}
            {(activePaymentType === PaymentMethodEnum.PREPAID ||
                topupIsAllowedBecauseOfNegativeSaldo) && (
                <TopUpBalanceMenu language={language} />
            )}
            <MonthlyReportsMenu
                language={language}
                accountBalance={storeState.accountBalance.data}
            />
            <VehiclesMenu />
            <VoucherTopUp />
            <OtherLoginsMenu />
            {canChooseEnv && <ChooseEnv />}
        </SpinnerCanvas>
    );
}
