import { css } from '@emotion/css';

import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { LoginEmail } from '../logins/RequestedEmailReview';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';

export const RequestedEmailReviewSlideIn = portalSlideIn<{
    currentLogin: CurrentLoginState.CurrentLogin;
}>(RequestedEmailReview);

function RequestedEmailReview(props: {
    portal: SlideInPortalId;
    onClose: () => void;
    currentLogin: CurrentLoginState.CurrentLogin;
}) {
    const resendUrl =
        '/ui-api/customer-account/current-login/resend-requested-email-confirmation';

    return (
        <div
            className={css({
                padding: '24px',
                ...Typo.robotoRegular,
                fontSize: '16px',
                lineHeight: '22px',
                color: Colors.action_f,
            })}
        >
            <TextField
                context={InputContext.form}
                value={props.currentLogin.email}
                readOnly={true}
                labelText={
                    <Localized
                        de="Aktuelle E-Mail-Adresse"
                        fr="Adresse e-mail actuelle"
                        it="Indirizzo e-mail attuale"
                        en="Current e-mail address"
                    />
                }
            />

            <TextField
                context={InputContext.form}
                value={props.currentLogin.requestedEmail}
                readOnly={true}
                labelText={
                    <Localized
                        de="Neue E-Mail-Adresse"
                        fr="Nouvelle adresse e-mail"
                        it="Nuovo indirizzo e-mail"
                        en="New e-mail address"
                    />
                }
            />

            <div>
                <Localized
                    de="Sie haben Ihre neue E-Mail-Adresse noch nicht bestätigt; dafür hatten wir Ihnen ein Bestätigungslink per E-Mail geschickt."
                    fr="Vous n'avez pas encore confirmé votre nouvelle adresse e-mail; nous vous avons envoyé par e-mail le lien de confirmation."
                    it="Non ha ancora confermato il suo nuovo indirizzo e-mail; a questo scopo le abbiamo inviato per e-mail il link di conferma."
                    en="You have not yet confirmed your new e-mail address; for this purpose we have sent you by e-mail the confirmation link."
                />
            </div>

            <Localized
                de={
                    <p>
                        Sollten Sie diese E-Mail noch nicht erhalten haben,
                        überprüfen Sie in Ihrem E-Mail-Programm den Spam-Ordner.
                        Ansonsten können Sie hier{' '}
                        <LoginEmail.ResendLink
                            httpPostUrl={resendUrl}
                            onSuccess={props.onClose}
                            portal={props.portal}
                        >
                            einen neuen Bestätigungslink anfordern.
                        </LoginEmail.ResendLink>
                    </p>
                }
                fr={
                    <p>
                        Si vous n'avez pas encore reçu cet e-mail, veuillez
                        également vérifier votre dossier spam dans le programme
                        de messagerie. Sinon, vous pouvez
                        <LoginEmail.ResendLink
                            httpPostUrl={resendUrl}
                            onSuccess={props.onClose}
                            portal={props.portal}
                        >
                            demander un nouveau lien de confirmation ici.
                        </LoginEmail.ResendLink>
                    </p>
                }
                it={
                    <p>
                        Se non ha ancora ricevuto questo e-mail, verifichi per
                        favore la sua cartella spam (posta indesiderata) nel
                        programma di posta elettronica. Altrimenti può
                        richiedere qui{' '}
                        <LoginEmail.ResendLink
                            httpPostUrl={resendUrl}
                            onSuccess={props.onClose}
                            portal={props.portal}
                        >
                            un nuovo link di conferma.
                        </LoginEmail.ResendLink>
                    </p>
                }
                en={
                    <p>
                        Please also check your spam folder in your e-mail
                        program, if you have not received this e-mail yet.
                        Otherwise, you can{' '}
                        <LoginEmail.ResendLink
                            httpPostUrl={resendUrl}
                            onSuccess={props.onClose}
                            portal={props.portal}
                        >
                            request here a new confirmation link.
                        </LoginEmail.ResendLink>
                    </p>
                }
            />

            <div className={css({ textAlign: 'right', width: '100%' })}>
                <LoginEmail.CancelChangeButton
                    httpPostUrl="/ui-api/customer-account/current-login/requested-email-cancel"
                    portal={props.portal}
                    onSuccess={props.onClose}
                />
            </div>
        </div>
    );
}
