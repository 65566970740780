import * as Flux from 'dg-web-shared/lib/Flux';
import { isUndefined } from 'dg-web-shared/lib/MaybeV2';
import { languageFromString } from 'dg-web-shared/lib/Text';
import { Localized } from '../../../common/components/Localized';
import * as Text from '../../../common/i18n/Text';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { GeolocationState } from '../../../common/state/GeolocationState';
import * as SettingsState from '../../../common/state/SettingsState';
import {
    FooterMobile,
    FooterMobileContainer,
    FooterMobileLanguageSwitchContainer,
    FooterMobileNavigationClickableItem,
    FooterMobileNavigationContainer,
} from '../../../layout/components/footer/FooterMobile';
import * as LayoutState from '../../../layout/state/LayoutState';
import { columnsVariant } from '../../../layout/utils/columnsVariant';
import { ResponsiveProperties } from '../../../layout/utils/ResponsiveProperties';
import { responsiveVariant } from '../../../layout/utils/responsiveVariant';
import { LanguageSwitch } from '../../../shared/components/LanguageSwitch';
import {
    CurrentLoginFormsSlideIn,
    CurrentLoginFormsVisibilityStateSlice,
    toggleCurrentLoginFormsVisibility,
} from '../../current-login/CurrentLoginForms';
import {
    MetaFormsSlideIn,
    MetaFormsVisibilityStateSlice,
    toggleMetaFormsVisibility,
} from '../../meta/MetaForms';
import * as MetaServerState from '../../meta/state/MetaServerState';
import * as AccountTexts from '../i18n/AccountTexts';
import * as SlideInState from '../state/SlideInState';
import { AccountAdmin } from './AccountAdmin';
import * as AccountRestricted from './AccountRestricted';
import { FAQSlideIn } from './FAQ';
import { SlideInPortalId } from './PortalSlidein';
import { SlideInContainer } from './SlideInContainer';
import { css } from '@emotion/css';
import { paper } from '../../../../../tb-ui/paper';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../../tb-ui/typo';
import { TosLink } from '../../../legal/TosFiles';
import { PrivacyLink } from '../../../legal/PrivacyPolicyFiles';
import { useStore } from 'dg-web-shared/lib/Flux';

export interface Texts {
    Account: Text.Translation;
    Help: Text.Translation;
}

function AccountBlock({
    currentLogin,
}: {
    currentLogin: CurrentLoginState.CurrentLogin | null;
}): JSX.Element | null {
    if (isUndefined(currentLogin)) {
        return null;
    } else {
        if (currentLogin.loginRole === CurrentLoginState.LoginRole.ADMIN) {
            return <AccountAdmin />;
        } else if (
            currentLogin.loginRole === CurrentLoginState.LoginRole.RESTRICTED
        ) {
            return <AccountRestricted.AccountBlock />;
        }
    }
    return null;
}

export function Account() {
    const { storeState, update, store } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        currentLogin: CurrentLoginState.get(s),
        layout: new LayoutState.StateSlice(s).state,
        meta: new MetaServerState.StateSlice(s).state,
        about: SlideInState.About.get(s),
        geo: GeolocationState.get(s),
        currentLoginFormsVisibility: new CurrentLoginFormsVisibilityStateSlice(
            s,
        ).state,
        metaFormsVisibility: new MetaFormsVisibilityStateSlice(s).state,
    }));

    const oneColumnVariant =
        columnsVariant(storeState) === 'OneColumn'
            ? {
                  ...paper(0),
                  borderRadius: 0,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  width: '100%',
              }
            : {};

    const responsiveVariantMobile =
        responsiveVariant(storeState) === 'Mobile'
            ? { bottom: '48px', top: 0 }
            : {};

    const responsiveProps = new ResponsiveProperties(storeState);
    const texts = AccountTexts.texts[storeState.settings.language];
    const linkStyle = css({ color: 'white', textDecoration: 'none' });

    const disabled =
        storeState.meta.data && !storeState.meta.data.registrationComplete;

    return (
        <div
            className={css({
                position: 'relative',
                height: '100%',
                ...paper(2),
                borderRadius: '2px',
                overflow: 'hidden', // force border radius upon children
                opacity: disabled ? 0.6 : 1,
                ...oneColumnVariant,
            })}
        >
            {!responsiveProps.mobile && (
                <div
                    className={css({
                        height: '48px',
                        paddingTop: `${14 - Typo.heading1TopCorrection}px`,
                        paddingLeft: '24px',
                        ...Typo.heading1,
                        background: Colors.darkblue,
                        color: Colors.white,
                    })}
                >
                    {texts.Account()}
                </div>
            )}
            <div
                className={css({
                    background: Colors.white,
                    position: 'absolute',
                    top: '48px',
                    bottom: 0,
                    width: '100%',
                    ...responsiveVariantMobile,
                })}
            >
                {!disabled && (
                    <div
                        className={css({
                            position: 'relative',
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: 'scroll',
                            overflowScrolling: 'touch',
                            WebkitOverflowScrolling: 'touch',
                        })}
                    >
                        <AccountBlock
                            currentLogin={storeState.currentLogin.data}
                        />
                    </div>
                )}

                <CurrentLoginFormsSlideIn
                    portal={SlideInPortalId.USER_ACCOUNT}
                    open={storeState.currentLoginFormsVisibility.expanded}
                    onClose={() => {
                        store.legacyUpdater(toggleCurrentLoginFormsVisibility);
                    }}
                    title={
                        <Localized
                            de="Benutzerprofil"
                            fr="Profil de l'utilisateur"
                            it="Profilo utente"
                            en="User profile"
                        />
                    }
                    currentLogin={storeState.currentLogin}
                    allState={store}
                />

                <MetaFormsSlideIn
                    portal={SlideInPortalId.USER_ACCOUNT}
                    open={storeState.metaFormsVisibility.expanded}
                    onClose={() => {
                        store.legacyUpdater(toggleMetaFormsVisibility);
                    }}
                    title={
                        <Localized
                            de="Konto / Adresse"
                            fr="Compte / Adresse"
                            it="Conto / Indirizzo"
                            en="Account / Address"
                        />
                    }
                    allState={store}
                />
            </div>

            {responsiveProps.mobile && (
                <FooterMobileContainer>
                    <FooterMobile>
                        <FooterMobileNavigationContainer>
                            <FooterMobileNavigationClickableItem
                                title={AccountTexts.texts[
                                    storeState.settings.language
                                ].Help()}
                                onClick={() =>
                                    update(SlideInState.About.stateWrite, {
                                        slideinOpen: true,
                                    })
                                }
                            />

                            <FooterMobileNavigationClickableItem
                                title={
                                    <TosLink className={linkStyle}>
                                        <Localized
                                            de="AGB"
                                            fr="Conditions générales"
                                            it="Condizioni generali"
                                            en="Terms of service"
                                        />
                                    </TosLink>
                                }
                            />

                            <FooterMobileNavigationClickableItem
                                title={
                                    <PrivacyLink className={linkStyle}>
                                        <Localized
                                            de="Privacy"
                                            fr="Privacy"
                                            it="Privacy"
                                            en="Privacy"
                                        />
                                    </PrivacyLink>
                                }
                            />
                        </FooterMobileNavigationContainer>
                        <FooterMobileLanguageSwitchContainer>
                            <LanguageSwitch />
                        </FooterMobileLanguageSwitchContainer>
                    </FooterMobile>
                </FooterMobileContainer>
            )}
            {!responsiveProps.mobile && <AccountSlideins allState={store} />}
        </div>
    );
}

interface AccountSlideinsState {
    layout: LayoutState.State;
    about: SlideInState.About.State;
    settings: SettingsState.State;
}

export class AccountSlideins extends Flux.Container<AccountSlideinsState> {
    stateSelector(): AccountSlideinsState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
            about: SlideInState.About.get(this.props.allState),
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const language = languageFromString(this.state.settings.language);

        return (
            <div id={SlideInPortalId.USER_ACCOUNT}>
                <SlideInContainer {...this.props} />
                {new ResponsiveProperties(this.state).mobile ? (
                    <FAQSlideIn
                        portal={SlideInPortalId.USER_ACCOUNT}
                        title={AccountTexts.texts[language].Help()}
                        open={this.state.about.slideinOpen || false}
                        onClose={() =>
                            this.update(SlideInState.About.stateWrite, {
                                slideinOpen: false,
                            })
                        }
                        language={language}
                    />
                ) : null}
            </div>
        );
    }
}
