import {
    PaymentAttemptResponseParams,
    PaymentCategory,
    PaymentProviderType,
    providerTypeFromPaymentCategory,
} from './Payment';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import {
    RequestStatus,
    ServerRequestState,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { logAction } from '../../utils/ActionLog';
import * as superagent from 'superagent';
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import TwintUoFStartResponse = StoreAliasRequest.TwintUoFStartResponse;
import RedirectStartResponse = StoreAliasRequest.RedirectStartResponse;

export namespace StoreAliasRequest {
    export interface Payload {
        paymentCategory: PaymentCategory;
        uiPaymentOrigin: UiPaymentOrigin;
        useAlias: boolean;
        language: string;
        ticketId?: string;
    }

    export function use(config: {
        onOpenTwint: (() => void) | null;
    }): [
        ServerRequestState<unknown, unknown>,
        (payload: Payload) => void,
        () => void,
    ] {
        const [stateCreditCard, writeCreditCard, resetCreditCard] =
            useCreditCard();
        const [stateTwint, writeTwint, resetTwint] = useTwint(
            config.onOpenTwint,
        );

        return [
            deriveState(stateCreditCard, stateTwint),
            (payload: Payload) => {
                switch (
                    providerTypeFromPaymentCategory(payload.paymentCategory)
                ) {
                    case PaymentProviderType.DATATRANS:
                        return writeCreditCard(payload);
                    case PaymentProviderType.TWINT:
                        return writeTwint(payload);
                }
            },
            () => {
                resetCreditCard();
                resetTwint();
            },
        ];
    }

    const deriveState = (
        stateCreditCard: ServerRequestState<unknown, unknown>,
        stateTwint: ServerRequestState<unknown, unknown>,
    ): ServerRequestState<unknown, unknown> => {
        if (
            stateCreditCard.status === RequestStatus.NEVER_EXECUTED &&
            stateTwint.status === RequestStatus.NEVER_EXECUTED
        ) {
            return { status: RequestStatus.NEVER_EXECUTED, data: null };
        } else if (
            stateCreditCard.status === RequestStatus.PENDING ||
            stateTwint.status === RequestStatus.PENDING
        ) {
            return { status: RequestStatus.PENDING, data: null };
        } else if (
            stateCreditCard.status === RequestStatus.SUCCESS ||
            stateTwint.status === RequestStatus.SUCCESS
        ) {
            return { status: RequestStatus.SUCCESS, data: {} };
        }

        const statusCode =
            stateTwint.status === RequestStatus.ERROR
                ? stateTwint.httpStatusCode
                : stateCreditCard.status === RequestStatus.ERROR
                  ? stateCreditCard.httpStatusCode
                  : 0;

        return {
            status: RequestStatus.ERROR,
            data: null,
            httpStatusCode: statusCode,
        };
    };

    const useCreditCard = () => {
        const update = useUpdate();
        return useServerWrite<Payload, PaymentAttemptResponseParams, Error>({
            req: (p: Payload) => {
                return superagent
                    .post('/ui-api/customer-account/deposits/store-alias')
                    .send({
                        uiPaymentOrigin: p.uiPaymentOrigin,
                        paymentCategory: p.paymentCategory,
                        baseUri: `${window.location.protocol}//${window.location.host}`,
                        ticketId: p.ticketId,
                        language: p.language,
                    });
            },
            onResponse: (current, payload) => {
                if (current.status === RequestStatus.SUCCESS) {
                    const data = current.data;
                    if (
                        data.paymentProviderType ===
                        PaymentProviderType.DATATRANS
                    ) {
                        update(store => {
                            logAction(store, 'store-alias-attempt', {
                                ...data,
                            });
                            return 'log-action';
                        });

                        window.location.href = data.uri;
                    }
                }
            },
        });
    };

    export interface TwintUoFStartResponse {
        aliasId: number;
    }

    export interface TokenStartResponse extends TwintUoFStartResponse {
        token: string;
    }

    export interface RedirectStartResponse extends TwintUoFStartResponse {
        twintUrl: string;
    }

    const useTwint = (onOpenTwint: (() => void) | null) => {
        const update = useUpdate();
        return useServerWrite<Payload, TwintUoFStartResponse, Error>({
            req: (p: Payload) => {
                return superagent
                    .post(
                        '/ui-api/customer-account/twint-alias/start-registration',
                    )
                    .send({
                        uiPaymentOrigin: p.uiPaymentOrigin,
                        baseUri: `${window.location.protocol}//${window.location.host}`,
                        ticketId: p.ticketId,
                    });
            },
            onResponse: (current, payload) => {
                if (current.status === RequestStatus.SUCCESS) {
                    update(store => {
                        logAction(store, 'twint-uof-start', {
                            payload,
                        });

                        if (isRedirectResponse(current.data)) {
                            window.location.href = current.data.twintUrl;
                        }

                        return 'log-action';
                    });
                }
            },
        });
    };
}

function isRedirectResponse(
    response: TwintUoFStartResponse,
): response is RedirectStartResponse {
    return (response as RedirectStartResponse).twintUrl !== undefined;
}
