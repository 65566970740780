import * as Flux from 'dg-web-shared/lib/Flux';
import * as CityDropinState from '../state/CityDropinState';
import * as ZoneDropinState from '../state/ZoneDropinState';
import { Geolocation } from 'dg-web-shared/common/utils/Geolocation';
import { GeolocationState } from '../../common/state/GeolocationState';

export namespace CityDropin {
    export function open(store: Flux.Store): string {
        new CityDropinState.StateSlice(store).setOpen(true);
        if (
            Geolocation.hasGeolocation() &&
            !GeolocationState.isError(GeolocationState.get(store))
        ) {
            new CityDropinState.StateSlice(store).setSortField(
                CityDropinState.SortField.Distance,
            );
            new CityDropinState.StateSlice(store).setSortAscending(true);
            new CityDropinState.StateSlice(
                store,
            ).setPositionAcquisitionCanceled(false);
        }
        return 'open';
    }

    export function close(store: Flux.Store): string {
        new CityDropinState.StateSlice(store).setFilterValue('');
        new CityDropinState.StateSlice(store).setOpen(false);
        return 'CloseCityDropin';
    }

    export function sortByZipcode(store: Flux.Store): string {
        const cityDropin = new CityDropinState.StateSlice(store);
        if (cityDropin.state.sortField !== CityDropinState.SortField.Zipcode) {
            cityDropin.setSortField(CityDropinState.SortField.Zipcode);
            cityDropin.setSortAscending(true);
        } else {
            cityDropin.toggleSortAscending();
        }
        return 'sortByZipcode';
    }

    export function sortByCity(store: Flux.Store): string {
        const cityDropin = new CityDropinState.StateSlice(store);
        if (cityDropin.state.sortField !== CityDropinState.SortField.City) {
            cityDropin.setSortField(CityDropinState.SortField.City);
            cityDropin.setSortAscending(true);
        } else {
            cityDropin.toggleSortAscending();
        }
        return 'sortByCity';
    }

    export function sortByDistance(store: Flux.Store): string {
        const cityDropin = new CityDropinState.StateSlice(store);
        if (cityDropin.state.sortField !== CityDropinState.SortField.Distance) {
            cityDropin.setSortField(CityDropinState.SortField.Distance);
            cityDropin.setSortAscending(true);
        } else {
            cityDropin.toggleSortAscending();
        }
        return 'sortByDistance';
    }

    export function setFilterValue(
        store: Flux.Store,
        filterValue: string,
    ): string {
        new CityDropinState.StateSlice(store).setFilterValue(filterValue);
        return 'setFilterValue';
    }

    export function clearFilterValue(store: Flux.Store): string {
        new CityDropinState.StateSlice(store).setFilterValue('');
        return 'clearFilterValue';
    }
}

export namespace ZoneDropin {
    export function open(store: Flux.Store): string {
        const location = GeolocationState.get(store);
        new ZoneDropinState.StateSlice(store).setOpen(true);
        if (
            Geolocation.hasGeolocation() &&
            GeolocationState.hasPosition(location)
        ) {
            new ZoneDropinState.StateSlice(store).setSortField(
                ZoneDropinState.SortField.Distance,
            );
            new ZoneDropinState.StateSlice(store).setSortAscending(true);
        }
        return 'open';
    }

    export function close(store: Flux.Store): string {
        new ZoneDropinState.StateSlice(store).setFilterValue('');
        new ZoneDropinState.StateSlice(store).setOpen(false);
        return 'close';
    }

    export function sortByZoneId(store: Flux.Store): string {
        const zoneDropin = new ZoneDropinState.StateSlice(store);
        if (zoneDropin.state.sortField !== ZoneDropinState.SortField.ZoneId) {
            zoneDropin.setSortField(ZoneDropinState.SortField.ZoneId);
            zoneDropin.setSortAscending(true);
        } else {
            zoneDropin.toggleSortAscending();
        }
        return 'sortByZoneId';
    }

    export function sortByZoneName(store: Flux.Store): string {
        const zoneDropin = new ZoneDropinState.StateSlice(store);
        if (zoneDropin.state.sortField !== ZoneDropinState.SortField.ZoneName) {
            zoneDropin.setSortField(ZoneDropinState.SortField.ZoneName);
            zoneDropin.setSortAscending(true);
        } else {
            zoneDropin.toggleSortAscending();
        }
        return 'sortByZoneName';
    }

    export function setFilterValue(
        store: Flux.Store,
        filterValue: string,
    ): string {
        new ZoneDropinState.StateSlice(store).setFilterValue(filterValue);
        return 'setFilterValue';
    }

    export function clearFilterValue(store: Flux.Store): string {
        new ZoneDropinState.StateSlice(store).setFilterValue('');
        return 'clearFilterValue';
    }
}
