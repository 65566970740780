import { Localized } from '../../../common/components/Localized';
import { InlineErrorBox } from '../../../ui/modals/Confirmable';
import { ReactNode } from 'react';

export function NoParkingpayUseTwintBlock({
    show,
}: {
    show: boolean;
    children?: ReactNode;
}) {
    if (!show) {
        return null;
    }

    return (
        <>
            <InlineErrorBox
                titleCaption={
                    <Localized
                        de="Nicht verfügbar"
                        fr="Pas disponible"
                        it="Non disponibile"
                        en="Not available"
                    />
                }
            >
                <div>
                    <Localized
                        de={
                            <>
                                <p>
                                    Parkingpay ist auf dieser Parkzone nicht
                                    verfügbar.
                                </p>
                                <p>
                                    Wir empfehlen Ihnen, auf die TWINT-App zu
                                    wechseln; mit der Parking-Funktion von
                                    TWINT+ können Sie weiterhin bequem bezahlen.
                                </p>
                            </>
                        }
                        fr={
                            <>
                                <p>
                                    Parkingpay n'est pas disponible dans cette
                                    zone de stationnement.
                                </p>
                                <p>
                                    Nous vous recommandons de passer à l' app
                                    TWINT ; vous pouvez continuer à payer
                                    confortablement avec la fonction parking de
                                    TWINT+.
                                </p>
                            </>
                        }
                        it={
                            <>
                                <p>
                                    Parkingpay non è disponibile in questa zona
                                    di parcheggio.
                                </p>
                                <p>
                                    Le consigliamo di passare all’ app TWINT con
                                    la funzione Parking di TWINT+ potrà
                                    continuare a pagare comodamente.
                                </p>
                            </>
                        }
                        en={
                            <>
                                <p>
                                    Parkingpay isn't available in this parking
                                    zone.
                                </p>
                                <p>
                                    We recommend that you switch to the TWINT
                                    app you can continue to pay conveniently
                                    with the Parking function of TWINT+.
                                </p>
                            </>
                        }
                    />
                </div>
            </InlineErrorBox>
        </>
    );
}
