import {
    LeftColumn,
    RightColumn,
    StaticPage,
} from '../../pages/static/components/StaticPage';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../tb-ui/typo';
import { Localized } from '../../common/components/Localized';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../common/components/FullWidthBottomButton';
import { Helpdesk } from '../../pages/static/components/Helpdesk';
import { Address } from '../../pages/static/components/Address';

import { useStore } from 'dg-web-shared/lib/Flux';
import { useNavigate } from 'react-router-dom';

export function UnathorizedPage() {
    const { store } = useStore(() => null);
    const navigate = useNavigate();
    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <div
                    className={css({
                        maxWidth: '480px',
                        color: Colors.typo1,
                        ...Typo.body,
                    })}
                >
                    <p>
                        <Localized
                            de="Aufgrund langer Nichtbenutzung oder Passwortänderung wurde dieses Gerät automatisch abgemeldet."
                            fr="En raison d'une longue inactivité ou d'un changement de mot de passe, ce dispositif a été déconnecté automatiquement."
                            it="A causa delle lunga inattività o del cambiamento della password, questo apparecchio è stato disconnesso automaticamente."
                            en="Due to long inactivity or password change, this device has been disconnected automatically."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Bitte loggen Sie sich erneut ein."
                            fr="Veuillez vous reconnecter."
                            it="Voglia accedere nuovamente."
                            en="Please log in again."
                        />
                    </p>

                    <FullWidthBottomButton
                        color={FullWidthBottomButtonColor.BLUE}
                        label={{
                            de: 'Neu anmelden',
                            fr: 'Se reconnecter',
                            it: 'Accedi di nuovo',
                            en: 'Login again',
                        }}
                        onClick={() => {
                            navigate('/');
                        }}
                    />
                </div>
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}
