import { QuickCheckoutSlideIn } from '../common/QuickCheckoutSlideIn';
import {
    IndentedList,
    processZones,
} from 'dg-web-shared/common/components/IndentedList';

import {
    QuickCheckoutContent,
    QuickCheckoutHeader,
} from '../common/QuickCheckoutLayout';
import { Localized } from '../../common/components/Localized';
import { QuickCheckoutLabel } from '../common/QuickCheckoutUiComponents';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { usePermittypeFormOutletContext } from './QuickCheckoutPermittypeForm';
import { useState } from 'react';

export function QuickCheckoutPermittypeDetailsSlideIn() {
    const {
        permittype: {
            name,
            additionalInfo,
            zones,
            operatorName,
            operatorContactDataMarkdown,
            permitIsChangeable,
        },
    } = usePermittypeFormOutletContext();
    const [isOpen, setOpen] = useState(true);
    const entries = processZones(zones);
    const navigate = useNavigate();

    return (
        <QuickCheckoutSlideIn open={isOpen} onClosed={() => navigate('..')}>
            <QuickCheckoutHeader onClose={() => setOpen(false)}>
                <Localized
                    de="Bewilligungsdetails"
                    fr="Détails de l'autorisation"
                    it="Dettagli autorizzazione"
                    en="Permit details"
                />
            </QuickCheckoutHeader>
            <QuickCheckoutContent padding={'x-only'}>
                <QuickCheckoutLabel noPaddingTop>
                    <Localized
                        de="Beschreibung"
                        fr="Description"
                        it="Descrizione"
                        en="Description"
                    />
                </QuickCheckoutLabel>
                {name}
                {additionalInfo && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="Zusätzliche Informationen"
                                fr="Informations complémentaires"
                                it="Informazioni supplementari"
                                en="Additional information"
                            />
                        </QuickCheckoutLabel>
                        {additionalInfo}
                    </>
                )}
                {entries && (
                    <>
                        <QuickCheckoutLabel>
                            <Localized
                                de="Zonen"
                                fr="Zones"
                                it="Zone"
                                en="Zones"
                            />
                        </QuickCheckoutLabel>
                        <IndentedList entries={entries} />
                    </>
                )}
                <QuickCheckoutLabel>
                    <Localized
                        de="Betreiber"
                        fr="Exploitant"
                        it="Gestore"
                        en="Operator"
                    />
                </QuickCheckoutLabel>
                {permitIsChangeable && operatorContactDataMarkdown ? (
                    <div
                        className={css({
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all',
                            userSelect: 'text',
                        })}
                    >
                        {operatorName}
                        <br />
                        {operatorContactDataMarkdown}
                    </div>
                ) : (
                    operatorName
                )}
            </QuickCheckoutContent>
        </QuickCheckoutSlideIn>
    );
}
