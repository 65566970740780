export enum Language {
    DE = 'de',
    FR = 'fr',
    IT = 'it',
    EN = 'en',
}

export function languageFromString(s: string): Language {
    const mayBeLanguage: Language | undefined = (Language as any)[
        s.toUpperCase()
    ];

    return mayBeLanguage ? mayBeLanguage : Language.DE;
}

export function indexToLanguage(languageIndex: number): Language {
    switch (languageIndex) {
        case 0:
            return Language.DE;
        case 1:
            return Language.FR;
        case 2:
            return Language.IT;
        case 3:
            return Language.EN;
        default:
            return Language.DE;
    }
}

export function languageToIndex(language: Language): 0 | 1 | 2 | 3 {
    switch (language) {
        case Language.FR:
            return 1;

        case Language.IT:
            return 2;

        case Language.EN:
            return 3;

        default:
            return 0;
    }
}

export function localizedText(ltMap: {
    de: string;
    fr: string;
    it: string;
    en: string;
}): [string, string, string, string] {
    return [ltMap.de, ltMap.fr, ltMap.it, ltMap.en];
}

export function localizedTextMap(lt: [string, string, string, string]): {
    de: string;
    fr: string;
    it: string;
    en: string;
} {
    return {
        de: lt[languageToIndex(Language.DE)],
        fr: lt[languageToIndex(Language.FR)],
        it: lt[languageToIndex(Language.IT)],
        en: lt[languageToIndex(Language.EN)],
    };
}

export interface Translations<T> {
    [key: string]: T;
    de: T;
}

export type Translation = () => string;
export type TranslationWithArgs1<T> = (arg1: T) => string;
export type TranslationWithArgs2<T, S> = (arg1: T, arg2: S) => string;
export type TranslationWithArgs3<T, S, U> = (
    arg1: T,
    arg2: S,
    arg3: U,
) => string;
