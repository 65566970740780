import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { SpinnerColor } from 'dg-web-shared/ui/Spinner';

import { QuickCheckoutContent } from './QuickCheckoutLayout';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export function QuickCheckoutAsyncLoadedSection<D, ED>({
    state,
    renderSuccess,
    renderError,
    showSpinnerOnRefetch,
}: {
    state: ServerRequestState<D, ED>;
    renderSuccess: (data: D) => JSX.Element | null;
    renderError: (
        error: ED | null,
        httpStatusCode: number,
    ) => JSX.Element | null;
    showSpinnerOnRefetch?: boolean;
}): JSX.Element | null {
    switch (state.status) {
        case RequestStatus.NEVER_EXECUTED:
            return <FullscreenSpinner />;
        case RequestStatus.PENDING:
            if (!showSpinnerOnRefetch && state.data) {
                return renderSuccess(state.data);
            } else {
                return <FullscreenSpinner />;
            }
        case RequestStatus.ERROR:
            return renderError(state.data, state.httpStatusCode);
        case RequestStatus.SUCCESS:
            return renderSuccess(state.data);
    }
}

function FullscreenSpinner() {
    return (
        <QuickCheckoutContent>
            <Spinner color={SpinnerColor.WHITE} />
        </QuickCheckoutContent>
    );
}
