import styled from '@emotion/styled';
import { QuickCheckoutConstants } from './QuickCheckoutConstants';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../style/typo';
import { css, injectGlobal } from '@emotion/css';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24';
import { useInsertionEffect } from 'react';
import { Icon24 } from 'dg-web-shared/ui/icons/Icon';

const ViewportContainer = styled.div({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const AppContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: '768px',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    '@media (min-width: 768px)': { maxHeight: '1024px' },
    background: Colors.blackGradient,
    color: Colors.white,
    ...Typo.body,
});

export function QuickCheckoutLayout({
    children,
}: {
    children: React.ReactNode;
}) {
    useInsertionEffect(() => {
        injectGlobal`
        @media screen and (max-width: 768px) {
            body {
                background-color: ${Colors.blackGradientStart} !important;
            }
        }
    `;
    }, []);

    return (
        <ViewportContainer>
            <AppContainer>{children}</AppContainer>
        </ViewportContainer>
    );
}

export function QuickCheckoutHeader({
    children,
    onClose,
}: {
    children: React.ReactNode;
    onClose: () => void;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                justifyContent: 'space-between',
                padding: QuickCheckoutConstants.BASE_PADDING,
                overflow: 'hidden',
            })}
        >
            <div
                className={css({
                    ...Typo.robotoBold,
                    fontSize: '22px',
                    lineHeight: '24px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                })}
            >
                {children}
            </div>
            <div onClick={onClose} className={css({ cursor: 'pointer' })}>
                <Icon24 icon={Icons24.clear_b} />
            </div>
        </div>
    );
}

export const QuickCheckoutContent = styled.div<{
    centered?: boolean;
    padding?: 'x-only' | 'full';
}>(props => ({
    flex: 1,
    padding: `${props.padding === 'x-only' ? '0 ' : ''}${
        QuickCheckoutConstants.BASE_PADDING
    }`,
    paddingBottom: QuickCheckoutConstants.BASE_PADDING,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.centered ? 'center' : undefined,
    alignItems: props.centered ? 'center' : undefined,
    overflowX: 'hidden',
    overflowY: 'auto',
}));

export const QuickCheckoutFooter = styled.div({
    padding: QuickCheckoutConstants.BASE_PADDING,
    paddingTop: 0,
    boxShadow: '0px 7px 21px 12px rgba(0,0,0,0.74)',
    backgroundColor: Colors.darkgrey,
});
