import * as Flux from 'dg-web-shared/lib/Flux';
import { CustomerAccountType } from '../../../api/CustomerAccountType';
import * as SettingsState from '../../../common/state/SettingsState';
import * as MetaTexts from '../i18n/MetaTexts';
import * as MetaServerState from '../state/MetaServerState';
import { FieldItemBlock } from '../../root/components/ItemBlock';

export class AccountNr extends Flux.Container<ContainerState> {
    stateSelector(): ContainerState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            metaServer: new MetaServerState.StateSlice(this.props.allState)
                .state,
        };
    }

    render() {
        const texts = MetaTexts.accountNr[this.state.settings.language];

        return (
            <FieldItemBlock
                label={
                    texts.AccountNr() +
                    ' (' +
                    getAccountInfo(
                        this.state.metaServer.data.customerAccountType,
                        texts,
                    ) +
                    ')'
                }
                content={this.state.metaServer.data.customerNr.toString()}
                onClick={null}
            />
        );
    }
}

function getAccountInfo(
    customerAccountType: CustomerAccountType,
    texts: MetaTexts.AccountNrTexts,
): string {
    if (customerAccountType === CustomerAccountType.PRIVATE) {
        return texts.PrivateAccount();
    }

    return texts.CompanyAccount();
}

interface ContainerState {
    settings: SettingsState.State;
    metaServer: MetaServerState.State;
}
