export function stripNonNumericCharacters(value: string): string {
    return value.replace(/\D/g, '');
}

export function stripNonNumericCharactersAllowPointAndComma(
    value: string,
): string {
    return value.replace(/[^\d\.,]/g, '');
}

export function stripNonNumericCharactersAllowSpace(value: string): string {
    return value.replace(/[^\d\s]/g, '');
}

export function stripNonNumericCharactersAllowPlus(value: string): string {
    return value.replace(/[^\d\+]/g, '');
}

export function stripNonNumericCharactersAllowSpaceAndPlus(
    value: string,
): string {
    return value.replace(/[^\d\s\+]/g, '');
}

export function stripNonNumericAndNoLetterCharacters(value: string): string {
    return value.replace(/[^0-9a-zA-Z]/g, '');
}

export function stripNonNumericAndNoLetterCharactersAllowSpace(
    value: string,
): string {
    return value.replace(/[^0-9a-zA-Z\s]/g, '');
}

export function stripNonNumericAndNoLetterCharactersAllowUmlauts(
    value: string,
): string {
    return value.replace(/[^0-9a-zA-ZäöüÄÖÜ]/g, '');
}

// the value is expected to be a string containing only numbers
export function stringToFourCharacterBlocks(value: string): string {
    return (value.match(/.{1,4}/g) || []).join(' ');
}

// takes a string of the form "929979000000000000709846102" and returns
// the display format "9299 7900 0000 0000 0070 9846 102"
export const ibanToDisplayFormat = stringToFourCharacterBlocks;

export function refNumberToDisplayFormat(iban: string): string {
    return (
        iban.substr(0, 2) +
        ' ' +
        (iban.substr(2).match(/.{1,5}/g) || []).join(' ')
    );
}

export const removeWhitespace = (value: string) => value.replace(/\s+/g, '');

export const forceDecimal = (v: string, precision = 2) => {
    const m = v.match(new RegExp(`(\\d+)(([\\.,])(\\d{0,${precision}})?)?`));
    if (!m) {
        return '';
    } else {
        return m[1] + (m[3] || m[4] ? '.' + (m[4] ? m[4] : '') : '');
    }
};
