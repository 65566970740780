import * as Sentry from '@sentry/browser';
import * as superagent from 'superagent';
import { v4 as randomUuid } from 'uuid';
import { Store, Update, Write } from 'dg-web-shared/lib/Flux';
import {
    makePendingResponse,
    makeTimeoutResponse,
    Response,
    SuperagentResponse,
} from 'dg-web-shared/lib/HttpResponse';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';

export { Response } from 'dg-web-shared/lib/HttpResponse';

export type RawRequest = superagent.Request<any, any>;

export interface RequestWrite {
    (store: Store, res: Response): void;
}

export function makeResponse(
    err: any,
    req: RawRequest,
    sRes: superagent.Response<any>,
): Response {
    let res: Response;

    if (err && !sRes) {
        res = makeTimeoutResponse(req);
    } else {
        res = new SuperagentResponse(req, sRes);
    }
    if (res.statusCode.cls.serverError) {
        Sentry.withScope(scope => {
            scope.setContext('response body', {
                value: sRes.text || 'no body',
            });
            Sentry.captureMessage(
                `${res.request.url} ${res.statusCode.statusCode}`,
            );
        });
    }
    return res;
}

/*
this is the legacy request pattern, do not use this. Instead, use requestV2
@deprecated use useServerFetch
*/
export function request(req: RawRequest, requestUpdate: RequestWrite): Write {
    return (store: Store): void => {
        requestUpdate(store, makePendingResponse(req));
        req.end((err: any, superagentRes: superagent.Response<any>) => {
            const res = makeResponse(err, req, superagentRes);
            requestUpdate(store, res);
            store.emitChange();
        });
    };
}

/*
@deprecated use useServerFetch
 */
export function requestV2<V>(
    reqGen: (args?: Maybe<V>) => RawRequest,
    requestUpdate: RequestWrite,
): Update<V> {
    return (store: Store, args?: V): string => {
        const req = reqGen(args);
        req.set(IDEMPOTENCE_TOKEN_HTTP_HEADER_NAME, randomUuid());

        const pendingName =
            requestUpdate(store, makePendingResponse(req)) +
            '-requestPending-log-ignore';

        req.end((err: any, superagentRes: superagent.Response<any>) => {
            const res = makeResponse(err, req, superagentRes);
            store.update(
                (store: Store, res: Response) =>
                    requestUpdate(store, res) + '-requestReturned-log-ignore',
                res,
            );
        });

        return pendingName;
    };
}

export const IDEMPOTENCE_TOKEN_HTTP_HEADER_NAME = 'Parkingpay-Request-UUID';
