import { Message } from 'dg-web-shared/lib/Localized';
import { PermitDurationType } from 'dg-web-shared/common/models/PermitDurationType';

export type PermittypeSuccessResponse =
    | PermittypeRedirectSuccessResponse
    | PermittypeFormSuccessResponse;

export enum PermittypeSuccessResponseType {
    REDIRECT = 'REDIRECT',
    FORM = 'FORM',
}
export type PermittypeRedirectSuccessResponse = {
    type: PermittypeSuccessResponseType.REDIRECT;
    externalId: string;
};

export type PermittypeFormSuccessResponse = {
    type: PermittypeSuccessResponseType.FORM;
    permitUrlIdentification: string;
    name: string;
    additionalInfo?: string | null;
    zones: PermittypeZone[];
    licensePlateCountries: LicensePlateCountry[];
    operatorName: string;
    operatorContactDataMarkdown?: string | null;
    operatorEditPolicyText: Message;
    allowedDurations: PermittypeDuration[];
    permitIsChangeable: boolean;
    minimalDailyDuration24h: boolean;
    tosAndPrivacyPolicyAccepted: boolean;
};
export type PermittypeRequestContext = { permitUrlIdentification: string };
export type PermittypeErrorResponse = { state: PermittypeErrorState };

type PermittypeZone = {
    id: number;
    isBarrierGate: boolean;
    zipCode: string;
    city: string;
    name: string;
    extZoneCode: number;
};

export type PermittypeDuration = {
    type: PermitDurationType;
    quantity: number;
    price: number;
};

export type LicensePlateCountry = {
    id: string;
    name: Message;
};

export const enum PermittypeErrorState {
    NOT_FOUND = 'NOT_FOUND',
    NOT_ACTIVE = 'NOT_ACTIVE',
    NOT_ALLOWED = 'NOT_ALLOWED',
    NO_QUICKCHECKOUT_PERMIT = 'NO_QUICKCHECKOUT_PERMIT',
}

export const enum LicensePlateType {
    CAR = 0,
    MOTORCYCLE = 1,
}

export const enum PermitPurchaseFormFields {
    permitUrlIdentification = 'permitUrlIdentification',
    licensePlateNr = 'licensePlateNr',
    licensePlateType = 'licensePlateType',
    licensePlateCountry = 'licensePlateCountry',
    email = 'email',
    duration = 'duration',
    tosAndPrivacyPolicyAccepted = 'tosAndPrivacyPolicyAccepted',
}

export type PermitPurchaseFormValues = {
    [PermitPurchaseFormFields.permitUrlIdentification]: string;
    [PermitPurchaseFormFields.licensePlateNr]: string;
    [PermitPurchaseFormFields.licensePlateType]: LicensePlateType;
    [PermitPurchaseFormFields.licensePlateCountry]: string;
    [PermitPurchaseFormFields.email]: string;
    [PermitPurchaseFormFields.duration]: PermittypeDuration | null;
    [PermitPurchaseFormFields.tosAndPrivacyPolicyAccepted]: boolean;
};
