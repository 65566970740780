import {
    Translation,
    Translation1,
    Translations,
} from 'dg-web-shared/lib/Text';
import { BarrierGateLicensePlateCheckInOption } from 'dg-web-shared/common/models/Vehicle';

export interface VehicleTexts {
    country: Translation;
    vehicle: Translation;
    vehicles: Translation;
    vehiclesWithoutBadge: Translation;
    licensePlate: Translation;
    badge: Translation;
    badgeOrdered: Translation;
    editDescription: Translation;
    editLicensePlate: Translation;
    addBadge: Translation;
    addVehicle: Translation;
    delVehicle: Translation;
    replace: Translation;
    barrierGateLicensePlateCheckIn: Translation;
    barrierGateLicensePlateCheckInOption: Translation1<BarrierGateLicensePlateCheckInOption>;
}

export const vehicleTexts: Translations<VehicleTexts> = {
    de: {
        country: () => 'Land',
        vehicles: () => 'Fahrzeuge',
        vehiclesWithoutBadge: () => 'Fahrzeuge ohne badge',
        vehicle: () => 'Fahrzeug',
        licensePlate: () => 'Kennzeichen',
        badge: () => 'Badge',
        badgeOrdered: () => 'bestellt',
        editDescription: () => 'Beschreibung ändern',
        editLicensePlate: () => 'Kennzeichen ändern',
        addBadge: () => 'Badge hinzufügen',
        addVehicle: () => 'Fahrzeug hinzufügen',
        delVehicle: () => 'Fahrzeug löschen',
        replace: () => 'Ersetzen',
        barrierGateLicensePlateCheckIn: () => 'Kennzeichenerkennung',
        barrierGateLicensePlateCheckInOption(option) {
            switch (option) {
                case BarrierGateLicensePlateCheckInOption.ACTIVE:
                    return 'Aktiv (öffentliche Parkings)';
                case BarrierGateLicensePlateCheckInOption.INACTIVE:
                    return 'Inaktiv (öffentliche Parkings)';
                case BarrierGateLicensePlateCheckInOption.OCCUPIED:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_TOO_SHORT:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_MOTORCYCLE:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_DIPLOMATIC:
                    return 'Nicht aktivierbar (öffentliche Parkings)';
            }
        },
    },
    fr: {
        country: () => 'Pays',
        vehicles: () => 'Véhicules',
        vehiclesWithoutBadge: () => 'Véhicule sans badge',
        vehicle: () => 'Véhicule',
        licensePlate: () => 'Immatriculation',
        badge: () => 'Badge',
        badgeOrdered: () => 'commandé',
        editDescription: () => 'Modifier la description',
        editLicensePlate: () => "Modifier l'immatriculation",
        addBadge: () => 'Ajouter un badge',
        addVehicle: () => 'Ajouter véhicule',
        delVehicle: () => 'Effacer véhicule',
        replace: () => 'Remplacer',
        barrierGateLicensePlateCheckIn: () =>
            "Reconnaissance de l'immatriculation",
        barrierGateLicensePlateCheckInOption(option) {
            switch (option) {
                case BarrierGateLicensePlateCheckInOption.ACTIVE:
                    return 'Active (parkings publics)';
                case BarrierGateLicensePlateCheckInOption.INACTIVE:
                    return 'Inactive (parkings publics)';
                case BarrierGateLicensePlateCheckInOption.OCCUPIED:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_TOO_SHORT:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_MOTORCYCLE:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_DIPLOMATIC:
                    return 'Non activable (parkings publics)';
            }
        },
    },
    it: {
        country: () => 'Nazione',
        vehicles: () => 'Veicoli',
        vehiclesWithoutBadge: () => 'Veicoli senza badge',
        vehicle: () => 'Veicolo',
        licensePlate: () => 'Targa',
        badge: () => 'Badge',
        badgeOrdered: () => 'ordinato',
        editDescription: () => 'Modifica descrizione',
        editLicensePlate: () => 'Modifica targa',
        addBadge: () => 'Aggiungi badge',
        addVehicle: () => 'Aggiungi veicolo',
        delVehicle: () => 'Elimina veicolo',
        replace: () => 'Sostituisci',
        barrierGateLicensePlateCheckIn: () => 'Riconoscimento della targa',
        barrierGateLicensePlateCheckInOption(option) {
            switch (option) {
                case BarrierGateLicensePlateCheckInOption.ACTIVE:
                    return 'Attivo (parcheggi pubblici)';
                case BarrierGateLicensePlateCheckInOption.INACTIVE:
                    return 'Inattivo (parcheggi pubblici)';
                case BarrierGateLicensePlateCheckInOption.OCCUPIED:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_TOO_SHORT:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_MOTORCYCLE:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_DIPLOMATIC:
                    return 'Non attivabile (parcheggi pubblici)';
            }
        },
    },
    en: {
        country: () => 'Country',
        vehicles: () => 'Vehicles',
        vehiclesWithoutBadge: () => 'Vehicles without badge',
        vehicle: () => 'Vehicle',
        licensePlate: () => 'License plate',
        badge: () => 'Badge',
        badgeOrdered: () => 'ordered',
        editDescription: () => 'Edit description',
        editLicensePlate: () => 'Edit license plate',
        addBadge: () => 'Add badge',
        addVehicle: () => 'Add vehicle',
        delVehicle: () => 'Delete vehicle',
        replace: () => 'Replace',
        barrierGateLicensePlateCheckIn: () => 'License plate recognition',
        barrierGateLicensePlateCheckInOption(option) {
            switch (option) {
                case BarrierGateLicensePlateCheckInOption.ACTIVE:
                    return 'Active (public parkings)';
                case BarrierGateLicensePlateCheckInOption.INACTIVE:
                    return 'Inactive (public parkings)';
                case BarrierGateLicensePlateCheckInOption.OCCUPIED:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_TOO_SHORT:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_MOTORCYCLE:
                case BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_DIPLOMATIC:
                    return 'Not available (public parkings)';
            }
        },
    },
};
