interface HighlightPartialMatchParams {
    text: string;
    filterValue: string;
    className: string;
    filterActive: boolean;
}
export function highlightPartialMatch(
    p: HighlightPartialMatchParams,
): JSX.Element {
    const matchStart = p.text
        .toLowerCase()
        .indexOf(p.filterValue.toLowerCase());
    if (p.filterActive && matchStart > -1) {
        const matchEnd = matchStart + p.filterValue.length;
        const before = p.text.substring(0, matchStart);
        const match = p.text.substring(matchStart, matchEnd);
        const after = p.text.substring(matchEnd);
        return (
            <div key={`match-${match}`}>
                <span>
                    {before}
                    <span className={p.className}>{match}</span>
                    {after}
                </span>
            </div>
        );
    } else {
        return (
            <div key={`no-match-${p.text}`}>
                <span>{p.text}</span>
            </div>
        );
    }
}
