import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import { useState } from 'react';
import { Localized } from '../../common/components/Localized';

import { SlideInForm } from '../root/components/SlideInForm';
import { ToggleIcon } from './LprParkingsSelectionSlideIn';
import { css } from '@emotion/css';

export const LprDisclaimerSlideIn = portalSlideIn<
    {
        onAgreement: () => void;
    } & PortalSlideInProps
>(LprDisclaimerSlideInContent);

function LprDisclaimerSlideInContent({
    onClose,
    onAgreement,
}: {
    onClose: () => void;
    onAgreement: () => void;
}) {
    const [hasAgreed, setHasAgreed] = useState(false);

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Bestätigen"
                        fr="Confirmer"
                        it="Conferma"
                        en="Confirm"
                    />
                ),
                disabled: !hasAgreed,
                onClick: () => {
                    onAgreement();
                    onClose();
                },
            }}
        >
            <ul
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    paddingLeft: '20px',
                })}
            >
                <li>
                    <Localized
                        de="Du bist der berechtigte Benutzer der Fahrzeuge, die für die Kennzeichenerkennung aktiviert werden."
                        fr="Tu es l'utilisateur autorisé des véhicules qui seront activés pour la reconnaissance de plaque."
                        it="Sei l'utente autorizzato dei veicoli attivati per il riconoscimento della targa."
                        en="You are the authorised user of the vehicles that are activated for licence plate recognition."
                    />
                </li>
                <li>
                    <Localized
                        de="Du bist verpflichtet, die Kennzeichenerkennung für die Fahrzeuge wieder zu deaktivieren, bei denen du nicht mehr der berechtigte Benutzer bist (z.B. bei Gemeinschafts- oder Mietfahrzeugen)."
                        fr="Tu es tenu de désactiver à nouveau la reconnaissance de plaque pour les véhicules dont tu n'es plus l'utilisateur autorisé (par exemple pour les véhicules partagés ou de location)."
                        it="Sei tenuto a disattivare il riconoscimento della targa per i veicoli per i quali non sei più l'utente autorizzato (ad esempio, veicoli condivisi o a noleggio)."
                        en="You are obliged to deactivate licence plate recognition for vehicles for which you are no longer the authorised user (e.g. shared or rental vehicles)."
                    />
                </li>
                <li>
                    <Localized
                        de="Du verstehst, dass das Aktivieren der Kennzeichenerkennung in deiner Parkingpay-App auf dein eigenes Risiko erfolgt."
                        fr="Tu comprends que l'activation de la reconnaissance de plaque d'immatriculation dans ton app Parkingpay se fait à tes propres risques."
                        it="Sei consapevole che l'attivazione del riconoscimento della targa nell’app Parkingpay è a tuo rischio e pericolo."
                        en="You understand that activating licence plate recognition in your Parkingpay app is at your own risk."
                    />
                </li>
                <li>
                    <Localized
                        de="Das Kennzeichen der aktivierten Fahrzeuge muss die folgenden Bedingungen erfüllen:"
                        fr="La plaque d'immatriculation des véhicules activés doit satisfaire les conditions suivantes:"
                        it="La targa dei veicoli attivati deve soddisfare i seguenti requisiti:"
                        en="The licence plate of the activated vehicles must meet the following conditions:"
                    />
                </li>
                <ul>
                    <li>
                        <Localized
                            de="muss reflektierend sein"
                            fr="doit être réfléchissante"
                            it="deve essere riflettente"
                            en="must be reflective"
                        />
                    </li>
                    <li>
                        <Localized
                            de="darf keine Löcher haben, verblasst oder beschädigt sein"
                            fr="ne doit pas être troué, décoloré ou endommagé"
                            it="non deve avere buchi, essere sbiadita o danneggiata"
                            en="must not have any holes, be faded or damaged"
                        />
                    </li>
                    <li>
                        <Localized
                            de="muss mittig am Fahrzeug montiert sein"
                            fr="doit être monté au centre du véhicule"
                            it="dev'essere montata al centro del veicolo"
                            en="must be mounted in the centre of the vehicle"
                        />
                    </li>
                </ul>
                <li>
                    <Localized
                        de="Du verstehst, dass nach dem Verlassen eines Parkings, das mit der Funktion Kennzeichenerkennung ausgestattet ist, die Kosten automatisch deiner Parkingpay-App belastet werden."
                        fr="Tu comprends qu'une fois que tu as quitté un parking équipé de la fonction pour la reconnaissance de plaque, les frais seront automatiquement débités de ton app Parkingpay."
                        it="Sei consapevole che dopo aver lasciato un parcheggio dotato della funzione per il riconoscimento della targa, la tassa di parcheggio viene addebitata automaticamente sulla tua app Parkingpay."
                        en="You understand that after leaving a parking facility equipped with the licence plate recognition function, the costs are automatically debited from your Parkingpay app."
                    />
                </li>
            </ul>
            <div
                className={css({
                    paddingTop: '15px',
                    paddingBot: '5px',
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                <div
                    className={css({ cursor: 'pointer' })}
                    onClick={() => setHasAgreed(agreed => !agreed)}
                >
                    <ToggleIcon selected={hasAgreed} />
                </div>
                <div
                    className={css({
                        paddingLeft: '10px',
                        fontWeight: 'bold',
                    })}
                >
                    <Localized
                        de="Ich bin einverstanden"
                        fr="Je suis d'accord"
                        it="Sono d'accordo"
                        en="I agree"
                    />
                </div>
            </div>
        </SlideInForm>
    );
}
