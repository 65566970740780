import * as Flux from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../common/state/SettingsState';
import * as TransactionsListState from '../state/TransactionsListState';
import { SlideIn } from '../../ui/slidein/SlideIn';
import { OffstreetCheckin } from './OffstreetCheckin';
import { Translation } from 'dg-web-shared/lib/Text';
import { offstreetTransactionDetailTexts as offstreetCheckinDetailTexts } from '../i18n/TransactionsListTexts';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export interface OffstreetCheckinDetailTexts {
    title: Translation;
}

const offstreetTransactionId = (store: Flux.Store) =>
    TransactionsListState.Detail.get(store).offstreetCheckinId;
const OffstreetCheckinDetail = Flux.selectState(
    store => {
        const id = offstreetTransactionId(store);
        return {
            settings: new SettingsState.StateSlice(store).state,
            offstreetCheckinDetail:
                TransactionsListState.OffstreetCheckinDetail.get(
                    store,
                    id ? { id: id } : null,
                ),
        };
    },
    p =>
        p.offstreetCheckinDetail.pending || !p.offstreetCheckinDetail.data ? (
            <Spinner />
        ) : (
            <OffstreetCheckin transaction={p.offstreetCheckinDetail.data} />
        ),
);

export const OffstreetCheckinDetailSlideIn = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        transactionsListDetail: TransactionsListState.Detail.get(store),
    }),
    p => (
        <SlideIn
            title={offstreetCheckinDetailTexts[p.settings.language].title()}
            open={!!p.transactionsListDetail.offstreetCheckinId}
            onClose={() =>
                p.update(TransactionsListState.Detail.stateWrite, {
                    offstreetCheckinId: null,
                })
            }
        >
            <OffstreetCheckinDetail />
        </SlideIn>
    ),
);
