import { css } from '@emotion/css';

import { useStore } from 'dg-web-shared/lib/Flux';
import { Language } from 'dg-web-shared/lib/Localized';
import * as Http from '../../api/Http';
import { PaymentMethodEnum } from '../../api/Http';
import * as PermitHttp from '../../api/PermitHttp';
import { Localized } from '../../common/components/Localized';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import * as NumberFormatter from '../../utils/NumberFormatter';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { DateTime } from 'luxon';
import { ParkingpayAsyncLoadedSection } from '../../common/components/ParkingpayAsyncLoadedSection';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export const StatementDetailsSlideIn =
    portalSlideIn<StatementDetailsProps>(StatementDetails);

interface StatementDetailsProps {
    language: Language;
    yyyyMM: string;
}

function StatementDetails({ language, yyyyMM }: StatementDetailsProps) {
    const { storeState } = useStore(store => {
        return {
            paymentType: new PaymentTypeState.StateSlice(store).state,
        };
    });

    const [ticketResetState] = useServerFetch<MonthlyItem[], Context, null>(
        context => ({
            url: `/ui-api/customer-account/deposits/monthly-reports-v2/detail-v2/${context.id}?language=${context.lang}`,
        }),
        { id: yyyyMM, lang: language },
    );

    return (
        <ParkingpayAsyncLoadedSection
            state={ticketResetState}
            showSpinnerOnRefetch
            renderSuccess={monthlyTransactions => (
                <StatementDetailsItems
                    monthlyTransactions={monthlyTransactions}
                    isCustomerOnPrepaid={
                        storeState.paymentType.data.activePaymentMethod
                            .activePaymentType === PaymentMethodEnum.PREPAID
                    }
                />
            )}
        />
    );
}

const ReceiptDownload = (p: { href: string }): JSX.Element => (
    <a
        className={css({
            position: 'absolute',
            width: '24px',
            height: '24px',
            display: 'block',
            bottom: '24px',
            right: '18px',
            color: Colors.blue,
        })}
        target="_blank"
        href={p.href}
        rel="noreferrer"
    >
        <Icon icon="description" />
    </a>
);

function StatementDetailsItem({
    transaction,
    isCustomerOnPrepaid,
}: {
    transaction: MonthlyItem;
    isCustomerOnPrepaid: boolean;
}) {
    return (
        <>
            <div
                className={css({
                    position: 'relative',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '24px',
                    '&::before': {
                        content: '" "',
                        display: 'table',
                    },
                    '&::after': {
                        content: '" "',
                        display: 'table',
                        clear: 'both',
                    },
                })}
            >
                <div
                    className={css({
                        marginTop: '-4px',
                        float: 'left',
                        width: '15%',
                        paddingRight: '8px',
                        ...Typo.body,
                        color: Colors.blue,
                        fontWeight: 500,
                    })}
                >
                    {DateTime.fromISO(transaction.date).toFormat('dd.MM.')}
                </div>
                <div
                    className={css({
                        float: 'left',
                        width: '60%',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        userSelect: 'text',
                        color: Colors.rgba(Colors.action_w, 0.8),
                        ...Typo.caption,
                        fontSize: '14px',
                        fontWeight: 500,
                    })}
                >
                    <div
                        className={css({
                            marginTop: '-4px',
                            ...Typo.body,
                            color: Colors.blue,
                            fontWeight: 500,
                        })}
                    >
                        {transaction.title}
                    </div>

                    <div className={css({ minHeight: '50px' })}>
                        {transaction.description.map((line, index) => (
                            <div key={line + index}>{line}</div>
                        ))}
                    </div>
                    <StatementDetailsItemReceiptDownload
                        transaction={transaction}
                    />
                </div>
                <div
                    className={css({
                        float: 'left',
                        width: '25%',
                        paddingLeft: '8px',
                        textAlign: 'right',
                        ...Typo.body,
                        color: Colors.blue,
                        marginTop: '-4px',
                        fontWeight: 500,
                    })}
                >
                    {NumberFormatter.numberToPrice(transaction.amount, false)}
                </div>
            </div>

            {isCustomerOnPrepaid && <Balance balance={transaction.balance} />}

            <div
                className={css({
                    borderBottom: `solid 1px ${Colors.rgba(
                        Colors.action_w,
                        0.08,
                    )}`,
                    paddingBottom: '24px',
                })}
            />
        </>
    );
}

function StatementDetailsItemReceiptDownload({
    transaction,
}: {
    transaction: MonthlyItem;
}): JSX.Element | null {
    switch (transaction?.meta?.metaType) {
        case 'PERMIT_PURCHASE':
            return (
                <ReceiptDownload
                    href={PermitHttp.getPermitReceiptPdf({
                        id: transaction.meta.id,
                        key: transaction.urlKey as string,
                    })}
                />
            );

        case 'ONSTREET_PURCHASE':
            return (
                // parkIds do not exist anymore, so we do not show a receipt
                transaction.meta.id > 14214235 ? (
                    <ReceiptDownload
                        href={Http.getContractReceiptOnstreetBookingPDFURL(
                            transaction.meta.id.toString(),
                            transaction.urlKey as string,
                        )}
                    />
                ) : null
            );

        case 'CUSTOMER_COMMISSION':
            return (
                <ReceiptDownload
                    href={Http.getContractReceiptOnstreetBookingPDFURL(
                        transaction.meta.id.toString(),
                        transaction.urlKey as string,
                    )}
                />
            );

        case 'OFFSTREET_PURCHASE':
        case 'OFFSTREET_TICKET_PURCHASE':
            return (
                <ReceiptDownload
                    href={Http.getContractReceiptOffstreetBookingPDFURL(
                        transaction.meta.id.toString(),
                        transaction.urlKey as string,
                    )}
                />
            );

        default:
            return null;
    }
}

function Balance({ balance }: { balance: number }): JSX.Element | null {
    return (
        <div
            className={css({
                clear: 'both',
                color: `${Colors.rgba(Colors.action_w, 0.8)}`,
                fontSize: '13px',
                lineHeight: '16px',
                letterSpacing: '0.015em',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                marginBottom: '12px',
                ...Typo.robotoRegular,
            })}
        >
            <div
                className={css({
                    float: 'left',
                    width: '75%',
                    textAlign: 'right',
                })}
            >
                <Localized
                    de={'Saldo'}
                    fr={'Solde'}
                    it={'Saldo'}
                    en={'Balance'}
                />
            </div>
            <div
                className={css({
                    float: 'left',
                    width: '25%',
                    textAlign: 'right',
                })}
            >
                {NumberFormatter.numberToPrice(balance, false)}
            </div>
        </div>
    );
}

function StatementDetailsItems({
    monthlyTransactions,
    isCustomerOnPrepaid,
}: {
    monthlyTransactions: MonthlyItem[];
    isCustomerOnPrepaid: boolean;
}) {
    if (monthlyTransactions.length > 0) {
        return (
            <div>
                {monthlyTransactions.map((t: MonthlyItem, i: number) => {
                    return (
                        <StatementDetailsItem
                            transaction={t}
                            key={i}
                            isCustomerOnPrepaid={isCustomerOnPrepaid}
                        />
                    );
                })}
            </div>
        );
    } else {
        return (
            <div
                className={css({
                    ...Typo.body,
                    color: Colors.action_w,
                    padding: '24px',
                })}
            >
                <Localized
                    de={'Es sind keine Bewegungen für diesen Monat vorhanden'}
                    fr={"Il n'y a pas de mouvement pour ce mois."}
                    it={'Non ci sono movimenti per questo mese.'}
                    en={'There are no movements registered for this month.'}
                />
            </div>
        );
    }
}

interface Context {
    id: string;
    lang: string;
}

interface Meta {
    metaType: string;
    id: number;
    pingenId?: number;
}

interface MonthlyItem {
    lang: Language;
    date: string;
    amount: number;
    balance: number;
    meta: Meta;
    vat: number;
    title: string;
    description: string[];
    urlKey: string | undefined;
}
