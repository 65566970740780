import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import * as Http from '../../../api/Http';
import * as AsyncRequest from '../../../AsyncRequest';
import { logAction } from '../../../utils/ActionLog';
import * as MetaServerState from '../state/MetaServerState';
import * as MetaState from '../state/MetaState';
import * as ShippingAddressState from '../state/ShippingAddressState';

export function updateMetaData(args: {
    payload: Http.AccountMetaDataUpdate;
    origin: MetaState.Origin;
    onSuccess: () => void;
}): Flux.Write {
    return AsyncRequest.request(
        Http.putMetaData(args.payload),
        (store: Flux.Store, res: Response): void => {
            new MetaState.ResponseStateSlice(store).writeResponse(res);
            new MetaState.LocalStateSlice(store).writeOrigin(args.origin);

            if (res.statusCode.cls.success) {
                logAction(store, 'update-account-meta', args.payload);
                new MetaServerState.StateSlice(store).reset();

                if (args.origin === MetaState.Origin.SHIPPING_ADDRESS) {
                    new ShippingAddressState.StateSlice(store).reset();
                }

                args.onSuccess();
            }
        },
    );
}
