import { css } from '@emotion/css';

export function AspectRatio({
    children,
    ratio,
}: {
    children: React.ReactNode;
    ratio: number;
}): JSX.Element {
    return (
        <div className={css({ position: 'relative' })}>
            <div
                className={css({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    '& > *': { height: '100%', width: '100%' },
                })}
            >
                {children}
            </div>
            <div className={css({ paddingBottom: (1 / ratio) * 100 + '%' })} />
        </div>
    );
}
