import { useParams } from 'react-router';
import { QuickCheckoutAsyncLoadedSection } from '../common/QuickCheckoutAsyncLoadedSection';
import {
    PermittypeNotActive,
    PermittypeNotAllowed,
    PermittypeNotFound,
    PermittypeServiceNotAvailable,
} from './QuickCheckoutPermittypeErrorStates';
import { QuickCheckoutPermittypeForm } from './QuickCheckoutPermittypeForm';
import {
    PermittypeErrorResponse,
    PermittypeErrorState,
    PermittypeRequestContext,
    PermittypeSuccessResponse,
    PermittypeSuccessResponseType,
} from './QuickCheckoutPermittypeTypes';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { Navigate } from 'react-router-dom';

export function QuickCheckoutPermittype() {
    const { permitUrlIdentification } = useParams();
    const [permittypeState] = useServerFetch<
        PermittypeSuccessResponse,
        PermittypeRequestContext,
        PermittypeErrorResponse
    >(
        () => ({
            url: `/ui-api/quickcheckout/permittype/${permitUrlIdentification}`,
        }),
        permitUrlIdentification ? { permitUrlIdentification } : null,
    );

    return (
        <QuickCheckoutAsyncLoadedSection
            state={permittypeState}
            renderSuccess={permittype => {
                switch (permittype.type) {
                    case PermittypeSuccessResponseType.REDIRECT:
                        return (
                            <Navigate
                                to={`/quickcheckout/permit/${permittype.externalId}`}
                                replace
                            />
                        );

                    case PermittypeSuccessResponseType.FORM:
                        return (
                            <QuickCheckoutPermittypeForm
                                permittype={permittype}
                            />
                        );
                }
            }}
            renderError={errorData => {
                switch (errorData?.state) {
                    case PermittypeErrorState.NOT_FOUND:
                        return <PermittypeNotFound />;
                    case PermittypeErrorState.NOT_ACTIVE:
                        return <PermittypeNotActive />;
                    case PermittypeErrorState.NOT_ALLOWED:
                        return <PermittypeNotAllowed />;
                    case PermittypeErrorState.NO_QUICKCHECKOUT_PERMIT:
                        return (
                            <Navigate
                                to={`/permittype/${permitUrlIdentification}`}
                                replace
                            />
                        );
                    case undefined:
                        return <PermittypeServiceNotAvailable />;
                }
            }}
        />
    );
}
