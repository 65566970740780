import { TransactionReminderEditForm } from './TransactionReminderEdit';
import { BalanceEditForm } from './BalanceEdit';
import { NewsletterEditForm } from './NewsletterEdit';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { portalSlideIn } from '../../root/components/PortalSlidein';

export const ReminderEditSlideIn = portalSlideIn(ReminderEdit);

function ReminderEdit() {
    const [currentLogin] = CurrentLoginState.use();
    const loginData = currentLogin.data;

    if (!loginData) {
        return null;
    }

    return (
        <>
            <TransactionReminderEditForm currentLoginData={loginData} />
            <BalanceEditForm currentLoginData={loginData} />
            <NewsletterEditForm currentLoginData={loginData} />
        </>
    );
}
