import { createContext, useContext, useEffect, useState } from 'react';

import {
    RequestMethod,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { getParameterByName } from 'dg-web-shared/lib/Url';

export enum AuthStatusType {
    UNKNOWN,
    OK,
    NOT_OK,
}
export interface AuthState {
    authStatus: AuthStatusType;
    setAuthStatus: (status: AuthStatusType) => void;
}

const AppStateContext = createContext<AuthState>({
    authStatus: AuthStatusType.UNKNOWN,
    setAuthStatus: () => null,
});

export function useAuthState() {
    return useContext(AppStateContext);
}

function getAuthHeaders() {
    const token = getParameterByName(window.location.href, 'token');
    if (!token) return;
    return {
        Authorization: `Bearer ${token}`,
    };
}

export function AuthStateProvider({ children }: { children: React.ReactNode }) {
    const [authState, fetchAuthState] = useServerWrite<null, null, null>(
        () => ({
            url: '/ui-api/customer-account/auth-token-validity-v2',
            method: RequestMethod.GET,
            headers: getAuthHeaders(),
        }),
    );
    const [authStatus, setAuthStatus] = useState<AuthStatusType>(
        AuthStatusType.UNKNOWN,
    );

    useEffect(() => {
        if (authStatus === AuthStatusType.UNKNOWN) {
            fetchAuthState(null);
        }
    }, [authStatus]);

    useServerSuccessEffect(authState, () => {
        setAuthStatus(AuthStatusType.OK);
    });

    useServerErrorEffect(authState, () => {
        setAuthStatus(AuthStatusType.NOT_OK);
    });

    return (
        <AppStateContext.Provider
            value={{
                authStatus: authStatus,
                setAuthStatus: setAuthStatus,
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
}
