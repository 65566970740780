import { PaymentMethodEnum } from '../../api/Http';

export enum PaymentMethodStatus {
    INACTIVE,
    REQUESTED,
    ACTIVE,
}

export type RequestedPaymentMethod =
    | PaymentMethodEnum.LSV
    | PaymentMethodEnum.INVOICE
    | null;

export function status(
    activePaymentMethod: PaymentMethodEnum,
    requestedPaymentMethod: RequestedPaymentMethod,
    method: PaymentMethodEnum,
): PaymentMethodStatus {
    if (activePaymentMethod === method) {
        return PaymentMethodStatus.ACTIVE;
    }

    if (requestedPaymentMethod === method) {
        return PaymentMethodStatus.REQUESTED;
    }

    return PaymentMethodStatus.INACTIVE;
}

export function requestedPaymentMethod(
    maybe: PaymentMethodEnum | null,
): RequestedPaymentMethod {
    if (
        maybe &&
        maybe !== PaymentMethodEnum.PREPAID &&
        maybe !== PaymentMethodEnum.DIRECT
    ) {
        return maybe;
    }

    return null;
}
