import { localState } from 'dg-web-shared/lib/Flux';
import { Message } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../../common/components/Localized';
import { SlideInPortalId } from '../../root/components/PortalSlidein';
import { PaymentCategory } from '../../../common/payment/Payment';
import { ModalQuestionBox, ButtonText } from '../../../ui/modals/Confirmable';

export const DirectPayWarning = localState<
    {
        portal: SlideInPortalId;
        paymentCategory: PaymentCategory;
        TheForm: (props: {
            onSubmit: (amountChf: number) => void;
        }) => JSX.Element;
        doPostForm: (amountChf: number) => void;
    },
    { warningVisibility: WarningVisibility }
>(
    () => ({
        warningVisibility: { tag: WarningVisibilityTag.HIDDEN },
    }),
    props => {
        function hideWarning() {
            props.setState({
                warningVisibility: { tag: WarningVisibilityTag.HIDDEN },
            });
        }

        const warningVisibility: WarningVisibility =
            props.state.warningVisibility;

        return (
            <>
                <props.TheForm
                    onSubmit={amountChf => {
                        props.setState({
                            warningVisibility: {
                                tag: WarningVisibilityTag.VISIBLE,
                                amountChf,
                            },
                        });
                    }}
                />

                {warningVisibility.tag === WarningVisibilityTag.VISIBLE && (
                    <DirectPayWarningAlertBox
                        onConfirm={() => {
                            hideWarning();
                            props.doPostForm(warningVisibility.amountChf);
                        }}
                        onCancel={hideWarning}
                        paymentCategory={props.paymentCategory}
                    />
                )}
            </>
        );
    },
);

interface DirectPayWarningAlertBoxProps {
    paymentCategory: PaymentCategory;
    onConfirm: () => void;
    onCancel: () => void;
}

export const DirectPayWarningAlertBox = (
    props: DirectPayWarningAlertBoxProps,
) => {
    return (
        <ModalQuestionBox
            titleCaption={
                <Localized
                    de="Sofortige Belastung"
                    fr="Charge immédiate"
                    it="Addebito immediato"
                    en="Immediate charge"
                />
            }
            confirmCaption={ButtonText.CONTINUE}
            confirmCallback={props.onConfirm}
            cancelCaption={ButtonText.CANCEL}
            cancelCallback={props.onCancel}
        >
            <p>
                <Localized
                    {...(props.paymentCategory === PaymentCategory.POSTFINANCE
                        ? messages.uofPaymentPostAccountWarning
                        : props.paymentCategory === PaymentCategory.TWINT
                          ? messages.uofPaymentTwintWarning
                          : messages.uofPaymentCardWarning)}
                />
            </p>
        </ModalQuestionBox>
    );
};

type WarningVisibility = WarningVisibilityHidden | WarningVisibilityVisible;
type WarningVisibilityHidden = { tag: WarningVisibilityTag.HIDDEN };
type WarningVisibilityVisible = {
    tag: WarningVisibilityTag.VISIBLE;
    amountChf: number;
};

enum WarningVisibilityTag {
    HIDDEN,
    VISIBLE,
}

const messages: Messages = {
    uofPaymentPostAccountWarning: {
        de: 'Ihr Postfinance-Konto wird sofort und ohne weitere Bestätigung belastet.',
        fr: 'Votre compte Postfinance sera débité immédiatement, sans autre confirmation.',
        it: 'Il suo conto Postfinance verrà addebitato immediatamente, senza richiedere ulteriori conferme.',
        en: 'Your Postfinance account will be charged immediately, without any further confirmation.',
    },
    uofPaymentCardWarning: {
        de: 'Ihre Karte wird sofort und ohne weitere Bestätigung belastet.',
        fr: 'Votre carte sera débitée immédiatement, sans autre confirmation.',
        it: 'La sua carta verrà addebitata immediatamente, senza richiedere ulteriori conferme.',
        en: 'Your card will be charged immediately, without any further confirmation.',
    },
    uofPaymentTwintWarning: {
        de: 'Ihre TWINT App wird sofort und ohne weitere Bestätigung belastet.',
        fr: 'Votre app TWINT sera débitée immédiatement, sans autre confirmation.',
        it: 'La app TWINT verrà addebitata immediatamente, senza richiedere ulteriori conferme.',
        en: 'Your TWINT App will be charged immediately, without any further confirmation.',
    },
};

interface Messages {
    uofPaymentPostAccountWarning: Message;
    uofPaymentCardWarning: Message;
    uofPaymentTwintWarning: Message;
}
