import moment from 'moment';

import { Formatter } from 'dg-web-shared/lib/Date';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import * as Text from '../../common/i18n/Text';

import * as LicensePlateInfoBoxes from '../components/license-plate/InfoBoxes';
import { PermitEntitySelectionTexts } from '../components/license-plate/PermitEntitySelection';
import * as ParkCreate from '../components/ParkCreate';
import * as ParkVariantSelection from '../components/ParkVariantSelection';
import * as Permit from '../components/permit/Permit';
import * as ParkTransaction from '../components/zone-transaction/ParkTransaction';
import * as TransactionPurchase from '../components/zone-transaction/TransactionPurchase';
import { ParkVariant } from '../state/ParkOptionListState';
import { CurrentBadgeSelectionTexts } from '../components/license-plate/Entities';

export const parkVariantSelectionTexts: Text.Translations<ParkVariantSelection.Texts> =
    {
        de: {
            ParkVariant: () => 'Parkvariante',
            BadgeEnterExit: () => 'Ein-/Ausfahren',
            Transaction: () => GeneralTexts.texts.de.Transaction(),
            Permit: () => GeneralTexts.texts.de.Permit(),
            SelectZoneInfoTitle: () => 'Zone/Parking auswählen',
            SelectZoneInfoBody: (v: ParkVariant) =>
                `Für die Variante «${
                    v === 'licensePlateTransaction'
                        ? GeneralTexts.texts.de.Transaction()
                        : GeneralTexts.texts.de.Permit()
                }» muss zwingend eine Zone/ein Parking ausgewählt werden.`,
        },

        fr: {
            ParkVariant: () => 'Variante de stationnement',
            BadgeEnterExit: () => 'Entrer/Sortir',
            Transaction: () => GeneralTexts.texts.fr.Transaction(),
            Permit: () => GeneralTexts.texts.fr.Permit(),
            SelectZoneInfoTitle: () => 'Choisir zone/parking',
            SelectZoneInfoBody: (v: ParkVariant) =>
                `Pour l'option «${
                    v === 'licensePlateTransaction'
                        ? GeneralTexts.texts.fr.Transaction()
                        : GeneralTexts.texts.fr.Permit()
                }» il est obligatoire de choisir une zone/un parking.`,
        },

        it: {
            ParkVariant: () => 'Variante di parcheggio',
            BadgeEnterExit: () => 'Entrare/Uscire',
            Transaction: () => GeneralTexts.texts.it.Transaction(),
            Permit: () => GeneralTexts.texts.it.Permit(),
            SelectZoneInfoTitle: () => 'Selezionare zona/parcheggio',
            SelectZoneInfoBody: (v: ParkVariant) =>
                `Per la variante «${
                    v === 'licensePlateTransaction'
                        ? GeneralTexts.texts.it.Transaction()
                        : GeneralTexts.texts.it.Permit()
                }» è obbligatorio selezionare una zona/un parcheggio.`,
        },

        en: {
            ParkVariant: () => 'Park options',
            BadgeEnterExit: () => 'Enter/Exit',
            Transaction: () => GeneralTexts.texts.en.Transaction(),
            Permit: () => GeneralTexts.texts.en.Permit(),
            SelectZoneInfoTitle: () => 'Select zone/parking',
            SelectZoneInfoBody: (v: ParkVariant) =>
                `For the option «${
                    v === 'licensePlateTransaction'
                        ? GeneralTexts.texts.en.Transaction()
                        : GeneralTexts.texts.en.Permit()
                }» it is mandatory to select a zone/parking.`,
        },
    };

export const parkCreateTexts: Text.Translations<ParkCreate.Texts> = {
    de: {
        Title: () => 'Parkieren',
        TitleUnauth: () => 'Parkbewilligungen',
    },

    fr: {
        Title: () => 'Stationner',
        TitleUnauth: () => 'Autorisations',
    },

    it: {
        Title: () => 'Parcheggiare',
        TitleUnauth: () => 'Autorizzazioni',
    },

    en: {
        Title: () => 'Park',
        TitleUnauth: () => 'Permit',
    },
};

export const optionListTexts: Text.Translations<{
    Location: Text.Translation;
    ParkOption: Text.Translation;
    GeolocationError: Text.Translation;
    WelcomeNotificationTitle: Text.Translation;
    WelcomeNotificationBody: () => JSX.Element;
}> = {
    de: {
        Location: () => GeneralTexts.texts.de.ZipcodeCity(),
        ParkOption: () => GeneralTexts.texts.de.ParkOption(),
        GeolocationError: () =>
            'Standortbestimmung nicht möglich. Bitte stellen Sie sicher, dass die Standortbestimmung in den Einstellungen aktiviert ist.',
        WelcomeNotificationTitle: () => 'Willkommen',
        WelcomeNotificationBody: () => (
            <>
                Hier können Sie den Standort suchen (zuerst PLZ/Ort und dann
                evt. Zone/Parking), um einen neuen <b>Parkvorgang</b> zu starten
                oder eine <b>Bewilligung</b> zu kaufen.
            </>
        ),
    },

    fr: {
        Location: () => GeneralTexts.texts.fr.ZipcodeCity(),
        ParkOption: () => GeneralTexts.texts.fr.ParkOption(),
        GeolocationError: () =>
            "Il n'est pas possible de determiner votre position. veuillez vérifier les paramètre de vostre navigateur Web.",
        WelcomeNotificationTitle: () => 'Bienvenu(e)',
        WelcomeNotificationBody: () => (
            <>
                Ci-dessus vous pouvez rechercher le lieu (d'abord avec
                NPA/localité et après evtl. zone/parking) pour démarrer une{' '}
                <b>procedure de stationnement</b> ou pour acheter une{' '}
                <b>autorisation.</b>
            </>
        ),
    },
    it: {
        Location: () => GeneralTexts.texts.it.ZipcodeCity(),
        ParkOption: () => GeneralTexts.texts.it.ParkOption(),
        GeolocationError: () =>
            'Non è possibile determinare la vostra posizione. Assicuratevi che questa funzione sia attiva nel vostro browser.',
        WelcomeNotificationTitle: () => 'Benvenuto/a',
        WelcomeNotificationBody: () => (
            <>
                Qui sopra può ricercare il luogo (prima NAP/località e poi ev.
                zona/parcheggio) per avviare una nuova{' '}
                <b>procedura di parcheggio</b> o per acquistare{' '}
                <b>un'autorizzazione.</b>
            </>
        ),
    },
    en: {
        Location: () => GeneralTexts.texts.en.ZipcodeCity(),
        ParkOption: () => GeneralTexts.texts.en.ParkOption(),
        GeolocationError: () =>
            "You can't determine your location. Make sure that this function is active in your browser.",
        WelcomeNotificationTitle: () => 'Welcome',
        WelcomeNotificationBody: () => (
            <>
                Above you can search for the place (first ZIP/city and then
                zone/parking) to start a new <b>procedure</b> or to purchase{' '}
                <b>a permit.</b>
            </>
        ),
    },
};

export const optionTexts: Text.Translations<Permit.Texts> = {
    de: {
        Permit: () => GeneralTexts.texts.de.PermitType(),
    },
    fr: {
        Permit: () => GeneralTexts.texts.fr.PermitType(),
    },
    it: {
        Permit: () => GeneralTexts.texts.it.PermitType(),
    },
    en: {
        Permit: () => GeneralTexts.texts.en.PermitType(),
    },
};

export const licensePlateTexts: Text.Translations<{
    Vehicle: Text.Translation;
    Badge: Text.Translation;
    NoPlatesTitle: Text.Translation;
    NoPlatesBody: () => JSX.Element;
    TypeNotAllowed: Text.Translation;
    NotOnWhitelistHeader: Text.Translation;
    NotOnWhitelistExplanation: Text.Translation;
}> = {
    de: {
        Vehicle: () => GeneralTexts.texts.de.Vehicle(),
        Badge: () => GeneralTexts.texts.de.Badge(),
        NoPlatesTitle: () => 'Kein Fahrzeug vorhanden',
        NoPlatesBody: () => (
            <div>
                <p>
                    Um einen Parkvorgang zu starten, müssen Sie ein Kennzeichen
                    erfassen.
                </p>
                <p>Fahrzeuge können auch im Konto verwaltet werden.</p>
            </div>
        ),
        TypeNotAllowed: () =>
            'Diese Zone ist für den gewählten Fahrzeugstyp nicht zugelassen.',
        NotOnWhitelistHeader: () => 'Keine Freigabe',
        NotOnWhitelistExplanation: () => '',
    },
    fr: {
        Vehicle: () => GeneralTexts.texts.fr.Vehicle(),
        Badge: () => GeneralTexts.texts.fr.Badge(),
        NoPlatesTitle: () => 'Aucune véhicule disponible',
        NoPlatesBody: () => (
            <div>
                <p>
                    Pour pouvoir démarrer un procédure de stationnement vous
                    devez saisir un véhicule.
                </p>
                <p>Les véhicules peuvent aussi être gérées dans le compte.</p>
            </div>
        ),
        TypeNotAllowed: () =>
            "Cette zone n'est pas permise pour le type de véhicule sélectionné.",
        NotOnWhitelistHeader: () => "Pas d'approbation",
        NotOnWhitelistExplanation: () => '',
    },
    it: {
        Vehicle: () => GeneralTexts.texts.it.Vehicle(),
        Badge: () => GeneralTexts.texts.it.Badge(),
        NoPlatesTitle: () => 'Nessun veicolo disponibile',
        NoPlatesBody: () => (
            <div>
                <p>
                    Per poter avviare una procedura di parcheggio dovete
                    registrare un veicolo.
                </p>
                <p>I veicoli possono anche essere gestiti nel conto.</p>
            </div>
        ),
        TypeNotAllowed: () =>
            'Questa zona non è abilitata per il tipo di veicolo selezionato.',
        NotOnWhitelistHeader: () => 'Nessuna approvazione',
        NotOnWhitelistExplanation: () => '',
    },
    en: {
        Vehicle: () => GeneralTexts.texts.en.Vehicle(),
        Badge: () => GeneralTexts.texts.en.Badge(),
        NoPlatesTitle: () => 'No vehicle available',
        NoPlatesBody: () => (
            <div>
                <p>
                    In order to start a parking procedure you must enter a
                    vehicle.
                </p>
                <p>Vehicles can also be managed in the account.</p>
            </div>
        ),
        TypeNotAllowed: () =>
            'This zone is not enabled for the selected category.',
        NotOnWhitelistHeader: () => 'No approval',
        NotOnWhitelistExplanation: () => '',
    },
};

export const licensePlateInfoBoxesTexts: Text.Translations<LicensePlateInfoBoxes.Texts> =
    {
        de: {
            CounterPermitHeader: () => 'Online nicht verfügbar',
            CounterPermitExplanation: () =>
                'Die gewählte Bewilligung kann nicht online gekauft werden.',
            ContactOperator: (operatorName: string) =>
                `Bitte wenden Sie sich direkt an den Betreiber ${operatorName}.`,
            VignetteOrderedHeader: () => 'Vignette wurde bestellt',
            VignetteWillBeSentExplanation: () =>
                'Die Vignette für dieses Kennzeichen wird per Post an diese Adresse versandt:',
            VignetteWasSentExplanation: (
                date: moment.Moment,
                address: boolean,
            ) =>
                `Die Vignette für dieses Kennzeichen wurde am ${Formatter.dayMonthYear(
                    date,
                )} per Post ${address ? 'an diese Adresse' : ''} versandt${
                    address ? ':' : '.'
                }`,
            VignetteWasAddedByOperator: (
                date: moment.Moment,
                operator: string,
            ) =>
                `Die Vignette für dieses Kennzeichen wurde Ihnen am ${Formatter.dayMonthYear(
                    date,
                )} vom Betreiber ${operator} übergeben.`,
            VignetteOnCarHeader: () => 'Vignette aufgeklebt?',
            VignetteExplanation: (lp: string[]) =>
                `Sie dürfen erst mit de${
                    lp.length === 1 ? 'm' : 'n'
                } Kennzeichen ${lp.join(
                    ', ',
                )} in dieser Zone parkieren, wenn die Vignette für diese${
                    lp.length === 1 ? 's' : ''
                } Kennzeichen an der Windschutzscheibe (unten rechts) angebracht ist.`,
            VignetteOnCarButton: () => 'Weiter',
            NoVignetteHeader: () => 'Vignette-Pflicht',
            NoVignetteExplanation1: (lp: string) =>
                `Sie dürfen in dieser Zone nicht parkieren, weil dem Kennzeichen ${lp} noch keine Parkingpay-Vignette zugeordnet ist.`,
            NoVignetteExplanation1Permit: (lps: string[]) =>
                `Sie dürfen diese Bewilligung nicht kaufen, weil de${
                    lps.length === 1 ? 'm' : 'n'
                } Kennzeichen ${lps.join(
                    ', ',
                )}  noch keine Parkingpay-Vignette zugeordnet ist.`,
            NoVignetteExplanation2: () =>
                'Die Vignette ist schweizweit gültig und nur noch an wenigen Parkingpay-Standorten für Parkvorgänge und Bewilligungen Pflicht.',
            NoVignetteExplanation3: () =>
                'Bestellen Sie jetzt kostenlos Ihre Vignette und sie wird Ihnen innert zwei Arbeitstagen per Post an diese Adresse zugestellt:',
            NoVignetteExplanation4: () =>
                'Kennzeichen, Vignetten und Adresse können auch im Konto verwaltet werden.',
            NoVignetteOrderButton: () => 'Bestellen',
            ClearanceNeededPermitOneLp: (operatorName: string, lp: string) =>
                `Das Kennzeichen ${lp} ist für die gewählte Bewilligung nicht freigeschaltet und muss beim Betreiber ${operatorName} beantragt werden.`,
            ClearanceNeededPermitMultiLp: (
                operatorName: string,
                lps: string[],
            ) =>
                `Die Kennzeichen ${lps.join(
                    ', ',
                )} sind für die gewählte Bewilligung nicht freigeschaltet und müssen beim Betreiber ${operatorName} beantragt werden.`,
            AddLicensePlate: () => 'Kennzeichen hinzufügen…',
            WrongTypeHeader: () => 'Fahrzeugtyp nicht zugelassen',
            WrongTypeHeaderTransaction: () =>
                'Dieser Fahrzeugstyp ist für die gewählte Zone nicht zugelassen.',
            ClearanceNeededHeader: () => 'Freigabe-Pflicht',
            ClearanceNeededTransaction: () =>
                'Dieses Kennzeichen ist für diese Zone nicht freigeschaltet und muss beim Betreiber beantragt werden',
            WrongTypeHeaderPermitOneLp: () =>
                'Dieser Fahrzeugstyp ist für die gewählte Bewilligung nicht zugelassen.',
            WrongTypeHeaderPermitMultipleLp: (lps: string[]) =>
                `Der Fahrzeugstyp von den Kennzeichen ${lps.join(
                    ', ',
                )} ist für die gewählte Bewilligung nicht zugelassen.`,
            ClearanceNeededPermitOneBadge: (
                operatorName: string,
                badge: string,
            ) =>
                `Der Badge ${badge} ist für die gewählte Bewilligung nicht freigeschaltet und muss beim Betreiber ${operatorName} beantragt werden.`,
            ClearanceNeededPermitMultiBadge: (
                operatorName: string,
                badges: string[],
            ) =>
                `Die Badges ${badges.join(
                    ', ',
                )} sind für die gewählte Bewilligung nicht freigeschaltet und müssen beim Betreiber ${operatorName} beantragt werden.`,
        },
        fr: {
            CounterPermitHeader: () => 'Pas disponible en ligne',
            CounterPermitExplanation: () =>
                "L'autorisation sélectionnée ne peut pas être achetée en ligne.",
            ContactOperator: (operatorName: string) =>
                `Veuillez contacter directement l'exploitant ${operatorName}.`,
            VignetteOrderedHeader: () => 'Vignette commandée',
            VignetteWillBeSentExplanation: () =>
                'La vignette pour cette immatriculation sera envoyée par poste à cette adresse:',
            VignetteWasSentExplanation: (
                date: moment.Moment,
                address: boolean,
            ) =>
                `La vignette pour cette immatriculation a été envoyée par poste le ${Formatter.dayMonthYear(
                    date,
                )} ${address ? 'à cette adresse:' : '.'}`,
            VignetteWasAddedByOperator: (
                date: moment.Moment,
                operator: string,
            ) =>
                `La vignette pour cette immatriculation a été consignée le ${Formatter.dayMonthYear(
                    date,
                )} par l’exploitant ${operator}.`,
            VignetteOnCarHeader: () => 'Vignette collée?',
            VignetteExplanation: (lp: string[]) =>
                `Vous pouvez seulement stationner dans cette zone avec ${
                    lp.length === 1 ? 'l’' : 'les '
                }immatriculation${lp.length === 1 ? '' : 's'}  ${lp.join(
                    ', ',
                )} si la vignette correspondante a été collée à l’intérieur du parebrise (en bas à droite).`,
            VignetteOnCarButton: () => 'Continuer',
            NoVignetteHeader: () => 'Vignette obligatoire',
            NoVignetteExplanation1: (lp: string) =>
                `Vous ne pouvez pas démarrer une procédure dans cette zone, car l’immatriculation ${lp} n’a pas encore été assignée à une vignette Parkingpay.`,
            NoVignetteExplanation1Permit: (lps: string[]) =>
                `Vous ne pouvez pas acheter cette autorisation, car ${
                    lps.length === 1 ? 'l’' : 'les '
                }immatriculation${lps.length === 1 ? '' : 's'} ${lps.join(
                    ', ',
                )} n’${
                    lps.length === 1 ? 'a' : 'ont'
                } pas encore été assignée à une vignette Parkingpay.`,
            NoVignetteExplanation2: () =>
                'La vignette est valable dans toute la Suisse et est encore obligatoire, pour les procédures et les autorisations, dans quelques sites Parkingpay.',
            NoVignetteExplanation3: () =>
                'Commandez maintenant votre vignette gratuitement; elle vous sera livrée dans les 2 prochaines jours ouvrables à l’adresse suivante:',
            NoVignetteExplanation4: () =>
                'Les immatriculations, les vignette et l‘adresse peuvent aussi être gérées dans le compte.',
            NoVignetteOrderButton: () => 'Commander',
            ClearanceNeededPermitOneLp: (operatorName: string, lp: string) =>
                `L'immatriculation ${lp} ne dispose pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`,
            ClearanceNeededPermitMultiLp: (
                operatorName: string,
                lps: string[],
            ) =>
                `Les immatriculations ${lps.join(
                    ', ',
                )} ne disposent pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`,
            AddLicensePlate: () => 'Ajouter immatriculation…',
            WrongTypeHeader: () => 'Type de véhicule pas permis',
            WrongTypeHeaderTransaction: () =>
                "Ce type de véhicule n'est pas admis pour la zone sélectionnée.",
            ClearanceNeededHeader: () => 'Approbation obligatoire',
            ClearanceNeededTransaction: () =>
                "Cette immatriculation n'est pas activée pour la zone sélectionnée. Vous devez soumettre une demande à l'exploitant.",
            WrongTypeHeaderPermitOneLp: () =>
                "Ce type de véhicule n'est pas admis pour l'autorisation sélectionnée.",
            WrongTypeHeaderPermitMultipleLp: (lps: string[]) =>
                `Le type de véhicule des immatriculations ${lps.join(
                    ', ',
                )} n'est pas admis pour l'autorisation sélectionnée.`,
            ClearanceNeededPermitOneBadge: (
                operatorName: string,
                badge: string,
            ) =>
                `Le badge ${badge} ne dispose pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`,
            ClearanceNeededPermitMultiBadge: (
                operatorName: string,
                badges: string[],
            ) =>
                `Les badges ${badges.join(
                    ', ',
                )} ne disposent pas de l'approbation nécessaire pour l’achat de l’autorisation. Vous devez faire une demande chez l'exploitant ${operatorName}.`,
        },
        it: {
            CounterPermitHeader: () => 'Non disponibile online',
            CounterPermitExplanation: () =>
                "L'autorizzazione selezionata non può essere acquistata online.",
            ContactOperator: (operatorName: string) =>
                `Vogliate rivolgetevi direttamente al gestore ${operatorName}.`,
            VignetteOrderedHeader: () => 'Vignetta ordinata',
            VignetteWillBeSentExplanation: () =>
                'La vignetta per questo numero di targa sarà inviata per posta a questo indirizzo:',
            VignetteWasSentExplanation: (
                date: moment.Moment,
                address: boolean,
            ) =>
                `La vignetta per questo numero di targa è stata inviata per posta ${Formatter.dayMonthYear(
                    date,
                )} ${address ? 'a questo indirizzo:' : '.'}`,
            VignetteWasAddedByOperator: (
                date: moment.Moment,
                operator: string,
            ) =>
                `La vignetta per questo numero di targa vi è stata consegnata il  ${Formatter.dayMonthYear(
                    date,
                )} dal gestore ${operator}.`,
            VignetteOnCarHeader: () => 'Vignetta incollata?',
            VignetteExplanation: (lp: string[]) =>
                `Potete parcheggiare con i${lp.length === 1 ? 'l' : ''} numer${
                    lp.length === 1 ? 'o' : 'i'
                } di targa ${lp.join(', ')} solamente se la relativ${
                    lp.length === 1 ? 'a' : 'e'
                } vignett${
                    lp.length === 1 ? 'a' : 'e'
                } è stata incollata all’interno del parabrezza (in basso a destra).`,
            VignetteOnCarButton: () => 'Continua',
            NoVignetteHeader: () => 'Vignetta obbligatoria',
            NoVignetteExplanation1: (lp: string) =>
                `Non potete avviare una procedura di parcheggio in questa zona, in quanto alla targa ${lp} non è ancora stata attribuita alcuna vignetta Parkingpay.`,
            NoVignetteExplanation1Permit: (lps: string[]) =>
                `Non potete acquistare questa autorizzazione, in quanto all${
                    lps.length === 1 ? 'a' : 'e'
                } targ${lps.length === 1 ? 'a' : 'e'} ${lps.join(
                    ', ',
                )} non è ancora stata attribuita una vignetta Parkingpay.`,
            NoVignetteExplanation2: () =>
                'La vignetta è valida in tutta la Svizzera ed é ancora obbligatoria, per le procedure e le autorizzazioni, solo in alcune località Parkingpay.',
            NoVignetteExplanation3: () =>
                'Ordinate adesso gratuitamente la vostra vignetta; vi verrà recapitata per posta nei 2 prossimi giorni lavorativi a questo indirizzo:',
            NoVignetteExplanation4: () =>
                'Le targhe, le vignette e l’indirizzo possono essere anche gestite nel conto.',
            NoVignetteOrderButton: () => 'Ordinare',
            ClearanceNeededPermitOneLp: (operatorName: string, lp: string) =>
                `Il numero di targa ${lp} non dispone dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`,
            ClearanceNeededPermitMultiLp: (
                operatorName: string,
                lps: string[],
            ) =>
                `I numeri di targa ${lps.join(
                    ', ',
                )} non dispongono dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`,
            AddLicensePlate: () => 'Aggiungi targa',
            WrongTypeHeader: () => 'Tipo di veicolo non abilitato',
            WrongTypeHeaderTransaction: () =>
                'Questo tipo di veicolo non è abilitato per la zona selezionata.',
            ClearanceNeededHeader: () => 'Approvazione obbligatoria',
            ClearanceNeededTransaction: () =>
                'Questa targa non è abilitata per zona selezionata. Dovete quindi inoltrare una richiesta presso il gestore.',
            WrongTypeHeaderPermitOneLp: () =>
                "Questo tipo di veicolo non è abilitato per l'autorizzazione selezionata.",
            WrongTypeHeaderPermitMultipleLp: (lps: string[]) =>
                `Il tipo di veicolo delle targhe ${lps.join(
                    ', ',
                )} non è abilitato per l'autorizzazione selezionata.`,
            ClearanceNeededPermitOneBadge: (
                operatorName: string,
                badge: string,
            ) =>
                `Il badge ${badge} non dispone dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`,
            ClearanceNeededPermitMultiBadge: (
                operatorName: string,
                badges: string[],
            ) =>
                `I badge ${badges.join(
                    ', ',
                )} non dispongono dell'approvazione per l'acquisto dell'autorizzazione selezionata. Dovete quindi inoltrare una richiesta presso il gestore ${operatorName}.`,
        },
        en: {
            CounterPermitHeader: () => 'Not available online',
            CounterPermitExplanation: () =>
                'The selected permit cannot be purchased online.',
            ContactOperator: (operatorName: string) =>
                `Please contact the operator ${operatorName} directly.`,
            VignetteOrderedHeader: () => 'Vignette ordered',
            VignetteWillBeSentExplanation: () =>
                'The vignette for this license plate will be sent by post to this address:',
            VignetteWasSentExplanation: (
                date: moment.Moment,
                address: boolean,
            ) =>
                `The vignette for this license plate was sent by post on ${Formatter.dayMonthYear(
                    date,
                )} ${address ? 'to this addess:' : '.'}`,
            VignetteWasAddedByOperator: (
                date: moment.Moment,
                operator: string,
            ) =>
                `The vignette for this license plate number was delivered to you on  ${Formatter.dayMonthYear(
                    date,
                )} by the operator ${operator}.`,
            VignetteOnCarHeader: () => 'Vignette glued?',
            VignetteExplanation: (lp: string[]) =>
                `You can only park with the license plate${
                    lp.length === 1 ? '' : 's'
                }
            ${lp.join(', ')} if the corresponding vignette${
                lp.length === 1 ? ' has' : 's have'
            } been glued to the inside of the windscreen (bottom right).`,
            VignetteOnCarButton: () => 'Next',
            NoVignetteHeader: () => 'Vignette mandatory',
            NoVignetteExplanation1: (lp: string) =>
                `You cannot start a parking procedure in this zone, as the ${lp} license plate has not yet been assigned to a Parkingpay vignette.`,
            NoVignetteExplanation1Permit: (lps: string[]) =>
                `Non potete acquistare questa autorizzazione, in quanto all${
                    lps.length === 1 ? 'a' : 'e'
                } targ${lps.length === 1 ? 'a' : 'e'} ${lps.join(
                    ', ',
                )} non è ancora stata attribuita una vignetta Parkingpay.`,
            NoVignetteExplanation2: () =>
                'The vignette is valid throughout Switzerland and is still required in some Parkingpay locations for procedures and authorizations.',
            NoVignetteExplanation3: () =>
                'Order your vignette now free of charge; it will be delivered to you by post within the next 2 working days at this address:',
            NoVignetteExplanation4: () =>
                'License plates, vignettes and addresses can also be managed in the account.',
            NoVignetteOrderButton: () => 'Order',
            ClearanceNeededPermitOneLp: (operatorName: string, lp: string) =>
                `The license plate ${lp} does not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`,
            ClearanceNeededPermitMultiLp: (
                operatorName: string,
                lps: string[],
            ) =>
                `I numeri di targa ${lps.join(
                    ', ',
                )} do not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`,
            AddLicensePlate: () => 'Add license plate',
            WrongTypeHeader: () => 'Type of vehicle not allowed',
            WrongTypeHeaderTransaction: () =>
                'This type of vehicle is not allowed in the selected zone.',
            ClearanceNeededHeader: () => 'Approval mandatory',
            ClearanceNeededTransaction: () =>
                'This license plate is not allowed for this zone and must be applied by the operator.',
            WrongTypeHeaderPermitOneLp: () =>
                'This type of vehicle is not enabled for the selected permit.',
            WrongTypeHeaderPermitMultipleLp: (lps: string[]) =>
                `The vehicle type of the license plates ${lps.join(
                    ', ',
                )} is not allowed for the selected permit.`,
            ClearanceNeededPermitOneBadge: (
                operatorName: string,
                badge: string,
            ) =>
                `The badge ${badge} does not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`,
            ClearanceNeededPermitMultiBadge: (
                operatorName: string,
                badges: string[],
            ) =>
                `The badges ${badges.join(
                    ', ',
                )} do not have the approval to purchase the selected permit. You must first submit a request to the operator ${operatorName}.`,
        },
    };

export const badgeTexts: Text.Translations<CurrentBadgeSelectionTexts> = {
    de: {
        BadgeOrdered: () => 'Badge bestellt',
    },
    fr: {
        BadgeOrdered: () => 'Badge commandé',
    },
    it: {
        BadgeOrdered: () => 'Badge ordinato',
    },
    en: {
        BadgeOrdered: () => 'Badge ordered',
    },
};

export const checkinTexts: Text.Translations<ParkTransaction.Texts> = {
    de: {
        Price: () => 'Parkgebühr',
        MaxPrice: () => 'Höchstparkgebühr',
        MaxEnd: () => 'Höchstparkzeit',
        Operator: () => 'Betreiber',
        Tariff: () => 'Tarif',
        TariffName: () => 'App',
        TariffMessage: () =>
            '(inkl. 4,85% Gebühr für die Nutzung der Applikation)',
        Duration: () => 'Parkdauer',
    },
    fr: {
        Price: () => 'Taxe de stationnement',
        MaxPrice: () => 'Montant maximum',
        MaxEnd: () => 'Temps maximum',
        Operator: () => 'Exploitant',
        Tariff: () => 'Tarif',
        TariffName: () => 'Appli',
        TariffMessage: () =>
            '(incl. 4.85% frais d’utilisation de l’application)',
        Duration: () => 'Durée',
    },
    it: {
        Price: () => 'Tassa di parcheggio',
        MaxPrice: () => 'Tassa massima',
        MaxEnd: () => 'Tempo massimo consentito',
        Operator: () => 'Gestore',
        Tariff: () => 'Tariffa',
        TariffName: () => 'App',
        TariffMessage: () => "(incl. 4.85% spese d'utilizzo dell'applicazione)",
        Duration: () => 'Durata',
    },
    en: {
        Price: () => 'Parking fee',
        MaxPrice: () => 'Max. price',
        MaxEnd: () => 'Max. end time',
        Operator: () => 'Operator',
        Tariff: () => 'Tariff',
        TariffName: () => 'App',
        TariffMessage: () => '(incl 4.85% application usage fee)',
        Duration: () => 'Duration',
    },
};

export const transactionPurchaseTexts: Text.Translations<TransactionPurchase.Texts> =
    {
        de: {
            ErrorNotEnoughMoney: () => 'Ungenügender Saldo',
            ErrorTransNotPossible: () =>
                'Sie sind nicht berechtigt diesen Parkvorgang zu starten (haben Sie für diese Zone eine Bewilligung mit mehreren Kennzeichen? Dann wurde die maximale Anzahl gleichzeitige Parkvorgänge schon erreicht. Sie müssen warten bis ein anderer Parkvorgang beendet wird).',
            ErrorAlreadyLoggedIn: () =>
                'Für das gewählte Kennzeichen wurde in dieser Zone bereits ein Parkvorgang gestartet. Dieser muss beendet werden, bevor ein neuer gestartet werden kann.',
            SuccessfulPurchase: () => 'Der Parkvorgang wurde gestartet',
            ErrorNoParkingTime: () =>
                'Während der gewählten Zeit ist das Parkieren gebührenfrei.',
            TransactionErrorGeneric: () =>
                `${GeneralTexts.texts.de.ErrorTryAgainOrHelpdesk()}`,
            AlreadyLoggedIn: () =>
                'Für das gewählte Kennzeichen wurde bereits ein Parkvorgang gestartet.',
            ToParkTransaction: () => 'Parkvorgang anzeigen',
        },
        fr: {
            ErrorNotEnoughMoney: () => 'Solde insuffisant',
            ErrorTransNotPossible: () =>
                "Vous n'avez pas le droit de démarrer ce stationnement (avez-vous une autorisation avec plusieurs immatriculations pour cette zone? Dans ce cas le nombre maximal de stationnement simultané est atteint. Vous devez attendre qu'un stationnement se termine).",
            ErrorAlreadyLoggedIn: () =>
                "Pour cette plaque d'immatriculation il y a déjà une procédure de stationnement en cours dans la zone sélectionnée. Il faut arrêter cette procédure avant de pouvoir en commencer une nouvelle.",
            SuccessfulPurchase: () => 'La procedure a été démarrée',
            ErrorNoParkingTime: () =>
                'Pas de procédure nécessaire pour la durée de stationnement sélectionnée.',
            TransactionErrorGeneric: () =>
                `${GeneralTexts.texts.fr.ErrorTryAgainOrHelpdesk()}`,
            AlreadyLoggedIn: () =>
                'Il y a déjà une procédure en cours pour cette immatriculation.',
            ToParkTransaction: () => 'Afficher la transaction',
        },
        it: {
            ErrorNotEnoughMoney: () => 'Saldo insufficente',
            ErrorTransNotPossible: () =>
                "Non siete abilitato ad avviare questa procedura di parcheggio (avete per questa zona un'autorizzazione con diversi numeri di targa? In questo caso il numero massimo di procedure contemporanee è stato raggiunto. Dovete aspettare che un'altra procedura venga terminata).",
            ErrorAlreadyLoggedIn: () =>
                "Per questo numero di targa è già in corso una procedura di parcheggio nella zona selezionata. Questa procedura dev'essere terminata prima di poterne avviare una nuova.",
            SuccessfulPurchase: () => 'La procedura è stata avviata.',
            ErrorNoParkingTime: () =>
                'Per la durata selezionata non è necessario avviare alcuna procedura di parcheggio.',
            TransactionErrorGeneric: () =>
                `${GeneralTexts.texts.it.ErrorTryAgainOrHelpdesk()}`,
            AlreadyLoggedIn: () =>
                'Per il numero di targa selezionato esiste già una procedura di parcheggio.',
            ToParkTransaction: () => 'Mostra procedura',
        },
        en: {
            ErrorNotEnoughMoney: () => 'Balance insufficient',
            ErrorTransNotPossible: () =>
                'You are not allowed to start this parking procedure (do you have a permit for this zone with different license plate numbers? In this case the maximum number of simultaneous procedures has been reached. You have to wait for another procedure to be terminated).',
            ErrorAlreadyLoggedIn: () =>
                'It was not possible to start a new parking procedure.' +
                'A parking procedure for the selected license plate is already running in this zone.',
            SuccessfulPurchase: () => 'The procedure has been started.',
            ErrorNoParkingTime: () =>
                'No parking procedure is required for the selected duration.',
            TransactionErrorGeneric: () =>
                `${GeneralTexts.texts.en.ErrorTryAgainOrHelpdesk()}`,
            AlreadyLoggedIn: () =>
                'A parking procedure already exists for the selected license plate.',
            ToParkTransaction: () => 'Show procedure',
        },
    };

export const permitEntitySelectionTexts: Text.Translations<PermitEntitySelectionTexts> =
    {
        de: {
            noLpsTitle: () => 'Kein Kennzeichen vorhanden',
            noLpsBody: () => (
                <div>
                    <p>
                        Um einer Bewilligung zu kaufen, müssen Sie mindestens
                        ein Kennzeichen erfassen.
                    </p>
                    <p>Kennzeichen können auch im Konto verwaltet werden.</p>
                </div>
            ),
            noBadgesTitle: () => 'Kein Badge vorhanden',
            noBadgesBody: () => (
                <div>
                    <p>
                        Um einer Bewilligung zu kaufen, müssen Sie mindestens
                        ein Badge erfassen/bestellen.
                    </p>
                    <p>Badges können auch im Konto verwaltet werden.</p>
                </div>
            ),
        },
        fr: {
            noLpsTitle: () => 'Aucune immatriculation disponible',
            noLpsBody: () => (
                <div>
                    <p>
                        Pour pouvoir acheter une autorisation vous devez saisir
                        au moins une immatriculation.
                    </p>
                    <p>
                        Les immatriculations peuvent aussi être gérées dans le
                        compte.
                    </p>
                </div>
            ),
            noBadgesTitle: () => 'Aucune bages disponible',
            noBadgesBody: () => (
                <div>
                    <p>
                        Pour pouvoir acheter une autorisation vous devez
                        saisir/commander au moins un badge.
                    </p>
                    <p>Les badges peuvent aussi être gérés dans le compte.</p>
                </div>
            ),
        },
        it: {
            noLpsTitle: () => 'Nessuna targa disponibile',
            noLpsBody: () => (
                <div>
                    <p>
                        Per poter acquistare un'autorizzazione dovete registrare
                        almeno un numero di targa.
                    </p>
                    <p>
                        I numeri di targa possono anche essere gestiti nel
                        conto.
                    </p>
                </div>
            ),
            noBadgesTitle: () => 'Nessun badge disponibile',
            noBadgesBody: () => (
                <div>
                    <p>
                        Per poter acquistare un'autorizzazione dovete
                        registrare/ordinare almeno un badge.
                    </p>
                    <p>
                        I badges possono anche essere anche gestiti nel conto.
                    </p>
                </div>
            ),
        },
        en: {
            noLpsTitle: () => 'No license plate available',
            noLpsBody: () => (
                <div>
                    <p>
                        To be able to purchase a permit, you must register at
                        least one license plate number.
                    </p>
                    <p>License plates can also be managed in the account.</p>
                </div>
            ),
            noBadgesTitle: () => 'No badge available',
            noBadgesBody: () => (
                <div>
                    <p>
                        To be able to purchase a permit, you must register/order
                        at least one badge.
                    </p>
                    <p>Badges can also be managed in the account.</p>
                </div>
            ),
        },
    };
