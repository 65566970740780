import { useNavigate } from 'react-router-dom';
import { ModalErrorBox, ModalSuccessBox } from '../../ui/modals/Confirmable';

import { Localized } from '../../common/components/Localized';

export function PermitPurchaseConfirmationModal() {
    const navigate = useNavigate();
    return (
        <ModalSuccessBox
            titleCaption={
                <Localized
                    de="Kaufbestätigung"
                    fr="Confirmation achat"
                    it="Conferma acquisto"
                    en="Purchase confirmation"
                />
            }
            confirmCallback={() => navigate('..')}
        >
            <p>
                <Localized
                    de="Die Zahlung wurde ausgeführt und die Bewilligung wurde erfolgreich gekauft."
                    fr="Le paiement a été exécuté et l'autorisation a été achetée avec succès."
                    it="Il pagamento è stato eseguito e l'autorizzazione è stata acquistata con successo."
                    en="The payment has been executed and the permit was successfully purchased."
                />
            </p>
        </ModalSuccessBox>
    );
}

export function PermitPurchaseAbortedModal() {
    const navigate = useNavigate();
    return (
        <ModalErrorBox
            titleCaption={
                <Localized
                    de="Zahlung abgebrochen"
                    fr="Paiement annulé"
                    it="Pagamento annullato"
                    en="Payment aborted"
                />
            }
            confirmCallback={() => navigate('..')}
        >
            <p>
                <Localized
                    de="Die Zahlung wurde abgebrochen. Deshalb wurde keine Bewilligung ausgestellt."
                    fr="Le paiement a été annulé. Par conséquent, aucune autorisation n'a été délivrée."
                    it="Il pagamento è stato annullato. Pertanto, non è stata rilasciata alcuna autorizzazione."
                    en="The payment has been cancelled. Therefore, no permit was issued."
                />
            </p>
        </ModalErrorBox>
    );
}
