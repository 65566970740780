export function normalizedIncludes(search: string, pattern: string): boolean {
    const normalizedSearch = search
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    return normalizedSearch.includes(
        pattern
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
    );
}

export const localeCompare = (a: string, b: string) => a.localeCompare(b);

export namespace Validators {
    export function isUpperCase(str: string): boolean {
        return str === str.toUpperCase();
    }

    export function hasWhiteSpace(str: string): boolean {
        return str.indexOf(' ') >= 0;
    }

    export function isNumber(str: string): boolean {
        const re = /^[0-9]*$/;
        return re.test(str);
    }

    export function isNumeric(str: string): boolean {
        return !isNaN(parseFloat(str)) && isFinite(+str);
    }
}

export const getSortFun =
    (params: { asc: boolean }) => (str1: string, str2: string) => {
        const direction = params.asc ? 1 : -1;
        if (str1 < str2) {
            return -1 * direction;
        }
        if (str1 > str2) {
            return 1 * direction;
        }
        return 0;
    };
