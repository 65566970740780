import { generateState } from 'dg-web-shared/lib/Flux';

export namespace DirectDebitPaymentMethodNavigation {
    export interface State {
        open: boolean;
    }

    export const { get, stateWrite, reset } = generateState<State>(
        'account-payment-method-direct-debit-navigation',
        () => ({ open: false }),
    );
}
