import * as Flux from 'dg-web-shared/lib/Flux';
import * as LayoutState from '../state/LayoutState';
import { LogoSloganVariant } from '../utils/LogoSloganVariant';
import { columnsVariant } from '../utils/columnsVariant';
import { responsiveVariant } from '../utils/responsiveVariant';
import { clearfix } from 'dg-web-shared/ui/clearfix';
import { css } from '@emotion/css';
import { LayoutVariantOption } from './LayoutContainer';

export interface ColumnContainerProps {
    children?: React.ReactNode;
    relative?: boolean;
    allState: Flux.Store;
    layoutVariant: LayoutVariantOption;
}
export interface ColumnContainerState {
    layout: LayoutState.State;
}
export class ColumnContainer extends Flux.ContainerWithProps<
    ColumnContainerProps,
    ColumnContainerState
> {
    static displayName = 'ColumnContainer';

    stateSelector(): ColumnContainerState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const logoSloganVariant = new LogoSloganVariant(
            this.state,
            this.props.layoutVariant,
        );
        return (
            <div className={clearfix}>
                <div
                    className={css([
                        {
                            clear: 'left',
                            bottom: '40px',
                            left: 0,
                            right: 0,
                            position: 'absolute',
                        },
                        this.props.relative && {
                            position: 'relative',
                        },
                        columnsVariant(this.state) === 'ThreeColumns' && [
                            logoSloganVariant.toString() === 'Large' && {
                                top: '128px',
                            },
                            logoSloganVariant.toString() === 'Medium' && {
                                top: '104px',
                            },
                            logoSloganVariant.toString() === 'Small' && {
                                top: '80px',
                            },
                        ],
                        columnsVariant(this.state) === 'OneColumn' && [
                            {
                                top: 0,
                                bottom: 0,
                            },
                            this.props.layoutVariant ===
                                LayoutVariantOption.StaticPage && [
                                { paddingTop: '40px', marginTop: '16px' },
                                responsiveVariant(this.state) === 'Mobile' && {
                                    paddingTop: 0,
                                },
                            ],
                        ],
                    ])}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
