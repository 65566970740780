import * as Http from '../../api/Http';
import * as CommonQueryActions from '../actions/CommonQueryActions';
import * as Flux from 'dg-web-shared/lib/Flux';
import * as ServerState from './ServerState';
import * as ServerStateSlice from './ServerStateSlice';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import { compareLicensePlateCountries } from 'dg-web-shared/dto/LicensePlateCountry';

export type LicensePlateCountry = Http.LicensePlateCountry;
export type LicensePlateType = Http.LicensePlateType;
export enum LicensePlateTypeEnum {
    Car = 0,
    Motorbike = 1,
}

export type State = ServerState.State<LicensePlateCountry[], void>;
type EmptyData = ServerStateSlice.EmptyData<LicensePlateCountry[], void>;

export function getEmptyData(): ServerState.EmptyData<
    LicensePlateCountry[],
    void
> {
    return { data: [], errorData: undefined };
}

function bodyParser(data: LicensePlateCountry[]): LicensePlateCountry[] {
    return data.sort(compareLicensePlateCountries);
}

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<
    LicensePlateCountry[],
    void
> {
    key(): string {
        return 'common-LicensePlateCountriesState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(
                CommonQueryActions.receiveLicensePlateCountries,
            );
        }
    }

    getEmptyData(): EmptyData {
        return { data: [], errorData: undefined };
    }

    writeRequest(res: Response): void {
        if (res.pending) {
            this.writePending();
        } else if (res.statusCode.cls.success) {
            this.writeSuccess(res);
            this.set((s: State): State => {
                // parse
                if (res.body) {
                    s.data = bodyParser(<any>res.body);
                }
                return s;
            });
        } else {
            this.writeError(res);
        }
    }
}
