import { LandingPageAnchor } from './LandingPageFooter';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Updater, localState } from 'dg-web-shared/lib/Flux';
import { logAction } from '../../utils/ActionLog';
import { Message, JsxMessage } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../common/components/Localized';
import {
    BlueBackdrop,
    BlueBackdropHeader,
    LoadAppOrSignUp,
    ButtonType,
} from './LandingPageShared';
import { Typo } from '../../style/typo';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

interface FAQItemProps {
    id: keyof FAQContent;
    expanded: boolean;
    onExpand: () => void;
    onContract: () => void;
}

export const LandingPageFAQ = localState<
    { update: Updater; showLoadApp: boolean },
    { expandedQuestionId: keyof FAQContent | null }
>({ expandedQuestionId: null }, p => (
    <BlueBackdrop image={'mood'}>
        <BlueBackdropHeader anchor={LandingPageAnchor.faq}>
            <Localized
                de="Häufig gestellte Fragen"
                fr="Foire aux questions"
                it="Domande frequenti"
                en="Frequently asked questions"
            />
        </BlueBackdropHeader>
        <div
            className={css({
                ...Typo.robotoLight,
                color: Colors.black,
                fontSize: '16px',
                lineHeight: '22px',
                height: '100%',
            })}
        >
            {QuestionIds().map(id => (
                <FAQItem
                    key={id}
                    id={id}
                    expanded={id === p.state.expandedQuestionId}
                    onExpand={() => {
                        p.setState({ expandedQuestionId: id });
                    }}
                    onContract={() => {
                        p.setState({ expandedQuestionId: null });
                    }}
                />
            ))}
        </div>
        <LoadAppOrSignUp
            update={p.update}
            showLoadApp={p.showLoadApp}
            type={ButtonType.white}
        />
    </BlueBackdrop>
));

const FAQItem = (props: FAQItemProps) => {
    const content = FAQContent[props.id];
    return (
        <div
            className={css({
                fontSize: '16px',
                lineHeight: '1.5',
                letterSpacing: '0.1px',
                marginBottom: '25px',
            })}
        >
            <div>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        color: Colors.white,
                        ...Typo.robotoBold,
                    })}
                    onClick={() => {
                        if (!props.expanded) {
                            logAction(
                                null,
                                'landing-page-open-faq-question',
                                props.id,
                            );
                            props.onExpand();
                        } else {
                            props.onContract();
                        }
                    }}
                >
                    <div
                        className={css({
                            flex: '1',
                            textAlign: 'left',
                            marginBottom: '8px',
                        })}
                    >
                        <Localized {...content.question} />
                    </div>
                    <div>
                        <Icon
                            icon={props.expanded ? 'chevronUp' : 'chevronDown'}
                        />
                    </div>
                </div>
                {props.expanded && (
                    <div
                        className={css({
                            background: Colors.white,
                            color: Colors.darkblue,
                            ...Typo.robotoRegular,
                            padding: '12px 15px',
                            a: {
                                textDecoration: 'none',
                                color: Colors.darkblue,
                            },
                            ul: {
                                margin: 0,
                            },
                        })}
                    >
                        <Localized {...content.answer} />
                    </div>
                )}
            </div>
        </div>
    );
};

interface FAQTextItem {
    question: Message;
    answer: Message | JsxMessage;
}
interface FAQContent {
    AdditionalCosts: FAQTextItem;
    PaymentMethods: FAQTextItem;
    Enforcement: FAQTextItem;
    OtherQuestions: FAQTextItem;
}

const Impressum = () => {
    return (
        <div
            className={css({ fontSize: 14, marginTop: 12, textAlign: 'right' })}
        >
            Digitalparking AG
            <br />
            Rütistrasse 13
            <br />
            8952 Schlieren <br />
        </div>
    );
};

const FAQContent: FAQContent = {
    AdditionalCosts: {
        question: {
            de: 'Entstehen für mich zusätzliche Kosten?',
            fr: `Est-ce que j'engagerai des frais supplémentaires?`,
            it: `Dovrò sostenere dei costi aggiuntivi?`,
            en: `Are there any additional costs for me?`,
        },
        answer: {
            de: (
                <>
                    <p>
                        In der Regel <b>gelten dieselben Tarife</b> wie bei der
                        Parkuhr/an der Kasse.
                    </p>
                    <p>
                        Bei entsprechendem und ausdrücklichem Hinweis in der
                        App, werden Zuschläge bei den Parkplätzen einzelner
                        Betreiber und/oder für die Parkingpay Zusatzfunktionen
                        erhoben.
                    </p>
                    <p>Zusatzfunktionen:</p>
                    <p>
                        <ul>
                            <li>
                                <b>Einzelbelastung der Zahlungsmittel</b>
                                <br />
                                (CHF 0.25 pro Zahlung)
                            </li>
                            <li>
                                <b>Ein-/Ausfahren mit Kennzeichenerkennung</b>
                                <br />
                                (CHF 0.25 pro kostenpflichtigen Parkvorgang)
                            </li>
                            <li>
                                <b>Firmenkonti auf Rechnung</b>
                                <br />
                                (CHF 18.50 bzw. 22.50 pro Rechnung)
                            </li>
                        </ul>
                    </p>
                    <p>Die Zusatzfunktionen sind standardmäßig inaktiv.</p>
                </>
            ),
            fr: (
                <>
                    <p>
                        En règle générale, <b>les tarifs sont les mêmes</b>{' '}
                        qu'au parcmètre/à la caisse.
                    </p>
                    <p>
                        Avec une indication appropriée et explicite dans
                        l'appli, des suppléments sont prélevés sur les places de
                        stationnement de quelques exploitants et/ou pour
                        l'utilisation des fonctions additionnelles Parkingpay.
                    </p>
                    <p>Fonctions additionnelles:</p>
                    <p>
                        <ul>
                            <li>
                                <b>débit individuel des moyens de paiement</b>
                                <br />
                                (CHF 0.25 par paiement)
                            </li>
                            <li>
                                <b>
                                    entrer/sortir avec reconnaissance des
                                    plaques
                                </b>
                                <br />
                                (CHF 0.25 par procédure payant)
                            </li>
                            <li>
                                <b>comptes entreprises sur facture</b>
                                <br />
                                (CHF 18.50 resp. 22.50 par facture)
                            </li>
                        </ul>
                    </p>
                    <p>
                        Les fonctions additionnelles sont inactives par défaut.
                    </p>
                </>
            ),
            it: (
                <>
                    <p>
                        Di norma si applicano <b>le stesse tariffe </b>previste
                        al parchimetro/alla cassa.
                    </p>
                    <p>
                        Laddove espressamente indicato nell'app, sono previsti
                        dei supplementi sui parcheggi di alcuni gestori e/o per
                        l'utilizzo delle funzioni aggiuntive Parkingpay.
                    </p>
                    <p>Funzioni aggiuntive:</p>
                    <p>
                        <ul>
                            <li>
                                <b>
                                    addebito individuale sul mezzo di pagamento
                                </b>
                                <br />
                                (CHF 0.25 per pagamento)
                            </li>
                            <li>
                                <b>
                                    entrata/uscita con riconoscimento della
                                    targa
                                </b>
                                <br />
                                (CHF 0.25 per procedura a pagamento)
                            </li>
                            <li>
                                <b>conti aziendali contro fattura</b>
                                <br />
                                (CHF 18.50 risp. 22.50 per fattura)
                            </li>
                        </ul>
                    </p>
                    <p>Le funzioni aggiuntive sono inattive per default.</p>
                </>
            ),
            en: (
                <>
                    <p>
                        As a rule, <b>same tariffs apply</b> the for the parking
                        meter/cash register.
                    </p>
                    <p>
                        Where explicitly indicated in the app, surcharges are
                        applicable on the parking spaces of specific operators
                        and/or for the use of the Parkingpay additional
                        functions.
                    </p>
                    <p>Additional functions:</p>
                    <p>
                        <ul>
                            <li>
                                <b>
                                    individual charges on the means of payment
                                </b>
                                <br />
                                (CHF 0.25 per payment)
                            </li>
                            <li>
                                <b>entry/exit with license plate recognition</b>
                                <br />
                                (CHF 0.25 per paid procedure)
                            </li>
                            <li>
                                <b>company accounts with invoice</b>
                                <br />
                                (CHF 18.50 resp. 22.50 per invoice)
                            </li>
                        </ul>
                    </p>
                    <p>Additional functions are inactive by default.</p>
                </>
            ),
        },
    },
    PaymentMethods: {
        question: {
            de: 'Mit welchen Zahlungsmitteln kann ich bezahlen?',
            fr: 'Quels sont les moyens de paiement disponibles?',
            it: 'Quali sono i metodi di pagamento disponibili?',
            en: 'With which means of payment can I pay?',
        },
        answer: {
            de: (
                <p>
                    <ul>
                        <li>TWINT</li>
                        <li>Apple Pay / Google Pay</li>
                        <li>Kredit-/Debitkarten</li>
                        <li>Postfinance Card</li>
                        <li>LSV</li>
                        <li>E-Banking</li>
                        <li>QR-Einzahlungsschein</li>
                        <li>Auf Rechnung (Nur Firmen)</li>
                    </ul>
                </p>
            ),
            fr: (
                <p>
                    <ul>
                        <li>TWINT</li>
                        <li>Apple Pay / Google Pay</li>
                        <li>Cartes de crédit/débit</li>
                        <li>Postfinance Card</li>
                        <li>Prélèv. autom. (LSV)</li>
                        <li>E-banking</li>
                        <li>Bulletin de versement QR</li>
                        <li>Sur facture (uniquement pour les entreprises)</li>
                    </ul>
                </p>
            ),
            it: (
                <p>
                    <ul>
                        <li>TWINT</li>
                        <li>Apple Pay / Google Pay</li>
                        <li>Carte di credito/debito</li>
                        <li>Postfinance Card</li>
                        <li>Addebito diretto (LSV)</li>
                        <li>E-banking</li>
                        <li>Polizza di versamento QR</li>
                        <li>Contro fattura (solo per aziende)</li>
                    </ul>
                </p>
            ),
            en: (
                <p>
                    <ul>
                        <li>TWINT</li>
                        <li>Apple Pay / Google Pay</li>
                        <li>Credit/debit cards</li>
                        <li>Postfinance Card</li>
                        <li>Direct debit (LSV)</li>
                        <li>E-banking</li>
                        <li>QR payment slip</li>
                        <li>Invoice (companies only)</li>
                    </ul>
                </p>
            ),
        },
    },
    Enforcement: {
        question: {
            de: 'Woher weiss der Kontrolleur, dass ich bezahlt habe?',
            fr: `Comment le contrôleur de parking sait-il que j'ai payé?`,
            it: 'Il controllore del parcheggio come fa a sapere che ho pagato?',
            en: 'How does the enforcement agent know that I have paid?',
        },
        answer: {
            de: (
                <p>
                    Der Kontrolleur verfügt über ein Kontrollgerät, welches
                    online mit unserem System verbunden ist. Mit diesem Gerät
                    wird das Kennzeichen des Fahrzeuges gescannt. So wird
                    festgestellt, ob die Parkgebühr bezahlt wurde.
                </p>
            ),
            fr: (
                <p>
                    Le contrôleur dispose d'un dispositif de contrôle connecté
                    en ligne à notre système. Avec cet appareil, la plaque
                    d'immatriculation du véhicule est scannée. Ceci permet de
                    déterminer si les frais de stationnement ont été payés.
                </p>
            ),
            it: (
                <p>
                    Il controllore dispone di un dispositivo collegato online al
                    nostro sistema. Con questo dispositivo viene scansionata la
                    targa del veicolo. Questo determinerà se la tassa di
                    parcheggio è stata pagata.
                </p>
            ),
            en: (
                <p>
                    The enforcement agent has got a device, which is connected
                    online to our system. With this device, the license plate of
                    the vehicle is scanned. This will determine if the parking
                    fee has been paid.
                </p>
            ),
        },
    },
    OtherQuestions: {
        question: {
            de: 'Weitere Fragen?',
            fr: `Plus de questions?`,
            it: 'Altre domande?',
            en: 'Further questions?',
        },
        answer: {
            de: (
                <p>
                    <b>Parkingpay-Helpdesk</b>
                    <br />
                    <a href="mailto:info@parkingpay.ch">info@parkingpay.ch</a>
                    <Impressum />
                </p>
            ),
            fr: (
                <p>
                    <b>Helpdesk Parkingpay</b>
                    <br />
                    <a href="mailto:info@parkingpay.ch">info@parkingpay.ch</a>
                    <Impressum />
                </p>
            ),
            it: (
                <p>
                    <b>Helpdesk Parkingpay</b>
                    <br />
                    <a href="mailto:info@parkingpay.ch">info@parkingpay.ch</a>
                    <Impressum />
                </p>
            ),
            en: (
                <p>
                    <b>Parkingpay Helpdesk</b>
                    <br />
                    <a href="mailto:info@parkingpay.ch">info@parkingpay.ch</a>
                    <Impressum />
                </p>
            ),
        },
    },
};

function QuestionIds(): (keyof FAQContent)[] {
    return Object.keys(FAQContent) as (keyof FAQContent)[];
}
