import { InputContext } from '../../../../../tb-ui/inputs/InputContext';
import { IndentedListParkingPay } from '../../../common/components/IndentedListParkingPay';
import * as PermitTexts from '../../i18n/PermitTexts';
import { TypeData } from '../../state/permit/PermitPurchaseState';
import { SelectableOptions } from './SelectableOptions';
import { processZones } from 'dg-web-shared/common/components/IndentedList';

export interface Texts {
    Zones: (i: number) => string;
}

interface Props {
    type: TypeData.Type;
    selectedZoneIds: number[];
    language: string;
    onToggleMultiple: (arg: { id: number; max: 1 | null }) => void;
}

function NotSelectableZonesFactory(
    title: string,
    zones: TypeData.Zone[],
): React.ReactNode {
    const entries = processZones(zones);
    if (!entries) {
        return null;
    }
    return (
        <IndentedListParkingPay
            title={title}
            entries={entries}
            context={InputContext.inverted}
        />
    );
}

export function zonesAreSelectable(type: TypeData.Type): boolean {
    return type.zones.length > 1 && type.selectZones !== 'FIXED_ZONES';
}

export function Zones({
    type,
    language,
    selectedZoneIds,
    onToggleMultiple,
}: Props) {
    const texts = PermitTexts.additionalZonesTexts[language];
    if (zonesAreSelectable(type)) {
        const selectableOptions = type.zones.map(zone => ({
            selected: selectedZoneIds.includes(zone.id),
            description: `${!zone.extZoneCode ? '' : zone.extZoneCode + ' | '}${
                zone.name
            } - ${zone.city}`,
            id: zone.id,
        }));

        return (
            <SelectableOptions
                selectableOptions={selectableOptions}
                onSelect={(id: number): void =>
                    onToggleMultiple({
                        id: id,
                        max: type.selectZones === 'SINGLE_CHOICE' ? 1 : null,
                    })
                }
                Label={texts.Zones}
                multiSelection={type.selectZones === 'MULTIPLE_CHOICE'}
            />
        );
    }

    return (
        <div>
            {NotSelectableZonesFactory(
                texts.Zones(type.zones.length),
                type.zones,
            )}
        </div>
    );
}
