import { generateState } from 'dg-web-shared/lib/Flux';

export namespace TopUpBalanceNavigationState {
    export interface State {
        displayMethods: boolean;
    }

    export const { get, stateWrite, reset } = generateState<State>(
        'account-top-up-balance-methods-navigation',
        { displayMethods: false },
    );
}
