import {
    ButtonText,
    ModalErrorBox,
    ModalQuestionBox,
    ModalSuccessBox,
} from '../../ui/modals/Confirmable';
import {
    deactivatePaymentModalMessage,
    deactivatePaymentModalTitle,
    paymentAbortedModalTitle,
    paymentAbortedModalMessage,
    paymentFailedModalTitle,
    paymentSuccessfulModalMessage,
    paymentSuccessfulModalTitle,
    deactivatePaymentFailedModalMessage,
} from '../../common/components/ticketplus/TicketPlusModalTexts';
import { PaymentModalState } from './QuickCheckoutTypes';
import { CardPaymentFailedMessage } from '../../common/components/PaymentCallback';

const PaymentSuccessfulModal = ({ onConfirm }: { onConfirm: () => void }) => (
    <ModalSuccessBox
        titleCaption={paymentSuccessfulModalTitle}
        confirmCallback={onConfirm}
    >
        <p>{paymentSuccessfulModalMessage}</p>
    </ModalSuccessBox>
);
const PaymentAbortedModal = ({ onConfirm }: { onConfirm: () => void }) => (
    <ModalErrorBox
        titleCaption={paymentAbortedModalTitle}
        confirmCallback={onConfirm}
    >
        <p>{paymentAbortedModalMessage}</p>
    </ModalErrorBox>
);
const PaymentFailedModal = ({ onConfirm }: { onConfirm: () => void }) => (
    <ModalErrorBox
        titleCaption={paymentFailedModalTitle}
        confirmCallback={onConfirm}
    >
        <p>
            <CardPaymentFailedMessage />
        </p>
    </ModalErrorBox>
);
export const PaymentStateModal = ({
    state,
    onConfirm,
}: {
    state: PaymentModalState;
    onConfirm: () => void;
}): JSX.Element => {
    switch (state) {
        case PaymentModalState.SUCCESSFUL:
            return <PaymentSuccessfulModal onConfirm={onConfirm} />;
        case PaymentModalState.ABORTED:
            return <PaymentAbortedModal onConfirm={onConfirm} />;
        case PaymentModalState.FAILED:
            return <PaymentFailedModal onConfirm={onConfirm} />;
    }
};

export const RevokePaymentModal = ({
    onConfirm,
    onCancel,
}: {
    onConfirm: () => void;
    onCancel: () => void;
}) => (
    <ModalQuestionBox
        titleCaption={deactivatePaymentModalTitle}
        confirmCaption={ButtonText.YES}
        confirmCallback={onConfirm}
        cancelCaption={ButtonText.NO}
        cancelCallback={onCancel}
    >
        <p>{deactivatePaymentModalMessage}</p>
    </ModalQuestionBox>
);

export const RevokePaymentErrorModal = ({
    onConfirm,
}: {
    onConfirm: () => void;
}) => (
    <ModalErrorBox confirmCallback={onConfirm}>
        <p>{deactivatePaymentFailedModalMessage}</p>
    </ModalErrorBox>
);
