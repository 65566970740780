import { css } from '@emotion/css';

import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import { Maybe, isDefined } from 'dg-web-shared/lib/MaybeV2';
import { paper } from '../paper';
import { Colors } from 'dg-web-shared/ui/vars';
import React from 'react';
import { Icon, Icon16 } from 'dg-web-shared/ui/icons/Icon';

interface Props {
    icon: React.ReactNode;
    disabled?: boolean;
    small?: boolean;
    link?: Maybe<string>;
    icon16px?: boolean;
    type?: IconButtonType;
    onClick: ClickHandler;
    key?: React.Key;
}

export enum IconButtonType {
    regular = 1,
    inverted,
    confirm,
    stop,
}

function getColor(type: IconButtonType): string {
    switch (type) {
        case IconButtonType.regular:
            return Colors.blue;
        case IconButtonType.inverted:
            return Colors.white;
        case IconButtonType.confirm:
            return Colors.white;
        case IconButtonType.stop:
            return Colors.white;
    }
}

function getBackgroundColor(type: IconButtonType): string {
    switch (type) {
        case IconButtonType.regular:
            return Colors.white;
        case IconButtonType.inverted:
            return Colors.blue;
        case IconButtonType.confirm:
            return Colors.green;
        case IconButtonType.stop:
            return Colors.error;
    }
}

export class IconButton extends React.Component<Props> {
    render() {
        const type = this.props.type || IconButtonType.inverted;
        const color = getColor(type);
        const backgroundColor = getBackgroundColor(type);
        const circle = (
            <Clickable
                element="div"
                className={css([
                    {
                        cursor: 'pointer',
                        position: 'absolute',
                        borderRadius: '100%',
                        ...paper(2),
                        background: backgroundColor,
                        color: color,
                    },
                    this.props.disabled && {
                        cursor: 'default',
                        opacity: 0.4,
                    },
                    this.props.small && {
                        top: '12px',
                        left: '12px',
                        width: '24px',
                        height: '24px',
                    },
                    !this.props.small && {
                        top: '4px',
                        left: '4px',
                        width: '40px',
                        height: '40px',
                        padding: '8px',
                    },
                    this.props.icon16px && {
                        paddingTop: '4px',
                        paddingLeft: '4px',
                    },
                ])}
                disabled={this.props.disabled || false}
                onClick={this.props.onClick}
            >
                {this.props.icon16px ? (
                    <Icon16 icon={this.props.icon} />
                ) : (
                    <Icon icon={this.props.icon} />
                )}
            </Clickable>
        );

        if (isDefined(this.props.link)) {
            return (
                <a
                    className={css({
                        textDecoration: 'none',
                        display: 'inline-block',
                        position: 'relative',
                        width: '48px',
                        height: '48px',
                    })}
                    tabIndex={-1}
                    target={this.props.link ? '_blank' : undefined}
                    href={this.props.link}
                    rel="noreferrer"
                >
                    {circle}
                </a>
            );
        } else {
            return (
                <div
                    className={css({
                        position: 'relative',
                        width: '48px',
                        height: '48px',
                    })}
                >
                    {circle}
                </div>
            );
        }
    }
}
