import * as LayoutState from '../state/LayoutState';
import { ResponsiveProperties } from './ResponsiveProperties';

interface ResponsiveVariantInputs {
    layout: LayoutState.State;
}
export function responsiveVariant(s: ResponsiveVariantInputs): string {
    const responsiveProps = new ResponsiveProperties(s);
    return responsiveProps.toString();
}
