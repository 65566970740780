import { RenderableLicensePlate } from 'dg-web-shared/dto/LicensePlate';
import * as Flux from 'dg-web-shared/lib/Flux';
import { getOrElse, thenElse } from 'dg-web-shared/lib/MaybeV2';
import { stringToFourCharacterBlocks } from 'dg-web-shared/lib/StringConversions';
import * as BadgeTypesState from '../../../common/state/BadgeTypesState';
import * as SettingsState from '../../../common/state/SettingsState';
import { badgeTexts } from '../../i18n/ParkCreateTexts';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { Translation } from 'dg-web-shared/lib/Text';
import { css } from '@emotion/css';
import { Typo } from '../../../../../tb-ui/typo';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export interface CurrentBadgeSelectionTexts {
    BadgeOrdered: Translation;
}
export const LicensePlateEntityComp = (p: {
    licensePlate: RenderableLicensePlate;
}): JSX.Element => {
    return (
        <div
            className={css({
                flex: 1,
                alignItems: 'baseline',
                display: 'flex',
            })}
        >
            <div className={css({ minWidth: '100px', ...Typo.body })}>
                {p.licensePlate.licensePlateNr}
            </div>
            <div
                className={css({ padding: '0 3px', flex: 1, ...Typo.caption })}
            >
                {getOrElse(p.licensePlate.remarks, '')}
            </div>
            <div className={css({ minWidth: '30px', ...Typo.caption })}>
                {p.licensePlate.countryId}
            </div>
            <div className={css({ width: '24px' })}>
                <div className={css({ marginBottom: '-3px' })}>
                    <Icon
                        icon={
                            p.licensePlate.type === LicensePlateType.MOTORCYCLE
                                ? 'bike'
                                : 'car'
                        }
                    />
                </div>
            </div>
        </div>
    );
};

interface BadgeEntityProps {
    labelNr: string;
    remarks: string | null;
    type: number;
}
interface BadgeEntityState {
    settings: SettingsState.State;
    badgeTypes: BadgeTypesState.List.State;
}
export const BadgeEntityComp = Flux.selectState<
    BadgeEntityProps,
    BadgeEntityState
>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        badgeTypes: BadgeTypesState.List.get(store),
    }),
    p => (
        <div
            className={css({
                flex: 1,
                alignItems: 'baseline',
                display: 'flex',
            })}
        >
            <div
                className={css({
                    minWidth: 0,
                    marginRight: '30px',
                    ...Typo.body,
                })}
            >
                {!p.labelNr || p.labelNr === 'ORDERED' ? (
                    <div className={css({ ...Typo.bodyItalic })}>
                        {badgeTexts[p.settings.language].BadgeOrdered()}
                    </div>
                ) : p.type ===
                  BadgeTypesState.BadgeTypeEnum.PARKINGPAY_BADGE ? (
                    stringToFourCharacterBlocks(p.labelNr)
                ) : (
                    p.labelNr
                )}
            </div>
            <div className={css({ padding: '0 3px', ...Typo.caption })}>
                {p.remarks || ''}
            </div>
            <div className={css({ ...Typo.caption })}>
                {p.type !== BadgeTypesState.BadgeTypeEnum.PARKINGPAY_BADGE
                    ? thenElse(
                          BadgeTypesState.getBadgeTypeById(
                              p.badgeTypes.data,
                              p.type,
                          ),
                          bt => bt.name[p.settings.language],
                          '',
                      )
                    : ''}
            </div>
        </div>
    ),
);
