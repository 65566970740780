import { CurrentLogin } from '../../current-login/components/CurrentLogin';
import * as SettingsState from '../../../common/state/SettingsState';
import { SpinnerCanvas } from '../../../../../pc-ui/md/layout/SpinnerCanvas';
import { Reminder } from './AccountAdmin';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { useStore } from 'dg-web-shared/lib/Flux';

export function AccountBlock() {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        currentLogin: CurrentLoginState.get(s),
    }));

    return (
        <SpinnerCanvas
            showSpinner={storeState.currentLogin.status === 'pending'}
        >
            <CurrentLogin />
            <Reminder
                language={storeState.settings.language}
                cl={storeState.currentLogin.data}
            />
        </SpinnerCanvas>
    );
}
