import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { Translation, Translations } from '../../common/i18n/Text';
import { ParkTransactionSlideInTexts } from '../checkin-by-plate/CheckinByPlateSlideIn';
import { ActiveStartStopTransactionTexts as RunningCheckinByPlateTexts } from '../checkin-by-plate/RunningCheckinByPlate';
import { OffstreetCheckinDetailTexts } from '../components/OffstreetCheckinDetailSlideIn';
import { TransactionsListTexts } from '../components/TransactionsList';

export const common: Translations<{
    StartDate: Translation;
    Location: Translation;
}> = {
    de: {
        StartDate: () => 'Beginn',
        Location: () => GeneralTexts.texts.de.Location(),
    },
    fr: {
        StartDate: () => 'Début',
        Location: () => GeneralTexts.texts.fr.Location(),
    },
    it: {
        StartDate: () => 'Inizio',
        Location: () => GeneralTexts.texts.it.Location(),
    },
    en: {
        StartDate: () => 'Start',
        Location: () => GeneralTexts.texts.en.Location(),
    },
};

export const expiredParkTransactionTexts: Translations<{
    StartDate: Translation;
    EndDate: Translation;
    Operator: Translation;
    Zone: Translation;
    ParkDuration: Translation;
    ParkPrice: Translation;
    ParkPriceSurcharge: Translation;
    ParkPriceTotal: Translation;
    LicensePlate: Translation;
    DownloadReceipt: Translation;
}> = {
    de: {
        StartDate: () => common.de.StartDate(),
        EndDate: () => 'Ende',
        Operator: () => GeneralTexts.texts.de.Operator(),
        Zone: () => GeneralTexts.texts.de.Zone(),
        ParkDuration: () => 'Dauer',
        ParkPrice: () => 'Parkgebühr',
        ParkPriceSurcharge: () => 'Zuschlag',
        ParkPriceTotal: () => 'Preis',
        LicensePlate: () => GeneralTexts.texts.de.LicensePlate(),
        DownloadReceipt: () => 'Beleg anzeigen',
    },
    fr: {
        StartDate: () => common.fr.StartDate(),
        EndDate: () => 'Fin',
        Operator: () => GeneralTexts.texts.fr.Operator(),
        Zone: () => GeneralTexts.texts.fr.Zone(),
        ParkDuration: () => 'Durée',
        ParkPrice: () => 'Taxe de stationnement',
        ParkPriceSurcharge: () => 'Supplément',
        ParkPriceTotal: () => 'Prix',
        LicensePlate: () => GeneralTexts.texts.fr.LicensePlate(),
        DownloadReceipt: () => 'Afficher document',
    },
    it: {
        StartDate: () => common.it.StartDate(),
        EndDate: () => 'Fine',
        Operator: () => GeneralTexts.texts.it.Operator(),
        Zone: () => GeneralTexts.texts.it.Zone(),
        ParkDuration: () => 'Durata',
        ParkPrice: () => 'Tassa di parcheggio',
        ParkPriceSurcharge: () => 'Supplemento',
        ParkPriceTotal: () => 'Prezzo',
        LicensePlate: () => GeneralTexts.texts.it.LicensePlate(),
        DownloadReceipt: () => 'Mostra documento',
    },
    en: {
        StartDate: () => common.en.StartDate(),
        EndDate: () => 'End',
        Operator: () => GeneralTexts.texts.en.Operator(),
        Zone: () => GeneralTexts.texts.en.Zone(),
        ParkDuration: () => 'Duration',
        ParkPrice: () => 'Parking Fee',
        ParkPriceSurcharge: () => 'Surcharge',
        ParkPriceTotal: () => 'Price',
        LicensePlate: () => GeneralTexts.texts.en.LicensePlate(),
        DownloadReceipt: () => 'Show document',
    },
};

export const runningCheckinByPlateTexts: RunningCheckinByPlateTexts = {
    licensePlate: {
        de: GeneralTexts.texts.de.LicensePlate(),
        fr: GeneralTexts.texts.fr.LicensePlate(),
        it: GeneralTexts.texts.it.LicensePlate(),
        en: GeneralTexts.texts.en.LicensePlate(),
    },
    vehicle: {
        de: GeneralTexts.texts.de.Vehicle(),
        fr: GeneralTexts.texts.fr.Vehicle(),
        it: GeneralTexts.texts.it.Vehicle(),
        en: GeneralTexts.texts.en.Vehicle(),
    },
    stopTransaction: {
        de: 'Jetzt stoppen',
        fr: 'Arrêter maintenant',
        it: 'Termina adesso',
        en: 'Stop now',
    },
    stopping: {
        de: 'wird gestoppt…',
        fr: 'arrête en cours…',
        it: 'fine in corso…',
        en: 'stopping…',
    },
    timeEndsAtDate: {
        de: 'Endet am',
        fr: 'Sera terminée le',
        it: 'Verrà terminata il',
        en: 'Ends at',
    },
    timeEndsAtTime: {
        de: 'Endet um',
        fr: 'Sera terminée à',
        it: 'Verrà terminata alle',
        en: 'Ends at',
    },
    changeParkDuration: {
        de: 'Parkdauer ändern',
        fr: 'Modifier la durée',
        it: 'Modifica durata',
        en: 'Change duration',
    },
    price: {
        de: 'Preis (in CHF)',
        fr: 'Prix (en CHF)',
        it: 'Prezzo (in CHF)',
        en: 'Price (in CHF)',
    },
    saveParkDuration: {
        de: 'Parkdauer übernehmen',
        fr: 'Confirmer la durée',
        it: 'Conferma durata',
        en: 'Save duration',
    },
    runningTransaction: {
        de: 'Laufender Parkvorgang',
        fr: 'Procédure en cours',
        it: 'Procedura in corso',
        en: 'Running procedure',
    },
    zoneDetails: {
        de: 'Zone',
        fr: 'Zone',
        it: 'Zona',
        en: 'Zone',
    },
    startWithOtherPlate: {
        de: 'Neue Parkvorgang/Bewilligung mit anderem Kennzeichen',
        fr: 'Nouvelle procédure/autorisation avec une immatriculation différente',
        it: 'Nuova procedura/autorizzazione con un altro numero di targa',
        en: 'New transaction/permit with other license palte',
    },
};

export const transactionsListTexts: Translations<TransactionsListTexts> = {
    de: {
        title: () => 'Transaktionen',
        activeItemsHeader: () => 'Gültige',
        needTransactionHeader: () => 'Nur gültig, wenn aktiviert',
        futureItemsHeader: () => 'Zukünftig Gültige',
        expiredItemsHeader: () => 'Abgelaufene',
        validFrom: validFrom => `ab ${validFrom}`,
    },
    fr: {
        title: () => 'Transactions',
        activeItemsHeader: () => 'Valables',
        needTransactionHeader: () => 'Valables seulement si activées',
        futureItemsHeader: () => 'Valables dans le future',
        expiredItemsHeader: () => 'Échues',
        validFrom: validFrom => `du ${validFrom}`,
    },
    it: {
        title: () => 'Transazioni',
        activeItemsHeader: () => 'Valide',
        needTransactionHeader: () => 'Valide solo se attivate',
        futureItemsHeader: () => 'Valide in futuro',
        expiredItemsHeader: () => 'Scadute',
        validFrom: validFrom => `dal ${validFrom}`,
    },
    en: {
        title: () => 'Transactions',
        activeItemsHeader: () => 'Valid',
        needTransactionHeader: () => 'Only valid if activated',
        futureItemsHeader: () => 'Valid in the future',
        expiredItemsHeader: () => 'Expired',
        validFrom: validFrom => `from ${validFrom}`,
    },
};

export const parkTransactionDetailTexts: Translations<ParkTransactionSlideInTexts> =
    {
        de: {
            titleActiveTransaction: () => 'Parkvorgang',
            titleActiveTransactionDetail: () => 'Details',
            titleExpiredTransaction: () => 'Parkvorgang',
        },
        fr: {
            titleActiveTransaction: () => 'Procédure',
            titleActiveTransactionDetail: () => 'Détails',
            titleExpiredTransaction: () => 'Procédure',
        },
        it: {
            titleActiveTransaction: () => 'Procedura',
            titleActiveTransactionDetail: () => 'Dettagli',
            titleExpiredTransaction: () => 'Procedura',
        },
        en: {
            titleActiveTransaction: () => 'Procedure',
            titleActiveTransactionDetail: () => 'Details',
            titleExpiredTransaction: () => 'Procedure',
        },
    };

export const offstreetTransactionDetailTexts: Translations<OffstreetCheckinDetailTexts> =
    {
        de: {
            title: () => 'Parkvorgang',
        },
        fr: {
            title: () => 'Procédure',
        },
        it: {
            title: () => 'Procedura',
        },
        en: {
            title: () => 'Procedure',
        },
    };
