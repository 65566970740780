import * as Texts from './common/i18n/Text';
import { Language, Message } from 'dg-web-shared/lib/Localized';

export function fromOldNotation<
    T extends { [P in keyof T]: Texts.Translation },
>(key: keyof T, oldTexts: Texts.Translations<T>): Message {
    return {
        [Language.DE]: oldTexts.de[key](),
        [Language.FR]: oldTexts.fr[key](),
        [Language.IT]: oldTexts.it[key](),
        [Language.EN]: oldTexts.en[key](),
    };
}
