import { Language } from 'dg-web-shared/lib/Localized';
import {
    PaymentAttemptResponseDatatrans,
    PaymentAttemptResponseParams,
    PaymentAttemptResponseTwintUrl,
    PaymentCategory,
    PaymentProviderType,
    PaymentRequestMode,
} from '../../../common/payment/Payment';
import { Updater, useUpdate } from 'dg-web-shared/lib/Flux';
import { logAction } from '../../../utils/ActionLog';

import {
    Failure,
    HttpFailure,
    NoAliasSaved,
    RedirectingToDatatransMessage,
} from './PaymentModals';
import { Localized } from '../../../common/components/Localized';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { SavedCreditCardState } from '../../shared/SavedCreditCardAliasState';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export interface NoOrExpiredPayloadBase {
    readonly language: Language;
    readonly useAlias: boolean;
    readonly paymentCategory: PaymentCategory;
}

export type ResponseState =
    | NeverExecuted
    | Pending
    | FailureDatatransApi
    | FailureBookingPermit
    | GenericError
    | DirectToAliasSuccess
    | NoOrNotValidAliasAttemptSuccess
    | FailureNoAliasSaved
    | FailureInsufficientFunds;

type NeverExecuted = { state: PaymentStateType.NEVER_EXECUTED };
type Pending = { state: PaymentStateType.PENDING };
type FailureDatatransApi = {
    state: PaymentStateType.FAILURE_DATATRANS_API;
};
type FailureInsufficientFunds = {
    state: PaymentStateType.INSUFFICIENT_FUNDS;
};
type FailureBookingPermit = {
    state: PaymentStateType.FAILURE_BOOKING_PERMIT;
};
type FailureNoAliasSaved = {
    state: PaymentStateType.FAILURE_NO_ALIAS_SAVED;
};
type GenericError = {
    state: PaymentStateType.GENERIC_ERROR;
    httpStatusCode: number;
    apiErrorCode: number | null;
};
type DirectToAliasSuccess = {
    state: PaymentStateType.DIRECT_TO_ALIAS_SUCCESS;
};
type NoOrNotValidAliasAttemptSuccess = {
    state: PaymentStateType.NO_OR_NOT_VALID_ALIAS_SUCCESS;
};

export enum PaymentStateType {
    NEVER_EXECUTED = 'NEVER_EXECUTED',
    PENDING = 'PENDING',
    FAILURE_DATATRANS_API = 'FAILURE_DATATRANS_API',
    FAILURE_BOOKING_PERMIT = 'FAILURE_BOOKING_PERMIT',
    GENERIC_ERROR = 'GENERIC_ERROR',
    DIRECT_TO_ALIAS_SUCCESS = 'DIRECT_TO_ALIAS_SUCCESS',
    NO_OR_NOT_VALID_ALIAS_SUCCESS = 'NO_OR_NOT_VALID_ALIAS_SUCCESS',
    FAILURE_NO_ALIAS_SAVED = 'FAILURE_NO_ALIAS_SAVED',
    INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum DirectToAliasResponseTag {
    SUCCESS = 'SUCCESS',
    FAILURE_NO_ALIAS_SAVED = 'FAILURE_NO_ALIAS_SAVED',
    FAILURE_DATATRANS_API = 'FAILURE_DATATRANS_API',
    FAILURE_BOOKING_PERMIT = 'FAILURE_BOOKING_PERMIT',
}

export type DirectToAliasResponse =
    | _DirectToAliasSuccess
    | DirectToFailureNoAliasSaved
    | DirectToAliasFailureDatatransApi
    | DirectToAliasFailureBookingPermit;

type _DirectToAliasSuccess = {
    tag: DirectToAliasResponseTag.SUCCESS;
    permitPurchaseResult?: { id: number };
};
type DirectToFailureNoAliasSaved = {
    tag: DirectToAliasResponseTag.FAILURE_NO_ALIAS_SAVED;
};

type DirectToAliasFailureDatatransApi = {
    tag: DirectToAliasResponseTag.FAILURE_DATATRANS_API;
    failure: RemoteFailure | null;
    message: string;
};

type DirectToAliasFailureBookingPermit = {
    tag: DirectToAliasResponseTag.FAILURE_BOOKING_PERMIT;
};

export type ErrorResponse = { code: number | null };

export const handlePaymentAttemptResponse = (
    update: Updater,
    current: ServerRequestState<PaymentAttemptResponseParams, ErrorResponse>,
    payload: NoOrExpiredPayloadBase,
    logPrefix: 'top-up' | 'permit',
) => {
    if (current.status === RequestStatus.PENDING) {
        update(store =>
            logAction(store, `${logPrefix}-payment-remote-request`, payload),
        );
    }

    if (current.status === RequestStatus.SUCCESS) {
        if (
            current.data.paymentProviderType === PaymentProviderType.DATATRANS
        ) {
            openDatatrans(
                update,
                current.data,
                `datatrans-${logPrefix}-remote-request-invoke`,
            );
        } else if (
            current.data.paymentProviderType === PaymentProviderType.TWINT
        ) {
            openTwint(
                update,
                current.data,
                `twint-${logPrefix}-remote-request-invoke`,
            );
        }
    }
};

export function derivePaymentResponseState(
    directAlias: ServerRequestState<DirectToAliasResponse, ErrorResponse>,
    noOrExpiredAlias: ServerRequestState<
        PaymentAttemptResponseParams,
        ErrorResponse
    >,
): ResponseState {
    if (
        directAlias.status === RequestStatus.NEVER_EXECUTED &&
        noOrExpiredAlias.status === RequestStatus.NEVER_EXECUTED
    ) {
        return { state: PaymentStateType.NEVER_EXECUTED };
    } else if (
        directAlias.status === RequestStatus.PENDING ||
        noOrExpiredAlias.status === RequestStatus.PENDING
    ) {
        return { state: PaymentStateType.PENDING };
    }

    switch (directAlias.status) {
        case RequestStatus.NEVER_EXECUTED:
            break; // noOrExpiredAlias has a state though
        case RequestStatus.SUCCESS:
            return deriveDirectAliasState(directAlias.data);
        case RequestStatus.ERROR:
            return {
                state: PaymentStateType.GENERIC_ERROR,
                httpStatusCode: directAlias.httpStatusCode,
                apiErrorCode: directAlias.data?.code || null,
            };
    }
    switch (noOrExpiredAlias.status) {
        case RequestStatus.NEVER_EXECUTED:
            throw Error(`both states never executed cannot happen!`);
        case RequestStatus.SUCCESS:
            return {
                state: PaymentStateType.NO_OR_NOT_VALID_ALIAS_SUCCESS,
            };
        case RequestStatus.ERROR:
            return {
                state: PaymentStateType.GENERIC_ERROR,
                httpStatusCode: noOrExpiredAlias.httpStatusCode,
                apiErrorCode: noOrExpiredAlias.data?.code || null,
            };
    }
}

function deriveDirectAliasState(data: DirectToAliasResponse): ResponseState {
    switch (data.tag) {
        case DirectToAliasResponseTag.FAILURE_BOOKING_PERMIT:
            return {
                state: PaymentStateType.FAILURE_BOOKING_PERMIT,
            };
        case DirectToAliasResponseTag.FAILURE_DATATRANS_API:
            if (
                data.failure &&
                data.failure === RemoteFailure.INSUFFICIENT_FUNDS
            ) {
                return { state: PaymentStateType.INSUFFICIENT_FUNDS };
            } else {
                return {
                    state: PaymentStateType.FAILURE_DATATRANS_API,
                };
            }
        case DirectToAliasResponseTag.SUCCESS:
            return {
                state: PaymentStateType.DIRECT_TO_ALIAS_SUCCESS,
            };
        case DirectToAliasResponseTag.FAILURE_NO_ALIAS_SAVED:
            return {
                state: PaymentStateType.FAILURE_NO_ALIAS_SAVED,
            };
    }
}

export function ShowPaymentState({
    remoteRequest,
    category,
    mode,
    onClose,
}: {
    remoteRequest: ResponseState;
    category: PaymentCategory;
    mode: PaymentRequestMode;
    onClose: () => void;
}): JSX.Element | null {
    const update = useUpdate();
    switch (remoteRequest.state) {
        case PaymentStateType.PENDING:
            return <Spinner />;
        case PaymentStateType.INSUFFICIENT_FUNDS:
            return (
                <Failure onConfirm={onClose}>
                    <p>
                        <Localized
                            de="Ungenügende Mittel"
                            fr="Fonds insuffisants"
                            it="Fondi insufficienti"
                            en="Insufficient funds"
                        />
                    </p>
                </Failure>
            );
        case PaymentStateType.FAILURE_DATATRANS_API:
            return category === PaymentCategory.TWINT ? (
                <Failure onConfirm={onClose}>
                    <p>
                        <Localized
                            de="Zahlung fehlgeschlagen"
                            fr="Paiement non effectué"
                            it="Pagamento non eseguito"
                            en="Payment failed"
                        />
                    </p>
                </Failure>
            ) : null;
        case PaymentStateType.GENERIC_ERROR:
            if (
                mode === PaymentRequestMode.DIRECT_TO_ALIAS &&
                category === PaymentCategory.TWINT
            ) {
                return (
                    <Failure onConfirm={onClose}>
                        <>
                            <p>
                                <Localized
                                    de="Aufgrund eines Kommunikationsproblems konnte der Status der Zahlung nicht ermittelt werden."
                                    fr="En raison d'un problème de communication, le statut du paiement n'a pas pu être déterminé."
                                    it="A causa di un problema di comunicazione, non è stato possibile determinare lo stato del pagamento."
                                    en="Due to a communication problem, the status of the payment could not be determined."
                                />
                            </p>
                            <p>
                                <Localized
                                    de="Bitte prüfen Sie in der TWINT App, ob die Zahlung abgebucht wurde oder nicht."
                                    fr="Veuillez vérifier dans l'application TWINT si le paiement a été facturé ou non."
                                    it="Voglia verificare nell'app TWINT se il pagamento è stato addebitato o meno."
                                    en="Please check in the TWINT app whether the payment has been charged or not."
                                />
                            </p>
                        </>
                    </Failure>
                );
            }
            return (
                <>
                    <Spinner />
                    <HttpFailure
                        httpStatusCode={remoteRequest.httpStatusCode || 0}
                        apiErrorCode={remoteRequest.apiErrorCode}
                        onConfirm={onClose}
                    />
                </>
            );
        case PaymentStateType.FAILURE_NO_ALIAS_SAVED:
            return (
                <NoAliasSaved
                    onConfirm={() => {
                        update(store => {
                            SavedCreditCardState.refetch(store);
                            onClose();
                            return 'reload aliases';
                        });
                    }}
                />
            );
        case PaymentStateType.NO_OR_NOT_VALID_ALIAS_SUCCESS:
            return <RedirectingToDatatransMessage />;
        case PaymentStateType.NEVER_EXECUTED:
        case PaymentStateType.FAILURE_BOOKING_PERMIT:
        case PaymentStateType.DIRECT_TO_ALIAS_SUCCESS:
            return null;
    }
}

const openDatatrans = (
    update: Updater,
    data: PaymentAttemptResponseDatatrans,
    logKey: string,
) => {
    update(store => {
        logAction(store, logKey, {
            ...data,
        });
        return 'log-action';
    });

    window.location.href = data.uri;
};

function openTwint(
    update: Updater,
    data: PaymentAttemptResponseTwintUrl,
    logKey: string,
) {
    update(store => {
        logAction(store, logKey, {
            ...data,
        });
        return 'log-action';
    });

    window.location.href = data.twintUrl;
}

export enum RemoteFailure {
    UNKNOWN = 'UNKNOWN',
    INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}
