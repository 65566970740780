import styled from '@emotion/styled';
import { Localized } from '../../../common/components/Localized';
import { TicketProcessIcon } from 'dg-web-shared/ui/icons/IconsScalable';
import { css } from '@emotion/css';
import { QuickCheckoutLayout } from '../../../quickcheckout/common/QuickCheckoutLayout';

const CentredPage = styled.div({
    textAlign: 'center',
    overflowY: 'auto',
    paddingLeft: '40px',
    paddingRight: '40px',
});

const IconContainer = styled.div({
    height: '100px',
    marginTop: '50px',
    marginBottom: '50px',
});

const Header = styled.div({
    fontSize: '24px',
    marginTop: '50px',
    marginBottom: '75px',
});
export function TicketPlusGuidePage() {
    return (
        <>
            <QuickCheckoutLayout>
                <CentredPage>
                    <Header>
                        <Localized
                            de="SO EINFACH GEHT ES"
                            fr="C'EST SI SIMPLE"
                            it="È COSÌ SEMPLICE"
                            en="IT'S THAT EASY"
                        />
                    </Header>
                    <IconContainer>
                        <TicketProcessIcon icon={'qrScan'} size={'small'} />
                    </IconContainer>
                    <div>
                        <Localized
                            de="Scannen Sie den QR-Code auf Ihrem Ticket und geben Sie die Bezahlung frei."
                            fr="Scannez le code QR sur votre ticket et autorisez le paiement."
                            it="Scansioni il codice QR sul suo ticket e autorizzi il pagamento."
                            en="Scan the QR code on your ticket and authorise payment."
                        />
                    </div>
                    <IconContainer>
                        <TicketProcessIcon icon={'barrier'} size={'small'} />
                    </IconContainer>
                    <div className={css({ marginBottom: '25px' })}>
                        <Localized
                            de="Stecken Sie beim Verlassen das Ticket wie gewohnt in die Ausfahrtstation."
                            fr="En sortant, insèrer le ticket comme d'habitude dans la station de sortie."
                            it="Quando se ne va, inserisca il ticket nella stazione d'uscita come di consueto."
                            en="When leaving, insert the ticket into the exit station as usual."
                        />
                    </div>
                    <div className={css({ marginBottom: '25px' })}>
                        <Localized
                            de="Der fällige Betrag wird automatisch abgebucht."
                            fr="Le montant dû est automatiquement débité."
                            it="L'importo dovuto verrà addebitato automaticamente."
                            en="The amount due will be debited automatically."
                        />
                    </div>
                    <div
                        className={css({
                            fontWeight: 'bold',
                            fontSize: '20px',
                            marginTop: '50px',
                            marginBottom: '50px',
                        })}
                    >
                        <Localized
                            de="Den Weg zur Kasse entfällt!"
                            fr="Plus besoin de passer à la caisse!"
                            it="Non è più necessario passare alla cassa!"
                            en="No need to go to the cash desk!"
                        />
                    </div>
                </CentredPage>
            </QuickCheckoutLayout>
        </>
    );
}
