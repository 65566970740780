import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { ClickHandler } from 'dg-web-shared/ui/Clickable';
import { Localized } from '../../common/components/Localized';
import { SlideInForm } from '../root/components/SlideInForm';
import { UpdateVehicle } from './VehicleState';

export const VehicleSlideInForm = (p: {
    onCancel: ClickHandler;
    onSave: ClickHandler;
    children?: React.ReactNode;
    disabled?: boolean;
    disabledPrimary?: boolean;
    selectedVehicle: UpdateVehicle.State;
}) => (
    <SlideInForm
        secondaryButton={{
            disabled:
                p.disabled ||
                p.selectedVehicle.status === RequestStatus.PENDING,
            label: (
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            ),
            onClick: p.onCancel,
        }}
        primaryButton={{
            disabled:
                p.disabledPrimary ||
                p.disabled ||
                p.selectedVehicle.status === RequestStatus.PENDING,
            label: (
                <Localized
                    de="Speichern"
                    fr="Sauvegarder"
                    it="Salva"
                    en="Save"
                />
            ),
            onClick: p.onSave,
        }}
    >
        {p.children}
    </SlideInForm>
);
