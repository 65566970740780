import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';

export enum SortField {
    Zipcode,
    City,
    Distance,
}

export interface State {
    open: boolean;
    openEpoch: Maybe<number>;
    sortField: SortField;
    sortAscending: boolean;
    filterValue: string;
    hidePositionErrorNotification: boolean;
    positionAcquisitionCanceled: boolean;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'park-create-CityDropinState';
    }
    sideEffects(): void {
        return;
    }

    getInitialState(): State {
        return {
            open: false,
            openEpoch: null,
            sortField: SortField.Zipcode,
            sortAscending: true,
            filterValue: '',
            hidePositionErrorNotification: false,
            positionAcquisitionCanceled: false,
        };
    }

    setOpen(open: boolean): void {
        this.set((s: State): State => {
            s.open = open;
            s.openEpoch = open ? new Date().getTime() : null;
            return s;
        });
    }

    setSortField(sortField: SortField): void {
        this.set((s: State): State => {
            s.sortField = sortField;
            return s;
        });
    }

    setSortAscending(sortAscending: boolean): void {
        this.set((s: State): State => {
            s.sortAscending = sortAscending;
            return s;
        });
    }

    toggleSortAscending(): void {
        this.set((s: State): State => {
            s.sortAscending = !s.sortAscending;
            return s;
        });
    }

    setFilterValue(filterValue: string): void {
        this.set((s: State): State => {
            s.filterValue = filterValue;
            return s;
        });
    }

    setHidePositionErrorNotification(hide: boolean) {
        this.set((s: State): State => {
            s.hidePositionErrorNotification = hide;
            return s;
        });
    }

    setPositionAcquisitionCanceled(canceled: boolean) {
        this.set((s: State): State => {
            s.positionAcquisitionCanceled = canceled;
            return s;
        });
    }
}

export function filterActive(s: State): boolean {
    return s.filterValue.length > 0;
}
