import * as Flux from 'dg-web-shared/lib/Flux';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import { StartedCheckinByPlateOnThisDeviceState } from '../components/ParkCreate';

export function onSuccessfulTransactionPurchase(
    store: Flux.Store,
    id: number,
): void {
    new AccountBalanceState.StateSlice(store).reset();
    StartedCheckinByPlateOnThisDeviceState.setCreatedTransaction(store, id);
}
