import { useStore } from 'dg-web-shared/lib/Flux';
import { Message } from 'dg-web-shared/lib/Localized';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as MetaServerState from '../meta/state/MetaServerState';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { EmailEditFormSlideIn } from './EmailEditForm';
import { GenderEditFormSlideIn } from './GenderEditForm';
import { LoginCreateFormSlideIn } from './LoginCreateForm';
import { LoginEditMenuSlideIn } from './LoginEditMenu';
import { NameEditFormSlideIn } from './NameEditForm';
import { OtherLoginsListSlideIn } from './OtherLoginsList';
import { PasswordEditFormSlideIn } from './PasswordEditForm';
import { RequestedEmailReviewSlideIn } from './RequestedEmailReview';
import { RoleEditFormSlideIn } from './RoleEditForm';
import * as LoginsState from './state/LoginsState';
import { StatusEditFormSlideIn } from './StatusEditForm';
import { UnconfirmedEmailReviewSlideIn } from './UnconfirmedEmailReview';
import { MainMenuItemBlock } from '../root/components/ItemBlock';
import { useState } from 'react';

type View =
    | MenuView
    | OtherLoginsListView
    | LoginCreateView
    | LoginEditMenuView
    | GenderEditFormView
    | NameEditFormView
    | UnconfirmedEmailReviewView
    | EmailEditFormView
    | RequestedEmailReviewView
    | RoleEditFormView
    | PasswordEditForm
    | StatusEditFormView;

type MenuView = { tag: ViewTag.MENU };
type OtherLoginsListView = { tag: ViewTag.OTHER_LOGINS_LIST };
type LoginCreateView = { tag: ViewTag.LOGIN_CREATE };
type LoginEditMenuView = { tag: ViewTag.LOGIN_EDIT_MENU; loginId: number };
type GenderEditFormView = { tag: ViewTag.GENDER_EDIT_FORM; loginId: number };
type NameEditFormView = { tag: ViewTag.NAME_EDIT_FORM; loginId: number };
type UnconfirmedEmailReviewView = {
    tag: ViewTag.UNCONFIRMED_EMAIL_REVIEW;
    loginId: number;
};
type EmailEditFormView = { tag: ViewTag.EMAIL_EDIT_FORM; loginId: number };
type RequestedEmailReviewView = {
    tag: ViewTag.REQUESTED_EMAIL_REVIEW;
    loginId: number;
};
type RoleEditFormView = { tag: ViewTag.ROLE_EDIT_FORM; loginId: number };
type PasswordEditForm = { tag: ViewTag.PASSWORD_EDIT_FORM; loginId: number };
type StatusEditFormView = { tag: ViewTag.STATUS_EDIT_FORM; loginId: number };

enum ViewTag {
    MENU,
    OTHER_LOGINS_LIST,
    LOGIN_CREATE,
    LOGIN_EDIT_MENU,
    GENDER_EDIT_FORM,
    NAME_EDIT_FORM,
    UNCONFIRMED_EMAIL_REVIEW,
    EMAIL_EDIT_FORM,
    REQUESTED_EMAIL_REVIEW,
    ROLE_EDIT_FORM,
    PASSWORD_EDIT_FORM,
    STATUS_EDIT_FORM,
}

export function OtherLoginsMenu() {
    const { storeState } = useStore(s => ({
        currentLogin: CurrentLoginState.get(s),
        logins: new LoginsState.StateSlice(s).state,
        metaServer: new MetaServerState.StateSlice(s).state,
    }));

    const [view, setView] = useState<View>({ tag: ViewTag.MENU });

    if (
        storeState.metaServer.data.customerAccountType ===
            CustomerAccountType.PRIVATE ||
        !storeState.currentLogin.data ||
        !storeState.logins.data
    ) {
        return null;
    }

    const currentLoginId = storeState.currentLogin.data.loginId;

    const otherLogins = storeState.logins.data.filter(
        l => l.id !== currentLoginId,
    );

    return (
        <>
            <MainMenuItemBlock
                title={<Localized {...messages.otherUsers} />}
                subTitle={otherLoginsPreview({ otherLogins: otherLogins })}
                rightTitle={String(storeState.logins.data.length - 1)}
                onClick={setView.bind(null, { tag: ViewTag.OTHER_LOGINS_LIST })}
            />

            <OtherLoginsListSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.otherUsers} />}
                otherLogins={otherLogins}
                open={
                    [
                        ViewTag.OTHER_LOGINS_LIST,
                        ViewTag.LOGIN_CREATE,
                        ViewTag.LOGIN_EDIT_MENU,
                        ViewTag.GENDER_EDIT_FORM,
                        ViewTag.NAME_EDIT_FORM,
                        ViewTag.UNCONFIRMED_EMAIL_REVIEW,
                        ViewTag.EMAIL_EDIT_FORM,
                        ViewTag.REQUESTED_EMAIL_REVIEW,
                        ViewTag.ROLE_EDIT_FORM,
                        ViewTag.PASSWORD_EDIT_FORM,
                        ViewTag.STATUS_EDIT_FORM,
                    ].indexOf(view.tag) >= 0
                }
                onClose={setView.bind(null, { tag: ViewTag.MENU })}
                onCreateLogin={setView.bind(null, {
                    tag: ViewTag.LOGIN_CREATE,
                })}
                onEditLogin={loginId => {
                    setView({ tag: ViewTag.LOGIN_EDIT_MENU, loginId });
                }}
            />

            <LoginCreateFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.createNewUser} />}
                open={view.tag === ViewTag.LOGIN_CREATE}
                onClose={setView.bind(null, { tag: ViewTag.OTHER_LOGINS_LIST })}
            />

            <LoginEditMenuSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.user} />}
                currentLoginId={storeState.currentLogin.data.loginId}
                open={
                    [
                        ViewTag.LOGIN_EDIT_MENU,
                        ViewTag.GENDER_EDIT_FORM,
                        ViewTag.NAME_EDIT_FORM,
                        ViewTag.UNCONFIRMED_EMAIL_REVIEW,
                        ViewTag.EMAIL_EDIT_FORM,
                        ViewTag.REQUESTED_EMAIL_REVIEW,
                        ViewTag.ROLE_EDIT_FORM,
                        ViewTag.PASSWORD_EDIT_FORM,
                        ViewTag.STATUS_EDIT_FORM,
                    ].indexOf(view.tag) >= 0
                }
                login={
                    view.tag === ViewTag.LOGIN_EDIT_MENU ||
                    view.tag === ViewTag.GENDER_EDIT_FORM ||
                    view.tag === ViewTag.NAME_EDIT_FORM ||
                    view.tag === ViewTag.UNCONFIRMED_EMAIL_REVIEW ||
                    view.tag === ViewTag.EMAIL_EDIT_FORM ||
                    view.tag === ViewTag.REQUESTED_EMAIL_REVIEW ||
                    view.tag === ViewTag.ROLE_EDIT_FORM ||
                    view.tag === ViewTag.PASSWORD_EDIT_FORM ||
                    view.tag === ViewTag.STATUS_EDIT_FORM
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={setView.bind(null, {
                    tag: ViewTag.OTHER_LOGINS_LIST,
                })}
                selectionCallbacks={{
                    editGender: loginId => {
                        setView({ tag: ViewTag.GENDER_EDIT_FORM, loginId });
                    },
                    editName: loginId => {
                        setView({ tag: ViewTag.NAME_EDIT_FORM, loginId });
                    },
                    reviewUnconfirmedEmail: loginId => {
                        setView({
                            tag: ViewTag.UNCONFIRMED_EMAIL_REVIEW,
                            loginId,
                        });
                    },
                    editEmail: loginId => {
                        setView({ tag: ViewTag.EMAIL_EDIT_FORM, loginId });
                    },
                    reviewRequestedEmail: loginId => {
                        setView({
                            tag: ViewTag.REQUESTED_EMAIL_REVIEW,
                            loginId,
                        });
                    },
                    editRole: loginId => {
                        setView({ tag: ViewTag.ROLE_EDIT_FORM, loginId });
                    },
                    editPassword: loginId => {
                        setView({ tag: ViewTag.PASSWORD_EDIT_FORM, loginId });
                    },
                    editStatus: loginId => {
                        setView({ tag: ViewTag.STATUS_EDIT_FORM, loginId });
                    },
                }}
            />

            <GenderEditFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.gender} />}
                open={view.tag === ViewTag.GENDER_EDIT_FORM}
                login={
                    view.tag === ViewTag.GENDER_EDIT_FORM
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.GENDER_EDIT_FORM) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />

            <NameEditFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.name} />}
                open={view.tag === ViewTag.NAME_EDIT_FORM}
                login={
                    view.tag === ViewTag.NAME_EDIT_FORM
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.NAME_EDIT_FORM) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />

            <UnconfirmedEmailReviewSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.unconfirmedEmailReview} />}
                open={view.tag === ViewTag.UNCONFIRMED_EMAIL_REVIEW}
                login={
                    view.tag === ViewTag.UNCONFIRMED_EMAIL_REVIEW
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.UNCONFIRMED_EMAIL_REVIEW) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />

            <EmailEditFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.email} />}
                open={view.tag === ViewTag.EMAIL_EDIT_FORM}
                login={
                    view.tag === ViewTag.EMAIL_EDIT_FORM
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.EMAIL_EDIT_FORM) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />

            <RequestedEmailReviewSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.requestedEmailReview} />}
                open={view.tag === ViewTag.REQUESTED_EMAIL_REVIEW}
                login={
                    view.tag === ViewTag.REQUESTED_EMAIL_REVIEW
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.REQUESTED_EMAIL_REVIEW) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />

            <RoleEditFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.role} />}
                open={view.tag === ViewTag.ROLE_EDIT_FORM}
                login={
                    view.tag === ViewTag.ROLE_EDIT_FORM
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.ROLE_EDIT_FORM) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />

            <PasswordEditFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.password} />}
                open={view.tag === ViewTag.PASSWORD_EDIT_FORM}
                login={
                    view.tag === ViewTag.PASSWORD_EDIT_FORM
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.PASSWORD_EDIT_FORM) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />

            <StatusEditFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Localized {...messages.status} />}
                open={view.tag === ViewTag.STATUS_EDIT_FORM}
                login={
                    view.tag === ViewTag.STATUS_EDIT_FORM
                        ? (otherLogins.find(
                              l => l.id === view.loginId,
                          ) as LoginsState.GetLoginData)
                        : null
                }
                onClose={() => {
                    if (view.tag !== ViewTag.STATUS_EDIT_FORM) {
                        return;
                    }

                    setView({
                        tag: ViewTag.LOGIN_EDIT_MENU,
                        loginId: view.loginId,
                    });
                }}
            />
        </>
    );
}

function otherLoginsPreview(props: {
    otherLogins: LoginsState.GetLoginData[];
}) {
    const logins = props.otherLogins.map(l => l.firstName || l.email);

    if (logins.length === 0) {
        return null;
    }

    return logins.join(', ');
}

const messages: Messages = {
    createNewUser: {
        de: 'Neuen Benutzer erfassen',
        fr: 'Saisir nouvel utilisateur',
        it: 'Registra nuovo utente',
        en: 'Create new user',
    },
    email: {
        de: 'E-Mail',
        fr: 'E-mail',
        it: 'E-mail',
        en: 'E-mail',
    },
    gender: {
        de: 'Anrede',
        fr: 'Titre',
        it: 'Titolo',
        en: 'Title',
    },
    name: {
        de: 'Vorname / Nachname',
        fr: 'Prénom / Nom',
        it: 'Nome / Cognome',
        en: 'First name / Last name',
    },
    noUsers: {
        de: 'Keine Benutzer erfasst',
        fr: "Pas d'utilisateur saisie",
        it: 'Nessun utente registrato',
        en: 'No user found',
    },
    otherUsers: {
        de: 'Andere Benutzer',
        fr: 'Autres utilisateurs',
        it: 'Altri utenti',
        en: 'Other users',
    },
    password: {
        de: 'Passwort',
        fr: 'Mot de passe',
        it: 'Password',
        en: 'Password',
    },
    requestedEmailReview: {
        de: 'E-Mail-Änderung pendent',
        fr: 'Changement e-mail en attente',
        it: 'Modifica e-mail in sospeso',
        en: 'E-mail change pending',
    },
    status: {
        de: 'Status',
        fr: 'Statut',
        it: 'Stato',
        en: 'Status',
    },
    role: {
        de: 'Rolle',
        fr: 'Rôle',
        it: 'Ruolo',
        en: 'Role',
    },
    unconfirmedEmailReview: {
        de: 'Unbestätigte E-Mail Adresse',
        fr: 'Adresse e-mail non confirmée',
        it: 'Indirizzo e-mail non confermato',
        en: 'Unconfirmed e-mail address',
    },
    user: {
        de: 'Benutzer',
        fr: 'Utilisateur',
        it: 'Utente',
        en: 'User',
    },
};

type Messages = {
    createNewUser: Message;
    email: Message;
    gender: Message;
    name: Message;
    noUsers: Message;
    otherUsers: Message;
    password: Message;
    requestedEmailReview: Message;
    role: Message;
    status: Message;
    unconfirmedEmailReview: Message;
    user: Message;
};
