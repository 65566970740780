import { useState } from 'react';
import { StateSlice } from '../../common/state/SettingsState';
import { ActionBlock } from '../root/components/ActionBlock';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { BarrierGateLicensePlateCheckInSlideIn } from './BarrierGateLicensePlateCheckIn';
import { VehicleBadgeDetail } from './VehicleBadgeDetail';
import { VehicldeDescriptionEdit } from './VehicleDescriptionEdit';
import {
    LprDisabledReason,
    VehicleLicensePlateEdit,
} from './VehicleLicensePlateEdit';
import { VehicleListElement } from './VehiclesList';
import { UpdateVehicle, Vehicle, Vehicles, vehicleTitle } from './VehicleState';
import { VehicleTexts } from './VehicleTexts';
import { Localized } from '../../common/components/Localized';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import {
    ButtonText,
    ModalDeleteQuestionBox,
    ModalInfoBox,
} from '../../ui/modals/Confirmable';
import { stringToFourCharacterBlocks } from 'dg-web-shared/lib/StringConversions';
import { FieldItemBlock } from '../root/components/ItemBlock';
import { VehicleVisibility } from './VehicleVisibility';
import * as LoginsState from '../logins/state/LoginsState';
import { css } from '@emotion/css';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Typo } from '../../style/typo';
import { updateTransactionlistWarnings } from '../../transactions-list/state/TransactionsListState';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';
import { BarrierGateLicensePlateCheckInOption } from 'dg-web-shared/common/models/Vehicle';

export interface VehicleDetailProps {
    texts: VehicleTexts;
    vehicle: Vehicle;
}

interface Props {
    addBadge: () => void;
    texts: VehicleTexts;
    vehicle: Vehicle | null;
    hasLprEnabled: boolean;
}

export const VehicleDetail = portalSlideIn<Props>(props => {
    const [deleteVehicle, setDeleteVehicle] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const [editLicensePlate, setEditLicensePlate] = useState(false);
    const [editBadge, setEditBadge] = useState(false);
    const [lprDisabledModal, setLprDisabledModal] =
        useState<LprDisabledReason | null>(null);
    const [
        editBarrierGateLicensePlateCheckIn,
        setEditBarrierGateLicensePlateCheckIn,
    ] = useState(false);
    const [openChangeableLicensePlate, setOpenChangeableLicensePlate] =
        useState(false);
    const refetchTransactionList = useTransactionListRefetch();

    const { storeState, update } = useStore(store => {
        return {
            vehicles: Vehicles.get(store),
            settings: new StateSlice(store).state,
            updateVehicle: UpdateVehicle.get(store),
            logins: new LoginsState.StateSlice(store).state,
        };
    });
    const v = props.vehicle;

    if (v === null) {
        return null;
    }

    const samePlateVehicles = storeState.vehicles.data.filter(
        vehicle =>
            vehicle.licensePlateNr === v.licensePlateNr &&
            vehicle.country === v.country,
    ).length;

    return (
        <>
            <VehicleListElement>
                <FieldItemBlock
                    onClick={() => {
                        setEditDescription(true);
                    }}
                    label={
                        <Localized
                            de="Beschreibung"
                            fr="Description"
                            it="Descrizione"
                            en="Description"
                        />
                    }
                    content={v.description || ''}
                />

                <FieldItemBlock
                    onClick={() => {
                        setEditLicensePlate(true);
                    }}
                    label={props.texts.licensePlate()}
                    content={v.licensePlateNr}
                />
                {storeState.logins.data.length > 1 && (
                    <VehicleVisibility vehicle={v} loginId={v.loginId} />
                )}
                {props.hasLprEnabled &&
                    v.barrierGateLicensePlateCheckInOption !==
                        BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_MOTORCYCLE &&
                    v.barrierGateLicensePlateCheckInOption !==
                        BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_DIPLOMATIC && (
                        <FieldItemBlock
                            onClick={() => {
                                setEditBarrierGateLicensePlateCheckIn(true);
                            }}
                            label={props.texts.barrierGateLicensePlateCheckIn()}
                            content={props.texts.barrierGateLicensePlateCheckInOption(
                                v.barrierGateLicensePlateCheckInOption,
                            )}
                        />
                    )}

                {v.badgeId ? (
                    <FieldItemBlock
                        onClick={() => {
                            setEditBadge(true);
                        }}
                        label={props.texts.badge()}
                        content={
                            v.badgeLabelNr
                                ? stringToFourCharacterBlocks(v.badgeLabelNr)
                                : props.texts.badgeOrdered()
                        }
                    />
                ) : (
                    <ActionBlock
                        onClick={props.addBadge}
                        title={props.texts.addBadge()}
                        icon="add"
                    />
                )}
                <ActionBlock
                    title={props.texts.delVehicle()}
                    icon="delete"
                    onClick={() => {
                        update(store => {
                            UpdateVehicle.reset(store);
                            return 'reset-UpdateVehicle';
                        });
                        setDeleteVehicle(true);
                    }}
                />
            </VehicleListElement>
            {deleteVehicle && !!props.vehicle && (
                <ModalDeleteQuestionBox
                    titleCaption={
                        <Localized
                            de="Fahrzeug löschen"
                            fr="Supprimer véhicule"
                            it="Eliminare veicolo"
                            en="Delete vehicle"
                        />
                    }
                    confirmCallback={() =>
                        update(store => {
                            if (props.vehicle != null) {
                                UpdateVehicle.del(store, props.vehicle, () => {
                                    refetchTransactionList();
                                    updateTransactionlistWarnings(update);
                                    props.onClose();
                                });
                            }
                            return 'delete-UpdateVehicle';
                        })
                    }
                    cancelCallback={() => {
                        setDeleteVehicle(false);
                    }}
                    isPending={
                        storeState.updateVehicle.status !==
                        RequestStatus.NEVER_EXECUTED
                    }
                >
                    <p>
                        <Localized
                            de={`Wollen Sie wirklich das Fahrzeug «${vehicleTitle(
                                props.vehicle,
                            )}» löschen?`}
                            fr={`Voulez-vous vraiment effacer le véhicule «${vehicleTitle(
                                props.vehicle,
                            )}»?`}
                            it={`Vuole veramente eliminare il veicolo «${vehicleTitle(
                                props.vehicle,
                            )}»?`}
                            en={`Do you really want to delete the vehicle «${vehicleTitle(
                                props.vehicle,
                            )}»?`}
                        />
                    </p>
                </ModalDeleteQuestionBox>
            )}
            <p>
                <a
                    onClick={() => setOpenChangeableLicensePlate(true)}
                    className={css({
                        ...Typo.tBody,
                        color: 'grey',
                        cursor: 'pointer',
                        display: 'block',
                        textAlign: 'center',
                        textDecoration: 'underline',
                    })}
                >
                    <Localized
                        de="Fahrzeuge mit Wechselschild?"
                        fr="Véhicules avec plaque interchangeable?"
                        it="Veicoli con targa trasferibile?"
                        en="Vehicles with changeable plate?"
                    />
                </a>
            </p>
            {openChangeableLicensePlate && (
                <ModalInfoBox
                    confirmCaption={ButtonText.OK}
                    confirmCallback={() => setOpenChangeableLicensePlate(false)}
                    titleCaption={
                        <Localized
                            de="Wechselschild"
                            fr="Plaque interchangeable"
                            it="Targa trasferibile"
                            en="Changeable plate"
                        />
                    }
                >
                    <ChangeableLicensePlateContent />
                </ModalInfoBox>
            )}
            <VehicldeDescriptionEdit
                portal={props.portal}
                texts={props.texts}
                vehicle={v}
                title={props.texts.editDescription()}
                open={editDescription}
                onClose={() => {
                    setEditDescription(false);
                }}
            />
            <VehicleLicensePlateEdit
                portal={props.portal}
                texts={props.texts}
                vehicle={v}
                title={props.texts.editLicensePlate()}
                open={editLicensePlate}
                onClose={() => {
                    setEditLicensePlate(false);
                    update(store => {
                        UpdateVehicle.reset(store);
                        return 'reset-vehicle';
                    });
                }}
                setLprDisabledModal={(reason: LprDisabledReason) =>
                    setLprDisabledModal(reason)
                }
            />
            {lprDisabledModal && (
                <ModalInfoBox
                    confirmCaption={ButtonText.OK}
                    confirmCallback={() => setLprDisabledModal(null)}
                    titleCaption={
                        <Localized
                            de="Automatisch deaktiviert"
                            fr="Automatiquement désactivée"
                            it="Disattivato automaticamente"
                            en="Automatically deactivated"
                        />
                    }
                >
                    <p>
                        <Localized
                            de={
                                <div>
                                    Die{' '}
                                    <b>
                                        Kennzeichenerkennung für öffentliche
                                        Parkings
                                    </b>{' '}
                                    wurde automatisch deaktiviert.
                                </div>
                            }
                            fr={
                                <div>
                                    La{' '}
                                    <b>
                                        reconnaissance de l`immatriculation pour
                                        les parkings publics
                                    </b>{' '}
                                    a été automatiquement désactivée.
                                </div>
                            }
                            it={
                                <div>
                                    Il{' '}
                                    <b>
                                        riconoscimento della targa per i
                                        parcheggi pubblici
                                    </b>{' '}
                                    è stato disattivato automaticamente.
                                </div>
                            }
                            en={
                                <div>
                                    The{' '}
                                    <b>
                                        licence plate recognition for public
                                        parkings
                                    </b>{' '}
                                    was automatically deactivated.
                                </div>
                            }
                        />
                    </p>
                    <LprAutomaticallyDisabledText reason={lprDisabledModal} />
                </ModalInfoBox>
            )}
            {samePlateVehicles < 2 && (
                <BarrierGateLicensePlateCheckInSlideIn
                    portal={props.portal}
                    title={props.texts.barrierGateLicensePlateCheckIn()}
                    open={editBarrierGateLicensePlateCheckIn}
                    customerCarId={v.customerCarId || 0}
                    vehicle={v}
                    currentOptionValue={v.barrierGateLicensePlateCheckInOption}
                    onClose={() => {
                        setEditBarrierGateLicensePlateCheckIn(false);
                    }}
                    onNavigateToBadgeOrder={() => {
                        setEditBarrierGateLicensePlateCheckIn(false);

                        props.addBadge();
                    }}
                />
            )}
            <VehicleBadgeDetail
                portal={props.portal}
                texts={props.texts}
                vehicle={v}
                title={props.texts.badge()}
                open={editBadge}
                language={storeState.settings.language}
                onClose={() => {
                    setEditBadge(false);
                }}
            />
        </>
    );
});

function LprAutomaticallyDisabledText({
    reason,
}: {
    reason: LprDisabledReason;
}) {
    switch (reason) {
        case LprDisabledReason.TOO_SHORT:
            return (
                <>
                    <p>
                        <Localized
                            de="Bei Kennzeichen mit weniger als 6 Zeichen (Buchstaben/Ziffern) wäre die Wahrscheinlichkeit, dass ein anderes Kennzeichen fälschlicherweise als Ihres erkannt wird, zu gross."
                            fr="Pour les plaques d'immatriculation comportant moins de 6 caractères (lettres/chiffres), la probabilité qu'une autre plaque soit reconnue à tort comme étant la vôtre serait trop élevée."
                            it="Per le targhe con meno di 6 caratteri (lettere/cifre), la probabilità che un'altra targa venga erroneamente riconosciuta come la sua sarebbe troppo alta."
                            en="For licence plates with less than 6 characters (letters/digits), the likelihood of another licence plate being mistakenly recognised as yours would be too big."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Dies würde zu ungerechtfertigten Belastungen Ihres Parkingpay-Kontos führen."
                            fr="Cela provoquerait des débits injustifiés sur votre compte Parkingpay."
                            it="Ciò comporterebbe addebiti ingiustificati sul suo conto Parkingpay."
                            en="This would result in unjustified charges to your Parkingpay account."
                        />
                    </p>
                </>
            );
        case LprDisabledReason.MOTORCYCLE:
            return (
                <p>
                    <Localized
                        de="Motorfahrräder sind nicht für die Kennzeichenerkennung geeignet und können diese somit nicht aktiv haben."
                        fr="Les motos ne sont pas utilisables pour la reconnaissance de plaque et ne peuvent donc pas l'avoir active."
                        it="Le moto non sono idonee al riconoscimento della targa e quindi non possono averlo attivo."
                        en="Motorbikes are not suitable for licence plate recognition and therefore cannot have it active."
                    />
                </p>
            );
        case LprDisabledReason.DIPLOMATIC:
            return (
                <p>
                    <Localized
                        de="Diplomatische und konsularische Kennzeichen können die Kennzeichenerkennung nicht verwenden."
                        fr="Les plaques diplomatiques et consulaires ne peuvent pas utiliser la reconnaissance des plaques d'immatriculation."
                        it="Le targhe diplomatiche e consolari non possono utilizzare il riconoscimento delle targhe."
                        en="Diplomatic and consular plates may not use number plate recognition."
                    />
                </p>
            );
    }
}
export function ChangeableLicensePlateContent() {
    return (
        <>
            <p>
                <Localized
                    de="Wenn Sie zwei Fahrzeuge mit Wechselschild haben, registrieren Sie sie einfach einmal in Ihrem Parkingpay-Konto und fügen Sie eine Beschreibung hinzu, die beide Fahrzeuge identifiziert: z.B. «VW Passat & BMW i3»."
                    fr="Si vous avez deux véhicules avec plaque d'immatriculation interchangeable, il suffit de les enregistrer une fois dans votre compte Parkingpay et d'ajouter une description qui les identifie tous les deux: par exemple «VW Passat & BMW i3»."
                    it="Se ha due veicoli con targa trasferibile è sufficiente registrarli una sola volta nel conto Parkingpay, aggiungendo una descrizione che li identifichi entrambi: p.es. «VW Passat & BMW i3»."
                    en="If you have two vehicles with changeable plate, simply register them once in your Parkingpay account and add a description that identifies them both: e.g. «VW Passat & BMW i3»."
                />
            </p>
            <p>
                <Localized
                    de="Falls Sie einen Badge für Parkplätze mit Schranken haben, denken Sie daran, diesen bei der Verlegung des Kennzeichens mitzunehmen."
                    fr="Lors du changement de la plaque d'immatriculation, si vous avez un badge pour le parking avec barrière, pensez à le prendre avec vous."
                    it="Quando trasferisce la targa, se ha un badge per i parcheggi con barriera, si ricordi di prenderlo con lei."
                    en="When transferring the number plate, if you have a badge for barrier parking, remember to take it with you."
                />
            </p>
        </>
    );
}
