import { Translation, Translations } from './Text';
import { LicensePlateErrorTexts } from 'dg-web-shared/lib/LicensePlateValidation';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { generalTexts } from '../../../../parkingcard-app/src/common/i18n/FieldTexts';

export interface Texts {
    Type: Translation;
    Country: Translation;
    TypeDescription: Translation;
    LicensePlate: Translation;
    Vignette: Translation;
    CarType: Translation;
    MotorcycleType: Translation;
}
/* tslint:disable:typedef max-line-length */

export const texts: Translations<Texts> = {
    de: {
        CarType: () => 'Motorwagen',
        Country: () => 'Land',
        LicensePlate: () => 'Kennzeichen',
        MotorcycleType: () => 'Motorrad',
        Type: () => 'Schildart',
        TypeDescription: () => 'Fahrzeugtyp',
        Vignette: () => 'Vignette',
    },
    fr: {
        CarType: () => 'Voiture',
        Country: () => 'Pays',
        LicensePlate: () => 'Immatriculation',
        MotorcycleType: () => 'Moto',
        Type: () => 'Type de plaque',
        TypeDescription: () => 'Type de véhicule',
        Vignette: () => 'Vignette',
    },
    it: {
        CarType: () => 'Automobile',
        Country: () => 'Nazione',
        LicensePlate: () => 'Targa',
        MotorcycleType: () => 'Motocicletta',
        Type: () => 'Tipo di targa',
        TypeDescription: () => 'Tipo veicolo',
        Vignette: () => 'Vignetta',
    },
    en: {
        CarType: () => 'Car',
        Country: () => 'Country',
        LicensePlate: () => 'License plate',
        MotorcycleType: () => 'Motorcycle',
        Type: () => 'License plate type',
        TypeDescription: () => 'Vehicle type',
        Vignette: () => 'Vignette',
    },
};

export const errors: Translations<LicensePlateErrorTexts> = {
    de: {
        AllLettersUppercase: () => generalTexts.de.AllLettersUppercase(),
        CountryMustBeFL: () => 'Das Kennzeichen muss immer mit FL beginnen',
        HasMoreThanFiveNumbers: () => 'Maximal 5 Zahlen erlaubt',
        HasMoreThanSixNumbers: () => 'Maximal 6 Zahlen erlaubt',
        LettersAfterCanton: () => 'Nur Zahlen nach Kantonskürzel erlaubt',
        LettersAfterCountryFL: () => 'Nur Zahlen nach FL-Kürzel erlaubt',
        MinThreeLetters: () => 'Mindestens 3 Zeichen eingeben',
        NoValidCanton: () => 'Keine gültige Kantonsbezeichnung',
        NoWhiteSpace: () => 'Keine Leerzeichen erlaubt',
    },
    fr: {
        AllLettersUppercase: () => generalTexts.fr.AllLettersUppercase(),
        CountryMustBeFL: () =>
            "L'immatriculation doit toujours commencer avec FL",
        HasMoreThanFiveNumbers: () => 'Maximum 5 chiffres autorisés',
        HasMoreThanSixNumbers: () => 'Maximum 6 chiffres autorisés',
        LettersAfterCanton: () =>
            'Seulement des chiffres autorisés après le code canton',
        LettersAfterCountryFL: () =>
            'Seulement des chiffres autorisés après le code FL',
        MinThreeLetters: () => 'Saisir au moins 3 caractères',
        NoValidCanton: () => "Le code canton n'est pas valable",
        NoWhiteSpace: () => 'Espaces pas autorisées',
    },
    it: {
        AllLettersUppercase: () => generalTexts.it.AllLettersUppercase(),
        CountryMustBeFL: () => 'La targa deve sempre cominciare con FL',
        HasMoreThanFiveNumbers: () => 'Sono consentite al massimo 5 cifre',
        HasMoreThanSixNumbers: () => 'Sono consentite al massimo 6 cifre',
        LettersAfterCanton: () =>
            'Sono consentite solo cifre dopo il codice del cantone',
        LettersAfterCountryFL: () =>
            'Sono consentite solo cifre dopo il codice FL',
        MinThreeLetters: () => 'Inserire almeno 3 caratteri',
        NoValidCanton: () => 'Il codice del cantone non è valido',
        NoWhiteSpace: () => 'Non sono consentiti spazi',
    },
    en: {
        AllLettersUppercase: () => generalTexts.en.AllLettersUppercase(),
        CountryMustBeFL: () => 'The license plate must always start with FL',
        HasMoreThanFiveNumbers: () => 'A maximum of 5 digits are allowed',
        HasMoreThanSixNumbers: () => 'A maximum of 6 digits are allowed',
        LettersAfterCanton: () =>
            'Only digits after the canton code are allowed',
        LettersAfterCountryFL: () =>
            'Only digits after the code FL are allowed',
        MinThreeLetters: () => 'Please enter at least 3 characters',
        NoValidCanton: () => 'The canton code is invalid',
        NoWhiteSpace: () => 'No space allowed',
    },
};

export const getTypeString = (
    language: string,
    type: LicensePlateType,
): string => {
    switch (type) {
        case LicensePlateType.CAR:
            return texts[language].CarType();
        case LicensePlateType.MOTORCYCLE:
            return texts[language].MotorcycleType();
        default:
            console.error(
                `No translation found for lp type '${type}'. Using type 'CAR' instead.`,
            );
            return texts[language].CarType();
    }
};

export interface DetailTexts {
    CompanyAccount: Translation;
    PrivateAccount: Translation;
    None: Translation;
    PrintTooltip: Translation;
    DropdownLabel: Translation;
    SlideInTitle: Translation;
    ChangeLicensePlate: Translation;
}

export const detailTexts: Translations<DetailTexts> = {
    de: {
        CompanyAccount: () => 'Firmenkonto',
        None: () => 'Keine',
        PrintTooltip: () => 'Beiblatt Vignette drucken',
        PrivateAccount: () => 'Privatkonto',
        DropdownLabel: () => 'Bearbeiten',
        SlideInTitle: () => 'Kennzeichen',
        ChangeLicensePlate: () => 'Kennzeichen wechseln',
    },
    fr: {
        CompanyAccount: () => 'Compte entreprise',
        None: () => 'Keine',
        PrintTooltip: () => 'Imprimer feuille jointe à la vignette',
        PrivateAccount: () => 'Compte particulier',
        DropdownLabel: () => 'Modifier',
        SlideInTitle: () => 'Immatriculation',
        ChangeLicensePlate: () => 'Changement immatriculation',
    },
    it: {
        CompanyAccount: () => 'Conto aziendale',
        None: () => 'Keine',
        PrintTooltip: () => 'Stampare foglio accompagnatorio vignetta',
        PrivateAccount: () => 'Conto privato',
        DropdownLabel: () => 'Modificare',
        SlideInTitle: () => 'Targa',
        ChangeLicensePlate: () => 'Cambio targa',
    },
};

interface CarTypeTexts {
    Type: Translation;
}

export const carTypeTexts: Translations<CarTypeTexts> = {
    de: {
        Type: () => 'Fahrzeugtyp',
    },
    fr: {
        Type: () => 'Type de véhicule',
    },
    it: {
        Type: () => 'Tipo veicolo',
    },
};

interface EditTexts {
    ForeignElementsHeader: Translation;
    ForeignElementsBody: (lp: string) => string;
    NewLpHasVignetteErrorHeader: Translation;
    NewLpHasVignetteErrorBody: (oldLp: string, newLp: string) => string;
    ReplaceHeader: Translation;
    ReplaceWithVignetteBody: (lp: string) => string;
    ReplaceWithoutVignetteBody: (lp: string) => string;
    HasVignettesHeader: Translation;
    HasVignettesBody: (lp: string) => string;
    Error: Translation;
    Until: Translation;
    NewPlate: Translation;
    Permits: Translation;
    Clearances: Translation;
    NoItemsHeader: Translation;
    NoItemsBody: (lp: string) => string;
}

export const editTexts: Translations<EditTexts> = {
    de: {
        Permits: () => 'Bewilligungen',
        Clearances: () => 'Freigaben',
        NewPlate: () => 'Neues Kennzeichen',
        Until: () => 'bis',
        Error: () => 'Fehler',
        ForeignElementsHeader: () =>
            'Bewilligungen/Freigaben bei anderen Betreibern',
        ForeignElementsBody: (lp: string) =>
            `Für das alte Kennzeichen ${lp} sind Bewilligungen/Freigaben bei anderen Betreiber vorhanden; sie werden NICHT automatisch mutiert. Der Benutzer muss selber die Mutation beim entsprechenden Betreiber im Auftrag geben.`,
        NewLpHasVignetteErrorHeader: () => 'Kennzeichen mit Vignette',
        NewLpHasVignetteErrorBody: (oldLp: string, newLp: string) =>
            `Die Vignette vom alten Kennzeichen ${oldLp} kann nicht auf das Kennzeichen ${newLp} umschreiben werden, weil dieses Kennzeichen schon mind. eine Vignetten hat. Bitte klären Sie mit dem Benutzer ab, ob diese Vignetten noch in Gebrauch sind. Falls nicht, dann müssen Sie sich beim Parkingportal-Helpdesk melden.`,
        ReplaceHeader: () => 'Kennzeichen wechseln',
        ReplaceWithVignetteBody: (lp: string) =>
            `Dieses Kennzeichen wird das alte Kennzeichen ${lp} ersetzten: Vignetten und gültige Bewilligungen/Freigaben werden entsprechend mutiert.`,
        ReplaceWithoutVignetteBody: (lp: string) =>
            `Dieses Kennzeichen wird das alte Kennzeichen ${lp} ersetzten: Gültige Bewilligungen/Freigaben werden entsprechend mutiert.`,
        HasVignettesHeader: () => 'Kennzeichen mit Vignette',
        HasVignettesBody: (lp: string) =>
            `Das alte Kennzeichen ${lp} hat mind. eine Vignette und kann deswegen nicht automatisch geändert werden. Sie müssen sich beim Parkingportal-Helpdesk melden.`,
        NoItemsHeader: () => 'Kennzeichen ohne Vignette, Bewilligung/Freigabe',
        NoItemsBody: (lp: string) =>
            `Das Kennzeichen ${lp} hat weder eine Vignette noch eine gültige Bewilligung/Freigabe bei Ihnen und kann deswegen nicht gewechselt werden.`,
    },
    fr: {
        Permits: () => 'Autorisations',
        Clearances: () => 'Approbations',
        NewPlate: () => 'Nouvelle immatriculation',
        Until: () => 'à',
        Error: () => 'Erreur',
        ForeignElementsHeader: () =>
            "Autorisations/approbations chez d'autres exploitants",
        ForeignElementsBody: (lp: string) =>
            `Pour l'ancienne immatriculation ${lp} il y a des autorisations/approbations valide chez d'autres exploitants; elles NE seront PAS mutées automatiquement. L'utilisateur lui-même doit donner un ordre pour la mutation à l'exploitant correspondant.`,
        NewLpHasVignetteErrorHeader: () => 'Immatriculation avec vignette',
        NewLpHasVignetteErrorBody: (oldLp: string, newLp: string) =>
            `La vignette de l'ancienne immatriculation ${oldLp} ne peut pas être transféré sur l'immatriculation ${newLp}, car cette immatriculation a déjà au moins une vignette. Veuillez vérifier avec l'utilisateur si ces vignettes sont encore en cours d'utilisation. Si c'est pas le cas, vous devez connecter l'helpdesk Parkingportal.`,
        ReplaceHeader: () => 'Changement immatriculation',
        ReplaceWithVignetteBody: (lp: string) =>
            `Cette immatriculation va remplacer l'ancienne ${lp}: vignettes et les autorisations/approbations valides seront muté de façon correspondante.`,
        ReplaceWithoutVignetteBody: (lp: string) =>
            `Cette immatriculation va remplacer l'ancienne ${lp}: Les autorisations/approbations valides seront muté de façon correspondante.`,
        HasVignettesHeader: () => 'Immatriculation avec vignette',
        HasVignettesBody: (lp: string) =>
            `L'ancienne immatriculation ${lp} a au moins une vignette; pour cette raison elle ne peut pas être changée automatiquement. Vous devez connecter l'helpdesk Parkingportal.`,
        NoItemsHeader: () =>
            'Immatriculation sans vignette, autorisation/approbation',
        NoItemsBody: (lp: string) =>
            `L'immatriculation ${lp} n'a ni une vignette ni des autorisations/approbations valable chez vous; pour cette raison elle ne peut pas être changée.`,
    },
    it: {
        Permits: () => 'Autorizzazioni',
        Clearances: () => 'Approvazioni ',
        NewPlate: () => 'Nuova targa',
        Until: () => 'a',
        Error: () => 'errore',
        ForeignElementsHeader: () =>
            'Autorizzazioni/approvazioni presso altri gestori',
        ForeignElementsBody: (lp: string) =>
            `Per la vecchia targa ${lp} esistono delle autorizzazioni/approvazioni valide presso altri gestori; quest'ultime NON verranno modificate automaticamente. L'utente stesso deve impartire un ordine di modifica al gestore corrispondente.`,
        NewLpHasVignetteErrorHeader: () => 'Targa con vignetta',
        NewLpHasVignetteErrorBody: (oldLp: string, newLp: string) =>
            `La vignetta della vecchia targa ${oldLp} non può essere trasferita sulla targa ${newLp}, in quanto questa targa ha già almeno una vignetta. Vogliate verificare con l'utilizzatore se queste vignette sono ancora in uso. In caso negativo, dovete contattare l'helpdesk Parkingportal.`,
        ReplaceHeader: () => 'Cambio targa',
        ReplaceWithVignetteBody: (lp: string) =>
            `Questa targa andrà a sostituire la vecchia targa ${lp}: vignette e autorizzazioni/approvazioni valide verranno modificate di conseguenza.`,
        ReplaceWithoutVignetteBody: (lp: string) =>
            `Questa targa andrà a sostituire la vecchia targa ${lp}: Autorizzazioni/approvazioni valide verranno modificate di conseguenza.`,
        HasVignettesHeader: () => 'Targa con vignetta',
        HasVignettesBody: (lp: string) =>
            `La vecchia targa ${lp} ha almeno una vignetta; per questa ragione non può essere cambiata automaticamente. Vogliate contattare l'helpdesk Parkingportal.`,
        NoItemsHeader: () =>
            'Targa senza vignetta, autorizzazioni/approvazioni',
        NoItemsBody: (lp: string) =>
            `La targa ${lp} non ha ne una vignetta ne un'autorizzazione/approvazione; per questa ragione non può essere cambiata.`,
    },
};

export interface CountrySlideInTexts {
    Heading: Translation;
}

export const countrySelectionTexts: Translations<CountrySlideInTexts> = {
    de: {
        Heading: () => 'Eingabe des Landes',
    },
    fr: {
        Heading: () => 'Saisir le pays.',
    },
    it: {
        Heading: () => 'Registrare la nazione',
    },
};

/* tslint:enable:typedef max-line-length */
