export function shallowEqual(objA: any, objB: any): boolean {
    if (objA === objB) {
        return true;
    }

    const keysA = Object.keys(objA);

    if (keysA.length !== Object.keys(objB).length) {
        return false;
    }

    for (const key of keysA) {
        if (objA[key] !== objB[key]) {
            return false;
        }
    }
    return true;
}

/**
 * deletes all properties on the given object which are undefined or null
 * @param oIn
 */
export const compact = (oIn: Object): Object => {
    const oOut = Object.assign({}, oIn);
    Object.keys(oOut).forEach(
        k =>
            ((<any>oOut)[k] === undefined || (<any>oOut)[k] === null) &&
            delete (<any>oOut)[k],
    );
    return oOut;
};
