import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';

export interface State {
    expanded: boolean;
    likeAddress: Maybe<boolean>;
    line1: Maybe<string>;
    line2: Maybe<string>;
    countryId: Maybe<string>;
    city: Maybe<string>;
    zipCode: Maybe<string>;
    invoiceOnly: Maybe<boolean>;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'account-meta-ShippingAddress';
    }
    getInitialState(): State {
        return {
            expanded: false,
            likeAddress: null,
            line1: null,
            line2: null,
            countryId: null,
            city: null,
            zipCode: null,
            invoiceOnly: null,
        };
    }
    sideEffects(): null {
        return null;
    }
    toggleExpanded(): void {
        this.set((s: State): State => {
            s.expanded = !s.expanded;
            return s;
        });
    }
}
