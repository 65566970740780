import styled from '@emotion/styled';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../style/typo';
import { QuickCheckoutConstants } from './QuickCheckoutConstants';
import { labelStyle } from '../../common/components/ticketplus/TicketPlusLayout';
import { FieldErrors, FieldName, get } from 'react-hook-form';
import { css } from '@emotion/css';

import {
    RadioActive,
    RadioInactive,
} from 'dg-web-shared/ui/icons/IconsScalable';

export const QuickCheckoutLabel = styled.div<{ noPaddingTop?: boolean }>(
    props =>
        props.noPaddingTop
            ? {
                  ...labelStyle,
                  paddingTop: 0,
                  '&:first-child': {
                      paddingTop: 0,
                  },
              }
            : labelStyle,
);

export const QuickCheckoutInputLabel = styled.label({
    display: 'block',
    ...labelStyle,
});

export const QuickCheckoutInput = styled.input<{ noPadding?: boolean }>(
    props => ({
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        border: '1px solid',
        borderColor: Colors.rgba(Colors.white, 0.8),
        borderRadius: QuickCheckoutConstants.BORDER_RADIUS,
        backgroundColor: 'transparent',
        ...Typo.body,
        color: Colors.white,
        height: QuickCheckoutConstants.INPUT_HEIGHT,
        minWidth: QuickCheckoutConstants.INPUT_HEIGHT,
        padding: props.noPadding ? undefined : '12px',
        '&::placeholder': {
            color: Colors.rgba(Colors.white, 0.6),
        },
    }),
);

export const QuickCheckoutInlineWarning = styled.div<{
    marginTop?: string;
    marginBottom?: string;
}>(props => ({
    backgroundColor: Colors.yellow,
    color: Colors.black,
    ...Typo.caption,
    padding: '12px',
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    flexShrink: 0,
}));

type FieldValuesFromFieldErrors<TFieldErrors> =
    TFieldErrors extends FieldErrors<infer TFieldValues> ? TFieldValues : never;

export function FormFieldValidationError<TFieldErrors extends FieldErrors>({
    errors,
    name,
}: {
    errors: TFieldErrors;
    name: FieldName<FieldValuesFromFieldErrors<TFieldErrors>>;
}) {
    const error = get(errors, name);

    if (!error) {
        return (
            <div
                className={css({
                    height: '19px',
                    marginBottom: '-8px',
                    flexShrink: 0,
                })}
            />
        );
    }

    return (
        <div
            className={css({
                paddingTop: '3px',
                height: '19px',
                flexShrink: 0,
                ...Typo.caption,
                color: Colors.yellow,
                marginBottom: '-8px',
            })}
        >
            {error.message}
        </div>
    );
}

export function RadioElementQuickCheckout({
    toggled,
    onClick,
    children,
    iconElement,
}: {
    toggled: boolean;
    onClick: () => void;
    children?: React.ReactNode;
    iconElement: JSX.Element;
}) {
    return (
        <div
            onClick={onClick}
            className={css({
                display: 'flex',
                lineHeight: '50px',
                cursor: 'pointer',
            })}
        >
            <div
                className={css({
                    height: '50px',
                    width: '50px',
                    marginLeft: '-12px',
                })}
            >
                {toggled ? (
                    <RadioActive
                        fillColor={Colors.white}
                        hookColor={Colors.black}
                    />
                ) : (
                    <RadioInactive />
                )}
            </div>
            <div
                className={css({
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottom: `1px solid ${Colors.white}`,
                })}
            >
                <div
                    className={css({
                        flex: '1 0',
                        paddingLeft: '8px',
                    })}
                >
                    {children}
                </div>
                <div
                    className={css({
                        display: 'flex',
                        height: '28px',
                        width: '38px',
                    })}
                >
                    {iconElement}
                </div>
            </div>
        </div>
    );
}
