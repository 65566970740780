import { useStore } from 'dg-web-shared/lib/Flux';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as SettingsState from '../../common/state/SettingsState';
import * as Fields from '../../utils/Fields';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import * as CurrentLoginActions from './actions/CurrentLoginActions';
import { isDefined } from 'dg-web-shared/lib/MaybeV2';
import { GenericError } from '../logins/EditForm';
import { css } from '@emotion/css';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import {
    lowerCasePasswordRegex,
    numericPasswordRegex,
    symbolsPasswordRegex,
    upperCasePasswordRegex,
} from 'dg-web-shared/lib/auth/PasswordRegexp';
import { useState } from 'react';
import { Icon16 } from 'dg-web-shared/ui/icons/Icon';

export const PasswordEditFormSlideIn = portalSlideIn(PasswordEditForm);

export function passwordToShort(password: string): JSX.Element | null {
    if (isDefined(password) && password.length < 6) {
        return (
            <Localized
                de="Passwort zu kurz, Minimum 6 Zeichen verwenden"
                fr="Mot de passe trop court, utiliser au moins 6 caractères"
                it="Password troppo corta, utilizzare almeno 6 caratteri"
                en="Password too short, use minimum 6 characters"
            />
        );
    }
    return null;
}

function PasswordEditForm(props: {
    currentLogin: CurrentLoginState.CurrentLogin;
    onClose: () => void;
}) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const { storeState, store, update } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        response: new CurrentLoginActions.PasswordEdit.ResponseStateSlice(s)
            .state,
    }));

    function onClose() {
        update(s => {
            new CurrentLoginActions.PasswordEdit.ResponseStateSlice(s).reset();
            return 'reset-password-edit-on-close';
        });

        props.onClose();
    }

    const unequalNewPassword =
        showErrorMessage &&
        newPassword &&
        oldPassword &&
        newPassword2 !== newPassword;

    const isOldPasswordInCorrect =
        storeState.response.responseCode ===
        CurrentLoginActions.PasswordEdit.ResponseCode.OLD_PW_INVALID;

    const sameOldAndNewPassword = oldPassword && oldPassword === newPassword;

    const newPasswordToShort = passwordToShort(newPassword);

    const isSaveable =
        !newPasswordToShort &&
        oldPassword !== newPassword &&
        newPassword2 === newPassword &&
        Fields.noStatesAndNotEmpty(
            new Fields.Password(oldPassword),
            new Fields.Password(newPassword),
            new Fields.Password(newPassword2),
        ) &&
        !storeState.response.pending;

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled: !isSaveable,
                onClick: () => {
                    store.legacyUpdater(CurrentLoginActions.putNewPassword, {
                        oldPassword,
                        newPassword,
                        onSuccess: onClose,
                    });
                },
            }}
        >
            <TextField
                context={InputContext.form}
                inputType="password"
                value={oldPassword}
                errorText={
                    isOldPasswordInCorrect ? (
                        <Localized
                            de="Falsches Passwort"
                            fr="Mot de passe incorrect"
                            it="Password errata"
                            en="Wrong password"
                        />
                    ) : (
                        ''
                    )
                }
                labelText={
                    <Localized
                        de="Altes Passwort"
                        fr="Ancien mot de passe"
                        it="Vecchia password"
                        en="Old password"
                    />
                }
                onChange={setOldPassword}
            />

            <TextField
                context={InputContext.form}
                inputType="password"
                value={newPassword}
                errorText={
                    sameOldAndNewPassword ? (
                        <Localized
                            de="Altes und neues Passwort ist identisch"
                            fr="L'ancien et le nouveau mot de passe sont identiques"
                            it="La vecchia e la nuova password sono identiche"
                            en="Old and new password are identical"
                        />
                    ) : showErrorMessage && newPassword ? (
                        newPasswordToShort
                    ) : (
                        ''
                    )
                }
                labelText={
                    <Localized
                        de="Neues Passwort"
                        fr="Nouveau mot de passe"
                        it="Nuova password"
                        en="New password"
                    />
                }
                onChange={setNewPassword}
                onFocus={() => setShowErrorMessage(false)}
                onBlur={() => setShowErrorMessage(true)}
            />

            <TextField
                context={InputContext.form}
                inputType="password"
                value={newPassword2}
                errorText={
                    unequalNewPassword && newPassword2 ? (
                        <Localized
                            de="Passwörter stimmen nicht überein"
                            fr="Mots de passe ne correspondent pas"
                            it="Le due password inserite non corrispondono"
                            en="The two passwords you entered do not match."
                        />
                    ) : (
                        ''
                    )
                }
                labelText={
                    <Localized
                        de="Passwort-Bestätigung"
                        fr="Confirmer le mot de passe"
                        it="Conferma password"
                        en="Repeat password"
                    />
                }
                onChange={setNewPassword2}
                onFocus={() => {
                    if (!newPasswordToShort) {
                        setShowErrorMessage(false);
                    }
                }}
                onBlur={() => setShowErrorMessage(true)}
            />
            <PasswordStrengthIndicator password={newPassword} />

            {storeState.response.statusCode.cls.error &&
                storeState.response.responseCode !==
                    CurrentLoginActions.PasswordEdit.ResponseCode
                        .OLD_PW_INVALID && <GenericError />}
        </SlideInForm>
    );
}

interface PasswordStrengthIndicatorProps {
    password: string | null;
}

export function PasswordStrengthIndicator(
    props: PasswordStrengthIndicatorProps,
) {
    const passwordStrength = passwordCheck(props.password);

    if (!passwordStrength) {
        return null;
    }

    const passwordStrengthColor = getPasswordStrengthColor(passwordStrength);

    return (
        <div
            className={css({
                ...Typo.robotoRegular,
                color: Colors.action_f,
                fontSize: '16px',
                lineHeight: '22px',
                fontWeight: 'bold',
                display: 'flex',
            })}
        >
            <Localized
                de="Sicherheit:"
                fr="Sécurité:"
                it="Sicurezza:"
                en="Security:"
            />

            <div
                className={css({
                    alignSelf: 'center',
                    padding: '0px 6px 0px 12px',
                    ...passwordStrengthColor,
                })}
            >
                <Icon16 icon={Icons16.indicatorCircle} />
            </div>
            <div
                className={css({
                    ...passwordStrengthColor,
                })}
            >
                <PasswordStrengthText passwordStrength={passwordStrength} />
            </div>
        </div>
    );
}

function getPasswordStrengthColor(passwordStrength: string) {
    switch (passwordStrength) {
        case PasswordStrength.WEAK:
            return {
                color: Colors.error,
            };
        case PasswordStrength.MEDIUM:
            return {
                color: Colors.error_b,
            };
        case PasswordStrength.STRONG:
            return {
                color: Colors.grassGreen,
            };
    }
}

function PasswordStrengthText(props: { passwordStrength: string }) {
    switch (props.passwordStrength) {
        case PasswordStrength.WEAK:
            return <Localized de="Schwach" fr="Faible" it="Debole" en="Weak" />;
        case PasswordStrength.MEDIUM:
            return <Localized de="Mittel" fr="Moyen" it="Medio" en="Medium" />;
        case PasswordStrength.STRONG:
            return <Localized de="Stark" fr="Fort" it="Forte" en="Strong" />;
    }
    return null;
}

enum PasswordStrength {
    WEAK = 'WEAK',
    MEDIUM = 'MEDIUM',
    STRONG = 'STRONG',
}

function passwordCheck(password: string | null) {
    const strongRegex = new RegExp(
        `^${lowerCasePasswordRegex}${upperCasePasswordRegex}${numericPasswordRegex}${symbolsPasswordRegex}(?=.{8,})`,
    );
    const mediumRegex = new RegExp(
        `^((${lowerCasePasswordRegex}${upperCasePasswordRegex})|(${lowerCasePasswordRegex}${numericPasswordRegex})|(${upperCasePasswordRegex}${numericPasswordRegex})|(${upperCasePasswordRegex}${symbolsPasswordRegex})|(${lowerCasePasswordRegex}${symbolsPasswordRegex})|(${numericPasswordRegex}${symbolsPasswordRegex}))(?=.{6,})`,
    );

    if (!password) {
        return null;
    }

    if (strongRegex.test(password)) {
        return PasswordStrength.STRONG;
    } else if (mediumRegex.test(password)) {
        return PasswordStrength.MEDIUM;
    } else {
        return PasswordStrength.WEAK;
    }
}
