import { css } from '@emotion/css';

export const clearfix = css({
    '&::before': {
        content: `''`,
        display: 'table',
    },
    '&::after': {
        content: `''`,
        display: 'table',
        clear: 'both',
    },
});
