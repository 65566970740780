import { useStore } from 'dg-web-shared/lib/Flux';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import {
    LabeledToggle,
    ToggleType,
} from '../../../../tb-ui/toggle/LabeledToggle';
import { Localized } from '../../common/components/Localized';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { EditFormSlideIn } from './EditForm';
import { EditFormState } from './EditFormState';
import * as LoginsState from './state/LoginsState';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export function StatusEditFormSlideIn(props: {
    portal: SlideInPortalId;
    title: React.ReactNode;
    open: boolean;
    login: LoginsState.GetLoginData | null;
    onClose: () => void;
}) {
    const { storeState } = useStore(s => ({
        edit: new EditFormState.StateSlice(s).state,
        legacyUpdater: s.legacyUpdater,
    }));

    if (!props.login) {
        return (
            <EditFormSlideIn isSaveable={false} {...props}>
                <Spinner />
            </EditFormSlideIn>
        );
    }

    const deactivated =
        storeState.edit.deactivated === null
            ? props.login.deactivated
            : storeState.edit.deactivated;

    return (
        <EditFormSlideIn
            isSaveable={deactivated !== props.login.deactivated}
            {...props}
        >
            <LabeledToggle
                type={ToggleType.checkbox}
                context={InputContext.form}
                label={
                    <Localized
                        de="Inaktiv"
                        fr="Pas actif"
                        it="Non attivo"
                        en="Deactivated"
                    />
                }
                selected={!!deactivated}
                onClick={() => {
                    storeState.legacyUpdater(
                        EditFormState.writeDeactivated,
                        !deactivated,
                    );
                }}
            />
        </EditFormSlideIn>
    );
}
