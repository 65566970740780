import * as Flux from 'dg-web-shared/lib/Flux';
import * as LayoutState from '../state/LayoutState';
import { responsiveVariant } from '../utils/responsiveVariant';
import { css } from '@emotion/css';

export interface LayoutProps {
    children?: React.ReactNode;
    allState: Flux.Store;
}
interface LayoutComponentState {
    layout: LayoutState.State;
}
export class Layout extends Flux.ContainerWithProps<
    LayoutProps,
    LayoutComponentState
> {
    static displayName = 'Layout';

    stateSelector(): LayoutComponentState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        return (
            <div>
                <div
                    className={css([
                        {
                            height: '100%',
                        },
                        responsiveVariant(this.state) === 'Mobile' && {
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            overflow: 'hidden',
                        },
                    ])}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
