import * as superagent from 'superagent';

export function getServerVersion(): superagent.Request<any, void> {
    return superagent.get('/').query(Date.now() + ''); // query parameter to circumvent browser cache
}

export function parseVersion(text: string): string | undefined {
    const m = text.match(/PCS_VERSION\s*=\s*\'([^\']+)\'\;/);
    if (m !== null) {
        return m[1];
    }
}
