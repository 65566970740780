import { useStore } from 'dg-web-shared/lib/Flux';
import { BasicButton } from '../../../../../tb-ui/buttons/BasicButton';
import { ButtonRow } from '../../../../../tb-ui/buttons/ButtonRow';
import * as Text from '../../../common/i18n/Text';
import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { Helpdesk } from './Helpdesk';
import { Address } from './Address';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../../tb-ui/typo';
import { Localized } from '../../../common/components/Localized';
import { useNavigate } from 'react-router-dom';

export interface Texts {
    Refresh: Text.Translation;
    Info: Text.Translation;
}

export function OfflinePage() {
    const { store } = useStore(() => null);
    const navigate = useNavigate();

    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <div
                    className={css({
                        maxWidth: '480px',
                        color: Colors.typo1,
                        ...Typo.body,
                    })}
                >
                    <Localized
                        de={
                            'Es konnten keine Daten vom Server geladen werden. Bitte überprüfen Sie die Internet-Verbindung Ihres Gerätes und drücken Sie auf "Erneut versuchen".'
                        }
                        fr={
                            'Il n\'a pas été possible de charger les donnés du serveur. Veuillez vérifier la connexion Internet de votre appareil et puis appuyez sur "Essayer de nouveau".'
                        }
                        it={
                            'Non è stato possibile caricare i dati dal server. Verifichi la connessione Internet del suo apparecchio e prema quindi su "Riprova".'
                        }
                        en={
                            'Data could not be loaded from the server. Check your device\'s Internet connection and press on "Try again".'
                        }
                    />
                    <ButtonRow>
                        <BasicButton
                            label={
                                <Localized
                                    de={'Erneut versuchen'}
                                    fr={'Essayer de nouveau'}
                                    it={'Riprova'}
                                    en={'Try again'}
                                />
                            }
                            negative={true}
                            onClick={() => navigate('/')}
                        />
                    </ButtonRow>
                </div>
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}
