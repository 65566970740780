import { ErrorBlock } from '../../account/root/components/ErrorBlock';
import { Localized } from './Localized';

export function ParkingpayGenericError() {
    return (
        <ErrorBlock
            text={
                <Localized
                    de={
                        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk.'
                    }
                    fr={
                        'Une erreur est survenue. Merci de réessayer plus tard. Si le problème persiste, merci de l’annoncer à notre Helpdesk.'
                    }
                    it={
                        'Si è verificato un errore. Vogliate riprovare più tardi. Se il problema dovesse persistere, contattate il nostro Helpdesk.'
                    }
                    en={
                        'An error has occurred. Please try again later. If the problem persists, please contact our Helpdesk.'
                    }
                />
            }
        />
    );
}
