import { css } from '@emotion/css';

import { LicensePlateLike } from 'dg-web-shared/dto/LicensePlate';
import * as Flux from 'dg-web-shared/lib/Flux';
import { getOrElse, Maybe } from 'dg-web-shared/lib/MaybeV2';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers';
import { localeCompare } from 'dg-web-shared/lib/String';
import { InputContext } from '../../../../../tb-ui/inputs/InputContext';
import { SingleSelection } from '../../../../../tb-ui/inputs/SingleSelection';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import { vehicleTexts } from '../../../account/vehicles/VehicleTexts';
import * as BadgeTypesState from '../../../common/state/BadgeTypesState';
import * as SettingsState from '../../../common/state/SettingsState';
import { Colors } from 'dg-web-shared/ui/vars';
import { licensePlateDropinTexts } from '../../i18n/DropinTexts';
import { ParkCreatePortalSlideIn } from '../../ParkCreateSlideIn';
import * as EntitySelectionState from '../../state/EntitySelectionState';
import { ParkCreateAddVehicleState, ParkCreateMode } from '../ParkCreate';
import {
    AddBadgeSlideIn,
    AddVehicleSlideIn,
} from './AddIdentificationEntitySlideIns';
import { BadgeEntityComp, LicensePlateEntityComp } from './Entities';
import { PermitTypeState } from '../permit/Permit';
import { Typo } from '../../../../../tb-ui/typo';
import { MaterialButton } from '../../../ui/inputs/MaterialButton';
import { Localized } from '../../../common/components/Localized';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export const EntityItem = Flux.selectState<
    {
        entity: Entity;
        onClick: ClickHandler;
        selectionMode: EntitySelectionState.SelectionMode;
        selected: boolean;
        disabled: boolean;
    },
    {
        settings: SettingsState.State;
        badgeTypes: BadgeTypesState.List.State;
    }
>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        badgeTypes: BadgeTypesState.List.get(store),
    }),
    p => {
        return (
            <Clickable
                element="div"
                className={css({
                    display: 'flex',
                    height: '48px',
                    padding: '0 24px',
                    color: Colors.action_w,
                    alignItems: 'baseline',
                    paddingTop: '10px',
                })}
                onClick={p.onClick}
                data-selection-mode={p.selectionMode}
                data-selected={p.selected}
                data-disabled={p.disabled}
                disabled={p.disabled}
            >
                <Conditional c={p.selectionMode === 'multiple'}>
                    <div className={css({ width: '30px' })}>
                        <div className={css({ marginBottom: '-7px' })}>
                            <Icon
                                icon={
                                    p.selected ? 'checkbox' : 'checkboxOutline'
                                }
                            />
                        </div>
                    </div>
                </Conditional>
                {p.entity.entityType === 'LICENSE_PLATE' && (
                    <LicensePlateEntityComp licensePlate={p.entity} />
                )}
                {p.entity.entityType === 'BADGE' && (
                    <BadgeEntityComp
                        {...p.entity}
                        remarks={p.entity.remarks || null}
                        type={p.entity.typeId}
                    />
                )}
            </Clickable>
        );
    },
);

function MaxEntitiesPlates(props: {
    maxLicensePlates: number;
    language: string;
}) {
    const texts = licensePlateDropinTexts[props.language];
    return (
        <div
            className={css({
                color: Colors.action_w,
                padding: '0 24px 20px 24px',
                ...Typo.caption,
            })}
        >
            {texts.numSelectableLicensePlates(
                props.maxLicensePlates.toString(),
            )}
        </div>
    );
}

interface LicensePlateEntity extends LicensePlateLike {
    entityType: 'LICENSE_PLATE';
    vehicleId: number | null;
}

export interface BadgeEntity {
    entityType: 'BADGE';
    id: number;
    labelNr: string;
    typeId: BadgeTypesState.BadgeTypeEnum;
    remarks: Maybe<string>;
    vehicleId: number | null;
}

const badgeIsActive = (b: BadgeEntity) =>
    b.labelNr && b.labelNr.toUpperCase() !== 'ORDERED';

export type Entity = LicensePlateEntity | BadgeEntity;

export interface EntitySelectionDropinProps {
    language: string;
    entities: Entity[];
    selectedEntityIds: number[];
    selectionMode: EntitySelectionState.SelectionMode;
    open: boolean;
    onSelect: (lpId: number) => void;
    onClose: () => void;
    update: Flux.Updater;
    disableUnselected: boolean;
    maxEntities?: Maybe<number>;
    canAddNewEntities: boolean;
    mode: ParkCreateMode;
    onVehicleAddSuccess?: () => void;
    permitTypeState?: PermitTypeState;
}

export const EntitySelectionDropin = (p: EntitySelectionDropinProps) => {
    const vTexts = vehicleTexts[p.language];

    return (
        <>
            <ParkCreatePortalSlideIn
                open={p.open}
                title={vTexts.vehicles()}
                onClose={p.onClose}
                hideClose
            >
                <div
                    className={css({
                        backgroundColor: Colors.white,
                        paddingTop: '12px',
                    })}
                >
                    <div
                        className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '20px',
                        })}
                    >
                        <MaterialButton
                            primary
                            label={
                                <Localized
                                    de="Zurück"
                                    fr="Retour"
                                    it="Indietro"
                                    en="Back"
                                />
                            }
                            onClick={p.onClose}
                        />
                    </div>
                    {p.canAddNewEntities && (
                        <div
                            className={css({
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            })}
                        >
                            <SingleSelection
                                labelText={vTexts.addVehicle()}
                                context={InputContext.form}
                                onClick={() =>
                                    p.update(
                                        ParkCreateAddVehicleState.stateWrite,
                                        {
                                            addVehicle: true,
                                        },
                                    )
                                }
                                selection={null}
                            />
                        </div>
                    )}

                    <Conditional c={p.selectionMode === 'multiple'}>
                        <MaxEntitiesPlates
                            language={p.language}
                            maxLicensePlates={getOrElse(p.maxEntities, 0)}
                        />
                    </Conditional>

                    <EntitiesList
                        selectionMode={p.selectionMode}
                        disableUnselected={p.disableUnselected}
                        entities={p.entities}
                        selectedEntityIds={p.selectedEntityIds}
                        onSelect={p.onSelect}
                    />
                </div>
            </ParkCreatePortalSlideIn>
            <AddVehicleSlideIn
                mode={p.mode}
                permitTypeState={p.permitTypeState}
                onVehicleAddSuccess={p.onVehicleAddSuccess}
            />
            <AddBadgeSlideIn
                mode={p.mode}
                permitTypeState={p.permitTypeState}
                onVehicleAddSuccess={p.onVehicleAddSuccess}
            />
        </>
    );
};

function EntitiesList(props: {
    entities: Entity[];
    selectedEntityIds: number[];
    selectionMode: EntitySelectionState.SelectionMode;
    onSelect: (lpId: number) => void;
    disableUnselected: boolean;
}) {
    return props.selectionMode === 'multiple' ? (
        <MultiselectionEntitiesList {...props} />
    ) : (
        <SingleSelectionEntitiesList {...props} />
    );
}

function MultiselectionEntitiesList(props: {
    entities: Entity[];
    selectedEntityIds: number[];
    onSelect: (lpId: number) => void;
    disableUnselected: boolean;
}) {
    return (
        <>
            {props.entities.sort(compareEntities).map(e => {
                const itemSelected = props.selectedEntityIds.includes(e.id);

                return (
                    <EntityItem
                        entity={e}
                        onClick={() => props.onSelect(e.id)}
                        selectionMode={'multiple'}
                        selected={itemSelected}
                        key={`entities-${e.id}-${e.vehicleId}`}
                        disabled={props.disableUnselected && !itemSelected}
                    />
                );
            })}
        </>
    );
}

function SingleSelectionEntitiesList(props: {
    entities: Entity[];
    selectedEntityIds: number[];
    onSelect: (lpId: number) => void;
    disableUnselected: boolean;
}) {
    return props.entities.length > SHORT_LIST_MAX_SIZE ? (
        <SingleSelectionEntitiesListLong {...props} />
    ) : (
        <SingleSelectionEntitiesListShort {...props} />
    );
}

function SingleSelectionEntitiesListShort(props: {
    entities: Entity[];
    selectedEntityIds: number[];
    onSelect: (lpId: number) => void;
    disableUnselected: boolean;
}) {
    return (
        <>
            {props.entities.sort(compareEntities).map(e => {
                const itemSelected = props.selectedEntityIds.includes(e.id);

                return (
                    <EntityItem
                        entity={e}
                        onClick={() => props.onSelect(e.id)}
                        selectionMode={'single'}
                        selected={itemSelected}
                        key={`entities-${e.id}-${e.vehicleId}`}
                        disabled={props.disableUnselected && !itemSelected}
                    />
                );
            })}
        </>
    );
}

function SingleSelectionEntitiesListLong(props: {
    entities: Entity[];
    selectedEntityIds: number[];
    onSelect: (lpId: number) => void;
    disableUnselected: boolean;
}): JSX.Element | null {
    if (props.entities.length === 0) {
        return null;
    }

    const selectedEntity =
        props.selectedEntityIds.length > 0
            ? props.entities.find(e => e.id === props.selectedEntityIds[0])
            : null;

    const otherEntities = selectedEntity
        ? props.entities.filter(e => e.id !== selectedEntity.id)
        : props.entities;

    return (
        <>
            {selectedEntity && (
                <>
                    <EntityItem
                        entity={selectedEntity}
                        onClick={() => {
                            props.onSelect(selectedEntity.id);
                        }}
                        selectionMode={'single'}
                        selected={true}
                        disabled={false}
                    />

                    <div
                        className={css({
                            margin: '20px 24px 2px 24px',
                            height: '2px',
                            backgroundColor: Colors.blue,
                        })}
                    />
                </>
            )}

            {otherEntities.sort(compareEntities).map(e => (
                <EntityItem
                    entity={e}
                    onClick={() => props.onSelect(e.id)}
                    selectionMode={'single'}
                    selected={false}
                    key={`entities-${e.id}-${e.vehicleId}`}
                    disabled={props.disableUnselected}
                />
            ))}
        </>
    );
}

function compareEntities(a: Entity, b: Entity): number {
    return a.entityType === 'BADGE' && b.entityType === 'BADGE'
        ? !badgeIsActive(a) && badgeIsActive(b)
            ? -1
            : badgeIsActive(a) && !badgeIsActive(b)
              ? 1
              : !badgeIsActive(a) && !badgeIsActive(b)
                ? 0
                : localeCompare(a.labelNr, b.labelNr)
        : a.entityType === 'LICENSE_PLATE' && b.entityType === 'LICENSE_PLATE'
          ? localeCompare(a.licensePlateNr, b.licensePlateNr)
          : 0;
}

const SHORT_LIST_MAX_SIZE = 6;
