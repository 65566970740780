import * as Fields from '../../../utils/Fields';
import { Language } from 'dg-web-shared/lib/Text';
import { paymentMethodTexts } from '../PaymentMethodTexts';

export function ibanErrorMessage(
    language: Language,
    validBankClearingNumbers: Set<number>,
    previousValue: string,
    value: string,
): string {
    const t = paymentMethodTexts[language];

    if (value === '') {
        return t.ibanFieldIsRequired();
    }

    const adt = new Fields.Iban(value.toUpperCase());
    const msg = Fields.ibanErrorText(adt, language);

    if (msg !== '') {
        return msg;
    }

    if (!adt.isCHLI) {
        return t.ibanMustBeCHorLI();
    }

    if (!validBankClearingNumbers.has(adt.clearingNumber)) {
        return t.ibanClearingNumberUnknown();
    }

    if (normalize(previousValue) === normalize(value)) {
        return t.ibanNewValueIsTheSameAsTheOldOne();
    }

    return '';
}

function normalize(iban: string) {
    return iban.toUpperCase().replace(/ +/g, '');
}
