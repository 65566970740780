import { Localized } from '../Localized';

export const paymentSuccessfulModalTitle = (
    <Localized
        de="Automatische Zahlung aktiviert"
        fr="Paiement automatique activé"
        it="Pagamento automatico attivato"
        en="Automatic payment activated"
    />
);
export const paymentSuccessfulModalMessage = (
    <Localized
        de="Das Ticket wurde für die automatische Zahlung freigegeben."
        fr="Le ticket a été approuvé pour le paiement automatique."
        it="Il ticket è stato abilitato al pagamento automatico."
        en="The ticket has been released for automatic payment."
    />
);

export const paymentAndAliasSuccessfulModalMessage = (
    <Localized
        de="Das Ticket wurde für die automatische Zahlung freigegeben und Ihr Parkingpay-Konto wurde für Einzelzahlung aktiviert."
        fr="Le ticket a été approuvé pour le paiement automatique et votre compte Parkingpay a été activé pour le paiement individuel."
        it="Il ticket è stato abilitato al pagamento automatico e il suo conto Parkingpay è stato attivato al pagamento singolo."
        en="The ticket has been released for automatic payment and your Parkingpay account was activated on individual payment."
    />
);

export const paymentAndTopupSuccessfulModalMessage = (
    <Localized
        de="Das Ticket wurde für die automatische Zahlung freigegeben und Ihr Parkingpay-Konto wurde geladen."
        fr="Le ticket a été approuvé pour le paiement automatique et votre compte Parkingpay a été alimenté."
        it="Il ticket è stato abilitato al pagamento automatico e il suo conto Parkingpay è stato ricaricato."
        en="The ticket has been released for automatic payment and your Parkingpay account has been topped up."
    />
);

export const paymentFailedModalTitle = (
    <Localized
        de="Aktivierung abgelehnt"
        fr="Activation refusée"
        it="Attivazione rifiutata"
        en="Activation rejected"
    />
);

export const paymentFailedModalMessage = (
    <Localized
        de="Die automatische Zahlung konnte nicht aktiviert werden."
        fr="Le paiement automatique n'a pas pu être activé."
        it="Il pagamento automatico non ha potuto essere attivato."
        en="The automatic payment could not be activated."
    />
);

export const paymentAbortedModalTitle = (
    <Localized
        de="Aktivierung abgebrochen"
        fr="Activation annulée"
        it="Attivazione annullata"
        en="Activation aborted"
    />
);

export const paymentAbortedModalMessage = (
    <Localized
        de="Die Aktivierung der automatischen Zahlung des Tickets wurde abgebrochen."
        fr="L'activation du paiement automatique du ticket a été annulée."
        it="L'attivazione del pagamento automatico del ticket è stata annullata."
        en="The activation of the automatic payment of the ticket was cancelled."
    />
);

export const deactivatePaymentModalTitle = (
    <Localized
        de="Zahlung deaktivieren"
        fr="Désactiver le paiement"
        it="Disattiva pagamento"
        en="Disable payment"
    />
);
export const deactivatePaymentModalMessage = (
    <Localized
        de="Sind Sie sicher, dass Sie die automatische Zahlung für dieses Ticket deaktivieren möchten?"
        fr="Êtes-vous sûr de vouloir désactiver le paiement automatique de ce ticket?"
        it="È sicuro di voler disattivare il pagamento automatico di questo ticket?"
        en="Are you sure you want to deactivate the automatic payment of this ticket?"
    />
);

export const deactivatePaymentFailedModalMessage = (
    <Localized
        de="Die automatische Zahlung für dieses Ticket konnte nicht deaktiviert werden."
        fr="Le paiement automatique pour ce ticket n'a pas pu être désactivé."
        it="Non è stato possibile disattivare il pagamento automatico per questo ticket."
        en="The automatic payment for this ticket could not be deactivated."
    />
);

export const ticketPaymentDisabledModalTitle = (
    <Localized
        de="Funktion nicht aktiv"
        fr="Fonction pas active"
        it="Funzione non attiva"
        en="Function not active"
    />
);
export const ticketPaymentDisabledModalMessage = (
    <>
        <p>
            <Localized
                de="Die Funktion für die automatische Zahlung des Tickets ist zurzeit nicht aktiv."
                fr="La fonction de paiement automatique du ticket n'est actuellement pas active."
                it="La funzione per il pagamento automatico del ticket non è al momento attiva."
                en="The function for automatic ticket payment is currently not active."
            />
        </p>
        <p>
            <Localized
                de="Bitte bezahlen Sie Ihr Ticket an der Kasse, bevor Sie zur Ausfahrt fahren."
                fr="Veuillez payer le ticket à la caisse avant de vous rendre à la sortie."
                it="Voglia pagare il ticket alla cassa, prima di recarsi all’uscita."
                en="Please pay the ticket at the cash desk before going to the exit."
            />
        </p>
    </>
);
export const ticketNotFoundModalTitle = (
    <Localized
        de="Ticket nicht gefunden"
        fr="Ticket pas trouvé"
        it="Ticket non trovato"
        en="Ticket not found"
    />
);
export const ticketNotFoundModalMessage = (
    <>
        <p>
            <Localized
                de="Dieses Ticket konnte nicht an das Online-System für die automatische Zahlung übermittelt werden."
                fr="Ce ticket n'a pas pu être transmis au système en ligne pour le paiement automatique."
                it="Questo ticket non ha potuto essere trasmesso al sistema online per il pagamento automatico."
                en="This ticket could not be transmitted to the online system for automatic payment."
            />
        </p>
        <p>
            <Localized
                de="Bitte bezahlen Sie Ihr Ticket an der Kasse, bevor Sie zur Ausfahrt fahren."
                fr="Veuillez payer le ticket à la caisse avant de vous rendre à la sortie."
                it="Voglia pagare il ticket alla cassa, prima di recarsi all’uscita."
                en="Please pay the ticket at the cash desk before going to the exit."
            />
        </p>
    </>
);
