import * as Flux from 'dg-web-shared/lib/Flux';
import { CustomerAccountType } from '../../../api/CustomerAccountType';
import { Localized } from '../../../common/components/Localized';
import * as Text from '../../../common/i18n/Text';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as MetaServerState from '../../meta/state/MetaServerState';
import { toggleCurrentLoginFormsVisibility } from '../CurrentLoginForms';
import { MainMenuItemBlock } from '../../root/components/ItemBlock';

export function CurrentLogin() {
    const { storeState, store } = Flux.useStore(s => ({
        currentLogin: CurrentLoginState.get(s),
        meta: new MetaServerState.StateSlice(s).state,
    }));

    return (
        <MainMenuItemBlock
            title={
                <Localized
                    de="Benutzerprofil"
                    fr="Profil de l'utilisateur"
                    it="Profilo utente"
                    en="User profile"
                />
            }
            subTitle={profileTitle(
                storeState.currentLogin.data,
                storeState.meta.data.customerAccountType,
            )}
            onClick={() => {
                store.legacyUpdater(toggleCurrentLoginFormsVisibility);
            }}
        />
    );
}

function profileTitle(
    login: CurrentLoginState.CurrentLogin | null,
    customerAccountType: CustomerAccountType,
): string {
    if (!login) {
        return '...';
    } else {
        const email = login.email || 'NO EMAIL!!';

        if (
            customerAccountType === CustomerAccountType.PRIVATE ||
            !login.firstName
        ) {
            return email;
        } else {
            return `${login.firstName} ${login.lastName}`;
        }
    }
}

export interface Texts {
    Reminders: Text.Translation;
    XActive: (n: number) => string;
}
