import { useState } from 'react';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    PayTicketButton,
    TicketPlusApprovalRequired,
} from '../../common/components/ticketplus/TicketPlusApprovalRequired';
import { TicketApiString } from '../../common/components/ticketplus/TicketPlusLayout';
import { PaymentStateModal } from './QuickCheckoutModals';
import {
    FlowStateAliasPending,
    FlowStateTicketScanned,
    PaymentModalState,
    StartPaymentResponse,
} from './QuickCheckoutTypes';
import { useParams } from 'react-router';
import { TicketPlusClaimedModal } from '../../common/components/ticketplus/TicketPlusClaimed';
import { TicketPlusTooManyApprovedTicketsModal } from '../../common/components/ticketplus/TicketPlusTooManyApprovedTickets';
import { QuickCheckoutTosCheckbox } from '../common/QuickCheckoutTosCheckbox';
import { QuickCheckoutFooter } from '../common/QuickCheckoutLayout';
import { useNavigate } from 'react-router-dom';

export const QuickCheckoutFlowTicketScanned = (
    props: (FlowStateTicketScanned | FlowStateAliasPending) & {
        paymentModalState?: PaymentModalState;
        onPaymentStateModalConfirm: () => void;
    },
) => {
    const { ticketId } = useParams();

    const [openTicketAlreadyClaimedModal, setOpenTicketAlreadyClaimedModal] =
        useState(false);
    const [openTooManyApprovedTicketsModal, setTooManyApprovedTicketsModal] =
        useState(false);
    const [paymentStartState, writePaymentStartState] = useServerWrite<
        {
            ticket: string;
            baseUri: string;
            tosAndPrivacyPolicyAccepted: boolean;
        },
        StartPaymentResponse,
        StartPaymentErrorResponse
    >(() => ({
        url: `/ui-api/quickcheckout/payment/start`,
    }));

    useServerSuccessEffect(paymentStartState, response => {
        window.location.href = response.redirectUrl;
    });

    useServerErrorEffect(paymentStartState, (_, res) => {
        switch (res?.errorType) {
            case StartPaymentErrorType.TICKET_ALREADY_CLAIMED:
                setOpenTicketAlreadyClaimedModal(true);
                break;
            case StartPaymentErrorType.TOO_MANY_APPROVED_TICKETS:
                setTooManyApprovedTicketsModal(true);
                break;
        }
    });

    const [tosAndPrivacyCheckboxSelected, setTosAndPrivacyCheckbox] = useState(
        props.flowState === 'TICKET_SCANNED' &&
            props.tosAndPrivacyPolicyAccepted,
    );

    const navigate = useNavigate();

    return (
        <>
            {props.paymentModalState !== undefined && (
                <PaymentStateModal
                    state={props.paymentModalState}
                    onConfirm={props.onPaymentStateModalConfirm}
                />
            )}
            <TicketPlusApprovalRequired
                onOpenZoneInfo={() => navigate('parking-details')}
                zoneName={props.zoneInfo.name}
                ticketCreatedAt={props.ticket.createdAt}
            />
            {openTicketAlreadyClaimedModal && (
                <TicketPlusClaimedModal
                    onClose={() => setOpenTicketAlreadyClaimedModal(false)}
                />
            )}
            {openTooManyApprovedTicketsModal && (
                <TicketPlusTooManyApprovedTicketsModal
                    onClose={() => setTooManyApprovedTicketsModal(false)}
                />
            )}
            <QuickCheckoutFooter>
                {props.flowState === 'TICKET_SCANNED' &&
                    !props.tosAndPrivacyPolicyAccepted && (
                        <QuickCheckoutTosCheckbox
                            selected={tosAndPrivacyCheckboxSelected}
                            setSelected={setTosAndPrivacyCheckbox}
                        />
                    )}
                <TicketApiString
                    ticketApiString={props.ticket.ticketApiString}
                />
                <PayTicketButton
                    loading={
                        paymentStartState.status === RequestStatus.PENDING ||
                        paymentStartState.status === RequestStatus.SUCCESS
                    }
                    disabled={
                        props.flowState === 'TICKET_SCANNED' &&
                        !tosAndPrivacyCheckboxSelected
                    }
                    onClick={() =>
                        writePaymentStartState({
                            ticket: ticketId ?? '',
                            baseUri: window.location.toString(),
                            tosAndPrivacyPolicyAccepted:
                                tosAndPrivacyCheckboxSelected,
                        })
                    }
                />
            </QuickCheckoutFooter>
        </>
    );
};

interface StartPaymentErrorResponse {
    errorType: StartPaymentErrorType;
}

enum StartPaymentErrorType {
    TICKET_ALREADY_CLAIMED = 'TICKET_ALREADY_CLAIMED',
    TOO_MANY_APPROVED_TICKETS = 'TOO_MANY_APPROVED_TICKETS',
}
