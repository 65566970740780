import { ParkCreatePortalSlideIn } from '../../park-create/ParkCreateSlideIn';
import { DatePicker, DatePickerProps } from '../datepicker/DatePicker';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';

interface DatePickerSlideinProps extends DatePickerProps {
    title: string;
    open: boolean;
    onClose: () => void;
}

export const ParkCreateDatePickerSlidein = (p: DatePickerSlideinProps) => (
    <ParkCreatePortalSlideIn title={p.title} onClose={p.onClose} open={p.open}>
        <div
            className={css({
                backgroundColor: Colors.white,
                display: 'flex',
                justifyContent: 'center',
                marginTop: '24px',
            })}
        >
            <DatePicker {...p} resetVisibleDate={p.open} />
        </div>
    </ParkCreatePortalSlideIn>
);
