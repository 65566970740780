import { useStore } from 'dg-web-shared/lib/Flux';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import * as MetaTexts from '../meta/i18n/MetaTexts';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { EditFormSlideIn } from './EditForm';
import { EditFormState } from './EditFormState';
import * as LoginsState from './state/LoginsState';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export function NameEditFormSlideIn(props: {
    portal: SlideInPortalId;
    title: React.ReactNode;
    open: boolean;
    login: LoginsState.GetLoginData | null;
    onClose: () => void;
}) {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        edit: new EditFormState.StateSlice(s).state,
        legacyUpdater: s.legacyUpdater,
    }));

    if (!props.login) {
        return (
            <EditFormSlideIn isSaveable={false} {...props}>
                <Spinner />
            </EditFormSlideIn>
        );
    }

    const mt = MetaTexts.generalTexts[storeState.settings.language];

    const firstName =
        storeState.edit.firstName === null
            ? props.login.firstName
            : storeState.edit.firstName;

    const lastName =
        storeState.edit.lastName === null
            ? props.login.lastName
            : storeState.edit.lastName;

    return (
        <EditFormSlideIn
            isSaveable={
                !!firstName &&
                !!lastName &&
                (firstName !== props.login.firstName ||
                    lastName !== props.login.lastName)
            }
            {...props}
        >
            <TextField
                context={InputContext.form}
                value={firstName}
                labelText={
                    <Localized
                        de="Vorname"
                        fr="Prénom"
                        it="Nome"
                        en="First name"
                    />
                }
                errorText={firstName ? '' : mt.MandatoryField()}
                onChange={(v: string) =>
                    storeState.legacyUpdater(EditFormState.writeFirstName, v)
                }
            />
            <TextField
                context={InputContext.form}
                value={lastName}
                labelText={
                    <Localized
                        de="Nachname"
                        fr="Nom"
                        it="Cognome"
                        en="Last name"
                    />
                }
                errorText={lastName ? '' : mt.MandatoryField()}
                onChange={(v: string) =>
                    storeState.legacyUpdater(EditFormState.writeLastName, v)
                }
            />
        </EditFormSlideIn>
    );
}
