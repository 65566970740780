import * as Flux from 'dg-web-shared/lib/Flux';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice';
import * as Http from '../../api/Http';
import * as AsyncRequest from '../../AsyncRequest';

export type BadgeType = Http.BadgeType;
export enum BadgeTypeEnum {
    PARKINGPAY_BADGE = 1,
    SwissPass = 5,
}

export const getBadgeTypeById = (badgeTypes: BadgeType[], id: number) =>
    badgeTypes.find(bt => bt.id === id);

export namespace List {
    export type State = ServerStateSlice.ServerState<BadgeType[]>;
    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<BadgeType[]>(
            'common/BadgeTypesState.List',
            () => [],
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(fetchBadgeTypes);
                }
            },
            (body: BadgeType[]): BadgeType[] => body,
        );
    const fetchBadgeTypes = AsyncRequest.requestV2(
        Http.getBadgeTypes,
        setResponse,
    );
}
