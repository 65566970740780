import { useStore } from 'dg-web-shared/lib/Flux';
import {
    AddTenantFormProps,
    ConfirmPage,
    CustomerInfo,
    FormLogic,
} from './ConfirmTenantShared';

function WinterThurForm({ info, refetch }: AddTenantFormProps) {
    return (
        <div>
            <h1>
                Formular zum Gesuch um eine Parkierungsbewilligung für das
                unbeschränkte Parkieren in der Blauen Zone (Monats- und
                Jahreskarten) (Art. 3 Abs. 1 PBZ)
            </h1>

            <CustomerInfo customer={info.customer} />

            <p>
                Gemäss Art. 3 Abs. 1 PBZ erhalten Einwohnerinnen und Einwohner
                sowie Betriebe für jeden auf ihren Namen und ihre Winterthurer
                Adresse eingelösten leichten Motorwagen eine Parkbewilligung,
                wenn ihr Wohnsitz oder ihr Betriebsdomizil innerhalb der Blauen
                Zone liegt{' '}
                <b>
                    und für die jeweilige Nutzergruppe keine private
                    Parkierungsmöglichkeit zur Verfügung steht*
                </b>
                . Keine Parkierungsbewilligung erhalten Personen und Betriebe,
                welche in Liegenschaften domiziliert sind, für die die minimal
                erforderliche Parkplatzzahl herabgesetzt wurde (Art. 3 Abs. 4
                PBZ).
            </p>
            <p>
                Als Nachweis, dass keine private Parkierungsmöglichkeit zur
                Verfügung steht, ist eine Bestätigung der Immobilienverwaltung /
                Vermietung bzw. Immobilieneigentümerschaft beizubringen.
            </p>

            <h2>
                Durch Immobilienverwaltung / Vermietung
                Immobilieneigentümerschaft auszufüllen
            </h2>
            <p>
                <b>
                    Obengenannte/-r Gesuchsteller/-in hat keine private
                    Parkierungsmöglichkeit:
                </b>
            </p>
            <FormLogic info={info} refetch={refetch} />

            <p>
                <b>*Keine private Parkierungsmöglichkeit bedeutet:</b>
            </p>
            <p>
                Alle vorhandenen Parkplätze sind bereits vermietet <b>und</b>{' '}
                die minimal erforderliche Parkplatzzahl gemäss der
                Parkplatzverordnung (PPVO) wurde nicht unterschritten.
            </p>
        </div>
    );
}

export function WinterthurConfirmTenantPage() {
    const { store } = useStore(() => null);
    return <ConfirmPage allState={store} FormComponent={WinterThurForm} />;
}
