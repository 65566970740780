import { useState } from 'react';
import {
    portalSlideIn,
    PortalSlideInProps,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { Localized } from '../../common/components/Localized';
import { SlideInForm } from '../root/components/SlideInForm';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { css } from '@emotion/css';
import { InlineInfoBox } from '../../ui/modals/Confirmable';
import { Colors } from 'dg-web-shared/ui/vars';
import {
    LprParkingsSelectionSlideIn,
    ToggleIcon,
} from './LprParkingsSelectionSlideIn';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { GeolocationState } from '../../common/state/GeolocationState';
import {
    LprParking,
    LprParkingSelectionState,
} from 'dg-web-shared/common/models/CustomerLprZoneSelection';
import { Location } from 'dg-web-shared/common/utils/Geolocation';
import { ParkingpayAsyncLoadedSection } from '../../common/components/ParkingpayAsyncLoadedSection';
import { RemoteRequestFailed } from './RemoteRequestFailed';
import { Vehicles } from './VehicleState';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { LprDisclaimerSlideIn } from './LprDisclaimerSlideIn';
import { Lang } from '../../../../../packages/twint-app/src/Translate';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const cameraPictureBits = require('../../../../assets/images/lpr.svg');

export const LprSettingsSlideIn = portalSlideIn<
    {
        numberOfEnabledLprZones: number;
        setOpenLprActivationModal: (open: boolean) => void;
    } & PortalSlideInProps
>(LprSettingsSlideInContent);

function LprSettingsSlideInContent({
    portal,
    onClose,
    numberOfEnabledLprZones,
    setOpenLprActivationModal,
}: {
    portal: SlideInPortalId;
    onClose: () => void;
    numberOfEnabledLprZones: number;
    setOpenLprActivationModal: (open: boolean) => void;
}) {
    const { storeState, update } = useStore(s => ({
        location: GeolocationState.get(s),
    }));
    const [currentLocation] = useState(storeState.location.currentLocation);
    const [lprParkingsListState] = useServerFetch<
        LprParking[],
        {
            location: Location | null;
        }
    >(
        ({ location }) => ({
            url: `/ui-api/customer-account/customer-lpr-parkings?lon=${
                location?.longitude ? location.longitude : 'NaN'
            }&lat=${location?.latitude ? location.latitude : 'NaN'}`,
        }),
        {
            location: currentLocation,
        },
    );
    const [showParkingsSelection, setShowParkingsSelection] = useState(false);
    const [newlyActivatedZoneIds, setNewlyActivatedZoneIds] = useState<
        number[]
    >([]);
    const [newlyDeactivatedZoneIds, setNewlyDeactivatedZoneIds] = useState<
        number[]
    >([]);
    const [customerLprZonesChangesState, sendCustomerLprZonesChanges] =
        useServerWrite<
            { activatedZoneIds: number[]; deactivatedZoneIds: number[] },
            { lprSuccessfullyActivated: boolean }
        >(() => {
            return {
                url: '/ui-api/customer-account/customer-lpr-parkings',
                method: RequestMethod.PUT,
            };
        });
    useServerSuccessEffect(customerLprZonesChangesState, lprChangesData => {
        update(s => {
            Vehicles.forceRefetch(s);
            CurrentLoginState.reset(s);
            return 'refresh-for-lpr-zone-selection-change';
        });
        setOpenLprActivationModal(lprChangesData.lprSuccessfullyActivated);
        onClose();
    });
    const currentlySelectedNumberOfZones =
        numberOfEnabledLprZones +
        newlyActivatedZoneIds.length -
        newlyDeactivatedZoneIds.length;
    const isEdited =
        newlyActivatedZoneIds.length > 0 || newlyDeactivatedZoneIds.length > 0;

    const [showLprDisclaimer, setShowLprDisclaimer] = useState(false);

    function deactivateAllZones(parkings: LprParking[]) {
        setNewlyActivatedZoneIds([]);
        setNewlyDeactivatedZoneIds(
            parkings
                .filter(
                    parking =>
                        parking.state === LprParkingSelectionState.ACTIVE,
                )
                .map(parking => parking.zoneId),
        );
    }
    function resetAndShowParkingsSelection() {
        setNewlyActivatedZoneIds([]);
        setShowParkingsSelection(true);
    }

    return (
        <SlideInForm
            secondaryButton={{
                label: <Localized {...GeneralTexts.cancel} />,
                onClick: onClose,
            }}
            primaryButton={{
                label:
                    customerLprZonesChangesState.status ===
                    RequestStatus.PENDING ? (
                        <Localized
                            de="Speichern..."
                            fr="Enregistrer..."
                            it="Salva..."
                            en="Saving..."
                        />
                    ) : (
                        <Localized {...GeneralTexts.save} />
                    ),
                disabled:
                    !isEdited ||
                    customerLprZonesChangesState.status ===
                        RequestStatus.PENDING,
                onClick: () =>
                    sendCustomerLprZonesChanges({
                        activatedZoneIds: newlyActivatedZoneIds,
                        deactivatedZoneIds: newlyDeactivatedZoneIds,
                    }),
            }}
        >
            <CameraPicture />

            <p>
                <Localized
                    de="Bei entsprechend ausgestatteten Parkings, wird Ihr Kennzeichen beim Ein-/Ausfahrt erkannt, die Barriere geht automatisch auf."
                    fr="Dans dans les parkings équipés de manière appropriée, votre immatriculation est reconnue à l'entrée/sortie, la barrière s'ouvre automatiquement."
                    it="Nei parcheggi adeguatamente attrezzati, la sua targa sarà riconosciuta all'entrata/uscita, la barriera si aprirà automaticamente."
                    en="In appropriately equipped car parks, your license plate will be recognised on entry/exit, the barrier will open automatically."
                />
            </p>

            <h3>
                <Localized
                    de="ÖFFENTLICHE PARKINGS"
                    fr="PARKINGS PUBLICS"
                    it="PARCHEGGI PUBBLICI"
                    en="PUBLIC PARKINGS"
                />
            </h3>

            <ParkingpayAsyncLoadedSection
                state={lprParkingsListState}
                renderSuccess={parkings => (
                    <>
                        <EnableLprToggle
                            onToggleClick={() => {
                                currentlySelectedNumberOfZones > 0
                                    ? deactivateAllZones(parkings)
                                    : setShowLprDisclaimer(true);
                            }}
                            onEdit={() => {
                                setShowParkingsSelection(true);
                            }}
                            numberOfEnabledLprZones={numberOfEnabledLprZones}
                            isEdited={isEdited}
                            currentlySelectedNumberOfZones={
                                currentlySelectedNumberOfZones
                            }
                        />

                        <LprDisclaimerSlideIn
                            portal={portal}
                            open={showLprDisclaimer}
                            onClose={() => setShowLprDisclaimer(false)}
                            onAgreement={resetAndShowParkingsSelection}
                            title={
                                <Localized
                                    de="Bedingungen"
                                    fr="Conditions"
                                    it="Condizioni"
                                    en="Conditions"
                                />
                            }
                        />

                        <LprParkingsSelectionSlideIn
                            portal={portal}
                            open={showParkingsSelection}
                            parkings={parkings}
                            title={
                                <Lang
                                    de="LPR Parkings"
                                    fr="Parkings LPR"
                                    it="Parcheggi LPR"
                                    en="LPR parkings"
                                />
                            }
                            onClose={() => {
                                setShowParkingsSelection(false);
                            }}
                            setNewlyActivatedZoneIds={setNewlyActivatedZoneIds}
                            setNewlyDeactivatedZoneIds={
                                setNewlyDeactivatedZoneIds
                            }
                            newlyActivatedZoneIds={newlyActivatedZoneIds}
                            newlyDeactivatedZoneIds={newlyDeactivatedZoneIds}
                        />
                    </>
                )}
            />

            <p>
                <Localized
                    de="Mit der Aktivierung dieser Option erklären Sie sich in den aktivierten Parkings damit einverstanden, die entsprechende Parkgebühr und den Zuschlag von diesem Parkingpay-Konto abzubuchen."
                    fr="En activant cette option, vous acceptez de débiter les frais de stationnement et le supplément correspondants de ce compte Parkingpay dans les places de stationnement activées."
                    it="Attivando questa opzione, accetti di addebitare la tariffa di parcheggio e il supplemento corrispondenti su questo conto Parkingpay nei parcheggi attivati."
                    en="By activating this option, in the enabled parkings, you agree to debit the corresponding parking fee and the surcharge to this Parkingpay account."
                />
            </p>

            <SurchargeText />

            <PrivateLprInlineInfo />

            {customerLprZonesChangesState.status === RequestStatus.ERROR && (
                <RemoteRequestFailed
                    httpStatusCode={customerLprZonesChangesState.httpStatusCode}
                />
            )}
        </SlideInForm>
    );
}

function EnableLprToggle({
    onToggleClick,
    onEdit,
    isEdited,
    numberOfEnabledLprZones,
    currentlySelectedNumberOfZones,
}: {
    onToggleClick: () => void;
    onEdit: () => void;
    isEdited: boolean;
    numberOfEnabledLprZones: number;
    currentlySelectedNumberOfZones: number;
}) {
    return (
        <p>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                <Clickable element="div" onClick={onToggleClick}>
                    <ToggleIcon selected={currentlySelectedNumberOfZones > 0} />
                </Clickable>
                <div
                    className={css({
                        paddingLeft: '10px',
                        fontWeight: 'bold',
                    })}
                >
                    <Localized
                        de="Aktiv in öffentlichen Parkings"
                        fr="Actif dans les parkings publics"
                        it="Attivo nei parcheggi pubblici"
                        en="Active in public parkings"
                    />
                </div>
            </div>
            {currentlySelectedNumberOfZones > 0 && (
                <div
                    className={css({
                        display: 'inline-flex',
                        margin: ' 7px 0 10px 34px',
                    })}
                >
                    <LprToggleCaption
                        numberOfLprZones={
                            isEdited
                                ? currentlySelectedNumberOfZones
                                : numberOfEnabledLprZones
                        }
                    />
                    <EditCaption onEdit={onEdit} />
                </div>
            )}
        </p>
    );
}
export function PrivateLprInlineInfo() {
    return (
        <div
            className={css({
                paddingTop: '24px',
            })}
        >
            <InlineInfoBox
                titleCaption={
                    <Localized
                        de="Mieter und Bewilligungen"
                        fr="Locataires et autorisations"
                        it="Abbonati e autorizzazioni"
                        en="Tenants and permits"
                    />
                }
            >
                <div
                    className={css({
                        color: Colors.action_f,
                    })}
                >
                    <p>
                        <Localized
                            de="Sobald Ihr Parkrecht (Freigaben, Bewilligung oder Spezialtarif) gültig ist, können Sie automatisch von der Kennzeichenerkennung profitieren."
                            fr="Dès que votre droit de stationnement (approbation, autorisations ou tarif spécial) est valable, vous pouvez bénéficier automatiquement de la reconnaissance de l'immatriculation."
                            it="Non appena il suo diritto di parcheggio (approvazione, autorizzazione o tariffa speciale) è valido, può beneficiare automaticamente del riconoscimento della targa."
                            en="As soon as your parking right (clearance, permit or special tariff) is valid, you can automatically benefit from the licence plate recognition."
                        />
                    </p>

                    <p
                        className={css({
                            fontWeight: 'bold',
                        })}
                    >
                        <Localized
                            de="In diesem Fall ist es nicht notwendig, die Funktion für öffentliche Parkings zu aktivieren."
                            fr="Dans ce cas, il n'est pas nécessaire d'activer la fonction pour les parkings publics."
                            it="In tal caso non è necessario attivare la funzione per i parcheggi pubblici."
                            en="In this case, it is not necessary to activate the function for public parking spaces."
                        />
                    </p>

                    <p
                        className={css({
                            fontWeight: 'bold',
                        })}
                    >
                        <Localized
                            de="Kein Zuschlag"
                            fr="Pas de supplément"
                            it="Nessun supplemento"
                            en="No surcharge"
                        />
                    </p>
                </div>
            </InlineInfoBox>
        </div>
    );
}

function LprToggleCaption({ numberOfLprZones }: { numberOfLprZones: number }) {
    switch (numberOfLprZones) {
        case 1:
            return (
                <Localized
                    de={`${numberOfLprZones} Parking`}
                    fr={`${numberOfLprZones} parking`}
                    it={`${numberOfLprZones} parcheggio`}
                    en={`${numberOfLprZones} parking`}
                />
            );
        default:
            return (
                <Localized
                    de={`${numberOfLprZones} Parkings`}
                    fr={`${numberOfLprZones} parkings`}
                    it={`${numberOfLprZones} parcheggi`}
                    en={`${numberOfLprZones} parkings`}
                />
            );
    }
}

function EditCaption({ onEdit }: { onEdit: () => void }) {
    return (
        <div>
            &nbsp;
            {'('}
            <Clickable
                element="span"
                onClick={onEdit}
                className={css({
                    textDecoration: 'underline',
                    cursor: 'pointer',
                })}
            >
                <Localized de="ändern" fr="modifier" it="modifica" en="edit" />
            </Clickable>
            {')'}
        </div>
    );
}

function CameraPicture() {
    return (
        <div
            className={css({
                display: 'flex',
                justifyContent: 'center',
                marginTop: '8px',
            })}
        >
            <img src={cameraPictureBits} width="100px" />
        </div>
    );
}

export const LprTitle = (
    <Localized
        de="Kennzeichenerkennung"
        fr="Reconnaissance de plaque"
        it="Riconoscimento targa"
        en="License plate recognition"
    />
);

export function SurchargeText() {
    return (
        <p
            className={css({
                fontWeight: 'bold',
            })}
        >
            <Localized
                de="Zuschlag: 25 Rp. (pro kostenpflichtiger Transaktion)"
                fr="Supplément: 25 ct. (par transaction payante)"
                it="Supplemento: 25 ct. (per transazione a pagamento)"
                en="Surcharge: 25 ct. (per paid transaction)"
            />
        </p>
    );
}
