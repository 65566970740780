import { css } from '@emotion/css';

import { Message } from 'dg-web-shared/lib/Localized';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter';
import { Localized } from '../../../common/components/Localized';
import { Typo } from '../../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { TopUpPaymentLogic } from './TopUpPaymentLogic';

export function AmountsChoiceGrid(props: {
    selectedAmount: number;
    accountBalanceChf: number;
    setSelectedAmount: (selectedAmount: number) => void;
    amountToLimit: number;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                flexWrap: 'wrap',
                margin: 0,
                padding: 0,
                fontSize: '16px',
                ...Typo.robotoMedium,
            })}
        >
            {TopUpPaymentLogic.topUpAmountOptions(props.accountBalanceChf).map(
                ({ value, enabled }) => (
                    <AmountChoice
                        key={value}
                        amount={value}
                        selected={value === props.selectedAmount}
                        enabled={enabled}
                        onClick={() => {
                            props.setSelectedAmount(value);
                        }}
                        width="33.33%"
                        displayCurrency={false}
                        amountToLimit={props.amountToLimit}
                    />
                ),
            )}
        </div>
    );
}

export function AmountChoice(props: {
    amount: number;
    selected: boolean;
    enabled: boolean;
    onClick: () => void;
    width: '33.33%' | '100%';
    displayCurrency: boolean;
    amountToLimit?: number;
}) {
    const baseInnerStyle: {
        padding: string;
        borderRadius: string;
        boxSizing: 'border-box';
        margin: string;
    } = {
        padding: '10px 20px',
        borderRadius: '20px',
        boxSizing: 'border-box',
        margin: '-10px -25px',
    };

    const isAboveLimit = props.amountToLimit
        ? props.amount > props.amountToLimit
        : false;
    const innerStyle = props.selected
        ? css({
              backgroundColor: isAboveLimit
                  ? Colors.disabledButtonGrey
                  : Colors.lightblue,
              color: Colors.white,
              ...baseInnerStyle,
          })
        : props.enabled
          ? css({
                color: isAboveLimit
                    ? Colors.disabledButtonGrey
                    : Colors.darkblue,
                ...baseInnerStyle,
            })
          : css({
                color: '#999',
                ...baseInnerStyle,
            });

    return (
        <div
            className={css({
                boxSizing: 'border-box',
                backgroundColor: Colors.form,
                flexGrow: 1,
                width: props.width,
                padding: '20px',
                overflow: 'hidden',
                margin: '-1px 0 0 -1px',
                border: `solid 1px ${Colors.darkblue}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                cursor: props.selected ? 'default' : 'pointer',
                fontSize: '16px',
                ...Typo.robotoMedium,
            })}
            onClick={props.enabled ? props.onClick : () => {}}
        >
            <div className={innerStyle}>
                {props.displayCurrency
                    ? numberToPrice(props.amount)
                    : props.amount.toLocaleString('de-CH')}
            </div>
        </div>
    );
}

export function AmountLabel(props: { text: Message }) {
    return (
        <div
            className={css({
                margin: '10px 0 20px 0',
                color: Colors.darkblue,
                fontSize: '16px',
                ...Typo.robotoMedium,
            })}
        >
            <Localized {...props.text} />
        </div>
    );
}
