import * as Flux from 'dg-web-shared/lib/Flux';
import * as ParkOptionListState from '../state/ParkOptionListState';
import * as PermitPurchaseState from '../state/permit/PermitPurchaseState';
import * as ZoneTransactionPurchaseState from '../state/zone-transaction/ZoneTransactionPurchaseState';
import * as CityDropinState from '../state/CityDropinState';
import * as ZoneDropinState from '../state/ZoneDropinState';
import * as EntitySelectionState from '../state/EntitySelectionState';
import { logAction } from '../../utils/ActionLog';

export { Response } from 'dg-web-shared/lib/HttpResponse';

export function onSelectedParkOptionHasChanged(store: Flux.Store): void {
    onPotentialOptionItemSelect(store);
}

export function resetCity(store: Flux.Store): string {
    ParkOptionListState.Selection.reset(store);
    onSelectedParkOptionHasChanged(store);
    return 'setCity';
}

export function setCity(store: Flux.Store, zipCode: string): string {
    new CityDropinState.StateSlice(store).setOpen(false);
    new CityDropinState.StateSlice(store).setFilterValue('');
    ParkOptionListState.Selection.setZipCode(store, zipCode);
    onSelectedParkOptionHasChanged(store);
    return 'setCity';
}

export function setParkOption(store: Flux.Store, uniqueId: number): string {
    new ZoneDropinState.StateSlice(store).setOpen(false);
    new ZoneDropinState.StateSlice(store).setFilterValue('');
    ParkOptionListState.Selection.setParkOptionId(store, uniqueId);
    EntitySelectionState.Selection.Permit.reset(store);
    onSelectedParkOptionHasChanged(store);
    return 'set-park-option';
}

export function onPotentialOptionItemSelect(store: Flux.Store): void {
    PermitPurchaseState.resetAllSlices(store);
    new ZoneTransactionPurchaseState.ResponseStateSlice(store).reset();
    new ZoneTransactionPurchaseState.InfoBlockStateSlice(store).reset();
}

export function parkVariantSelectLicensePlateTransaction(
    store: Flux.Store,
): string {
    ParkOptionListState.Selection.setVariant(store, 'licensePlateTransaction');
    logAction(store, 'select-license-plate-transaction-variant', null);
    onPotentialOptionItemSelect(store);
    return 'parkVariantSelectLicensePlateTransaction';
}

export function parkVariantSelectBadgeTransaction(store: Flux.Store): string {
    ParkOptionListState.Selection.setVariant(store, 'badgeTransaction');
    logAction(store, 'select-badge-transaction-variant', null);
    onPotentialOptionItemSelect(store);
    return 'parkVariantSelectBadgeTransaction';
}

export function parkVariantSelectPermit(
    store: Flux.Store,
    isRenewal: boolean,
): string {
    ParkOptionListState.Selection.setVariant(store, 'permit');
    if (!isRenewal) {
        logAction(store, 'select-permit-variant', null);
    }
    onPotentialOptionItemSelect(store);
    return 'parkVariantSelectPermit';
}

export function parkVariantSelectCityWidePermit(store: Flux.Store): string {
    ParkOptionListState.Selection.setVariant(store, 'cityWidePermit');
    logAction(store, 'select-city-wide-permit-variant', null);
    onPotentialOptionItemSelect(store);
    return 'parkVariantSelectCityWidePermit';
}
export function parkVariantSelectNone(store: Flux.Store): string {
    ParkOptionListState.Selection.setVariant(store, null);
    onPotentialOptionItemSelect(store);
    return 'parkVariantSelectNone';
}
