import { css } from '@emotion/css';

import { JsxMessageTemplate, Message } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../../common/components/Localized';
import { Typo } from '../../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';

export function LsvTip(props: {
    onNavigateToDirectDebitPaymentMethod: () => void;
}) {
    return (
        <div
            className={css`
                ${Typo.robotoMedium}
                padding: 24px 30px;
                font-size: 16px;
                line-height: 22px;
                color: ${Colors.action_w};
            `}
        >
            <div>
                <Localized {...messages.directDebitTipTitle} />
            </div>

            <p
                className={css`
                    ${Typo.robotoRegular}
                    font-size: 14px;
                `}
            >
                <Localized
                    {...messages.directDebitTipText(e => {
                        e.preventDefault();
                        props.onNavigateToDirectDebitPaymentMethod();
                    })}
                />
            </p>
        </div>
    );
}

const messages: Messages = {
    directDebitTipText(onClick) {
        return {
            de: (
                <span>
                    Wenn Sie regelmässig mit Parkingpay parkieren, lohnt es sich
                    die Zahlungsweise{' '}
                    <a href="" onClick={onClick}>
                        auf «Lastschrift (LSV)» zu ändern.
                    </a>{' '}
                    Einmal eingerichtet, müssen Sie sich nicht mehr darum
                    kümmern, ob es genug Guthaben auf Ihrem Parkingpay-Konto
                    gibt.
                </span>
            ),
            fr: (
                <span>
                    Si vous stationner régulièrement avec Parkingpay, ça vaut la
                    peine de passer{' '}
                    <a href="" onClick={onClick}>
                        aux mode de paiement «Prélèv. autom. (LSV)».
                    </a>{' '}
                    Lorsque vous l’avez défini, vous n’avez plus à vous soucier
                    de savoir si votre solde en compte Parkingpay est suffisant.
                </span>
            ),
            it: (
                <span>
                    Se parcheggiate regolarmente con Parkingpay, conviene
                    passare{' '}
                    <a href="" onClick={onClick}>
                        alla modalità di pagamento «Addebito diretto (LSV)».
                    </a>{' '}
                    Una volta impostata non dovrete più preoccuparvi che il
                    saldo del vostro conto Parkingpay sia sufficiente.
                </span>
            ),
            en: (
                <span>
                    If you regularly park with Parkingpay, it&apos;s worth
                    changing your{' '}
                    <a href="" onClick={onClick}>
                        payment method to «Direct debit (LSV)».
                    </a>{' '}
                    Once configured, it frees you from worrying about having
                    enough balance on your Parkingpay account.
                </span>
            ),
        };
    },
    directDebitTipTitle: {
        de: 'Tipp',
        fr: 'Conseil',
        it: 'Consiglio',
        en: 'Tip',
    },
};

interface Messages {
    directDebitTipText: JsxMessageTemplate<(ev: React.SyntheticEvent) => void>;
    directDebitTipTitle: Message;
}
