import { Localized } from '../../common/components/Localized';
import { SlideInForm } from '../root/components/SlideInForm';

export function EditForm(props: {
    isSaveable: boolean;
    onClose: () => void;
    onSave: () => void;
    children: React.ReactNode;
    pending: boolean;
    primaryButtonLabel?: React.ReactNode;
}) {
    const primaryButtonLabel = props.primaryButtonLabel ? (
        props.primaryButtonLabel
    ) : (
        <Localized de="Speichern" fr="Enregistrer" it="Salva" en="Save" />
    );

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: props.onClose,
            }}
            primaryButton={{
                label: props.pending ? (
                    <>{primaryButtonLabel}...</>
                ) : (
                    primaryButtonLabel
                ),
                disabled: !props.isSaveable || props.pending,
                onClick: props.onSave,
            }}
        >
            {props.children}
        </SlideInForm>
    );
}
