import { useEffect, useState } from 'react';
import * as superagent from 'superagent';
import { makeTimeoutResponse, SuperagentResponse } from '../HttpResponse';
import { useNavigate } from 'react-router-dom';
import { ValidationData } from '../forms/FormValidationHelpers';

export enum RequestStatus {
    NEVER_EXECUTED = 'neverExecuted',
    PENDING = 'pending',
    ERROR = 'error',
    SUCCESS = 'success',
}

/* in body when throwing ApiException in backend */
export interface GenericRequestError {
    code: number;
    message: string;
}

export interface ConstraintViolationResponse {
    code: number;
    message: string;
    validationErrors: ValidationError[];
}

export interface ValidationError {
    message: string;
    field: string;
    violation: string;
    invalidValue: any;
    annotation: string;
    constraint: { [key: string]: any };
    messageTemplate: string;
}

export type ErrorResponseTypes =
    | ConstraintViolationResponse
    | GenericRequestError
    | ValidationData
    | null;

export type ServerRequestState<D, ED = ErrorResponseTypes> =
    | NeverExecutedState
    | PendingState<D>
    | SuccessState<D>
    | ErrorState<ED>;

export interface NeverExecutedState {
    readonly status: RequestStatus.NEVER_EXECUTED;
    readonly data: null;
}

export interface PendingState<D> {
    readonly status: RequestStatus.PENDING;
    readonly data: D | null;
}

export interface SuccessState<D> {
    readonly status: RequestStatus.SUCCESS;
    readonly data: D;
}

export interface ErrorState<ED> {
    readonly status: RequestStatus.ERROR;
    readonly data: ED | null;
    readonly httpStatusCode: number;
}

export type ServerWriteState<D, ED = {}, P = undefined> = [
    ServerRequestState<D, ED>,
    (args?: P) => void,
    () => void,
];

type OldServerWriteConfig<P, D, ED = {}> = {
    req: (args: P) => superagent.Request<any, any>;
    onResponse?: (state: ServerRequestState<D, ED>, payload: P) => void;
};

export type WriteInfo<P> = (payload: P) => WriteRequestConfig;

/**
 * @deprecated replacedWith useServerWrite with requestInfo
 */
export function useServerWrite<P, D, ED = {}>(
    config: OldServerWriteConfig<P, D, ED>,
): ServerWriteState<D, ED, P>;
/**
 * Gives you a function to send data to the backend. The send has to be triggered manually.
 * All requests are JSON, the default method is POST.
 *
 * @param writeInfoFactory This function gets the payload so you can build your RequestConfig accordingly.
 * @returns [state, send(payload), reset()]
 * @example A simple component that writes a new user
 *
 * function SomeComponent() {
 *     const [state, write] = useServerWrite<{ name: string }, { id: number }>(
 *         () => ({ url: `/record` })
 *     );
 *
 *     if (state.status === RequestStatus.NEVER_EXECUTED) {
 *         return <button onClick={() => write({ name: 'John Doe' })} />;
 *     } else if (state.status === RequestStatus.PENDING) {
 *         return <div>Spinner</div>;
 *     } else if (state.status === RequestStatus.ERROR) {
 *         return <div>Something went wrong</div>;
 *     } else {
 *         return <div>Created with ID: {state.data.id}</div>;
 *     }
 * }
 */
export function useServerWrite<Payload, Data, ErrorData = {}>(
    writeInfoFactory: WriteInfo<Payload>,
): ServerWriteState<Data, ErrorData, Payload>;
export function useServerWrite<P, D, ED = {}>(
    config: OldServerWriteConfig<P, D, ED> | WriteInfo<P>,
): ServerWriteState<D, ED, P> {
    if (typeof config === 'function') {
        return oldServerWrite({
            req(args) {
                const conf = config(args);
                const method = getSuperagentMethod(
                    conf.method ?? RequestMethod.POST,
                );
                const agent = method(conf.url);
                const headers = conf.headers;
                if (headers) {
                    Object.keys(headers).forEach(name =>
                        agent.set(name, headers[name]),
                    );
                }

                return agent.send(args);
            },
        });
    } else {
        return oldServerWrite(config);
    }
}

/**
 * Returns true if the status is SUCCESS or ERROR
 * @param status RequestStatus
 */
export function requestDone(status: RequestStatus): boolean {
    switch (status) {
        case RequestStatus.ERROR:
        case RequestStatus.SUCCESS:
            return true;
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.PENDING:
            return false;
    }
}

export function getSuperagentMethod(method: RequestMethod) {
    switch (method) {
        case RequestMethod.GET:
            return superagent.get;
        case RequestMethod.POST:
            return superagent.post;
        case RequestMethod.PUT:
            return superagent.put;
        case RequestMethod.DELETE:
            return superagent.del;
    }
}

function oldServerWrite<P, D, ED = {}>(
    config: OldServerWriteConfig<P, D, ED>,
): ServerWriteState<D, ED, P> {
    type State = ServerRequestState<D, ED>;
    const [state, setState] = useState<State>({
        data: null,
        status: RequestStatus.NEVER_EXECUTED,
    });

    return [
        state,
        makeWriteFunction(config.req, setState, true, config.onResponse),
        () =>
            setState({
                data: null,
                status: RequestStatus.NEVER_EXECUTED,
            }),
    ];
}

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export interface RequestConfig extends WriteRequestConfig {
    body?: any;
}

export interface WriteRequestConfig {
    url: string;
    method?: RequestMethod;
    headers?: { [key: string]: string };
}

export type ServerFetchState<D, ED = D> = [
    ServerRequestState<D, ED>,
    () => void,
];
type OldFetchConfig<C extends { [key: string]: any }, D, ED = D> = {
    request: (context: C) => superagent.Request<any, any>;
    onResponse?: (state: ServerRequestState<D, ED>) => void;
};
export type FetchInfo<C> = (context: C) => RequestConfig;

/**
 * @deprecated replacedWith useServerFetch with requestInfo
 */
export function useServerFetch<D, C extends { [key: string]: any }, ED = D>(
    config: OldFetchConfig<C, D, ED>,
    context: C | null,
): ServerFetchState<D, ED>;

/**
 * Fetches and updates the server data on every context change.
 * If context is `null` no call is made.
 * If a request is running it will be canceled.
 * All requests are JSON, the default method is GET.
 *
 * @param requestInfoFactory This function gets the context so you can build your RequestConfig accordingly.
 * @param context If `null` no call is made. Otherwise a new request is made if any context data changes.
 * @returns [state, refetchFunction]
 * @example A simple component that fetches a record
 *
 * function SomeComponent({id}: {id: number}) {
 *     const [state] = useServerFetch<{name: string}, {id: number}>(
 *         context => ({url: `/record/${context.id}`}),
 *         {id}
 *     );
 *
 *     if (state.status === RequestStatus.PENDING || state.status === RequestStatus.NEVER_EXECUTED) {
 *         return <div>Spinner</div>
 *     } else if (state.status === RequestStatus.ERROR) {
 *         return <div>Something went wrong</div>
 *     } else {
 *         return <div>Hello {state.data.name}</div>
 *     }
 * }
 */
export function useServerFetch<Data, Context extends object, ErrorData = Data>(
    requestInfoFactory: FetchInfo<Context>,
    context: Context | null,
): ServerFetchState<Data, ErrorData>;

export function useServerFetch<D, C extends { [key: string]: any }, ED = D>(
    config: OldFetchConfig<C, D, ED> | FetchInfo<C>,
    context: C | null,
): ServerFetchState<D, ED> {
    if (typeof config === 'function') {
        return oldServerFetch(
            {
                request(c) {
                    const conf = config(c);
                    const agent = getSuperagentMethod(
                        conf.method ?? RequestMethod.GET,
                    )(conf.url);
                    const headers = conf.headers;
                    if (headers) {
                        Object.keys(headers).forEach(name =>
                            agent.set(name, headers[name]),
                        );
                    }
                    if (
                        conf.body !== undefined &&
                        conf.method !== RequestMethod.GET
                    ) {
                        agent.send(conf.body);
                    }
                    return agent;
                },
            },
            context,
        );
    } else {
        return oldServerFetch(config, context);
    }
}

function oldServerFetch<D, C extends { [key: string]: any }, ED = D>(
    config: OldFetchConfig<C, D, ED>,
    context: C | null,
): ServerFetchState<D, ED> {
    type State = ServerRequestState<D, ED>;

    const [request, setRequest] = useState<superagent.Request<any, any> | null>(
        null,
    );

    const [forceFetchCount, setForceFetchCount] = useState(0);

    const [state, setState] = useState<State>({
        data: null,
        status: RequestStatus.NEVER_EXECUTED,
    });

    function refetchSameContext() {
        setForceFetchCount(prev => prev + 1);
    }
    useEffect(
        makeContextReadFunction(
            config.request,
            request,
            context,
            setState,
            setRequest,
            config.onResponse,
        ),
        [
            JSON.stringify(context), // yes we actually do this https://github.com/facebook/react/issues/14476#issuecomment-471199055
            forceFetchCount,
        ],
    );

    return [state, refetchSameContext];
}

function makeContextReadFunction<D, C extends { [key: string]: any }, ED = D>(
    configRequest: (context: C) => superagent.Request<any, any>,
    oldRequest: superagent.Request<any, any> | null,
    context: C | null,
    setState: React.Dispatch<React.SetStateAction<ServerRequestState<D, ED>>>,
    setRequest: React.Dispatch<
        React.SetStateAction<superagent.Request<any, any> | null>
    >,
    onResponse?: (state: ServerRequestState<D, ED>) => void,
) {
    return () => {
        if (!context) {
            setState({ data: null, status: RequestStatus.NEVER_EXECUTED });
            if (oldRequest) {
                oldRequest.abort();
            }
        }
        if (context) {
            const newRequest = configRequest(context);
            if (oldRequest) {
                oldRequest.abort();
            }
            setRequest(newRequest);
            setState(prev => ({
                data: prev.status !== RequestStatus.ERROR ? prev.data : null,
                status: RequestStatus.PENDING,
            }));
            newRequest.end(
                (err: any, superagentRes: superagent.Response<any>) => {
                    setRequest(null);
                    const res =
                        err && !superagentRes
                            ? makeTimeoutResponse(newRequest)
                            : new SuperagentResponse(newRequest, superagentRes);
                    if (res.statusCode.cls.success) {
                        const newState: ServerRequestState<D, ED> = {
                            data: res.body || null,
                            status: RequestStatus.SUCCESS,
                        };
                        setState(newState);
                        onResponse && onResponse(newState);
                    } else if (res.statusCode.cls.error) {
                        const newState = {
                            data: res.body,
                            status: RequestStatus.ERROR,
                            httpStatusCode: res.statusCode.statusCode || 0,
                        };
                        setState(newState);
                        onResponse && onResponse(newState);
                    }
                },
            );
        }
    };
}

function makeWriteFunction<P, D, ED = {}>(
    request: (args: P) => superagent.Request<any, any>,
    setState: React.Dispatch<React.SetStateAction<ServerRequestState<D, ED>>>,
    resetStateDuringUpdate: boolean,
    onResponse?: (state: ServerRequestState<D, ED>, payload: P) => void,
) {
    return (
        args?: P,
        onResponseOfThisWrite?: (state: ServerRequestState<D, ED>) => void,
    ) => {
        if (resetStateDuringUpdate) {
            setState(() => ({
                data: null,
                status: RequestStatus.PENDING,
            }));
        } else {
            setState(oldState => {
                const data =
                    oldState.status === RequestStatus.SUCCESS
                        ? oldState.data
                        : null;
                return {
                    data,
                    status: RequestStatus.PENDING,
                };
            });
        }

        const newRequest = request(args!);
        newRequest.end((err: any, superagentRes: superagent.Response<any>) => {
            const res =
                err && !superagentRes
                    ? makeTimeoutResponse(newRequest)
                    : new SuperagentResponse(newRequest, superagentRes);

            if (res.statusCode.cls.success) {
                const newState: { data: D; status: RequestStatus.SUCCESS } = {
                    data: res.body || null,
                    status: RequestStatus.SUCCESS,
                };
                setState(newState);
                if (onResponse) {
                    onResponse(newState, args!);
                }
                if (
                    onResponseOfThisWrite &&
                    typeof onResponseOfThisWrite === 'function'
                ) {
                    console.error('Deprecated onResponseOfThisWrite called');
                    onResponseOfThisWrite(newState);
                }
            } else if (res.statusCode.cls.error) {
                const newState: {
                    data: ED;
                    status: RequestStatus.ERROR;
                    httpStatusCode: number;
                } = {
                    data: res.body,
                    status: RequestStatus.ERROR,
                    httpStatusCode: res.statusCode.statusCode || 0,
                };
                setState(newState);
                if (onResponse) {
                    onResponse(newState, args!);
                }
                if (
                    onResponseOfThisWrite &&
                    typeof onResponseOfThisWrite === 'function'
                ) {
                    console.error('Deprecated onResponseOfThisWrite called');
                    onResponseOfThisWrite(newState);
                }
            }
        });
    };
}

/**
 * @deprecated use useServerSuccessEffect instead (or rewrite this function not exposing superagent)
 */
export function useServerReadAndUpdate<
    P,
    C extends { [key: string]: any } = {},
    D = P,
    ED = {},
>(
    config: {
        read: (args: C) => superagent.Request<any, any>;
        update: (args: P) => superagent.Request<any, any>;
        onResponse?: (state: ServerRequestState<D, ED>, payload?: P) => void;
    },
    context: C | null,
): [ServerRequestState<D, ED>, (args?: P) => void, () => void] {
    const [request, setRequest] = useState<superagent.Request<any, any> | null>(
        null,
    );

    const [forceFetchCount, setForceFetchCount] = useState(0);

    const [state, setState] = useState<ServerRequestState<D, ED>>({
        data: null,
        status: RequestStatus.NEVER_EXECUTED,
    });

    function refetchSameContext() {
        setForceFetchCount(prev => prev + 1);
    }

    useEffect(
        makeContextReadFunction(
            config.read,
            request,
            context,
            setState,
            setRequest,
            config.onResponse,
        ),
        [
            JSON.stringify(context), // yes we actually do this https://github.com/facebook/react/issues/14476#issuecomment-471199055
            forceFetchCount,
        ],
    );

    return [
        state,
        makeWriteFunction(config.update, setState, false, config.onResponse),
        refetchSameContext,
    ];
}

/**
 * Will call the action function with the request data once every time it's successful.
 * WARNING: It doesn't take any dependencies so don't use outside data that will not get updated.
 * @param state RequestState
 * @param action The action to be executed onSuccess - will receive the success data
 */
export function useServerSuccessEffect<Data, ErrorData = Data>(
    state: ServerRequestState<Data, ErrorData>,
    action: (data: Data) => void,
) {
    useEffect(() => {
        if (state.status === RequestStatus.SUCCESS) {
            action(state.data);
        }
    }, [state]);
}

/**
 * Will use the react-router history.push method to navigate every time it's successful.
 * @param state RequestState
 * @param to the path to navigate to, it can be a function getting as input the returned data
 */
export function useNavigateOnSuccess<Data, ErrorData = Data>(
    state: ServerRequestState<Data, ErrorData>,
    to: string | ((data: Data) => string),
    replace?: boolean,
) {
    const navigate = useNavigate();
    useServerSuccessEffect(state, data => {
        const target = typeof to === 'string' ? to : to(data);

        if (replace) {
            navigate(target, { replace: true });
        } else {
            navigate(target);
        }
    });
}

/**
 * Will call the action function with the request data once every time it's failed.
 * WARNING: It doesn't take any dependencies so don't use outside data that will not get updated.
 * @param state RequestState
 * @param action The action to be executed onError - will receive the error data
 */
export function useServerErrorEffect<D, ED = D>(
    state: ServerRequestState<D, ED>,
    action: (statusCode: number, data: ED | null) => void,
) {
    useEffect(() => {
        if (state.status === RequestStatus.ERROR) {
            action(state.httpStatusCode, state.data);
        }
    }, [state]);
}

/**
 * Will use the react-router history.push method to navigate every time it's failed.
 * @param state RequestState
 * @param to the path to navigate to
 */
export function useNavigateOnError<Data, ErrorData = Data>(
    state: ServerRequestState<Data, ErrorData>,
    to: string,
) {
    const navigate = useNavigate();
    useServerErrorEffect(state, () => {
        navigate(to);
    });
}
