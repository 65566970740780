import * as LayoutState from '../state/LayoutState';
import { TwoColumnsProperties } from './TwoColumnsProperties';
import { ResponsiveProperties } from './ResponsiveProperties';

interface StaticPageLayoutInputs {
    layout: LayoutState.State;
}

export class StaticPageLayout {
    private _s: StaticPageLayoutInputs;
    private _columnProps: TwoColumnsProperties;

    constructor(s: StaticPageLayoutInputs) {
        this._s = s;
        this._columnProps = new TwoColumnsProperties(s);
    }

    get minContainerSpacing(): number {
        const responsiveProps = new ResponsiveProperties(this._s);
        if (responsiveProps.mobile) {
            return 0;
        } else {
            return this._s.layout.viewportWidth < 848 ? 48 : 64;
        }
    }

    private get columnsSpace(): number {
        return (
            this._s.layout.viewportWidth -
            2 * this.minContainerSpacing -
            (this._columnProps.numColumns - 1) * this._columnProps.columnSpacing
        );
    }

    private get fullColumnWidth(): number {
        return this.columnsSpace;
    }

    private get large(): boolean {
        return this._s.layout.viewportWidth >= 1224;
    }

    private get column1LargeWidth(): number {
        return Math.min(1040, Math.floor(this.columnsSpace * 0.7));
    }
    private get column1SmallWidth(): number {
        return Math.min(528, Math.floor(this.columnsSpace * 0.55));
    }
    get column1Width(): number {
        if (this._columnProps.variant.oneColumn) {
            return this.fullColumnWidth;
        } else {
            return this.large ? this.column1LargeWidth : this.column1SmallWidth;
        }
    }

    private get column2LargeWidth(): number {
        return Math.min(432, Math.floor(this.columnsSpace * 0.3));
    }
    private get column2SmallWidth(): number {
        return Math.min(432, Math.floor(this.columnsSpace * 0.45));
    }
    get column2Width(): number {
        if (this._columnProps.variant.oneColumn) {
            return this.fullColumnWidth;
        } else {
            return this.large ? this.column2LargeWidth : this.column2SmallWidth;
        }
    }

    get columnsContainerWidth(): number {
        if (this._columnProps.variant.oneColumn) {
            return this.column1Width;
        } else {
            return (
                this.column1Width +
                this._columnProps.columnSpacing +
                this.column2Width
            );
        }
    }
}
