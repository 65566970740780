import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import * as Http from '../../../api/Http';
import * as AsyncRequest from '../../../AsyncRequest';
import * as WriteStateSlice from '../../../common/state/WriteStateSlice';
import { logAction } from '../../../utils/ActionLog';
import * as SlideInState from '../../root/state/SlideInState';
import * as CurrentLoginEditResponseState from '../state/CurrentLoginEditResponseState';
import * as CurrentLoginEditState from '../state/CurrentLoginEditState';
import * as CurrentLoginExpandedState from '../state/CurrentLoginExpandedState';

export const DEFAULT_SUMMARY_HOUR = 18;

export function showReminderSlideIn(): Flux.Write {
    return (store: Flux.Store): void => {
        new SlideInState.StateSlice(store).setType(
            SlideInState.SlideInType.REMINDER_EDIT,
        );
    };
}

export function toggleReminderTypes(): Flux.Write {
    return (store: Flux.Store): void => {
        if (
            new CurrentLoginExpandedState.StateSlice(store).state
                .reminderTypesExpanded
        ) {
            new CurrentLoginEditResponseState.StateSlice(store).reset();
        } else {
            new CurrentLoginEditState.StateSlice(store).reset();
        }
        new CurrentLoginExpandedState.StateSlice(store).toggleReminderTypes();
    };
}

export function setEmailReminder(value: boolean): Flux.Write {
    return (store: Flux.Store): void => {
        new CurrentLoginEditState.StateSlice(store).toggleEmailNotification(
            value,
        );
    };
}

export function putNewPassword(args: {
    oldPassword: string;
    newPassword: string;
    onSuccess: () => void;
}): Flux.Write {
    return AsyncRequest.request(
        Http.putCurrentLoginPassword(args.oldPassword, args.newPassword),

        (store: Flux.Store, res: Response) => {
            new PasswordEdit.ResponseStateSlice(store).writeResponse(res);

            if (res.statusCode.cls.success) {
                logAction(store, 'change-password', null);
                args.onSuccess();
            }
        },
    );
}

export namespace PasswordEdit {
    export class ResponseStateSlice extends WriteStateSlice.StateSlice {
        key(): string {
            return 'account-current-login-PasswordEditResponse';
        }
    }

    export type ResponseState = WriteStateSlice.State;
    export const ResponseCode = WriteStateSlice.ResponseCode;
}
