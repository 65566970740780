import * as Flux from 'dg-web-shared/lib/Flux';

export enum ContentMode {
    SIGNUP,
    TERMS_OF_SERVICE,
    PRIVACY_POLICY,
}

export namespace SignUpSlideinState {
    export interface State {
        loginSlideinOpen: boolean;
        contentMode: ContentMode;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'LoginSlideinState.Slidein',
        {
            loginSlideinOpen: false,
            contentMode: ContentMode.SIGNUP,
        },
    );
}

export function makeSwitchTo(update: Flux.Updater, contentMode: ContentMode) {
    return () => {
        update<Partial<SignUpSlideinState.State>>(
            SignUpSlideinState.stateWrite,
            {
                contentMode,
            },
        );
    };
}
