import { calculateAmountByTimeFromCurve } from './AmountByTimeFromTariffCurve';
import { Slope } from './Tariff';

export function calculatePriceInChfFromCurve(
    selectedMinutes: number,
    tariffCurve: Slope[],
    minChfStep: number,
) {
    const curveData = calculateAmountByTimeFromCurve(
        Math.max(Math.ceil(selectedMinutes) * 60 * 1000 - 1, 0),
        tariffCurve,
    );
    return (
        Math.ceil(Math.round(curveData.rappen) / (minChfStep * 100)) *
        minChfStep
    );
}
