import { selectState } from 'dg-web-shared/lib/Flux';
import { Localized } from '../../common/components/Localized';
import { SlideIn } from '../../ui/slidein/SlideIn';
import * as TransactionsListState from '../state/TransactionsListState';
import { PermitDetailFetching } from './Permit';

export const PermitDetailSlideIn = selectState(
    store => {
        const transactionsListDetail = TransactionsListState.Detail.get(store);

        return {
            transactionsListDetail,
            permitDetail: transactionsListDetail.permitId
                ? TransactionsListState.PermitDetail.get(store, {
                      id: transactionsListDetail.permitId,
                  })
                : null,
        };
    },
    props => (
        <SlideIn
            title={
                <Localized
                    de="Bewilligung"
                    fr="Autorisation"
                    it="Autorizzazione"
                    en="Permit"
                />
            }
            open={!!props.transactionsListDetail.permitId}
            onClose={() => {
                props.update(TransactionsListState.Detail.stateWrite, {
                    permitId: null,
                });
            }}
        >
            {!!props.permitDetail && (
                <PermitDetailFetching
                    permitDetail={props.permitDetail}
                    renewalIsOkInCurrentContext={true}
                />
            )}
        </SlideIn>
    ),
);
