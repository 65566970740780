import { css, keyframes } from '@emotion/css';
import React from 'react';

const SIZE = 100;
const rotate = keyframes`
100% {
    transform: rotate(360deg)
}
`;
const dash = keyframes`
0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
}
50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
}
100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
}
`;
const color = keyframes`
    100%, 0% {
        stroke: #132F69;
    }
    40% {
        stroke: #B40000;
    }
    66% {
        stroke: #7CB342;
    }
    80%, 90% {
        stroke: #2E91DF;
    }
`;

export const enum SpinnerColor {
    RAINBOW,
    WHITE,
}

interface Props {
    centerVertically?: boolean;
    color?: SpinnerColor;
}

export class Spinner extends React.Component<Props> {
    static displayName = 'Spinner';
    static defaultProps: Props = {
        centerVertically: false,
    };

    render() {
        return (
            <div
                className={css({
                    position: 'relative',
                    width: SIZE,
                    height: SIZE,
                    margin: 'auto',
                    ...(this.props.centerVertically && {
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }),
                })}
            >
                <svg
                    className={css({
                        animation: `${rotate} 2s linear infinite`,
                        height: SIZE,
                        position: 'relative',
                        width: SIZE,
                    })}
                >
                    <circle
                        className={css({
                            strokeDasharray: '1,200',
                            strokeDashoffset: 0,
                            stroke:
                                this.props.color === SpinnerColor.WHITE
                                    ? '#FFFFFF'
                                    : undefined,
                            animation:
                                (this.props.color ?? SpinnerColor.RAINBOW) ===
                                SpinnerColor.RAINBOW
                                    ? `
                                ${dash} 1.5s ease-in-out infinite,
                                ${color} 6s ease-in-out infinite;
                            `
                                    : `
                                ${dash} 1.5s ease-in-out infinite;
                            `,
                            strokeLinecap: 'round',
                        })}
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        strokeWidth="2"
                        strokeMiterlimit="1"
                    />
                </svg>
            </div>
        );
    }
}
