import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import * as Sort from 'dg-web-shared/lib/Sort';
import * as CommonQueryActions from '../actions/CommonQueryActions';
import * as ServerState from './ServerState';
import * as ServerStateSlice from './ServerStateSlice';

export { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
export type VignetteStatus = 'ACTIVE' | 'INPROGRESS';

export interface SentAddress {
    company1?: Maybe<string>;
    company2?: Maybe<string>;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    street1?: Maybe<string>;
    city?: Maybe<string>;
    zipCode?: Maybe<string>;
}

export interface Vignette {
    nr: string;
    status: VignetteStatus;
    issuingOperatorName: Maybe<string>;
    channel: 'OPERATOR' | 'ADMIN';
    sentAddress: Maybe<SentAddress>;
    mailingDate: Maybe<string>;
}

export interface SavedLicensePlate {
    entityType: 'LICENSE_PLATE';
    id: number;
    licensePlateNr: string;
    vignetteNrs: Vignette[];
    askForVignetteOnCar: boolean;
    typeId: 0 | 1 | 2;
    type: LicensePlateType;
    countryId: string;
    remarks: string;
    vehicleId: number | null;
}

export type State = ServerState.State<SavedLicensePlate[], void>;
type EmptyData = ServerStateSlice.EmptyData<SavedLicensePlate[], void>;

function parseLicensePlates(body: SavedLicensePlate[]): SavedLicensePlate[] {
    return body;
}

function sortLPs(data: SavedLicensePlate[]): SavedLicensePlate[] {
    return data.sort(compareLPs);
}

function compareLPs(a: SavedLicensePlate, b: SavedLicensePlate): number {
    return Sort.arithmeticCompare(a.licensePlateNr, b.licensePlateNr, true);
}

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<
    SavedLicensePlate[],
    void
> {
    key(): string {
        return 'common-LicensePlateState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            // TODO: Do we really need to check for auth here?!
            store.legacyUpdater(CommonQueryActions.receiveLicensePlates);
        }
    }

    getEmptyData(): EmptyData {
        return { data: [], errorData: undefined };
    }

    writeRequest(res: Response): void {
        if (res.pending) {
            this.writePending();
        } else if (res.statusCode.cls.success) {
            this.writeSuccess(res);
            this.set((s: State): State => {
                // parse
                if (res.body) {
                    s.data = sortLPs(parseLicensePlates(res.body));
                }
                return s;
            });
        } else {
            this.writeError(res);
        }
    }
}
