export enum Language {
    DE = 'de',
    FR = 'fr',
    IT = 'it',
    EN = 'en',
}

export function languageFromString(s: string): Language {
    switch (s) {
        case 'fr':
            return Language.FR;
        case 'it':
            return Language.IT;
        case 'en':
            return Language.EN;
        default:
            return Language.DE;
    }
}

export interface Translations<T> {
    [key: string]: T;
    de: T;
    fr: T;
    it: T;
}

export type Translation = () => string;
export type Translation1<T> = (arg1: T) => string;
export type Translation2<T, S> = (arg1: T, arg2: S) => string;
export type Translation3<T, S, U> = (arg1: T, arg2: S, arg3: U) => string;

export interface BackendTranslation {
    id: string;
    name: {
        [key: string]: string;
        de: string;
        fr: string;
        it: string;
    };
}
