import { css } from '@emotion/css';

import { Language } from 'dg-web-shared/lib/Text';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { PaymentMethodEnum } from '../../api/Http';
import { Typo } from '../../style/typo';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { RequestedPaymentMethod, status } from './PaymentMethodStatus';
import { Prepaid } from './PrepaidMethod';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import { Lsv } from './direct-debit/DirectDebitMethod';
import { Invoice } from './invoice/InvoiceMethod';

import { DirectMethod } from './DirectMethod';

export const PaymentMethodsListSlideIn = portalSlideIn(PaymentMethodsList);

export function PaymentMethodsList({
    language,
    customerAccountType,
    customerWasInDebtCollection,
    activePaymentMethod,
    requestedPaymentMethod,
    onClose,
}: {
    language: Language;
    customerAccountType: CustomerAccountType;
    customerWasInDebtCollection: boolean;
    activePaymentMethod: PaymentMethodEnum;
    requestedPaymentMethod: RequestedPaymentMethod;
    onClose: () => void;
}) {
    const statusOf = (method: PaymentMethodEnum) =>
        status(activePaymentMethod, requestedPaymentMethod, method);
    const update = useUpdate();

    return (
        <div
            className={css`
                ${Typo.robotoRegular};
                font-size: 16px;
                line-height: 22px;
            `}
        >
            <DirectMethod
                status={statusOf(PaymentMethodEnum.DIRECT)}
                paymentMethod={activePaymentMethod}
            />
            <Prepaid
                status={statusOf(PaymentMethodEnum.PREPAID)}
                update={update}
                language={language}
                closePaymentMethodsSlideIn={onClose}
            />
            {!customerWasInDebtCollection && (
                <>
                    <Lsv
                        status={statusOf(PaymentMethodEnum.LSV)}
                        update={update}
                    />
                    {customerAccountType === CustomerAccountType.COMPANY && (
                        <Invoice status={statusOf(PaymentMethodEnum.INVOICE)} />
                    )}
                </>
            )}
        </div>
    );
}
