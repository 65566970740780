import { NavigationTexts } from '../components/AccountSetup';
import * as SettingsState from '../../common/state/SettingsState';
import Text = require('../../common/i18n/Text');

/* tslint:disable:max-line-length */
export const navigationTexts: Text.Translations<NavigationTexts> = {
    de: {
        next: () => 'Weiter',
        previous: () => 'Zurück',
        finish: () => 'Abschliessen',
        headerLabel: () => 'Konto einrichten',
        pending: () => 'Wird geladen...',
    },
    fr: {
        next: () => 'Continuer',
        previous: () => 'Retour',
        finish: () => 'Conclure',
        headerLabel: () => 'Configurer le compte',
        pending: () => 'Chargement en cours...',
    },
    it: {
        next: () => 'Avanti',
        previous: () => 'Indietro',
        finish: () => 'Concludi',
        headerLabel: () => 'Configura conto',
        pending: () => 'Caricamento in corso...',
    },
    en: {
        next: () => 'Next',
        previous: () => 'Back',
        finish: () => 'Finish',
        headerLabel: () => 'Set up account',
        pending: () => 'Loading...',
    },
};

export const navigationTextsFor = (p: { settings: SettingsState.State }) =>
    navigationTexts[p.settings.language];
