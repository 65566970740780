import { portalSlideIn, SlideInPortalId } from './PortalSlidein';
import { Environment, getBridge } from '../../../Native';
import { MainMenuItemBlock } from './ItemBlock';
import { ActionBlock } from './ActionBlock';
import { envHost } from 'dg-web-shared/lib/Environment';
import { useState } from 'react';

export function ChooseEnv() {
    const [open, setOpen] = useState(false);
    return (
        <>
            <MainMenuItemBlock
                onClick={() => setOpen(true)}
                title="Environment"
                rightTitle={envHost()
                    .match(/dev[1-4]|test|parkingpay/)
                    ?.toString()
                    .replace('parkingpay', 'prod')
                    .toUpperCase()}
            />
            <TheEnvs
                open={open}
                onClose={() => setOpen(false)}
                title={'Environments'}
                portal={SlideInPortalId.USER_ACCOUNT}
            />
        </>
    );
}

const envs = ['prod', 'test', 'dev1', 'dev2', 'dev3', 'dev4', 'ebi'];
const TheEnvs = portalSlideIn(() => {
    return (
        <div>
            {envs.map(env => (
                <ActionBlock
                    key={`env-${env}`}
                    icon="chevronRight"
                    onClick={() => {
                        const bridge = getBridge();
                        if (bridge && bridge.appEnv) {
                            bridge.appEnv(env as Environment);
                        }
                    }}
                    title={env}
                />
            ))}
        </div>
    );
});
