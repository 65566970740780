import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import {
    LabeledToggle,
    ToggleType,
} from '../../../../tb-ui/toggle/LabeledToggle';
import { ErrorBlock } from '../root/components/ErrorBlock';
import { GenericErrorBody } from '../../ui/modals/HandleHttpStati';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';
import { logAction } from '../../utils/ActionLog';
import { useState } from 'react';

export const RecentsEditFormSlideIn = portalSlideIn(RecentsToggleForm);

export function useRecentTransactionsRequest(onSuccess?: () => void) {
    const update = useUpdate();
    const refetchTransactionList = useTransactionListRefetch();

    const serverWriteArray = useServerWrite<
        {
            showRecents: boolean;
        },
        unknown,
        unknown
    >(() => ({
        url: 'ui-api/customer-account/recents',
        method: RequestMethod.PUT,
    }));

    useServerSuccessEffect(serverWriteArray[0], () => {
        update(s => CurrentLoginState.reset(s));
        refetchTransactionList();
        onSuccess && onSuccess();
    });

    return serverWriteArray;
}

function RecentsToggleForm({
    currentLogin,
    onClose,
}: {
    currentLogin: CurrentLoginState.CurrentLogin;
    onClose: () => void;
}) {
    const [showRecents, setShowRecents] = useState(currentLogin.showRecents);
    const [requestState, setRecents] = useRecentTransactionsRequest(onClose);
    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled:
                    requestState.status === RequestStatus.PENDING ||
                    showRecents === currentLogin.showRecents,
                onClick: () => {
                    setRecents({ showRecents });
                    logAction(null, 'recents-settings-toggle', { showRecents });
                },
            }}
        >
            <LabeledToggle
                context={InputContext.form}
                type={ToggleType.checkbox}
                selected={showRecents}
                label={
                    <Localized
                        de="Kürzlich verwendete Zonen und Bewilligungen auf der Startseite anzeigen"
                        fr="Afficher les zones et les autorisations récemment utilisées sur la page d'accueil"
                        it="Mostra le zone e le autorizzazioni utilizzate di recente sulla pagina iniziale"
                        en="Show recently used zones and permits on the home screen"
                    />
                }
                onClick={() => {
                    setShowRecents(current => !current);
                }}
            />
            {requestState.status === RequestStatus.ERROR && (
                <ErrorBlock text={<GenericErrorBody />} />
            )}
        </SlideInForm>
    );
}
