import {
    TwintPaymentStateKnown,
    TwintPaymentStateUnknown,
    TwintUofStateKnown,
    TwintUofStateUnknown,
} from './TwintPairing';

/**
 * Included in /common/utils/ instead of /parkingcard-app/ to avoid leaks from the usage within TwintPairing.ts
 */

export enum UiPaymentOrigin {
    TOPUP_FROM_CHECKIN = 'TOPUP_FROM_CHECKIN',
    TOPUP_FROM_ACCOUNT = 'TOPUP_FROM_ACCOUNT',
    TOPUP_FROM_PERMIT = 'TOPUP_FROM_PERMIT',
    TOPUP_FROM_TICKET = 'TOPUP_FROM_TICKET',
    STORE_ALIAS_FROM_ACCOUNT = 'STORE_ALIAS_FROM_ACCOUNT',
    STORE_ALIAS_FROM_CHECKIN = 'STORE_ALIAS_FROM_CHECKIN',
    STORE_ALIAS_FROM_TICKET = 'STORE_ALIAS_FROM_TICKET',
    ACTIVATE_ALIAS_FROM_TOPUP = 'ACTIVATE_ALIAS_FROM_TOPUP',
    ACTIVATE_ALIAS_FROM_TICKET = 'ACTIVATE_ALIAS_FROM_TICKET',
}

interface TwintBasePaymentStateKnown extends TwintPaymentStateKnown {
    uiPaymentOrigin:
        | UiPaymentOrigin.TOPUP_FROM_ACCOUNT
        | UiPaymentOrigin.TOPUP_FROM_CHECKIN;
}
interface TwintPermitPaymentStateKnown extends TwintPaymentStateKnown {
    uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_PERMIT;
    permitId: number;
}

interface TwintTicketPaymentStateKnown extends TwintPaymentStateKnown {
    uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_TICKET;
    ticketString: string;
}

interface TwintBaseUofStateKnown extends TwintUofStateKnown {
    uiPaymentOrigin:
        | UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT
        | UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN;
}

interface TwintTicketUofStateKnown extends TwintUofStateKnown {
    uiPaymentOrigin: UiPaymentOrigin.STORE_ALIAS_FROM_TICKET;
    ticketString: string;
}

export type TwintParkingpayPaymentStatesKnown =
    | TwintBasePaymentStateKnown
    | TwintPermitPaymentStateKnown
    | TwintTicketPaymentStateKnown;
export type TwintParkingpayPaymentStates =
    | TwintParkingpayPaymentStatesKnown
    | TwintPaymentStateUnknown;

export type TwintParkingpayUofStatesKnown =
    | TwintBaseUofStateKnown
    | TwintTicketUofStateKnown;

export type TwintParkingpayUofStates =
    | TwintParkingpayUofStatesKnown
    | TwintUofStateUnknown;

export type TwintParkingpayPendingState =
    | TwintParkingpayPaymentStates
    | TwintParkingpayUofStates;
