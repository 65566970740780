import {
    envIsLocalDev,
    envIsProduction,
    envIsTest,
} from 'dg-web-shared/lib/Environment';

/**
 * returns property ID for Google Analytics
 */
export function getGAPropertyId(): string {
    if (envIsProduction()) {
        return 'UA-65830896-1';
    } else if (envIsTest()) {
        return 'UA-65830896-2';
    } else {
        return 'UA-65830896-3';
    }
}

/**
 * returns property ID for Google Analytics
 */
export function getSentryProjectDSN(): string | null {
    if (envIsProduction()) {
        return 'https://057c998e20294992adc94b599fad98f7@o28142.ingest.sentry.io/59593';
    } else if (envIsTest()) {
        return 'https://8e49437142f14d94bd1fa159475b078a@o28142.ingest.sentry.io/59592';
    } else if (envIsLocalDev()) {
        return null;
    } else {
        return 'https://9d6d1bcd0b974c138a12a4ab7335ddbf@app.getsentry.com/59458';
    }
}

const isProduction = () => envIsProduction();

export const geolocationEnabled = () => !isProduction();
export const downloadGatePermitReceiptEnabled = () => isProduction();
export const showActivationLinkInSignup = () =>
    window.location.host !== 'parkingpay.ch';
