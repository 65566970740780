import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import * as WriteStateSlice from '../../common/state/WriteStateSlice';

export namespace Signup {
    export interface State {
        email?: Maybe<string>;
        password?: Maybe<string>;
        passwordRepeated?: Maybe<string>;
        tosAccepted: boolean;
        contestAccepted: boolean;
        showErrors: boolean;
        subscribeToNewsletter: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'signup/SignupState.Signup',
        {
            tosAccepted: false,
            showErrors: false,
            contestAccepted: false,
            subscribeToNewsletter: true,
        },
    );
}

export namespace SignupResponse {
    export interface CustomerSignup {
        customerId: number;
        activationToken: Maybe<string>;
    }
    export type State = WriteStateSlice.State<CustomerSignup>;
    export const { get, setResponse, reset } = WriteStateSlice.generate(
        'signup/SignupState.SignupResponse',
    );
}
