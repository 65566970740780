import { generatePortalSlideInComponent } from 'dg-web-shared/common/components/GenericSlideInFactory';
import { QuickCheckoutConstants } from './QuickCheckoutConstants';
import { defaultTransition } from '../../../../tb-ui/transitions';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../style/typo';
import { css } from '@emotion/css';

const staticStyles = css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...defaultTransition('transform'),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    background: Colors.blackGradient,
    color: Colors.white,
    ...Typo.body,
});

const portalSlideIn = generatePortalSlideInComponent(
    QuickCheckoutConstants.SLIDE_IN_CONTAINER_ID,
    staticStyles,
    200,
);

function QuickCheckoutSlideInComponent({
    children,
}: {
    open: boolean;
    onClosed?: () => void;
    children: React.ReactNode;
}) {
    return <>{children}</>;
}

export const QuickCheckoutSlideIn = portalSlideIn(
    QuickCheckoutSlideInComponent,
);
