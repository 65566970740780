import { css } from '@emotion/css';

import * as Flux from 'dg-web-shared/lib/Flux';
import { languageFromString } from 'dg-web-shared/lib/Text';
import { FAQStatelessWrapper } from '../../../account/root/components/FAQ';
import * as AccountTexts from '../../../account/root/i18n/AccountTexts';
import * as SettingsState from '../../../common/state/SettingsState';
import { HeadingLarge, StaticPage } from './StaticPage';

export interface AboutPageTexts {
    content: () => JSX.Element;
}

export interface AboutPageState {
    settings: SettingsState.State;
}
export class FAQPage extends Flux.Container<AboutPageState> {
    static displayName = 'AboutPage';
    stateSelector(): AboutPageState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }
    render() {
        return (
            <StaticPage allState={this.props.allState}>
                <div
                    className={css({
                        marginLeft: '15%',
                        marginRight: '15%',
                        '@media (max-width: 700px)': {
                            marginLeft: '0',
                            marginRight: '0',
                        },
                    })}
                >
                    <div className={css({ margin: '24px' })}>
                        <HeadingLarge first={true}>
                            {AccountTexts.texts[
                                this.state.settings.language
                            ].Help()}
                        </HeadingLarge>
                    </div>
                    <FAQStatelessWrapper
                        language={languageFromString(
                            this.state.settings.language,
                        )}
                    />
                </div>
            </StaticPage>
        );
    }
}
