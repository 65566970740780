import * as Text from '../../common/i18n/Text';
import * as ParkCreateTexts from '../../park-create/i18n/ParkCreateTexts';
import * as AccountTexts from '../../account/root/i18n/AccountTexts';
import { Translation } from 'dg-web-shared/lib/Text';

interface HeaderMobileTexts {
    navigatePark: Translation;
    navigateParkList: Translation;
    navigateAccount: Translation;
}

export const headerMobileTexts: Text.Translations<HeaderMobileTexts> = {
    de: {
        navigatePark: () => ParkCreateTexts.parkCreateTexts.de.Title(),
        navigateParkList: () => 'Transaktionen',
        navigateAccount: () => AccountTexts.texts.de.Account(),
    },
    fr: {
        navigatePark: () => ParkCreateTexts.parkCreateTexts.fr.Title(),
        navigateParkList: () => 'Transactions',
        navigateAccount: () => AccountTexts.texts.fr.Account(),
    },
    it: {
        navigatePark: () => ParkCreateTexts.parkCreateTexts.it.Title(),
        navigateParkList: () => 'Transazioni',
        navigateAccount: () => AccountTexts.texts.it.Account(),
    },
    en: {
        navigatePark: () => ParkCreateTexts.parkCreateTexts.en.Title(),
        navigateParkList: () => 'Transactions',
        navigateAccount: () => AccountTexts.texts.en.Account(),
    },
};
