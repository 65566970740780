import { Localized } from '../../common/components/Localized';
import { Ticket } from './TicketSlideIn';
import { ModalErrorBox, ModalSuccessBox } from '../../ui/modals/Confirmable';
import {
    ticketNotFoundModalMessage,
    ticketNotFoundModalTitle,
    ticketPaymentDisabledModalMessage,
    ticketPaymentDisabledModalTitle,
} from '../../common/components/ticketplus/TicketPlusModalTexts';
import { TicketPlusTooManyApprovedTicketsModal } from '../../common/components/ticketplus/TicketPlusTooManyApprovedTickets';
import { useCoreAppOutletContext } from '../../app/components/CoreAppOutletContext';
import { useNavigate, useParams } from 'react-router-dom';
import { TicketOutlet } from './TicketOutletContext';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { TicketRoutes } from './TicketRoutes';
import { SharedTicketSlideIn } from './SharedTicketSlideIn';
import { useServerFetch } from '../../hooks/ServerStateHooks';

export const TICKET_SCAN_BAR_HEIGHT_PX = 70;

export function ParkCreateTicketPlus() {
    const { hasRunningCheckinByPlate } = useCoreAppOutletContext();

    if (hasRunningCheckinByPlate) {
        return null;
    }

    return <ParkCreateTicketPlusSlideIn />;
}

function ParkCreateTicketPlusSlideIn() {
    const navigate = useNavigate();
    const { ticketString } = useParams();

    const [ticketState, refetchTicketState] = useServerFetch<
        Ticket,
        { ticketString: string },
        TicketError
    >(
        context => ({
            url: `/ui-api/customer-account/ticket/${context.ticketString}`,
        }),
        ticketString ? { ticketString } : null,
    );

    return (
        <>
            <SharedTicketSlideIn
                portal={SlideInPortalId.PARK_CREATE}
                onClose={() => navigate('/')}
                ticketState={ticketState}
                onTicketReset={() =>
                    navigate(TicketRoutes.automaticPaymentDeactivated)
                }
                onTicketApproveSuccess={() =>
                    navigate(TicketRoutes.directActivationSuccessful)
                }
                refetchTicketState={refetchTicketState}
                renderError={errorData => {
                    if (errorData && errorData.alreadyClaimed) {
                        navigate(TicketRoutes.alreadyClaimed);
                    } else if (errorData && errorData.ticketPaymentDisabled) {
                        navigate(TicketRoutes.paymentDisabled);
                    } else if (errorData && errorData.ticketNotFound) {
                        navigate(TicketRoutes.notFound);
                    } else if (errorData && errorData.tooManyApprovedTickets) {
                        navigate(TicketRoutes.limitReached);
                    } else {
                        navigate(TicketRoutes.unknownError);
                    }
                    return null;
                }}
            />
            <TicketOutlet context={{ refetchTicketState }} />
        </>
    );
}

export function AutomaticPaymentDeactivatedModal() {
    const navigate = useNavigate();
    return <TicketResetInfo onClose={() => navigate('/')} />;
}

export function TicketAlreadyClaimedModal() {
    const navigate = useNavigate();
    return (
        <ModalErrorBox
            confirmCallback={() => navigate('/')}
            titleCaption={
                <Localized
                    de="Ticket bereits freigegeben"
                    fr="Ticket déjà approuvé"
                    it="Ticket già approvato"
                    en="Tickets already released"
                />
            }
        >
            <p>
                <Localized
                    de="Die automatische Zahlung dieses Tickets wurde bereits mit einer anderen App aktiviert."
                    fr="Le paiement automatique de ce ticket a déjà été activé avec une autre app."
                    it="Il pagamento automatico di questo ticket è già stato attivato con un'altra app."
                    en="The automatic payment of this ticket has already been activated with another app."
                />
            </p>
        </ModalErrorBox>
    );
}

export function TicketNotFoundModal() {
    const navigate = useNavigate();
    return (
        <ModalErrorBox
            confirmCallback={() => navigate('/')}
            titleCaption={ticketNotFoundModalTitle}
        >
            {ticketNotFoundModalMessage}
        </ModalErrorBox>
    );
}

export function TicketPaymentDisabledModal() {
    const navigate = useNavigate();
    return (
        <ModalErrorBox
            confirmCallback={() => navigate('/')}
            titleCaption={ticketPaymentDisabledModalTitle}
        >
            {ticketPaymentDisabledModalMessage}
        </ModalErrorBox>
    );
}

export function TicketApprovalLimitReachedModal() {
    const navigate = useNavigate();
    return (
        <TicketPlusTooManyApprovedTicketsModal onClose={() => navigate('/')} />
    );
}

export function TicketUnknownErrorModal() {
    const navigate = useNavigate();
    return (
        <ModalErrorBox
            confirmCallback={() => navigate('/')}
            titleCaption={
                <Localized de="Fehler" fr="Erreur" it="Errore" en="Error" />
            }
        >
            <p>
                <Localized
                    de="Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk."
                    fr="Une erreur est survenue. Merci de réessayer plus tard. Si le problème persiste, merci de l’annoncer à notre Helpdesk."
                    it="Si è verificato un errore. Vogliate riprovare più tardi. Se il problema dovesse persistere, contattate il nostro Helpdesk."
                    en="An error has occurred. Please try again later. If the problem persists, please contact our Helpdesk."
                />
            </p>
        </ModalErrorBox>
    );
}

export function TicketResetInfo({ onClose }: { onClose: () => void }) {
    return (
        <ModalSuccessBox
            confirmCallback={onClose}
            titleCaption={
                <Localized
                    de="Automatische Zahlung deaktiviert"
                    fr="Paiement automatique désactivé"
                    it="Pagamento automatico disattivato"
                    en="Automatic payment deactivated"
                />
            }
        >
            <p>
                <Localized
                    de="Die automatische Zahlung für dieses Ticket wurde deaktiviert. Um sie zu reaktivieren, müssen Sie den QR-Code erneut scannen."
                    fr="Le paiement automatique de ce ticket a été désactivé. Pour le réactiver, vous devez à nouveau scanner le code QR."
                    it="Il pagamento automatico di questo ticket è stato disattivato. Per riattivalro, deve scansionare nuovamente il codice QR."
                    en="The automatic payment of this ticket has been deactivated. To reactivate it, you have to scan the QR code again."
                />
            </p>
        </ModalSuccessBox>
    );
}

export interface TicketError {
    alreadyClaimed: boolean;
    ticketPaymentDisabled: boolean;
    ticketNotFound: boolean;
    tooManyApprovedTickets: boolean;
}
