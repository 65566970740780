import { useStore } from 'dg-web-shared/lib/Flux';
import {
    Account,
    AccountSlideins,
} from '../../../account/root/components/Account';
import {
    ColumnBlock,
    ColumnPaddingTop,
} from '../../../layout/components/ColumnBlock';
import { ColumnContainer } from '../../../layout/components/ColumnContainer';
import {
    ColumnSlider,
    ColumnSliderColumn,
} from '../../../layout/components/ColumnSlider';
import { HeaderLargeBlock } from '../../../layout/components/header/HeaderLargeBlock';
import { LogoSlogan } from '../../../layout/components/header/logo-slogan/LogoSlogan';
import { Layout } from '../../../layout/components/Layout';
import { LayoutBackground } from '../../../layout/components/LayoutBackground';
import {
    LayoutContainer,
    LayoutVariantOption,
} from '../../../layout/components/LayoutContainer';
import * as LayoutState from '../../../layout/state/LayoutState';
import { ApplicationLayout } from '../../../layout/utils/ApplicationLayout';
import { responsiveVariant } from '../../../layout/utils/responsiveVariant';
import { ThreeColumnsProperties } from '../../../layout/utils/ThreeColumnsProperties';
import { ParkCreate } from '../../../park-create/components/ParkCreate';
import {
    TransactionsList,
    TransactionsListSlideIns,
} from '../../../transactions-list/components/TransactionsList';
import * as SettingsState from '../../../common/state/SettingsState';
import { headerMobileTexts } from '../../../layout/i18n/HeaderMobileTexts';
import { ResponsiveProperties } from '../../../layout/utils/ResponsiveProperties';
import { SlideIn } from '../../../ui/slidein/SlideIn';
import { css } from '@emotion/css';
import { FCMTokenState } from '../../../common/state/FCMTokenState';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { PendingPairing } from '../../../account/root/components/PendingPairing';
import { VehicleListOpenProvider } from '../../../hooks/OpenVehicleList';
import { PendingTwintPairing } from 'dg-web-shared/common/utils/TwintPairing';

const moodImage = require('../../home-unauthenticated/assets/parking-emotion.jpg');

export function AuthenticatedHomePage() {
    const { storeState, store } = useStore(store => ({
        fcm: FCMTokenState.get(store),
        isMobile: new ResponsiveProperties({
            layout: new LayoutState.StateSlice(store).state,
        }).mobile,
        pendingTwintPairing: PendingTwintPairing.get(store),
    }));
    return (
        <VehicleListOpenProvider>
            <Layout allState={store}>
                {storeState.isMobile ? <MobileLayout /> : <DesktopLayout />}
                {(storeState.pendingTwintPairing.pairingToken ||
                    storeState.pendingTwintPairing.paymentAttemptId) && (
                    <PendingPairing
                        pendingTwintPairing={storeState.pendingTwintPairing}
                    />
                )}
                <div id={SlideInPortalId.FULL_SCREEN_MODAL} />
            </Layout>
        </VehicleListOpenProvider>
    );
}

function DesktopLayout() {
    const { storeState, store } = useStore(store => ({
        layout: new LayoutState.StateSlice(store).state,
        mobileLayout: LayoutState.Mobile.get(store),
    }));
    const applicationLayout = new ApplicationLayout(storeState);
    const columnProps = new ThreeColumnsProperties(storeState);
    const layoutVariant = LayoutVariantOption.Application;
    return (
        <LayoutContainer allState={store} layoutVariant={layoutVariant}>
            <LayoutBackground allState={store} layoutVariant={layoutVariant} />
            <HeaderLargeBlock allState={store} />
            <LogoSlogan layoutVariant={layoutVariant} />
            <ColumnContainer allState={store} layoutVariant={layoutVariant}>
                <ColumnSlider allState={store}>
                    <ColumnSliderColumn index={0} allState={store}>
                        <ColumnBlock
                            width={applicationLayout.columnWidth}
                            isEnabled={true}
                            hasSpacing={true}
                            paddingTop={
                                columnProps.variant.oneColumn
                                    ? undefined
                                    : ColumnPaddingTop.Authenticated
                            }
                            responsiveVariant={responsiveVariant(storeState)}
                        >
                            <ParkCreate />
                        </ColumnBlock>
                    </ColumnSliderColumn>
                    <ColumnSliderColumn index={1} allState={store}>
                        <ColumnBlock
                            width={applicationLayout.columnWidth}
                            isEnabled={true}
                            hasSpacing={true}
                        >
                            <TransactionsList />
                        </ColumnBlock>
                    </ColumnSliderColumn>
                    <ColumnSliderColumn index={2} allState={store}>
                        <ColumnBlock
                            width={applicationLayout.columnWidth}
                            isEnabled={true}
                            hasSpacing={false}
                        >
                            <Account />
                        </ColumnBlock>
                    </ColumnSliderColumn>
                </ColumnSlider>
            </ColumnContainer>
        </LayoutContainer>
    );
}

function MobileLayout() {
    const { storeState, store, update } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        layout: new LayoutState.StateSlice(store).state,
        mobileLayout: LayoutState.Mobile.get(store),
    }));
    const headerTexts = headerMobileTexts[storeState.settings.language];
    return (
        <div
            className={css({
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'stretch',
                alignItems: 'center',
                ':before': {
                    content: '" "',
                    backgroundImage: `url(${moodImage})`,
                    backgroundPosition: '100% 100%',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    opacity: 0.09,
                    zIndex: -1,
                },
                ':after': {
                    content: '" "',
                    background: '#0e224b',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    zIndex: -2,
                },
            })}
        >
            <div
                className={css({
                    position: 'relative',
                    flexShrink: 0,
                    flexGrow: 1,
                    maxWidth: '500px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
                    height: Math.min(
                        900,
                        storeState.layout.viewportHeight,
                    ) /*cannot use maxHeight, collapses because position absolute in children */,
                })}
            >
                <ParkCreate />
                <SlideIn
                    open={storeState.mobileLayout.transactionSlideinOpen}
                    title={headerTexts.navigateParkList()}
                    onClose={() =>
                        update(LayoutState.Mobile.stateWrite, {
                            transactionSlideinOpen: false,
                        })
                    }
                    key="transactionSlideIn"
                >
                    <TransactionsList />
                </SlideIn>
                <TransactionsListSlideIns />
                <SlideIn
                    open={storeState.mobileLayout.accountSlideinOpen}
                    title={headerTexts.navigateAccount()}
                    onClose={() =>
                        update(LayoutState.Mobile.stateWrite, {
                            accountSlideinOpen: false,
                        })
                    }
                    key="accountSlideIn"
                >
                    {storeState.mobileLayout.accountSlideinOpen && <Account />}
                </SlideIn>
                <AccountSlideins allState={store} />
            </div>
        </div>
    );
}
