import { useStore } from 'dg-web-shared/lib/Flux';
import { logAction } from '../../utils/ActionLog';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isRunningInNativeWrapper } from '../../Native';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { setLanguage } from '../../common/actions/I18nActions';
import { AuthStatusType, useAuthState } from './AuthState';

type Web = {
    platform: 'Web';
};

type Native = {
    platform: 'Native';
};

type iOS = Native & {
    os: 'iOS';
    iosVersion: string;
    iosBuild: string;
};

type Android = Native & {
    os: 'Android';
    androidRelease: string;
    androidAppVersion: string;
};

type Device = Web | iOS | Android;

function useDevice(): Device | null {
    const [searchParams] = useSearchParams();
    const [device, setDevice] = useState<Device | null>(null);

    useEffect(() => {
        // Only parse URL Params at the start and don't update after
        if (!isRunningInNativeWrapper()) {
            setDevice({ platform: 'Web' });
        } else {
            const iosVersion = searchParams.get('ios_version');
            const iosBuild = searchParams.get('ios_build');
            if (iosVersion && iosBuild) {
                setDevice({
                    platform: 'Native',
                    os: 'iOS',
                    iosVersion,
                    iosBuild,
                });
            }

            const androidRelease = searchParams.get('android_release');
            const androidAppVersion = searchParams.get('android_app_version');
            if (androidRelease && androidAppVersion) {
                setDevice({
                    platform: 'Native',
                    os: 'Android',
                    androidRelease,
                    androidAppVersion,
                });
            }
        }
    }, []);

    return device;
}

function useLanguageFromQueryParams() {
    const [searchParams] = useSearchParams();
    const { store } = useStore(() => null);
    useEffect(() => {
        const lang = searchParams.get('lang');
        const savedLanguage = LocalStorage.Settings.getUiLanguageAsStringId();
        if (savedLanguage.isNull && lang != null) {
            setLanguage(lang)(store);
        }
    }, [searchParams]);
}

export function AppStartupHooks({ children }: { children: React.ReactNode }) {
    const { store } = useStore(() => null);

    const authState = useAuthState();

    const device = useDevice();
    useLanguageFromQueryParams();

    useEffect(() => {
        if (authState.authStatus === AuthStatusType.OK) {
            logAction(store, 'enter-app-as-customer', device);
        }
    }, [authState.authStatus]);

    return <>{children}</>;
}
