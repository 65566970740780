import { Localized } from '../Localized';
import { TicketInfoHeader } from './TicketPlusLayout';
import { DateTime } from 'luxon';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats';

export const TicketPlusTicketEntry = ({ createdAt }: { createdAt: string }) => (
    <>
        <TicketInfoHeader>
            <Localized de="Einfahrt" fr="Entrée" it="Entrata" en="Entry" />
        </TicketInfoHeader>
        {DateTime.fromISO(createdAt).toFormat(
            LuxonDateStringFormats.DATE_HOUR_MINUTE,
        )}
    </>
);
