import {
    FieldValues,
    SubmitHandler,
    useForm,
    UseFormHandleSubmit,
    UseFormProps,
    UseFormReturn,
    useFormState,
} from 'react-hook-form';
import {
    RequestStatus,
    ServerRequestState,
} from '../../lib/hooks/ServerStateHooks';
import { Language } from '../../lib/Localized';
import {
    addValidationErrorsToForm,
    FormGlobalViolationEntry,
    ValidationData,
} from '../../lib/forms/FormValidationHelpers';
import { useEffect, useState } from 'react';

interface GenericServerError {
    isGenericServerError: true;
}

interface GenericValidationError {
    isGenericServerError: false;
    issues: FormGlobalViolationEntry[];
}

export type GenericFormError = GenericValidationError | GenericServerError;

export function useEasyForm<
    D,
    ED extends ValidationData,
    TFieldValues extends FieldValues,
    TContext extends object,
>(
    onDirtyStateChange: ((isDirty: boolean) => void) | undefined,
    submitState: ServerRequestState<D, ED> | undefined,
    language: Language,
    useFormProps: UseFormProps<TFieldValues, TContext>,
) {
    const formInfo = useForm(useFormProps);
    const formState = useFormState({ control: formInfo.control });
    const [genericSubmitError, setGenericSubmitError] =
        useState<GenericFormError | null>(null);

    useEffect(() => {
        return () => {
            if (onDirtyStateChange) {
                // cleanup state in case form is exited when dirty
                onDirtyStateChange(false);
            }
        };
    }, []);

    useEffect(() => {
        if (onDirtyStateChange) {
            onDirtyStateChange(formState.isDirty);
        }
    }, [formState.isDirty]);

    useEffect(() => {
        if (submitState?.status === RequestStatus.ERROR) {
            if (
                submitState.httpStatusCode === 400 &&
                submitState.data !== null
            ) {
                const globalError = addValidationErrorsToForm(
                    submitState.data,
                    formInfo.setError,
                    language,
                );
                setGenericSubmitError({
                    isGenericServerError: false,
                    issues: globalError,
                });
            } else {
                setGenericSubmitError({
                    isGenericServerError: true,
                });
            }
        }
    }, [submitState]);

    return {
        formInfo: {
            ...formInfo,
            handleSubmit: cookSubmitHandlerToStripEmptyStrings(
                formInfo.handleSubmit,
            ),
        },
        formState,
        genericSubmitError,
    };
}

export function useRequestErrorFieldMap<TFieldValues extends FieldValues>(
    serverRequestState: ServerRequestState<unknown>,
    form: UseFormReturn<TFieldValues, unknown>,
    language: Language,
) {
    useEffect(() => {
        if (
            serverRequestState?.status === RequestStatus.ERROR &&
            isValidationData(serverRequestState.data)
        ) {
            addValidationErrorsToForm(
                serverRequestState.data,
                form.setError,
                language,
            );
        }
    }, [serverRequestState, language, form.setError]);
}

export function isValidationData(err: unknown): err is ValidationData {
    return (
        typeof err === 'object' &&
        err !== null &&
        (err as ValidationData).violations !== undefined
    );
}

export function setNullInitialValuesToEmptyStrings<Obj extends object>(
    initialValues: Obj,
) {
    const newObj = Object.assign(
        {},
        ...Object.entries(initialValues).map(([key, value]) => ({
            [key]: value ?? '',
        })),
    );
    return newObj as Record<keyof Obj, string>;
}

function cookSubmitHandlerToStripEmptyStrings<TFieldValues extends FieldValues>(
    handleSubmit: UseFormHandleSubmit<TFieldValues>,
): UseFormHandleSubmit<TFieldValues> {
    return (onSubmit, onInvalid) => {
        const submitHandler = stripEmptyValuesFromFormData(onSubmit);

        return handleSubmit(submitHandler, onInvalid);
    };
}

function stripEmptyValuesFromFormData<TFieldValues extends FieldValues>(
    onSubmit: SubmitHandler<TFieldValues>,
) {
    return (formData: TFieldValues) => {
        const reducedFormFields = {
            ...formData,
        };

        Object.keys(reducedFormFields).forEach((key: keyof TFieldValues) => {
            if (reducedFormFields[key] === '') {
                // @ts-expect-error
                reducedFormFields[key] = null;
            }
        });

        onSubmit(reducedFormFields);
    };
}
