import { css } from '@emotion/css';

import { useState } from 'react';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Typo } from '../../style/typo';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { Vehicle } from './VehicleState';
import { ActionBlock } from '../root/components/ActionBlock';
import * as MetaServerState from '../meta/state/MetaServerState';
import { isDefined } from 'dg-web-shared/lib/MaybeV2';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { Colors } from 'dg-web-shared/ui/vars';
import { Localized } from '../../common/components/Localized';
import { stringToFourCharacterBlocks } from 'dg-web-shared/lib/StringConversions';
import {
    DropdownSlideIn,
    SlideInDisplay,
} from '../root/components/DropdownSlideIn';
import * as SettingsState from '../../common/state/SettingsState';
import { SearchField, SearchFieldStyle } from '../../ui/SearchField';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { LprSettingsSlideIn, LprTitle } from './LprSettingsSlideIn';
import { FieldItemBlock } from '../root/components/ItemBlock';
import { ModalSuccessBox } from '../../ui/modals/Confirmable';
import { BarrierGateLicensePlateCheckInOption } from 'dg-web-shared/common/models/Vehicle';
import rgba = Colors.rgba;
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export const VehiclesListSlideIn = portalSlideIn(VehiclesList);

enum VehicleSortFilter {
    DESCPRTION_ASC = 'DescritpionASC',
    DESCPRTION_DESC = 'DescritpionDESC',
    LICENSEPLATENR_ASC = 'LicensePlateNrASC',
    LICENSEPLATENR_DESC = 'LicensePlateNrDESC',
    BADGELABELNR_ASC = 'BadgeLabelNrASC',
    BADGELABELNR_DESC = 'BadgeLabelNrDESC',
    LOGINDESCRIPTION_ASC = 'LoginDescriptionASC',
    LOGINDESCRIPTION_DESC = 'LoginDescriptionDESC',
}

const vehicleSearchCondition = (
    vehiclePropertie: string | null,
    inputSearch: string,
) => {
    return vehiclePropertie
        ?.toLocaleLowerCase()
        .includes(inputSearch.toLocaleLowerCase());
};

const searchingVehicles = (vehicles: Vehicle[], inputSearch: string) =>
    vehicles.filter(function (a) {
        return (
            vehicleSearchCondition(a.description, inputSearch) ||
            vehicleSearchCondition(a.loginDescription, inputSearch) ||
            vehicleSearchCondition(a.badgeLabelNr, inputSearch) ||
            vehicleSearchCondition(a.licensePlateNr, inputSearch) ||
            vehicleSearchCondition(a.country, inputSearch)
        );
    });

const vehicleSortByCategoryCompare = (
    objAProbertie: string | null,
    objBProbertie: string | null,
    language: string,
) => {
    if (!objAProbertie) {
        return +1;
    }

    if (!objBProbertie) {
        return -1;
    }

    return objAProbertie.localeCompare(objBProbertie, language, {
        numeric: true,
    });
};

const sortVehiclesByCategory = (
    searchedVehicles: Vehicle[],
    activeVehicleFilter: VehicleSortFilter,
    language: string,
) =>
    searchedVehicles.sort(function (a, b) {
        switch (activeVehicleFilter) {
            case VehicleSortFilter.DESCPRTION_ASC:
            case VehicleSortFilter.DESCPRTION_DESC:
                return vehicleSortByCategoryCompare(
                    a.description,
                    b.description,
                    language,
                );

            case VehicleSortFilter.BADGELABELNR_ASC:
            case VehicleSortFilter.BADGELABELNR_DESC:
                return vehicleSortByCategoryCompare(
                    a.badgeLabelNr,
                    b.badgeLabelNr,
                    language,
                );

            case VehicleSortFilter.LICENSEPLATENR_ASC:
            case VehicleSortFilter.LICENSEPLATENR_DESC:
                return vehicleSortByCategoryCompare(
                    a.licensePlateNr,
                    b.licensePlateNr,
                    language,
                );

            case VehicleSortFilter.LOGINDESCRIPTION_ASC:
            case VehicleSortFilter.LOGINDESCRIPTION_DESC:
                return vehicleSortByCategoryCompare(
                    a.loginDescription,
                    b.loginDescription,
                    language,
                );
        }
    });

const sortVehiclesByDirection = (
    filteredAndSearchedVehicles: Vehicle[],
    activeVehicleFilter: VehicleSortFilter,
) => {
    switch (activeVehicleFilter) {
        case VehicleSortFilter.DESCPRTION_DESC:
        case VehicleSortFilter.BADGELABELNR_DESC:
        case VehicleSortFilter.LICENSEPLATENR_DESC:
        case VehicleSortFilter.LOGINDESCRIPTION_DESC:
            return filteredAndSearchedVehicles.reverse();
        case VehicleSortFilter.BADGELABELNR_ASC:
        case VehicleSortFilter.DESCPRTION_ASC:
        case VehicleSortFilter.LICENSEPLATENR_ASC:
        case VehicleSortFilter.LOGINDESCRIPTION_ASC:
            return filteredAndSearchedVehicles;
    }
};

const descriptionName = (
    <Localized
        de="Beschreibung"
        fr="Description"
        it="Descrizione"
        en="Description"
    />
);

const licensePlateNrName = (
    <Localized
        de="Kennzeichen"
        fr="Immatriculation"
        it="Targa"
        en="License plate"
    />
);

const badgeLabelNrName = (
    <Localized
        de="Badgenummer"
        fr="Numéro badge"
        it="Numero badge"
        en="Badgenumber"
    />
);

const loginDescriptionName = (
    <Localized de="Fahrer" fr="Chauffeur" it="Conducente" en="Driver" />
);

interface VehicleSortConfig {
    id: VehicleSortFilter;
    name: JSX.Element;
    sortDirection: JSX.Element;
}

const createVehicleSortConfig = (
    hasVehiclesDriver: boolean,
): VehicleSortConfig[] => {
    const vehicleSortConfigs = [
        {
            id: VehicleSortFilter.DESCPRTION_ASC,
            name: descriptionName,
            sortDirection: <Icon icon="arrowDownward" />,
        },
        {
            id: VehicleSortFilter.DESCPRTION_DESC,
            name: descriptionName,
            sortDirection: <Icon icon="arrowUpward" />,
        },
        {
            id: VehicleSortFilter.LICENSEPLATENR_ASC,
            name: licensePlateNrName,
            sortDirection: <Icon icon="arrowDownward" />,
        },
        {
            id: VehicleSortFilter.LICENSEPLATENR_DESC,
            name: licensePlateNrName,
            sortDirection: <Icon icon="arrowUpward" />,
        },
        {
            id: VehicleSortFilter.BADGELABELNR_ASC,
            name: badgeLabelNrName,
            sortDirection: <Icon icon="arrowDownward" />,
        },
        {
            id: VehicleSortFilter.BADGELABELNR_DESC,
            name: badgeLabelNrName,
            sortDirection: <Icon icon="arrowUpward" />,
        },
    ];
    if (hasVehiclesDriver) {
        vehicleSortConfigs.push(
            {
                id: VehicleSortFilter.LOGINDESCRIPTION_ASC,
                name: loginDescriptionName,
                sortDirection: <Icon icon="arrowDownward" />,
            },
            {
                id: VehicleSortFilter.LOGINDESCRIPTION_DESC,
                name: loginDescriptionName,
                sortDirection: <Icon icon="arrowUpward" />,
            },
        );
    }

    return vehicleSortConfigs;
};

const sortOrderName = (
    <Localized
        de="Sortierung"
        fr="Order de tri"
        it="Ordinamento"
        en="Sort order"
    />
);

interface VehiclesListProps {
    vehicles: Vehicle[];
    selectVehicle: (vehicleId: number) => void;
    addNewVehicle: () => void;
    numberOfEnabledLprZones: number;
}

function VehiclesList(p: VehiclesListProps) {
    const { storeState } = useStore(store => ({
        meta: new MetaServerState.StateSlice(store).state,
        settings: new SettingsState.StateSlice(store).state,
    }));

    const [openLprSettingsSlideIn, setOpenLprSettingsSlideIn] = useState(false);
    const [openLprActivationModal, setOpenLprActivationModal] = useState(false);

    const [vehicleSortSlideIn, setVehicleSortSlideIn] = useState(false);

    const [activeVehicleSortCategory, setActiveVehicleSortCategory] =
        useState<VehicleSortFilter>(VehicleSortFilter.DESCPRTION_ASC);

    const [inputSearch, setInputSearch] = useState('');

    const searchedVehicles = searchingVehicles(p.vehicles, inputSearch);

    const sortedByCategorySearchVehicles = sortVehiclesByCategory(
        searchedVehicles,
        activeVehicleSortCategory,
        storeState.settings.language,
    );

    const sortedByDirectionCategorySearchVehicles = sortVehiclesByDirection(
        sortedByCategorySearchVehicles,
        activeVehicleSortCategory,
    );

    const vehiclesWithDriver = p.vehicles.filter(
        vehicles => vehicles.loginDescription !== null,
    );

    const hasVehiclesDriver = vehiclesWithDriver.length > 0;

    const vehicleSortConfig = createVehicleSortConfig(hasVehiclesDriver);

    return (
        <>
            <SectionHeader
                title={<Localized de="Liste" fr="Liste" it="Lista" en="List" />}
            />
            {p.vehicles.length > 5 && (
                <>
                    <div
                        className={css({
                            padding: '8px 24px 6px 24px',
                            background: Colors.backgroundGray,
                        })}
                    >
                        <SearchField
                            labelText={<SearchText />}
                            elevatedLabelText={<SearchText />}
                            value={inputSearch}
                            onChange={value => setInputSearch(value)}
                            onClear={() => setInputSearch('')}
                            disabled={false}
                            searchFieldStyle={SearchFieldStyle.WHITE_WHITE}
                        />
                    </div>

                    <FilterField
                        onClickFilter={() => setVehicleSortSlideIn(true)}
                        activeVehicleSortCategory={activeVehicleSortCategory}
                        sortedVehicleDisplay={vehicleSortConfig}
                    />
                </>
            )}
            <ActionBlock
                onClick={p.addNewVehicle}
                title={
                    <Localized
                        de="Fahrzeug hinzufügen"
                        fr="Ajouter un véhicule"
                        it="Aggiungi veicolo"
                        en="Add vehicle"
                    />
                }
                icon={'add'}
            />
            <DropdownSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                open={vehicleSortSlideIn}
                title={sortOrderName}
                onClose={() => {
                    setVehicleSortSlideIn(false);
                }}
                selected={activeVehicleSortCategory}
                onSelected={(filter: VehicleSortFilter) => {
                    setVehicleSortSlideIn(false);
                    setActiveVehicleSortCategory(filter);
                }}
                options={vehicleSortConfig.map(sortConfig => {
                    return {
                        id: sortConfig.id,
                        display: (
                            <SlideInDisplay
                                firstRowText={
                                    <div
                                        className={css({
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        })}
                                    >
                                        <div
                                            className={css({
                                                marginRight: '5px',
                                            })}
                                        >
                                            {sortConfig.name}
                                        </div>

                                        {sortConfig.sortDirection}
                                    </div>
                                }
                            />
                        ),
                    };
                })}
            />
            {sortedByDirectionCategorySearchVehicles.map(vehicle => (
                <VehicleMenuItemBlock
                    key={`vehicle-${vehicle.customerCarId}`}
                    description={vehicle.description}
                    licensePlateNr={vehicle.licensePlateNr}
                    country={vehicle.country}
                    badgeLabelNr={vehicle.badgeLabelNr}
                    loginDescription={
                        !storeState.meta.data.nonAdminTransactionsVisible &&
                        isDefined(vehicle.loginDescription)
                            ? vehicle.loginDescription
                            : null
                    }
                    onClick={() => {
                        p.selectVehicle(vehicle.customerCarId!);
                    }}
                    icon={
                        vehicle.type === LicensePlateType.CAR ? 'car' : 'bike'
                    }
                    vehiclesLength={p.vehicles.length}
                />
            ))}
            <SectionHeader
                title={
                    <Localized
                        de="Einstellungen"
                        fr="Réglages"
                        it="Impostazioni"
                        en="Settings"
                    />
                }
            />
            <FieldItemBlock
                onClick={() => setOpenLprSettingsSlideIn(true)}
                label={LprTitle}
                content={
                    <LprFieldItemDescription
                        numberOfEnabledLprZones={p.numberOfEnabledLprZones}
                    />
                }
            />
            <LprSettingsSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                open={openLprSettingsSlideIn}
                title={LprTitle}
                onClose={() => {
                    setOpenLprSettingsSlideIn(false);
                }}
                numberOfEnabledLprZones={p.numberOfEnabledLprZones}
                setOpenLprActivationModal={setOpenLprActivationModal}
            />
            {openLprActivationModal && (
                <ModalSuccessBox
                    confirmCallback={() => setOpenLprActivationModal(false)}
                    titleCaption={
                        <Localized
                            de="Aktivierungsbestätigung"
                            fr="Confirmation de l'activation"
                            it="Conferma di attivazione"
                            en="Activation confirmation"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Die Kennzeichenerkennung wurde erfolgreich aktiviert."
                            fr="La reconnaissance des plaques d'immatriculation a été activée avec succès."
                            it="Il riconoscimento della targa è stato attivato con successo."
                            en="License plate recognition has been successfully activated."
                        />
                    </p>

                    <LpOccupiedText
                        occupiedLps={p.vehicles.filter(
                            lp =>
                                lp.barrierGateLicensePlateCheckInOption ===
                                BarrierGateLicensePlateCheckInOption.OCCUPIED,
                        )}
                    />
                    <LpNotAllowedMotorcycleText
                        notAllowedLps={p.vehicles.filter(
                            lp =>
                                lp.barrierGateLicensePlateCheckInOption ===
                                BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_MOTORCYCLE,
                        )}
                    />
                    <LpNotAllowedTooShortText
                        notAllowedLps={p.vehicles.filter(
                            lp =>
                                lp.barrierGateLicensePlateCheckInOption ===
                                BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_TOO_SHORT,
                        )}
                    />
                    <LpNotAllowedDiplomaticText
                        notAllowedLps={p.vehicles.filter(
                            lp =>
                                lp.barrierGateLicensePlateCheckInOption ===
                                BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_DIPLOMATIC,
                        )}
                    />
                </ModalSuccessBox>
            )}
        </>
    );
}

function SectionHeader({ title }: { title: JSX.Element }) {
    return (
        <div
            className={css({
                padding: `4px 16px 0 24px`,
                background: Colors.content,
                height: '30px',
                ...Typo.body,
                color: Colors.blue,
            })}
        >
            {title}
        </div>
    );
}

function LprFieldItemDescription({
    numberOfEnabledLprZones,
}: {
    numberOfEnabledLprZones: number;
}) {
    switch (numberOfEnabledLprZones) {
        case 0:
            return (
                <Localized
                    de="Inaktiv"
                    fr="Inactif"
                    it="Inattivo"
                    en="Inactive"
                />
            );
        case 1:
            return (
                <Localized
                    de={`Aktiv (${numberOfEnabledLprZones} Parking)`}
                    fr={`Actif (${numberOfEnabledLprZones} parking)`}
                    it={`Attivo (${numberOfEnabledLprZones} parcheggio)`}
                    en={`Active (${numberOfEnabledLprZones} parking)`}
                />
            );
        default:
            return (
                <Localized
                    de={`Aktiv (${numberOfEnabledLprZones} Parkings)`}
                    fr={`Actif (${numberOfEnabledLprZones} parkings)`}
                    it={`Attivo (${numberOfEnabledLprZones} parcheggi)`}
                    en={`Active (${numberOfEnabledLprZones} parkings)`}
                />
            );
    }
}

interface VehicleMenuItemBlockProps {
    description: string | null;
    licensePlateNr: string;
    country: string;
    badgeLabelNr: string | null;
    loginDescription: string | null;
    onClick: () => void;
    icon: string;
    vehiclesLength: number;
}

function VehicleMenuItemBlock(p: VehicleMenuItemBlockProps) {
    return (
        <Clickable
            element="div"
            className={css({
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                padding: '12px 24px',
                transitionProperty: 'background',
                transitionDuration: '0.5s',
                background: 'white',
                ':hover': {
                    background: Colors.contentHover,
                },
                borderBottom: `1px solid ${Colors.rgba(Colors.black, 0.08)}`,
                overflow: 'hidden',
                color: Colors.blue,
                ...Typo.robotoMedium,
                letterSpacing: '0.015em',
            })}
            onClick={p.onClick}
            disabled={!p.onClick}
        >
            <div
                className={css({
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                })}
            >
                <div
                    className={css({
                        paddingRight: '18px',
                    })}
                >
                    <Icon icon={p.icon} />
                </div>
                <div className={css({ flexShrink: 1, minWidth: 0 })}>
                    <div
                        className={css({
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '16px',
                        })}
                    >
                        {p.description}
                    </div>

                    <div
                        className={css({
                            color: `${Colors.rgba(Colors.black, 0.5)}`,
                            fontSize: '14px',
                        })}
                    >
                        <div
                            className={css({
                                marginTop: '8px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            })}
                        >{`${p.licensePlateNr} (${p.country})`}</div>
                        {p.vehiclesLength > 5 && (
                            <div
                                className={css({
                                    marginTop: '4px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                })}
                            >
                                {p.badgeLabelNr
                                    ? stringToFourCharacterBlocks(
                                          p.badgeLabelNr,
                                      )
                                    : ''}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    height: '100%',
                })}
            >
                <div
                    className={css({
                        color: `${Colors.rgba(Colors.black, 0.5)}`,
                        fontSize: '14px',
                        alignSelf: 'center',
                        width: '100%',
                        textAlign: 'right',
                    })}
                >
                    {p.loginDescription}
                </div>
                <div
                    className={css({
                        paddingLeft: '8px',
                    })}
                >
                    <Icon icon="chevronRight" />
                </div>
            </div>
        </Clickable>
    );
}

interface FilterFieldProps {
    onClickFilter: () => void;
    activeVehicleSortCategory: VehicleSortFilter;
    sortedVehicleDisplay: VehicleSortConfig[];
}

export const FilterField = (p: FilterFieldProps) => {
    const activeVehicleSortElement = p.sortedVehicleDisplay.find(
        element => element.id === p.activeVehicleSortCategory,
    );

    const activeVehicleSortCategoryName = activeVehicleSortElement?.name;
    const activeVehicleSortDirection = activeVehicleSortElement?.sortDirection;
    return (
        <div
            className={css({
                padding: '0px 24px 12px 24px',
                background: Colors.backgroundGray,
                color: Colors.white,
            })}
        >
            <Clickable
                element="div"
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: '4px',
                    borderBottom: `solid 1px ${Colors.rgba(
                        Colors.action_b,
                        0.6,
                    )}`,
                })}
                onClick={p.onClickFilter}
            >
                <div>
                    <div
                        className={css({
                            ...Typo.robotoRegular,
                            fontSize: '13px',
                            lineHeight: '8px',
                            marginBottom: '2px',
                            letterSpacing: '0.015em',
                        })}
                    >
                        {sortOrderName}
                    </div>
                    <div className={css({ ...Typo.body })}>
                        <div
                            className={css({
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            })}
                        >
                            <div
                                className={css({
                                    marginRight: '5px',
                                })}
                            >
                                {activeVehicleSortCategoryName}
                            </div>

                            {activeVehicleSortDirection}
                        </div>
                    </div>
                </div>

                <Icon
                    className={css({
                        marginLeft: 'auto',
                    })}
                    icon="chevronRight"
                />
            </Clickable>
        </div>
    );
};

const SearchText = () => {
    return <Localized de="Suche" fr="Recherche" it="Ricerca" en="Search" />;
};

export const VehicleListElement = (p: { children: React.ReactNode }) => (
    <div
        className={css({
            background: Colors.white,
        })}
    >
        {p.children}
    </div>
);

export const AddVehicleRow = (p: { title: string; onClick: () => void }) => (
    <div
        className={css({
            display: 'flex',
            height: '72px',
            padding: '12px 24px',
            cursor: 'pointer',
            borderBottom: `1px solid ${rgba(Colors.black, 0.08)}`,
            ...Typo.action,
            '&:hover': {
                background: Colors.contentHover,
            },
        })}
        onClick={p.onClick}
    >
        <div className={css('align-self: center;flex-grow: 1;')}>{p.title}</div>
        <div className={css('align-self: center;')}>
            <Icon icon="add" />
        </div>
    </div>
);

function LpNotAllowedTooShortText({
    notAllowedLps,
}: {
    notAllowedLps: Vehicle[];
}) {
    switch (notAllowedLps.length) {
        case 0:
            return null;
        case 1:
            return (
                <p>
                    <Localized
                        de={`Das Fahrzeug ${notAllowedLps[0].licensePlateNr} konnte nicht aktiviert werden, da das Kennzeichen weniger als 6 Zeichen hat.`}
                        fr={`Le véhicule ${notAllowedLps[0].licensePlateNr} n'a pas pu être activé, car la plaque d'immatriculation comporte moins de 6 caractères.`}
                        it={`Il veicolo ${notAllowedLps[0].licensePlateNr} non ha potuto essere attivato, perché la targa ha meno di 6 caratteri.`}
                        en={`The vehicle ${notAllowedLps[0].licensePlateNr} could not be activated, because the license plate has fewer than 6 characters.`}
                    />
                </p>
            );
        default:
            return (
                <p>
                    <Localized
                        de={`Die Fahrzeuge ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} konnten nicht aktiviert werden, da die Kennzeichen weniger als 6 Zeichen haben.`}
                        fr={`Les véhicules ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} n'ont pas pu être activés, car les plaques d'immatriculation comportent moins de 6 caractères.`}
                        it={`I veicoli ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} non hanno potuto essere attivati, perché le targhe hanno meno di 6 caratteri.`}
                        en={`The vehicles ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} could not be activated, because the license plates have fewer than 6 characters.`}
                    />
                </p>
            );
    }
}

function LpNotAllowedDiplomaticText({
    notAllowedLps,
}: {
    notAllowedLps: Vehicle[];
}) {
    switch (notAllowedLps.length) {
        case 0:
            return null;
        case 1:
            return (
                <p>
                    <Localized
                        de={`Das Fahrzeug ${notAllowedLps[0].licensePlateNr} konnte nicht aktiviert werden, da es sich um ein Diplomatenkennzeichen handelt.`}
                        fr={`Le véhicule ${notAllowedLps[0].licensePlateNr} n'a pas pu être activé, puisque c'est une plaque diplomatique.`}
                        it={`Il veicolo ${notAllowedLps[0].licensePlateNr} non ha potuto essere attivato, dal momento che è una targa diplomatica.`}
                        en={`The vehicle ${notAllowedLps[0].licensePlateNr} could not be activated, as it is a diplomatic plate.`}
                    />
                </p>
            );
        default:
            return (
                <p>
                    <Localized
                        de={`Die Fahrzeuge ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} konnten nicht aktiviert werden, da es sich um Diplomatenkennzeicher handelt.`}
                        fr={`Les véhicules ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} n'ont pas pu être activés, puisque c'est plaques diplomatique.`}
                        it={`I veicoli ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} non hanno potuto essere attivati, dal momento che è una targa diplomatica.`}
                        en={`The vehicles ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} could not be activated, as it is a diplomatic plate.`}
                    />
                </p>
            );
    }
}

function LpNotAllowedMotorcycleText({
    notAllowedLps,
}: {
    notAllowedLps: Vehicle[];
}) {
    switch (notAllowedLps.length) {
        case 0:
            return null;
        case 1:
            return (
                <p>
                    <Localized
                        de={`Das Fahrzeug ${notAllowedLps[0].licensePlateNr} konnte nicht aktiviert werden, da es sich um ein Motorrad handelt.`}
                        fr={`Le véhicule ${notAllowedLps[0].licensePlateNr} n'a pas pu être activé, puisque c'est une moto.`}
                        it={`Il veicolo ${notAllowedLps[0].licensePlateNr} non ha potuto essere attivato, dal momento che è una moto.`}
                        en={`The vehicle ${notAllowedLps[0].licensePlateNr} could not be activated, as it is a motorcycle.`}
                    />
                </p>
            );
        default:
            return (
                <p>
                    <Localized
                        de={`Die Fahrzeuge ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} konnten nicht aktiviert werden, da es sich um Motorräder handelt.`}
                        fr={`Les véhicules ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} n'ont pas pu être activés, puisque ce sont des motos.`}
                        it={`I veicoli ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} non hanno potuto essere attivati, dal momento che sono delle moto.`}
                        en={`The vehicles ${notAllowedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} could not be activated, as they are motorcycles.`}
                    />
                </p>
            );
    }
}

function LpOccupiedText({ occupiedLps }: { occupiedLps: Vehicle[] }) {
    switch (occupiedLps.length) {
        case 0:
            return null;
        case 1:
            return (
                <p>
                    <Localized
                        de={`Das Fahrzeug ${occupiedLps[0].licensePlateNr} konnte nicht aktiviert werden, da es bereits in einem anderen Parkingpay-Konto aktiv ist.`}
                        fr={`Le véhicule ${occupiedLps[0].licensePlateNr} n'a pas pu être activé, car il est déjà actif dans un autre compte Parkingpay.`}
                        it={`Il veicolo ${occupiedLps[0].licensePlateNr} non ha potuto essere attivato, in quanto è già attivo in un altro conto Parkingpay.`}
                        en={`The vehicle ${occupiedLps[0].licensePlateNr} could not be activated, as it is already active in another Parkingpay account.`}
                    />
                </p>
            );
        default:
            return (
                <p>
                    <Localized
                        de={`Die Fahrzeuge ${occupiedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} konnten nicht aktiviert werden, da sie bereits in einem anderen Parkingpay-Konto aktiv sind.`}
                        fr={`Les véhicules ${occupiedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} n'ont pas pu être activés, car ils sont déjà actifs dans un autre compte Parkingpay.`}
                        it={`I veicoli ${occupiedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} non hanno potuto essere attivati, in quanto sono già attivi un altro conto Parkingpay.`}
                        en={`The vehicles ${occupiedLps
                            .map(lp => lp.licensePlateNr)
                            .join(
                                ', ',
                            )} could not be activated, as they are already active in another Parkingpay account.`}
                    />
                </p>
            );
    }
}
