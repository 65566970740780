import { css } from '@emotion/css';

import { useState } from 'react';
import { Store, useStore } from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import { languageFromString } from 'dg-web-shared/lib/Text';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import * as SettingsState from '../../common/state/SettingsState';
import { Colors } from 'dg-web-shared/ui/vars';
import { logAction } from '../../utils/ActionLog';
import * as MetaServerState from '../meta/state/MetaServerState';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { PaymentMethodsListSlideIn } from './PaymentMethodsList';
import { requestedPaymentMethod } from './PaymentMethodStatus';
import { SavedCreditCardState } from '../shared/SavedCreditCardAliasState';
import { PaymentMethodEnum } from '../../api/Http';
import { MainMenuItemBlock } from '../root/components/ItemBlock';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Localized } from '../../common/components/Localized';
import { Message } from 'dg-web-shared/lib/Localized';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import {
    PaymentCardType,
    SavedCreditCardAliasResponseTag,
} from 'dg-web-shared/model/PaymentAlias';
import { RedWarnIcon24 } from '../../common/components/RedWarnIcon';

export const PaymentMethodMenu = () => {
    const { storeState, update } = useStore(store => ({
        meta: new MetaServerState.StateSlice(store).state,
        paymentType: new PaymentTypeState.StateSlice(store).state,
        settings: new SettingsState.StateSlice(store).state,
        ccAliasGetRequest: SavedCreditCardState.get(store),
    }));
    const [showPaymentMethodsListSlideIn, setShowPaymentMethodsListSlideIn] =
        useState(false);

    const language = languageFromString(storeState.settings.language);
    const toggleSlideIn = () => {
        setShowPaymentMethodsListSlideIn(true);
        update(s => logAction(s, 'show-payment-methods-from-account'));
    };

    const aliasReq = storeState.ccAliasGetRequest;

    const alias =
        aliasReq.status === RequestStatus.SUCCESS &&
        aliasReq.data.tag === SavedCreditCardAliasResponseTag.FOUND
            ? aliasReq.data.creditCardAlias
            : null;

    const isDirect =
        storeState.paymentType.data.activePaymentMethod.activePaymentType ===
        PaymentMethodEnum.DIRECT;

    const directWithFailedAlias = alias?.lastDirectFailed && isDirect;
    const directWithExpiredAlias = alias?.expiration?.isExpired && isDirect;

    let rightTitle;

    if (isDirect) {
        rightTitle =
            !alias || directWithFailedAlias || directWithExpiredAlias ? (
                <RedWarnIcon24 />
            ) : (
                !!alias && paymentMethodName(alias?.paymentMethod)
            );
    } else {
        rightTitle = (
            <Localized
                {...getMethodShortName(
                    storeState.paymentType.data.activePaymentMethod
                        .activePaymentType,
                )}
            />
        );
    }

    return (
        <div>
            <MainMenuItemBlock
                onClick={toggleSlideIn}
                title={
                    <Localized
                        {...{
                            de: 'Zahlungsweise',
                            fr: 'Mode de paiement',
                            it: 'Modalità di pagamento',
                            en: 'Payment method',
                        }}
                    />
                }
                rightTitle={rightTitle}
            />

            <PaymentMethodsListSlideIn
                language={language}
                customerAccountType={storeState.meta.data.customerAccountType}
                customerWasInDebtCollection={
                    storeState.meta.data.wasInDebtCollection
                }
                activePaymentMethod={
                    storeState.paymentType.data.activePaymentMethod
                        .activePaymentType
                }
                requestedPaymentMethod={requestedPaymentMethod(
                    storeState.paymentType.data.paymentMethodChangeRequest
                        ?.requestedPaymentType || null,
                )}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={showPaymentMethodsListSlideIn}
                title={
                    <Localized
                        {...{
                            de: 'Zahlungsweise',
                            fr: 'Mode de paiement',
                            it: 'Modalità di pagamento',
                            en: 'Payment method',
                        }}
                    />
                }
                onClose={() => setShowPaymentMethodsListSlideIn(false)}
            />
        </div>
    );
};

export function requestCallback(
    actionToLog: string,
    actionDataToLog: { iban: string } | null,
    success: () => void,
): (store: Store, res: Response) => void {
    return (store: Store, res: Response) => {
        logAction(store, actionToLog, actionDataToLog);
        if (res.statusCode.cls.success) {
            new PaymentTypeState.StateSlice(store).reset();
            new AccountBalanceState.StateSlice(store).reset();
            success();
        }
    };
}

export const formStyle = css({
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    backgroundColor: Colors.form,
    ':focus': { outline: 'none' },
});

export function paymentMethodName(method: PaymentCardType | null): string {
    if (!method) {
        return '';
    }

    return {
        TWI: 'TWINT',
        VIS: 'Visa',
        ECA: 'Mastercard',
        AMX: 'Amex',
        PFC: 'Postfinance',
        PEF: 'Postfinance E-Finance',
    }[method];
}

export function getMethodName(method: PaymentMethodEnum): Message {
    switch (method) {
        case PaymentMethodEnum.PREPAID:
            return {
                de: 'Vorauszahlung',
                fr: 'Prépaiement',
                it: 'Prepagata',
                en: 'Prepaid',
            };

        case PaymentMethodEnum.LSV:
            return {
                de: 'Lastschrift (LSV)',
                fr: 'Prélèv. autom. (LSV)',
                it: 'Addebito diretto (LSV)',
                en: 'Direct debit (LSV)',
            };

        case PaymentMethodEnum.INVOICE:
            return {
                de: 'Auf Rechnung',
                fr: 'Sur facture',
                it: 'Contro fattura',
                en: 'Invoice',
            };

        case PaymentMethodEnum.DIRECT:
            return {
                de: 'Einzelzahlung',
                fr: 'Paiement individuel',
                it: 'Pagamento singolo',
                en: 'Individual payment',
            };
    }
}

function getMethodShortName(method: PaymentMethodEnum): Message {
    switch (method) {
        case PaymentMethodEnum.PREPAID:
            return {
                de: 'Vorauszahlung',
                fr: 'prépaiement',
                it: 'prepagata',
                en: 'Prepaid',
            };

        case PaymentMethodEnum.LSV:
            return {
                de: 'LSV',
                fr: 'LSV',
                it: 'LSV',
                en: 'LSV',
            };

        case PaymentMethodEnum.INVOICE:
            return {
                de: 'Auf Rechnung',
                fr: 'sur facture',
                it: 'contro fattura',
                en: 'Invoice',
            };

        case PaymentMethodEnum.DIRECT:
            return {
                de: 'Einzelzahlung',
                fr: 'individuel',
                it: 'singolo',
                en: 'Individual',
            };
    }
}
