import { Translation, Translations } from './Text';
import { validBadgeNumber } from './RfidCardValidation';
import * as emailValidator from 'email-validator';

export type ErrorTextTranslation = Translation;
export type FormValidator = (
    value: string | null | undefined,
    language: string,
) => ErrorTextTranslation | null;
export type Form<S> = { [F in keyof S]?: FormValidator[] };
export type ErrorTexts<S> = { [F in keyof S]?: ErrorTextTranslation };

export namespace FormValidation {
    export const validate = <S>(
        values: { [F in keyof S]?: string | null },
        form: Form<S>,
        language: string,
    ) => {
        const errorTexts: ErrorTexts<S> = {};

        for (const fieldName in form) {
            if (form.hasOwnProperty(fieldName)) {
                const value = values[fieldName];
                const firstValidator = (<FormValidator[]>(
                    (form[fieldName] || [])
                )).find(
                    validator => validator(value || null, language) !== null,
                );
                const errorText = firstValidator
                    ? firstValidator(value || null, language)
                    : null;
                if (errorText) {
                    errorTexts[fieldName] = errorText;
                }
            }
        }

        return {
            isValid: Object.keys(errorTexts).length === 0,
            errorTexts: errorTexts,
        };
    };
    export const errorText = <S>(
        show: boolean,
        errors: ErrorTexts<S>,
        get: (errors: ErrorTexts<S>) => string | null | undefined,
    ) => (get(errors) !== null && show ? get(errors) : null);
}

namespace FormValidatorTexts {
    interface Texts {
        requiredField: ErrorTextTranslation;
        fieldsNotEqual: ErrorTextTranslation;
        length: (n: number) => ErrorTextTranslation;
        invalidParkingpayBadgeLabel: ErrorTextTranslation;
        minimumIs: (n: number) => ErrorTextTranslation;
        maximumIs: (n: number) => ErrorTextTranslation;
        emailNotValid: ErrorTextTranslation;
    }

    const validationTexts: Translations<Texts> = {
        de: {
            requiredField: () => 'Pflichtfeld',
            fieldsNotEqual: () => 'Felder müssen identisch sein',
            length: n => () => `Exakt ${n} Zeichen erforderlich`,
            invalidParkingpayBadgeLabel: () => 'Ungültige Badge-Nummer',
            minimumIs: n => () => `Der minimal zulässige Wert ist ${n}`,
            maximumIs: n => () => `Der maximal zulässige Wert ist ${n}`,
            emailNotValid: () => 'E-Mail nicht gültig',
        },
        fr: {
            requiredField: () => 'Champ obligatoire',
            fieldsNotEqual: () => 'Les champs doivent être identiques',
            length: n => () => `Le champ doit contenir ${n} caractères`,
            invalidParkingpayBadgeLabel: () => 'Numéro de badge invalide',
            minimumIs: n => () => `La valeur minimale autorisée est ${n}`,
            maximumIs: n => () => `La valeur maximale autorisée est ${n}`,
            emailNotValid: () => 'E-mail non valide',
        },
        it: {
            requiredField: () => 'Campo obbligatorio',
            fieldsNotEqual: () => 'I campi devo essere identici',
            length: n => () => `Il campo deve contenere ${n} caratteri`,
            invalidParkingpayBadgeLabel: () => 'Numero di badge non valido',
            minimumIs: n => () => `Il valore minimo consentito è ${n}`,
            maximumIs: n => () => `Il valore massimo consentito è ${n}`,
            emailNotValid: () => 'E-mail non valida',
        },
        en: {
            requiredField: () => 'Mandatory field',
            fieldsNotEqual: () => 'Fields must be identical',
            length: n => () => `The field must contain ${n} characters`,
            invalidParkingpayBadgeLabel: () => 'Invalid badge number',
            minimumIs: n => () => `The minimum allowed value is ${n}`,
            maximumIs: n => () => `The maximum allowed value is ${n}`,
            emailNotValid: () => 'Email not valid',
        },
    };

    export const texts = (lang: string) => validationTexts[lang];
}

export namespace FormValidators {
    export const NotEmpty: FormValidator = (v, language) =>
        v && v.length > 0
            ? null
            : FormValidatorTexts.texts(language).requiredField;

    export const Equals =
        (v1: string | null | undefined): FormValidator =>
        (v2, language) =>
            v1 === null || v1 === undefined || v1.length === 0 || v2 !== v1
                ? FormValidatorTexts.texts(language).fieldsNotEqual
                : null;

    export const EmailValid: FormValidator = (v, language) => {
        if (!v) {
            return null;
        }
        return !emailValidator.validate(v)
            ? FormValidatorTexts.texts(language).emailNotValid
            : null;
    };

    export const Length =
        (n: number): FormValidator =>
        (v, language) =>
            v && v.length === n
                ? null
                : FormValidatorTexts.texts(language).length(n);

    export const ParkingpayBadgeLabel: FormValidator = (v, language) =>
        v && validBadgeNumber(v)
            ? null
            : FormValidatorTexts.texts(language).invalidParkingpayBadgeLabel;

    export const Minimum =
        (n: number): FormValidator =>
        (v, language) =>
            !!v && parseInt(v, 10) >= n
                ? null
                : FormValidatorTexts.texts(language).minimumIs(n);

    export const Maximum =
        (n: number): FormValidator =>
        (v, language) =>
            !!v && parseInt(v, 10) <= n
                ? null
                : FormValidatorTexts.texts(language).maximumIs(n);
}
