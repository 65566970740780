import { createContext, useContext, useState } from 'react';

interface OpenVehicleListContextType {
    listOpen: boolean;
    setListOpen: (listOpen: boolean) => void;
}

export const OpenVehicleListContext = createContext<OpenVehicleListContextType>(
    {
        listOpen: false,
        setListOpen: () => {},
    },
);
export const useVehicleListOpen = () => useContext(OpenVehicleListContext);

export function VehicleListOpenProvider(p: { children: React.ReactNode }) {
    const [listOpen, setListOpen] = useState(false);
    return (
        <OpenVehicleListContext.Provider value={{ listOpen, setListOpen }}>
            {p.children}
        </OpenVehicleListContext.Provider>
    );
}
