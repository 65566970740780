import { CSSObject } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';

export namespace Typo {
    export const robotoLight = {
        fontFamily: 'Roboto',
        fontWeight: 300,
    };

    export const robotoLightItalic = {
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontStyle: 'italic',
    };

    export const robotoRegular = {
        fontFamily: 'Roboto',
        fontWeight: 400,
    };

    export const robotoMedium = {
        fontFamily: 'Roboto',
        fontWeight: 500,
    };

    export const robotoBold = {
        fontFamily: 'Roboto',
        fontWeight: 700,
    };

    export const robotoBlack = {
        fontFamily: 'Roboto',
        fontWeight: 900,
    };
    interface BasicFont extends CSSObject {
        fontFamily: string;
        fontWeight: number;
    }

    interface Action extends BasicFont {
        color: string;
        fontSize: string;
        lineHeight: string;
        letterSpacing: string;
        textTransform: 'uppercase';
    }

    export const action: Action = {
        ...robotoMedium,
        color: Colors.blue,
        fontSize: '15px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
        textTransform: 'uppercase',
    };

    export const bodyTopCorrection = 3; // px
    export const body = {
        lineHeight: '22px',
        fontSize: '16px',
        ...robotoMedium,
    };

    export const caption = {
        ...robotoRegular,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const tBody = {
        ...robotoRegular,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.01em',
    };

    export const heading3 = {
        ...robotoMedium,
        fontSize: '32px',
        lineHeight: '40px',
    };

    export const heading4 = {
        ...robotoBold,
        fontSize: '18px',
        lineHeight: '20px',
    };

    export const sCaptionTopCorrectionPx = 2;
}
