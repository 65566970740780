import React = require('react');
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { HasChildren } from 'dg-web-shared/lib/ReactHelpers';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../../tb-ui/typo';
import { defaultTransition } from '../../../../../tb-ui/transitions';
import { IconBig } from '../../../../../tb-ui/icons/IconBig';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

interface BlockTitle {
    caption: React.ReactNode;
    font: BlockFont;
    color: BlockColor;
}

interface BlockIcon {
    name: string;
    color?: BlockColor;
}

enum BlockFont {
    BIG = 'big',
    MEDIUM = 'medium',
    SMALL = 'small',
}

export enum BlockStatus {
    ACTIVE = 'active',
    REQUESTED = 'requested',
    INACTIVE = 'inactive',
}

export enum BlockColor {
    BLUE = 'blue',
    RED = 'red',
    DARKGREY = 'darkgrey',
    GREY = 'grey',
    LIGHTGREY = 'lightgrey',
}

interface Props {
    title: BlockTitle;
    subTitle?: BlockTitle;
    rightTitle?: BlockTitle;
    editAction?: boolean;
    onClick: (() => void) | null;
    icon?: BlockIcon;
    iconIsBig?: boolean;
    status?: BlockStatus;
    hasTopBorder?: boolean;
    hideChevron?: boolean;
    className?: string;
}

interface ItemBlockGroupProps extends HasChildren {
    title?: React.ReactNode;
    hasTopSpace?: boolean;
}

export const ItemBlockGroup = (p: ItemBlockGroupProps) => (
    <div
        className={css({
            borderTop: `solid 1px ${Colors.lightgrey}`,
            marginTop: p.hasTopSpace ? '12px' : undefined,
        })}
    >
        {p.title && (
            <div
                className={css({
                    ...Typo.listheader2,
                    textTransform: 'uppercase',
                    color: Colors.blue,
                    background: Colors.rgba(Colors.black, 0.08),
                    height: '30px',
                    padding: '0 24px',
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                {p.title}
            </div>
        )}
        {p.children}
    </div>
);

function getItemBlockContainerStyle(
    hasTopBorder: boolean,
    hasOnClick: boolean,
    isAvailableCard: boolean,
    status?: BlockStatus,
) {
    const clickAnimationCss = hasOnClick
        ? {
              ...defaultTransition('background', 500),
              '&:hover': {
                  backgroundColor: Colors.contentHover,
              },
          }
        : undefined;

    return css({
        height: isAvailableCard ? '108px' : '72px',
        overflow: 'hidden',
        borderBottom: `solid 1px ${Colors.lightgrey}`,
        borderTop: hasTopBorder ? `solid 1px ${Colors.lightgrey}` : undefined,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0px 24px',
        background: status === BlockStatus.ACTIVE ? Colors.form : undefined,
        ...clickAnimationCss,
    });
}

const ItemBlock = (p: Props) => {
    return (
        <Clickable
            element="div"
            className={`${getItemBlockContainerStyle(
                Boolean(p.hasTopBorder),
                p.onClick !== null,
                false,
                p.status,
            )} ${p.className ? p.className : ''}`}
            onClick={p.onClick ? p.onClick : () => null}
            disabled={!p.onClick}
        >
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    overflow: 'hidden',
                })}
            >
                {p.status && <StatusIcon status={p.status} />}
                {p.icon && (
                    <div
                        className={css({
                            color: getColorHex(p.icon.color),
                            paddingRight: '18px',
                        })}
                    >
                        {p.iconIsBig ? (
                            <IconBig icon={p.icon.name} />
                        ) : (
                            <Icon icon={p.icon.name} />
                        )}
                    </div>
                )}
                <div className={css({ flexShrink: 1, minWidth: 0 })}>
                    <div
                        className={css({
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            color: getColorHex(p.title.color),
                            ...getTypo(p.title.font),
                            userSelect: 'text',
                            textOverflow: 'ellipsis',
                        })}
                    >
                        {p.title.caption}
                    </div>
                    {p.subTitle && (
                        <div
                            className={css({
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                color: getColorHex(p.subTitle.color),
                                ...getTypo(p.subTitle.font),
                                marginTop: '8px',
                                userSelect: 'text',
                                textOverflow: 'ellipsis',
                            })}
                        >
                            {p.subTitle.caption}
                        </div>
                    )}
                </div>
            </div>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    height: '100%',
                })}
            >
                {p.rightTitle && (
                    <div
                        className={css({
                            color: getColorHex(p.rightTitle.color),
                            ...getTypo(p.rightTitle.font),
                            alignSelf: 'center',
                            width: '100%',
                            textAlign: 'right',
                        })}
                    >
                        {p.rightTitle.caption}
                    </div>
                )}
                {p.onClick !== null && !p.hideChevron && (
                    <RightIcon editAction={p.editAction} />
                )}
            </div>
        </Clickable>
    );
};

function getColorHex(color?: BlockColor): string {
    switch (color) {
        case BlockColor.BLUE:
            return Colors.blue;
        case BlockColor.RED:
            return Colors.red;
        case BlockColor.GREY:
            return Colors.grey;
        case BlockColor.LIGHTGREY:
            return Colors.lightgrey;
        case BlockColor.DARKGREY:
            return Colors.darkgrey;
        default:
            return 'inital';
    }
}

function getTypo(typo: BlockFont) {
    const font = {
        ...Typo.robotoMedium,
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };
    switch (typo) {
        case BlockFont.SMALL:
            return { ...font, fontSize: '14px' };
        case BlockFont.MEDIUM:
            return { ...font, fontSize: '15px' };
        case BlockFont.BIG:
            return { ...font, fontSize: '16px' };
    }
}

interface ContractProps {
    title: React.ReactNode;
    subTitle: React.ReactNode;
    rightTitle?: React.ReactNode;
    onClick: (() => void) | null;
    iconName: string;
    iconColor: BlockColor;
}

export const ContractItemBlock = (p: ContractProps) => (
    <ItemBlock
        title={{
            caption: p.title,
            font: BlockFont.BIG,
            color: BlockColor.DARKGREY,
        }}
        subTitle={{
            caption: p.subTitle,
            font: BlockFont.SMALL,
            color: BlockColor.GREY,
        }}
        rightTitle={{
            caption: p.rightTitle,
            font: BlockFont.MEDIUM,
            color: BlockColor.GREY,
        }}
        onClick={p.onClick}
        icon={{ name: p.iconName, color: p.iconColor }}
    />
);

interface MenuProps {
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    rightTitle?: React.ReactNode;
    icon?: string;
    hideChevron?: boolean;
    onClick: (() => void) | null;
}

export const MainMenuItemBlock = (p: MenuProps) => (
    <ItemBlock
        title={{
            caption: p.title,
            font: BlockFont.BIG,
            color: BlockColor.DARKGREY,
        }}
        subTitle={
            p.subTitle
                ? {
                      caption: p.subTitle,
                      font: BlockFont.BIG,
                      color: BlockColor.BLUE,
                  }
                : undefined
        }
        rightTitle={
            p.rightTitle
                ? {
                      caption: p.rightTitle,
                      font: BlockFont.BIG,
                      color: BlockColor.BLUE,
                  }
                : undefined
        }
        onClick={p.onClick}
        hideChevron={p.hideChevron}
    />
);

export const MenuItemBlock = (p: MenuProps) => (
    <ItemBlock
        title={{
            caption: p.title,
            font: BlockFont.BIG,
            color: BlockColor.BLUE,
        }}
        subTitle={
            p.subTitle
                ? {
                      caption: p.subTitle,
                      font: BlockFont.SMALL,
                      color: BlockColor.GREY,
                  }
                : undefined
        }
        rightTitle={
            p.rightTitle
                ? {
                      caption: p.rightTitle,
                      font: BlockFont.SMALL,
                      color: BlockColor.GREY,
                  }
                : undefined
        }
        onClick={p.onClick}
        icon={p.icon ? { name: p.icon, color: BlockColor.BLUE } : undefined}
    />
);

interface PaymentMethodProps {
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    rightTitle?: React.ReactNode;
    icon?: string;
    iconIsBig?: boolean;
    onClick: (() => void) | null;
    status?: BlockStatus;
}

export const PaymentMethodItemBlock = (p: PaymentMethodProps) => (
    <ItemBlock
        title={{
            caption: p.title,
            font: BlockFont.BIG,
            color: BlockColor.BLUE,
        }}
        subTitle={
            p.subTitle
                ? {
                      caption: p.subTitle,
                      font: BlockFont.SMALL,
                      color: BlockColor.GREY,
                  }
                : undefined
        }
        rightTitle={
            p.rightTitle
                ? {
                      caption: p.rightTitle,
                      font: BlockFont.SMALL,
                      color: BlockColor.GREY,
                  }
                : undefined
        }
        onClick={p.onClick}
        icon={p.icon ? { name: p.icon, color: undefined } : undefined}
        status={p.status}
        iconIsBig={p.iconIsBig}
    />
);

export function CardItemBlock({
    title,
    subTitle,
    rightTitle,
    icons,
    onClick,
    status,
}: {
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    rightTitle?: React.ReactNode;
    icons: JSX.Element | null;
    onClick: () => void;
    status?: BlockStatus;
}) {
    return (
        <Clickable
            element="div"
            className={getItemBlockContainerStyle(
                false,
                Boolean(onClick),
                false,
                status,
            )}
            onClick={onClick}
        >
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    overflow: 'hidden',
                })}
            >
                {status && <StatusIcon status={status} />}
                <div className={css({ flexShrink: 1, minWidth: 0 })}>
                    <Title title={title} />
                    {subTitle && <SubTitle subTitle={subTitle} />}
                </div>
            </div>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    height: '100%',
                })}
            >
                {rightTitle && (
                    <div
                        className={css({
                            color: getColorHex(BlockColor.GREY),
                            ...getTypo(BlockFont.SMALL),
                            alignSelf: 'center',
                            width: '100%',
                            textAlign: 'right',
                        })}
                    >
                        {rightTitle}
                    </div>
                )}
                {icons}
                <RightIcon />
            </div>
        </Clickable>
    );
}

export function SelectedCardItemBlock({
    title,
    subTitle,
    icon,
    onClick,
}: {
    title: React.ReactNode;
    subTitle: React.ReactNode;
    icon: string;
    onClick: () => void;
}) {
    return (
        <Clickable
            element="div"
            className={getItemBlockContainerStyle(
                false,
                Boolean(onClick),
                true,
            )}
            onClick={onClick}
        >
            <div
                className={css({
                    display: 'flex',
                    flexFlow: 'column',
                    overflow: 'hidden',
                })}
            >
                <IconBig icon={icon} />
                <div className={css({ flexShrink: 1, minWidth: 0 })}>
                    <Title title={title} />
                    <SubTitle subTitle={subTitle} />
                </div>
            </div>

            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    height: '100%',
                })}
            >
                <RightIcon />
            </div>
        </Clickable>
    );
}

function Title({ title }: { title: React.ReactNode }) {
    return (
        <div
            className={css({
                overflow: 'hidden',
                color: getColorHex(BlockColor.BLUE),
                ...getTypo(BlockFont.BIG),
                userSelect: 'text',
                textOverflow: 'ellipsis',
            })}
        >
            {title}
        </div>
    );
}

function SubTitle({ subTitle }: { subTitle: React.ReactNode }) {
    return (
        <div
            className={css({
                overflow: 'hidden',
                color: getColorHex(BlockColor.GREY),
                ...getTypo(BlockFont.SMALL),
                marginTop: '8px',
                userSelect: 'text',
                textOverflow: 'ellipsis',
            })}
        >
            {subTitle}
        </div>
    );
}

function RightIcon({ editAction }: { editAction?: boolean }) {
    return (
        <div
            className={css({
                color: Colors.blue,
                paddingLeft: '8px',
            })}
        >
            <Icon icon={editAction ? 'edit' : 'chevronRight'} />
        </div>
    );
}

function StatusIcon({ status }: { status: BlockStatus }) {
    return (
        <div
            className={css({
                color: Colors.blue,
                paddingRight: '18px',
            })}
        >
            <Icon
                icon={
                    status === BlockStatus.ACTIVE
                        ? 'checkmark'
                        : status === BlockStatus.REQUESTED
                          ? 'hourglass'
                          : ''
                }
            />
        </div>
    );
}

interface FieldProps {
    label: React.ReactNode;
    content: React.ReactNode;
    onClick: (() => void) | null;
    className?: string;
}

export const FieldItemBlock = (p: FieldProps) => (
    <ItemBlock
        title={{
            caption: p.label,
            font: BlockFont.SMALL,
            color: BlockColor.GREY,
        }}
        subTitle={
            p.content
                ? {
                      caption: p.content,
                      font: BlockFont.BIG,
                      color: BlockColor.BLUE,
                  }
                : {
                      caption: '—',
                      font: BlockFont.BIG,
                      color: BlockColor.GREY,
                  }
        }
        onClick={p.onClick}
        className={p.className}
        editAction
    />
);
