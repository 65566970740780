import * as HttpResponse from './HttpResponse';
import * as AbstractServerStateSlice from './AbstractLegacyServerStateSlice';
import * as Flux from './Flux';
import { Response } from './HttpResponse';

export interface ServerState<D>
    extends AbstractServerStateSlice.AbstractLegacyServerState<D> {
    data: D;
}

export abstract class ServerStateSlice<
    D,
> extends AbstractServerStateSlice.AbstractLegacyServerStateSlice<
    ServerState<D>,
    D
> {
    abstract getEmptyData(): D;
    abstract parseBody(body: any): D;

    getInitialState(): ServerState<D> {
        return {
            pending: false,
            shouldFetch: true,
            data: this.getEmptyData(),
            statusCode: new HttpResponse.StatusCode(null),
            attempts: 0,
            request: null,
            lastSuccessfulFetch: 0,
        };
    }

    protected writeSuccess(res: HttpResponse.Response): void {
        super.writeSuccess(res);
        this.set((s: ServerState<D>): ServerState<D> => {
            s.data = res.body ? this.parseBody(res.body) : this.getEmptyData();
            return s;
        });
    }
}

export function someArePendingOrWillBeFetched(
    ...stati: AbstractServerStateSlice.AbstractLegacyServerState<any>[]
): boolean {
    for (const s of stati) {
        if (s.pending || s.shouldFetch) {
            return true;
        }
    }
    return false;
}

export interface GeneratorFunctions<D> {
    get: (store: Flux.Store) => ServerState<D>;
    reset: (store: Flux.Store) => string;
    setResponse: (store: Flux.Store, res: Response) => string;
    forceRefetch: (store: Flux.Store) => string;
}

export const generateServerState = <D>(
    key: string,
    emptyData: () => D,
    sideEffects: (store: Flux.Store, state: ServerState<D>) => void,
    parseBody: (body: any) => D,
    refreshSecs?: number,
): GeneratorFunctions<D> => {
    class GenServerStateSlice extends ServerStateSlice<D> {
        key(): string {
            return key;
        }
        getEmptyData(): D {
            return emptyData();
        }
        sideEffects(store: Flux.Store): void {
            sideEffects(store, this.state);
        }
        parseBody(body: any): D {
            if (body) {
                return parseBody(body);
            } else {
                return emptyData();
            }
        }

        protected getRefreshIntervalSecs(): number {
            return refreshSecs || 0;
        }
    }

    return {
        get: (store: Flux.Store): ServerState<D> =>
            new GenServerStateSlice(store).state,
        reset: (store: Flux.Store): string => {
            new GenServerStateSlice(store).reset();
            return key + '-reset';
        },
        forceRefetch: store => {
            new GenServerStateSlice(store).set(s => {
                s.shouldFetch = true;
                return s;
            });
            return key + 'refetch';
        },
        setResponse: (store: Flux.Store, res: Response): string => {
            new GenServerStateSlice(store).writeRequest(res);
            return key + '-fetch';
        },
    };
};
