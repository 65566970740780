type TransactionTax = { percent: number; amount: number };

export enum TransactionCostTag {
    NO_SURCHARGES = 'NO_SURCHARGES',
    HAVING_SURCHARGES = 'HAVING_SURCHARGES',
}

export type TransactionCost =
    | TransactionCostNoSurcharges
    | TransactionCostHavingSurcharges;

type TransactionCostNoSurcharges = {
    tag: TransactionCostTag.NO_SURCHARGES;
    paidAmount: number;
    serviceCostExcludingTax: number;
    serviceCostTax: TransactionTax;
};

type TransactionCostHavingSurcharges = {
    tag: TransactionCostTag.HAVING_SURCHARGES;
    paidAmount: number;
    serviceCostExcludingTax: number;
    serviceCostTax: TransactionTax;
    surchargeExcludingTax: number;
    surchargeTax: TransactionTax;
};
