import { css } from '@emotion/css';

import * as superagent from 'superagent';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    ErrorState,
    RequestStatus,
    useServerFetch,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { BasicButton } from '../../../../../tb-ui/buttons/BasicButton';
import { ButtonRow } from '../../../../../tb-ui/buttons/ButtonRow';
import { InputContext } from '../../../../../tb-ui/inputs/InputContext';
import { TextField } from '../../../../../tb-ui/inputs/TextField';
import { Localized } from '../../../common/components/Localized';
import { Typo } from '../../../style/typo';
import { Address } from './Address';
import { Helpdesk } from './Helpdesk';
import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import {
    PasswordStrengthIndicator,
    passwordToShort,
} from '../../../account/current-login/PasswordEditForm';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useEffect, useState } from 'react';

enum PasswordResetErrors {
    INVALID_TOKEN = 'INVALID_TOKEN',
    ACCOUNT_TERMINATED = 'ACCOUNT_TERMINATED',
    ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
    PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
}

type PasswordTokenError = {
    error: PasswordResetErrors;
    reactivationToken: string | null;
};

function ResetPasswordContent() {
    const token = useParams()['token'] ?? '';
    const [tokenValidation] = useServerFetch<
        null,
        { token: string },
        PasswordTokenError
    >(
        {
            request: () =>
                superagent.get('/ui-api/reset-password-token').query({ token }),
        },
        { token },
    );
    const navigate = useNavigate();

    useEffect(() => {
        const data = tokenValidation.data;
        if (data && data.error === PasswordResetErrors.ACCOUNT_TERMINATED) {
            navigate(
                `/reactivate-terminated-account/${data.reactivationToken}`,
            );
        }
    }, [tokenValidation.data]);

    switch (tokenValidation.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.PENDING:
            return <Spinner />;
        case RequestStatus.ERROR:
            return <ResetPasswordError request={tokenValidation} />;
        case RequestStatus.SUCCESS:
            return <ResetPasswordForm token={token} />;
    }
}

const PasswordCouldNotBeReset = () => {
    return (
        <Localized
            de="Das Passwort konnte nicht zurückgesetzt werden"
            fr="Le mot de passe n'a pas pu être réinitialisé"
            it="La password non può essere reimpostata"
            en="The password could not be reset"
        />
    );
};

const ResetPasswordError = (p: {
    request: ErrorState<{ error: PasswordResetErrors }>;
}) => {
    const navigate = useNavigate();

    return (
        <div
            className={css({
                ...Typo.body,
                maxWidth: '480px',
            })}
        >
            {p.request.data &&
            p.request.data.error === PasswordResetErrors.INVALID_TOKEN ? (
                <Localized
                    de='Dieser Zurücksetzungslink ist abgelaufen. Fordern Sie einen neuen Link über die "Passwort vergessen" Funktionalität an.'
                    fr="Le lien pour changer votre mot de passe n'est plus valide. Demander un nouveau lien avec la function 'Mot de passe oublié"
                    it="Il link per reimpostare la vostra password non è più valido. Richiedete un nuovo link tramite la funzione 'Password dimenticata"
                    en="The link to reset your password is no longer valid. Request a new link using the 'Forgotten password' function."
                />
            ) : (
                <PasswordCouldNotBeReset />
            )}
            <ButtonRow>
                <BasicButton
                    label={'Ok'}
                    negative={true}
                    onClick={() => navigate('/')}
                />
            </ButtonRow>
        </div>
    );
};

const ResetPasswordForm = (p: { token: string }) => {
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const navigate = useNavigate();
    const [submitPasswordState, submitPasswordRequest, submitPasswordReset] =
        useServerWrite<
            { token: string; password: string },
            null,
            PasswordTokenError
        >({
            req: args => superagent.post('/ui-api/reset-password').send(args),
        });
    useEffect(() => {
        if (submitPasswordState.status === RequestStatus.SUCCESS) {
            navigate('/');
        }
    }, [submitPasswordState.status]);

    const formIsValid =
        showErrorMessage && password2 && password1 !== password2;

    const submitPassword = () => {
        submitPasswordRequest({
            token: p.token,
            password: password1,
        });
    };

    const newPasswordToShort = passwordToShort(password1);

    return (
        <div className={css({ maxWidth: '480px' })}>
            <TextField
                context={InputContext.regular}
                value={password1}
                inputType="password"
                tabIndex={1}
                autoFocus={true}
                labelText={
                    <Localized
                        de="Neues Passwort"
                        fr="Nouveau mot de passe"
                        it="Nuova password"
                        en={'New password'}
                    />
                }
                onFocus={() => {
                    setShowErrorMessage(false);
                    submitPasswordReset();
                }}
                onBlur={() => setShowErrorMessage(true)}
                onChange={setPassword1}
                onEnter={submitPassword}
                errorText={
                    submitPasswordState.status === RequestStatus.ERROR ? (
                        <PasswordCouldNotBeReset />
                    ) : showErrorMessage && password1 ? (
                        newPasswordToShort
                    ) : (
                        ''
                    )
                }
            />
            <TextField
                context={InputContext.regular}
                value={password2}
                inputType="password"
                tabIndex={2}
                labelText={
                    <Localized
                        de="Passwort-Bestätigung"
                        fr="Confirmer le mot de passe"
                        it="Conferma password"
                        en={'Repeat password'}
                    />
                }
                errorText={
                    formIsValid ? (
                        <Localized
                            de="Passwörter stimmen nicht überein"
                            fr={'Mots de passe ne correspondent pas'}
                            it={'Le due password inserite non corrispondono'}
                            en={'Passwords do not match'}
                        />
                    ) : (
                        ''
                    )
                }
                onBlur={() => setShowErrorMessage(true)}
                onFocus={() => {
                    if (!newPasswordToShort) {
                        setShowErrorMessage(false);
                    }
                }}
                onChange={setPassword2}
                onEnter={submitPassword}
            />
            <PasswordStrengthIndicator password={password1} />
            <ButtonRow>
                <BasicButton
                    label={
                        <Localized
                            de="Speichern"
                            fr="Sauvegarder"
                            it="Salva"
                            en="Save"
                        />
                    }
                    negative={true}
                    disabled={
                        newPasswordToShort !== null ||
                        password1.length === 0 ||
                        password2.length === 0 ||
                        password1 !== password2 ||
                        submitPasswordState.status === RequestStatus.PENDING
                    }
                    onClick={submitPassword}
                />
            </ButtonRow>
        </div>
    );
};

export function ResetPasswordPage() {
    const { store } = useStore(() => null);
    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <ResetPasswordContent />
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}
