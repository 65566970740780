import { ParkDurationWheelTexts } from '../components/ParkDurationWheel';

export const parkDurationWheelTexts: ParkDurationWheelTexts = {
    endsAtTime: {
        de: 'Endet um',
        fr: 'Sera terminée à',
        it: 'Verrà terminata alle',
        en: 'Ends at',
    },
    endsAtDate: {
        de: 'Endet am',
        fr: 'Sera terminée le',
        it: 'Verrà terminata il',
        en: 'Ends at',
    },
    price: {
        de: 'Preis (in CHF)',
        fr: 'Prix (en CHF)',
        it: 'Prezzo (in CHF)',
        en: 'Price (in CHF)',
    },
    selectDuration: {
        de: 'Parkdauer auswählen',
        fr: 'Choisir la durée',
        it: 'Selezionare la durata',
        en: 'Select parking duration',
    },
    maxParkTime: {
        de: 'Höchstparkzeit',
        fr: 'Temps maximum',
        it: 'Tempo massimo',
        en: 'Max. time',
    },
};
