import { Updater } from 'dg-web-shared/lib/Flux';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';

export function triggerAccountBalanceSliceUpdate(update: Updater) {
    update(store => {
        const accountSlice = new AccountBalanceState.StateSlice(store);
        accountSlice.reset();
        return 'triggerAccountBalanceSliceUpdate';
    });
}
