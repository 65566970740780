import { Controller } from 'react-hook-form';
import {
    LicensePlateCountry,
    PermitPurchaseFormFields,
} from './QuickCheckoutPermittypeTypes';
import { useState } from 'react';
import { QuickCheckoutSlideIn } from '../common/QuickCheckoutSlideIn';
import {
    QuickCheckoutContent,
    QuickCheckoutHeader,
} from '../common/QuickCheckoutLayout';
import { Localized } from '../../common/components/Localized';

import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Typo } from '../../style/typo';
import { usePermittypeFormOutletContext } from './QuickCheckoutPermittypeForm';
import { useNavigate } from 'react-router-dom';
import { RadioElementQuickCheckout } from '../common/QuickCheckoutUiComponents';

export function QuickCheckoutPermittypeCountrySelectionSlideIn() {
    const { control, permittype } = usePermittypeFormOutletContext();
    return (
        <Controller
            name={PermitPurchaseFormFields.licensePlateCountry}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
                <CountrySelectionSlideIn
                    selectedLicensePlateCountry={value}
                    setSelectedLicensePlateCountry={onChange}
                    licensePlateCountries={permittype.licensePlateCountries}
                />
            )}
        />
    );
}

function CountrySelectionSlideIn({
    selectedLicensePlateCountry,
    setSelectedLicensePlateCountry,
    licensePlateCountries,
}: {
    selectedLicensePlateCountry: string;
    setSelectedLicensePlateCountry: (licensePlateCountry: string) => void;
    licensePlateCountries: LicensePlateCountry[];
}) {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(true);
    const mostUsed = licensePlateCountries.slice(0, 6);

    const sorted = licensePlateCountries
        .filter(n => !mostUsed.includes(n))
        .sort((a, b) => {
            return a.id.localeCompare(b.id);
        });
    return (
        <QuickCheckoutSlideIn open={isOpen} onClosed={() => navigate('..')}>
            <QuickCheckoutHeader onClose={() => setOpen(false)}>
                <Localized de="Land" fr="Pays" it="Nazione" en="Country" />
            </QuickCheckoutHeader>
            <QuickCheckoutContent padding={'x-only'}>
                <CountryGroupTitle>
                    <Localized
                        de="Nachbarländer"
                        fr="Pays voisins"
                        it="Nazioni limitrofi"
                        en="Neighboring countries"
                    />
                </CountryGroupTitle>
                {mostUsed.map(country => (
                    <RadioElementQuickCheckout
                        key={country.id}
                        toggled={country.id === selectedLicensePlateCountry}
                        iconElement={<CountryIcon>{country.id}</CountryIcon>}
                        onClick={() => {
                            setSelectedLicensePlateCountry(country.id);
                            setOpen(false);
                        }}
                    >
                        <Localized {...country.name} />
                    </RadioElementQuickCheckout>
                ))}
                <div className={css({ marginTop: '24px' })} />
                <CountryGroupTitle>
                    <Localized
                        de="Andere Länder"
                        fr="Autres pays"
                        it="Altre nazioni"
                        en="Other countries"
                    />
                </CountryGroupTitle>
                {sorted.map(country => (
                    <RadioElementQuickCheckout
                        key={country.id}
                        toggled={country.id === selectedLicensePlateCountry}
                        iconElement={<CountryIcon>{country.id}</CountryIcon>}
                        onClick={() => {
                            setSelectedLicensePlateCountry(country.id);
                            setOpen(false);
                        }}
                    >
                        <Localized {...country.name} />
                    </RadioElementQuickCheckout>
                ))}
            </QuickCheckoutContent>
        </QuickCheckoutSlideIn>
    );
}

export const CountryGroupTitle = styled.div({
    ...Typo.robotoBold,
    fontSize: '18px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});

export const CountryIcon = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 300,
});
