import * as Flux from 'dg-web-shared/lib/Flux';
import * as SignupState from '../../../signup/state/SignupState';

import * as LoginSlideinState from '../state/SignUpSlideinState';

export const closeSignupSlideIn = (store: Flux.Store) => {
    LoginSlideinState.SignUpSlideinState.reset(store);
    SignupState.Signup.reset(store);

    /* don't clear success, so we can hide registration box */
    if (!SignupState.SignupResponse.get(store).statusCode.cls.success) {
        SignupState.SignupResponse.reset(store);
    }

    return 'close-signup-slidein';
};
