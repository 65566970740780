import { css } from '@emotion/css';
import { useStore } from 'dg-web-shared/lib/Flux';
import { Localized } from '../../../common/components/Localized';
import * as TransactionState from '../../state/zone-transaction/TransactionState';
import { Colors } from 'dg-web-shared/ui/vars';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../../../account/root/components/PortalSlidein';
import { LabeledText } from '../../../../../tb-ui/typo/LabeledText';
import { InputContext } from '../../../../../tb-ui/inputs/InputContext';

type ZoneEnforcedInfoSlideInProps = {
    open: boolean;
    onClose: () => void;
    zoneId: number;
};

export const ZoneEnforcedInfoSlideIn = ({
    open,
    onClose,
    zoneId,
}: ZoneEnforcedInfoSlideInProps) => (
    <SlideIn
        headerColor="darkBlue"
        open={open}
        title={
            <Localized
                de="Zone-Details"
                fr="Détails zone"
                it="Dettagli zona"
                en="Zone details"
            />
        }
        onClose={onClose}
        zoneId={zoneId}
        portal={SlideInPortalId.PARK_CREATE}
    >
        <SlideInBody zoneId={zoneId} />
    </SlideIn>
);

const SlideIn = portalSlideIn<ZoneEnforcedInfoSlideInProps>(
    (p: ZoneEnforcedInfoSlideInProps) => (
        <div
            className={css({
                background: Colors.blue,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '10px 24px',
                overflowX: 'hidden',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
            })}
        >
            <SlideInBody zoneId={p.zoneId} />
        </div>
    ),
);

const SlideInBody = ({ zoneId }: { zoneId: number }) => {
    const { storeState } = useStore(store => ({
        zone: TransactionState.ParkTransaction.get(store, {
            permitZoneId: zoneId,
        }),
    }));

    return storeState.zone.data ? (
        <>
            <LabeledText
                context={InputContext.inverted}
                label={{
                    de: 'Nummer / Name',
                    fr: 'Numéro / nom',
                    it: 'Numero / nome',
                    en: 'Number / name',
                }}
            >
                {`${
                    storeState.zone.data.zoneCode
                        ? storeState.zone.data.zoneCode
                        : ''
                } ${storeState.zone.data.name}`}
            </LabeledText>
            <LabeledText
                context={InputContext.inverted}
                label={{
                    de: 'PLZ / Ort',
                    fr: 'NPA / Localité',
                    it: 'NAP / Località',
                    en: 'ZIP / City',
                }}
            >
                {`${storeState.zone.data.zipCode} ${storeState.zone.data.cityName}`}
            </LabeledText>
            <LabeledText
                label={{
                    de: 'Betreiber',
                    fr: 'Exploitant',
                    it: 'Gestore',
                    en: 'Operator',
                }}
                context={InputContext.inverted}
            >
                {storeState.zone.data.operatorName}
                {storeState.zone.data.operatorParkingpayContactDetails && (
                    <p
                        className={css({
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all',
                            fontWeight: 'bold',
                            marginBottom: 0,
                        })}
                    >
                        {storeState.zone.data.operatorParkingpayContactDetails}
                    </p>
                )}
            </LabeledText>
            <LabeledText
                label={{
                    de: 'Nachzahlen',
                    fr: 'Payer à nouveau',
                    it: 'Rinnovo del pagamento',
                    en: 'Payment renewal',
                }}
                context={InputContext.inverted}
            >
                {storeState.zone.data.additionalPaymentProhibited ? (
                    <Localized
                        de="Verboten"
                        fr="Interdit"
                        it="Proibito"
                        en="Prohibited"
                    />
                ) : (
                    <Localized
                        de="Erlaubt"
                        fr="Autorisé"
                        it="Consentito"
                        en="Allowed"
                    />
                )}
            </LabeledText>
        </>
    ) : null;
};
