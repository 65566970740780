import { DateTime } from 'luxon';
import * as superagent from 'superagent';
import {
    generateServerReadStateSlice,
    RequestStatus,
    ServerRequestState as SliceServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import { requestV2 } from '../../AsyncRequest';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    ServerRequestState,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    SavedCreditCardAliasResponse,
    SavedCreditCardAliasResponseTag,
} from 'dg-web-shared/model/PaymentAlias';

export namespace DeleteSavedCreditCardState {
    export type State = ServerRequestState<string, null>;
    export function use(onSuccess?: () => void) {
        const { update } = useStore(() => ({}));
        return useServerWrite<{}, string, null>({
            req: () =>
                superagent.del(
                    '/ui-api/customer-account/deposits/credit-card-alias',
                ),
            onResponse: s => {
                if (s.status === RequestStatus.SUCCESS) {
                    update(SavedCreditCardState.refetch);
                    onSuccess && onSuccess();
                }
            },
        });
    }
}

export namespace SavedCreditCardState {
    export type State = SliceServerRequestState<SavedCreditCardAliasResponse>;

    export const { get, setResponse, refetch, use } =
        generateServerReadStateSlice<SavedCreditCardAliasResponse>({
            key: 'top-up-balance-methods-get-saved-cc-alias-remote-request',
            sideEffects(store, state) {
                if (state.shouldFetch) {
                    store.update(
                        requestV2(
                            () =>
                                superagent.get(
                                    '/ui-api/customer-account/deposits/credit-card-alias',
                                ),
                            setResponse,
                        ),
                    );
                }
            },

            parseBody(body: any): SavedCreditCardAliasResponse {
                const result = JSON.parse(JSON.stringify(body));
                const tag = result.tag as SavedCreditCardAliasResponseTag;

                if (
                    tag === SavedCreditCardAliasResponseTag.FOUND &&
                    !!result.creditCardAlias.expiration
                ) {
                    const upperBoundTime = DateTime.fromISO(
                        result.creditCardAlias.expiration.upperBoundDateTime,
                    );
                    if (
                        typeof result.creditCardAlias.expiration.isExpired ===
                        'undefined'
                    ) {
                        result.creditCardAlias.expiration.isExpired =
                            upperBoundTime < DateTime.local();
                    }
                }

                return result as SavedCreditCardAliasResponse;
            },
        });
}
