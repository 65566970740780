import { css } from '@emotion/css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { localState } from 'dg-web-shared/lib/Flux';
import { Language, Translation, Translations } from 'dg-web-shared/lib/Text';
import { Typo } from '../../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { logAction } from '../../../utils/ActionLog';
import { portalSlideIn } from './PortalSlidein';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export const FAQSlideIn = portalSlideIn<Props>(FAQStatelessWrapper);

export function FAQStatelessWrapper(props: Props) {
    return <FAQ {...props} />;
}

const FAQ = localState<
    Props,
    { expandedQuestionId: keyof ContentTexts | null }
>({ expandedQuestionId: null }, props => (
    <div
        className={css({
            ...Typo.robotoLight,
            color: Colors.black,
            fontSize: '16px',
            lineHeight: '22px',
            padding: '0 24px 24px 24px',
            height: '100%',
        })}
    >
        {ids().map(id => (
            <AnsweredQuestion
                key={id}
                id={id}
                questionText={questionTexts()[props.language][id]()}
                answerText={answerTexts()[props.language][id]()}
                expanded={id === props.state.expandedQuestionId}
                onExpand={() => {
                    props.setState({ expandedQuestionId: id });
                }}
                onContract={() => {
                    props.setState({ expandedQuestionId: null });
                }}
            />
        ))}
    </div>
));

interface Props {
    language: Language;
}

function AnsweredQuestion(props: {
    id: string;
    questionText: string;
    answerText: string;
    expanded: boolean;
    onExpand: () => void;
    onContract: () => void;
}) {
    return (
        <div className={css({ marginTop: '20px' })}>
            <div
                className={css({
                    display: 'flex',
                    fontSize: 16,
                    padding: '0 8px',
                    alignItems: 'center',
                    color: props.expanded ? Colors.blue : Colors.black,
                    borderBottom: `1px solid ${
                        props.expanded ? Colors.blue : Colors.black
                    }`,
                    fontWeight: props.expanded
                        ? Typo.robotoMedium.fontWeight
                        : Typo.robotoLight.fontWeight,
                })}
                onClick={() => {
                    if (!props.expanded) {
                        logAction(null, 'open-faq-question', props.id);
                        props.onExpand();
                    } else {
                        props.onContract();
                    }
                }}
            >
                <div
                    className={css({
                        flex: '1',
                        textAlign: 'left',
                        marginBottom: '8px',
                    })}
                >
                    {props.questionText}
                </div>
                <div>
                    <Icon icon={props.expanded ? 'chevronUp' : 'chevronDown'} />
                </div>
            </div>
            {props.expanded && (
                <div
                    className={css({
                        padding: '16px',
                        background: Colors.background,
                        color: Colors.blue,
                    })}
                >
                    <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>
                        {props.answerText}
                    </ReactMarkdown>
                </div>
            )}
        </div>
    );
}

function ids(): (keyof ContentTexts)[] {
    return Object.keys(questionTexts().de) as (keyof ContentTexts)[];
}

function questionTexts(): Translations<ContentTexts> {
    return {
        de: {
            Vignette: () =>
                `Muss ich am Fahrzeug etwas anbringen (Kleber, Vignette, usw.)?`,
            PaymentOptions: () => 'Welche Zahlungsoptionen habe ich?',
            Badge: () =>
                'Wie kann ich einen Badge für Parkplätzen mit Schranken bestellen?',
            Tariff: () =>
                `Haben Sie ein Problem mit dem Tarif, der bei einem Parkvorgang angewendet wurde?`,
            Busse: () =>
                `Ich habe eine Parkbusse erhalten, die meiner Meinung nach nicht gerechtfertigt ist. Was kann ich tun?`,
            Kontrolleur: () =>
                `Woher weiß der Parkplatzkontrolleur, dass ich bezahlt habe?`,
            Restart: () =>
                `Darf ich nach dem Ende eines Parkvorganges sofort einen neuen starten?`,
            Parkdauer: () => `Wie lange darf ich parkieren?`,
            Kosten: () => `Entstehen für mich zusätzliche Kosten?`,
            ChangeableLicensePlate: () =>
                `Wie sollten zwei Fahrzeuge mit Wechselschild registriert werden?`,
            Support: () => `Weitere Fragen?`,
        },
        fr: {
            PaymentOptions: () =>
                'Quelles sont les possibilités de paiement dont je dispose?',
            Vignette: () =>
                `Dois-je attacher quelque chose au véhicule (autocollante, vignette, etc.)?`,
            Badge: () =>
                'Comment puis-je commander un badge pour les parkings avec barrières?',
            Tariff: () =>
                `Vous avez un problème avec le tarif appliqué à une procédure de stationnement?`,
            Busse: () =>
                `J'ai reçu une amende de stationnement qui, à mon avis, n'est pas justifiée. Que puis-je faire?`,
            Kontrolleur: () =>
                `Comment le contrôleur de parking sait-il que j'ai payé?`,
            Restart: () =>
                `Puis-je commencer un nouveau stationnement immédiatement après la fin du précédent?`,
            Parkdauer: () => `Combien de temps puis-je me garer?`,
            Kosten: () => `Est-ce que j'engagerai des frais supplémentaires?`,
            ChangeableLicensePlate: () =>
                `Comment faut-il enregistrer deux véhicules avec plaque d'immatriculation interchangeable?`,
            Support: () => `Plus de questions?`,
        },
        it: {
            PaymentOptions: () => 'Che opzioni di pagamento ho a disposizione?',
            Vignette: () =>
                `Devo esporre qualcosa nel veicolo (adesivo, vignetta, ecc.)?`,
            Badge: () =>
                'Come posso ordinare un badge per i parcheggi con barriera?',
            Tariff: () =>
                `Ha un problema con la tariffa applicata ad una procedura di parcheggio?`,
            Busse: () =>
                `Ho ricevuto una contravvenzione di parcheggio che, a mio avviso, non è giustificata. Cosa posso fare?`,
            Kontrolleur: () =>
                `Il controllore del parcheggio come fa a sapere che ho pagato?`,
            Restart: () =>
                `Posso avviare una nuova procedura, subito dopo la fine di quella precedente?`,
            Parkdauer: () => `Quanto tempo posso parcheggiare?`,
            Kosten: () => `Dovrò sostenere dei costi aggiuntivi?`,
            ChangeableLicensePlate: () =>
                `Come vanno registrati due veicoli con targa trasferibile?`,
            Support: () => `Altre domande?`,
        },
        en: {
            PaymentOptions: () => 'What payment options do I have?',
            Vignette: () =>
                `Do I have to expose something in the vehicle (sticker, label, etc.)?`,
            Badge: () => 'How can I order a badge for car parks with barriers?',
            Tariff: () =>
                `Do you have a problem with the tariff applied to a park transaction?`,
            Busse: () =>
                `I received a fine that I believe is not justified. What can I do?`,
            Kontrolleur: () =>
                `How does the enforcement agent know that I have paid?`,
            Restart: () =>
                `Can I start a new parking transaction immediately after the end of the previous one?`,
            Parkdauer: () => `How long can I park?`,
            Kosten: () => `Are there any additional costs for me?`,
            ChangeableLicensePlate: () =>
                `How should two vehicles with changeable plate be registered?`,
            Support: () => `Further questions?`,
        },
    };
}

function answerTexts(): Translations<ContentTexts> {
    return {
        de: {
            PaymentOptions: () =>
                `Sie können die einzelne Parkvorgänge und Bewilligungen direkt auf diese Zahlungsmittel belasten:<br/><p>
                    - TWINT<br/>
                    - Mastercard<br/>
                    - Visa<br/>
                    - Amex<br/>
                    - Postfinance Card<br/></p><p>
                    Sie haben auch die Möglichkeit, die Vorauszahlung zu nutzen; Zusätzlich zu den oben erwähnten Zahlungsmitteln können Sie Ihr Konto auch mit Bank-/Postüberweisung, Einzahlungsschein oder Gutschein aufladen.</p><p>
                    Auch das Lastschrift (nur IBAN aus der Schweiz oder Liechtenstein) und die Bezahlung gegen Rechnung (nur Firmen) stehen Ihnen zur Verfügung; die Parkgebühren werden während des ganzen Monats auf dem Parkingpay-Konto verrechnet; zu Beginn des Folgemonats wird der fällige Gesamtbetrag automatisch Ihrem Bankkonto belastet bzw. in Rechnung gestellt.</p>`,
            Vignette: () =>
                `Nein, dies ist nicht nötig. Die Kontrolle wird direkt durch das Lesen des Kennzeichens sichergestellt.`,
            Badge: () =>
                '<p>Überprüfen Sie vorher unter KONTO > KONTO/ADRESSE ob Ihre Adresse stimmt.</p><p>Danach können Sie ein Badge unter KONTO > FAHRZEUGE > gewünschtes Fahrzeug > BADGE HINZUFÜGEN > BADGE BESTELLEN bestellen.</p>',
            Tariff: () => `<p>
                Der für den Parkingpay-Parkvorgang angewendete Tarif entspricht dem, was auf der Parkuhr angegeben ist, aber trotzdem finden Sie ihn zu hoch oder nicht konform.
                </p><p>
                In diesem Fall wenden Sie sich bitte direkt an den Betreiber des betreffenden Parkplatzes.
                Es liegt in der alleinigen Verantwortung des Parkhausbetreibers, zu entscheiden, ob eine Gebühr geändert wird, und Parkingpay hat keinen Einfluss auf diesen Prozess.
                </p><p>
                Sollte es stattdessen zu einer Diskrepanz zwischen der Anzeige der Parkuhr und der von Parkingpay erhobenen Parkgebühr kommen, informieren Sie bitte unseren Helpdesk.
                </p>`,
            Busse: () =>
                `<p>Melden Sie sich bitte ausschliesslich an die auf dem Bussenbeleg aufgeführte Stelle. Sie wird den bezahlten Parkvorgang überprüfen und über eine allfällige Aufhebung der Busse entscheiden.</p><p>Parkingpay kann eine Busse nicht aufheben.</p>`,
            Kontrolleur: () =>
                `Der Kontrolleur verfügt über ein Kontrollgerät, welches online mit unserem System verbunden ist. Mit diesem Gerät wird das Kennzeichen des Fahrzeuges gescannt. So wird festgestellt, ob die Parkgebühr bezahlt wurde.`,
            Restart: () =>
                `Nein. Nach dem Ende eines Parkvorganges muss das Fahrzeug wieder in Verkehr gesetzt werden.`,
            Parkdauer: () =>
                `Je nach Parkplatz ist die maximale Parkdauer unterschiedlich und wird sowohl auf der Parkuhr wie auch in der App ausgewiesen.`,
            Kosten: () =>
                `<p>
                In der Regel <b>gelten dieselben Tarife</b> wie bei der
                Parkuhr/an der Kasse.
                </p>
                <p>
                    Bei entsprechendem und ausdrücklichem Hinweis in der
                    App, werden Zuschläge bei den Parkplätzen einzelner
                    Betreiber und/oder für die Parkingpay Zusatzfunktionen
                    erhoben.
                </p>
                <p>Zusatzfunktionen:</p>
                <p>
                    <ul>
                        <li>
                            <b>Einzelbelastung der Zahlungsmittel</b>
                            <br />
                            (CHF 0.25 pro Zahlung)
                        </li>
                        <li>
                            <b>Ein-/Ausfahren mit Kennzeichenerkennung</b>
                            <br />
                            (CHF 0.25 pro kostenpflichtigen Parkvorgang)
                        </li>
                        <li>
                            <b>Firmenkonti auf Rechnung</b>
                            <br />
                            (CHF 18.50 bzw. 22.50 pro Rechnung)
                        </li>
                    </ul>
                </p>
                <p>Die Zusatzfunktionen sind standardmäßig inaktiv.</p>`,
            ChangeableLicensePlate: () =>
                `<p>Wenn Sie zwei Fahrzeuge mit Wechselschild haben, registrieren Sie sie einfach einmal in Ihrem Parkingpay-Konto und fügen Sie eine Beschreibung hinzu, die beide Fahrzeuge identifiziert: z.B. «VW Passat & BMW i3».</p>
                <p>Falls Sie einen Badge für Parkplätze mit Schranken haben, denken Sie daran, diesen bei der Verlegung des Kennzeichens mitzunehmen.</p>`,
            Support: () => `
Helpdesk<br/>
<info@parkingpay.ch><br/>
0848 330 555<br/>
<!--<InfoBox>Aufgrund technischer Probleme können wir Ihre Anrufe derzeit nicht beantworten. Bitte kontaktieren Sie uns per E-Mail. Wir danken Ihnen für Ihr Verständnis.</InfoBox>-->

Digitalparking AG<br/>
Rütistrasse 13<br/>
8952 Schlieren<br/>
`,
        },
        fr: {
            PaymentOptions: () =>
                `Vous pouvez débiter les procédures et les autorisations de stationnement individuelles directement sur ces moyens de paiement:<br/><p>
                - TWINT<br/>
                - Mastercard<br/>
                - Visa<br/>
                - Amex<br/>
                - Postfinance Card<br/></p><p>
                Vous avez également la possibilité d'utiliser le prépaiement; en plus des moyens de paiement mentionnés ci-dessus, vous pouvez également alimenter votre compte par virement bancaire/postal, bulletin de versement ou bon.</p><p>
                Aussi le prélèvement automatique (seulement IBAN en Suisse ou au Liechtenstein) et le paiement sur facture (seulement entreprises) sont à votre disposition; les taxes de stationnement sont comptabilisés pendant tout le mois sur le compte Parkingpay. Au début du mois suivant, le montant total dû sera débité sur votre compte bancaire ou facturé.</p>`,
            Vignette: () =>
                `Non, ce n'est pas nécessaire. Le contrôle est assuré directement en lisant la plaque d'immatriculation.`,
            Badge: () =>
                `<p>Vérifiez à l'avance sous COMPTE > COMPTE/ADRESSE si votre adresse est correcte.</p><p>Ensuite vous pouvez commander le badge sous COMPTE > VEHICULES > véhicule désiré > AJOUTER UN BADGE > COMMANDER UN NOUVEAU BADGE.</p>`,
            Tariff: () =>
                `<p>
                Le tarif appliqué à la procédure de stationnement Parkingpay correspond à ce qui est indiqué sur le parcmètre, mais vous le trouvez néanmoins trop élevé ou non conforme.
                </p><p>
                Dans ce cas, veuillez contacter directement l'exploitant du parking en question.
                Il appartient à l'exploitant du parking de décider si une tarif est à modifiée et Parkingpay n'a aucune influence sur ce processus.
                </p><p>
                Si, au contraire, il y a une différence entre l'affichage du parcmètre et le prix du stationnement facturé par Parkingpay, veuillez en informer notre helpdesk.
                </p>`,
            Busse: () =>
                `<p>Nous vous prions de vous adresser exclusivement à l'instance indiquée sur l'amende. Elle vérifiera le payement du stationnement et décidera d'une éventuelle annulation de l'amende.</p><p>Parkingpay ne peut pas annuler une amende.</p>`,
            Kontrolleur: () =>
                `Le contrôleur dispose d'un dispositif de contrôle connecté en ligne à notre système. Avec cet appareil, la plaque d'immatriculation du véhicule est scannée. Ceci permet de déterminer si les frais de stationnement ont été payés.`,
            Restart: () =>
                `Non. Après la fin du stationnement, le véhicule doit être remis en circulation.`,
            Parkdauer: () =>
                `La durée maximale de stationnement  varie selon le parking et est indiquée sur le parcmètre et dans l'appli.`,
            Kosten: () =>
                `<p>
                En règle générale, <b>les tarifs sont les mêmes</b>
                qu'au parcmètre/à la caisse.
                </p>
                <p>
                    Avec une indication appropriée et explicite dans
                    l'appli, des suppléments sont prélevés sur les places de
                    stationnement de quelques exploitants et/ou pour
                    l'utilisation des fonctions additionnelles Parkingpay.
                </p>
                <p>Fonctions additionnelles:</p>
                <p>
                    <ul>
                        <li>
                            <b>débit individuel des moyens de paiement</b>
                            <br />
                            (CHF 0.25 par paiement)
                        </li>
                        <li>
                            <b>
                                entrer/sortir avec reconnaissance des
                                plaques
                            </b>
                            <br />
                            (CHF 0.25 par procédure payant)
                        </li>
                        <li>
                            <b>comptes entreprises sur facture</b>
                            <br />
                            (CHF 18.50 resp. 22.50 par facture)
                        </li>
                    </ul>
                </p>
                <p>
                    Les fonctions additionnelles sont inactives par défaut.
                </p>`,
            ChangeableLicensePlate: () =>
                `<p>Si vous avez deux véhicules avec plaque d'immatriculation interchangeable, il suffit de les enregistrer une fois dans votre compte Parkingpay et d'ajouter une description qui les identifie tous les deux: par exemple «VW Passat & BMW i3»..</p>
                <p>Lors du changement de la plaque d'immatriculation, si vous avez un badge pour le parking avec barrière, pensez à le prendre avec vous.</p>`,
            Support: () => `
Helpdesk<br/>
<info@parkingpay.ch><br/>
0848 330 555<br/>
<!--En raison de problèmes techniques, nous ne sommes actuellement pas en mesure de répondre à vos appels téléphoniques; veuillez nous contacter par e-mail. Nous vous remercions de votre compréhension.-->

Digitalparking SA<br/>
Rütistrasse 13<br/>
8952 Schlieren<br/>
`,
        },
        it: {
            PaymentOptions: () =>
                `Può addebitare le procedure di parcheggio e le autorizzazioni direttamente su questi mezzi di pagamento:<br/><p>
                - TWINT<br/>
                - Mastercard<br/>
                - Visa<br/>
                - Amex<br/>
                - Postfinance Card<br/></p><p>
                Ha anche la possibilità di utilizzare il prepagamento; oltre ai mezzi di pagamento di cui sopra, può anche ricaricare il suo conto tramite bonifico bancario/postale, polizza di versamento o buono.</p><p>
                Anche l'addebito diretto (solo IBAN in Svizzera o nel Liechtenstein) e il pagamento contro fattura (solo aziende) sono a sua disposizione; le tasse di parcheggio vengono conteggiate durante tutto il mese sul conto Parkingpay; all'inizio del mese seguente l'importo totale dovuto viene addebitato sul suo conto bancario, risp. fatturato.</p>`,
            Vignette: () =>
                `No, non è necessario. Il controllo viene effettuato direttamente tramite la lettura del numero di targa.`,
            Badge: () =>
                `<p>Controllate preventivamente sotto CONTO > CONTO/INDIRIZZO se il vostro indirizzo è corretto.</p><p>In seguito ordinare il badge sotto CONTO > VEICOLI > veicolo desiderato > AGGIUNGI BADGE > ORDINA NUOVO BADGE.</p>`,
            Tariff: () =>
                `<p>
                La tariffa applicata alla procedura di parcheggio Parkingpay corrisponde a quanto riportato sul parchimetro, ma ciononostante lei trova che sia troppo alta oppure non conforme.
                </p><p>
                In questo caso la invitiamo a rivolgersi direttamente al gestore del parcheggio in questione.
                Una decisione sulla modifica di una tariffa spetta unicamente al gestore e Parkingpay non ha nessun influsso su questo processo.
                </p><p>
                Se al contrario ci fossero delle discrepanze tra l'indicazione del parchimetro e la tariffa applicata da Parkingpay, allora la preghiamo comunicarlo al nostro Helpdesk.
                </p>`,
            Busse: () =>
                `<p>La preghiamo di contattare unicamente l'ufficio riportato sulla contravvenzione stessa.Quest'ultimo controllerà il pagamento della tassa di parcheggio e deciderà sull'annullamento della contravvenzione.</p><p>Parkingpay non può annullare una contravvenzione.</p>`,
            Kontrolleur: () =>
                `Il controllore dispone di un dispositivo collegato online al nostro sistema. Con questo dispositivo viene scansionata la targa del veicolo. Questo determinerà se la tassa di parcheggio è stata pagata.`,
            Restart: () =>
                `No. Dopo la fine della procedura, il veicolo deve essere rimesso in circolazione.`,
            Parkdauer: () =>
                `Il tempo massimo di sosta varia a seconda del parcheggio ed è indicato sia sul parchimetro che nell'app.`,
            Kosten: () =>
                `<p>
                Di norma si applicano <b>le stesse tariffe </b>previste
                al parchimetro/alla cassa.
                </p>
                <p>
                    Laddove espressamente indicato nell'app, sono previsti
                    dei supplementi sui parcheggi di alcuni gestori e/o per
                    l'utilizzo delle funzioni aggiuntive Parkingpay.
                </p>
                <p>Funzioni aggiuntive:</p>
                <p>
                    <ul>
                        <li>
                            <b>
                                addebito individuale sul mezzo di pagamento
                            </b>
                            <br />
                            (CHF 0.25 per pagamento)
                        </li>
                        <li>
                            <b>
                                entrata/uscita con riconoscimento della
                                targa
                            </b>
                            <br />
                            (CHF 0.25 per procedura a pagamento)
                        </li>
                        <li>
                            <b>conti aziendali contro fattura</b>
                            <br />
                            (CHF 18.50 risp. 22.50 per fattura)
                        </li>
                    </ul>
                </p>
                <p>Le funzioni aggiuntive sono inattive per default.</p>`,
            ChangeableLicensePlate: () =>
                `<p>Se ha due veicoli con targa trasferibile è sufficiente registrarli una sola volta nel conto Parkingpay, aggiungendo una descrizione che li identifichi entrambi: p.es. «VW Passat & BMW i3».</p>
                <p>Quando trasferisce la targa, se ha un badge per i parcheggi con barriera, si ricordi di prenderlo con lei.</p>`,
            Support: () => `
Helpdesk<br/>
<info@parkingpay.ch><br/>
0848 330 555<br/>
<!--A causa di problemi tecnici, attualmente non siamo in grado di rispondere alle vostre chiamate telefoniche; vogliate contattarci per e-mail. Vi ringraziamo per la comprensione.-->

Digitalparking AG<br/>
Rütistrasse 13<br/>
8952 Schlieren<br/>
`,
        },
        en: {
            PaymentOptions: () =>
                `You can debit the individual parking transactions and permits directly to these means of payment:<br/><p>
                - TWINT<br/>
                - Mastercard<br/>
                - Visa<br/>
                - Amex<br/>
                - Postfinance Card<br/></p><p>
                You also have the option to use the prepayment mode; in addition to the payment methods mentioned above, you can also top up your account by bank/postal transfer, payment slip or voucher.</p><p>
                Direct debit (only IBAN from Switzerland or Liechtenstein) and invoice payment (only companies) are also available; parking fees are charged to your Parkingpay account throughout the month; at the beginning of the following month, the total amount due is debited to your bank account resp. is invoiced.</p>`,
            Vignette: () =>
                `No, this is not necessary. The control is ensured directly reading the license plate.`,
            Badge: () =>
                `<p>Check in advance if your address is still correct: goto ACCOUNT > ACCOUNT/ADDESS and verify it.</p><p>Subsequently goto ACCOUNT > VEHICLES > desired vehicle > ADD BADGE > ORDER NEW BADGE to order a badge.</p>`,
            Tariff: () =>
                `<p>
                The tariff applied to the Parkingpay parking procedure corresponds to what is stated on the parking meter, but nevertheless you find it too high or not compliant.
                </p><p>
                In this case, please contact the operator of the car park in question directly.
                It is the sole responsibility of the car park operator to decide whether a fee is changed and Parkingpay has no influence on this process.
                </p><p>
                If, on the contrary, there is a discrepancy between the parking meter display and the parking fee charged by Parkingpay, please inform our Helpdesk.
                </p>`,
            Busse: () =>
                `<p>Please contact the office listed there. It will check the payment of the parking and decide on any cancellation of the fine.</p><p>Parkingpay can not cancel a fine.</p>`,
            Kontrolleur: () =>
                `The enforcement agent has got a device, which is connected online to our system. With this device, the license plate of the vehicle is scanned. This will determine if the parking fee has been paid.`,
            Restart: () =>
                `No. After the end of a parking transaction, the vehicle must be put back into traffic.`,
            Parkdauer: () =>
                `The maximum parking time varies according to the parking lot and is indicated both on the parking meter and in the app.`,
            Kosten: () =>
                `<p>
                As a rule, <b>same tariffs apply</b> the for the parking
                meter/cash register.
                </p>
                <p>
                    Where explicitly indicated in the app, surcharges are
                    applicable on the parking spaces of specific operators
                    and/or for the use of the Parkingpay additional
                    functions.
                </p>
                <p>Additional functions:</p>
                <p>
                    <ul>
                        <li>
                            <b>
                                individual charges on the means of payment
                            </b>
                            <br />
                            (CHF 0.25 per payment)
                        </li>
                        <li>
                            <b>entry/exit with license plate recognition</b>
                            <br />
                            (CHF 0.25 per paid procedure)
                        </li>
                        <li>
                            <b>company accounts with invoice</b>
                            <br />
                            (CHF 18.50 resp. 22.50 per invoice)
                        </li>
                    </ul>
                </p>
                <p>Additional functions are inactive by default.</p>`,
            ChangeableLicensePlate: () =>
                `<p>If you have two vehicles with changeable plate, simply register them once in your Parkingpay account and add a description that identifies them both: e.g. «VW Passat & BMW i3».</p>
                <p>When transferring the number plate, if you have a badge for barrier parking, remember to take it with you.</p>`,
            Support: () => `
Helpdesk<br/>
<info@parkingpay.ch><br/>
0848 330 555<br/>
<!--Due to technical problems, we are currently unable to answer your telephone calls; please contact us by e-mail. We thank you for your understanding.-->

Digitalparking AG<br/>
Rütistrasse 13<br/>
8952 Schlieren<br/>
`,
        },
    };
}

interface ContentTexts {
    Vignette: Translation;
    Busse: Translation;
    Kontrolleur: Translation;
    Restart: Translation;
    Parkdauer: Translation;
    Kosten: Translation;
    Support: Translation;
    Badge: Translation;
    PaymentOptions: Translation;
    Tariff: Translation;
    ChangeableLicensePlate: Translation;
}
