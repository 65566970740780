import { ReactNode, useEffect } from 'react';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2';
import { Language } from 'dg-web-shared/lib/Text';
import { InputContext } from '../../../../../tb-ui/inputs/InputContext';
import { SingleSelection } from '../../../../../tb-ui/inputs/SingleSelection';
import { Vehicles } from '../../../account/vehicles/VehicleState';
import { ShippingAddress } from '../../../api/Http';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as LicensePlateState from '../../../common/state/LicensePlateState';
import * as SettingsState from '../../../common/state/SettingsState';
import * as ParkCreateTexts from '../../i18n/ParkCreateTexts';
import * as EntitySelectionState from '../../state/EntitySelectionState';
import { LicensePlate } from '../../state/shared/EntityData';
import { ParkCreateAddVehicleState, ParkCreateMode } from '../ParkCreate';
import { ZoneEnforcedCheckinResponsive } from '../zone-transaction/ZoneEnforcedCheckinResponsive';
import { LicensePlateEntityComp as LicensePlateRepresentation } from './Entities';
import { EntitySelectionDropin } from './EntitySelectionDropin';
import {
    ImmediateSubsequentParkingNotification,
    isParkTransactionRunning,
    wouldItBeImmediateSubsequentParking,
} from './ImmediateSubsequentParking';
import { NeedsClearance, WrongType } from './InfoBoxes';
import * as ParkOptionListState from '../../state/ParkOptionListState';
import { ArrowPosition, InlineAlertBox } from '../../../ui/modals/Confirmable';
import { useTransactionList } from '../../../transactions-list/TransactionsListContext';

interface Props {
    language: Language;
    licensePlates: LicensePlate[];
    selectedId: Maybe<number>;
    update: Flux.Updater;
    address: Maybe<LicensePlateState.SentAddress>;
    shippingAddress: ShippingAddress;
    operatorName: string;
    operatorContactDetails: string | null;
    additionalPaymentProhibited: boolean;
    children: ReactNode;
}

type InfosOrNextElementProps = { children?: React.ReactNode } & Props & {
        update: Flux.Updater;
    };

const InfosOrNextElement = (p: InfosOrNextElementProps) => {
    const texts = ParkCreateTexts.licensePlateTexts[p.language];

    if (p.licensePlates.length === 0) {
        return (
            <InlineAlertBox
                arrowPosition={ArrowPosition.left}
                titleCaption={texts.NoPlatesTitle()}
            >
                {texts.NoPlatesBody()}
            </InlineAlertBox>
        );
    }

    const selectedLp: Maybe<LicensePlate> = p.licensePlates.filter(
        lp => !!lp.id && lp.id === p.selectedId,
    )[0];

    if (!selectedLp) {
        return null;
    }

    if (selectedLp.mode === 'noClearance') {
        return (
            <NeedsClearance
                lps={[selectedLp]}
                operatorName={p.operatorName}
                operatorContactDetails={p.operatorContactDetails}
                permit={false}
            />
        );
    }
    if (selectedLp.mode === 'typeNotAllowed') {
        return (
            <WrongType
                language={p.language}
                lps={[selectedLp]}
                permit={false}
            />
        );
    }

    if (p.children) {
        return <>{p.children}</>;
    } else {
        return null;
    }
};

export const LicensePlateSelectionTransaction = Flux.selectState<
    Props,
    {
        settings: SettingsState.State;
        vehicles: Vehicles.State;
        allState: Flux.Store;
    }
>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        vehicles: Vehicles.get(store),
        allState: store,
    }),

    Flux.localState(
        {
            ignoreImmediateSubsequentParkingWarning: false,
            showLicensePlateSelection: false,
        },

        p => {
            useEffect(() => {
                p.setState({
                    showLicensePlateSelection: p.licensePlates.length > 1,
                });
            }, [p.licensePlates.length]);
            const [transactionList] = useTransactionList();

            const texts = ParkCreateTexts.licensePlateTexts[p.language];
            const selectedLp = p.licensePlates.filter(
                lp => lp.id === p.selectedId,
            )[0];

            const selection =
                p.selectedId && selectedLp ? (
                    <LicensePlateRepresentation licensePlate={selectedLp} />
                ) : null;

            const warnOfImmediateParking =
                p.additionalPaymentProhibited &&
                !!selectedLp &&
                !isParkTransactionRunning(
                    selectedLp.id,
                    transactionList.data?.active,
                ) &&
                !!selectedLp.previousSignificantParkEnd &&
                wouldItBeImmediateSubsequentParking(
                    selectedLp.previousSignificantParkEnd,
                ) &&
                !p.state.ignoreImmediateSubsequentParkingWarning;

            return (
                <>
                    <SingleSelection
                        onClick={
                            p.licensePlates.length > 0
                                ? () => {
                                      p.setState({
                                          showLicensePlateSelection: true,
                                      });
                                  }
                                : () =>
                                      p.update(
                                          ParkCreateAddVehicleState.stateWrite,
                                          {
                                              addVehicle: true,
                                          },
                                      )
                        }
                        selection={selection}
                        context={InputContext.inverted}
                        labelText={texts.Vehicle()}
                        compactHeight={ZoneEnforcedCheckinResponsive.level4}
                        superCompactHeight={
                            ZoneEnforcedCheckinResponsive.level2
                        }
                    />
                    {warnOfImmediateParking && (
                        <ImmediateSubsequentParkingNotification
                            language={p.language}
                            previousSignificantParkEnd={
                                selectedLp.previousSignificantParkEnd!
                            }
                            onContinue={() =>
                                p.setState({
                                    ignoreImmediateSubsequentParkingWarning:
                                        true,
                                })
                            }
                            onCancel={() =>
                                p.update(
                                    ParkOptionListState.Selection.setVariant,
                                    null,
                                )
                            }
                        />
                    )}

                    {!warnOfImmediateParking && <InfosOrNextElement {...p} />}

                    <LicensePlateSelectionTransactionDropin
                        open={p.state.showLicensePlateSelection}
                        onClose={() => {
                            p.setState({
                                showLicensePlateSelection: false,
                            });
                        }}
                    />
                </>
            );
        },
    ),
);

function LicensePlateSelectionTransactionDropin(props: {
    open: boolean;
    onClose: () => void;
}) {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        licensePlates: new LicensePlateState.StateSlice(s).state,
        licensePlateSelectionTransaction:
            EntitySelectionState.Selection.Transaction.get(s),
        currentLogin: CurrentLoginState.get(s),
    }));

    const selectLicensePlateForTransaction = (
        store: Flux.Store,
        id: number,
    ) => {
        props.onClose();

        EntitySelectionState.Selection.Transaction.writeSelectedLicenseplate(
            store,
            id,
        );

        ParkCreateAddVehicleState.reset(store);
        return 'selectLicensePlateForTransaction';
    };

    return (
        <EntitySelectionDropin
            mode={ParkCreateMode.transaction}
            language={storeState.settings.language}
            entities={storeState.licensePlates.data}
            update={update}
            onClose={() =>
                update(store => {
                    props.onClose();
                    ParkCreateAddVehicleState.reset(store);
                    return 'Entit-Selection-CLose';
                })
            }
            onSelect={(id: number) =>
                update(selectLicensePlateForTransaction, id)
            }
            open={props.open}
            selectedEntityIds={thenElse(
                storeState.licensePlateSelectionTransaction
                    .selectedLicensePlate,
                id => [id],
                [],
            )}
            selectionMode="single"
            disableUnselected={false}
            canAddNewEntities={
                storeState.currentLogin.data
                    ? storeState.currentLogin.data.loginRole ===
                      CurrentLoginState.LoginRole.ADMIN
                    : false
            }
        />
    );
}
