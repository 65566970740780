import Text = require('../../common/i18n/Text');
import { Translation } from '../../common/i18n/Text';

interface AccountSetupAddBadgeTexts {
    badgeTypeLabel: Translation;
    badgeTypeParkingPay: Translation;
    badgeTypeHSGcard: Translation;
    badgeNrLabel: Translation;
    orderBadgeLabel: Translation;
    useExistingBadgeLabel: Translation;
    parkingpayBadgeInfo1: Translation;
    parkingpayBadgeInfo2: Translation;

    badgeNrIsVignette: Translation;
    badgeNrIsAssigned: Translation;
    badgeNrInvalid: Translation;
}

export const addBadgeTexts: Text.Translations<AccountSetupAddBadgeTexts> = {
    de: {
        badgeTypeLabel: () => 'Badge-Typ',
        badgeTypeParkingPay: () => 'Parkingpay-Badge',
        badgeTypeHSGcard: () => 'HSGcard',
        badgeNrLabel: () => 'Badge-Nummer',
        orderBadgeLabel: () => 'Ich bestelle kostenlos einen Badge',
        useExistingBadgeLabel: () => 'Ich habe bereits einen Badge',
        parkingpayBadgeInfo1: () =>
            'Der Badge wird innert 3 Arbeitstagen kostenlos zugestellt.',
        parkingpayBadgeInfo2: () =>
            'Weitere Badges können Sie nach der Registrierung im Konto erfassen/bestellen.',
        badgeNrIsVignette: () => 'Dies ist eine Vignetten-Nummer',
        badgeNrIsAssigned: () =>
            'Dieser Badge kann nicht erfasst werden. Bitte kontaktieren Sie unseren Helpdesk (info@parkingpay.ch).',
        badgeNrInvalid: () => 'Ungültige Badge-Nummer',
    },
    fr: {
        badgeTypeLabel: () => 'Typ de badge',
        badgeTypeParkingPay: () => 'Badge Parkingpay',
        badgeTypeHSGcard: () => 'HSGcard',
        badgeNrLabel: () => 'No. Badge',
        orderBadgeLabel: () => 'Je commande gratuitement un badge',
        useExistingBadgeLabel: () => "J'ai déjà un badge",
        parkingpayBadgeInfo1: () =>
            'Le badge sera livré gratuitement dans les 3 jours ouvrables.',
        parkingpayBadgeInfo2: () =>
            'Vous pouvez saisir/commander d’autres badges dans votre compte.',
        badgeNrIsVignette: () => "Ce numéro est d'une vignette",
        badgeNrIsAssigned: () =>
            'Ce badge ne peut pas être enregistré. Veuillez contacter notre helpdesk (info@parkingpay.ch).',
        badgeNrInvalid: () => 'Numéro de badge invalide',
    },
    it: {
        badgeTypeLabel: () => 'Tipo di badge',
        badgeTypeParkingPay: () => 'Badge Parkingpay',
        badgeTypeHSGcard: () => 'HSGcard',
        badgeNrLabel: () => 'Nr. badge',
        orderBadgeLabel: () => 'Ordino gratuitamente un badge',
        useExistingBadgeLabel: () => 'Ho già un badge',
        parkingpayBadgeInfo1: () =>
            'Il badge vi verrà inviato gratuitamente per posta nei prossimi 3 giorni lavorativi.',
        parkingpayBadgeInfo2: () =>
            'Una volta terminata la registrazione potrete inserire/ordinare dei badge supplementari direttamente nel vostro conto.',
        badgeNrIsVignette: () => 'Questo numero è di una vignetta',
        badgeNrIsAssigned: () =>
            'Questo badge non può essere registrato. Voglia contattare il nostro helpdesk (info@parkingpay.ch).',
        badgeNrInvalid: () => 'Numero di badge non valido',
    },
    en: {
        badgeTypeLabel: () => 'Badge type',
        badgeTypeParkingPay: () => 'Parkingpay Badge',
        badgeTypeHSGcard: () => 'HSGcard',
        badgeNrLabel: () => 'Badge nr.',
        orderBadgeLabel: () => 'I order a free badge',
        useExistingBadgeLabel: () => 'I already have a badge.',
        parkingpayBadgeInfo1: () =>
            'The badge will be sent to you free of charge by post within the next 3 working days.',
        parkingpayBadgeInfo2: () =>
            'Once registration is complete, you can insert/order additional badges directly into your account.',
        badgeNrIsVignette: () => 'This number is from a vignette',
        badgeNrIsAssigned: () =>
            'This badge cannot be registered. Please contact our helpdesk (info@parkingpay.ch).',
        badgeNrInvalid: () => 'Invalid badge number',
    },
};
