import { css } from '@emotion/css';

import { JsxMessage, Message } from '../../../lib/Localized';
import { WeekDay, weekdayTexts } from '../translations/WeekdayTexts';
import {
    IntradayValidityScheduleEntry,
    IntradayValidityWeekProfile,
} from '../../../model/IntradayValidityWeekProfile';

export function IntradayValidityWeekProfileDisplay({
    weekProfile,
    localizedComponent: Localized,
}: {
    weekProfile: IntradayValidityWeekProfile;
    localizedComponent: React.ComponentType<Message | JsxMessage>;
}) {
    const configs = Object.keys(weekProfile).map(k => {
        return { day: k, profile: JSON.stringify((weekProfile as any)[k]) };
    });

    const groupedConfigs = configs.reduce(function (r, a) {
        r[a.profile] = r[a.profile] || [];
        r[a.profile].push(a.day);
        return r;
    }, Object.create(null));

    const numberOfConfigs = Object.keys(groupedConfigs).length;

    if (numberOfConfigs === 1) {
        const entries: IntradayValidityScheduleEntry[] = JSON.parse(
            Object.keys(groupedConfigs)[0],
        );
        return (
            <>
                <Localized
                    de="Jeden Tag"
                    fr="Tous les jours"
                    it="Tutti i giorni"
                    en="Everyday"
                />
                <ScheduleEntriesDisplay
                    entries={entries}
                    everyday={true}
                    localizedComponent={Localized}
                />
            </>
        );
    } else {
        const configKeys = Object.keys(groupedConfigs);
        return (
            <div className={css({ marginBottom: '10px' })}>
                {configKeys.map(key => {
                    const days: WeekDay[] = groupedConfigs[key];
                    return (
                        <>
                            <div className={css({ paddingTop: '5px' })}>
                                <Localized {...weekdayTexts(days)} />
                            </div>
                            <ScheduleEntriesDisplay
                                entries={JSON.parse(key)}
                                localizedComponent={Localized}
                            />
                        </>
                    );
                })}
            </div>
        );
    }
}

function ScheduleEntriesDisplay({
    entries,
    everyday,
    localizedComponent: Localized,
}: {
    entries: IntradayValidityScheduleEntry[];
    everyday?: boolean;
    localizedComponent: React.ComponentType<Message | JsxMessage>;
}) {
    if (entries.length === 0) {
        return (
            <div className={css({ paddingLeft: '16px', fontStyle: 'italic' })}>
                <Localized
                    de="nicht gültig"
                    fr="pas valable"
                    it="non valida"
                    en="not valid"
                />
            </div>
        );
    }

    if (
        entries.length === 2 &&
        everyday &&
        entries[0].start === '00:00' &&
        (entries[1].end === '23:59' || entries[1].end === '24:00')
    ) {
        return (
            <div className={css({ paddingLeft: '16px' })}>
                <div>
                    {entries[1].start} - {entries[0].end}
                </div>
            </div>
        );
    }

    return (
        <div className={css({ paddingLeft: '16px' })}>
            {entries.map(function (value) {
                return (
                    <div key={'times-' + entries.indexOf(value)}>
                        {value.start} - {value.end}
                    </div>
                );
            })}
        </div>
    );
}
