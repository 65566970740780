import { QuickCheckoutContent } from '../common/QuickCheckoutLayout';
import {
    DeepLinkErrorType,
    DeepLinkPermitScanErrorDescription,
    DeepLinkPermitScanErrorTitle,
} from '../../park-create/components/DeepLinkConverter';
import styled from '@emotion/styled';
import { Typo } from '../../style/typo';
import { css } from '@emotion/css';
import { AppBlocking, PestControl } from '@mui/icons-material';

import { Localized } from '../../common/components/Localized';

export function PermittypeServiceNotAvailable() {
    return (
        <QuickCheckoutContent centered>
            <PestControlIcon />
            <ErrorStateTitle>
                <Localized
                    de="Technischer Fehler"
                    fr="Erreur technique"
                    it="Errore tecnico"
                    en="Technical error"
                />
            </ErrorStateTitle>
            <ErrorStateText>
                <Localized
                    de="Die Bewilligung, die der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht, kann momentan nicht gekauft werden. Bitte versuchen Sie es später noch einmal oder laden Sie als Alternative die Parkingpay-App herunter."
                    fr="L'autorisation correspondant à l'adresse URL saisie ou au code QR scanné ne peut pas être achetée pour le moment. Veuillez réessayer plus tard ou téléchargez l'app Parkingpay comme alternative."
                    it="L'autorizzazione corrispondente all’indirizzo URL inserito risp. al codice QR scansionato non può essere acquistata al momento. Riprova più tardi o scarica l'app Parkingpay come alternativa."
                    en="The permit corresponding to the entered URL address or the scanned QR code cannot be purchased at the moment. Please try again later or download the Parkingpay app as an alternative."
                />
            </ErrorStateText>
        </QuickCheckoutContent>
    );
}

export function PermittypeNotFound() {
    return (
        <QuickCheckoutContent centered>
            <PestControlIcon />
            <ErrorStateTitle>
                <DeepLinkPermitScanErrorTitle
                    errorType={DeepLinkErrorType.NOT_FOUND}
                />
            </ErrorStateTitle>
            <ErrorStateText>
                <DeepLinkPermitScanErrorDescription
                    errorType={DeepLinkErrorType.NOT_FOUND}
                    isParkingpay={false}
                />
            </ErrorStateText>
        </QuickCheckoutContent>
    );
}

export function PermittypeNotActive() {
    return (
        <QuickCheckoutContent centered>
            <AppBlockingIcon />
            <ErrorStateTitle>
                <DeepLinkPermitScanErrorTitle
                    errorType={DeepLinkErrorType.NOT_ACTIVE}
                />
            </ErrorStateTitle>
            <ErrorStateText>
                <DeepLinkPermitScanErrorDescription
                    errorType={DeepLinkErrorType.NOT_ACTIVE}
                    isParkingpay={false}
                />
            </ErrorStateText>
        </QuickCheckoutContent>
    );
}

export function PermittypeNotAllowed() {
    return (
        <QuickCheckoutContent centered>
            <AppBlockingIcon />
            <ErrorStateTitle>
                <DeepLinkPermitScanErrorTitle
                    errorType={DeepLinkErrorType.NOT_ALLOWED}
                />
            </ErrorStateTitle>
            <ErrorStateText>
                <DeepLinkPermitScanErrorDescription
                    errorType={DeepLinkErrorType.NOT_ALLOWED}
                    isParkingpay={false}
                />
            </ErrorStateText>
        </QuickCheckoutContent>
    );
}

const ErrorStateTitle = styled.div({
    textAlign: 'center',
    marginBottom: '16px',
    ...Typo.robotoBold,
});

const ErrorStateText = styled.div({
    textAlign: 'center',
    maxWidth: '375px',
    ...Typo.robotoRegular,
});

function PestControlIcon() {
    return (
        <div className={css({ fontSize: '120px', marginBottom: '36px' })}>
            <PestControl fontSize="inherit" />
        </div>
    );
}

function AppBlockingIcon() {
    return (
        <div className={css({ fontSize: '120px', marginBottom: '48px' })}>
            <AppBlocking fontSize="inherit" />
        </div>
    );
}
