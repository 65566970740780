import * as Flux from 'dg-web-shared/lib/Flux';
import { languages } from '../../layout/i18n/Languages';
import * as SettingsState from '../../common/state/SettingsState';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { setLanguageToAllStores } from '../../common/actions/I18nActions';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { css } from '@emotion/css';
import { Typo } from '../../../../tb-ui/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import React from 'react';

interface LanguageSwitchProps {}
interface LanguageSwitchState {
    settings: SettingsState.State;
}
interface LanguageSwitchReactState {
    open: boolean;
}

type ReactClassProps = Flux.SelectStateProps<
    LanguageSwitchProps,
    LanguageSwitchState
>;

export const setLanguage = (store: Flux.Store, language: string) => {
    setLanguageToAllStores(store, language);
    LocalStorage.Settings.setUiLanguage(language);
    return 'setLanguage';
};

export const LanguageSwitch = Flux.selectState<
    LanguageSwitchProps,
    LanguageSwitchState
>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
    }),
    class LanguageSwitchReact extends React.Component<
        ReactClassProps,
        LanguageSwitchReactState
    > {
        constructor(props: ReactClassProps) {
            super(props);
            this.state = { open: false };
        }

        renderLanguages(): React.ReactNode[] {
            return languages.map(language => {
                const isCurrent =
                    this.props.settings.language === language.shortcut;
                return this.state.open || isCurrent ? (
                    <Clickable
                        element="div"
                        className={css({
                            ...Typo.caption,
                            width: '40px',
                            lineHeight: '32px',
                            textAlign: 'center',
                            color: Colors.rgba(
                                Colors.action_b,
                                isCurrent ? 1 : 0.6,
                            ),
                        })}
                        onClick={
                            this.state.open
                                ? () => {
                                      this.props.update(
                                          setLanguage,
                                          language.shortcut,
                                      );
                                      this.setState({ open: false });
                                  }
                                : () => this.setState({ open: true })
                        }
                        key={language.shortcut}
                    >
                        {language.shortcut.toLocaleUpperCase()}
                    </Clickable>
                ) : null;
            });
        }

        render() {
            return (
                <div
                    className={css({
                        display: 'flex',
                        height: '32px',
                        background: Colors.blue,
                        borderRadius: '2px',
                    })}
                >
                    {this.renderLanguages()}
                </div>
            );
        }
    },
);
