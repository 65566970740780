import {
    InvoiceRequestFormSlideIn,
    InvoiceRequestPayload,
} from './InvoiceRequestForm';
import { formStyle } from '../PaymentMethodMenu';
import { SlideInPortalId } from '../../root/components/PortalSlidein';
import { RemoteRequestDoneSlideIn } from '../RemoteRequestDone';
import { DateTime } from 'luxon';

import { useState } from 'react';
import { Updater, useStore } from 'dg-web-shared/lib/Flux';
import { PaymentMethodEnum } from '../../../api/Http';
import { PaymentMethodStatus } from '../PaymentMethodStatus';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import * as SettingsState from '../../../common/state/SettingsState';
import { languageFromString } from 'dg-web-shared/lib/Text';
import { paymentMethodTexts } from '../PaymentMethodTexts';
import {
    BlockStatus,
    PaymentMethodItemBlock,
} from '../../root/components/ItemBlock';
import { logAction } from '../../../utils/ActionLog';
import {
    RequestMethod,
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import { Localized } from '../../../common/components/Localized';

enum ActiveSlideIn {
    INVOICE_REQUEST = 'INVOICE_REQUEST',
    INVOICE_REQUEST_DONE = 'INVOICE_REQUEST_DONE',
    INVOICE_REQUEST_REVIEW = 'INVOICE_REQUEST_REVIEW',
}

const activeSlideInForStatus = (status: PaymentMethodStatus) => {
    switch (status) {
        case PaymentMethodStatus.INACTIVE:
            return ActiveSlideIn.INVOICE_REQUEST;

        case PaymentMethodStatus.REQUESTED:
            return ActiveSlideIn.INVOICE_REQUEST_REVIEW;

        default:
            // if we're active the slide-in won't open but we're still getting
            // called as the slide-in has to be mounted...
            return ActiveSlideIn.INVOICE_REQUEST_REVIEW;
    }
};

export function Invoice(props: { status: PaymentMethodStatus }) {
    const { store, update } = useStore(() => null);
    const [open, setOpen] = useState(false);
    return (
        <>
            <PaymentMethodItemBlock
                title={
                    <Localized
                        de="auf Rechnung"
                        fr="Sur facture"
                        it="Contro fattura"
                        en="Invoice"
                    />
                }
                status={
                    props.status === PaymentMethodStatus.ACTIVE
                        ? BlockStatus.ACTIVE
                        : props.status === PaymentMethodStatus.REQUESTED
                          ? BlockStatus.REQUESTED
                          : BlockStatus.INACTIVE
                }
                onClick={
                    props.status !== PaymentMethodStatus.ACTIVE
                        ? () => {
                              logAction(store, 'open-invoice-slidein');
                              setOpen(true);
                          }
                        : null
                }
                key={`INVOICE`}
            />
            <InvoiceSlideIns
                status={props.status}
                backToList={() => setOpen(false)}
                open={open}
                update={update}
            />
        </>
    );
}

const COMPANY_UID_VALIDATION_ERROR = 'COMPANY_UID_INVALID';

export const InvoiceSlideIns = (props: {
    status: PaymentMethodStatus;
    backToList: () => void;
    open: boolean;
    update: Updater;
}) => {
    const { storeState, store } = useStore(s => ({
        paymentType: new PaymentTypeState.StateSlice(s).state,
        settings: new SettingsState.StateSlice(s).state,
    }));
    const [activeSlideIn, setActiveSlideIn] = useState(
        activeSlideInForStatus(props.status),
    );
    const [requestInvoiceState, fetchRequestInvoice] = useServerWrite<
        {
            activePaymentType: PaymentMethodEnum;
        } & InvoiceRequestPayload,
        unknown,
        { error?: string }
    >(() => ({
        url: `/ui-api/customer-account/payment-type`,
        method: RequestMethod.PUT,
    }));
    const [showCompanyUidError, setShowCompanyUidError] = useState(false);
    const isPending = requestInvoiceState.status === RequestStatus.PENDING;
    useServerSuccessEffect(requestInvoiceState, () => {
        new PaymentTypeState.StateSlice(store).reset();
        new AccountBalanceState.StateSlice(store).reset();
        setActiveSlideIn(ActiveSlideIn.INVOICE_REQUEST_DONE);
    });
    useServerErrorEffect(requestInvoiceState, (_, res) => {
        if (res?.error === COMPANY_UID_VALIDATION_ERROR) {
            setShowCompanyUidError(true);
        }
    });

    const activeSlideInIs = (wanted: ActiveSlideIn) =>
        props.open && activeSlideIn === wanted;

    const language = languageFromString(storeState.settings.language);
    const t = paymentMethodTexts[language];

    return (
        <>
            <InvoiceRequestFormSlideIn
                onCancel={() => {
                    setShowCompanyUidError(false);
                    props.backToList();
                }}
                onClose={() => {
                    setShowCompanyUidError(false);
                    props.backToList();
                }}
                onSubmit={(args: InvoiceRequestPayload) => {
                    logAction(store, 'request-invoice', args);
                    fetchRequestInvoice({
                        activePaymentType: PaymentMethodEnum.INVOICE,
                        ...args,
                    });
                    setShowCompanyUidError(false);
                }}
                isPending={isPending}
                showCompanyUidError={showCompanyUidError}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.INVOICE_REQUEST)}
                title={t.ApplyForInvoice()}
                contentCssClass={formStyle}
            />

            <RemoteRequestDoneSlideIn
                onClose={props.backToList}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.INVOICE_REQUEST_DONE)}
                title={t.invoiceRequested()}
            >
                <p>{t.invoiceJustSubmitted()}</p>
                <p>{t.invoiceDurationTime()}</p>
                <p>{t.invoiceConfirmation()}</p>
            </RemoteRequestDoneSlideIn>

            <RemoteRequestDoneSlideIn
                onClose={props.backToList}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.INVOICE_REQUEST_REVIEW)}
                title={t.invoiceRequested()}
            >
                <p>
                    {t.invoiceSubmittedEarlier(
                        storeState.paymentType.data.paymentMethodChangeRequest
                            ?.paymentMethodRequestTimestamp ||
                            DateTime.fromISO('1970-01-01', {
                                locale: 'de-CH',
                            }),
                    )}
                </p>
                <p>{t.invoiceDurationTime()}</p>
                <p>{t.invoiceConfirmation()}</p>
            </RemoteRequestDoneSlideIn>
        </>
    );
};
