import 'core-js/stable';

import {
    App,
    INVALID_SCAN_PATH,
    TWINT_QR_CODE_SCAN_PATH,
} from './app/components/App';
import * as Sentry from './utils/Sentry';
import * as fluxInstance from './fluxInstance';
import * as Update from './utils/Update';
import { Store } from 'dg-web-shared/lib/Flux';
import { GeolocationState } from './common/state/GeolocationState';
import { logAction } from './utils/ActionLog';
import { FCMTokenState } from './common/state/FCMTokenState';
import { createRoot } from 'react-dom/client';
import { Base64 } from './utils/Base64';
import React from 'react';

require('inobounce');
Sentry.init();

/*
let's not use RUM for the moment, as this might cause issues in the app
datadogRum.init({
    applicationId: 'c7df0a35-9577-435e-afeb-27f2c61de1f8',
    clientToken: 'pubbd3dde716870ebd9e4ef873fb1f3cf87',
    site: 'datadoghq.com',
    service: 'Parkingpay',
    env: envIsProduction() ? 'prod' : 'test',
    sampleRate: 2,
    trackInteractions: true,
});
*/

const enum NativeDeviceMessageType {
    location = 'location',
    locationError = 'locationError',
    FCMToken = 'FCMToken',
    DatatransCallback = 'DatatransCallback',
    TwintCallback = 'TwintCallback',
    TicketCallback = 'TicketCallback',
    PushNotificationCallback = 'PushNotificationCallback',
    QRScannerCallback = 'QRScannerCallback',
}

type MessageFromNativeDeviceCallback = (
    type: NativeDeviceMessageType,
    message: string,
) => void;

type WindowWithBridge = {
    messageFromNativeDevice: MessageFromNativeDeviceCallback;
} & Window &
    typeof globalThis;

(<WindowWithBridge>window).messageFromNativeDevice = (
    type: NativeDeviceMessageType,
    message: string,
) => {
    fluxInstance.flux.update((store: Store): string => {
        switch (type) {
            case NativeDeviceMessageType.location:
                return GeolocationState.setCoordinatesIfChanged(
                    store,
                    JSON.parse(message),
                );
            case NativeDeviceMessageType.locationError:
                return GeolocationState.setError(store);
            case NativeDeviceMessageType.FCMToken:
                return FCMTokenState.stateWrite(store, JSON.parse(message));
            case NativeDeviceMessageType.DatatransCallback: {
                // deprecated
                console.error('Tried to call deprecated DatatransCallback');
                return 'ok';
            }
            case NativeDeviceMessageType.TwintCallback: {
                // deprecated
                console.error('Tried to call deprecated TwintCallback');
                return 'ok';
            }
            case NativeDeviceMessageType.PushNotificationCallback:
                store.update(store => {
                    logAction(store, 'receive-push-callback');
                    return 'receive-push-callback';
                });
                return 'ok';
            case NativeDeviceMessageType.TicketCallback:
                logAction(store, 'receive-ticket-callback', message);
                window.location.href = `/ticket/${message}`;
                return 'ok';
            case NativeDeviceMessageType.QRScannerCallback: {
                const qrCodeString = extractTwintQrCode(message);
                if (qrCodeString) {
                    logAction(store, 'scan-twint-qr', {
                        qrCodeString: qrCodeString,
                    });
                    window.location.href = `/${TWINT_QR_CODE_SCAN_PATH}/${Base64.encodeURLSafe(
                        qrCodeString,
                    )}`;
                    return 'ok';
                }

                let url: URL;
                try {
                    url = new URL(message);
                } catch (_) {
                    logAction(store, 'scan-unknown', { scan: message });
                    window.location.href = '/' + INVALID_SCAN_PATH;
                    return 'ok';
                }

                const allowedPathPatterns = [
                    {
                        pattern: /^\/z\/[A-Z2-7]+$/,
                        action: 'scan-zone-url',
                    },
                    {
                        pattern: /^\/p\/[A-Z2-7]+$/,
                        action: 'scan-permit-url',
                    },
                    {
                        pattern: /^\/t\/[A-Za-z0-9-_=]+$/,
                        action: 'scan-ticket-url',
                    },
                ];

                const match = allowedPathPatterns.find(
                    ({ pattern }) =>
                        isValidHost(url.host) &&
                        url.pathname.match(pattern) != null,
                );

                if (match) {
                    const indexIdentification =
                        url.pathname.lastIndexOf('/') + 1;
                    logAction(store, match.action, {
                        redirectUrl: message,
                        identification:
                            url.pathname.substring(indexIdentification),
                    });
                    window.location.href = url.pathname + url.search;
                } else {
                    logAction(store, 'scan-unknown', {
                        redirectUrl: message,
                    });
                    window.location.href = '/' + INVALID_SCAN_PATH;
                }
                return 'ok';
            }
            default:
                return 'unknownMessageFromDevice';
        }
    });
};

Update.init(fluxInstance.flux);

const container = document.getElementById('app-container');
const root = createRoot(container!);
root.render(
    React.createElement(App, {
        allState: fluxInstance.flux,
    }),
);

if (module.hot) {
    module.hot.accept();
}

logAction(fluxInstance.flux, 'app-loaded', window.location.search);

function isValidHost(host: string) {
    const prodValidHostsPattern = /^(www\.)?parkingpay\.ch$/;
    if (window.location.host.match(prodValidHostsPattern) != null) {
        return host.match(prodValidHostsPattern) !== null;
    } else {
        return (
            host.match(
                /^(www\.)?parkingpay\.ch$|^demo\.parkingpay\.ch$|^customer\.test\.digitalparking\.ch$|^customer\.([\w-]+)\.digitalparking\.dev$/,
            ) != null
        );
    }
}

const TWINT_ZURICH_SPECIAL_URL = 'https://www.twint.ch/qr_scan?twint=02:';

function extractTwintQrCode(message: unknown): string | null {
    if (typeof message != 'string') {
        return null;
    } else if (message.startsWith('02:')) {
        return message;
    } else if (message.startsWith(TWINT_ZURICH_SPECIAL_URL)) {
        return message.slice(TWINT_ZURICH_SPECIAL_URL.length - 3);
    } else {
        return null;
    }
}
