import { useStore } from 'dg-web-shared/lib/Flux';
import {
    AddTenantFormProps,
    ConfirmPage,
    CustomerInfo,
    FormLogic,
} from './ConfirmTenantShared';

function LandLordHeader() {
    return (
        <>
            <h2>Jährliche Einreichung erforderlich</h2>
            <div>
                In Kenntnis dieser Bestimmungen erklärt die nachfolgende
                Person/Firma (Verwaltung) das Folgende:
                <ol>
                    <li>
                        Es stehen derzeit keine privaten Parkplätze für die
                        Bewohnerinnen und Bewohner der eigenen Liegenschaft zur
                        Verfügung.
                    </li>
                    <li>
                        Es sind derzeit sämtliche privaten Parkplätze für
                        Bewohnerinnen und Bewohner rechtmässig belegt.
                    </li>
                </ol>
            </div>
            <h2>
                Angaben der Grundeigentümerin oder Grundeigentümer / Vermieterin
                oder Vermieter (Immobilienverwaltungen)
            </h2>
        </>
    );
}

function LucerneForm({ info, refetch }: AddTenantFormProps) {
    return (
        <div>
            <h1>Formular Nachweispflicht</h1>
            <LandLordHeader />

            <FormLogic info={info} refetch={refetch} />
            <CustomerInfo customer={info.customer} />

            <LegalInfoBox />
        </div>
    );
}

function LegalInfoBox() {
    return (
        <>
            <h2>
                In Kenntnis dieser Bestimmungen erklärt die zuständige
                Person/Firma (Verwaltung) Folgendes:
            </h2>
            <ol>
                <li>
                    Es stehen derzeit keine privaten Parkplätze für die
                    Bewohnerinnen und Bewohner zur Verfügung.
                </li>
                <li>
                    Es sind derzeit sämtliche privaten Parkplätze für
                    Bewohnerinnen und Bewohner rechtmässig belegt.
                </li>
            </ol>
            <p>
                Die einreichende Person ist zur Abgabe vorliegender Bestätigung
                berechtigt. Mit der Bestätigung wird festgehalten, dass alle
                Angaben wahrheitsgemäss gemacht wurden.
            </p>
            <p>
                Wer die Berechtigung zum Bezug einer Bewilligung mit unwahren
                Angaben belegt oder diese missbräuchlich verwendet, wird gemäss
                Art. 14 Abs. 1 Parkkartenreglement mit einer Busse bestraft. Die
                Strafbestimmungen kantonaler oder eidgenössischer Erlasse
                bleiben vorbehalten (insb. Art. 251 StGB).
            </p>
            <div>
                <p>
                    Folgende Rechtsbestimmung sind im Parkkartenreglement vom
                    01.09.2021 wurden festgehalten:
                </p>

                <div>
                    <b>
                        Art. 9 <sup>4</sup> Parkkarten mit Bezugsbedingungen
                    </b>
                    <p>
                        <sup>1</sup> Parkkarten für die Einzelzonen A–U werden
                        ausschliesslich für Anwohnerinnen und Anwohner, für
                        Geschäftsbetriebe sowie für andere gleichermassen
                        Berechtigte ausgestellt.
                    </p>
                    <p>
                        <sup>2</sup> Anwohnerinnen und Anwohner können
                        Parkkarten für die Einzelzonen A–U sowie die Einzelzone
                        Z mit Berechtigungsnachweis nur beziehen, wenn ihnen in
                        der Liegenschaft, in der sie wohnen, kein privater
                        Parkplatz zur Verfügung steht.
                    </p>
                </div>

                <div>
                    <b>
                        Art. 11 <sup>6</sup> Parkkartenbezug
                    </b>
                    <p>
                        <sup>1</sup> Die Parkkarte wird auf Gesuch hin
                        abgegeben, sofern die Voraussetzungen gemäss diesem
                        Reglement gegeben sind und die Bezahlung der Parkkarte
                        erfolgt ist.
                    </p>
                    <p>
                        <sup>2</sup>Es ist Sache der gesuchstellenden Person,
                        die Berechtigung mit geeigneten Mitteln nachzuweisen.
                        Sie hat dabei vollständige und wahre Angaben zu machen.
                    </p>
                    <p>
                        <sup>3</sup>Der Nachweis des nicht zur Verfügung
                        stehenden privaten Parkplatzes gemäss Art. 9 Abs. 2 wird
                        mittels Formular erbracht. Darin bestätigt die
                        Grundeigentümer- oder Vermieterschaft, dass keine
                        privaten Parkplätze für die Bewohnerinnen und Bewohner
                        zur Verfügung stehen und sämtliche privaten Parkplätze
                        für Bewohnerinnen und Bewohner rechtmässig belegt sind.
                    </p>
                </div>
            </div>
        </>
    );
}

export function LucernConfirmTenantPage() {
    const { store } = useStore(() => null);

    return <ConfirmPage allState={store} FormComponent={LucerneForm} />;
}
