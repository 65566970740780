import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../../common/components/FullWidthBottomButton';

export function ProceedButton(props: {
    disabled: boolean;
    onClick: () => void;
}) {
    return (
        <FullWidthBottomButton
            color={FullWidthBottomButtonColor.BLUE}
            disabled={props.disabled}
            onClick={props.onClick}
            label={{
                de: 'Weiter',
                fr: 'Continuer',
                it: 'Avanti',
                en: 'Proceed',
            }}
        />
    );
}
