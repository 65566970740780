export const enum TicketRoutes {
    automaticPaymentDeactivated = 'automatic-payment-deactivated',
    alreadyClaimed = 'already-claimed',
    paymentDisabled = 'not-available',
    notFound = 'not-found',
    limitReached = 'limit-reached',
    unknownError = 'unknown-error',
    activationFailed = 'activation-failed',
    topupActivationSuccessful = 'topup-activation-successful',
    aliasActivationSuccessful = 'alias-activation-successful',
    directActivationSuccessful = 'direct-activation-successful',
}
