/* tslint:enable:no-unused-variable */

import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { Helpdesk } from './Helpdesk';
import { Address } from './Address';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useStore } from 'dg-web-shared/lib/Flux';

export function ReceiptPage() {
    const { hash } = useParams();
    useEffect(() => {
        if (hash) {
            window.location.assign(`/ui-api/receipt/pdf/${hash}`);
        }
    }, [hash]);

    const { store } = useStore(() => null);

    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}></LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}
