import * as Http from '../../api/Http';

export const languages: Http.Language[] = [
    {
        shortcut: 'de',
        name: {
            de: 'Deutsch',
            fr: 'Allemand',
            it: 'Tedesco',
            en: 'German',
        },
    },
    {
        shortcut: 'fr',
        name: {
            de: 'Französisch',
            fr: 'Français',
            it: 'Francese',
            en: 'French',
        },
    },
    {
        shortcut: 'it',
        name: {
            de: 'Italienisch',
            fr: 'Italien',
            it: 'Italiano',
            en: 'Italian',
        },
    },
    {
        shortcut: 'en',
        name: {
            de: 'Englisch',
            fr: 'Anglais',
            it: 'Inglese',
            en: 'English',
        },
    },
];
