import { AuthZonePermitPurchaseRequest } from '../../../api/PermitHttp';
import { PaymentCategory } from '../../../common/payment/Payment';

export enum PermitPurchaseResponseType {
    COMPLETE = 'COMPLETE',
    REDIRECT = 'REDIRECT',
}

export type PermitPurchaseResponse = Complete | Redirect;

export type Complete = {
    type: PermitPurchaseResponseType.COMPLETE;
    contractId: number;
};

export type Redirect = {
    type: PermitPurchaseResponseType.REDIRECT;
    redirectUri: string;
};

export interface PermitPurchaseRequest {
    permitRequest: AuthZonePermitPurchaseRequest;
    paymentCategory: PaymentCategory | null;
    amount: number | null;
    baseUri: string;
    deviceUUID: string;
    language: string;
    useExistingAlias: boolean;
}
