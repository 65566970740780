import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import { CustomerAccountType } from '../../../api/CustomerAccountType';
import * as Http from '../../../api/Http';
import * as AsyncRequest from '../../../AsyncRequest';
import * as ServerStateSlice from '../../../common/state/ServerStateSliceV2';

export interface AccountMeta {
    customerNr: number;
    trackingId: string;
    depositEsrReferenceNr: string;
    correspondenceLanguageId: string;
    refNo: string;
    customerAccountType: CustomerAccountType;
    address: Http.Address;
    shippingAddress: Http.ShippingAddress;
    registrationComplete: boolean;
    wasInDebtCollection: boolean;
    nonAdminTransactionsVisible: boolean;
    hasInvoices: boolean;
    lprSurcharge: boolean;
    betaFeaturesEnabled: boolean;
}

export type State = ServerStateSlice.State<AccountMeta>;

function enforceEmptyString(str: string | null | undefined): string {
    return str || '';
}

export function receiveAccountMeta(): Flux.Write {
    return AsyncRequest.request(
        Http.getAccountMetaData(),
        (store: Flux.Store, res: Response): void => {
            new StateSlice(store).writeRequest(res);
        },
    );
}

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<AccountMeta> {
    key(): string {
        return 'common-MetaServerState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(receiveAccountMeta);
        }
    }
    parseBody(body: AccountMeta): AccountMeta {
        const address = body.address;
        address.company1 = enforceEmptyString(address.company1);
        address.company2 = enforceEmptyString(address.company2);
        const shippingAddress = body.shippingAddress;
        shippingAddress.line1 = enforceEmptyString(shippingAddress.line1);
        shippingAddress.line2 = enforceEmptyString(shippingAddress.line2);
        shippingAddress.countryId = enforceEmptyString(
            shippingAddress.countryId,
        );
        shippingAddress.city = enforceEmptyString(shippingAddress.city);
        shippingAddress.zipCode = enforceEmptyString(shippingAddress.zipCode);

        body.refNo = enforceEmptyString(body.refNo);
        return body;
    }
    getEmptyData(): AccountMeta {
        return {
            customerNr: 0,
            trackingId: '',
            depositEsrReferenceNr: '',
            correspondenceLanguageId: '',
            customerAccountType: CustomerAccountType.PRIVATE,
            refNo: '',
            address: {
                gender: 0,
                company1: '',
                company2: '',
                firstName: '',
                lastName: '',
                street1: '',
                zipCode: '',
                city: '',
                countryId: '',
            },
            shippingAddress: {
                line1: '',
                line2: '',
                zipCode: '',
                city: '',
                countryId: 'CH',
                invoiceOnly: false,
            },
            registrationComplete: false,
            wasInDebtCollection: false,
            nonAdminTransactionsVisible: false,
            hasInvoices: false,
            lprSurcharge: true,
            betaFeaturesEnabled: false,
        };
    }
}
