import { useStore } from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../common/state/SettingsState';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { EditFormSlideIn } from './EditForm';
import { EditFormState } from './EditFormState';
import * as LoginsState from './state/LoginsState';
import { UnderlineStatus } from '../../../../tb-ui/inputs/internal/Underline';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { GenderSelect } from '../../../../tb-ui/inputs/GenderSelect';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export function GenderEditFormSlideIn(props: {
    portal: SlideInPortalId;
    title: React.ReactNode;
    open: boolean;
    login: LoginsState.GetLoginData | null;
    onClose: () => void;
}) {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        edit: new EditFormState.StateSlice(s).state,
        legacyUpdater: s.legacyUpdater,
    }));

    return (
        <EditFormSlideIn
            isSaveable={
                !!storeState.edit.gender &&
                !!props.login &&
                props.login.gender !== storeState.edit.gender
            }
            {...props}
        >
            {props.login ? (
                <GenderSelect
                    gender={storeState.edit.gender || props.login.gender}
                    underlineStatus={UnderlineStatus.none}
                    errorText={''}
                    styleContext={InputContext.form}
                    writeGender={(gender: 'm' | 'f') =>
                        storeState.legacyUpdater(
                            EditFormState.writeGender,
                            gender,
                        )
                    }
                />
            ) : (
                <Spinner />
            )}
        </EditFormSlideIn>
    );
}
