import * as Http from '../../api/Http';
import * as LanguagesState from '../state/LanguagesState';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import * as AsyncRequest from '../../AsyncRequest';
import * as LicensePlateState from '../state/LicensePlateState';
import { SavedLicensePlate } from '../state/LicensePlateState';
import * as SettingsState from '../state/SettingsState';
import * as AddressCountriesState from '../state/AddressCountriesState';
import * as LicensePlateCountriesState from '../state/LicensePlateCountriesState';
import * as AccountBalanceState from '../state/AccountBalanceState';
import * as PaymentTypeState from '../state/PaymentTypeState';
import { isUndefined } from 'dg-web-shared/lib/MaybeV2';
import * as EntitySelectionState from '../../park-create/state/EntitySelectionState';

export const verifyLicensePlateSelection = (
    store: Flux.Store,
    savedLps: SavedLicensePlate[],
): void => {
    // if we have recently created a license plate, we set it as selected... no matter what.
    // context is fixed to park-create here because it is the relevant state to consider.
    // this auto selection logic only applies to transactions anyway.
    // TOOD: Add this logic again?

    // we verify if the selected lp is still available, if not, we reset the selection
    const selectedId =
        EntitySelectionState.Selection.Transaction.get(
            store,
        ).selectedLicensePlate;
    if (
        isUndefined(selectedId) ||
        savedLps.filter(lp => lp.id === selectedId).length === 0
    ) {
        if (savedLps.length === 1) {
            EntitySelectionState.Selection.Transaction.writeSelectedLicenseplate(
                store,
                savedLps[0].id,
            );
        } else {
            EntitySelectionState.Selection.Transaction.writeSelectedLicenseplate(
                store,
                null,
            );
        }
    }
};

export function receiveLicensePlates(): Flux.Write {
    return AsyncRequest.request(
        Http.getLicensePlates(),
        (store: Flux.Store, res: Response): void => {
            const lp = new LicensePlateState.StateSlice(store);
            lp.writeRequest(res);
            if (res.statusCode.cls.success) {
                verifyLicensePlateSelection(store, lp.state.data);
            }
        },
    );
}

export function receiveAddressCountries(): Flux.Write {
    return AsyncRequest.request(
        Http.getAddressCountries(),
        (store: Flux.Store, res: Response): void => {
            const settings = new SettingsState.StateSlice(store).state;
            new AddressCountriesState.StateSlice(store).writeRequest(
                res,
                settings.language,
            );
        },
    );
}

export function receiveLanguages(): Flux.Write {
    return AsyncRequest.request(
        Http.getLanguages(),
        (store: Flux.Store, res: Response): void => {
            new LanguagesState.StateSlice(store).writeRequest(res);
        },
    );
}

export function receiveLicensePlateCountries(): Flux.Write {
    return AsyncRequest.request(
        Http.getLicensePlateCountries(),
        (store: Flux.Store, res: Response): void => {
            new LicensePlateCountriesState.StateSlice(store).writeRequest(res);
        },
    );
}

export function receiveBalance(): Flux.Write {
    return AsyncRequest.request(
        Http.getBalance(),
        (store: Flux.Store, res: Response): void => {
            new AccountBalanceState.StateSlice(store).writeRequest(res);
        },
    );
}

export function receivePaymentType(): Flux.Write {
    return AsyncRequest.request(
        Http.getPaymentType(),
        (store: Flux.Store, res: Response): void => {
            new PaymentTypeState.StateSlice(store).writeRequest(res);
        },
    );
}
