export function arithmeticCompare(a: any, b: any, ascending: boolean): number {
    let sign = 1;
    if (ascending) {
        sign = -1;
    }

    if (a < b) {
        return sign;
    } else if (a > b) {
        return -1 * sign;
    } else {
        return 0;
    }
}

export function localeCompare(
    a: string,
    b: string,
    ascending: boolean,
): number {
    const sign = ascending ? 1 : -1;
    return sign * a.localeCompare(b);
}

export function buildComparator<T>(
    key: keyof T,
    ascending = true,
): (a: T, b: T) => number {
    return (a: T, b: T) => arithmeticCompare(a[key], b[key], ascending);
}

export const numberCompare = (
    a: number | null,
    b: number | null,
    ascending = true,
    nullsFirst = true,
) => {
    const sign = ascending ? 1 : -1;
    const nullSign = nullsFirst ? 1 : -1;

    if (a !== null && b !== null) {
        return sign * (a - b);
    } else if (a === null && b !== null) {
        return -1 * nullSign;
    } else if (a !== null && b === null) {
        return 1 * nullSign;
    } else {
        return 0;
    }
};
