import { useState } from 'react';
import { isUndefined } from 'dg-web-shared/lib/MaybeV2';
import { InputContext } from '../../../../../tb-ui/inputs/InputContext';
import {
    LabeledToggle,
    ToggleType,
} from '../../../../../tb-ui/toggle/LabeledToggle';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import { getBridge } from '../../../Native';
import { ErrorBlock } from '../../root/components/ErrorBlock';
import { DEFAULT_SUMMARY_HOUR } from '../actions/CurrentLoginActions';
import { SingleSelection } from '../../../../../tb-ui/inputs/SingleSelection';
import {
    DropdownSlideIn,
    SlideInDisplay,
} from '../../root/components/DropdownSlideIn';
import { Localized } from '../../../common/components/Localized';
import { SlideInPortalId } from '../../root/components/PortalSlidein';
import { NotificationsBlock } from './NotificationsBlock';
import { writeEditLoginData } from './NewsletterEdit';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { GenericErrorBody } from '../../../ui/modals/HandleHttpStati';

export function hourToStr(hour: number) {
    return (hour < 10 ? '0' + hour : hour.toString()) + ':00';
}

interface EditState {
    automaticStop: boolean;
    summary: boolean;
    summaryHour: number;
}

export const TransactionReminderEditForm = (p: {
    currentLoginData: CurrentLoginState.CurrentLogin;
}) => {
    const initialState = {
        automaticStop: p.currentLoginData.sendRunningTransactionsAutoTerminate,
        summary: p.currentLoginData.runningTransactionsSummaryEnabled,
        summaryHour:
            p.currentLoginData.runningTransactionsWarningHour ??
            DEFAULT_SUMMARY_HOUR,
    };

    const [editState, setEditState] = useState<EditState>(initialState);

    const [expanded, setExpanded] = useState(false);

    const runningTransactionsWarningHour = () => {
        if (editState.summary) {
            return editState.summaryHour;
        }
        return null;
    };

    const [httpPut, doHttpPut, resetPut] = writeEditLoginData(
        () => setExpanded(false),
        false,
        'transactionReminder-edit',
    );

    const isSaveable =
        editState.automaticStop !==
            p.currentLoginData.sendRunningTransactionsAutoTerminate ||
        editState.summary !==
            p.currentLoginData.runningTransactionsSummaryEnabled ||
        (editState.summary &&
            editState.summaryHour !==
                p.currentLoginData.runningTransactionsWarningHour);

    const pending = httpPut.status === RequestStatus.PENDING;

    const hour =
        p.currentLoginData.runningTransactionsWarningHour !== null
            ? hourToStr(p.currentLoginData.runningTransactionsWarningHour)
            : null;

    const contentText = () => {
        if (p.currentLoginData !== null) {
            const messages: Array<JSX.Element> = [];
            p.currentLoginData.device !== null &&
                p.currentLoginData.device.fcmToken !== null &&
                !!p.currentLoginData.device.status &&
                messages.push(
                    <Localized
                        de="vor dem Ablauf"
                        fr="avant expiration"
                        it="prima della scadenza"
                        en="before expiration"
                    />,
                );
            p.currentLoginData.sendRunningTransactionsAutoTerminate &&
                messages.push(
                    <Localized
                        de="beim autom. Stopp"
                        fr="arrêtée autom."
                        it="fermata autom."
                        en="autom. stop"
                    />,
                );
            p.currentLoginData.runningTransactionsSummaryEnabled &&
                messages.push(
                    <Localized
                        de={`täglich um ${hour} Uhr`}
                        fr={`quotidiennement à ${hour}`}
                        it={`giornalmente alle ${hour}`}
                        en={`daily at ${hour}`}
                    />,
                );
            messages.length === 0 &&
                messages.push(<Localized de="—" fr="—" it="—" en="—" />);

            return messages.map((x: JSX.Element, idx: number) => {
                return (
                    <span>
                        {x}
                        {idx < messages.length - 1 && ', '}
                    </span>
                );
            });
        }
    };

    return (
        <NotificationsBlock
            caption={
                <Localized
                    de="Laufende Parkvorgänge"
                    fr="Procédures en cours"
                    it="Procedure in corso"
                    en="Running procedures"
                />
            }
            contentElement={
                <div>
                    <RenderEditContent
                        currentLoginData={p.currentLoginData}
                        setEditState={setEditState}
                        editState={editState}
                    />
                    {httpPut.status === RequestStatus.ERROR && (
                        <ErrorBlock text={<GenericErrorBody />} />
                    )}
                </div>
            }
            toggle={(): void => {
                setEditState({ ...initialState });
                setExpanded(!expanded);
                resetPut();
            }}
            expanded={expanded}
            onSave={() => {
                doHttpPut({
                    ...p.currentLoginData,
                    runningTransactionsWarningHour:
                        runningTransactionsWarningHour(),
                    sendRunningTransactionsAutoTerminate:
                        editState.automaticStop,
                });
            }}
            disabled={!isSaveable}
            text={contentText()}
            pending={pending}
        />
    );
};

const getDropdownData = (summary: boolean, summaryHour: number) => {
    if (summary) {
        return [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 0,
        ];
    } else {
        return [summaryHour];
    }
};

interface RenderEditContentProps {
    currentLoginData: CurrentLoginState.CurrentLogin;
    setEditState: (edit: EditState) => void;
    editState: EditState;
}

const RenderEditContent = (props: RenderEditContentProps) => {
    const [sendAtTimeSelect, setSendAtTimeSelect] = useState(false);

    if (isUndefined(props.currentLoginData)) {
        return null;
    } else {
        const device = props.currentLoginData.device;
        const dropdownData = getDropdownData(
            props.editState.summary,
            props.editState.summaryHour,
        );

        return (
            <>
                {device !== null && device.fcmToken !== null && (
                    <LabeledToggle
                        context={InputContext.form}
                        type={ToggleType.checkbox}
                        selected={!!device.status}
                        label={
                            <Localized
                                de={`${device.autoTerminationPreWarningMinutes} Min. vor dem Ablauf (nur Push)`}
                                fr={`${device.autoTerminationPreWarningMinutes} min. avant expiration (seulement push)`}
                                it={`${device.autoTerminationPreWarningMinutes} min. prima della scadenza (solo push)`}
                                en={`${device.autoTerminationPreWarningMinutes} min. before expiration (push only)`}
                            />
                        }
                        onClick={() => {
                            const bridge = getBridge();
                            if (bridge) {
                                bridge.notificationSettings();
                            }
                        }}
                    />
                )}

                <LabeledToggle
                    context={InputContext.form}
                    type={ToggleType.checkbox}
                    selected={props.editState.automaticStop}
                    label={
                        <Localized
                            de="Beim automatischen Stopp"
                            fr="Dans le cas où elle s'est arrêtée automatiquement"
                            it="Nel caso termini automaticamente"
                            en="In case it stops automatically"
                        />
                    }
                    onClick={() => {
                        props.setEditState({
                            ...props.editState,
                            automaticStop: !props.editState.automaticStop,
                        });
                    }}
                />
                <LabeledToggle
                    context={InputContext.form}
                    selected={props.editState.summary}
                    type={ToggleType.checkbox}
                    label={
                        <Localized
                            de="Täglich die Übersicht von den laufenden Parkvorgängen"
                            fr="Aperçu quotidien des procédures en cours"
                            it="Panoramica giornaliera delle procedure in corso"
                            en="Daily overview of current procedures"
                        />
                    }
                    onClick={() => {
                        props.setEditState({
                            ...props.editState,
                            summary: !props.editState.summary,
                        });
                    }}
                />

                {props.editState.summary && (
                    <>
                        <SingleSelection
                            labelText={{
                                de: 'Versandzeit',
                                fr: "Heure d'envoi",
                                it: "Ora d'invio",
                                en: 'Sent at',
                            }}
                            selection={hourToStr(props.editState.summaryHour)}
                            onClick={() => {
                                setSendAtTimeSelect(true);
                            }}
                            context={InputContext.form}
                            readOnly={!props.editState.summary}
                        />
                        <DropdownSlideIn
                            portal={SlideInPortalId.USER_ACCOUNT}
                            open={sendAtTimeSelect}
                            title={
                                <Localized
                                    de="Versandzeit"
                                    fr="Heure d'envoi"
                                    it="Ora d'invio"
                                    en="Sent at"
                                />
                            }
                            onClose={() => {
                                setSendAtTimeSelect(false);
                            }}
                            selected={props.editState.summaryHour.toString()}
                            onSelected={(hour: string) => {
                                setSendAtTimeSelect(false);
                                props.setEditState({
                                    ...props.editState,
                                    summaryHour: parseInt(hour, 10),
                                });
                            }}
                            options={dropdownData.map(hour => {
                                return {
                                    id: hour.toString(),
                                    display: (
                                        <SlideInDisplay
                                            firstRowText={hourToStr(hour)}
                                        />
                                    ),
                                };
                            })}
                        />
                    </>
                )}
            </>
        );
    }
};
