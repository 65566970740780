import Text = require('../../common/i18n/Text');
import * as TopNav from '../components/header/TopNav';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';

export const texts: Text.Translations<TopNav.TopNavTexts> = {
    de: {
        aboutUs: () => 'Unternehmen',
        home: () => 'Home',
        privacy: () => 'Privacy',
        provider: () => GeneralTexts.texts.de.Operator(),
        spread: () => 'Verbreitung',
        tos: () => 'AGB',
    },
    fr: {
        aboutUs: () => 'Entreprise',
        home: () => 'Home',
        privacy: () => 'Privacy',
        provider: () => GeneralTexts.texts.fr.Operator(),
        spread: () => 'Diffusion',
        tos: () => 'CGV',
    },
    it: {
        aboutUs: () => 'Azienda',
        home: () => 'Home',
        privacy: () => 'Privacy',
        provider: () => GeneralTexts.texts.it.Operator(),
        spread: () => 'Diffusione',
        tos: () => 'CG',
    },
    en: {
        aboutUs: () => 'Company',
        home: () => 'Home',
        privacy: () => 'Privacy',
        provider: () => GeneralTexts.texts.it.Operator(),
        spread: () => 'Diffusions',
        tos: () => 'GTC',
    },
};
