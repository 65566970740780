export type KeyGenerator<K, V> = (v: V) => K;

export const contains = <V>(values: V[], cb: (v: V) => boolean): boolean => {
    const res = values.filter(cb);
    return res.length > 0;
};

export const range = (n: number): number[] =>
    Array.apply(0, Array(n)).map((_v: any, i: number) => i);

export const unique = <T>(values: T[]): T[] => Array.from(new Set(values));

/**
 * indexes an array using the given key generation function and returns a map
 * If an index occurs multiple times in the array, only the last occurence will be in the resulting map
 * @param array
 * @param keyGen
 * @returns {{[p: string]: V}}
 */
export function indexBy<V>(
    array: V[],
    keyGen: KeyGenerator<string, V>,
): { [key: string]: V } {
    const indexedMap: { [key: string]: V } = {};
    array.forEach(element => (indexedMap[keyGen(element)] = element));
    return indexedMap;
}

/**
 * indexes an array using the given key generation function and returns a map
 * The map always resolves to an array of all values with the same key
 * @param array
 * @param keyGen
 * @returns {{[p: string]: V[]}}
 */
export function indexByMulti<V>(
    array: V[],
    keyGen: KeyGenerator<string, V>,
): { [key: string]: V[] } {
    const indexedMap: { [key: string]: V[] } = {};
    array.forEach(element => {
        const key = keyGen(element);
        if (!indexedMap[key]) {
            indexedMap[key] = [];
        }
        indexedMap[key].push(element);
    });
    return indexedMap;
}
