import * as CommonQueryActions from '../actions/CommonQueryActions';
import * as Http from '../../api/Http';
import * as Flux from 'dg-web-shared/lib/Flux';
import * as ServerState from './ServerState';
import * as ServerStateSlice from './ServerStateSlice';
import { Response } from 'dg-web-shared/lib/HttpResponse';

export type Balance = Http.Balance;
export type State = ServerState.State<Balance, void>;

export function getEmptyData(): ServerState.EmptyData<Balance, void> {
    return {
        data: {
            balance: 0,
            creditIsAllowed: true,
            overdueLevel: 0,
        },
        errorData: undefined,
    };
}

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<
    Balance,
    void
> {
    key(): string {
        return 'common-badges-AccountBalanceState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(CommonQueryActions.receiveBalance);
        }
    }

    getEmptyData() {
        return getEmptyData();
    }

    writeRequest(res: Response): void {
        if (res.pending) {
            this.writePending();
        } else if (res.statusCode.cls.success) {
            this.writeSuccess(res);
        } else {
            this.writeError(res);
        }
    }
}
