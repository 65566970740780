import { useStore } from 'dg-web-shared/lib/Flux';
import { isDefinedAndDiffersFrom } from 'dg-web-shared/lib/MaybeV2';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import * as Http from '../../api/Http';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import * as Fields from '../../utils/Fields';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import { updateMetaData } from './actions/MetaServerActions';
import { AccountMeta } from './state/MetaServerState';
import * as MetaState from './state/MetaState';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { GenderSelect } from '../../../../tb-ui/inputs/GenderSelect';
import { UnderlineStatus } from '../../../../tb-ui/inputs/internal/Underline';
import { useState } from 'react';

export const PersonEditFormSlideIn = portalSlideIn(PersonEditForm);

function PersonEditForm(props: {
    addressCountries: Http.AddressCountry[];
    accountMeta: AccountMeta;
    onClose: () => void;
}) {
    const { storeState, store } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        meta: MetaState.getMetaState(s),
    }));

    const address = props.accountMeta.address;

    const [form, setForm] = useState<FormState>({
        genderId: address.gender,
        firstName: address.firstName || null,
        lastName: address.lastName || null,
    });

    const genderNumberToChar = form.genderId === 0 ? 'f' : 'm';

    const genderCharToNumber = (gender: 'm' | 'f') => {
        if (gender === 'f') {
            setForm({ ...form, genderId: 0 });
        } else {
            setForm({ ...form, genderId: 1 });
        }
    };

    const isSaveable =
        !storeState.meta.response.pending &&
        formValuesChanged(props.accountMeta.address, form) &&
        formIsValid(form);

    const isNotCompany =
        props.accountMeta.customerAccountType === CustomerAccountType.PRIVATE;

    function onClose() {
        new MetaState.LocalStateSlice(store).reset();
        props.onClose();
    }

    return (
        <SlideInForm
            secondaryButton={{
                label: <Localized {...GeneralTexts.cancel} />,
                onClick: onClose,
            }}
            primaryButton={{
                label: <Localized {...GeneralTexts.save} />,
                disabled: !isSaveable,
                onClick: () => {
                    store.legacyUpdater(updateMetaData, {
                        payload: {
                            ...props.accountMeta,
                            address: {
                                ...props.accountMeta.address,
                                gender: form.genderId as number,
                                firstName: form.firstName || undefined,
                                lastName: form.lastName || undefined,
                                company1: isNotCompany
                                    ? undefined
                                    : props.accountMeta.address.company1 ||
                                      undefined,
                                company2: isNotCompany
                                    ? undefined
                                    : props.accountMeta.address.company2 ||
                                      undefined,
                            },
                        },
                        origin: MetaState.Origin.ADDRESS,
                        onSuccess: onClose,
                    });
                },
            }}
        >
            <GenderSelect
                gender={genderNumberToChar}
                underlineStatus={UnderlineStatus.none}
                errorText={''}
                styleContext={InputContext.form}
                writeGender={(gender: 'm' | 'f') => genderCharToNumber(gender)}
            />

            <TextField
                context={InputContext.form}
                value={form.firstName}
                tabIndex={0}
                name="first-name"
                autocomplete="given-name"
                labelText={<Localized {...GeneralTexts.firstName} />}
                errorText={mandatoryErrorText(
                    new Fields.GenericField(form.firstName || ''),
                )}
                onChange={(firstName: string): void => {
                    setForm({ ...form, firstName });
                }}
            />

            <TextField
                context={InputContext.form}
                value={form.lastName}
                tabIndex={0}
                name="last-name"
                autocomplete="family-name"
                labelText={<Localized {...GeneralTexts.lastName} />}
                errorText={mandatoryErrorText(
                    new Fields.GenericField(form.lastName || ''),
                )}
                onChange={(lastName: string): void => {
                    setForm({ ...form, lastName });
                }}
            />
        </SlideInForm>
    );
}

function formIsValid(form: FormState): boolean {
    const values = makeFormValues(form);

    return Fields.noStatesAndNotEmpty(values.firstName, values.lastName);
}

function makeFormValues(s: FormState): FormValues {
    return {
        genderId: s.genderId as number,
        firstName: new Fields.GenericField(s.firstName || ''),
        lastName: new Fields.GenericField(s.lastName || ''),
    };
}

function formValuesChanged(cloud: Http.Address, local: FormState): boolean {
    return [
        [local.genderId, cloud.gender],
        [local.firstName, cloud.firstName],
        [local.lastName, cloud.lastName],
    ]
        .map(([a, b]) => isDefinedAndDiffersFrom(a, b))
        .reduce((a, b) => a || b);
}

function mandatoryErrorText(
    field: Fields.Field<void>,
): React.ReactElement | null {
    if (field.isEmpty) {
        return <Localized {...GeneralTexts.mandatoryField} />;
    }

    return null;
}

interface FormValues {
    genderId: number;
    firstName: Fields.GenericField;
    lastName: Fields.GenericField;
}

interface FormState {
    genderId: number | null;
    firstName: string | null;
    lastName: string | null;
}
