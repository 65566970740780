import * as Http from '../../api/Http';
import * as Flux from 'dg-web-shared/lib/Flux';
import * as AsyncRequest from '../../AsyncRequest';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import { setAppToken } from '../../Native';

export const logout = AsyncRequest.requestV2(
    Http.logout,
    (_store: Flux.Store, res: Response): void => {
        if (!res.pending) {
            setAppToken(null);
            LocalStorage.clear();
            // on logout, reload url to reset all stores
            window.location.href = '/';
        }
    },
);
