export interface LprParking {
    zoneId: number;
    name: string;
    city: string;
    lon: number;
    lat: number;
    distance: number | null;
    state: LprParkingSelectionState;
}

export enum LprParkingSelectionState {
    ACTIVE = 'ACTIVE',
    AVAILABLE = 'AVAILABLE',
    NOT_AVAILABLE_ANYMORE = 'NOT_AVAILABLE_ANYMORE',
}

export function getFilteredParkingsByState(parkings: LprParking[]) {
    const activeParkings = parkings.filter(
        p => p.state === LprParkingSelectionState.ACTIVE,
    );
    const notAvailableAnymoreParkings = parkings.filter(
        p => p.state === LprParkingSelectionState.NOT_AVAILABLE_ANYMORE,
    );
    const availableParkings = parkings.filter(
        p => p.state === LprParkingSelectionState.AVAILABLE,
    );

    return [activeParkings, notAvailableAnymoreParkings, availableParkings];
}
