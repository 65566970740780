import { useStore } from 'dg-web-shared/lib/Flux';
import {
    JsxMessage,
    languageFromString,
    Message,
} from 'dg-web-shared/lib/Localized';
import * as SettingsState from '../state/SettingsState';

export function Localized(message: Message | JsxMessage) {
    const { storeState } = useStore(s => ({
        language: languageFromString(
            new SettingsState.StateSlice(s).state.language,
        ),
    }));

    return <>{message[storeState.language]}</>;
}

export function useLocalized(message: Message): string {
    const { storeState } = useStore(s => ({
        language: languageFromString(
            new SettingsState.StateSlice(s).state.language,
        ),
    }));

    return message[storeState.language];
}

export const EMPTY_TRANSLATION = {
    de: '',
    fr: '',
    it: '',
    en: '',
};
