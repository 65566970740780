import { DateTime } from 'luxon';

import { useState } from 'react';
import { Store, Updater, useStore } from 'dg-web-shared/lib/Flux';
import { Language, languageFromString } from 'dg-web-shared/lib/Text';
import { apiPut, PaymentMethodEnum } from '../../../api/Http';
import { requestV2 } from '../../../AsyncRequest';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import * as SettingsState from '../../../common/state/SettingsState';
import * as MetaServerState from '../../meta/state/MetaServerState';
import { SlideInPortalId } from '../../root/components/PortalSlidein';
import { DirectDebitChangeAccountFormSlideIn } from './DirectDebitChangeAccountForm';
import { DirectDebitRequestFormSlideIn } from './DirectDebitRequestForm';
import { DirectDebitReviewSlideIn } from './DirectDebitReview';
import {
    DirectDebitAccountChangeJustRequested,
    DirectDebitJustRequested,
    DirectDebitSubmittedEarlier,
} from './PaymentMethodJsxTexts';
import {
    formStyle,
    requestCallback,
    getMethodName,
} from '../PaymentMethodMenu';
import { PaymentMethodStatus } from '../PaymentMethodStatus';
import { paymentMethodTexts } from '../PaymentMethodTexts';
import { RemoteRequestDoneSlideIn } from '../RemoteRequestDone';
import {
    PaymentMethodItemBlock,
    BlockStatus,
} from '../../root/components/ItemBlock';
import { Localized } from '../../../common/components/Localized';

enum ActiveSlideIn {
    LSV_REQUEST = 'LSV_REQUEST',
    LSV_REQUEST_DONE = 'LSV_REQUEST_DONE',
    LSV_REQUEST_REVIEW = 'LSV_REQUEST_REVIEW',
    LSV_REVIEW = 'LSV_REVIEW',
    LSV_CHANGE_ACCOUNT = 'LSV_CHANGE_ACCOUNT',
    LSV_CHANGE_ACCOUNT_DONE = 'LSV_CHANGE_ACCOUNT_DONE',
}

const activeSlideInForStatus = (status: PaymentMethodStatus) => {
    switch (status) {
        case PaymentMethodStatus.REQUESTED:
            return ActiveSlideIn.LSV_REQUEST_REVIEW;

        case PaymentMethodStatus.INACTIVE:
            return ActiveSlideIn.LSV_REQUEST;

        case PaymentMethodStatus.ACTIVE:
            return ActiveSlideIn.LSV_REVIEW;
    }
};

export function Lsv(props: { status: PaymentMethodStatus; update: Updater }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <PaymentMethodItemBlock
                title={
                    <Localized
                        de="Lastschrift (LSV)"
                        fr="Prélèv. autom. (LSV)"
                        it="Addebito diretto (LSV)"
                        en="Direct debit (LSV)"
                    />
                }
                status={
                    props.status === PaymentMethodStatus.ACTIVE
                        ? BlockStatus.ACTIVE
                        : props.status === PaymentMethodStatus.REQUESTED
                          ? BlockStatus.REQUESTED
                          : BlockStatus.INACTIVE
                }
                onClick={() => {
                    setOpen(true);
                }}
                key={`LSV`}
            />
            <DirectDebitSlideIns
                update={props.update}
                backToList={() => setOpen(false)}
                status={props.status}
                open={open}
            />
        </>
    );
}

export const DirectDebitSlideIns = (props: {
    status: PaymentMethodStatus;
    update: Updater;
    backToList: () => void;
    open: boolean;
}) => {
    const { storeState } = useStore(s => ({
        paymentType: new PaymentTypeState.StateSlice(s).state,
        meta: new MetaServerState.StateSlice(s).state,
        settings: new SettingsState.StateSlice(s).state,
    }));

    const [activeSlideIn, setActiveSlideIn] = useState(
        activeSlideInForStatus(props.status),
    );

    const paymentMethodChangeRequestTimestamp =
        storeState.paymentType.data.paymentMethodChangeRequest
            ?.paymentMethodRequestTimestamp ||
        DateTime.fromISO('1970-01-01', { locale: 'de-CH' });

    const lsvSelected = props.open;
    const activeSlideInIs = (wanted: ActiveSlideIn) =>
        lsvSelected && activeSlideIn === wanted;

    const language = languageFromString(storeState.settings.language);
    const t = paymentMethodTexts[language];

    return (
        <>
            <DirectDebitRequestFormSlideIn
                language={language}
                customerAddressCountryCode={
                    storeState.meta.data.address.countryId
                }
                customerShippingAddressCountryCode={
                    storeState.meta.data.shippingAddress.countryId
                }
                validBankClearingNumbers={
                    storeState.paymentType.data.validBankClearingNumbers
                }
                onClose={props.backToList}
                onSubmit={({ iban }) => {
                    props.update(requestDirectDebit(iban), () =>
                        setActiveSlideIn(ActiveSlideIn.LSV_REQUEST_DONE),
                    );
                }}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.LSV_REQUEST)}
                title={t.RequestLSV()}
                contentCssClass={formStyle}
            />

            <RemoteRequestDoneSlideIn
                onClose={props.backToList}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.LSV_REQUEST_DONE)}
                title={t.directDebitRequested()}
            >
                <DirectDebitJustRequested
                    language={language}
                    iban={requestedIban(storeState.paymentType)}
                    authorizationDocumentPdfUri={lsvPdfUri(language)}
                />
            </RemoteRequestDoneSlideIn>

            <RemoteRequestDoneSlideIn
                onClose={props.backToList}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.LSV_REQUEST_REVIEW)}
                title={t.directDebitRequested()}
            >
                <p>
                    {t.directDebitSubmittedEarlierP1(
                        requestedIban(storeState.paymentType),
                        paymentMethodChangeRequestTimestamp,
                    )}
                </p>
                <p>{t.directDebitSubmittedEarlierP2()}</p>

                <DirectDebitSubmittedEarlier
                    language={language}
                    authorizationDocumentPdfUri={lsvPdfUri(language)}
                />
            </RemoteRequestDoneSlideIn>

            <DirectDebitReviewSlideIn
                onClose={props.backToList}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={
                    activeSlideInIs(ActiveSlideIn.LSV_REVIEW) ||
                    activeSlideInIs(ActiveSlideIn.LSV_CHANGE_ACCOUNT)
                }
                title={<Localized {...getMethodName(PaymentMethodEnum.LSV)} />}
                language={language}
                iban={activeIban(storeState.paymentType)}
                directDebitAccountChangeRequest={
                    storeState.paymentType.data.activePaymentMethod
                        .activePaymentType === PaymentMethodEnum.LSV
                        ? storeState.paymentType.data.activePaymentMethod
                              .directDebitAccountChangeRequest
                        : null
                }
                onChangeAccountClick={() => {
                    setActiveSlideIn(ActiveSlideIn.LSV_CHANGE_ACCOUNT);
                }}
                lsvPdfUri={lsvPdfUri(language)}
            />

            <DirectDebitChangeAccountFormSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.LSV_CHANGE_ACCOUNT)}
                title={t.directDebitChangeAccount()}
                onClose={() => setActiveSlideIn(ActiveSlideIn.LSV_REVIEW)}
                onCancel={() => setActiveSlideIn(ActiveSlideIn.LSV_REVIEW)}
                language={language}
                validBankClearingNumbers={
                    storeState.paymentType.data.validBankClearingNumbers
                }
                currentIban={
                    storeState.paymentType.data.activePaymentMethod
                        .activePaymentType === PaymentMethodEnum.LSV
                        ? storeState.paymentType.data.activePaymentMethod.iban
                        : ''
                }
                onSubmit={({ iban }) => {
                    props.update(requestNewAccountForDirectDebit(iban), () =>
                        setActiveSlideIn(ActiveSlideIn.LSV_CHANGE_ACCOUNT_DONE),
                    );
                }}
            />

            <RemoteRequestDoneSlideIn
                onClose={props.backToList}
                portal={SlideInPortalId.USER_ACCOUNT}
                open={activeSlideInIs(ActiveSlideIn.LSV_CHANGE_ACCOUNT_DONE)}
                title={t.directDebitAccountChangeRequested()}
            >
                <DirectDebitAccountChangeJustRequested
                    language={language}
                    iban={requestedIban(storeState.paymentType)}
                    authorizationDocumentPdfUri={lsvPdfUri(language)}
                />
            </RemoteRequestDoneSlideIn>
        </>
    );
};

function requestDirectDebit(iban: string) {
    return (store: Store, success: () => void) =>
        requestV2(
            () =>
                apiPut('payment-type').send({
                    activePaymentType: PaymentMethodEnum.LSV,
                    iban,
                }),
            requestCallback(
                'request-payment-method-direct-debit',
                { iban: iban },
                success,
            ),
        )(store);
}

function requestNewAccountForDirectDebit(requestedIban: string) {
    return (store: Store, success: () => void) =>
        requestV2(
            () =>
                apiPut('payment-type-lsv').send({
                    requestedIban,
                }),
            requestCallback(
                'request-new-account-for-payment-type-lsv',
                { iban: requestedIban },
                success,
            ),
        )(store);
}

const lsvPdfUri = (language: Language) =>
    `/ui-api/customer-account/payment-type/lsv-pdf-in-session?language=${language}`;

function requestedIban(paymentType: PaymentTypeState.State) {
    if (
        paymentType.data.paymentMethodChangeRequest &&
        paymentType.data.paymentMethodChangeRequest.requestedPaymentType ===
            PaymentMethodEnum.LSV
    ) {
        return paymentType.data.paymentMethodChangeRequest.requestedIban;
    }

    if (
        paymentType.data.activePaymentMethod.activePaymentType ===
            PaymentMethodEnum.LSV &&
        paymentType.data.activePaymentMethod.directDebitAccountChangeRequest
    ) {
        return paymentType.data.activePaymentMethod
            .directDebitAccountChangeRequest.requestedIban;
    }

    return PLACEHOLDER_IBAN;
}

function activeIban(paymentType: PaymentTypeState.State) {
    if (
        paymentType.data.activePaymentMethod.activePaymentType ===
        PaymentMethodEnum.LSV
    ) {
        return paymentType.data.activePaymentMethod.iban;
    }

    return PLACEHOLDER_IBAN;
}

const PLACEHOLDER_IBAN = '---------------------';
