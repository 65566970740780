import * as Http from '../../api/Http';
import * as AsyncRequest from '../../AsyncRequest';
import moment from 'moment';
import { Parser } from 'dg-web-shared/lib/Date';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Updater } from 'dg-web-shared/lib/Flux';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { Slope } from 'dg-web-shared/common/tariff-logic/Tariff';
import { CheckinDataState } from '../../park-create/components/zone-transaction/ZoneEnforcedCheckin';
import { Message } from 'dg-web-shared/lib/Localized';
import { IntradayValidityWeekProfile } from 'dg-web-shared/model/IntradayValidityWeekProfile';
import { DateTime } from 'luxon';
import { TransactionCost } from 'dg-web-shared/model/TransactionList';

export interface LicensePlateDTO {
    id: number;
    licensePlateNr: string;
    type: LicensePlateType;
    country: string;
    remarks: string | null;
}

export namespace Detail {
    export interface State {
        parkTransactionId: number | null;
        parkTransactionShowDetail: boolean;

        permitId: number | null;
        purchasedPermitId: number | null;
        permitPurchaseIncludedBalanceTopUp: boolean;

        offstreetCheckinId: number | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'transactions-list/TransactionsListState.Detail',
        {
            parkTransactionId: null,
            parkTransactionShowDetail: false,
            permitId: null,
            purchasedPermitId: null,
            permitPurchaseIncludedBalanceTopUp: false,
            offstreetCheckinId: null,
        },
    );
}

export namespace CheckinByPlateDetailState {
    export type AutoTerminationReason = 'no_balance' | 'max_park_time';
    export type LicensePlate = LicensePlateDTO;
    interface SharedCheckinByPlate {
        id: number;
        operatorName: string;
        city: string;
        zipCode: string;
        zoneName: string;
        extZoneCode: number;
        licensePlate: LicensePlate;
        begin: string;
        isRunning: boolean;
        currentTime: string;
    }

    export interface RunningCheckinByPlate extends SharedCheckinByPlate {
        isRunning: true;
        tariffMaxEnd: string;
        autoTerminationTime: string;
        autoTerminationPrice: number;
        tariffCurve: Slope[] | null;
        tariffMaxMinutes: number;
        minChfStep: number;
        surchargeData: CheckinDataState.SurchargeData[];
    }

    export interface TerminatedCheckinByPlate extends SharedCheckinByPlate {
        isRunning: false;
        end: string;
        key: string;
        contractId: number;
        cost: TransactionCost;
    }

    export type CheckinByPlate =
        | RunningCheckinByPlate
        | TerminatedCheckinByPlate;
    export type State =
        ContextualServerStateSlice.ContextualState<CheckinByPlate>;

    export const getReferenceTime = (t: CheckinByPlate) =>
        moment(t.currentTime).startOf('minute');
    export const requestGenerator = (context: Context) =>
        Http.TransactionsList.Detail.ParkTransaction.get(context && context.id);
    export interface Context {
        id: number;
    }
    export const { get, getState, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<
            Context,
            CheckinByPlate
        >({
            key: 'transactions-list/TransactionsListState.ParkTransactionDetail',
            requestGenerator,
            request: AsyncRequest.requestV2,
            parseBody: b => b,
            refreshIntervalSecs: 180,
        });
}

export namespace PermitDetail {
    export interface PermitDetailLicensePlate {
        id: number;
        licensePlateNr: string;
        type: LicensePlateType;
        country: string;
        remarks: string | null;
        explicitCheckin:
            | ExplicitCheckinRunning
            | ExplicitCheckinNotRunning
            | null;
        isMissingBadgeButRequired: boolean;
        isMissingVehicleButRequired: boolean;
        pastActivations: Activation[];
    }

    export type Activation = {
        contractId: number;
        validFrom: string;
        validTo: string;
    };

    export interface ExplicitCheckinRunning {
        running: true;
        checkoutPossible: boolean;
        runningFrom: string;
        runningUntil: string;
    }

    export interface ExplicitCheckinNotRunning {
        running: false;
        checkinPossible: boolean;
    }

    interface PermitType {
        description: string;
        zipCode: string;
        id: number;
    }

    interface PermitZone {
        extZoneId: number;
        uniqueId: number;
        name: string;
        city: string;
        zipCode: string;
    }

    interface Badge {
        id: number;
        labelNr: string;
    }

    interface OffstreetZone {
        uniqueId: number;
        zoneId: number;
        name: string;
        city: string;
        zipCode: string;
    }

    export interface MinimalBalanceWarning {
        showMinimalBalanceWarning: boolean;
        isPrepaid: boolean;
        minimalEntryAmount: number;
    }

    export interface ExplicitCheckinInfo {
        usages: UsageInfo | null;
        maxConcurrentCheckins: number | null;
    }

    export interface UsageInfo {
        usageLimit: number;
        usedCount: number;
    }

    export enum PaymentChannel {
        PARKINGPAY = 'PARKINGPAY',
        PARKINGPORTAL_COUNTER = 'PARKINGPORTAL_COUNTER',
    }

    export interface Permit {
        id: number;
        urlKey: string;
        operatorId: number;
        city: string;
        additionalInfo: string;
        operatorName: string;
        validFrom: DateTime;
        validTo: DateTime;
        isValid: boolean;
        permitType: PermitType;
        price: number;
        permitZones: PermitZone[];
        licensePlates: PermitDetailLicensePlate[];
        isRenewable: boolean;
        isRenewableExact: boolean;
        missingLpsForRenewal: LicensePlateDTO[];
        badges: Badge[];
        offstreetZones: OffstreetZone[];
        paymentChannel: PaymentChannel;
        minimalBalanceWarning: MinimalBalanceWarning;
        explicitCheckinInfo: ExplicitCheckinInfo | null;
        intradayValidityWeekProfile: IntradayValidityWeekProfile | null;
        operatorEditPolicyText: Message;
        permitIsChangeable: boolean;
        operatorContactDataMarkdown: string | null;
        showMissingVehicleWarning: boolean;
        showMissingBadgeWarning: boolean;
        badgeRequired: boolean;
    }

    const parsePermit = (t: any): Permit =>
        Object.assign(t, {
            validFrom: DateTime.fromISO(t.validFrom),
            validTo: DateTime.fromISO(t.validTo),
        });

    export type State = ContextualServerStateSlice.ContextualState<Permit>;

    interface Context {
        id: number;
    }
    export const { get, getState, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<Context, Permit>({
            key: 'transactions-list/TransactionsListState.PermitDetail',
            requestGenerator: (context: Context) =>
                Http.TransactionsList.Detail.Permit.get(context && context.id),
            request: AsyncRequest.requestV2,
            parseBody: b => parsePermit(b),
        });
}

export namespace OffstreetCheckinDetail {
    export type OffstreetCheckin = {
        id: number;
        operatorName: string;
        city: string;
        remarks: string;
        zipCode: string;
        zoneName: string;
        badgeLabelNr: string;
        begin: moment.Moment;
        end: moment.Moment;
        price: number;
        key: string;
        contractId: number;
        cost: TransactionCost;
    };
    const parseOffstreetTransactionDetail = (t: any): OffstreetCheckin =>
        Object.assign(t, {
            begin: Parser.isoToMoment(t.begin),
            end: Parser.isoToMaybeMoment(t.end),
        });
    export type State =
        ContextualServerStateSlice.ContextualState<OffstreetCheckin>;
    interface Context {
        id: number;
    }
    export const { get, getState } =
        ContextualServerStateSlice.generateContextualState<
            Context,
            OffstreetCheckin
        >({
            key: 'transactions-list/TransactionsListState.OffstreetTransactionDetail',
            requestGenerator: (context: Context) =>
                Http.TransactionsList.Detail.OffstreetTransaction.get(
                    context && context.id,
                ),
            request: AsyncRequest.requestV2,
            parseBody: b => parseOffstreetTransactionDetail(b),
        });
}

export function updateTransactionlistWarnings(update: Updater) {
    update(s => {
        PermitDetail.refetchSameContext(s, false);
        return 'update-permit-warning-transactionlist';
    });
}
