import { rgba as libRgba } from 'utils/src/Rgba';

export enum Color {
    white,
    black,
    typo,
    darkblue,
    middleblue,
    lightblue,
    navbar,
    navbarhover,
    fPendent,
    fPendentC,
    fAbgelehnt,
    bActive,
    bFuture,
    bExpired,
    bCanceled,
    bCredited,
    error,
    versionLatest,
}

export const colorString = (c: Color) => Color[c] as keyof typeof Color;

export const ColorHex = {
    // group base
    white: '#FFFFFF',
    black: '#000000',
    typo: '#646464',
    darkblue: '#32496B',
    middleblue: '#0067A3',
    lightblue: '#4F9ADB',
    navbar: '#1E2B40',
    navbarhover: '#3E5A85',
    errorBright: '#FFAE33',

    // group freigaben
    fPendent: '#FFAE00',
    fPendentC: '#333333',
    fAbgelehnt: '#A60000',

    // group bewilligungen
    bActive: '#468033',
    bFuture: '#99C65B',
    bExpired: '#666666',
    bCanceled: '#DE412F',
    bCredited: '#DE412F',
    error: '#A60000',
    versionLatest: '#4A99D9',

    rgba: libRgba,
};
