import { DateTime } from 'luxon';
import {
    CreditCardAlias,
    PaymentCardType,
} from 'dg-web-shared/model/PaymentAlias';
import {
    PaymentCategory,
    SelectedPaymentFlow,
} from '../../common/payment/Payment';

export const paymentCategoryFromAliasPaymentMethod = (
    paymentMethod: PaymentCardType,
): PaymentCategory => {
    switch (paymentMethod) {
        case PaymentCardType.VIS:
        case PaymentCardType.AMX:
        case PaymentCardType.ECA:
            return PaymentCategory.CARDS;
        case PaymentCardType.PFC:
        case PaymentCardType.PEF:
            return PaymentCategory.POSTFINANCE;
        case PaymentCardType.TWI:
            return PaymentCategory.TWINT;
    }
};

export const navCategoryForPaymentAlias = (
    creditCardAlias: CreditCardAlias,
): SelectedPaymentFlow => {
    switch (creditCardAlias.paymentMethod) {
        case PaymentCardType.VIS:
            return SelectedPaymentFlow.VISA;
        case PaymentCardType.AMX:
            return SelectedPaymentFlow.AMERICAN_EXPRESS;
        case PaymentCardType.ECA:
            return SelectedPaymentFlow.MASTERCARD;
        case PaymentCardType.PFC:
        case PaymentCardType.PEF:
            return SelectedPaymentFlow.POSTFINANCE;
        case PaymentCardType.TWI:
            return SelectedPaymentFlow.TWINT;
    }
};

export function aliasIsValid(creditCardAlias: CreditCardAlias) {
    if (creditCardAlias.lastDirectFailed) {
        return false;
    }
    return (
        !creditCardAlias.expiration ||
        DateTime.fromISO(creditCardAlias.expiration.upperBoundDateTime) >
            DateTime.local()
    );
}
