import * as Flux from 'dg-web-shared/lib/Flux';
import { Gender } from '../../../account-setup/state/AccountSetupState';

export interface State {
    email1: string | null;
    email2: string | null;
    gender: Gender | null;
    firstName: string | null;
    lastName: string | null;
    telNr: string | null;
    balanceWarningEnabled: boolean | null;
    balanceWarningAmount: number | null;
    newsletterStatus: string | null;
    runningTransactionsSummaryEnabled: boolean | null;
    runningTransactionsWarningHour: number | null;
    sendAutomaticStopReminder: boolean | null;
    sendEmailNotifications: boolean | null;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'account-current-login-EditState';
    }
    sideEffects(): void {
        return;
    }

    getInitialState(): State {
        return {
            email1: null,
            email2: null,
            firstName: null,
            lastName: null,
            gender: null,
            telNr: null,
            balanceWarningEnabled: null,
            balanceWarningAmount: null,
            newsletterStatus: null,
            runningTransactionsSummaryEnabled: null,
            runningTransactionsWarningHour: null,
            sendAutomaticStopReminder: null,
            sendEmailNotifications: null,
        } as any;
    }

    setEmail1(email: string): void {
        this.set((s: State): State => {
            s.email1 = email;
            return s;
        });
    }

    setEmail2(email: string): void {
        this.set((s: State): State => {
            s.email2 = email;
            return s;
        });
    }

    setFirstName(firstName: string): void {
        this.set((s: State): State => {
            s.firstName = firstName;
            return s;
        });
    }

    setGender(gender: Gender): void {
        this.set((s: State): State => {
            s.gender = gender;
            return s;
        });
    }

    setLastName(lastName: string): void {
        this.set((s: State): State => {
            s.lastName = lastName;
            return s;
        });
    }

    setPhoneNumber(telNr: string): void {
        this.set((s: State): State => {
            s.telNr = telNr;
            return s;
        });
    }

    toggleEmailNotification(value: boolean): void {
        this.set((s: State): State => {
            s.sendEmailNotifications = value;
            return s;
        });
    }

    setNewsletterEnabled(value: boolean): void {
        this.set((s: State): State => {
            if (value) {
                s.newsletterStatus = 'SUBSCRIBED';
            } else {
                s.newsletterStatus = 'UNSUBSCRIBED_BY_US';
            }
            return s;
        });
    }

    setSummary(value: boolean): void {
        this.set((s: State): State => {
            s.runningTransactionsSummaryEnabled = value;
            return s;
        });
    }
    setSummaryHour(value: number): void {
        this.set((s: State): State => {
            s.runningTransactionsWarningHour = value;
            return s;
        });
    }
    setAutomaticStop(value: boolean): void {
        this.set((s: State): State => {
            s.sendAutomaticStopReminder = value;
            return s;
        });
    }

    setBalanceWarning(value: boolean): void {
        this.set((s: State): State => {
            s.balanceWarningEnabled = value;
            return s;
        });
    }
    setBalanceWarningAmount(value: number): void {
        this.set((s: State): State => {
            s.balanceWarningAmount = value;
            return s;
        });
    }
}
