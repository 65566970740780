import { TicketState } from '../../common/components/ticketplus/TicketPlusTypes';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';

export type ZoneInfo = {
    name: string;
    zipCode: string;
    city: string;
    operatorName: string;
};

type TicketWithState = {
    ticketId: string;
    state: TicketState;
    ticketApiString: string;
    createdAt: string;
};

type TicketBooked = {
    amount: number;
    validFrom: string;
    validTo: string;
} & TicketWithState;

export type FlowStateTicketScanned = {
    flowState: 'TICKET_SCANNED';
    ticket: TicketWithState;
    zoneInfo: ZoneInfo;
    tosAndPrivacyPolicyAccepted: boolean;
};
export type FlowStateAliasPending = {
    flowState: 'ALIAS_PENDING';
    ticket: TicketWithState;
    zoneInfo: ZoneInfo;
    redirectUri: string;
};
export type FlowStateTicketApproved = {
    flowState: 'TICKET_APPROVED';
    ticket: TicketWithState;
    zoneInfo: ZoneInfo;
};
export type FlowStateTicketBooked = {
    flowState: 'TICKET_BOOKED';
    zoneInfo: ZoneInfo;
    ticket: TicketBooked;
};
export type FlowStateTicketClaimed = {
    flowState: 'TICKET_CLAIMED';
    ticket: TicketWithState;
};
export type FlowStateTooManyApprovedTickets = {
    flowState: 'TOO_MANY_APPROVED_TICKETS';
};
export type FlowStateTicketPaymentNotAllowed = {
    flowState: 'TICKET_PAYMENT_NOT_ALLOWED';
};
export type FlowStateTicketNotFound = {
    flowState: 'TICKET_NOT_FOUND';
};

export type FlowState =
    | FlowStateTicketScanned
    | FlowStateAliasPending
    | FlowStateTicketApproved
    | FlowStateTicketBooked
    | FlowStateTicketClaimed
    | FlowStateTooManyApprovedTickets
    | FlowStateTicketPaymentNotAllowed
    | FlowStateTicketNotFound;

export function getZoneInfoFromFlowState(
    flowState: ServerRequestState<FlowState, unknown>,
) {
    if (flowState.status != RequestStatus.ERROR && flowState.data) {
        switch (flowState.data.flowState) {
            case 'TICKET_SCANNED':
            case 'ALIAS_PENDING':
            case 'TICKET_APPROVED':
            case 'TICKET_BOOKED':
                return flowState.data.zoneInfo;
            case 'TICKET_CLAIMED':
            case 'TICKET_PAYMENT_NOT_ALLOWED':
            case 'TICKET_NOT_FOUND':
            case 'TOO_MANY_APPROVED_TICKETS':
                return undefined;
        }
    } else {
        return undefined;
    }
}

export type StartPaymentResponse = {
    redirectUrl: string;
};

export enum PaymentModalState {
    ABORTED,
    SUCCESSFUL,
    FAILED,
}

export enum PaymentAbortionReason {
    CANCELLED,
    FAILURE,
}
