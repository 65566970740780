import * as Flux from 'dg-web-shared/lib/Flux';
import { CustomerAccountType } from '../../../api/CustomerAccountType';
import * as SettingsState from '../../../common/state/SettingsState';
import * as MetaTexts from '../i18n/MetaTexts';
import { toggleMetaFormsVisibility } from '../MetaForms';
import * as MetaServerState from '../state/MetaServerState';
import { MainMenuItemBlock } from '../../root/components/ItemBlock';

export function Meta() {
    const { storeState, store } = Flux.useStore(s => ({
        metaServer: new MetaServerState.StateSlice(s).state,
        settings: new SettingsState.StateSlice(s).state,
    }));

    const texts = MetaTexts.generalTexts[storeState.settings.language];

    return (
        <MainMenuItemBlock
            title={texts.AccountMetaEditTitle()}
            subTitle={getSubHeaderInfo(storeState)}
            onClick={() => {
                store.legacyUpdater(toggleMetaFormsVisibility);
            }}
        />
    );
}

function enforceEmptyString(str: string | null | undefined): string {
    if (str === null || str === undefined) {
        return '';
    }
    return str;
}

function getSubHeaderInfo(s: {
    metaServer: MetaServerState.State;
    settings: SettingsState.State;
}): string {
    if (s.metaServer.data.customerAccountType === CustomerAccountType.PRIVATE) {
        const address = s.metaServer.data.address;
        const firstName = enforceEmptyString(address.firstName);
        const lastName = enforceEmptyString(address.lastName);
        return firstName + ' ' + lastName;
    } else {
        const address = s.metaServer.data.address;
        const company1 = enforceEmptyString(address.company1);
        const company2 = enforceEmptyString(address.company2);
        return company1 + (company2 ? ', ' + company2 : '');
    }
}
