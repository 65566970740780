import * as Flux from 'dg-web-shared/lib/Flux';
import { EditableLicensePlate } from '../../account/vehicles/VehicleLicensePlateEdit';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { BadgeTypeEnum } from '../../common/state/BadgeTypesState';
import * as WriteStateSlice from '../../common/state/WriteStateSlice';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';

export namespace Options {
    export interface State {
        registerLicensePlate?: boolean | null;
        registerBadge?: boolean | null;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account-setup/AccountSetup.Options',
        {},
    );
}

export type Gender = 'm' | 'f';
export namespace Address {
    export interface State {
        gender?: Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        company1?: string | null;
        company2?: string | null;
        companyUid?: string | null;
        street?: string | null;
        zipCode?: string | null;
        city?: string | null;
        country?: string | null;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account-setup/AccountSetup.Address',
        {
            country: 'CH',
        },
    );
}

export namespace LicensePlate {
    export interface State extends EditableLicensePlate {
        remark: string;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account-setup/AccountSetup.LicensePlate',
        {
            licensePlateType: LicensePlateType.CAR,
            licensePlateNr: '',
            licensePlateCountryId: 'CH',
            remark: '',
        },
    );
}

export namespace Badge {
    export interface State {
        badgeType: number;
        badgeNr: string | null;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account-setup/AccountSetup.Badge',
        {
            badgeType: BadgeTypeEnum.PARKINGPAY_BADGE,
            badgeNr: null,
        },
    );
}

export type Step = 'Type' | 'Address' | 'AddLicensePlate' | 'Summary';
export namespace Navigation {
    export interface State {
        currentStep: Step;
        showErrorsAddress: boolean;
        showErrorsAddLicensePlate: boolean;
        showErrorsUseOffstreetQuestion: boolean;
        showErrorsAddBadge: boolean;
        showErrorsSummary: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account-setup/AccountSetup.Navigation',
        {
            currentStep: 'Type',
            showErrorsAddress: false,
            showErrorsAddLicensePlate: false,
            showErrorsUseOffstreetQuestion: false,
            showErrorsAddBadge: false,
            showErrorsSummary: false,
        },
    );
}

export namespace Layout {
    export interface State {
        addressCountrySlideinOpen: boolean;
        licensePlateCountrySlideinOpen: boolean;
        licensePlateTypeSlideinOpen: boolean;
        badgeTypeSlideinOpen: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account-setup/AccountSetup.Layout',
        {
            addressCountrySlideinOpen: false,
            licensePlateCountrySlideinOpen: false,
            licensePlateTypeSlideinOpen: false,
            badgeTypeSlideinOpen: false,
        },
    );
}

export namespace Configuration {
    export interface State {
        accountType: CustomerAccountType;
        orderBadge?: boolean | null;
        useExistingBadge?: boolean | null;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account-setup/AccountSetup.Configuration',
        () => {
            return {
                accountType: CustomerAccountType.PRIVATE,
            };
        },
    );
}

export type YesNo = 'yes' | 'no';

export namespace SubmitResponse {
    export type State = WriteStateSlice.State;
    export const { get, setResponse } = WriteStateSlice.generate(
        'account-setup/AccountSetup.SubmitResponse',
    );
}
