import { Outlet, useOutletContext } from 'react-router-dom';

type CoreAppOutletContext = {
    hasRunningCheckinByPlate: boolean;
};

export function useCoreAppOutletContext() {
    return useOutletContext<CoreAppOutletContext>();
}

export function CoreAppOutlet({ context }: { context: CoreAppOutletContext }) {
    return <Outlet context={context} />;
}
