import { MultiLangString } from './MultiLangString';
import * as Sort from '../lib/Sort';

export interface LicensePlateType {
    id: number;
    name: MultiLangString;
}

export interface LicensePlateCountry {
    id: string;
    name: MultiLangString;
    licensePlateTypes: LicensePlateType[];
}

export function compareLicensePlateCountries(
    a: LicensePlateCountry,
    b: LicensePlateCountry,
): number {
    return Sort.arithmeticCompare(a.id, b.id, true);
}
