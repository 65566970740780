const STRING_STORE: { [idx: string]: StringId } = {};

export class StringId {
    private _value: string | null;
    constructor(id: string | null = null) {
        this._value = id;
        const existing = STRING_STORE[id as string];
        if (existing) {
            return existing;
        } else {
            STRING_STORE[id as string] = this;
        }
    }

    get value(): string {
        return this._value || '';
    }

    get isNull(): boolean {
        return this._value === undefined || this._value === null;
    }
}

const INTEGER_STORE: { [idx: number]: IntegerId } = {};

export class IntegerId {
    private _value: number | null;
    constructor(id: number | null = null) {
        this._value = id;
        const existing = INTEGER_STORE[id as number];
        if (existing) {
            return existing;
        } else {
            INTEGER_STORE[id as number] = this;
        }
    }

    get value(): number {
        return this._value || -1;
    }

    get isNull(): boolean {
        return this._value === undefined || this._value === null;
    }
}
