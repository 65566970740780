import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import {
    SignupErrorTexts,
    SignupSuccessTexts,
    SignupTexts,
} from '../components/Signup';
import { TosLink } from '../../legal/TosFiles';
import { PrivacyLink } from '../../legal/PrivacyPolicyFiles';
import Text = require('../../common/i18n/Text');

export const signupTexts: Text.Translations<SignupTexts> = {
    de: {
        emailLabel: () => 'E-Mail',
        passwordLabel: () => 'Passwort',
        passwordRepeatedLabel: () => 'Passwort bestätigen',
        acceptTos: () => (
            <span>
                Ich bin mit den <TosLink>AGB</TosLink> und{' '}
                <PrivacyLink>Datenschutzerklärung</PrivacyLink> einverstanden.
            </span>
        ),
        acceptTosDesktop: () => (
            <span>
                Ich bin mit den <TosLink>AGB</TosLink> und{' '}
                <PrivacyLink>Datenschutzerklärung</PrivacyLink> einverstanden.
            </span>
        ),
        contestAccepted: () => (
            <div>
                <div>Teilnahmebedigungen akzeptieren</div>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    Teilnahmeberechtigt sind in der Schweiz wohnhafte Personen,
                    ausgenommen Mitarbeitende der Digitalparking AG, Taxomex AG,
                    Parkomatic AG und ParkingTec AG.
                </p>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    Die Gewinner werden persönlich benachrichtigt. Über den
                    Wettbewerb wird keine Korrespondenz geführt. Barauszahlung
                    und Rechtsweg sind ausgeschlossen.
                </p>
            </div>
        ),
        signupButtonLabel: () => 'Registrieren',
        backToLogin: () => 'zum Login',
    },
    fr: {
        emailLabel: () => 'E-mail',
        passwordLabel: () => 'Mot de passe',
        passwordRepeatedLabel: () => 'Confirmer le mot de passe',
        acceptTos: () => (
            <span>
                J’accepte les <TosLink>CGV</TosLink> et la{' '}
                <PrivacyLink>
                    déclaration sur la protection des données
                </PrivacyLink>
                .
            </span>
        ),
        acceptTosDesktop: () => (
            <span>
                J’accepte les <TosLink>CGV</TosLink> et la{' '}
                <PrivacyLink>
                    déclaration sur la protection des données
                </PrivacyLink>
                .
            </span>
        ),
        contestAccepted: () => (
            <div>
                <div>Accepter les conditions de participation</div>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    Sont autorisées à participer les personnes domiciliées en
                    Suisse, à l'exception des collaborateurs de Digitalparking
                    SA, Taxomex SA, Parkomatic SA et ParkingTec SA.
                </p>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    Les gagnants seront informés personnellement par téléhone.
                    Aucune correspondance ne sera échangée au sujet du
                    jeu-concours. Tout recours juridique ou paiement en espèces
                    est exclu.
                </p>
            </div>
        ),
        signupButtonLabel: () => "S'enregistrer",
        backToLogin: () => 'à la connexion',
    },
    it: {
        emailLabel: () => 'E-mail',
        passwordLabel: () => 'Password',
        passwordRepeatedLabel: () => 'Conferma password',
        acceptTos: () => (
            <span>
                Sono d'accordo con le <TosLink>CG</TosLink> e{' '}
                <PrivacyLink>
                    l'informativa sulla protezione dei dati
                </PrivacyLink>
                .
            </span>
        ),
        acceptTosDesktop: () => (
            <span>
                Sono d'accordo con le <TosLink>CG</TosLink> e{' '}
                <PrivacyLink>
                    l'informativa sulla protezione dei dati
                </PrivacyLink>
                .
            </span>
        ),
        contestAccepted: () => (
            <div>
                <div>Accetta le condizioni di partecipazione</div>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    La partecipazione è aperta a tutte le persone residenti in
                    Svizzera, a esclusione dei collaboratori di Digitalparking
                    SA, Taxomex SA, Parkomatic SA e ParkingTec SA.
                </p>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    I vincitori saranno avvisati per telefono. Non verrà tenuta
                    alcuna corrispondenza in merito al concorso. Sono esclusi il
                    pagamento in contanti e le vie legali.
                </p>
            </div>
        ),
        signupButtonLabel: () => 'Registrarsi',
        backToLogin: () => "all'accesso",
    },
    en: {
        emailLabel: () => 'E-mail',
        passwordLabel: () => 'Password',
        passwordRepeatedLabel: () => 'Confirm password',
        acceptTos: () => (
            <span>
                I agree with the <TosLink>terms and conditions</TosLink> and the{' '}
                <PrivacyLink>privacy policy</PrivacyLink>.
            </span>
        ),
        acceptTosDesktop: () => (
            <span>
                I agree with the <TosLink>terms and conditions</TosLink> and{' '}
                <PrivacyLink>privacy policy</PrivacyLink>.
            </span>
        ),
        contestAccepted: () => (
            <div>
                <div>Teilnahmebedigungen akzeptieren</div>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    Teilnahmeberechtigt sind in der Schweiz wohnhafte Personen,
                    ausgenommen Mitarbeitende der Digitalparking AG, Taxomex AG,
                    Parkomatic AG und ParkingTec AG.
                </p>
                <p style={{ fontSize: 10, lineHeight: '12px' }}>
                    Die Gewinner werden persönlich benachrichtigt. Über den
                    Wettbewerb wird keine Korrespondenz geführt. Barauszahlung
                    und Rechtsweg sind ausgeschlossen.
                </p>
            </div>
        ),
        signupButtonLabel: () => 'Sign up',
        backToLogin: () => 'back to login',
    },
};

export const signupErrorTexts: Text.Translations<SignupErrorTexts> = {
    de: {
        requiredField: () => 'Pflichtfeld',
        invalidEmail: () => 'Diese Email-Adresse ist ungültig.',
        emailNotUnique: () =>
            'Diese E-Mail-Adresse ist bereits in einem Konto registriert.',
        emailFieldsDoNotMatch: () => 'E-Mail-Adressen stimmen nicht überein.',
        passwordFieldsDoNotMatch: () => 'Passwörter stimmen nicht überein.',
        signupFailure: () => GeneralTexts.texts.de.ErrorTryAgainOrHelpdesk(),
    },
    fr: {
        requiredField: () => 'Champ obligatoire',
        invalidEmail: () => "L'adresse e-mail n'est pas valide.",
        emailNotUnique: () =>
            'Cette adresse e-mail est déjà enregistrée dans un compte.',
        emailFieldsDoNotMatch: () =>
            'Les adresses e-mail ne correspondent pas.',
        passwordFieldsDoNotMatch: () =>
            'Les mots de passe ne correspondent pas',
        signupFailure: () => GeneralTexts.texts.fr.ErrorTryAgainOrHelpdesk(),
    },
    it: {
        requiredField: () => 'Campo obbligatorio',
        invalidEmail: () => "L'indirizzo e-mail non é valido.",
        emailNotUnique: () =>
            'Questo indirizzo e-mail è già registrato in un conto.',
        emailFieldsDoNotMatch: () =>
            'I due indirizzi e-mail inseriti non corrispondono.',
        passwordFieldsDoNotMatch: () =>
            'Le due password inserite non corrispondono.',
        signupFailure: () => GeneralTexts.texts.it.ErrorTryAgainOrHelpdesk(),
    },
    en: {
        requiredField: () => 'Required field',
        invalidEmail: () => 'The e-mail address is not valid.',
        emailNotUnique: () =>
            'This e-mail address is already registered in an account.',
        emailFieldsDoNotMatch: () =>
            'The two e-mail addresses you entered do not match.',
        passwordFieldsDoNotMatch: () =>
            'The two passwords you entered do not match.',
        signupFailure: () => GeneralTexts.texts.en.ErrorTryAgainOrHelpdesk(),
    },
};

export const signupSuccessTexts: Text.Translations<SignupSuccessTexts> = {
    de: {
        signupSuccess: () =>
            'Sie haben sich erfolgreich registriert. Sie werden in Kürze eine E-Mail zur Aktivierung ihres Kontos erhalten.',
        activateAccount: () => 'Account aktivieren',
    },
    fr: {
        signupSuccess: () =>
            'Vous vous êtes enregistré avec succès. Vous recevrez prochainement un e-mail pour activer votre compte.',
        activateAccount: () => 'Activer le compte',
    },
    it: {
        signupSuccess: () =>
            'La registrazione si è conclusa con successo. A breve riceverà un e-mail per attivare il suo conto.',
        activateAccount: () => 'Attiva conto',
    },
    en: {
        signupSuccess: () =>
            'You have successfully registered. You will shortly receive an email to activate your account.',
        activateAccount: () => 'Activate account',
    },
};
