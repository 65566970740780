import * as Flux from 'dg-web-shared/lib/Flux';
import { Store, useUpdate } from 'dg-web-shared/lib/Flux';
import { Message } from 'dg-web-shared/lib/Localized';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import * as Http from '../../api/Http';
import { Localized } from '../../common/components/Localized';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import * as AddressCountriesState from '../../common/state/AddressCountriesState';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as LanguagesState from '../../common/state/LanguagesState';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import * as SettingsState from '../../common/state/SettingsState';
import { logAction } from '../../utils/ActionLog';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { AddressEditFormSlideIn } from './AddressEditForm';
import { AccountNr } from './components/AccountNr';
import { CorrespondenceLanguageEditFormSlideIn } from './CorrespondenceLanguageEditForm';
import { DeleteAccountFormSlideIn } from './DeleteAccountForm';
import * as MetaTexts from './i18n/MetaTexts';
import { ShippingAddressEditFormSlideIn } from './ShippingAddressEditForm';
import * as MetaServerState from './state/MetaServerState';
import { CompanyEditFormSlideIn } from './CompanyEditForm';
import { PersonEditFormSlideIn } from './PersonEditForm';
import { FieldItemBlock } from '../root/components/ItemBlock';
import { ActionBlock } from '../root/components/ActionBlock';
import { useMatches, useNavigate } from 'react-router-dom';
import { ACCOUNT_DELETION_PATH } from '../../app/components/App';
import { useEffect, useState } from 'react';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export const MetaFormsSlideIn = portalSlideIn<{ allState: Store }>(MetaForms);

enum View {
    MENU,
    COMPANY_EDIT_FORM,
    PERSON_EDIT_FORM,
    ADDRESS_EDIT_FORM,
    SHIPPING_ADDRESS_EDIT_FORM,
    CORRESPONDENCE_LANGUAGE_EDIT_FORM,
    BILLING_REFERENCE_EDIT_FORM,
    DELETE_ACCOUNT_FORM,
}

function MetaForms(props: { allState: Store }) {
    const update = useUpdate();
    const navigate = useNavigate();

    const [view, setView] = useState(View.MENU);

    const matches = useMatches();
    const isSpecialDeleteRoute = matches.some(
        match => match.pathname === ACCOUNT_DELETION_PATH,
    );
    useEffect(() => {
        if (isSpecialDeleteRoute) {
            setView(View.DELETE_ACCOUNT_FORM);
        }
    }, [isSpecialDeleteRoute]);

    const { storeState } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        addressCountries: new AddressCountriesState.StateSlice(s).state,
        languages: new LanguagesState.StateSlice(s).state,
        metaServer: new MetaServerState.StateSlice(s).state,
        paymentType: new PaymentTypeState.StateSlice(s).state,
        accountBalance: new AccountBalanceState.StateSlice(s).state,
        currentLogin: CurrentLoginState.get(s),
    }));

    const isCompany =
        storeState.metaServer.data.customerAccountType ===
        CustomerAccountType.COMPANY;

    return (
        <>
            <AccountNr allState={props.allState} />

            {storeState.metaServer.data &&
            storeState.addressCountries.data &&
            storeState.languages.data &&
            storeState.paymentType.data &&
            storeState.accountBalance.data &&
            storeState.currentLogin.data ? (
                <>
                    {isCompany && (
                        <FieldItemBlock
                            onClick={() => {
                                setView(View.COMPANY_EDIT_FORM);
                            }}
                            label={<Localized {...messages.owner} />}
                            content={
                                storeState.metaServer.data.address.company1 +
                                (storeState.metaServer.data.address.company2
                                    ? ', ' +
                                      storeState.metaServer.data.address
                                          .company2
                                    : '')
                            }
                        />
                    )}
                    <FieldItemBlock
                        onClick={() => {
                            setView(View.PERSON_EDIT_FORM);
                        }}
                        label={
                            isCompany ? (
                                <Localized {...messages.contactPerson} />
                            ) : (
                                <Localized {...messages.owner} />
                            )
                        }
                        content={
                            storeState.metaServer.data.address.firstName +
                            ' ' +
                            storeState.metaServer.data.address.lastName
                        }
                    />
                    <FieldItemBlock
                        onClick={() => {
                            setView(View.ADDRESS_EDIT_FORM);
                        }}
                        label={<Localized {...messages.address} />}
                        content={
                            storeState.metaServer.data.address.street1 +
                            ', ' +
                            storeState.metaServer.data.address.city
                        }
                    />
                    <FieldItemBlock
                        onClick={() => {
                            setView(View.SHIPPING_ADDRESS_EDIT_FORM);
                        }}
                        label={<Localized {...messages.shippingAddress} />}
                        content={shippingAddressLabel(
                            storeState.metaServer.data,
                            MetaTexts.shippingTexts[
                                storeState.settings.language
                            ],
                        )}
                    />
                    <FieldItemBlock
                        onClick={() => {
                            setView(View.CORRESPONDENCE_LANGUAGE_EDIT_FORM);
                        }}
                        label={
                            <Localized {...messages.correspondenceLanguage} />
                        }
                        content={correspondenceLanguageLabel(
                            storeState.languages.data,
                            storeState.settings.language,
                            storeState.metaServer.data,
                        )}
                    />
                    <ActionBlock
                        title={<Localized {...messages.deleteAccount} />}
                        icon="delete"
                        onClick={() => {
                            setView(View.DELETE_ACCOUNT_FORM);

                            logAction(
                                props.allState,
                                'open-terminate-account-form',
                            );
                        }}
                    />
                    <CompanyEditFormSlideIn
                        title={<Localized {...messages.owner} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.COMPANY_EDIT_FORM}
                        onClose={() => {
                            setView(View.MENU);
                        }}
                        addressCountries={storeState.addressCountries.data}
                        accountMeta={storeState.metaServer.data}
                    />
                    <PersonEditFormSlideIn
                        title={<Localized {...messages.contactPerson} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.PERSON_EDIT_FORM}
                        onClose={() => {
                            setView(View.MENU);
                        }}
                        addressCountries={storeState.addressCountries.data}
                        accountMeta={storeState.metaServer.data}
                    />
                    <AddressEditFormSlideIn
                        title={<Localized {...messages.address} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.ADDRESS_EDIT_FORM}
                        onClose={() => {
                            setView(View.MENU);
                        }}
                        addressCountries={storeState.addressCountries.data}
                        accountMeta={storeState.metaServer.data}
                    />
                    <ShippingAddressEditFormSlideIn
                        title={<Localized {...messages.shippingAddress} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.SHIPPING_ADDRESS_EDIT_FORM}
                        onClose={() => {
                            setView(View.MENU);
                        }}
                        addressCountries={storeState.addressCountries.data}
                        accountMeta={storeState.metaServer.data}
                    />
                    <CorrespondenceLanguageEditFormSlideIn
                        title={
                            <Localized {...messages.correspondenceLanguage} />
                        }
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.CORRESPONDENCE_LANGUAGE_EDIT_FORM}
                        onClose={() => {
                            setView(View.MENU);
                        }}
                        languages={storeState.languages.data}
                        accountMeta={storeState.metaServer.data}
                    />
                    <DeleteAccountFormSlideIn
                        title={<Localized {...messages.deleteAccount} />}
                        portal={SlideInPortalId.USER_ACCOUNT}
                        open={view === View.DELETE_ACCOUNT_FORM}
                        onClose={() => {
                            if (isSpecialDeleteRoute) {
                                update(store => {
                                    new MetaFormsVisibilityStateSlice(
                                        store,
                                    ).toggle();
                                    return 'closeMetaFormsBeforeNavigate';
                                });
                                navigate('/');
                            }
                            setView(View.MENU);
                        }}
                        paymentType={storeState.paymentType.data}
                        accountBalance={storeState.accountBalance.data}
                        currentLogin={storeState.currentLogin.data}
                    />
                </>
            ) : (
                <Spinner />
            )}
        </>
    );
}

function correspondenceLanguageLabel(
    languages: Http.Language[],
    uiLanguage: string,
    accountMeta: MetaServerState.AccountMeta,
): string {
    const matchingLanguage = languages.find(
        l => l.shortcut === accountMeta.correspondenceLanguageId,
    );

    return matchingLanguage ? matchingLanguage.name[uiLanguage] : '';
}

function shippingAddressLabel(
    accountMeta: MetaServerState.AccountMeta,
    texts: MetaTexts.ShippingAddressTexts,
): string {
    const address = accountMeta.shippingAddress;

    if (!address || !address.line1) {
        return lowercaseFirstLetter(texts.LikeAddress());
    }

    return `${address.line1}, ${address.city}`;
}

function lowercaseFirstLetter(str: string): string {
    return str.length > 0 ? str.charAt(0).toLowerCase() + str.slice(1) : '';
}

export interface MetaFormsVisibilityState {
    expanded: boolean;
}

export class MetaFormsVisibilityStateSlice extends Flux.StateSlice<MetaFormsVisibilityState> {
    key(): string {
        return 'account-meta-Meta';
    }

    sideEffects(): void {
        return;
    }

    getInitialState(): MetaFormsVisibilityState {
        return {
            expanded: false,
        };
    }

    toggle(): void {
        this.set((s: MetaFormsVisibilityState): MetaFormsVisibilityState => {
            s.expanded = !s.expanded;
            return s;
        });
    }
}

export function toggleMetaFormsVisibility(): Flux.Write {
    return (store: Flux.Store): void => {
        new MetaFormsVisibilityStateSlice(store).toggle();
    };
}

const messages: Messages = {
    owner: {
        de: 'Inhaber',
        fr: 'Titulaire',
        it: 'Titolare',
        en: 'Owner',
    },
    contactPerson: {
        de: 'Kontaktperson',
        fr: 'Personne de contact',
        it: 'Persona di contatto',
        en: 'Contact person',
    },
    address: {
        de: 'Adresse',
        fr: 'Adresse',
        it: 'Indirizzo',
        en: 'Address',
    },
    correspondenceLanguage: {
        de: 'Korrespondenzsprache',
        fr: 'Langue de correspondance',
        it: 'Lingua di corrispondenza',
        en: 'Correspondence language',
    },
    deleteAccount: {
        de: 'Konto kündigen',
        fr: 'Résilier le compte',
        it: 'Disdire il conto',
        en: 'Delete account',
    },
    none: {
        de: 'leer',
        fr: 'vide',
        it: 'vuoto',
        en: 'none',
    },
    shippingAddress: {
        de: 'Versandadresse',
        fr: 'Adresse de livraison',
        it: "Indirizzo d'invio",
        en: 'Shipping address',
    },
};

type Messages = {
    owner: Message;
    contactPerson: Message;
    address: Message;
    correspondenceLanguage: Message;
    deleteAccount: Message;
    none: Message;
    shippingAddress: Message;
};
