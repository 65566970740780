import { Store, useStore } from 'dg-web-shared/lib/Flux';
import * as Text from '../../../common/i18n/Text';
import { BasicButton } from '../../../../../tb-ui/buttons/BasicButton';
import { ButtonRow } from '../license-plate/InfoBoxes';
import { Customer, LicensePlate } from '../../state/shared/EntityData';
import * as TransactionState from '../../state/zone-transaction/TransactionState';
import {
    TextField,
    Props as TextFieldProps,
} from '../../../../../tb-ui/inputs/TextField';
import { ClearanceRequestCustomField } from '../../../../../operator-app/src/common/models/ClearanceRequest';
import {
    InlineAlertBox,
    ArrowPosition,
    InlineErrorBox,
} from '../../../ui/modals/Confirmable';
import { TransmitsAddressToOperatorText } from './PermitPurchase';
import { Localized } from '../../../common/components/Localized';
import { css } from '@emotion/css';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';
import { languageFromString } from 'dg-web-shared/lib/Text';
import {
    Control,
    Controller,
    FieldValues,
    Path,
    PathValue,
} from 'react-hook-form';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import { useState } from 'react';

export interface Texts {
    bodyTransaction: (lp: string) => string;
    bodySingular: (lp: string) => string;
    bodyPlural: (lp: string[]) => string;
    button: Text.Translation;
    operatorInfo: (lp: string) => string;
    pendingLpInfoSingular: (lp: string) => string;
    pendingLpInfoPlural: (lp: string[]) => string;
    pendingInfo: () => string;
}

type CustomFields = Record<string, string>;

function clearanceCustomFieldsToFormFields(
    clearanceRequestCustomFields: ClearanceRequestCustomField[],
) {
    return clearanceRequestCustomFields.reduce<CustomFields>(
        (fieldObject, singleField) => {
            fieldObject[singleField.id.toString()] = '';

            return fieldObject;
        },
        {},
    );
}

interface WhitelistRequestProps {
    isTransaction: boolean;
    lps: LicensePlate[];
    customers: Customer[];
    operatorName: string;
    operatorContactDetails: string | null;
    permitTypeId: number;
    clearanceRequestCustomFields: ClearanceRequestCustomField[];
    refetchTypeData: () => void;
}

export function WhitelistRequest({
    isTransaction,
    lps,
    customers,
    operatorName,
    operatorContactDetails,
    permitTypeId,
    clearanceRequestCustomFields,
    refetchTypeData,
}: WhitelistRequestProps) {
    const language = languageFromString(useParkingpayLanguage());
    const { update } = useStore(() => ({}));
    const [submitState, submit] = useServerWrite<
        {
            permitTypeId: number;
            licensePlateIds: number[];
            customFields: {
                id: number;
                value: string;
            }[];
        },
        null,
        ValidationData
    >(() => ({
        url: '/ui-api/customer-account/permits/clearance-request',
    }));
    const [showPermissionError, setShowPermissionError] = useState(false);

    const {
        formInfo: { handleSubmit, control, watch },
    } = useEasyForm(undefined, submitState, language, {
        defaultValues: clearanceCustomFieldsToFormFields(
            clearanceRequestCustomFields,
        ),
    });

    useServerErrorEffect(submitState, statusCode => {
        if (statusCode === 403) {
            setShowPermissionError(true);
        }
    });

    useServerSuccessEffect(submitState, () => {
        update((store: Store): string => {
            TransactionState.ParkTransaction.refetchSameContext(store, false);
            refetchTypeData();

            return 'clearanceRequested';
        });
    });

    function onSubmit(formValues: CustomFields) {
        submit({
            permitTypeId,
            licensePlateIds: lps.map(lp => lp.id),
            customFields: Object.keys(formValues).map((id: string) => ({
                id: parseInt(id, 10),
                value: formValues[id],
            })),
        });
    }

    const lpNumbrs = lps.map(lp => lp.licensePlateNr);
    const customerNumbrs = customers.map(c => c.customerNr);
    const submitDisabled =
        submitState.status === RequestStatus.PENDING ||
        anyFieldEmpty(clearanceRequestCustomFields, watch()) ||
        showPermissionError;

    return (
        <InlineAlertBox
            arrowPosition={ArrowPosition.left}
            titleCaption={
                <Localized
                    de="Freigabe-Pflicht"
                    fr="Approbation requise"
                    it="Approvazione necessaria"
                    en="Approval required"
                />
            }
        >
            <form
                onSubmit={
                    submitDisabled
                        ? e => e.preventDefault() // the submit button will trigger even if the disabled prop is passed as true
                        : handleSubmit(onSubmit)
                }
            >
                <p>
                    <ClearanceRequiredText
                        isTransaction={isTransaction}
                        lpNumbrs={lpNumbrs}
                        customerNumbrs={customerNumbrs}
                    />
                </p>
                <p>
                    <TransmitsAddressToOperatorText />
                </p>
                <OperatorContactText
                    operatorName={operatorName}
                    operatorContactDetails={operatorContactDetails}
                />
                <div>
                    {clearanceRequestCustomFields.map(f => (
                        <ReactHookFormLegagyTextField
                            key={f.id.toString()}
                            labelText={
                                <Localized
                                    de={f.nameDe}
                                    fr={f.nameFr}
                                    it={f.nameIt}
                                    en={f.nameEn}
                                />
                            }
                            control={control}
                            name={f.id.toString()}
                        />
                    ))}
                </div>
                {showPermissionError && <PermissionError />}
                <div className={css({ paddingBottom: '18px' })}>
                    <ButtonRow>
                        <BasicButton
                            negative
                            label={
                                <Localized
                                    de="Beantragen"
                                    fr="Appliquer"
                                    it="Inoltra richiesta"
                                    en="Submit request"
                                />
                            }
                            disabled={submitDisabled}
                            onClick={null}
                        />
                    </ButtonRow>
                </div>
            </form>
        </InlineAlertBox>
    );
}

function PermissionError() {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Fehlende Berechtigungen"
                    fr="Autorisations manquantes"
                    it="Autorizzazioni mancanti"
                    en="Missing permissions"
                />
            }
        >
            <p>
                <Localized
                    de="Nur Administratoren können Freigaben beantragen."
                    fr="Seuls les administrateurs peuvent demander des approbations."
                    it="Solo gli amministratori possono richiedere le approvazioni."
                    en="Only administrators can request approvals."
                />
            </p>
        </InlineErrorBox>
    );
}

interface ReactHookFormLegagyTextFieldProps<FormFields extends FieldValues>
    extends Omit<TextFieldProps, 'onChange' | 'value'> {
    control: Control<FormFields>;
    name: Path<FormFields>;
}

function ReactHookFormLegagyTextField<FormFields extends FieldValues>({
    control,
    name,
    ...props
}: ReactHookFormLegagyTextFieldProps<FormFields>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    name={name}
                    errorText={
                        !field.value || (field.value as string).length <= 0 ? (
                            <Localized
                                de="Pflichtfeld"
                                fr="Ce champ est obligatoire"
                                it="Campo obbligatorio"
                                en="Mandatory field"
                            />
                        ) : null
                    }
                    value={field.value as string}
                    onChange={e =>
                        field.onChange(
                            e as PathValue<FormFields, Path<FormFields>>,
                        )
                    }
                    {...props}
                />
            )}
        />
    );
}

const anyFieldEmpty = (
    clearanceRequestCustomFields: ClearanceRequestCustomField[],
    fieldValues: CustomFields,
): boolean => {
    if (clearanceRequestCustomFields.length === 0) {
        return false;
    }
    return clearanceRequestCustomFields.some(
        f =>
            fieldValues[f.id] === null ||
            fieldValues[f.id] === undefined ||
            fieldValues[f.id].length === 0,
    );
};

export const WhitelistRequestPending = ({
    operatorName,
    operatorContactDetails,
}: {
    operatorName: string;
    operatorContactDetails: string | null;
}) => {
    return (
        <InlineAlertBox
            arrowPosition={ArrowPosition.left}
            titleCaption={
                <Localized
                    de="Freigabe beantragt"
                    fr="Approbation en cours"
                    it="Approvazione in sospeso"
                    en="Approval pending"
                />
            }
        >
            <p>
                <Localized
                    de={`Der Antrag ist vom Betreiber noch nicht bearbeitet worden.`}
                    fr={`La demande est encore en cours de traitement par l'exploitant.`}
                    it={`La richiesta non è ancora stata elaborata da parte del gestore.`}
                    en={`The request has not yet been processed by the operator.`}
                />
            </p>
            <OperatorContactText
                operatorName={operatorName}
                operatorContactDetails={operatorContactDetails}
            />
        </InlineAlertBox>
    );
};

function ClearanceRequiredText({
    isTransaction,
    lpNumbrs,
    customerNumbrs,
}: {
    isTransaction: boolean;
    lpNumbrs: string[];
    customerNumbrs: number[];
}) {
    if (isTransaction) {
        if (customerNumbrs.length === 1) {
            return (
                <Localized
                    de={`Ihr Konto ${customerNumbrs[0]} ist für einen Parkvorgang in der gewählten Zone nicht freigeschaltet und muss zuerst beantragt werden.`}
                    fr={`Votre compte ${customerNumbrs[0]} ne dispose pas de l'approbation nécessaire pour démarrer une procedure dans la zone sélectionnée. Vous devez d'abord faire une demande.`}
                    it={`Il suo conto ${customerNumbrs[0]} non dispone dell'approvazione necessaria per avviare una procedura nella zona selezionata. Deve quindi prima inoltrare una richiesta.`}
                    en={`Your account ${customerNumbrs[0]} does not have the necessary approval to initiate a procedure in the selected zone. You must therefore first submit a request.`}
                />
            );
        } else {
            return (
                <Localized
                    de={`Das Kennzeichen ${lpNumbrs[0]} ist für einen Parkvorgang in der gewählten Zone nicht freigeschaltet und muss zuerst beantragt werden.`}
                    fr={`L'immatriculation ${lpNumbrs[0]} ne dispose pas de l'approbation nécessaire pour démarrer une procedure dans la zone sélectionnée. Vous devez d'abord faire une demande.`}
                    it={`La targa ${lpNumbrs[0]} non dispone dell'approvazione necessaria per avviare una procedura nella zona selezionata. Deve quindi prima inoltrare una richiesta.`}
                    en={`The license plate ${lpNumbrs[0]} does not have the necessary approval to initiate a procedure in the selected zone. You must therefore first submit a request.`}
                />
            );
        }
    }

    if (lpNumbrs.length === 1) {
        return (
            <Localized
                de={`Das Kennzeichen ${lpNumbrs[0]} ist für die gewählte Bewilligung nicht freigeschaltet und muss zuerst beantragt werden.`}
                fr={`L'immatriculation ${lpNumbrs[0]} ne dispose pas de l'approbation nécessaire pour l'achat de l'autorisation. Vous devez d'abord faire une demande.`}
                it={`Il numero di targa ${lpNumbrs[0]} non dispone dell'approvazione necessaria per l'acquisto dell'autorizzazione selezionata. Deve quindi prima inoltrare una richiesta.`}
                en={`The license plate ${lpNumbrs[0]} does not have the necessary approval to purchase the selected permit. You must therefore first submit a request.`}
            />
        );
    }

    if (customerNumbrs.length === 1) {
        return (
            <Localized
                de={`Ihr Konto ${customerNumbrs[0]} ist für die gewählte Bewilligung nicht freigeschaltet und muss zuerst beantragt werden.`}
                fr={`Le compte ${customerNumbrs[0]} ne dispose pas de l'approbation nécessaire pour l'achat de l'autorisation. Vous devez d'abord faire une demande.`}
                it={`Il conto ${customerNumbrs[0]} non dispone dell'approvazione necessaria per l'acquisto dell'autorizzazione selezionata. Deve quindi prima inoltrare una richiesta.`}
                en={`Your account ${customerNumbrs[0]} does not have the necessary approval to purchase the selected permit. You must therefore first submit a request.`}
            />
        );
    }

    return (
        <Localized
            de={`Die Kennzeichen ${lpNumbrs.join(
                ', ',
            )} sind für die gewählte Bewilligung nicht freigeschaltet und müssen zuerst beantragt werden.`}
            fr={`Les immatriculations ${lpNumbrs.join(
                ', ',
            )} ne disposent pas de l'approbation nécessaire pour l'achat de l'autorisation. Vous devez d'abord faire une demande.`}
            it={`I numeri di targa ${lpNumbrs.join(
                ', ',
            )} non dispongono dell'approvazione necessaria per l'acquisto dell'autorizzazione selezionata. Deve quindi prima inoltrare una richiesta.`}
            en={`The license plates ${lpNumbrs.join(
                ', ',
            )} do not have the necessary approval to purchase the selected permit. You must therefore submit a request first.`}
        />
    );
}

function OperatorContactText({
    operatorName,
    operatorContactDetails,
}: {
    operatorName: string;
    operatorContactDetails?: string | null;
}) {
    const lastChar = operatorContactDetails ? ':' : '.';
    const operatorContactText = (
        <p>
            <Localized
                de={`Für weitere Fragen oder bei Unklarheiten wenden Sie sich direkt an den Betreiber ${operatorName}${lastChar}`}
                fr={`Pour toutes questions ou doutes contactez l'exploitant ${operatorName}${lastChar}`}
                it={`In caso di domande o dubbi si rivolga direttamente al gestore ${operatorName}${lastChar}`}
                en={`If you have any questions or concerns, please contact the operator ${operatorName} directly${lastChar}`}
            />
        </p>
    );

    if (!operatorContactDetails) {
        return operatorContactText;
    }

    return (
        <>
            {operatorContactText}
            <p>
                <span
                    className={css({
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-all',
                        fontWeight: 'bold',
                    })}
                >
                    {operatorContactDetails}
                </span>
            </p>
        </>
    );
}
