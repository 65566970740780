import * as superagent from 'superagent';
import { RequestStatus } from '../../lib/hooks/ServerStateHooks';
import { generateState } from '../../lib/Flux';
import { TwintParkingpayPendingState } from './TwintPairingParkingpay';
import { useEffect, useState } from 'react';

export enum PairingState {
    UNKNOWN = 'UNKNOWN',
    CREATED = 'CREATED',
    CONFIRMED_BY_USER = 'CONFIRMED_BY_USER',
    SUCCESS = 'SUCCESS',
    ABORTED = 'ABORTED',
}

export namespace PendingTwintPairing {
    export enum TwintOrderMethodType {
        COMBINED = 'COMBINED',
        ORDER = 'ORDER',
        UOF = 'UOF',
    }

    export interface State {
        paymentAttemptId: number | null;
        pairingToken: string | null;
        mode: TwintOrderMethodType;
    }

    export const { get, set, reset } = generateState<State>(
        'twint-pairing-token',
        {
            pairingToken: null,
            paymentAttemptId: null,
            mode: TwintOrderMethodType.ORDER,
        },
    );
}

export type TwintPaymentStateUnknown = {
    mode:
        | PendingTwintPairing.TwintOrderMethodType.ORDER
        | PendingTwintPairing.TwintOrderMethodType.COMBINED;
    state: PairingState.UNKNOWN;
    requestState: RequestStatus;
};
export type TwintPaymentStateKnown = {
    mode:
        | PendingTwintPairing.TwintOrderMethodType.ORDER
        | PendingTwintPairing.TwintOrderMethodType.COMBINED;
    state: PairingState;
    requestState: RequestStatus;
    amount: string;
};
type TwintPaymentState = TwintPaymentStateUnknown | TwintPaymentStateKnown;

export interface TwintUofStateUnknown {
    mode: PendingTwintPairing.TwintOrderMethodType.UOF;
    state: PairingState.UNKNOWN;
    requestState: RequestStatus;
}
export interface TwintUofStateKnown {
    mode: PendingTwintPairing.TwintOrderMethodType.UOF;
    state: PairingState;
    requestState: RequestStatus;
}

type TwintUofState = TwintUofStateUnknown | TwintUofStateKnown;
export type TwintPendingState = TwintPaymentState | TwintUofState;

export enum UoFState {
    CREATED = 'CREATED',
    REQUESTED = 'REQUESTED',
    PAIRED = 'PAIRED',
    ABORTED = 'ABORTED',
    SUCCESS = 'SUCCESS',
}

export function transactionFromUoFState(state: UoFState) {
    switch (state) {
        case UoFState.ABORTED:
            return PairingState.ABORTED;
        case UoFState.SUCCESS:
            return PairingState.SUCCESS;
        case UoFState.PAIRED:
            return PairingState.CONFIRMED_BY_USER;
        case UoFState.REQUESTED:
        case UoFState.CREATED:
            return PairingState.CREATED;
    }
}

export interface TwintUoFStateResponse {
    state: UoFState;
}

export type TwintPairingState = (
    mode: PendingTwintPairing.TwintOrderMethodType,
    requestCounter: number,
    token: string | null,
    paymentAttemptId: number | null,
) => TwintPendingState | TwintParkingpayPendingState;

export function abortTwintTransaction(
    abortRequest: superagent.Request<unknown, unknown>,
    onDone?: () => void,
) {
    const onEnd = () => {
        if (onDone) {
            onDone();
        }
    };
    return abortRequest.end(onEnd);
}

function getInitialState(
    mode: PendingTwintPairing.TwintOrderMethodType,
): TwintPendingState | TwintParkingpayPendingState {
    return mode === PendingTwintPairing.TwintOrderMethodType.ORDER
        ? {
              mode: PendingTwintPairing.TwintOrderMethodType.ORDER,
              state: PairingState.UNKNOWN,
              requestState: RequestStatus.NEVER_EXECUTED,
          }
        : {
              mode: PendingTwintPairing.TwintOrderMethodType.UOF,
              state: PairingState.UNKNOWN,
              requestState: RequestStatus.NEVER_EXECUTED,
          };
}

export function useTwintState<T extends TwintPendingState>(
    token: string | null,
    paymentAttemptId: number | null,
    mode: PendingTwintPairing.TwintOrderMethodType,
    stateState: TwintPairingState,
): T {
    const [requestCounter, setRequestCounter] = useState(0);
    const [lastState, setLastState] = useState<
        TwintPendingState | TwintParkingpayPendingState
    >(getInitialState(mode));

    useEffect(() => {
        setLastState(getInitialState(mode));
    }, [token, paymentAttemptId]);

    const pairingState = stateState(
        mode,
        requestCounter,
        token,
        paymentAttemptId,
    );

    useEffect(() => {
        if (
            pairingState.state === PairingState.SUCCESS ||
            pairingState.state === PairingState.ABORTED
        ) {
            return;
        }
        const interval = setInterval(() => {
            if (pairingState.requestState !== RequestStatus.PENDING) {
                setRequestCounter(requestCounter => requestCounter + 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [pairingState]);

    useEffect(() => {
        if (
            pairingState.state !== PairingState.UNKNOWN &&
            pairingState.state !== lastState.state
        ) {
            setLastState(pairingState);
        }
    }, [pairingState.state]);

    return <T>lastState;
}
