import * as Flux from 'dg-web-shared/lib/Flux';

export enum SlideInType {
    NONE,
    LOOSE_BADGES,
    REMINDER_EDIT,
}

export interface State {
    previousType: SlideInType;
    type: SlideInType;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'account-root-SlideInState';
    }
    sideEffects(): null {
        return null;
    }
    getInitialState(): State {
        return {
            previousType: SlideInType.NONE,
            type: SlideInType.NONE,
        };
    }

    setType(type: SlideInType): void {
        this.set((s: State): State => {
            s.previousType = s.type;
            s.type = type;
            return s;
        });
    }
}

export namespace About {
    export interface State {
        slideinOpen?: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'account/root/SlideInState.About',
        { slideinOpen: false },
    );
}
