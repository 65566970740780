import { useNavigate, useParams } from 'react-router-dom';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import { logAction } from '../../utils/ActionLog';
import * as ParkCreateActions from '../actions/ParkCreateActions';
import { usePermitPayloadContext } from './permit/PermitPayloadContext';
import { ModalErrorBox } from '../../ui/modals/Confirmable';
import { Localized } from '../../common/components/Localized';
import { INVALID_SCAN_PATH } from '../../app/components/App';

export function DeepLinkConverter() {
    const update = useUpdate();
    const navigate = useNavigate();
    const requestContext = useRequestContextFromParams();
    const { setSelectedPermitTypeId } = usePermitPayloadContext();

    const [contractTemplateUrlState] = useServerFetch<
        ContractTemplateData,
        DeepLinkScanContext,
        DeepLinkScanError
    >(
        context => ({
            url: `/ui-api/customer-account/deeplink-scan/${context.identification}/${context.type}`,
        }),
        requestContext,
    );

    useServerSuccessEffect(contractTemplateUrlState, data => {
        update(store => {
            logAction(store, 'path-id-redirect', data);
            ParkCreateActions.setCity(store, data.zipcode);
            switch (data.type) {
                case DeepLinkType.PERMIT:
                    setSelectedPermitTypeId(data.permittypeId);
                    ParkCreateActions.parkVariantSelectPermit(store, false);
                    break;
                case DeepLinkType.ZONE:
                    ParkCreateActions.setParkOption(store, data.zoneId);
                    ParkCreateActions.parkVariantSelectLicensePlateTransaction(
                        store,
                    );
                    break;
            }
            return 'path-id-redirect';
        });
        navigate('/');
    });

    useServerErrorEffect(contractTemplateUrlState, (statusCode, data) => {
        if (statusCode === 500 || !data?.type || !data?.error) {
            navigate('/' + INVALID_SCAN_PATH);
        }
    });

    if (
        contractTemplateUrlState.status === RequestStatus.ERROR &&
        contractTemplateUrlState.data
    ) {
        return (
            <DeepLinkRedirectErrorModal
                deepLinkScanError={contractTemplateUrlState.data}
                onClose={() => navigate('/')}
            />
        );
    }

    return null;
}

function useRequestContextFromParams(): DeepLinkScanContext | null {
    const {
        zoneUrlIdentification,
        permitUrlIdentification,
        twintQrCodeString,
    } = useParams();

    if (zoneUrlIdentification) {
        return {
            type: DeepLinkType.ZONE,
            identification: zoneUrlIdentification,
        };
    }

    if (permitUrlIdentification) {
        return {
            type: DeepLinkType.PERMIT,
            identification: permitUrlIdentification,
        };
    }

    if (twintQrCodeString) {
        return {
            type: DeepLinkType.TWINT_QR_CODE,
            identification: twintQrCodeString,
        };
    }

    return null;
}

export interface DeepLinkScanError {
    type: DeepLinkType;
    error: DeepLinkErrorType;
}
export enum DeepLinkErrorType {
    NOT_FOUND = 'NOT_FOUND',
    NOT_ACTIVE = 'NOT_ACTIVE',
    NOT_ALLOWED = 'NOT_ALLOWED',
}
export type ContractTemplateData = ZoneData | PermitData;

interface ContractTemplateBaseData {
    type: DeepLinkType;
    zipcode: string;
}

interface ZoneData extends ContractTemplateBaseData {
    type: DeepLinkType.ZONE;
    zoneId: number;
}

interface PermitData extends ContractTemplateBaseData {
    type: DeepLinkType.PERMIT;
    permittypeId: number;
}

type DeepLinkScanContext = {
    type: DeepLinkType;
    identification: string;
};

const enum DeepLinkType {
    ZONE = 'ZONE',
    PERMIT = 'PERMIT',
    TWINT_QR_CODE = 'TWINT_QR_CODE',
}

export function DeepLinkRedirectErrorModal({
    deepLinkScanError,
    onClose,
}: {
    deepLinkScanError: DeepLinkScanError;
    onClose: () => void;
}) {
    return (
        <ModalErrorBox
            confirmCallback={onClose}
            titleCaption={
                <DeepLinkScanErrorTitle deepLinkScanError={deepLinkScanError} />
            }
        >
            <p>
                <DeepLinkScanErrorDescription
                    deepLinkScanError={deepLinkScanError}
                />
            </p>
        </ModalErrorBox>
    );
}

function DeepLinkScanErrorTitle({
    deepLinkScanError,
}: {
    deepLinkScanError: DeepLinkScanError;
}) {
    switch (deepLinkScanError.type) {
        case DeepLinkType.ZONE: {
            return (
                <DeepLinkZoneScanErrorTitle
                    errorType={deepLinkScanError.error}
                />
            );
        }
        case DeepLinkType.PERMIT: {
            return (
                <DeepLinkPermitScanErrorTitle
                    errorType={deepLinkScanError.error}
                />
            );
        }
        case DeepLinkType.TWINT_QR_CODE: {
            return (
                <Localized
                    de="Zone / Bewilligung nicht gefunden"
                    fr="Zone / Autorisation inconnue"
                    it="Zona / Autorizzazione non trovata"
                    en="Zone / Permit not found"
                />
            );
        }
    }
}

function DeepLinkZoneScanErrorTitle({
    errorType,
}: {
    errorType: DeepLinkErrorType;
}) {
    switch (errorType) {
        case DeepLinkErrorType.NOT_FOUND:
            return (
                <Localized
                    de="Zone nicht gefunden"
                    fr="Zone inconnue"
                    it="Zona non trovata"
                    en="Zone not found"
                />
            );
        case DeepLinkErrorType.NOT_ACTIVE:
            return (
                <Localized
                    de="Zone inaktiv"
                    fr="Zone inactive"
                    it="Zona inattiva"
                    en="Inactive zone"
                />
            );
        case DeepLinkErrorType.NOT_ALLOWED:
            return (
                <Localized
                    de="Parkvorgang nicht erlaubt"
                    fr="Stationnement non permis"
                    it="Sosta non consentita"
                    en="Parking not allowed"
                />
            );
    }
}

export function DeepLinkPermitScanErrorTitle({
    errorType,
}: {
    errorType: DeepLinkErrorType;
}) {
    switch (errorType) {
        case DeepLinkErrorType.NOT_FOUND:
            return (
                <Localized
                    de="Bewilligung nicht gefunden"
                    fr="Autorisation inconnue"
                    it="Autorizzazione non trovata"
                    en="Permit not found"
                />
            );
        case DeepLinkErrorType.NOT_ACTIVE:
            return (
                <Localized
                    de="Bewilligung inaktiv"
                    fr="Autorisation inactive"
                    it="Autorizzazione inattiva"
                    en="Inactive permit"
                />
            );
        case DeepLinkErrorType.NOT_ALLOWED:
            return (
                <Localized
                    de="Kauf nicht erlaubt"
                    fr="Achat non permis"
                    it="Acquisto non consentito"
                    en="Purchase not allowed"
                />
            );
    }
}

function DeepLinkScanErrorDescription({
    deepLinkScanError,
}: {
    deepLinkScanError: DeepLinkScanError;
}) {
    switch (deepLinkScanError.type) {
        case DeepLinkType.ZONE: {
            return (
                <DeepLinkZoneScanErrorDescription
                    errorType={deepLinkScanError.error}
                />
            );
        }
        case DeepLinkType.PERMIT: {
            return (
                <DeepLinkPermitScanErrorDescription
                    errorType={deepLinkScanError.error}
                    isParkingpay={true}
                />
            );
        }
        case DeepLinkType.TWINT_QR_CODE: {
            return (
                <Localized
                    de="Es wurde keine Zone / Bewilligung gefunden, die mit der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht."
                    fr="Aucune zone / autorisation correspondant à l'adresse URL saisie ou au code QR scanné n'a été trouvée."
                    it="Non è stata trovata alcuna zone / autorizzazione corrispondente all’indirizzo URL inserito risp. al codice QR scansionato."
                    en="No zone / permit was found corresponding to the entered URL address or the scanned QR code."
                />
            );
        }
    }
}

function DeepLinkZoneScanErrorDescription({
    errorType,
}: {
    errorType: DeepLinkErrorType;
}) {
    switch (errorType) {
        case DeepLinkErrorType.NOT_FOUND:
            return (
                <Localized
                    de="Es wurde keine Zone gefunden, die mit der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht."
                    fr="Aucune zone correspondant à l'adresse URL saisie ou au code QR scanné n'a été trouvée."
                    it="Non è stata trovata alcuna zona corrispondente all’indirizzo URL inserito risp. al codice QR scansionato."
                    en="No zone was found corresponding to the entered URL address or the scanned QR code."
                />
            );
        case DeepLinkErrorType.NOT_ACTIVE:
            return (
                <Localized
                    de="Die Zone, die der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht, ist nicht mehr aktiv und daher kann kein Parkvorgang gestartet werden."
                    fr="La zone correspondant à l'adresse URL saisie ou au code QR scanné n'est plus active et donc un stationnement ne peut pas être démarré."
                    it="La zona corrispondente all’indirizzo URL inserito risp. al codice QR scansionato non è più attiva e quindi non può essere avviata una sosta."
                    en="The zone corresponding to the entered URL address or the scanned QR code is no longer active and therefore a parking procedure cannot be started."
                />
            );
        case DeepLinkErrorType.NOT_ALLOWED:
            return (
                <Localized
                    de="In der Zone, die der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht, kann mit Ihrem Konto kein Parkvorgang gestartet werden."
                    fr="Dans la zone correspondant à l'adresse URL saisie ou au code QR scanné, il n'est pas possible de démarrer un stationnement avec votre compte."
                    it="Nella zona corrispondente all’indirizzo URL inserito risp. al codice QR scansionato non può essere avviata una sosta tramite il suo conto."
                    en="No parking procedure can be started using your account in the zone corresponding to the URL address entered or the QR code scanned."
                />
            );
    }
}

export function DeepLinkPermitScanErrorDescription({
    errorType,
    isParkingpay,
}: {
    errorType: DeepLinkErrorType;
    isParkingpay: boolean;
}) {
    switch (errorType) {
        case DeepLinkErrorType.NOT_FOUND:
            return (
                <Localized
                    de="Es wurde keine Bewilligung gefunden, die mit der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht."
                    fr="Aucune autorisation correspondant à l'adresse URL saisie ou au code QR scanné n'a été trouvée."
                    it="Non è stata trovata alcuna autorizzazione corrispondente all’indirizzo URL inserito risp. al codice QR scansionato."
                    en="No permit was found corresponding to the entered URL address or the scanned QR code."
                />
            );
        case DeepLinkErrorType.NOT_ACTIVE:
            return (
                <Localized
                    de="Die Bewilligung, die der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht, ist nicht mehr aktiv und kann daher nicht gekauft werden."
                    fr="L'autorisation correspondant à l'adresse URL saisie ou au code QR scanné n'est plus active et ne peut donc pas être achetée."
                    it="L'autorizzazione corrispondente all’indirizzo URL inserito risp. al codice QR scansionato non è più attiva e quindi non può essere acquistata."
                    en="The permit corresponding to the entered URL address or the scanned QR code is no longer active and therefore cannot be purchased."
                />
            );
        case DeepLinkErrorType.NOT_ALLOWED:
            return isParkingpay ? (
                <Localized
                    de="Die Bewilligung, die mit der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht, kann nicht mit Ihr Konto erworben werden."
                    fr="L'autorisation correspondant à l'adresse URL saisie ou au code QR scanné ne peut pas être achetée avec votre compte."
                    it="L'autorizzazione corrispondente all’indirizzo URL inserito risp. al codice QR scansionato non può essere acquistata tramite il suo conto."
                    en="The permit corresponding to the entered URL address or the scanned QR code cannot be purchased using your account."
                />
            ) : (
                <Localized
                    de="Die Bewilligung, die mit der eingegebenen URL-Adresse oder dem gescannten QR-Code entspricht, kann nicht mit diesem Kanal erworben werden."
                    fr="L'autorisation correspondant à l'adresse URL saisie ou au code QR scanné ne peut pas être achetée avec ce canal."
                    it="L'autorizzazione corrispondente all’indirizzo URL inserito risp. al codice QR scansionato non può essere acquistata tramite questo canale."
                    en="The permit corresponding to the entered URL address or the scanned QR code cannot be purchased using this channel."
                />
            );
    }
}
