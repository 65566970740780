import { css } from '@emotion/css';
import { DateTime } from 'luxon';

import { Language } from 'dg-web-shared/lib/Text';
import { Translation, Translations } from '../../../common/i18n/Text';
import {
    ArrowPosition,
    InlineQuestionBox,
    ButtonText,
} from '../../../ui/modals/Confirmable';
import { ActiveListItem } from '../../../transactions-list/TransactionsListContext';

const TIME_SINCE_PREVIOUS_PARKING_END_UPPER_BOUND_MINUTES = 10;

export function ImmediateSubsequentParkingNotification(props: Props) {
    const t = translations[props.language];

    return (
        <div
            className={css({
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
            })}
        >
            <InlineQuestionBox
                arrowPosition={ArrowPosition.left}
                titleCaption={t.title()}
                confirmCaption={ButtonText.CONTINUE}
                confirmCallback={props.onContinue}
                cancelCaption={ButtonText.CANCEL}
                cancelCallback={props.onCancel}
            >
                <p>{t.immediateParkForThisLicensePlateAndZone()}</p>
                <p>
                    <strong>{t.subsequentPaymentForbiddenAnyway()}</strong>
                </p>
                <p>{t.whatYouShouldDo()}</p>
            </InlineQuestionBox>
        </div>
    );
}

interface Props {
    previousSignificantParkEnd: DateTime;
    language: Language;
    onContinue: () => void;
    onCancel: () => void;
}

export function wouldItBeImmediateSubsequentParking(
    previousSignificantParkEnd: DateTime,
): boolean {
    return (
        previousSignificantParkEnd >
        DateTime.local().minus({
            minutes: TIME_SINCE_PREVIOUS_PARKING_END_UPPER_BOUND_MINUTES,
        })
    );
}

export function isParkTransactionRunning(
    licensePlateId: number,
    active?: ActiveListItem[],
): boolean {
    if (!active) {
        return false;
    }

    return active.some(
        (t: ActiveListItem) =>
            t.type === 'parkTransaction' && t.licensePlateId === licensePlateId,
    );
}

const translations: Translations<Texts> = {
    de: {
        title: () => 'Nachzahlen verboten',
        immediateParkForThisLicensePlateAndZone: () =>
            'In dieser Zone und mit diesem Kennzeichen haben Sie soeben einen Parkvorgang beendet.',
        subsequentPaymentForbiddenAnyway: () =>
            'Nachzahlungsverbot gilt in jedem Fall auch bei der Nutzung von Parkingpay und bei den Gratis-Zeiten.',
        whatYouShouldDo: () =>
            'Nach dem Ablauf eines Parkvorgangs muss das Fahrzeug zuerst wieder in den Verkehr eingefügt werden; ist dies nicht der Fall und wird ein neuer Parkvorgang gestartet, muss trotz laufendem Parkvorgang mit einer Parkbusse gerechnet werden.',
        close: () => 'Weiter',
    },
    fr: {
        title: () => 'Interdit de payer à nouveau',
        immediateParkForThisLicensePlateAndZone: () =>
            'Vous venez de terminer une procédure de stationnement dans cette zone avec l’immatriculation sélectionnée.',
        subsequentPaymentForbiddenAnyway: () =>
            "L'interdiction de payer à nouveau s'applique dans tous les cas, même avec l'utilisation de Parkingpay et dans les temps gratuits.",
        whatYouShouldDo: () =>
            'Au terme de chaque procédure de stationnement, le véhicule doit être remis en circulation. Si tel n’est pas le cas et qu’une nouvelle procédure de stationnement est démarrée, une amende d’ordre peut être émise par l’autorité de contrôle.',
        close: () => 'Continuer',
    },
    it: {
        title: () => 'Proibito ripagare',
        immediateParkForThisLicensePlateAndZone: () =>
            'Con questo numero di targa avete appena terminato una sosta in questa zona.',
        subsequentPaymentForbiddenAnyway: () =>
            'Il divieto di rinnovare il pagamento vale anche per Parkingpay e i tempi gratuiti.',
        whatYouShouldDo: () =>
            'Dopo la fine di ogni sosta, il veicolo dev’essere rimesso in circolazione; se questo non avviene e una nuova sosta viene avviata, si può andare in contro a una contravvenzione, nonostante la sosta sia attiva.',
        close: () => 'Avanti',
    },
    en: {
        title: () => 'Payment renewal prohibited',
        immediateParkForThisLicensePlateAndZone: () =>
            'With this license plate you just finished a parking transaction in this zone.',
        subsequentPaymentForbiddenAnyway: () =>
            'The prohibition to renew the payment also applies in case of using the Parkingpay and during the free times.',
        whatYouShouldDo: () =>
            'After the end of each parking transaction, the vehicle must be re-inserted in the traffic; if this is not the case and a new parking transaction is started, a parking fine must be expected despite active parking transaction.',
        close: () => 'Continue',
    },
};

interface Texts {
    close: Translation;
    immediateParkForThisLicensePlateAndZone: Translation;
    subsequentPaymentForbiddenAnyway: Translation;
    title: Translation;
    whatYouShouldDo: Translation;
}
