import {
    PaymentAttemptResponseParams,
    PaymentCategory,
    PaymentRequestMode,
} from '../../../common/payment/Payment';
import { Language } from 'dg-web-shared/lib/Localized';
import {
    derivePaymentResponseState,
    DirectToAliasResponse,
    DirectToAliasResponseTag,
    ErrorResponse,
    handlePaymentAttemptResponse,
    ResponseState,
} from './PaymentBase';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import { useServerWrite } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import * as superagent from 'superagent';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';

export namespace TopUpPaymentRequest {
    interface TopUpPaymentPayloadBase {
        amount: number;
        language: Language;
        paymentCategory: PaymentCategory;
        uiPaymentOrigin: UiPaymentOrigin;
        ticketId?: string;
    }

    type DirectToAliasPayload = TopUpPaymentPayloadBase;

    interface NoOrExpiredPayload extends TopUpPaymentPayloadBase {
        useAlias: boolean;
    }

    export interface PaymentRequestPayload extends TopUpPaymentPayloadBase {
        mode: PaymentRequestMode;
    }

    export function use(): [ResponseState, (p: PaymentRequestPayload) => void] {
        const update = useUpdate();

        const [noOrExpiredAlias, sendNoOrExpiredAlias] = useServerWrite<
            NoOrExpiredPayload,
            PaymentAttemptResponseParams,
            ErrorResponse
        >({
            req: (p: NoOrExpiredPayload) => {
                return superagent
                    .post('/ui-api/customer-account/deposits/deposit-attempt')
                    .send({
                        amount: p.amount,
                        uiPaymentOrigin: p.uiPaymentOrigin,
                        paymentCategory: p.paymentCategory,
                        baseUri: `${window.location.protocol}//${window.location.host}`,
                        language: p.language,
                        ticketId: p.ticketId,
                    });
            },
            onResponse: (current, payload) => {
                handlePaymentAttemptResponse(
                    update,
                    current,
                    payload,
                    'top-up',
                );
            },
        });
        const [directToAlias, sendDirectToAlias] = useServerWrite<
            DirectToAliasPayload,
            DirectToAliasResponse,
            ErrorResponse
        >({
            req: (p: DirectToAliasPayload) => {
                return superagent
                    .post('/ui-api/customer-account/epaydirect/top-up')
                    .send({
                        amount: p.amount,
                        uiPaymentOrigin: p.uiPaymentOrigin,
                    });
            },
            onResponse: (current, payload) => {
                if (current.status === RequestStatus.SUCCESS) {
                    if (
                        current.data.tag ===
                        DirectToAliasResponseTag.FAILURE_DATATRANS_API
                    ) {
                        if (payload.paymentCategory !== PaymentCategory.TWINT) {
                            sendNoOrExpiredAlias({
                                paymentCategory: payload.paymentCategory,
                                amount: payload.amount,
                                useAlias: true,
                                language: payload.language,
                                uiPaymentOrigin: payload.uiPaymentOrigin,
                                ticketId: payload.ticketId,
                            });
                        }
                    }
                }
            },
        });

        return [
            derivePaymentResponseState(directToAlias, noOrExpiredAlias),
            (p: PaymentRequestPayload) => {
                if (p.mode === PaymentRequestMode.DIRECT_TO_ALIAS) {
                    sendDirectToAlias({
                        amount: p.amount,
                        uiPaymentOrigin: p.uiPaymentOrigin,
                        paymentCategory: p.paymentCategory,
                        language: p.language,
                        ticketId: p.ticketId,
                    });
                } else {
                    sendNoOrExpiredAlias({
                        amount: p.amount,
                        language: p.language,
                        uiPaymentOrigin: p.uiPaymentOrigin,
                        paymentCategory: p.paymentCategory,
                        useAlias: p.mode === PaymentRequestMode.INVALID_ALIAS,
                        ticketId: p.ticketId,
                    });
                }
            },
        ];
    }
}
