import { Localized } from '../../../common/components/Localized';
import { ResponseCode } from '../../../common/state/WriteStateSlice';
import {
    ModalErrorBox,
    ModalInfoBox,
    ModalSuccessBox,
} from '../../../ui/modals/Confirmable';

export function Success(props: { children: React.ReactNode }) {
    return (
        <ModalSuccessBox confirmCallback={() => {}}>
            {props.children}
        </ModalSuccessBox>
    );
}

export function Failure(props: {
    children: React.ReactNode;
    onConfirm: () => void;
}) {
    return (
        <ModalErrorBox confirmCallback={props.onConfirm}>
            {props.children}
        </ModalErrorBox>
    );
}

export function RedirectingToDatatransMessage() {
    return (
        <ModalInfoBox
            confirmCallback={() => {}}
            isPending={false}
            confirmDisabled={true}
        >
            <p>
                <Localized
                    de="Sie werden in Kürze auf das sichere Online-Zahlungsportal weitergeleitet..."
                    fr="Vous serez bientôt redirigé vers le portail de paiement en ligne sécurisé..."
                    it="A breve verrà reindirizzato sul portale per il pagamento online sicuro..."
                    en="You'll be redirected to the secure online payment gateway shortly..."
                />
            </p>
        </ModalInfoBox>
    );
}

export function NoAliasSaved({ onConfirm }: { onConfirm: () => void }) {
    return (
        <Failure onConfirm={onConfirm}>
            <p>
                <Localized
                    de="Ihre gespeicherte Karte konnte nicht gefunden werden."
                    fr="Votre méthode de paiement enregistrée n'a pas été trouvée."
                    it="Il tuo metodo di pagamento salvato non è stato trovato."
                    en="Your saved card could not be found."
                />
            </p>
        </Failure>
    );
}

export function HttpFailure(props: {
    httpStatusCode: number;
    apiErrorCode: number | null;
    onConfirm: () => void;
}) {
    return (
        <Failure onConfirm={props.onConfirm}>
            <p>
                <Localized
                    {...{
                        de: `Online-Zahlung kann nicht gestartet werden: HTTP ${props.httpStatusCode}.`,
                        fr: `Impossible de démarrer le paiement en ligne: HTTP ${props.httpStatusCode}.`,
                        it: `Impossibile avviare il pagamento online: HTTP ${props.httpStatusCode}.`,
                        en: `Failed to initialize the e-payment: HTTP ${props.httpStatusCode}.`,
                    }}
                />
            </p>

            {props.apiErrorCode === ResponseCode.INVALID_BADGE && (
                <p>
                    <Localized
                        de="Es ist noch nicht möglich, diese Bewilligung zu erwerben, da mindestens einer der ausgewählten Badges zwar bestellt, aber unsererseits noch nicht aktiviert und versendet wurde. Das wird in den nächsten 3 Werktagen passieren."
                        fr="Il n'est pas encore possible d'acheter cette autorisation parce qu'au moins un des badges sélectionnés a été commandé, mais de notre part pas encore activé et envoyé. Cela se produira dans les 3 prochains jours ouvrables."
                        it="Non è ancora possibile aquistare quest'autorizzazione in quanto almeno un badge tra quelli selezionati è stato sì ordinato, ma da parte nostra non ancora attivato e inviato. Questo avverrà nei prossimi 3 giorni lavorativi."
                        en="It is not yet possible to purchase this authorization because at least one of the selected badges has been ordered, but on our part not yet activated and sent. This will happen in the next 3 working days."
                    />
                </p>
            )}
        </Failure>
    );
}
