import { Colors } from 'dg-web-shared/ui/vars';

export function paper(depth: 0 | 1 | 2 | 3 | 4 | 5) {
    switch (depth) {
        case 0:
            return { boxShadow: 'none' };
        case 1:
            return { boxShadow: `0 2px 2px ${Colors.rgba(Colors.black, 0.2)}` };
        case 2:
            return { boxShadow: `0 1px 4px ${Colors.rgba(Colors.black, 0.3)}` };
        case 3:
            return {
                boxShadow: `0 2px 6px ${Colors.rgba(Colors.black, 0.45)}`,
            };
        case 4:
            return {
                boxShadow: `0 20px 40px ${Colors.rgba(Colors.black, 0.3)}`,
            };
        case 5:
            return {
                boxShadow: `0 10px 20px ${Colors.rgba(Colors.black, 0.3)}`,
            };
    }
}
