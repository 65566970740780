export enum PermitDurationType {
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
}

export function permitDurationText(
    durationType: PermitDurationType,
    quantity: number,
) {
    const isSingleUnit = quantity === 1;
    switch (durationType) {
        case PermitDurationType.hour:
            return isSingleUnit ? durationTexts.hour : durationTexts.hours;
        case PermitDurationType.day:
            return isSingleUnit ? durationTexts.day : durationTexts.days;
        case PermitDurationType.week:
            return isSingleUnit ? durationTexts.week : durationTexts.weeks;
        case PermitDurationType.month:
            return isSingleUnit ? durationTexts.month : durationTexts.months;
    }
}

const durationTexts = {
    hour: {
        de: 'Stunde',
        fr: 'heure',
        it: 'ora',
        en: 'hour',
    },
    hours: {
        de: 'Stunden',
        fr: 'heures',
        it: 'ore',
        en: 'hours',
    },
    day: {
        de: 'Tag',
        fr: 'jour',
        it: 'giorno',
        en: 'day',
    },
    days: {
        de: 'Tage',
        fr: 'jours',
        it: 'giorni',
        en: 'days',
    },
    week: {
        de: 'Woche',
        fr: 'semaine',
        it: 'settimana',
        en: 'week',
    },
    weeks: {
        de: 'Wochen',
        fr: 'semaines',
        it: 'settimane',
        en: 'weeks',
    },
    month: {
        de: 'Monat',
        fr: 'mois',
        it: 'mese',
        en: 'month',
    },
    months: {
        de: 'Monate',
        fr: 'mois',
        it: 'mesi',
        en: 'months',
    },
};
