import { useStore } from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../common/state/SettingsState';
import { Language } from 'dg-web-shared/lib/Localized';

export function useParkingpayLanguage(): Language {
    const {
        storeState: { settings },
    } = useStore(allState => ({
        settings: new SettingsState.StateSlice(allState).state,
    }));

    return settings.language as Language;
}
