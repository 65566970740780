import * as Flux from 'dg-web-shared/lib/Flux';
import * as LayoutState from '../../state/LayoutState';

import { ResponsiveProperties } from '../../utils/ResponsiveProperties';
import { TopNav } from './TopNav';
import { css } from '@emotion/css';

interface HeaderLargeBlockProps {
    allState: Flux.Store;
    children?: React.ReactNode;
}
interface HeaderLargeBlockState {
    layout: LayoutState.State;
}

export class HeaderLargeBlock extends Flux.ContainerWithProps<
    HeaderLargeBlockProps,
    HeaderLargeBlockState
> {
    static displayName = 'HeaderLarge';
    stateSelector(): HeaderLargeBlockState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }
    render() {
        const responsiveProperties = new ResponsiveProperties(this.state);
        if (responsiveProperties.mobile) {
            return null;
        }

        return (
            <div className={css({ width: '100%', margin: 'auto' })}>
                <TopNav />
            </div>
        );
    }
}
