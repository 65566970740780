import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers';

export type SelectionMode = 'single' | 'multiple';

const sn = ElementNamer('park-create-LicensePlateSelectionState');

export namespace Selection {
    export namespace Permit {
        export interface State {
            selectedEntityIds: number[];
        }
        export const { set, get, reset, stateWrite } =
            Flux.generateState<State>(sn('Permit'), {
                selectedEntityIds: [],
            });
    }

    export namespace Transaction {
        export interface State {
            selectedLicensePlate: Maybe<number>;
        }
        export const { set, get, reset, stateWrite } =
            Flux.generateState<State>(sn('Transaction'), {
                selectedLicensePlate: null,
            });
        export const writeSelectedLicenseplate = (
            store: Flux.Store,
            id: number | null,
        ) => {
            stateWrite(store, { selectedLicensePlate: id });
            return sn('-Transaction.writeSelectedLicenseplate');
        };
    }
}

export namespace Dropin {
    interface DropinState {
        open: boolean;
    }

    export namespace Permit {
        export type State = DropinState;
        export const { set, get, reset, stateWrite } =
            Flux.generateState<State>(sn('Dropin.Permit'), {
                open: false,
            });
    }

    export namespace Transaction {
        export type State = DropinState;
        export const { set, get, reset, stateWrite } =
            Flux.generateState<State>(sn('Dropin.Transaction'), {
                open: false,
            });
    }
}
