import { css } from '@emotion/css';

import { useStore } from 'dg-web-shared/lib/Flux';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { Localized } from '../../common/components/Localized';
import * as FieldTexts from '../../common/i18n/FieldTexts';
import * as SettingsState from '../../common/state/SettingsState';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import * as Fields from '../../utils/Fields';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { EditFormSlideIn } from './EditForm';
import { EditFormState } from './EditFormState';
import * as LoginsState from './state/LoginsState';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export function EmailEditFormSlideIn(props: {
    portal: SlideInPortalId;
    title: React.ReactNode;
    open: boolean;
    login: LoginsState.GetLoginData | null;
    onClose: () => void;
}) {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        edit: new EditFormState.StateSlice(s).state,
        legacyUpdater: s.legacyUpdater,
    }));

    if (!props.login) {
        return (
            <EditFormSlideIn isSaveable={false} {...props}>
                <Spinner />
            </EditFormSlideIn>
        );
    }

    const emailField = new Fields.Email(storeState.edit.email ?? '');

    return (
        <EditFormSlideIn
            isSaveable={
                !emailField.isEmpty &&
                Fields.noStates(emailField) &&
                emailField.value !== props.login.email
            }
            {...props}
        >
            <TextField
                context={InputContext.form}
                value={emailField.value}
                maxChars={100}
                labelText={
                    <Localized
                        de="Neue E-Mail-Adresse"
                        fr="Nouvelle adresse e-mail"
                        it="Nuovo indirizzo e-mail"
                        en="New e-mail address"
                    />
                }
                errorText={Fields.emailErrorText(
                    emailField,
                    FieldTexts.emailTexts[storeState.settings.language],
                )}
                onChange={(v: string) =>
                    storeState.legacyUpdater(EditFormState.writeEmail, v)
                }
            />
            <p
                className={css({
                    ...Typo.robotoRegular,
                    color: Colors.action_f,
                    fontSize: '16px',
                    lineHeight: '22px',
                    marginBottom: '18px',
                })}
            >
                <Localized
                    de="Ein Bestätigungslink wird per E-Mail an die neue Adresse gesendet. Der Benutzer muss den Link öffnen, um die Änderung der E-Mail Adresse zu bestätigen."
                    fr="Le lien de confirmation sera envoyé par e-mail à la nouvelle adresse. L'utilisateur doit ouvrir le lien afin de confirmer le changement d'adresse e-mail."
                    it="Il link di conferma verrà inviato via e-mail al nuovo indirizzo. L'utente deve aprire il link per confermare il cambio d'indirizzo e-mail."
                    en="The e-mail confirmation link will be sent to the new address. The user should open the link in order to confirm the e-mail address change."
                />
            </p>
            <p
                className={css({
                    ...Typo.robotoRegular,
                    color: Colors.action_f,
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '22px',
                    marginBottom: '18px',
                })}
            >
                <Localized
                    de="Bis zur Bestätigung bleibt die aktuelle E-Mail-Adresse gültig."
                    fr="L'adresse e-mail actuelle reste valide jusqu'à confirmation."
                    it="L'indirizzo e-mail attuale rimane valido fino alla conferma."
                    en="The current e-mail address remains valid until confirmation."
                />
            </p>
        </EditFormSlideIn>
    );
}
