import { LabeledText } from '../../../../../tb-ui/typo/LabeledText';
import {
    InputContext,
    inputContextToColor,
} from '../../../../../tb-ui/inputs/InputContext';
import { OffstreetTransaction } from './OffstreetTransaction';
import { ZonesOfCityState } from '../ParkOptionList';
import { ZoneEnforcedCheckin } from './ZoneEnforcedCheckin';
import { css } from '@emotion/css';
import { ZoneEnforcedInfoSlideIn } from './ZoneEnforcedInfoSlideIn';
import { Label } from '../../../../../tb-ui/inputs/internal/Label';
import { Localized } from '../../../common/components/Localized';
import { Typo } from '../../../../../tb-ui/typo';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { ZoneEnforcedCheckinResponsive } from './ZoneEnforcedCheckinResponsive';
import { useState } from 'react';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

interface Props {
    zone: ZonesOfCityState.Zone;
    isOffstreet: boolean;
}

export const Transaction = (p: Props) => {
    return (
        <>
            <MetaInfo zone={p.zone} isOffstreet={p.isOffstreet} />
            <Config zone={p.zone} isOffstreet={p.isOffstreet} />
        </>
    );
};

const Config = (p: { zone: ZonesOfCityState.Zone; isOffstreet: boolean }) => {
    if (p.isOffstreet) {
        return <OffstreetTransaction zoneId={p.zone.id} />;
    } else {
        return <ZoneEnforcedCheckin zoneId={p.zone.id} />;
    }
};

const MetaInfo = (p: { zone: ZonesOfCityState.Zone; isOffstreet: boolean }) => {
    if (p.isOffstreet) {
        return (
            <div>
                <LabeledText
                    context={InputContext.inverted}
                    label={{
                        de: 'PLZ / Ort',
                        fr: 'NPA / Localité',
                        it: 'NAP / Località',
                        en: 'ZIP / City',
                    }}
                >
                    {`${p.zone.zipCode} ${p.zone.city}`}
                </LabeledText>
                <LabeledText
                    context={InputContext.inverted}
                    label={{
                        de: 'Name',
                        fr: 'Nom',
                        it: 'Nome',
                        en: 'Name',
                    }}
                >
                    {`${p.zone.extZoneCode ? p.zone.extZoneCode : ''} ${
                        p.zone.name
                    }`}
                </LabeledText>
            </div>
        );
    } else {
        const [zoneInfoSlideInOpen, setZoneInfoSlideInOpen] = useState(false);
        return (
            <>
                <div
                    className={css({
                        marginTop: '8px',
                        marginBottom: '22px',
                        [`@media(max-height: ${ZoneEnforcedCheckinResponsive.level4}px)`]:
                            {
                                marginTop: 0,
                                marginBottom: '12px',
                            },
                        [`@media(max-height: ${ZoneEnforcedCheckinResponsive.level2}px)`]:
                            {
                                marginTop: 0,
                                marginBottom: '4px',
                            },
                        position: 'relative',
                        color: inputContextToColor(InputContext.inverted),
                    })}
                >
                    <Label
                        context={InputContext.inverted}
                        text={
                            <Localized
                                de="Zone"
                                fr="Zone"
                                it="Zona"
                                en="Zone"
                            />
                        }
                        elevated={true}
                        hideHeight={ZoneEnforcedCheckinResponsive.level2}
                    />
                    <div
                        className={css({
                            ...Typo.robotoMedium,
                            fontSize: 16,
                            minHeight: 20,
                            lineHeight: '20px',
                            position: 'relative',
                        })}
                    >
                        <div
                            className={css({
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                marginRight: 24,
                            })}
                        >
                            {`${p.zone.extZoneCode || ''} ${p.zone.name}${
                                p.zone.city ? ' (' + p.zone.city + ')' : ''
                            }`}
                        </div>
                        <Clickable
                            element="div"
                            className={css({
                                position: 'absolute',
                                bottom: 0,
                                right: -3,
                            })}
                            onClick={() => setZoneInfoSlideInOpen(true)}
                        >
                            <Icon icon={<MoreHorizIcon />} />
                        </Clickable>
                    </div>
                </div>

                <ZoneEnforcedInfoSlideIn
                    zoneId={p.zone.id}
                    open={zoneInfoSlideInOpen}
                    onClose={() => setZoneInfoSlideInOpen(false)}
                />
            </>
        );
    }
};
