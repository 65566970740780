export namespace TopUpPaymentLogic {
    export function topUpAmountOptions(
        currentBalanceChf: number,
    ): AmountOption[] {
        const amounts = topUpAmounts();

        return amounts.map((value, index) => ({
            value,
            enabled:
                currentBalanceChf + value >= 20 || index === amounts.length - 1,
        }));
    }

    export function topUpAmounts(): number[] {
        return [20, 30, 50, 100, 150, 200, 300, 500, 1000];
    }

    export function defaultAmountChf(currentBalanceChf: number): number {
        if (currentBalanceChf >= 0) {
            return 50;
        }

        return Math.max(
            50,
            topUpAmounts().find(x => x + currentBalanceChf > 0) || 1000,
        );
    }

    interface AmountOption {
        value: number;
        enabled: boolean;
    }
}
