import { Localized } from '../Localized';

export function TicketPlusTitle() {
    return (
        <Localized
            de="Parking-Ticket"
            fr="Ticket de parking"
            it="Ticket parcheggio"
            en="Parking ticket"
        />
    );
}

export function ZoneInfoSlideInTitle() {
    return (
        <Localized
            de="Parking-Details"
            fr="Détails du parking"
            it="Dettagli parcheggio"
            en="Parking Details"
        />
    );
}
