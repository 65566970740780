import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import {
    generateWriteStateSlice,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import {
    generateServerState,
    ServerState,
} from 'dg-web-shared/lib/ServerStateSlice';
import { BackendTranslation } from 'dg-web-shared/lib/Text';
import { apiDelete, apiGetReq, apiPost, apiPut } from '../../api/Http';
import { requestV2 } from '../../AsyncRequest';
import * as LicensePlateState from '../../common/state/LicensePlateState';
import * as TransactionState from '../../park-create/state/zone-transaction/TransactionState';
import { logAction } from '../../utils/ActionLog';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { BarrierGateLicensePlateCheckInOption } from 'dg-web-shared/common/models/Vehicle';

export type BadgeTypes = 'ParkingpayBadge' | 'SpitalEmmental';

export interface VehicleVignette {
    vignetteId: number;
    vignetteNr: string | null;
    ordered: boolean;
}

export interface Vehicle {
    customerCarId: number | null;
    licensePlateNr: string;
    loginId: number | null;
    loginDescription: string | null;
    type: LicensePlateType;
    country: string;
    description: string | null;
    badgeId: number | null;
    badgeLabelNr: string | null;
    badgeType: BadgeTypes | null;
    vignettes: VehicleVignette[];
    runningLpContractOperatorNames: string[];
    runningBadgeContractOperatorNames: string[];
    barrierGateLicensePlateCheckInOption: BarrierGateLicensePlateCheckInOption;
}

export interface CreateVehicle {
    licensePlateNr: string;
    loginId: number | null;
    type: LicensePlateType;
    country: string;
    description: string;
    addBadgeToVehicle: boolean;
}

export function vehicleTitle(vehicle: Vehicle | null): string {
    if (vehicle === null) {
        return '';
    }
    return vehicle.description || vehicle.licensePlateNr;
}

export namespace Vehicles {
    export type State = ServerState<Vehicle[]>;

    export const { get, setResponse, reset, forceRefetch } =
        generateServerState<Vehicle[]>(
            'customer-vehicles',
            () => [],
            (store, state) => {
                if (state.shouldFetch) {
                    store.update(requestV2(apiGetReq('vehicles'), setResponse));
                }
            },
            b => b,
        );
}

export namespace UpdateVehicle {
    export type State = ServerRequestState<Vehicle, ErrorState>;
    interface ErrorState {
        code: number;
        message: string;
    }

    export const { get, setResponse, reset } = generateWriteStateSlice<
        Vehicle,
        ErrorState
    >({
        key: 'vehicle-update',
    });

    const setServerResponse = (store: Flux.Store, res: Response) => {
        if (res.statusCode.cls.success) {
            resetAll(store);
        }
        return setResponse(store, res);
    };

    const resetAll = (store: Flux.Store) => {
        Vehicles.forceRefetch(store);
        UpdateVehicle.reset(store);
        TransactionState.ParkTransaction.refetchSameContext(store, false);
        // TODO: Should use vehicle in the first place!
        new LicensePlateState.StateSlice(store).reset();
    };

    export const put = (
        store: Flux.Store,
        oldData: Vehicle,
        data: Vehicle,
        success?: (res: Response) => void,
    ): string => {
        logAction(store, `vehicle-update`, { oldData: oldData, newData: data });

        return requestV2(
            () => apiPut(`vehicle/${data.customerCarId}`).send(data),
            (store: Flux.Store, res: Response) => {
                if (success && res.statusCode.cls.success) {
                    success(res);
                }

                return setServerResponse(store, res);
            },
        )(store);
    };

    export interface CreateResponse {
        vehicleId: number;
        carId: number;
    }

    export const post = (
        store: Flux.Store,
        data: CreateVehicle,
        success?: (res: CreateResponse, data: CreateVehicle) => void,
    ): string => {
        logAction(store, `vehicle-create`, { data });

        return requestV2(
            () => apiPost(`vehicle`).send(data),
            (store: Flux.Store, res: Response) => {
                if (res.statusCode.cls.success) {
                    resetAll(store);
                    if (success) {
                        success(res.body, data);
                    }
                }
                return setResponse(store, res);
            },
        )(store);
    };

    export const postBadge = (
        store: Flux.Store,
        data: Vehicle,
        success?: (badgeId: number | null, data: Vehicle) => void,
    ): string => {
        logAction(store, `badge-create`, {
            id: data.badgeId,
            labelNr: data.badgeLabelNr,
            type: data.badgeType,
            vehicleId: data.customerCarId,
        });

        return requestV2(
            () =>
                apiPost(`badges`).send({
                    type: data.badgeType,
                    labelNr: data.badgeLabelNr,
                    vehicleId: data.customerCarId,
                }),
            (store: Flux.Store, res: Response) => {
                if (success && res.statusCode.cls.success) {
                    success(data.badgeLabelNr ? res.body.badgeId : null, data);
                }

                return setServerResponse(store, res);
            },
        )(store);
    };

    export const deleteBadge = (
        store: Flux.Store,
        data: Vehicle,
        success?: (data: Vehicle) => void,
    ): string => {
        logAction(store, 'badge-delete', {
            id: data.badgeId,
            labelNr: data.badgeLabelNr,
            type: data.badgeType,
            vehicleId: data.customerCarId,
        });

        return requestV2(
            () => apiDelete(`vehicle/${data.customerCarId}/badge`),
            (store: Flux.Store, res: Response) => {
                if (success && res.statusCode.cls.success) {
                    success(data);
                }
                return setServerResponse(store, res);
            },
        )(store);
    };

    export const del = (
        store: Flux.Store,
        data: Vehicle,
        success?: (data: Vehicle) => void,
    ): string => {
        logAction(store, 'vehicle-delete', {
            vehicleId: data.customerCarId,
            licensePlate: data.licensePlateNr,
            description: data.description,
        });

        return requestV2(
            () => apiDelete(`vehicle/${data.customerCarId}`).send(data),
            (store: Flux.Store, res: Response) => {
                if (success && res.statusCode.cls.success) {
                    success(data);
                }
                return setServerResponse(store, res);
            },
        )(store);
    };
}

export namespace VehiclesMeta {
    interface BackendState {
        licensePlateTypes: BackendTranslation[];
        licensePlateCountries: BackendTranslation[];
        badgeTypes: BackendTranslation[];
    }
    export type State = ServerState<BackendState>;

    export const { get, setResponse, reset } =
        generateServerState<BackendState>(
            'vehicle-meta',
            () => ({
                licensePlateTypes: [],
                licensePlateCountries: [],
                badgeTypes: [],
            }),
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(
                        requestV2(apiGetReq('../meta/vehicles'), setResponse),
                    );
                }
            },
            b => b,
        );
}
