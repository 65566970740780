import * as Flux from 'dg-web-shared/lib/Flux';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';

export type ParkVariant =
    | 'licensePlateTransaction'
    | 'badgeTransaction'
    | 'permit'
    | 'cityWidePermit';

export namespace Selection {
    export interface State {
        selectedZipCode: string | null;
        selectedParkOptionId: number | null;
        variant: ParkVariant | null;
    }
    export const s = Flux.generateState<State>(
        'park-create-ParkOptionListState',
        () => {
            return {
                selectedZipCode: null,
                selectedParkOptionId: null,
                variant: null,
            };
        },
    );

    const stateWrite = (store: Flux.Store, state: Partial<State>) => {
        s.stateWrite(store, state);
    };

    export const get = s.get;
    export const setZipCode = (
        store: Flux.Store,
        selectedZipCode: string,
    ): string => {
        stateWrite(store, {
            selectedZipCode,
            selectedParkOptionId: null,
            variant: null,
        });
        return 'ParkCreate-setZipCode';
    };

    export const setParkOptionId = (
        store: Flux.Store,
        selectedParkOptionId: number,
    ): string => {
        stateWrite(store, { selectedParkOptionId, variant: null });
        return 'ParkCreate-setZipCode';
    };

    export const setVariant = (
        store: Flux.Store,
        variant: Maybe<ParkVariant>,
    ): string => {
        stateWrite(store, { variant });
        return 'ParkCreate-setZipCode';
    };

    export const reset = s.reset;
}

export interface CityName {
    city: string;
    distances: (number | null)[];
}

export interface City {
    zipCode: string;
    names: CityName[];
}
