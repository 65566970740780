export const chevronRight = (
    <path d="M4.3 12.6L8.9 8 4.3 3.4 5.7 2l6 6-6 6-1.4-1.4z" />
);
export const chevronLeft = (
    <path d="M10.3 14l-6-6 6-6 1.4 1.41L7.11 8l4.59 4.59z" />
);
export const chevronDown = <path d="M11.1 5.5L8 8.6 4.9 5.5l-.9 1 4 4 4-4z" />;
export const chevronUp = <path d="M4.9 10.5L8 7.4l3.1 3.1.9-1-4-4-4 4z" />;
export const chevronBreadcrumb = (
    <path d="M6.1 10.3L8.4 8 6.1 5.7l.8-.7 3 3-3 3-.8-.7z" />
);

export const collapsed = <path d="M5.5 3l5 5-5 5z" />;
export const expanded = <path d="M13 5.5l-5 5-5-5z" />;

export const moreHorizontal = (
    <path d="M12 8a2.07 2.07 0 0 1 .61-1.41A2 2 0 0 1 16 8a2 2 0 0 1-3.37 1.41A2.07 2.07 0 0 1 12 8M6 8a2.07 2.07 0 0 1 .61-1.41A1.91 1.91 0 0 1 8 6a1.92 1.92 0 0 1 2 2 1.92 1.92 0 0 1-2 2 1.91 1.91 0 0 1-1.41-.56A2.07 2.07 0 0 1 6 8M0 8a2.07 2.07 0 0 1 .62-1.41A1.91 1.91 0 0 1 2 6a1.92 1.92 0 0 1 2 2 1.92 1.92 0 0 1-2 2 1.91 1.91 0 0 1-1.38-.59A2.07 2.07 0 0 1 0 8" />
);

export namespace licensePlateType {
    export const car = (
        <path d="M16 7.7c0-.7-.5-1.2-1.2-1.2h-.1c-.2-.5-.4-1.2-.7-1.8-.3-.7-.6-1.5-.8-1.9-.3-.7-.9-1.2-1.7-1.2H4.4c-.7.1-1.4.5-1.6 1.2-.2.5-.5 1.2-.8 1.9-.3.6-.5 1.3-.7 1.8h-.1C.5 6.5 0 7 0 7.7v4.2h1v1.9c0 .3.3.6.6.6h1.2c.3 0 .6-.3.6-.6v-1.9h9.2v1.9c0 .3.3.6.6.6h1.2c.3 0 .6-.3.6-.6v-1.9h1V7.7zM2.4 9.9c-.6 0-1.2-.5-1.2-1.2 0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2 0 .6-.5 1.2-1.2 1.2m.1-3.5c.1-.4 1.1-2.7 1.3-3.2.1-.3.4-.5.7-.5h7.1c.3 0 .5.2.7.5.2.5 1.1 2.8 1.3 3.2-1.5 0-3.8-.1-5.6-.1-1.8 0-4.1.1-5.5.1m11.1 3.5c-.6 0-1.2-.5-1.2-1.2 0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2 0 .6-.6 1.2-1.2 1.2" />
    );

    export const motorcycle = (
        <g>
            <path d="M2.85,9.3a2.85,2.85,0,1,0,2.85,2.85A2.85,2.85,0,0,0,2.85,9.3" />
            <path d="M13.15,9.3A2.85,2.85,0,1,0,16,12.15,2.85,2.85,0,0,0,13.15,9.3" />
            <path d="M10.39,8.75a0.91,0.91,0,0,0-.72-0.61L7.34,7.6,9.05,5.82l2.56,2.27,0.53-.53-3-3.45a1.46,1.46,0,0,0-1-.44,1.52,1.52,0,0,0-1.2.64l-2.21,3a1.47,1.47,0,0,0-.2,1.53,1.33,1.33,0,0,0,1.2.66h2.7L6.26,12l0.58,0.77L10.1,9.65a0.9,0.9,0,0,0,.3-0.9" />
            <path d="M8.37,3.19a1.93,1.93,0,0,1,1.12.54L10,4.33l0.46,0.1a0.65,0.65,0,0,0,.7-0.3l-0.42-.41a0.8,0.8,0,0,1-.24-0.57s0-.37,0-0.59,0.22-.25.35-0.1l0.58,0.59V3A1.66,1.66,0,0,0,9.73,1a1.64,1.64,0,0,0-1.39.91,1.27,1.27,0,0,0-.14.74,3.28,3.28,0,0,0,.17.55" />
        </g>
    );
}

export namespace identifier {
    export const licensePlate = (
        <g>
            <path d="M14.19 11.18a1.39 1.39 0 0 0 .94-.41 1.27 1.27 0 0 0 .38-.94V6.16a1.27 1.27 0 0 0-.37-.94 1.39 1.39 0 0 0-.94-.41H1.81a1.39 1.39 0 0 0-.94.41 1.27 1.27 0 0 0-.38.94v3.68a1.27 1.27 0 0 0 .38.94 1.39 1.39 0 0 0 .94.41h12.38zM1.5 9.84V6.16a.28.28 0 0 1 .08-.23.36.36 0 0 1 .23-.11h12.32a.39.39 0 0 1 .29.12.28.28 0 0 1 .08.23v3.67a.28.28 0 0 1-.08.23.38.38 0 0 1-.26.11H1.87a.39.39 0 0 1-.29-.12.28.28 0 0 1-.08-.22z" />
            <circle cx="3" cy="8" r=".5" />
            <circle cx="13" cy="8" r=".5" />
        </g>
    );
    export const badge = (
        <path d="M5.7 7.3h-2V6h2v1.3zm8.9-3.6c-.2-.2-.6-.4-.9-.4H2.3c-.4 0-.7.2-.9.4s-.4.6-.4 1v6.7c0 .4.1.7.4.9.2.3.6.4.9.4h11.4c.4 0 .7-.2.9-.4.2-.2.4-.6.4-.9V4.7c0-.4-.1-.7-.4-1" />
    );
    export const qrCode = (
        <g>
            <path d="M9.57 8.54h1.06v1.07H9.57V8.54zM11.7 8.54h1.06v1.07h-1.07V8.54zM13.82 8.54h1.06v1.07h-1.06V8.54zM8.5 14.94V16h2.13v-1.06H8.51z" />
            <path d="M8.5 9.61v4.26h3.2v-1.06h1.06v1.06H11.7v1.07h1.06V16h1.06v-1.06h2.13v-3.2h-1.07v-1.06h-1.06v1.06h-1.06v-1.06H11.7v1.06h-1.07v1.07H9.57v-1.07h1.06v-1.06H9.57V9.6H8.5zm5.32 3.2h1.06v1.06h-1.06v-1.06zM6.33 0H0v7.45h7.44V0H6.33zm0 6.34H1.12V1.12h5.2v5.22zM14.88 0H8.56v7.45H16V0h-1.12zm0 6.34h-5.2V1.12h5.2v5.22zM6.33 8.54H0V16h7.44V8.54H6.33zm0 6.34H1.12V9.66h5.2v5.22z" />
            <path d="M2.13 2.13h3.19v3.2h-3.2v-3.2zM10.68 2.13h3.2v3.2h-3.2v-3.2zM2.13 10.68h3.19v3.19h-3.2v-3.2z" />
        </g>
    );
    export const ticket = (
        <path d="M 16.47,5.12 C 16.47,5.12 16.47,1.92 16.47,1.92 16.47,1.03 15.77,0.32 14.91,0.32 14.91,0.32 2.42,0.32 2.42,0.32 1.56,0.32 0.87,1.03 0.87,1.92 0.87,1.92 0.87,5.12 0.87,5.12 1.73,5.12 2.42,5.84 2.42,6.72 2.42,7.60 1.73,8.32 0.86,8.32 0.86,8.32 0.86,11.52 0.86,11.52 0.86,12.40 1.56,13.12 2.42,13.12 2.42,13.12 14.91,13.12 14.91,13.12 15.77,13.12 16.47,12.40 16.47,11.52 16.47,11.52 16.47,8.32 16.47,8.32 15.62,8.32 14.91,7.60 14.91,6.72 14.91,5.84 15.62,5.12 16.47,5.12M 9.45,11.12 C 9.45,11.12 7.89,11.12 7.89,11.12 7.89,11.12 7.89,9.52 7.89,9.52 7.89,9.52 9.45,9.52 9.45,9.52 9.45,9.52 9.45,11.12 9.45,11.12M 9.45,7.52 C 9.45,7.52 7.89,7.52 7.89,7.52 7.89,7.52 7.89,5.92 7.89,5.92 7.89,5.92 9.45,5.92 9.45,5.92 9.45,5.92 9.45,7.52 9.45,7.52M 9.45,3.92 C 9.45,3.92 7.89,3.92 7.89,3.92 7.89,3.92 7.89,2.32 7.89,2.32 7.89,2.32 9.45,2.32 9.45,2.32 9.45,2.32 9.45,3.92 9.45,3.92"></path>
    );
    export const permitId = (
        <g>
            <path d="M7.19 8.79H8.5l.26-1.54H7.44l-.25 1.54z" />
            <path d="M15.55 2.09a1.67 1.67 0 0 0-1.13-.49H1.58a1.67 1.67 0 0 0-1.13.49A1.52 1.52 0 0 0 0 3.21v3.18a1.67 1.67 0 0 1 1.13.49A1.52 1.52 0 0 1 1.58 8a1.52 1.52 0 0 1-.45 1.12A1.67 1.67 0 0 1 0 9.61v3.18a1.52 1.52 0 0 0 .45 1.12 1.67 1.67 0 0 0 1.13.49h12.84a1.67 1.67 0 0 0 1.13-.49 1.52 1.52 0 0 0 .45-1.12V3.21a1.52 1.52 0 0 0-.45-1.12zm-4.1 5.16H9.92l-.25 1.54H11v1.07H9.49l-.39 2.39H7.94l.39-2.39H7l-.38 2.39H5.46l.39-2.39h-1.3V8.79H6l.28-1.54H5V6.18h1.46l.4-2.43H8l-.38 2.43h1.32l.4-2.43h1.16l-.4 2.43h1.35v1.07z" />
        </g>
    );
    export const contractNumber = (
        <path d="M6.85 9.64h1.86l.36-2.18H7.21zm1.61 1.51H6.6l-.55 3.37H4.41L5 11.14H3.13v-1.5h2.08l.36-2.18H3.75V6h2.07l.57-3.48H8L7.46 6h1.86l.57-3.48h1.64L11 6h1.9v1.46h-2.18l-.37 2.18h1.89v1.51H10.1l-.55 3.37H7.91z" />
    );
    export const personalNumber = (
        <path d="M5.8 7.94a2.63 2.63 0 0 0 1.93-.77 2.78 2.78 0 0 0 .82-1.93 2.78 2.78 0 0 0-.82-1.93 2.63 2.63 0 0 0-1.93-.79 2.64 2.64 0 0 0-2.71 2.71A2.64 2.64 0 0 0 5.8 7.94zM9.23 10.47a9.64 9.64 0 0 0-3.43-.73 9.65 9.65 0 0 0-3.44.73A2.76 2.76 0 0 0 .3 12.58v.9h11v-.9a2.76 2.76 0 0 0-2.07-2.11zM15.7 5.07v-.78h-1l.3-1.77h-.83l-.29 1.77h-1l.32-1.77h-.84l-.29 1.77H11v.78h.94l-.19 1.12h-1.07V7h.94l-.28 1.72h.85L12.47 7h1l-.32 1.72H14L14.28 7h1.1v-.81h-1l.19-1.12h1.13zm-2.14 1.12h-1l.19-1.12h1z" />
    );

    export const infoField = (
        <path d="M8.8,5.6H7.2V4H8.8V5.6Zm0,6.4H7.2V7.2H8.8V12ZM8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0" />
    );
}

export namespace radiobutton {
    export const off = (
        <path d="M12.13 3.87A5.88 5.88 0 1 1 8 2.18a5.79 5.79 0 0 1 4.13 1.69zm-.82 7.44A4.69 4.69 0 1 0 8 12.68a4.68 4.68 0 0 0 3.31-1.37z" />
    );
    export const on = (
        <path d="M12.13 3.87A5.88 5.88 0 1 1 8 2.18a5.79 5.79 0 0 1 4.13 1.69zm-.82 7.44A4.69 4.69 0 1 0 8 12.68a4.68 4.68 0 0 0 3.31-1.37zm-1.25-5.37A2.9 2.9 0 1 1 8 5.07a2.9 2.9 0 0 1 2.06.87z" />
    );
}

export namespace node {
    export const off = (
        <path d="M12.13 3.87A5.88 5.88 0 1 1 8 2.18a5.79 5.79 0 0 1 4.13 1.69zm-.82 7.44A4.69 4.69 0 1 0 8 12.68a4.68 4.68 0 0 0 3.31-1.37z" />
    );
    export const on = (
        <path d="M13.82 8A5.84 5.84 0 1 1 8 2.18a5.77 5.77 0 0 1 2.19.41l-.9.93A4.41 4.41 0 0 0 8 3.32 4.68 4.68 0 1 0 12.68 8h1.15zm-6.4.68l5-5 .83.82-5.82 5.82L4.8 7.7l.82-.82z" />
    );
    export const all = (
        <path d="M12.13 3.87A5.88 5.88 0 1 1 8 2.18a5.79 5.79 0 0 1 4.13 1.69zm-.05 1.8l-.82-.85-4.44 4.44-2.07-2.08-.82.82 2.9 2.93z" />
    );
}

export namespace checkbox {
    export const off = (
        <path d="M12.91,3.08a1.12,1.12,0,0,1,.34.85v8.15a1.17,1.17,0,0,1-1.18,1.18H3.93a1.17,1.17,0,0,1-1.18-1.18V3.93A1.12,1.12,0,0,1,3.93,2.75h8.15A1.16,1.16,0,0,1,12.91,3.08Zm-9,.85v8.15h8.15V3.93H3.93Z" />
    );
    export const on = (
        <path d="M12.91,3.08a1.12,1.12,0,0,1,.34.85v8.15a1.17,1.17,0,0,1-1.18,1.18H3.93a1.17,1.17,0,0,1-1.18-1.18V3.93A1.12,1.12,0,0,1,3.93,2.75h8.15A1.16,1.16,0,0,1,12.91,3.08Zm-0.83,2.6-0.82-.85L6.82,9.26,4.75,7.18,3.93,8l2.9,2.93Z" />
    );
    export const all = (
        <path d="M10.32,12.68v1.15h-7a1.12,1.12,0,0,1-1.15-1.15V5.07H3.32v7.6h7Zm3.5-2.35a1.16,1.16,0,0,1-.33.83,1.09,1.09,0,0,1-.82.34h-7a1.12,1.12,0,0,1-.85-0.34,1.16,1.16,0,0,1-.33-0.83v-7A1.11,1.11,0,0,1,4.83,2.5a1.15,1.15,0,0,1,.85-0.33h7a1.12,1.12,0,0,1,1.15,1.15v7ZM8.57,9.18l4.1-4.1-0.82-.82L8.57,7.51,6.77,5.73l-0.82.82Z" />
    );
    export const mixed = (
        <g>
            <path d="M10.32,12.68 L10.32,13.83 L3.32,13.83 C3.013,13.838 2.715,13.72 2.498,13.502 C2.28,13.285 2.162,12.987 2.17,12.68 L2.17,5.07 L3.32,5.07 L3.32,12.67 L10.32,12.67 z M13.82,10.33 C13.825,10.64 13.706,10.938 13.49,11.16 C13.277,11.385 12.979,11.508 12.67,11.5 L5.67,11.5 C5.351,11.513 5.042,11.389 4.82,11.16 C4.604,10.938 4.485,10.64 4.49,10.33 L4.49,3.33 C4.482,3.018 4.605,2.717 4.83,2.5 C5.056,2.278 5.363,2.158 5.68,2.17 L12.68,2.17 C12.987,2.162 13.285,2.28 13.502,2.498 C13.72,2.715 13.838,3.013 13.83,3.32 L13.83,10.32 z M12.67,10.33 L12.67,3.33 L5.67,3.33 L5.67,10.33 L12.67,10.33 z" />
            <path d="M11.243,8.381 C11.245,8.519 11.192,8.653 11.095,8.752 C11,8.853 10.867,8.908 10.728,8.904 L7.596,8.904 C7.453,8.91 7.315,8.855 7.216,8.752 C7.119,8.653 7.066,8.519 7.068,8.381 L7.068,5.249 C7.064,5.109 7.12,4.974 7.22,4.877 C7.321,4.778 7.459,4.724 7.601,4.73 L10.733,4.73 C10.87,4.726 11.003,4.779 11.101,4.876 C11.198,4.974 11.251,5.107 11.247,5.244 L11.247,8.376 z" />
        </g>
    );
    export const none = (
        <path d="M10.32,12.68v1.15h-7a1.12,1.12,0,0,1-1.15-1.15V5.07H3.32v7.6h7Zm3.5-2.35a1.16,1.16,0,0,1-.33.83,1.09,1.09,0,0,1-.82.34h-7a1.12,1.12,0,0,1-.85-0.34,1.16,1.16,0,0,1-.33-0.83v-7A1.11,1.11,0,0,1,4.83,2.5a1.15,1.15,0,0,1,.85-0.33h7a1.12,1.12,0,0,1,1.15,1.15v7Zm-1.15,0v-7h-7v7h7Z" />
    );
}

export namespace filter {
    export const enabled = (
        <path d="M2 1h12v1.34h-.06L9.34 7v8l-2.68-2.72V7l-4.6-4.66H2V1z" />
    );
    export const remove = (
        <path d="M.92 1h12v1.34h-.06L8.23 7v8l-2.65-2.72V7L1 2.34H.92V1zm8.5 12.53l1.88-1.87-1.88-1.85.94-.94 1.88 1.84 1.91-1.84.94.94-1.89 1.88 1.88 1.88-.94.94-1.91-1.87-1.87 1.86z" />
    );
}

export namespace sort {
    export const arrowUp = (
        <path d="M5.18 8.76l2.25-2.23v5.01H8.57V6.53l2.25 2.23.74-.74L8 4.46 4.44 8.02l.74.74z" />
    );
    export const arrowDown = (
        <path d="M10.82 7.24L8.57 9.47V4.46H7.43v5.01L5.18 7.24l-.74.74L8 11.54l3.56-3.56-.74-.74z" />
    );
}

export const permitsWithoutClearance = (
    <path d="M11.2 10.6c.2-.2.4-.5.4-.9s-.1-.7-.4-.9c-.2-.2-.5-.4-.9-.4H8.2V11h2.1c.4 0 .7-.2.9-.4m9.4 6.6c0 .3-.1.5-.3.7-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7m-3 0c0 .3-.1.5-.3.7-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7m-3 0c0 .3-.1.5-.3.7-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7M14 9.7c0 1.1-.4 2-1.1 2.7-.7.7-1.6 1.1-2.7 1.1h-2v4.6H5.7V5.9h4.5c1.1 0 2 .4 2.7 1.1.7.7 1.1 1.6 1.1 2.7m10 9.5V4.8c0-.7-.2-1.2-.7-1.7-.5-.4-1-.7-1.7-.7H2.4c-.7 0-1.2.3-1.7.7-.5.5-.7 1-.7 1.7v4.8c.7 0 1.2.3 1.7.7s.7 1 .7 1.7-.2 1.2-.7 1.7-1 .7-1.7.7v4.8c0 .7.2 1.2.7 1.7s1 .7 1.7.7h19.3c.7 0 1.2-.3 1.7-.7s.6-1 .6-1.7" />
);

export const stopTransaction = (
    <path d="M12.9,3.1c0.2,0.2,0.3,0.5,0.3,0.8v8.1c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3H3.9   c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V3.9c0-0.3,0.1-0.6,0.3-0.8s0.5-0.3,0.8-0.3h8.1C12.4,2.8,12.7,2.9,12.9,3.1z" />
);

export const transaction = (
    <path d="M8 0C5.733.067 3.867.8 2.334 2.334.801 3.801.067 5.734 0 8c.067 2.267.8 4.133 2.334 5.666C3.801 15.199 5.734 15.933 8 16c2.267-.067 4.133-.8 5.666-2.334C15.199 12.199 15.933 10.266 16 8c-.067-2.267-.8-4.133-2.334-5.666C12.133.801 10.266.067 8 0zM6.4 4.4L11.2 8l-4.8 3.6z" />
);

export const download = (
    <path d="M8,11L3.34,6.33H6v-4h4v4h2.66ZM3.34,12.33h9.31v1.34H3.34V12.33Z" />
);

export const pdf = (
    <path d="M4,1.3h5.3l4,4v8c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-1,0.4H4c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.6-0.4-0.9V2.7C2.7,2.3,2.8,2,3,1.7S3.6,1.3,4,1.3z M11.6,10.8c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2,0-0.3-0.1-0.4c-0.2-0.3-0.7-0.5-1.5-0.5L9.3,9.6L8.7,9.2C8.3,8.9,8,8.3,7.7,7.5l0-0.1C7.8,7,7.9,6.5,8,6.1c0-0.5,0-0.8-0.2-1C7.6,4.9,7.4,4.9,7.3,4.9H7.1C7,4.9,6.9,4.9,6.8,5C6.7,5.1,6.6,5.2,6.6,5.4C6.5,5.8,6.4,6.2,6.5,6.5c0,0.3,0.1,0.7,0.2,1.1C6.6,8.2,6.3,8.8,6,9.5l-0.6,1.2L4.8,11c-0.4,0.3-0.7,0.5-0.9,0.8c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.1,0,0.2,0,0.3l0,0l0.3,0.2l0.3,0.1c0.3,0,0.6-0.2,0.9-0.5c0.3-0.3,0.7-0.8,1.1-1.5L6.3,11C7,10.8,7.9,10.7,9,10.5c0.4,0.2,0.7,0.3,1.1,0.4c0.4,0.1,0.7,0.1,1,0.1C11.3,11,11.5,11,11.6,10.8z M5.2,11.3c-0.4,0.8-0.8,1.2-1.1,1.3c0-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.3-0.4,0.6-0.6L5.2,11.3z M7.3,8.3c0.3,0.6,0.6,1.1,1,1.4l0.3,0.2C8,10,7.2,10.2,6.4,10.6l-0.1,0l0.3-0.7C6.9,9.3,7.1,8.8,7.3,8.3z M7.2,6.7C7.1,6.1,7.1,5.7,7.2,5.4l0.1-0.1l0.1,0C7.4,5.5,7.5,5.7,7.4,6L7.2,6.7L7.2,6.7z M8.7,6h3.7L8.7,2.3V6z M11.3,10.4L11.3,10.4c0.1,0.1,0,0.2,0,0.2h-0.2c-0.3,0-0.8-0.1-1.2-0.3c0,0,0.1-0.1,0.1-0.1c0.5,0,0.8,0,1,0.1S11.3,10.3,11.3,10.4z" />
);

export const bulletpoint = (
    <path d="M11.7 8.5L4.8 12c-.3.2-.7 0-.8-.4V4.5c0-.5.4-.7.8-.5l6.9 3.6c.4.1.4.7 0 .9" />
);

export const indicatorCircle = <circle cx={8} cy={8} r={8} />;
