import * as LayoutState from '../state/LayoutState';
import {
    TwoColumnsVariant,
    TwoColumnsVariantOption,
} from './TwoColumnsVariant';
import { ResponsiveProperties } from './ResponsiveProperties';

interface TwoColumnsPropertiesInputs {
    layout: LayoutState.State;
}
export class TwoColumnsProperties {
    private _s: TwoColumnsPropertiesInputs;
    constructor(s: TwoColumnsPropertiesInputs) {
        this._s = s;
    }
    get variant(): TwoColumnsVariant {
        if (this._s.layout.viewportWidth < ResponsiveProperties.desktopSize) {
            return new TwoColumnsVariant(TwoColumnsVariantOption.OneColumn);
        } else {
            return new TwoColumnsVariant(TwoColumnsVariantOption.TwoColumns);
        }
    }
    get hasColumn1(): boolean {
        return this.variant.oneColumn || this.variant.twoColumns;
    }
    get hasColumn2(): boolean {
        return this.variant.twoColumns;
    }
    get numColumns(): number {
        if (this.variant.oneColumn) {
            return 1;
        } else {
            return 2;
        }
    }
    get columnSpacing(): number {
        return 32;
    }
}
