import { HasChildren } from 'dg-web-shared/lib/ReactHelpers';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import { css } from '@emotion/css';
import { paper } from '../../../../tb-ui/paper';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../tb-ui/typo';
import { Icon16 } from 'dg-web-shared/ui/icons/Icon';

export const Infobox = (p: HasChildren) => (
    <div
        className={css({
            margin: '18px 0',
            borderRadius: '10px 0 10px 10px',
            overflow: 'hidden',
            ...paper(2),
        })}
    >
        <div
            className={css({
                padding: '16px',
                color: Colors.blue,
                backgroundColor: Colors.white,
            })}
        >
            {p.children}
        </div>
    </div>
);

export const InfoboxText = (p: HasChildren) => (
    <div className={css({ ...Typo.body })}>{p.children}</div>
);

export const BulletpointText = (p: HasChildren) => (
    <div
        className={css({
            display: 'flex',
            marginTop: '8px',
            ...Typo.bodyItalic,
        })}
    >
        <div
            className={css({
                color: Colors.red,
                marginRight: '3px',
            })}
        >
            <Icon16 icon={Icons16.bulletpoint} />
        </div>
        {p.children}
    </div>
);
