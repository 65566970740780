import * as StringConversions from './StringConversions';

export enum RfidCardState {
    HAS_NOT_EXACT_LENGTH,
    INVALID,
}

export function hasState(
    states: RfidCardState[],
    state: RfidCardState,
): boolean {
    return states.indexOf(state) !== -1;
}

export interface RfidCardErrorTexts {
    HasNotExactLength: () => string;
    Invalid: () => string;
}

export function rfidCardErrorText(
    rfidString: string,
    text: RfidCardErrorTexts,
): string {
    const states = getRfidCardStates(rfidString);
    if (hasState(states, RfidCardState.HAS_NOT_EXACT_LENGTH)) {
        return text.HasNotExactLength();
    }
    if (hasState(states, RfidCardState.INVALID)) {
        return text.Invalid();
    }
    return '';
}

export function getRfidCardStates(rfidString: string): RfidCardState[] {
    const states: RfidCardState[] = [];
    if (StringConversions.stripNonNumericCharacters(rfidString).length !== 12) {
        states.push(RfidCardState.HAS_NOT_EXACT_LENGTH);
    }
    if (!validBadgeNumber(rfidString)) {
        states.push(RfidCardState.INVALID);
    }
    return states;
}

export function validBadgeNumber(badgeNumber: string): boolean {
    const bytes =
        StringConversions.stripNonNumericCharacters(badgeNumber).match(
            /.{1,3}/g,
        );
    if (!bytes) {
        return false;
    }
    return (
        bytes.every((b: string) => parseInt(b, 10) <= 255) &&
        !bytes.every((b: string) => parseInt(b, 10) === 0)
    );
}
