import { ModalErrorBox, ModalSuccessBox } from '../../ui/modals/Confirmable';
import * as TicketPlusModalTexts from '../../common/components/ticketplus/TicketPlusModalTexts';
import { AliasActivatedMessage } from '../../common/components/PaymentCallback';

import { useNavigate } from 'react-router-dom';
import { useTicketOutletContext } from './TicketOutletContext';
import { useEffect } from 'react';

export function TicketActivationFailedModal() {
    const navigate = useNavigate();
    const { refetchTicketState } = useTicketOutletContext();
    useEffect(() => {
        refetchTicketState();
    }, []);
    return (
        <ModalErrorBox
            titleCaption={TicketPlusModalTexts.paymentFailedModalTitle}
            confirmCallback={() => navigate('..')}
        >
            <p>{TicketPlusModalTexts.paymentFailedModalMessage}</p>
        </ModalErrorBox>
    );
}

export function TicketActivationTopupSuccessModal() {
    const navigate = useNavigate();
    const { refetchTicketState } = useTicketOutletContext();
    useEffect(() => {
        refetchTicketState();
    }, []);
    return (
        <ModalSuccessBox
            titleCaption={TicketPlusModalTexts.paymentSuccessfulModalTitle}
            confirmCallback={() => navigate('..')}
        >
            <p>{TicketPlusModalTexts.paymentAndTopupSuccessfulModalMessage}</p>
        </ModalSuccessBox>
    );
}

export function TicketActivationAliasSuccessModal() {
    const navigate = useNavigate();
    const { refetchTicketState } = useTicketOutletContext();
    useEffect(() => {
        refetchTicketState();
    }, []);
    return (
        <ModalSuccessBox
            titleCaption={TicketPlusModalTexts.paymentSuccessfulModalTitle}
            confirmCallback={() => navigate('..')}
        >
            <p>{TicketPlusModalTexts.paymentAndAliasSuccessfulModalMessage}</p>
            <p>{AliasActivatedMessage}</p>
        </ModalSuccessBox>
    );
}

export function TicketActivationDirectSuccessModal() {
    const navigate = useNavigate();
    const { refetchTicketState } = useTicketOutletContext();
    useEffect(() => {
        refetchTicketState();
    }, []);
    return (
        <ModalSuccessBox
            titleCaption={TicketPlusModalTexts.paymentSuccessfulModalTitle}
            confirmCallback={() => navigate('..')}
        >
            <p>{TicketPlusModalTexts.paymentSuccessfulModalMessage}</p>
        </ModalSuccessBox>
    );
}
