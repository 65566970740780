import { Message } from '../../../lib/Localized';
import { joinMessage } from './MessageHelpers';

export enum WeekDay {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export function weekdayTexts(weekdays: WeekDay[]): Message {
    return joinMessage(
        weekdays.map(weekday => weekdayText(weekday)),
        ', ',
    );
}

export function weekdayText(weekday: WeekDay): Message {
    switch (weekday) {
        case WeekDay.monday:
            return { de: 'Montag', fr: 'lundi', it: 'lunedì', en: 'Monday' };
        case WeekDay.tuesday:
            return {
                de: 'Dienstag',
                fr: 'mardi',
                it: 'martedì',
                en: 'Tuesday',
            };
        case WeekDay.wednesday:
            return {
                de: 'Mittwoch',
                fr: 'mercredi',
                it: 'mercoledì',
                en: 'Wednesday',
            };
        case WeekDay.thursday:
            return {
                de: 'Donnerstag',
                fr: 'jeudi',
                it: 'giovedì',
                en: 'Thursday',
            };
        case WeekDay.friday:
            return {
                de: 'Freitag',
                fr: 'vendredi',
                it: 'venerdì',
                en: 'Friday',
            };
        case WeekDay.saturday:
            return { de: 'Samstag', fr: 'samdi', it: 'sabato', en: 'Saturday' };
        case WeekDay.sunday:
            return {
                de: 'Sonntag',
                fr: 'dimanche',
                it: 'domenica',
                en: 'Sunday',
            };
    }
}
