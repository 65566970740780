import { css } from '@emotion/css';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { portalSlideIn } from '../root/components/PortalSlidein';

export const RemoteRequestDoneSlideIn = portalSlideIn<Props>(RemoteRequestDone);

export function RemoteRequestDone(props: Props) {
    return <div className={style}>{props.children}</div>;
}

interface Props {
    children: React.ReactNode;
}

const style = css`
    padding: 0px 24px;
    font-family: ${Typo.robotoLight.fontFamily};
    font-weight: ${Typo.robotoLight.fontWeight};
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.action_f};
`;
