export interface Point {
    x: number;
    y: number;
}
export const absoluteTouchPosition = (e: React.TouchEvent<HTMLDivElement>) => ({
    x: e.changedTouches[0].pageX - (window.scrollX || window.pageXOffset),
    y: e.changedTouches[0].pageY - (window.scrollY || window.pageYOffset),
});

export const absoluteMousePosition = (e: React.MouseEvent<HTMLDivElement>) => ({
    x: e.pageX - (window.scrollX || window.pageXOffset),
    y: e.pageY - (window.scrollY || window.pageYOffset),
});

const calculateOrigin = (
    container: Element,
    { x: offsetX, y: offsetY }: Point,
) => {
    const { x: containerX, y: containerY } =
        absoluteContainerPosition(container)!;
    return { x: containerX + offsetX, y: containerY + offsetY };
};

const calculateAngleDelta = (
    { x: x1, y: y1 }: Point,
    { x: x2, y: y2 }: Point,
) => Math.atan2(x1 * y2 - y1 * x2, x1 * x2 + y1 * y2);

const calculateAngleFromOrigin = (
    { x: originX, y: originY }: Point,
    { x: pointX, y: pointY }: Point,
) => {
    let angleInRadians = Math.atan2(pointY - originY, pointX - originX);
    if (angleInRadians < 0) {
        angleInRadians += 2 * Math.PI;
    }
    return angleInRadians;
};

const calculateAngleToPoint = (
    container: Element,
    offset: Point,
    point: Point,
) => {
    const origin = calculateOrigin(container, offset);
    return calculateAngleFromOrigin(origin, point);
};

export const calculateAngleBetweenPoints = (
    { x: originX, y: originY }: Point,
    { x: startX, y: startY }: Point,
    { x: endX, y: endY }: Point,
) => {
    const startPoint = { x: startX - originX, y: startY - originY };
    const endPoint = { x: endX - originX, y: endY - originY };
    return calculateAngleDelta(startPoint, endPoint);
};

export const calculateRadialPosition = (
    container: Element,
    offset: Point,
    radius: number,
    point: Point,
) => {
    const angleInRadians = calculateAngleToPoint(container, offset, point);
    return {
        x: offset.x + radius * Math.cos(angleInRadians),
        y: offset.y + radius * Math.sin(angleInRadians),
    };
};

const absoluteContainerPosition = (container: Element) => {
    const { left: x, top: y } = container.getBoundingClientRect();
    return { x, y };
};
