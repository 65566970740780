import { CSSObject } from '@emotion/css';

export function hover(content: CSSObject): CSSObject {
    return {
        '&:hover': {
            '@media screen and (min-width: 1224px)': {
                ...content,
            },
        },
    };
}
