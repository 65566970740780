import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../../common/components/FullWidthBottomButton';

export function DirectPayButton(props: {
    disabled: boolean;
    onClick: () => void;
}) {
    return (
        <FullWidthBottomButton
            color={FullWidthBottomButtonColor.GREEN}
            disabled={props.disabled}
            onClick={props.onClick}
            label={{
                de: 'Bezahlen',
                fr: 'Payer',
                it: 'Paga',
                en: 'Pay',
            }}
        />
    );
}
