import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import {
    getLicensePlateStates,
    licensePlateErrorText as genericlicensePlateErrorText,
    LicensePlateErrorTexts,
    LicensePlateState,
} from 'dg-web-shared/lib/LicensePlateValidation';
import {
    getRfidCardStates,
    RfidCardState,
} from 'dg-web-shared/lib/RfidCardValidation';
import * as String from 'dg-web-shared/lib/String';
import * as MonetaryValueValidation from 'dg-web-shared/lib/MonetaryValueValidation';
import { Language } from 'dg-web-shared/lib/Text';
import { ibanTexts } from '../common/i18n/FieldTexts';
import Text = require('../common/i18n/Text');
import StringConversions = require('dg-web-shared/lib/StringConversions');
import emailValidator = require('email-validator');

export {
    LicensePlateState,
    LicensePlateErrorTexts,
} from 'dg-web-shared/lib/LicensePlateValidation';
export { MonetaryValueState as MoneyState } from 'dg-web-shared/lib/MonetaryValueValidation';

const countries = require('i18n-iso-countries');

export interface Field<S> {
    value: string;
    isEmpty: boolean;
    states: S[];
}

export enum EmailState {
    INVALID,
}

function forceString(str: string): string {
    return str ? str : '';
}

export function hasState<S>(field: Field<S>, state: S): boolean {
    return field.states.indexOf(state) !== -1;
}

export class Email implements Field<EmailState> {
    constructor(value: string) {
        this._value = forceString(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): EmailState[] {
        if (!this.isEmpty && !emailValidator.validate(this.value)) {
            return [EmailState.INVALID];
        }
        return [];
    }
}

export interface EmailTexts {
    Invalid: Text.Translation;
}

export function emailErrorText(field: Email, text: EmailTexts): string {
    if (hasState(field, EmailState.INVALID)) {
        return text.Invalid();
    }
    return '';
}

export const licensePlateErrorText = (
    field: LicensePlate,
    texts: LicensePlateErrorTexts,
): string =>
    genericlicensePlateErrorText(
        { licensePlateNr: field.value, licensePlateCountryId: field.country },
        texts,
    );

export const stripNotAllowedLpCharachters = (
    value: string,
    countryId: Maybe<string>,
): string => {
    switch (countryId) {
        case 'CH':
        case 'FL':
            return StringConversions.stripNonNumericAndNoLetterCharacters(
                forceString(value),
            );
        default:
            return StringConversions.stripNonNumericAndNoLetterCharactersAllowUmlauts(
                forceString(value),
            );
    }
};

export class LicensePlate implements Field<LicensePlateState> {
    private _countryId: string;

    constructor(value: string | null | undefined, countryId: string) {
        this._countryId = countryId;
        this._value = stripNotAllowedLpCharachters(value || '', countryId);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get country(): string {
        return this._countryId;
    }
    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): LicensePlateState[] {
        return getLicensePlateStates({
            licensePlateNr: this._value,
            licensePlateCountryId: this._countryId,
        });
    }
}

export class Password implements Field<void> {
    constructor(value: string) {
        this._value = forceString(value);
    }

    private _value: string;

    get value(): string {
        return forceString(this._value);
    }

    get isEmpty(): boolean {
        return this._value === '';
    }

    get states(): void[] {
        return [];
    }
}

export class StandardBadgeNumber implements Field<RfidCardState> {
    constructor(value: string) {
        value = forceString(value);
        this._value =
            StringConversions.stripNonNumericCharactersAllowSpace(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): RfidCardState[] {
        if (this.value === '') {
            return [];
        } else {
            return getRfidCardStates(this.value);
        }
    }
}

export enum MobilePhoneState {
    MUST_CONTAIN_PREFIX,
    INVALID,
    TOO_SHORT,
    TOO_LONG,
}

export class MobilePhone implements Field<MobilePhoneState> {
    private prefix = '+';

    constructor(value: string) {
        this._value =
            StringConversions.stripNonNumericCharactersAllowSpaceAndPlus(
                forceString(value),
            );
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): MobilePhoneState[] {
        const states: MobilePhoneState[] = [];
        if (this.isEmpty) {
            return states;
        }
        const valueClean = StringConversions.stripNonNumericCharactersAllowPlus(
            this.value,
        );

        if (valueClean[0] !== this.prefix[0]) {
            states.push(MobilePhoneState.MUST_CONTAIN_PREFIX);
            return states;
        }

        const inputOnly = valueClean.substr(1, valueClean.length - 1);

        if (inputOnly.length < 10) {
            states.push(MobilePhoneState.TOO_SHORT);
        }

        if (inputOnly.length > 13) {
            states.push(MobilePhoneState.TOO_LONG);
        }

        if (!/^\+[1-9][0-9]{9,12}$/.test(valueClean)) {
            states.push(MobilePhoneState.INVALID);
        }

        return states;
    }
}

export class GenericField implements Field<void> {
    constructor(value: string) {
        this._value = forceString(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): void[] {
        return [];
    }
}

export class NumberField implements Field<void> {
    constructor(value: string) {
        this._value = StringConversions.stripNonNumericCharacters(
            forceString(value),
        );
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): void[] {
        return [];
    }
}

export interface MoneyTexts {
    InvalidAmount: Text.Translation;
}

export function moneyErrorText(field: MoneyField, text: MoneyTexts): string {
    if (
        hasState(
            field,
            MonetaryValueValidation.MonetaryValueState.INVALID_AMOUNT,
        )
    ) {
        return text.InvalidAmount();
    }
    return '';
}

export class MoneyField
    implements Field<MonetaryValueValidation.MonetaryValueState>
{
    constructor(value: string) {
        this._value = MonetaryValueValidation.autoFormat(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): MonetaryValueValidation.MonetaryValueState[] {
        return MonetaryValueValidation.getStates(this._value);
    }
}

export enum IbanState {
    NO_VALID_COUNTRY_CODE,
    CHECKSUM_FAIL,
    TOO_SHORT,
    TOO_LONG,
    NOT_UPPERCASE,
}

export interface IbanTexts {
    NoValidCountryCode: Text.Translation;
    ChecksumFail: Text.Translation;
    TooShort: Text.Translation;
    TooLong: Text.Translation;
    AllLettersUppercase: Text.Translation;
}

export function ibanErrorText(badge: Iban, language: Language): string {
    const t = ibanTexts[language];

    const map: [IbanState, string][] = [
        [IbanState.NOT_UPPERCASE, t.AllLettersUppercase()],
        [IbanState.TOO_SHORT, t.TooShort()],
        [IbanState.TOO_LONG, t.TooLong()],
        [IbanState.NO_VALID_COUNTRY_CODE, t.NoValidCountryCode()],
        [IbanState.CHECKSUM_FAIL, t.ChecksumFail()],
    ];

    const match = map.find(([err, _]) => hasState(badge, err));
    return match ? match[1] : '';
}

function validateCountryCode(countryCode: string): boolean {
    const a2codes = countries.getAlpha2Codes();
    return a2codes[countryCode];
}

/**
 * Prepare an IBAN for mod 97 computation by moving the first 4 chars to the end and transforming the letters to
 * numbers (A = 10, B = 11, ..., Z = 35), as specified in ISO13616.
 *
 * @param {string} iban the IBAN
 * @returns {string} the prepared IBAN
 */
function iso13616Prepare(iban: string): string {
    iban = iban.toUpperCase();
    iban = iban.substr(4) + iban.substr(0, 4);

    const A = 'A'.charCodeAt(0),
        Z = 'Z'.charCodeAt(0);

    return iban
        .split('')
        .map((n: string) => {
            const code = n.charCodeAt(0);
            if (code >= A && code <= Z) {
                // A = 10, B = 11, ... Z = 35
                return code - A + 10;
            } else {
                return n;
            }
        })
        .join('');
}

/**
 * Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064.
 */
function iso7064Mod97_10(iban: string): number {
    let remainder = iban;
    let block = '';

    while (remainder.length > 2) {
        block = remainder.slice(0, 9);
        remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
    }

    return parseInt(remainder, 10) % 97;
}

const IBAN_MAX_LENGTH = 34;
const IBAN_LENGTH_CH_LI = 21;
const POST_FINANCE_CLEARING_NUMBER = 9000;

export enum AccountType {
    BANK = 0,
    POST = 1,
}

export class Iban implements Field<IbanState> {
    constructor(value: string) {
        value = forceString(value);
        this._value =
            StringConversions.stripNonNumericAndNoLetterCharactersAllowSpace(
                value,
            );
        this._valueNoSpace =
            StringConversions.stripNonNumericAndNoLetterCharacters(value);
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    private _valueNoSpace: string;

    get valueNoSpace(): string {
        return this._valueNoSpace;
    }

    get isCHLI(): boolean {
        if (this._value.length < 2) {
            return true; // as long as we do not have the full country code, assume its CH/LI -> removes BIC field
        }
        const countryCode = this._value.substring(0, 2);
        return countryCode === 'CH' || countryCode === 'LI';
    }

    get type(): number | null {
        if (!this.isCHLI) {
            return null;
        }
        if (this.valueNoSpace.length < 9) {
            return null;
        }

        if (this.clearingNumber === POST_FINANCE_CLEARING_NUMBER) {
            return AccountType.POST;
        } else {
            return AccountType.BANK;
        }
    }

    get clearingNumber(): number {
        return parseInt(this.valueNoSpace.substring(4, 9), 10);
    }

    get country(): string {
        return this.value.substring(0, 2) || '';
    }

    get isEmpty(): boolean {
        return this.value === '';
    }

    get states(): IbanState[] {
        const states: IbanState[] = [];
        if (this.isEmpty) {
            return states;
        }

        const length = this.valueNoSpace.length;
        const value = this.valueNoSpace;

        const countryCode = value.substring(0, 2);

        if (length >= 2) {
            if (!validateCountryCode(countryCode)) {
                states.push(IbanState.NO_VALID_COUNTRY_CODE);
            }
        }

        if (!String.Validators.isUpperCase(value)) {
            states.push(IbanState.NOT_UPPERCASE);
        }

        if (this.isCHLI) {
            if (length < IBAN_LENGTH_CH_LI) {
                states.push(IbanState.TOO_SHORT);
            } else if (length === IBAN_LENGTH_CH_LI) {
                if (length === IBAN_LENGTH_CH_LI) {
                    // 21 = 2 digits country code + 2 digits checksum + 17 digits BBAN
                    if (iso7064Mod97_10(iso13616Prepare(value)) !== 1) {
                        states.push(IbanState.CHECKSUM_FAIL);
                    }
                }
            } else {
                states.push(IbanState.TOO_LONG);
            }
        } else {
            if (value.length > 4 && value.length < IBAN_MAX_LENGTH) {
                if (iso7064Mod97_10(iso13616Prepare(value)) !== 1) {
                    states.push(IbanState.CHECKSUM_FAIL);
                }
            } else if (value.length > IBAN_MAX_LENGTH) {
                states.push(IbanState.TOO_LONG);
            }
        }

        return states;
    }
}

export enum BicState {
    NOT_UPPERCASE,
    TOO_SHORT,
    TOO_LONG,
    DOES_NOT_MATCH_IBAN,
    INVALID,
}

export interface BicTexts {
    TooShort: Text.Translation;
    TooLong: Text.Translation;
    AllLettersUppercase: Text.Translation;
    DoesNotMatchIban: Text.Translation;
    Invalid: Text.Translation;
}

export function bicErrorText(badge: Bic, text: BicTexts): string {
    if (hasState(badge, BicState.NOT_UPPERCASE)) {
        return text.AllLettersUppercase();
    }
    if (hasState(badge, BicState.TOO_SHORT)) {
        return text.TooShort();
    }
    if (hasState(badge, BicState.TOO_LONG)) {
        return text.TooLong();
    }
    if (hasState(badge, BicState.DOES_NOT_MATCH_IBAN)) {
        return text.DoesNotMatchIban();
    }
    if (hasState(badge, BicState.INVALID)) {
        return text.Invalid();
    }
    return '';
}

export class Bic implements Field<BicState> {
    private _validationCountry: string;

    // the validationCountry is the country given in the first two digits of the IBAN
    constructor(value: string, validationCountry: string) {
        value = forceString(value);
        this._value =
            StringConversions.stripNonNumericAndNoLetterCharacters(value);
        this._validationCountry = validationCountry;
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value === '';
    }
    get states(): BicState[] {
        const states: BicState[] = [];
        if (this.isEmpty) {
            return states;
        }

        if (this.value.length < 8) {
            states.push(BicState.TOO_SHORT);
        }

        if (this.value.length > 11) {
            states.push(BicState.TOO_LONG);
        }

        if (!String.Validators.isUpperCase(this.value)) {
            states.push(BicState.NOT_UPPERCASE);
        }

        if (this.value.substring(4, 6) !== this._validationCountry) {
            states.push(BicState.DOES_NOT_MATCH_IBAN);
        }

        if (
            !this.value.match(
                /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/,
            )
        ) {
            states.push(BicState.INVALID);
        }
        return states;
    }
}

export function noStates(...fields: Field<any>[]): boolean {
    for (const f of fields) {
        if (f.states.length > 0) {
            return false;
        }
    }
    return true;
}

export function noStatesAndNotEmpty(...fields: Field<any>[]): boolean {
    for (const f of fields) {
        if (f.states.length > 0 || f.isEmpty) {
            return false;
        }
    }
    return true;
}
