import { ModalErrorBox } from './Confirmable';
import {
    ServerRequestState,
    RequestStatus,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Localized } from '../../common/components/Localized';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { SpinnerModal } from '../../../../tb-ui/spinner/Spinner';

interface Props {
    serverHooks: [ServerRequestState<any, any>, () => void][];
    showSpinner: boolean;
    ignore400: boolean;
}

export function HandleHttpStati(p: Props) {
    const minStatusCode = p.ignore400 ? 499 : 399;
    const isError = (s: ServerRequestState<any, any>) =>
        s.status === RequestStatus.ERROR && s.httpStatusCode > minStatusCode;
    const isPending = (s: ServerRequestState<any, any>) =>
        s.status === RequestStatus.PENDING && p.showSpinner;
    return (
        <>
            <SpinnerModal
                visible={p.serverHooks.some(s => isPending(s[0]))}
                portal={SlideInPortalId.FULL_SCREEN_MODAL}
            />
            {p.serverHooks.some(s => isError(s[0])) && (
                <ModalErrorBox
                    confirmCallback={() => {
                        p.serverHooks
                            .filter(it => isError(it[0]))
                            .forEach(it => it[1]());
                    }}
                >
                    <GenericErrorBody />
                </ModalErrorBox>
            )}
        </>
    );
}

export function GenericErrorBody() {
    return (
        <p>
            <Localized
                de="Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk."
                fr="Une erreur est survenue. Merci de réessayer plus tard. Si le problème persiste, merci de l’annoncer à notre Helpdesk."
                it="Si è verificato un errore. Vogliate riprovare più tardi. Se il problema dovesse persistere, contattate il nostro Helpdesk."
                en="An error has occurred. Please try again later. If the problem persists, please contact our Helpdesk."
            />
        </p>
    );
}
