import { Store } from 'dg-web-shared/lib/Flux';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import * as LayoutActions from '../../layout/actions/LayoutActions';
import * as TransactionsListState from '../../transactions-list/state/TransactionsListState';
import * as ParkOptionListState from '../state/ParkOptionListState';

export function navigateToPermitDetails(
    store: Store,
    permitId: number,
): string {
    TransactionsListState.Detail.stateWrite(store, { permitId });
    LayoutActions.mobileNavigateTransactions(store);
    return 'PermitActions-openPermit';
}

export function navigateToPermitPurchaseConfirmation(
    store: Store,
    purchasedPermitId: number,
    permitPurchaseIncludedBalanceTopUp: boolean,
): string {
    TransactionsListState.Detail.stateWrite(store, {
        purchasedPermitId,
        permitPurchaseIncludedBalanceTopUp,
    });

    LayoutActions.mobileNavigateTransactions(store);
    return 'PermitActions-openPermitPurchaseConfirmation';
}

export function refreshStateSlicesOnSuccessfulPermitPurchase(
    store: Store,
    clearSelection = true,
) {
    new AccountBalanceState.StateSlice(store).reset();
    if (clearSelection) {
        store.update(ParkOptionListState.Selection.setVariant, null);
    }
}
