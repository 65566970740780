import { Localized } from '../../common/components/Localized';
import { ResponseCode } from '../../common/state/WriteStateSlice';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import rgba = Colors.rgba;
import { Typo } from '../../../../tb-ui/typo';
import { Icon } from 'dg-web-shared/ui/icons/Icon';
import { Clickable } from 'dg-web-shared/ui/Clickable';

export function ErrorBlock(props: {
    apiErrorCode: number | null;
    onClose: () => void;
}) {
    return (
        <div
            className={css({
                position: 'relative',
                borderTop: `1px solid ${rgba(Colors.error, 0.5)}`,
                borderBottom: `1px solid ${rgba(Colors.error, 0.5)}`,
                minHeight: '48px',
                margin: '0px -24px',
                background: Colors.error_bg,
            })}
        >
            <div
                className={css({
                    position: 'absolute',
                    color: Colors.error,
                    top: 0,
                    left: '8px',
                    width: '48px',
                    height: '48px',
                    marginTop: '12px',
                    marginLeft: '12px',
                })}
            >
                <Icon icon="error" />
            </div>

            <div
                className={css({
                    color: Colors.error,
                    marginTop: `${20 - Typo.captionTopCorrection}px`,
                    marginLeft: '64px',
                    marginRight: '48px',
                    marginBottom: `${20 - Typo.captionTopCorrection}px`,
                    ...Typo.caption,
                })}
            >
                {props.apiErrorCode === ResponseCode.WHITELIST_MISSING ? (
                    <Localized
                        de="Keine Freigabe vom Betreiber."
                        fr="Pas d'approbation."
                        it="Nessuna approvazione"
                        en="No approval from operator"
                    />
                ) : props.apiErrorCode === ResponseCode.INVALID_BADGE ? (
                    <Localized
                        de="Es ist noch nicht möglich, diese Bewilligung zu erwerben, da mindestens einer der ausgewählten Badges zwar bestellt, aber unsererseits noch nicht aktiviert und versendet wurde. Das wird in den nächsten 3 Werktagen passieren."
                        fr="Il n'est pas encore possible d'acheter cette autorisation parce qu'au moins un des badges sélectionnés a été commandé, mais de notre part pas encore activé et envoyé. Cela se produira dans les 3 prochains jours ouvrables."
                        it="Non è ancora possibile aquistare quest'autorizzazione in quanto almeno un badge tra quelli selezionati è stato sì ordinato, ma da parte nostra non ancora attivato e inviato. Questo avverrà nei prossimi 3 giorni lavorativi."
                        en="It is not yet possible to purchase this authorization because at least one of the selected badges has been ordered, but on our part not yet activated and sent. This will happen in the next 3 working days."
                    />
                ) : (
                    <Localized
                        de="Beim Erwerben der Bewilligung ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin auftritt, melden Sie sich bei unserem Helpdesk."
                        fr="Une erreur s'est produite lors de l'acquisition de l’autorisation. Merci de réessayer plus tard. Si le problème persiste, merci de l’annoncer à notre Helpdesk."
                        it="Si è verificato un errore durante l'acquisto dell'autorizzazione. Vogliate riprovare più tardi. Se il problema dovesse persistere, contattate il nostro Helpdesk."
                        en="An error occurred during the purchase of the permit. Please try again later. If the problem persists, please contact our Helpdesk."
                    />
                )}

                <br />

                <b>
                    <Localized
                        de="Sie sind trotzdem verpflichtet, die Parkgebühr auf einem anderen Weg (z.B. Parkuhr) zu bezahlen."
                        fr="Vous êtes quand même tenu de payer les taxes de stationnement par un autre moyen (p.ex. parcomètre)."
                        it="Siete comunque tenuti a pagare la tassa di parcheggio con altri mezzi (p.es. parchimetro)."
                        en="'You must pay the parking fee by other means (e.g. parking meter)."
                    />
                </b>
            </div>

            <Clickable
                element="div"
                className={css({
                    color: Colors.error,
                    position: 'absolute',
                    top: '3px',
                    width: '48px',
                    paddingLeft: '12px',
                    paddingTop: '12px',
                    right: '8px',
                })}
                onClick={props.onClose}
            >
                <Icon icon="clear" />
            </Clickable>
        </div>
    );
}
