import * as Flux from 'dg-web-shared/lib/Flux';
import { logAction } from '../../utils/ActionLog';
import { Settings } from 'dg-web-shared/lib/LocalStorage';

export interface State {
    language: string;
    browserLanguagePending: boolean;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'common-SettingsState';
    }

    sideEffects() {}

    getInitialState(): State {
        const storedLanguage = Settings.getUiLanguage();
        const LOCALE =
            (window.navigator as any).userLanguage || window.navigator.language;

        const lang = storedLanguage
            ? storedLanguage
            : LOCALE
              ? LOCALE.slice(0, 2)
              : 'en';

        const langIsPresent = ['fr', 'it', 'de', 'en'].indexOf(lang) > -1;
        if (!langIsPresent) {
            logAction((this as any).store, 'unknown-locale', LOCALE);
        }
        return {
            language: langIsPresent ? lang : 'en',
            browserLanguagePending: false,
        };
    }

    writeBrowserLanguagePending(): void {
        this.set((s: State) => {
            s.browserLanguagePending = true;
            return s;
        });
    }

    writeLanguage(language: string): void {
        this.set((s: State) => {
            s.language = language;
            s.browserLanguagePending = false;
            return s;
        });
    }
}
