import {
    JsxMessage,
    Language,
    languageFromString,
    Message,
} from 'dg-web-shared/lib/Localized';
import { useSettingsStateSlice } from './state/SettingsState';

export function Localized(props: Message | JsxMessage) {
    const [settings] = useSettingsStateSlice();

    return <>{props[languageFromString(settings.language)]}</>;
}

export function useAdminLanguage(): Language {
    const [settings] = useSettingsStateSlice();
    return settings.language;
}

export function useAdminTranslation(message: Message): string {
    const [settings] = useSettingsStateSlice();
    return message[settings.language];
}
