import { SlideInPortalId } from '../../parkingcard-app/src/account/root/components/PortalSlidein';
import { css } from '@emotion/css';
import { ResponsiveProperties } from '../../parkingcard-app/src/layout/utils/ResponsiveProperties';
import { selectState } from 'dg-web-shared/lib/Flux';
import { Colors } from 'dg-web-shared/ui/vars';
import { StartedCheckinByPlateOnThisDeviceState } from '../../parkingcard-app/src/park-create/components/ParkCreate';
import * as LayoutState from '../../parkingcard-app/src/layout/state/LayoutState';
import { Modal } from '../../parkingcard-app/src/common/components/Modal';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import React from 'react';

export const SpinnerModal = (p: {
    visible: boolean;
    portal: SlideInPortalId;
}) => {
    if (!p.visible) {
        return null;
    }
    return (
        <Modal portal={p.portal}>
            <EasingInSpinner />
        </Modal>
    );
};

class EasingInSpinner extends React.Component<object, { didMount: boolean }> {
    private delayId: any;
    constructor(p: object) {
        super(p);
        this.state = { didMount: false };
    }
    componentDidMount() {
        this.delayId = setTimeout(() => {
            this.setState({ didMount: true });
        }, 350);
    }
    componentWillUnmount() {
        clearTimeout(this.delayId);
    }
    render() {
        return (
            <div
                className={css({
                    background: Colors.white,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    zIndex: 1000,
                    position: 'absolute',
                    opacity: this.state.didMount ? 0.66 : 0,
                    transition: 'opacity 200ms ease-in',
                })}
            >
                {this.state.didMount && <Spinner centerVertically />}
            </div>
        );
    }
}

export const EmptyBackground = selectState(
    s => {
        return {
            layout: new LayoutState.StateSlice(s).state,
            runningTransactionId: StartedCheckinByPlateOnThisDeviceState.get(s),
        };
    },
    p => {
        const blueSpinnerBackground =
            new ResponsiveProperties(p).mobile &&
            !p.runningTransactionId.checkinByPlateId;
        return (
            <div
                className={css({
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    background: blueSpinnerBackground
                        ? Colors.blue
                        : Colors.white,
                })}
            />
        );
    },
);
