import { css } from '@emotion/css';

import * as superagent from 'superagent';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Response } from 'dg-web-shared/lib/HttpResponse';
import { isDefined } from 'dg-web-shared/lib/MaybeV2';
import { languageFromString } from 'dg-web-shared/lib/Text';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { Infobox, InfoboxText } from '../../account-setup/components/Infobox';
import * as AsyncRequest from '../../AsyncRequest';
import { Localized } from '../../common/components/Localized';
import { bicTexts } from '../../common/i18n/FieldTexts';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import * as SettingsState from '../../common/state/SettingsState';
import * as WriteStateSlice from '../../common/state/WriteStateSlice';
import { AddressBlock } from '../../park-create/components/license-plate/InfoBoxes';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { logAction } from '../../utils/ActionLog';
import * as Fields from '../../utils/Fields';
import * as MetaServerState from '../meta/state/MetaServerState';
import { ErrorBlock } from '../root/components/ErrorBlock';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import * as MetaTexts from './i18n/MetaTexts';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter';
import { InlineAlertBox } from '../../ui/modals/Confirmable';
import { ParkingpayAsyncLoadedSection } from '../../common/components/ParkingpayAsyncLoadedSection';
import { isRunningInNativeWrapper } from '../../Native';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { useState } from 'react';

export const DeleteAccountFormSlideIn = portalSlideIn(DeleteAccountForm);

export interface ActivePermit {
    hasActivePermits: boolean;
}

function DeleteAccountForm(props: {
    paymentType: PaymentTypeState.PaymentType;
    accountBalance: AccountBalanceState.Balance;
    currentLogin: CurrentLoginState.CurrentLogin;
    onClose: () => void;
}) {
    const [password, setPassword] = useState<string | null>(null);
    const [iban, setIban] = useState<string | null>(null);
    const [bic, setBic] = useState<string | null>(null);
    const [hasActivePermit] = useServerFetch<
        ActivePermit,
        Record<string, never>
    >(
        () => ({
            url: `/ui-api/customer-account/has-active-permit`,
        }),
        {},
    );

    const { storeState, store } = Flux.useStore(s => {
        const responseSlice = new ResponseStateSlice(s);
        const resetResponse = responseSlice.reset.bind(responseSlice);

        return {
            settings: new SettingsState.StateSlice(s).state,
            response: responseSlice.state,
            legacyUpdater: s.legacyUpdater,
            resetResponse,
            meta: new MetaServerState.StateSlice(s).state,
        };
    });

    function onClose() {
        storeState.resetResponse();
        props.onClose();
    }

    const isNegative = props.accountBalance.balance < 0;
    const form = getFormFields({ password, iban, bic });
    const isSaveable = formIsSaveable(props.accountBalance, password, form);
    const texts = MetaTexts.deleteAccountTexts[storeState.settings.language];

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Kündigen"
                        fr="Résilier"
                        it="Disdire"
                        en="Delete"
                    />
                ),
                disabled: !isSaveable || isNegative,
                onClick: () => {
                    const clientGuiType = isRunningInNativeWrapper()
                        ? Http.ClientGuiType.NATIVE
                        : Http.ClientGuiType.WEB;

                    storeState.legacyUpdater(deleteAccount, {
                        username: props.currentLogin.email,
                        password,
                        iban: iban || null,
                        bic: bic || null,
                        clientGuiType,
                    });

                    logAction(store, 'terminate-account', {
                        iban,
                        bic,
                        clientGuiType,
                    });
                },
            }}
        >
            <ParkingpayAsyncLoadedSection
                state={hasActivePermit}
                renderSuccess={data => (
                    <Info
                        accountBalance={props.accountBalance}
                        hasActivePermits={data.hasActivePermits}
                    />
                )}
                showSpinnerOnRefetch
                portal={SlideInPortalId.USER_ACCOUNT}
            />

            {!isNegative && (
                <>
                    <TextField
                        value={password || ''}
                        labelText={texts.Password()}
                        inputType="password"
                        onChange={setPassword}
                    />

                    {props.accountBalance.balance > 0 && (
                        <>
                            <TextField
                                value={iban || ''}
                                labelText={texts.Iban()}
                                errorText={
                                    form.iban.isEmpty
                                        ? ''
                                        : Fields.ibanErrorText(
                                              form.iban,
                                              languageFromString(
                                                  storeState.settings.language,
                                              ),
                                          )
                                }
                                onChange={setIban}
                            />

                            {!form.iban.isCHLI && (
                                <TextField
                                    value={bic || ''}
                                    labelText={texts.Bic()}
                                    errorText={
                                        form.bic.isEmpty
                                            ? ''
                                            : Fields.bicErrorText(
                                                  form.bic,
                                                  bicTexts[
                                                      storeState.settings
                                                          .language
                                                  ],
                                              )
                                    }
                                    onChange={setBic}
                                />
                            )}

                            <Infobox>
                                <InfoboxText>
                                    <AddressBlock
                                        address={storeState.meta.data.address}
                                        shippingAddress={
                                            storeState.meta.data.shippingAddress
                                        }
                                        isBillingAddress={false}
                                    />
                                </InfoboxText>
                            </Infobox>

                            <p
                                className={css({
                                    ...Typo.robotoMedium,
                                    color: Colors.action_f,
                                })}
                            >
                                <Localized
                                    de="Bitte stellen Sie sicher, dass Ihre Adresse als Zahlungsempfängers korrekt ist."
                                    fr="Veuillez vous assurer que votre adresse est correcte en tant que destinataire du paiement."
                                    it="Voglia assicurarsi che il suo indirizzo, quale destinatario del pagamento, sia corretto."
                                    en="Please make sure that your address as the payment recipient is correct."
                                />
                            </p>
                        </>
                    )}
                </>
            )}

            <RemoteRequestError response={storeState.response} />
        </SlideInForm>
    );
}

function Info({
    accountBalance,
    hasActivePermits,
}: {
    accountBalance: AccountBalanceState.Balance;
    hasActivePermits: boolean;
}) {
    const saldo = accountBalance.balance;
    const priceFormatted = numberToPrice(saldo);

    return (
        <div
            className={css({
                ...Typo.robotoMedium,
                fontSize: '15px',
                marginTop: '8px',
                marginBottom: '24px',
                color: Colors.action_f,
            })}
        >
            {hasActivePermits && (
                <InlineAlertBox
                    titleCaption={
                        <Localized
                            de="Vorhandene Bewilligungen"
                            fr="Autorisations existantes"
                            it="Autorizzazioni esistenti"
                            en="Existing permits"
                        />
                    }
                >
                    <p>
                        <Localized
                            de={
                                <>
                                    Sie haben mindestens noch eine gültige
                                    Bewilligung. Durch das Kündigen des Kontos,
                                    wird diese
                                    <b> nicht automatisch annuliert.</b>
                                </>
                            }
                            fr={
                                <>
                                    Vous avez au moins encore une autorisation
                                    valable. La résiliation du compte
                                    <b>
                                        {' '}
                                        ne l&apos;annule pas automatiquement.
                                    </b>
                                </>
                            }
                            it={
                                <>
                                    Ha ancora almeno un&apos;autorizzazione
                                    valida. Disdicendo il suo account
                                    l’autorizzazione
                                    <b>
                                        {' '}
                                        non verrà cancellata automaticamente.
                                    </b>
                                </>
                            }
                            en={
                                <>
                                    You still have at least one valid permit.
                                    Cancelling your account
                                    <b> does not automatically cancel it.</b>
                                </>
                            }
                        />
                    </p>
                    <p>
                        <Localized
                            de="Bitte wenden Sie sich an den jeweiligen Betreiber bevor Sie Ihr Parkingpay Konto kündigen, da Rückzahlungen von Bewilligungen nur auf Ihr Parkingpay Konto gemacht werden können."
                            fr="Veuillez vous adresser à l'exploitant concerné avant de résilier votre compte Parkingpay, car les remboursements d'autorisations ne peuvent être effectués que sur votre compte Parkingpay."
                            it="Contatti il relativo gestore prima di disdire il suo conto Parkingpay, poiché un eventuale rimborso dell’autorizzazione può essere effettuato solo sul suo conto Parkingpay."
                            en="Please contact the corresponding operator before cancelling your Parkingpay account, as refunds of permits can only be made to your Parkingpay account."
                        />
                    </p>
                </InlineAlertBox>
            )}
            {saldo < 0 && (
                <p>
                    <Localized
                        de={`Aufgrund des negativen Saldos von ${priceFormatted} kann Ihr Konto derzeit nicht gekündig werden`}
                        fr={`À cause du solde negativ de ${priceFormatted} au moment votre compte ne peut pas être résilié`}
                        it={`A causa del saldo negativo di ${priceFormatted} al momento il vostro conto non può essere disdetto`}
                        en={`Due to the negative balance of ${priceFormatted} at the moment your account can not be deleted`}
                    />
                </p>
            )}
            {saldo === 0 && pressToDeleteImmediately}
            {saldo > 0 && (
                <>
                    <p>{pressToDeleteImmediately}</p>
                    <p>
                        <Localized
                            de={`Die Rückzahlung des Restsaldos von ${priceFormatted} erfolgt automatisch auf Ihr Bank-/Postkonto Anfangs nächsten Monat.`}
                            fr={`Le remboursement du solde résiduel du ${priceFormatted} sera effectué automatiquement sur votre compte bancaire/postale au début du mois prochain.`}
                            it={`Il rimborso del saldo rimanente di ${priceFormatted} avverrà automaticamente sul suo conto bancario/postale all'inizio del prossimo mese.`}
                            en={`The refund of the remaining balance of ${priceFormatted} will occur automatically on your bank/postal account at the beginning of the next month.`}
                        />
                    </p>
                </>
            )}
        </div>
    );
}

const pressToDeleteImmediately = (
    <Localized
        de='Beim Drücken auf "KÜNDIGEN" wird Ihr Konto mit sofortiger Wirkung gekündigt.'
        fr={
            'En appuyant sur "RÉSILIER" votre compte sera résilié immédiatement.'
        }
        it={
            'Premendo su "DISDIRE" il vostro conto verrà disdetto con effetto immediato.'
        }
        en={'Press "DELETE" to immediately cancel your account.'}
    />
);

function RemoteRequestError(props: { response: WriteStateSlice.State }) {
    const { storeState } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    }));

    if (!props.response.statusCode.cls.error) {
        return null;
    }

    const message = (() => {
        const t = MetaTexts.deleteAccountTexts[storeState.settings.language];

        switch (props.response.responseCode) {
            case WriteStateSlice.ResponseCode
                .APIERROR_CANCEL_ACCOUNT_NEGATIVE_ACCOUNT_BALANCE:
                return t.ErrorNegativeAccountBalance();

            case WriteStateSlice.ResponseCode
                .APIERROR_CANCEL_ACCOUNT_IBAN_MANDATORY:
                return t.ErrorIbanMissing();

            case WriteStateSlice.ResponseCode
                .APIERROR_CANCEL_ACCOUNT_VOUCHERCREDITS:
                return t.ErrorGotVoucherCredit();

            case WriteStateSlice.ResponseCode.CANCEL_ACCOUNT_WRONG_PW:
                return t.WrongPassword();

            default:
                return t.HttpError();
        }
    })();

    return <ErrorBlock text={message} />;
}

function getFormFields(s: {
    password: string | null;
    iban: string | null;
    bic: string | null;
}): FormFields {
    return {
        password: new Fields.Password(s.password || ''),
        iban: new Fields.Iban(s.iban || ''),
        bic: new Fields.Bic(s.bic || '', new Fields.Iban(s.iban || '').country),
    };
}

function formIsSaveable(
    accountBalance: AccountBalanceState.Balance,
    password: string | null,
    form: FormFields,
): boolean {
    const passwordNotEmpty = isDefined(password);

    if (accountBalance.balance > 0) {
        if (form.iban.isCHLI) {
            return passwordNotEmpty && Fields.noStatesAndNotEmpty(form.iban);
        } else {
            return (
                passwordNotEmpty &&
                Fields.noStatesAndNotEmpty(form.iban, form.bic)
            );
        }
    } else {
        return passwordNotEmpty;
    }
}

interface FormFields {
    password: Fields.Password;
    iban: Fields.Iban;
    bic: Fields.Bic;
}

function deleteAccount(data: Http.DeleteAccount): Flux.Write {
    return AsyncRequest.request(
        Http.deleteAccount(data),
        (store: Flux.Store, res: Response): void => {
            new ResponseStateSlice(store).writeResponse(res);
            if (res.statusCode.cls.success) {
                if (
                    res.body &&
                    res.body.amountPayedBack !== undefined &&
                    res.body.amountPayedBack !== null &&
                    +res.body.amountPayedBack > 0
                ) {
                    window.location.href = `/account-deleted/saldo`;
                } else {
                    window.location.href = `/account-deleted/nosaldo`;
                }
            }
        },
    );
}

namespace Http {
    export function deleteAccount(
        data: DeleteAccount,
    ): superagent.Request<any, void> {
        return superagent
            .post('/ui-api/customer-account/cancel-account')
            .send(data);
    }

    export interface DeleteAccount {
        username: string;
        password: string;
        iban: string;
        bic: string;
        clientGuiType: ClientGuiType;
    }

    export enum ClientGuiType {
        NATIVE = 'NATIVE',
        WEB = 'WEB',
    }
}

class ResponseStateSlice extends WriteStateSlice.StateSlice {
    key(): string {
        return 'account-meta-DeleteAccountStateResponse';
    }
}
