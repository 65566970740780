import { css } from '@emotion/css';
import { Language } from 'dg-web-shared/lib/Text';
import { TextField } from '../../../../../tb-ui/inputs/TextField';
import { Typo } from '../../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import * as Fields from '../../../utils/Fields';
import { portalSlideIn } from '../../root/components/PortalSlidein';
import { ibanErrorMessage } from './ibanErrorMessage';
import { PaymentMethodFormBlock } from '../PaymentMethodFormBlock';
import { paymentMethodTexts } from '../PaymentMethodTexts';
import { useState } from 'react';
import {
    hasPostfinanceClearingNumber,
    PostfinanceErrorModal,
} from './DirectDebitRequestForm';

function DirectDebitChangeAccountForm({
    onSubmit,
    onCancel,
    language,
    validBankClearingNumbers,
    currentIban,
}: Props) {
    const [iban, setIban] = useState<string>('');
    const [ibanEdited, setIbanEdited] = useState(false);
    const [showPostfinanceErrorModal, setShowPostfinanceErrorModal] =
        useState(false);
    const t = paymentMethodTexts[language];

    return (
        <PaymentMethodFormBlock
            onCancel={onCancel}
            onSubmit={() => {
                !hasPostfinanceClearingNumber(iban)
                    ? onSubmit({
                          iban: new Fields.Iban(
                              iban,
                          ).valueNoSpace.toUpperCase(),
                      })
                    : setShowPostfinanceErrorModal(true);
            }}
            submitEnabled={
                ibanErrorMessage(
                    language,
                    validBankClearingNumbers,
                    currentIban,
                    iban,
                ) === ''
            }
        >
            <div
                className={css({
                    fontFamily: Typo.robotoRegular.fontFamily,
                    fontWeight: Typo.robotoRegular.fontWeight,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: Colors.action_f,
                })}
            >
                <p>{t.directDebitChangeAccountP1()}</p>
                <p>{t.directDebitChangeAccountP2()}</p>
                <p>{t.directDebitChangeAccountP3()}</p>
            </div>

            <TextField
                onChange={iban => setIban(iban)}
                onBlur={() => {
                    setIban(iban.toUpperCase().trim());
                    setIbanEdited(true);
                }}
                value={iban}
                errorText={
                    ibanEdited
                        ? ibanErrorMessage(
                              language,
                              validBankClearingNumbers,
                              currentIban,
                              iban,
                          )
                        : ''
                }
                labelText={t.newIban()}
            />
            {showPostfinanceErrorModal && (
                <PostfinanceErrorModal
                    onClose={() => setShowPostfinanceErrorModal(false)}
                />
            )}
        </PaymentMethodFormBlock>
    );
}

function DirectDebitChangeAccountFormWrapper(props: Props) {
    return <DirectDebitChangeAccountForm {...props} />;
}

export const DirectDebitChangeAccountFormSlideIn = portalSlideIn<Props>(
    DirectDebitChangeAccountFormWrapper,
);

interface Props {
    language: Language;
    onCancel: () => void;
    validBankClearingNumbers: Set<number>;
    currentIban: string;
    onSubmit: (values: { iban: string }) => void;
}
