import * as ReactDOM from 'react-dom';
import { InputContext } from '../InputContext';
import { css } from '@emotion/css';
import { Typo } from '../../typo';
import { Colors } from 'dg-web-shared/ui/vars';
import React from 'react';

interface Props {
    value: string;
    inputType: string;
    context: InputContext;
    onFocus: Function;
    onBlur: Function;
    onChange: Function;
    maxChars: number;
    autoCapitalize: string | undefined;
    autoCorrect: string | undefined;
    name?: string;
    autocomplete?: string;
    onKeyDown?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    ref?: React.Ref<Input>;
    disabled?: boolean;
    tabIndex?: number;
    style?: any;
    useAutoCorrectInput?: boolean;
    autoFocus?: boolean;
    required?: boolean;
}

export class Input extends React.Component<Props> {
    _onChange(e: any): void {
        const val = e.target.value;
        if (val.length <= this.props.maxChars) {
            this.props.onChange(val);
        }
    }

    _onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    focus(): void {
        (<any>ReactDOM.findDOMNode(this)).focus();
    }

    blur(): void {
        (<any>ReactDOM.findDOMNode(this)).blur();
    }

    UNSAFE_componentWillUpdate(nextProps: Props): void {
        if (this.props.useAutoCorrectInput) {
            const node: any = ReactDOM.findDOMNode(this);
            const oldLength = node.value.length; // this is the value of the field after the manual input
            const oldIdx = node.selectionStart;

            // return if there is no change, otherwise this will steal the focus (sort of) of other input fields
            // nextProps.value is the value we will ultimately display. It is the value
            // after the manual input *and* with automatic modifications made to that input (if any)
            if (
                node.value === nextProps.value ||
                nextProps.value === undefined ||
                nextProps.value === null
            ) {
                return;
            }

            node.value = nextProps.value;
            const newIdx = Math.max(0, node.value.length - oldLength + oldIdx);
            node.selectionStart = node.selectionEnd = newIdx;
        }
    }

    render() {
        const context = this.props.context;
        return React.createElement('input', {
            className: css([
                {
                    ...Typo.body,
                    userSelect: 'text',
                    margin: 0,
                    padding: 0,
                    border: 'none',
                    outline: 'none',
                    display: 'block',
                    verticalAlign: 'baseline',
                    whiteSpace: 'normal',
                    background: 'none',
                    width: '100%',
                    lineHeight: 1,
                    height: '20px',
                    '&:focus': { outline: 0 },
                    '&::-ms-clear': {
                        display: 'none',
                    },
                },
                context === InputContext.regular && {
                    color: Colors.action_w,
                    WebkitTextFillColor: Colors.action_w,
                },
                context === InputContext.form && {
                    color: Colors.action_f,
                    WebkitTextFillColor: Colors.action_f,
                },
                context === InputContext.inverted && {
                    color: Colors.action_b,
                    WebkitTextFillColor: Colors.action_b,
                },
            ]),
            autoCorrect: this.props.autoCorrect || 'off',
            autoCapitalize: this.props.autoCapitalize,
            name: this.props.name,
            autoComplete: this.props.autocomplete,
            autoFocus: this.props.autoFocus,
            value: this.props.value,
            type: this.props.inputType,
            onFocus: (): void => this.props.onFocus(),
            onBlur: (): void => this.props.onBlur(),
            onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
                this._onChange(e),
            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>): void =>
                this._onKeyDown(e),
            disabled: this.props.disabled,
            required: this.props.required,
            tabIndex:
                this.props.tabIndex !== undefined ? this.props.tabIndex : -1,
            style: this.props.style,
            maxLength: this.props.maxChars,
        } as any);
    }
}

export var Factory = React.createFactory<Props>(Input);
