import { ibanToDisplayFormat } from 'dg-web-shared/lib/StringConversions';
import { Language } from 'dg-web-shared/lib/Text';

type URI = string;

export function DirectDebitAccountChangeJustRequested(props: {
    language: Language;
    iban: string;
    authorizationDocumentPdfUri: URI;
}): JSX.Element {
    switch (props.language) {
        case Language.DE:
            return (
                <>
                    <p>
                        Wir haben Ihren Antrag zur Aktivierung des Kontos{' '}
                        {ibanToDisplayFormat(props.iban)} erhalten und wir haben
                        Ihnen{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            den Link zum Download
                        </a>{' '}
                        des PDF-Dokuments per E-Mail zugesandt. Die
                        Zahlungsermächtigung muss ausgedruckt, unterschrieben
                        und per Post an die angegebene Adresse gesendet werden.
                    </p>

                    <p>
                        Da dieser Prozess mehrstufige Prüfungen erfordert, kann
                        es 7 bis 10 Arbeitstage dauern. In der Zwischenzeit
                        bleibt das bisherige Bank-/Post-Konto aktiv.
                    </p>
                </>
            );

        case Language.FR:
            return (
                <>
                    <p>
                        Nous avons reçu votre demande pour l'activation du
                        compte {ibanToDisplayFormat(props.iban)} et nous vous
                        avons envoyé par e-mail{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            le lien pour télécharger
                        </a>{' '}
                        le document PDF. L'autorisation de paiement doit être
                        imprimée, signée et envoyée par courrier à l'adresse
                        indiquée.
                    </p>

                    <p>
                        Ce processus nécessitant des contrôles sur plusieurs
                        niveaux, il peut prendre entre 7 et 10 jours ouvrables.
                        En attendant, le compte bancaire/postal actuelle reste
                        actif.
                    </p>
                </>
            );

        case Language.IT:
            return (
                <>
                    <p>
                        Abbiamo ricevuto la vostra richiesta per l'attivazione
                        del conto {ibanToDisplayFormat(props.iban)} e vi abbiamo
                        inviato per e-mail{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            il link per scaricare
                        </a>{' '}
                        il documento PDF. L'autorizzazione di pagamento va
                        stampata, firmata e inviata per posta all'indirizzo
                        indicato.
                    </p>

                    <p>
                        Siccome questo processo richiede delle verifiche a più
                        livelli, possono essere necessari da 7 a 10 giorni
                        lavorativi. Nel frattempo il conto bancario/postale
                        attuale rimane attivo.
                    </p>
                </>
            );
        case Language.EN:
            return (
                <>
                    <p>
                        We have received your request for the activation of the
                        account {ibanToDisplayFormat(props.iban)} and we sent
                        you by e-mail{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            the link to download
                        </a>{' '}
                        the PDF document. The payment authorization must be
                        printed, signed and sent by post to the address
                        indicated.
                    </p>
                    <p>
                        As this process requires more than one verification, may
                        take from 7 to 10 working days. In the meantime, the
                        current bank/post account remains active.
                    </p>
                </>
            );
    }
}

export function DirectDebitJustRequested(props: {
    language: Language;
    iban: string;
    authorizationDocumentPdfUri: URI;
}): JSX.Element {
    switch (props.language) {
        case Language.DE:
            return (
                <>
                    <p>
                        Wir haben Ihren Antrag zur Aktivierung der Lastschrift
                        beim Konto {ibanToDisplayFormat(props.iban)} erhalten
                        und wir haben Ihnen{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            den Link zum Download
                        </a>{' '}
                        des PDF-Dokuments per E-Mail zugesandt. Die
                        Zahlungsermächtigung muss ausgedruckt, unterschrieben
                        und per Post an die angegebene Adresse gesendet werden.
                    </p>
                    <p>
                        Da dieser Prozess mehrstufige Prüfungen erfordert, kann
                        es 7 bis 10 Arbeitstage dauern. In der Zwischenzeit
                        bleibt die Zahlungsweise Vorauszahlung aktiv.
                    </p>
                    <p>
                        Bei der Aktivierung der Lastschrift erhalten Sie eine
                        Bestätigung per E-Mail.
                    </p>
                </>
            );

        case Language.FR:
            return (
                <>
                    <p>
                        Nous avons reçu votre demande d'activation pour
                        prélèvement automatique sur le compte{' '}
                        {ibanToDisplayFormat(props.iban)} et nous vous avons
                        envoyé par e-mail{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            le lien pour télécharger
                        </a>{' '}
                        le document PDF. L'autorisation de paiement doit être
                        imprimée, signée et envoyée par courrier à l'adresse
                        indiquée.
                    </p>
                    <p>
                        Ce processus nécessitant des contrôles sur plusieurs
                        niveaux, il peut prendre entre 7 et 10 jours ouvrables.
                        En attendant, le mode prépaiement reste actif.
                    </p>
                    <p>
                        Dès l'activation du prélèvement automatique, vous
                        recevrez une confirmation par e-mail.
                    </p>
                </>
            );

        case Language.IT:
            return (
                <>
                    <p>
                        Abbiamo ricevuto la vostra richiesta per l'attivazione
                        dell'addebito diretto sul conto{' '}
                        {ibanToDisplayFormat(props.iban)} e vi abbiamo inviato
                        per e-mail{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            il link per scaricare
                        </a>{' '}
                        il documento PDF. L'autorizzazione di pagamento va
                        stampata, firmata e inviata per posta all'indirizzo
                        indicato.
                    </p>
                    <p>
                        Siccome questo processo richiede delle verifiche a più
                        livelli, possono essere necessari da 7 a 10 giorni
                        lavorativi. Nel frattempo rimane attiva la modalità
                        prepagata.
                    </p>
                    <p>
                        Al momento dell'attivazione dell'addebito diretto
                        riceverete una conferma per e-mail.
                    </p>
                </>
            );

        case Language.EN:
            return (
                <>
                    <p>
                        We have received your request for the activation of
                        direct debit on the account{' '}
                        {ibanToDisplayFormat(props.iban)} and we sent you by
                        e-mail{' '}
                        <a
                            target="_blank"
                            href={props.authorizationDocumentPdfUri}
                            rel="noreferrer"
                        >
                            the link to download
                        </a>{' '}
                        the PDF document. The payment authorization must be
                        printed, signed and sent by post to the address
                        indicated.
                    </p>
                    <p>
                        As this process requires more than one verification, may
                        take from 7 to 10 working days. In the meantime, the
                        prepaid mode remains active.
                    </p>
                    <p>
                        At the time of activation of the direct debit you will
                        receive a confirmation by e-mail.
                    </p>
                </>
            );
    }
}

export function DirectDebitSubmittedEarlier(props: {
    language: Language;
    authorizationDocumentPdfUri: URI;
}): JSX.Element {
    switch (props.language) {
        case Language.DE:
            return (
                <p>
                    Falls nicht, hier können Sie das PDF-Dokument nochmals
                    herunterladen:{' '}
                    <a
                        target="_blank"
                        href={props.authorizationDocumentPdfUri}
                        rel="noreferrer"
                    >
                        Zahlungsermächtigung
                    </a>
                    .
                </p>
            );

        case Language.FR:
            return (
                <p>
                    Si non, vous pouvez télécharger le document PDF à nouveau
                    ici:{' '}
                    <a
                        target="_blank"
                        href={props.authorizationDocumentPdfUri}
                        rel="noreferrer"
                    >
                        autorisation de paiement
                    </a>
                    .
                </p>
            );

        case Language.IT:
            return (
                <p>
                    Se no, qui potete scaricare di nuovo il documento PDF:{' '}
                    <a
                        target="_blank"
                        href={props.authorizationDocumentPdfUri}
                        rel="noreferrer"
                    >
                        autorizzazione di pagamento
                    </a>
                    .
                </p>
            );

        case Language.EN:
            return (
                <p>
                    If not, you can download the PDF document here again:{' '}
                    <a
                        target="_blank"
                        href={props.authorizationDocumentPdfUri}
                        rel="noreferrer"
                    >
                        payment authorization
                    </a>
                    .
                </p>
            );
    }
}
