import {
    getSuperagentMethod,
    RequestConfig,
    RequestMethod,
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    makeTimeoutResponse,
    SuperagentResponse,
} from 'dg-web-shared/lib/HttpResponse';
import superagent from 'superagent';
import { useEffect, useState } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { AuthStatusType, useAuthState } from '../app/state/AuthState';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';

import { setAppToken } from '../Native';

export type ServerFetchState<D, ED = D> = [
    ServerRequestState<D, ED>,
    () => void,
];
type OldFetchConfig<C extends { [key: string]: any }, D, ED = D> = {
    request: (context: C) => superagent.Request<any, any>;
    onResponse?: (state: ServerRequestState<D, ED>) => void;
};
export type FetchInfo<C> = (context: C) => RequestConfig;

/**
 * @deprecated replacedWith useServerFetch with requestInfo
 */
export function useServerFetch<D, C extends { [key: string]: any }, ED = D>(
    config: OldFetchConfig<C, D, ED>,
    context: C | null,
): ServerFetchState<D, ED>;

/**
 * Fetches and updates the server data on every context change.
 * If context is `null` no call is made.
 * If a request is running it will be canceled.
 * All requests are JSON, the default method is GET.
 *
 * @param requestInfoFactory This function gets the context so you can build your RequestConfig accordingly.
 * @param context If `null` no call is made. Otherwise a new request is made if any context data changes.
 * @returns [state, refetchFunction]
 * @example A simple component that fetches a record
 *
 * function SomeComponent({id}: {id: number}) {
 *     const [state] = useServerFetch<{name: string}, {id: number}>(
 *         context => ({url: `/record/${context.id}`}),
 *         {id}
 *     );
 *
 *     if (state.status === RequestStatus.PENDING || state.status === RequestStatus.NEVER_EXECUTED) {
 *         return <div>Spinner</div>
 *     } else if (state.status === RequestStatus.ERROR) {
 *         return <div>Something went wrong</div>
 *     } else {
 *         return <div>Hello {state.data.name}</div>
 *     }
 * }
 */
export function useServerFetch<Data, Context extends object, ErrorData = Data>(
    requestInfoFactory: FetchInfo<Context>,
    context: Context | null,
): ServerFetchState<Data, ErrorData>;

export function useServerFetch<D, C extends { [key: string]: any }, ED = D>(
    config: OldFetchConfig<C, D, ED> | FetchInfo<C>,
    context: C | null,
): ServerFetchState<D, ED> {
    if (typeof config === 'function') {
        return oldServerFetch(
            {
                request(c) {
                    const conf = config(c);
                    const agent = getSuperagentMethod(
                        conf.method ?? RequestMethod.GET,
                    )(conf.url);
                    const headers = conf.headers;
                    if (headers) {
                        Object.keys(headers).forEach(name =>
                            agent.set(name, headers[name]),
                        );
                    }
                    if (
                        conf.body !== undefined &&
                        conf.method !== RequestMethod.GET
                    ) {
                        agent.send(conf.body);
                    }
                    return agent;
                },
            },
            context,
        );
    } else {
        return oldServerFetch(config, context);
    }
}

function oldServerFetch<D, C extends { [key: string]: any }, ED = D>(
    config: OldFetchConfig<C, D, ED>,
    context: C | null,
): ServerFetchState<D, ED> {
    type State = ServerRequestState<D, ED>;

    const [request, setRequest] = useState<superagent.Request<any, any> | null>(
        null,
    );

    const [forceFetchCount, setForceFetchCount] = useState(0);

    const [state, setState] = useState<State>({
        data: null,
        status: RequestStatus.NEVER_EXECUTED,
    });

    function refetchSameContext() {
        setForceFetchCount(prev => prev + 1);
    }
    useEffect(
        makeContextReadFunction(
            config.request,
            request,
            context,
            setState,
            setRequest,
            config.onResponse,
        ),
        [
            JSON.stringify(context), // yes we actually do this https://github.com/facebook/react/issues/14476#issuecomment-471199055
            forceFetchCount,
        ],
    );

    return [state, refetchSameContext];
}

function useSafeNavigate(): (to: To, options?: NavigateOptions) => void {
    try {
        return useNavigate();
    } catch (e) {
        return () => null;
    }
}

function makeContextReadFunction<D, C extends { [key: string]: any }, ED = D>(
    configRequest: (context: C) => superagent.Request<any, any>,
    oldRequest: superagent.Request<any, any> | null,
    context: C | null,
    setState: React.Dispatch<React.SetStateAction<ServerRequestState<D, ED>>>,
    setRequest: React.Dispatch<
        React.SetStateAction<superagent.Request<any, any> | null>
    >,
    onResponse?: (state: ServerRequestState<D, ED>) => void,
) {
    const { authStatus, setAuthStatus } = useAuthState();
    const navigate = useSafeNavigate();
    return () => {
        if (!context) {
            setState({ data: null, status: RequestStatus.NEVER_EXECUTED });
            if (oldRequest) {
                oldRequest.abort();
            }
        }
        if (context) {
            const newRequest = configRequest(context);
            if (oldRequest) {
                oldRequest.abort();
            }
            setRequest(newRequest);
            setState(prev => ({
                data: prev.status !== RequestStatus.ERROR ? prev.data : null,
                status: RequestStatus.PENDING,
            }));
            newRequest.end(
                (err: any, superagentRes: superagent.Response<any>) => {
                    setRequest(null);
                    const res =
                        err && !superagentRes
                            ? makeTimeoutResponse(newRequest)
                            : new SuperagentResponse(newRequest, superagentRes);
                    if (res.statusCode.cls.success) {
                        const newState: ServerRequestState<D, ED> = {
                            data: res.body || null,
                            status: RequestStatus.SUCCESS,
                        };
                        setState(newState);
                        onResponse && onResponse(newState);
                    } else if (res.statusCode.cls.error) {
                        if (res.statusCode.timeout) {
                            navigate('/offline');
                        } else if (
                            (res.statusCode.unauthorized ||
                                res.statusCode.forbidden) &&
                            authStatus === AuthStatusType.OK
                        ) {
                            LocalStorage.clear();
                            setAppToken(null);
                            setAuthStatus(AuthStatusType.NOT_OK);
                            navigate('/unauthorized');
                        }

                        const newState = {
                            data: res.body,
                            status: RequestStatus.ERROR,
                            httpStatusCode: res.statusCode.statusCode || 0,
                        };
                        setState(newState);
                        onResponse && onResponse(newState);
                    }
                },
            );
        }
    };
}
