import * as Flux from 'dg-web-shared/lib/Flux';
import * as LayoutState from '../state/LayoutState';
import { responsiveVariant } from '../utils/responsiveVariant';
import { css } from '@emotion/css';

interface ColumnSliderProps {
    children?: React.ReactNode;
    allState: Flux.Store;
}
interface ColumnSliderState {
    layout: LayoutState.State;
}

export class ColumnSlider extends Flux.ContainerWithProps<
    ColumnSliderProps,
    ColumnSliderState
> {
    static displayName = 'ColumnSlider';

    stateSelector(): ColumnSliderState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        return (
            <div
                className={css([
                    {
                        height: '100%',
                    },
                    responsiveVariant(this.state) === 'Mobile' && {
                        position: 'absolute',
                        width: '100%',
                    },
                ])}
            >
                {this.props.children}
            </div>
        );
    }
}

interface ColumnSliderColumnProps {
    children?: React.ReactNode;
    index: number;
    allState: Flux.Store;
}

interface ColumnSliderColumnState {
    layout: LayoutState.State;
}

export class ColumnSliderColumn extends Flux.ContainerWithProps<
    ColumnSliderColumnProps,
    ColumnSliderColumnState
> {
    static displayName = 'ColumnSliderColumn';

    stateSelector(): ColumnSliderState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        return (
            <div
                className={css({
                    height: '100%',
                    float: 'left',
                })}
                data-index={this.props.index}
                data-responsive-variant={responsiveVariant(this.state)}
            >
                {this.props.children}
            </div>
        );
    }
}
