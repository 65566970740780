import { InputContext } from '../../../../../tb-ui/inputs/InputContext';
import { LabeledText } from '../../../../../tb-ui/typo/LabeledText';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import * as TransactionState from '../../state/zone-transaction/TransactionState';
import {
    ArrowPosition,
    ButtonText,
    InlineAlertBox,
    InlineInfoBox,
    ModalInfoBox,
} from '../../../ui/modals/Confirmable';
import { useStore, useUpdate } from 'dg-web-shared/lib/Flux';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import { PaymentMethodEnum } from '../../../api/Http';
import { Localized } from '../../../common/components/Localized';
import { Vehicles } from '../../../account/vehicles/VehicleState';
import { GenericErrorBody } from '../../../ui/modals/HandleHttpStati';
import { ErrorBlock } from '../../../account/root/components/ErrorBlock';
import { badgeHand, lpr } from 'dg-web-shared/ui/icons/Icons24';
import { css } from '@emotion/css';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter';
import { useVehicleListOpen } from '../../../hooks/OpenVehicleList';
import { mobileNavigateAccount } from '../../../layout/actions/LayoutActions';
import { ResponsiveProperties } from '../../../layout/utils/ResponsiveProperties';
import * as LayoutState from '../../../layout/state/LayoutState';
import { TicketIcon } from 'dg-web-shared/ui/icons/IconsScalable';
import { BarrierGateLicensePlateCheckInOption } from 'dg-web-shared/common/models/Vehicle';
import { SpinnerModal } from '../../../../../tb-ui/spinner/Spinner';
import { useState } from 'react';

interface OffstreetTransactionProps {
    zoneId: number;
}

export function OffstreetTransaction(props: OffstreetTransactionProps) {
    const { storeState } = useStore(s => ({
        offstreetInfo: TransactionState.OffstreetZone.get(s, {
            zoneId: props.zoneId,
        }),
        balance: new AccountBalanceState.StateSlice(s).state,
        paymentType: new PaymentTypeState.StateSlice(s).state,
        vehicles: Vehicles.get(s),
    }));

    if (storeState.balance.pending || storeState.offstreetInfo.pending) {
        return <SpinnerModal visible portal={SlideInPortalId.PARK_CREATE} />;
    }

    if (storeState.offstreetInfo.data && storeState.balance.data) {
        return (
            <>
                <LabeledText
                    label={{
                        de: 'Betreiber',
                        fr: 'Exploitant',
                        it: 'Gestore',
                        en: 'Operator',
                    }}
                    context={InputContext.inverted}
                >
                    {storeState.offstreetInfo.data.operatorName}
                </LabeledText>
                {storeState.paymentType.data.activePaymentMethod
                    .activePaymentType === PaymentMethodEnum.PREPAID &&
                    storeState.offstreetInfo.data.entryLimit &&
                    storeState.offstreetInfo.data.entryLimit > 0 && (
                        <LabeledText
                            label={{
                                de: 'Mindestsaldo',
                                fr: 'Solde minime',
                                it: 'Saldo minimo',
                                en: 'Minimal balance',
                            }}
                            context={InputContext.inverted}
                        >
                            {numberToPrice(
                                storeState.offstreetInfo.data.entryLimit,
                            )}
                        </LabeledText>
                    )}

                <LabeledText
                    label={{
                        de: 'Zahlungsoptionen',
                        fr: 'Options de paiement',
                        it: 'Opzioni di pagamento',
                        en: 'Payment options',
                    }}
                    context={InputContext.inverted}
                >
                    {storeState.offstreetInfo.data.allowTicketPayment && (
                        <div
                            className={css({
                                display: 'flex',
                                marginTop: '10px',
                            })}
                        >
                            <div
                                className={css({
                                    width: '36px',
                                    height: '24px',
                                    marginRight: '10px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                })}
                            >
                                <TicketIcon />
                            </div>
                            <div
                                className={css({
                                    lineHeight: '24px',
                                })}
                            >
                                <Localized
                                    de="Ticket"
                                    fr="Ticket"
                                    it="Ticket"
                                    en="Ticket"
                                />
                            </div>
                        </div>
                    )}
                    {storeState.offstreetInfo.data.publicLpr && (
                        <div
                            className={css({
                                marginBottom: '8px',
                            })}
                        >
                            <IconLabelContainer
                                icon={lpr}
                                label={
                                    <Localized
                                        de="Kennzeichenerkennung"
                                        fr="Reconnaissance de plaque"
                                        it="Riconoscimento targa"
                                        en="License plate recognition"
                                    />
                                }
                            />
                        </div>
                    )}
                    {storeState.offstreetInfo.data.publicBadge && (
                        <IconLabelContainer
                            icon={badgeHand}
                            label={
                                <Localized
                                    de="Badge"
                                    fr="Badge"
                                    it="Badge"
                                    en="Badge"
                                />
                            }
                        />
                    )}
                </LabeledText>
                {!storeState.offstreetInfo.data.allowTicketPayment && (
                    <IdentificationInfoBox
                        publicLpr={storeState.offstreetInfo.data.publicLpr}
                        vehicles={storeState.vehicles}
                    />
                )}
                {storeState.offstreetInfo.data.customerCommission && (
                    <CustomerCommissionInfo />
                )}
            </>
        );
    } else {
        return <ErrorBlock text={<GenericErrorBody />} />;
    }
}

interface IconLabelContainerProps {
    icon: React.ReactNode;
    label: React.ReactNode;
}

export function IconLabelContainer(p: IconLabelContainerProps) {
    return (
        <div
            className={css({
                display: 'flex',
                marginTop: '10px',
            })}
        >
            <div
                className={css({
                    width: '36px',
                    height: '24px',
                    marginRight: '10px',
                })}
            >
                <SvgIcon icon={p.icon} width={36} height={24} />
            </div>
            <div
                className={css({
                    lineHeight: '24px',
                })}
            >
                {p.label}
            </div>
        </div>
    );
}

function CustomerCommissionInfo() {
    const infoButtonIconBits = require('../../../../assets/info_button_darkblue.svg');
    const [showSurchargeInfo, setShowSurchargeInfo] = useState(false);

    return (
        <LabeledText
            label={{
                de: 'Zuschlag (App-Nutzung)',
                fr: 'Supplément (usage app)',
                it: 'Supplemento (utilizzo app)',
                en: 'Surcharge (app usage)',
            }}
            context={InputContext.inverted}
        >
            {`4.85%`}
            <a
                className={css({
                    marginLeft: '8px',
                    cursor: 'pointer',
                })}
            >
                <img
                    src={infoButtonIconBits}
                    onClick={() => setShowSurchargeInfo(true)}
                />
            </a>
            {showSurchargeInfo && (
                <ModalInfoBox
                    confirmCaption={ButtonText.CLOSE}
                    confirmCallback={() => setShowSurchargeInfo(false)}
                    titleCaption={
                        <Localized
                            de="Zuschlag"
                            fr="Supplément"
                            it="Supplemento"
                            en="Surcharge"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="In diesem Parking wird bei Zahlungen über Parkingpay ein Zuschlag von 4,85 % auf die anfallenden Parkgebühren erhoben."
                            fr="Dans ce parking, pour les paiements effectués via Parkingpay, un supplément de 4,85 % est appliqué au montant des frais de stationnement dus."
                            it="In questo parcheggio, per i pagamenti effettuati tramite Parkingpay, all’importo della tassa di parcheggio dovuta viene applicato un supplemento del 4.85 %."
                            en="In this parking lot, for payments made via Parkingpay, a surcharge of 4.85 % is applied to the amount of parking fee due."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wenn Sie Fragen zu diesem Zuschlag haben, wenden Sie sich bitte direkt an den Betreiber."
                            fr="Si vous avez des questions concernant ce supplément, veuillez contacter directement l’exploitant."
                            it="Per domande riguardo a questo supplemento, voglia rivolgersi direttamente al gestore."
                            en="If you have any questions regarding this surcharge, please contact the operator directly."
                        />
                    </p>
                </ModalInfoBox>
            )}
        </LabeledText>
    );
}

function IdentificationInfoBox({
    publicLpr,
    vehicles,
}: {
    publicLpr: boolean;
    vehicles: Vehicles.State;
}) {
    if (
        publicLpr &&
        vehicles.data.every(
            vehicle =>
                vehicle.barrierGateLicensePlateCheckInOption !==
                BarrierGateLicensePlateCheckInOption.ACTIVE,
        ) &&
        vehicles.data.length
    ) {
        return (
            <InlineInfoBox
                titleCaption={
                    <Localized
                        de="Kennzeichenerkennung nicht aktiv"
                        fr="Reconnaissance de plaque pas active"
                        it="Riconoscimento targa non attivo"
                        en="License plate recognition not active"
                    />
                }
                arrowPosition={ArrowPosition.left}
            >
                <p>
                    <Localized
                        de={
                            <>
                                Um von dieser komfortablen Funktion zu
                                profitieren, müssen Sie sie zuerst in der
                                <VehicleListOpenLink />
                                Ihres Kontos aktivieren.
                            </>
                        }
                        fr={
                            <>
                                Pour bénéficier de cette fonction pratique, vous
                                devez d&apos;abord l&apos;activer dans la
                                <VehicleListOpenLink />
                                de vostre compte.
                            </>
                        }
                        it={
                            <>
                                Per poter beneficiare di questa comoda funziona,
                                deve prima attivarla nella
                                <VehicleListOpenLink />
                                del vostro conto.
                            </>
                        }
                        en={
                            <>
                                To benefit from this convenient feature, you
                                must first activate it in the
                                <VehicleListOpenLink />
                                of your account.
                            </>
                        }
                    />
                </p>
            </InlineInfoBox>
        );
    }

    if (!publicLpr && vehicles.data.every(vehicle => !vehicle.badgeId)) {
        return (
            <InlineAlertBox
                titleCaption={
                    <Localized
                        de="Kein Badge vorhanden"
                        fr="Aucune bages disponible"
                        it="Nessun badge disponibile"
                        en="No badges available"
                    />
                }
                arrowPosition={ArrowPosition.left}
            >
                <p>
                    <Localized
                        de={
                            <>
                                Um bei diesem Parking ein- und auszufahren,
                                müssen Sie mindestens ein Badge
                                erfassen/bestellen. Badges können in der
                                <VehicleListOpenLink />
                                Ihres Kontos verwaltet werden.
                            </>
                        }
                        fr={
                            <>
                                Pour pouvoir entrer et sortir du parking, vous
                                devez saisir/commander au moins un badge. Les
                                badges peuvent être gérés dans la
                                <VehicleListOpenLink />
                                de vostre compte.
                            </>
                        }
                        it={
                            <>
                                Per poter entrare e uscire da questo parcheggio,
                                deve registrare/ordinare almeno un badge. I
                                badges possono essere gestiti nella
                                <VehicleListOpenLink />
                                del vostro conto.
                            </>
                        }
                        en={
                            <>
                                In order to enter and exit this parking, you
                                must register/order at least one badge. Badges
                                can be managed in the
                                <VehicleListOpenLink />
                                of your account.
                            </>
                        }
                    />
                </p>
            </InlineAlertBox>
        );
    }
    return null;
}

function VehicleListOpenLink() {
    const { storeState } = useStore(s => ({
        isMobile: new ResponsiveProperties({
            layout: new LayoutState.StateSlice(s).state,
        }).mobile,
    }));
    const { setListOpen } = useVehicleListOpen();
    const update = useUpdate();
    return (
        <>
            {' '}
            <a
                href=""
                onClick={e => {
                    e.preventDefault();
                    if (storeState.isMobile) {
                        update(mobileNavigateAccount);
                    }
                    setListOpen(true);
                }}
            >
                <Localized
                    de="Fahrzeugkonfiguration"
                    fr="configuration du véhicule"
                    it="configurazione del veicolo"
                    en="vehicle configuration"
                />
            </a>{' '}
        </>
    );
}
