import {
    TicketContent,
    TicketContentText,
    TicketSubtitle,
} from '../../common/components/ticketplus/TicketPlusLayout';
import {
    ticketNotFoundModalMessage,
    ticketNotFoundModalTitle,
} from '../../common/components/ticketplus/TicketPlusModalTexts';

export const QuickCheckoutFlowTicketNotFound = () => (
    <TicketContent>
        <TicketSubtitle>{ticketNotFoundModalTitle}</TicketSubtitle>
        <TicketContentText>{ticketNotFoundModalMessage}</TicketContentText>
    </TicketContent>
);
