import { Outlet, useOutletContext } from 'react-router-dom';

type TicketOutletContext = {
    refetchTicketState: () => void;
};

export function useTicketOutletContext() {
    return useOutletContext<TicketOutletContext>();
}

export function TicketOutlet({ context }: { context: TicketOutletContext }) {
    return <Outlet context={context} />;
}
