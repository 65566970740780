export enum TwoColumnsVariantOption {
    OneColumn,
    TwoColumns,
}

export class TwoColumnsVariant {
    private _v: TwoColumnsVariantOption;
    constructor(v: TwoColumnsVariantOption) {
        this._v = v;
    }
    get oneColumn(): boolean {
        return this._v === TwoColumnsVariantOption.OneColumn;
    }
    get twoColumns(): boolean {
        return this._v === TwoColumnsVariantOption.TwoColumns;
    }
    toString(): string {
        return TwoColumnsVariantOption[this._v];
    }
}
