import { useEffect } from 'react';
import * as Flux from 'dg-web-shared/lib/Flux';
import { useStore } from 'dg-web-shared/lib/Flux';
import { BasicButton } from '../../../../../tb-ui/buttons/BasicButton';
import { ButtonRow } from '../../../../../tb-ui/buttons/ButtonRow';
import * as Text from '../../../common/i18n/Text';
import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { Helpdesk } from './Helpdesk';
import { Address } from './Address';
import { HasEverLoggedInState } from '../../home-unauthenticated/HasEverLoggedInState';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from '../../../../../tb-ui/typo';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { DEVICE_UUID } from '../../../utils/ActionLog';
import { Localized } from '../../../common/components/Localized';
import * as GeneralTexts from '../../../common/i18n/GeneralTexts';

export interface Texts {
    Login: Text.Translation;
    AlreadyActive: Text.Translation;
    Success: Text.Translation;
    Failure: Text.Translation;
}

export function ActivateAccountPage() {
    const { store, update } = useStore(() => null);
    const navigate = useNavigate();
    const { token } = useParams();
    const [activate, sendActivate] = useServerWrite<
        { token?: string; appVersion: string; uuid: string },
        null,
        { code: -212 }
    >(() => ({
        url: '/ui-api/activate-account',
    }));

    useEffect(
        () =>
            sendActivate({ token, appVersion: PCS_VERSION, uuid: DEVICE_UUID }),
        [token, PCS_VERSION, DEVICE_UUID],
    );

    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <div
                    className={css({
                        maxWidth: '480px',
                        color: Colors.typo1,
                        ...Typo.body,
                    })}
                >
                    {activate.status === RequestStatus.SUCCESS ? (
                        <Localized
                            de={
                                'Das Konto wurde erfolgreich aktiviert. Bitte loggen Sie sich ein.'
                            }
                            fr={
                                'Le compte a été activé avec succès. Merci de vous connecter.'
                            }
                            it={
                                "Il conto è stato attivato con successo. Vogliate effettuare l'accesso."
                            }
                            en={
                                'The account has been activated successfully. Please log in.'
                            }
                        />
                    ) : activate.status === RequestStatus.ERROR ? (
                        activate.data?.code === -212 ? (
                            <Localized
                                de={'Dieses Konto wurde schon aktiviert.'}
                                fr={'Ce compte est déjà activé.'}
                                it={'Questo conto è già attivo'}
                                en={'This account has already been activated'}
                            />
                        ) : (
                            <Localized
                                de={GeneralTexts.texts.de.ErrorTryAgainOrHelpdesk()}
                                fr={GeneralTexts.texts.fr.ErrorTryAgainOrHelpdesk()}
                                it={GeneralTexts.texts.it.ErrorTryAgainOrHelpdesk()}
                                en={GeneralTexts.texts.en.ErrorTryAgainOrHelpdesk()}
                            />
                        )
                    ) : (
                        ''
                    )}
                    <ButtonRow>
                        <BasicButton
                            label={
                                <Localized
                                    de={'Einloggen'}
                                    fr={'Se connecter'}
                                    it={'Accedi'}
                                    en={'Sign in'}
                                />
                            }
                            negative={true}
                            disabled={
                                activate.status === RequestStatus.PENDING ||
                                activate.status === RequestStatus.NEVER_EXECUTED
                            }
                            onClick={() =>
                                update((store: Flux.Store) => {
                                    HasEverLoggedInState.stateWrite(store, {
                                        hasEver: true,
                                    });
                                    navigate('/');
                                    return 'gotoHomeFromConfirmedEmail';
                                })
                            }
                        />
                    </ButtonRow>
                </div>
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}
