import {
    JsxMessage,
    Language,
    languageFromString,
    Message,
} from '../../lib/Localized';
import { LocalStorageBase } from '../../lib/LocalStorageBase';
import { createContext, useContext, useState } from 'react';

const PARKINGABO_LANG_KEY = 'parkingabo_language';

function validOrNullify(language: Language | null) {
    if (['en', 'fr', 'it', 'de'].includes(language || '')) {
        return language;
    }

    return null;
}

const LngContext = createContext<{
    language: Language;
    setLanguage: (lng: Language) => void;
}>({
    language: Language.DE,
    setLanguage: () => null,
});

export function LanguageProvider({ children }: { children: React.ReactNode }) {
    const defaultLanguage = languageFromString(
        navigator.language || (window.navigator as any).userLanguage,
    );
    const savedLanguage = validOrNullify(
        LocalStorageBase.getStringItem(PARKINGABO_LANG_KEY) as Language | null,
    );

    const language =
        savedLanguage && savedLanguage.length > 0
            ? savedLanguage
            : defaultLanguage;

    const [lng, setLng] = useState(language);

    const setLanguage = (lang: Language) => {
        LocalStorageBase.setStringItem(PARKINGABO_LANG_KEY, lang);
        setLng(lang);
    };

    return (
        <LngContext.Provider
            value={{
                language: lng,
                setLanguage,
            }}
        >
            {children}
        </LngContext.Provider>
    );
}

export function useLanguage() {
    return useContext(LngContext);
}

export function useTranslation(message: Message): string {
    const { language } = useLanguage();
    return message[languageFromString(language)];
}

export const Localized: React.FC<Message | JsxMessage> = props => {
    const { language } = useLanguage();

    return <>{props[languageFromString(language)]}</>;
};
