import { css } from '@emotion/css';

import { isAndroid, isIos } from 'dg-web-shared/common/utils/BrowserOrigin';
import { localState, Updater } from 'dg-web-shared/lib/Flux';
import { JsxMessage, Message } from 'dg-web-shared/lib/Localized';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { Localized } from '../../common/components/Localized';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { logAction } from '../../utils/ActionLog';
import { LandingPageAnchor } from './LandingPageFooter';
import {
    BlueBackdrop,
    BlueBackdropHeader,
    ButtonType,
    LoadAppOrSignUp,
} from './LandingPageShared';
import { portalOrModalSlideIn } from './PortalSlideInOrModal';

export const LandingPageHowItWorks = (p: {
    update: Updater;
    showLoadApp: boolean;
    isInApp: boolean;
    viewportWidth: number;
}) => {
    const android = isAndroid();
    const iOs = isIos();
    const showLoadApp = !p.isInApp && (android || iOs);
    return (
        <BlueBackdrop image={'mood'} spaceBetween>
            <BlueBackdropHeader anchor={LandingPageAnchor.app}>
                <Localized
                    de="Wie funktioniert die App?"
                    fr="Comment ça fonctionne l'appli?"
                    it="Come funziona l'app?"
                    en="How does the app work?"
                />
            </BlueBackdropHeader>
            <ExplainItem
                viewportWidth={p.viewportWidth}
                icon={onstreetIcon}
                {...Onstreet}
            />
            <ExplainItem
                viewportWidth={p.viewportWidth}
                icon={offstreetIcon}
                {...Offstreet}
            />
            <ExplainItem
                viewportWidth={p.viewportWidth}
                icon={permitIcon}
                {...Permits}
            />
            <br />
            <LoadAppOrSignUp
                update={p.update}
                showLoadApp={showLoadApp}
                type={ButtonType.white}
            />
        </BlueBackdrop>
    );
};

interface ExplainItemProps {
    icon: JSX.Element;
    header: Message;
    content: JsxMessage;
    viewportWidth: number;
}

const SlideIn = portalOrModalSlideIn<{ content: JsxMessage }>(p => {
    return (
        <div
            className={css({
                padding: '0px 14px 0px 13px',
            })}
        >
            <Localized {...p.content} />
        </div>
    );
});

const ExplainItem = localState<ExplainItemProps, { open: boolean }>(
    { open: false },
    p => {
        return (
            <>
                <Clickable
                    element="div"
                    className={css({
                        marginTop: '15px',
                        height: '125px',
                        background: 'rgba(19, 47, 105, 0.7)',
                        borderRadius: '2px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingTop: '20px',
                        paddingBottom: '15px',
                    })}
                    onClick={() => {
                        logAction(
                            null,
                            'landing-page-how-it-works-click-item',
                            {
                                type: p.header.en,
                            },
                        );
                        p.setState({ open: true });
                    }}
                >
                    <div className={css({ width: '50px', height: '50px' })}>
                        {p.icon}
                    </div>
                    <div
                        className={css({
                            fontSize: '20px',
                            ...Typo.robotoBold,
                            color: Colors.white,
                            letterSpacing: '0.1px',
                        })}
                    >
                        <Localized {...p.header} />
                    </div>
                </Clickable>
                <SlideIn
                    viewportWidth={p.viewportWidth}
                    open={p.state.open}
                    onClose={() => {
                        p.setState({ open: false });
                    }}
                    title={<Localized {...p.header} />}
                    portal={SlideInPortalId.UNAUTH_LANDING_PAGE}
                    headerColor={'darkBlue'}
                    content={p.content}
                />
            </>
        );
    },
);

const Content = (p: { children?: React.ReactNode }) => {
    return (
        <div
            className={css({
                ...Typo.robotoMedium,
                fontSize: '16px',
                color: Colors.darkblue,
                margin: '15px 10px',
                li: {
                    marginBottom: '10px',
                },
            })}
        >
            {p.children}
        </div>
    );
};

const Onstreet = {
    header: {
        de: 'Strassenparkplätze',
        fr: 'Parking en voirie',
        it: 'Parcheggi su strada',
        en: 'Street parking',
    },
    content: {
        de: (
            <Content>
                <h2>Wie funktioniert's?</h2>
                <ol>
                    <li>
                        Auto parkieren, Parkingpay-App öffnen und Zone wählen.
                    </li>
                    <li>
                        Voraussichtliche Parkdauer einstellen und Parkvorgang
                        starten.
                    </li>
                    <li>Wenn zurück im Auto, Parkvorgang stoppen.</li>
                </ol>
                <h2>Vorteile</h2>
                <ul>
                    <li>Bargeldlos bezahlen</li>
                    <li>Nur effektive Parkdauer bezahlt</li>
                    <li>Kein Gang zur Parkuhr</li>
                </ul>
            </Content>
        ),
        fr: (
            <Content>
                <h2>Comment ça marche?</h2>
                <ol>
                    <li>
                        Stationner la voiture, ouvrir l'appli Parkingpay et
                        sélectionner la zone.
                    </li>
                    <li>
                        Définir la durée de stationnement prévue et démarrer la
                        procédure.
                    </li>
                    <li>
                        Une fois de retour dans la voiture, arrêtez la
                        procédure.
                    </li>
                </ol>
                <h2>Avantages</h2>
                <ul>
                    <li>Paiement sans espèces</li>
                    <li>Vous ne payez que la durée réelle du stationnement</li>
                    <li>Vous n'avez plus besoin de passer au parcmètre</li>
                </ul>
            </Content>
        ),
        it: (
            <Content>
                <h2>Come funziona?</h2>
                <ol>
                    <li>
                        Parcheggiare l'auto, aprire l'app Parkingpay e
                        selezionare la zona.
                    </li>
                    <li>
                        Definire la durata di parcheggio prevista e avviare la
                        procedura.
                    </li>
                    <li>
                        Quando si torna alla macchina, fermare la procedura.
                    </li>
                </ol>
                <h2>Vantaggi</h2>
                <ul>
                    <li>Pagamento senza contanti</li>
                    <li>Si paga solo la durata effettiva</li>
                    <li>Non è più necessario passare dal parchimetro</li>
                </ul>
            </Content>
        ),
        en: (
            <Content>
                <h2>How does it work?</h2>
                <ol>
                    <li>Park car, open Parkingpay app and select zone.</li>
                    <li>
                        Define the expected parking duration and start the
                        procedure.
                    </li>
                    <li>When back in the car, stop the procedure.</li>
                </ol>
                <h2>Advantages</h2>
                <ul>
                    <li>Cashless payment</li>
                    <li>Only effective parking duration paid</li>
                    <li>No walk to the parking meter</li>
                </ul>
            </Content>
        ),
    },
};

const Offstreet = {
    header: {
        de: 'Parkhäuser',
        fr: 'Parkings à étages',
        it: 'Autosili',
        en: 'Park garages',
    },
    content: {
        de: (
            <Content>
                <h2>Wie funktioniert's?</h2>
                <ol>
                    <li>
                        Gratis in der App für das Fahrzeug einen Badge
                        bestellen.
                        <br />
                        KONTO &#x3E; FAHRZEUGE &#x3E; <i>Fahrzeug XY</i> &#x3E;
                        BADGE HINZUFÜGEN
                    </li>
                    <li>
                        Badge bei der Einfahrtsstation an den Badge-Leser
                        halten.
                    </li>
                    <li>
                        Badge bei der Ausfahrt an den Badge-Leser halten. Die
                        Gebühr wird automatisch abgebucht.
                    </li>
                </ol>
                <h2>Vorteile</h2>
                <ul>
                    <li>Bargeldlos bezahlen</li>
                    <li>Kein Ticket</li>
                    <li>Kein Gang zur Parkhauskasse</li>
                </ul>
            </Content>
        ),
        fr: (
            <Content>
                <h2>Comment ça marche?</h2>
                <ol>
                    <li>
                        Commander gratuitement dans l'appli un badge pour le
                        vehicule.
                        <br />
                        COMPTE &#x3E; VÉHICULES &#x3E; <i>Véhicule XY</i> &#x3E;
                        AJOUTER UNE BADGE
                    </li>
                    <li>
                        Présenter le badge devant le lecteur de la station
                        d'entrée.
                    </li>
                    <li>
                        Présenter le badge devant le lecteur de la station de
                        sortie. Les taxes seront débités automatiquement.
                    </li>
                </ol>
                <h2>Avantages</h2>
                <ul>
                    <li>Paiement sans espèces</li>
                    <li>Pas de ticket</li>
                    <li>Vous n'avez plus besoin de passer à la caisse</li>
                </ul>
            </Content>
        ),
        it: (
            <Content>
                <h2>Come funziona?</h2>
                <ol>
                    <li>
                        Ordinare gratuitamente nell'app un badge per il veicolo.
                        <br />
                        CONTO &#x3E; VEICOLI &#x3E; <i>Veicolo XY</i> &#x3E;
                        AGGIUNGI BADGE
                    </li>
                    <li>
                        Appoggiare il bagde al lettore della stazione d'entrata.
                    </li>
                    <li>
                        Appoggiare il badge al lettore della stazione d'uscita.
                        La tassa sarà addebitata automaticamente.
                    </li>
                </ol>
                <h2>Vantaggi</h2>
                <ul>
                    <li>Pagamento senza contanti</li>
                    <li>Niente biglietto</li>
                    <li>Non è più necessario passare dalla cassa</li>
                </ul>
            </Content>
        ),
        en: (
            <Content>
                <h2>How does it work?</h2>
                <ol>
                    <li>
                        Order a free badge for your vehicle in the app
                        <br />
                        ACCOUNT &#x3E; VEHICLES &#x3E; <i>Vehicle XY</i> &#x3E;
                        ADD BADGE
                    </li>
                    <li>
                        Hold the badge to the reader at the entrance station.
                    </li>
                    <li>
                        Hold the badge to the reader at the exit station. The
                        fee is debited automatically.
                    </li>
                </ol>
                <h2>Advantages</h2>
                <ul>
                    <li>Cashless payment</li>
                    <li>No ticket</li>
                    <li>No walk to the cash machine</li>
                </ul>
            </Content>
        ),
    },
};

const Permits = {
    header: {
        de: 'Bewilligungen',
        fr: 'Autorisations',
        it: 'Autorizzazioni',
        en: 'Permits',
    },
    content: {
        de: (
            <Content>
                <h2>Wie funktioniert's?</h2>
                <ol>
                    <li>
                        Wenn nötig, Freigabe zum Kauf der Bewilligung
                        beantragen.
                    </li>
                    <li>
                        App öffnen oder im Browser parkingpay.ch öffnen und
                        Bewilligung kaufen.
                    </li>
                    <li>
                        Ein paar Tage vor Ablauf kriegen Sie ein
                        Erinnerungsmail. Die Bewilligung kann bequem mit
                        Parkingpay verlängert werden.
                    </li>
                </ol>
                <h2>Vorteile</h2>
                <ul>
                    <li>Kein Gang zur Gemeinde</li>
                    <li>Bargeldlos</li>
                    <li>Keine Karte hinter der Windschutzscheibe</li>
                </ul>
            </Content>
        ),
        fr: (
            <Content>
                <h2>Comment ça marche?</h2>
                <ol>
                    <li>
                        Si nécessaire, demander une approbation pour acheter
                        l'autorisation.
                    </li>
                    <li>
                        Ouvrez l'appli ou parkingpay.ch dans votre navigateur et
                        achetez l'autorisation.
                    </li>
                    <li>
                        Quelques jours avant la date d'échéance, vous recevrez
                        un rappel par email. L'autorisation peut être facilement
                        renouvelée avec Parkingpay.
                    </li>
                </ol>
                <h2>Avantages</h2>
                <ul>
                    <li>Vous n'avez plus besoin de passer à la commune</li>
                    <li>Paiement sans espèces</li>
                    <li>Pas de carte derrière le pare-brise</li>
                </ul>
            </Content>
        ),
        it: (
            <Content>
                <h2>Come funziona?</h2>
                <ol>
                    <li>
                        Se necessario, richiedere l'approvazione per l'acquisto
                        dell'autorizzazione.
                    </li>
                    <li>
                        Aprire l'app o parkingpay.ch nel browser e acquistare
                        l'autorizzazione.
                    </li>
                    <li>
                        Alcuni giorni prima della scadenza viene inviato
                        un'e-mail di promemoria. L'autorizzazione può essere
                        facilmente rinnovata con Parkingpay.
                    </li>
                </ol>
                <h2>Vantaggi</h2>
                <ul>
                    <li>Non è più necessario passare in comune</li>
                    <li>Pagamento senza contanti</li>
                    <li>Nessun cartoncino dietro il parabrezza</li>
                </ul>
            </Content>
        ),
        en: (
            <Content>
                <h2>How does it work?</h2>
                <ol>
                    <li>
                        If necessary, apply for clearance to purchase the
                        permit.
                    </li>
                    <li>
                        Open the app or parkingpay.ch in your browser and
                        purchase the permit.
                    </li>
                    <li>
                        A few days before the expiration date, a reminder email
                        is sent. The permit can be easily renewed with
                        Parkingpay.
                    </li>
                </ol>
                <h2>Advantages</h2>
                <ul>
                    <li>No walk to the municipality</li>
                    <li>Cashless payment</li>
                    <li>No card behind the windshield</li>
                </ul>
            </Content>
        ),
    },
};

const offstreetIcon = (
    <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
            id="Icon/parking"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M50,25 C49.84375,32.1875 47.34375,37.96875 42.65625,42.65625 C37.96875,47.34375 32.03125,49.84375 25,50 C17.8125,49.84375 12.03125,47.34375 7.34375,42.65625 C2.65625,37.96875 0.15625,32.03125 0,25 C0.15625,17.8125 2.65625,12.03125 7.34375,7.34375 C12.03125,2.65625 17.96875,0.15625 25,0 C32.03125,0.15625 37.96875,2.65625 42.65625,7.34375 C47.34375,12.03125 49.84375,17.96875 50,25 Z M25.2840909,11.7433506 L42.1292164,17.7818641 L43.75,13.3960614 L25.2777708,6.81818182 L6.81818182,13.3960614 L8.43896541,17.7818641 L25.2840909,11.7433506 Z M32.3537813,31.8130294 C33.8419271,30.4667255 34.5860001,28.6716535 34.5860001,26.4278136 C34.5860001,24.3335631 33.6931125,22.5384912 32.3537813,21.1921872 C31.01445,19.696294 29.2286749,18.9483473 26.9964561,18.9483473 L18.0675809,18.9483473 L18.0675809,43.1818182 L22.9784623,43.1818182 L22.9784623,34.0568693 L26.9964561,34.0568693 C29.2286749,34.0568693 31.01445,33.1593333 32.3537813,31.8130294 Z M28.9310458,28.2228856 C28.6334166,28.6716535 28.0381582,29.1204215 27.1452707,29.1204215 L22.9784623,29.1204215 L22.9784623,23.8847951 L27.1452707,23.8847951 C27.8893436,23.8847951 28.484602,24.1839738 28.9310458,24.6327417 C29.5263041,25.0815097 29.6751187,25.679867 29.6751187,26.4278136 C29.6751187,27.1757603 29.3774895,27.7741176 28.9310458,28.2228856 Z"
                id="Combined-Shape-Copy-2"
                fill="#FFFFFF"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

const onstreetIcon = (
    <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
            id="Icon/parktransaction"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Shape-2" fill="#FFFFFF" fillRule="nonzero">
                <path
                    d="M50,25 C49.84375,32.1875 47.34375,37.96875 42.65625,42.65625 C37.96875,47.34375 32.03125,49.84375 25,50 C17.8125,49.84375 12.03125,47.34375 7.34375,42.65625 C2.65625,37.96875 0.15625,32.03125 0,25 C0.15625,17.8125 2.65625,12.03125 7.34375,7.34375 C12.03125,2.65625 17.96875,0.15625 25,0 C32.03125,0.15625 37.96875,2.65625 42.65625,7.34375 C47.34375,12.03125 49.84375,17.96875 50,25 Z M36,25 L18,13.75 L18,36.25 L36,25 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);

const permitIcon = (
    <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
            id="Icon/permit"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Shape-3-Copy-3"
                transform="translate(-0.000000, 0.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
            >
                <path
                    d="M60.5633803,43.7206573 L60.5633803,6.74882629 C60.5633803,5.01577171 59.9955986,3.66784038 58.8600352,2.31990904 C57.5352113,1.35710094 56.3996479,0.58685446 54.5070423,0.58685446 L6.05633803,0.58685446 C4.35299296,0.58685446 3.02816901,1.35710094 1.70334507,2.31990904 C0.56778169,3.66784038 0,5.01577171 0,6.74882629 L0,19.07277 C1.70334507,19.07277 3.02816901,19.8430164 4.35299296,20.8058245 C5.6778169,21.7686326 6.05633803,23.3091256 6.05633803,25.2347418 C6.05633803,26.9677964 5.48855634,28.3157277 4.35299296,29.663659 C3.02816901,31.0115904 1.89260563,31.3967136 0,31.3967136 L0,43.7206573 C0,45.4537119 0.56778169,46.8016432 1.70334507,48.1495745 C3.02816901,49.4975059 4.16373239,49.8826291 6.05633803,49.8826291 L54.6963028,49.8826291 C56.3996479,49.8826291 57.7244718,49.1123826 59.0492958,48.1495745 C60.3741197,46.9942048 60.5633803,45.4537119 60.5633803,43.7206573 M35.3917254,19.2653316 C35.3917254,22.1537559 34.4454225,24.4644953 32.5528169,26.1975499 C30.8494718,27.9306045 28.5783451,29.0859742 25.7394366,29.0859742 L20.6294014,29.0859742 L20.6294014,40.832233 L14.3838028,40.832233 L14.3838028,9.63725059 L25.7394366,9.63725059 C28.5783451,9.63725059 30.8494718,10.6000587 32.5528169,12.5256749 C34.256162,14.2587295 35.3917254,16.5694689 35.3917254,19.2653316 M28.1998239,21.576071 C28.7676056,20.9983862 29.1461268,20.2281397 29.1461268,19.2653316 C29.1461268,18.3025235 28.9568662,17.532277 28.1998239,16.9545921 C27.6320423,16.3769073 26.875,15.991784 25.9286972,15.991784 L20.6294014,15.991784 L20.6294014,22.7314407 L25.9286972,22.7314407 C27.0642606,22.7314407 27.8213028,22.1537559 28.1998239,21.576071"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);
