import { css } from '@emotion/css';
import { Typo } from '../../style/typo';
import { Localized } from './Localized';
import { Colors } from 'dg-web-shared/ui/vars';

export const enum DelimiterVariant {
    BLUE = 'BLUE',
    WHITE = 'WHITE',
}

export function OrDelimiter({ variant }: { variant: DelimiterVariant }) {
    return (
        <TextDelimiter variant={variant}>
            <Localized de="oder" fr="ou" it="oppure" en="or" />
        </TextDelimiter>
    );
}

export function TextDelimiter({
    children,
    variant,
}: {
    variant: DelimiterVariant;
    children: React.ReactNode;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                alignItems: 'center',
                margin: '32px 0px',
            })}
        >
            <DelimiterBar variant={variant} />
            <div
                className={css({
                    padding: '0 16px',
                    ...Typo.robotoBlack,
                    fontSize: '16px',
                    color: getTextColor(variant),
                })}
            >
                {children}
            </div>
            <DelimiterBar variant={variant} />
        </div>
    );
}

function DelimiterBar({ variant }: { variant: DelimiterVariant }) {
    return (
        <div
            className={css({
                backgroundColor: getBorderColor(variant),
                height: '2px',
                flexGrow: 1,
                opacity: getBorderOpacity(variant),
            })}
        />
    );
}

function getBorderColor(variant: DelimiterVariant) {
    switch (variant) {
        case DelimiterVariant.BLUE:
            return Colors.darkblue;
        case DelimiterVariant.WHITE:
            return Colors.white;
    }
}

function getBorderOpacity(variant: DelimiterVariant) {
    switch (variant) {
        case DelimiterVariant.BLUE:
            return undefined;
        case DelimiterVariant.WHITE:
            return 0.6;
    }
}

function getTextColor(variant: DelimiterVariant) {
    switch (variant) {
        case DelimiterVariant.BLUE:
            return Colors.darkblue;
        case DelimiterVariant.WHITE:
            return Colors.white;
    }
}
