import { Outlet } from 'react-router-dom';
import { SlideInPortalId } from '../account/root/components/PortalSlidein';

import { QuickCheckoutConstants } from './common/QuickCheckoutConstants';
import { QuickCheckoutLayout } from './common/QuickCheckoutLayout';

export function QuickCheckoutRoot() {
    return (
        <QuickCheckoutLayout>
            <Outlet />
            <div id={QuickCheckoutConstants.SLIDE_IN_CONTAINER_ID} />
            <div id={SlideInPortalId.FULL_SCREEN_MODAL} />
        </QuickCheckoutLayout>
    );
}
