import { Store, Write } from 'dg-web-shared/lib/Flux';
import * as LayoutState from '../state/LayoutState';

export function viewportResize(args: {
    viewportWidth: number;
    viewportHeight: number;
}): Write {
    return (store: Store): void => {
        new LayoutState.StateSlice(store).writeViewport(
            args.viewportWidth,
            args.viewportHeight,
        );
    };
}

export const mobileNavigatePark = (store: Store) => {
    LayoutState.Mobile.stateWrite(store, {
        transactionSlideinOpen: false,
        accountSlideinOpen: false,
    });
    return 'navigatePark';
};

export const mobileNavigateTransactions = (store: Store) => {
    LayoutState.Mobile.stateWrite(store, {
        transactionSlideinOpen: true,
        accountSlideinOpen: false,
    });
    return 'navigateTransactions';
};

export const mobileCloseTransactions = (store: Store) => {
    LayoutState.Mobile.stateWrite(store, { transactionSlideinOpen: false });
    return 'closeTransactions';
};

export const mobileNavigateAccount = (store: Store) => {
    LayoutState.Mobile.stateWrite(store, {
        transactionSlideinOpen: false,
        accountSlideinOpen: true,
    });
    return 'navigateAccount';
};
