import React from 'react';
import { IconProps } from 'dg-web-shared/ui/icons/Icon';
import { css } from '@emotion/css';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon';

export class IconBig extends React.Component<IconProps> {
    render() {
        return (
            <div className={css({ width: '48px', height: '48px' })}>
                <SvgIcon {...this.props} width={48} height={48} />
            </div>
        );
    }
}
