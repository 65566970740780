import React from 'react';

export type ReactRenderReturnType =
    | JSX.Element
    | JSX.Element[]
    | React.ReactPortal
    | string
    | number
    | null
    | false;

/*
 * @deprecated use emotion css
 */
export const ElementNamer = (component: string) => (block?: string) =>
    `${component}${block ? '' : ''}${block ? block : ''}`;

/*
 * @deprecated use emotion css
 */
export const ElementNamerGenerator = (file: string) => (component?: string) =>
    ElementNamer(`${file}${component ? component : ''}`);

export interface ConditionalProps {
    c: boolean;
    children?: React.ReactElement;
}

/*
 * @deprecated use expression or ternary
 */
export const Conditional = (p: ConditionalProps): JSX.Element | null => {
    if (!p.c) {
        return null;
    }
    return React.Children.only(p.children || null);
};

export interface HasChildren {
    children?: React.ReactNode;
    className?: string;
}

/*
 * @deprecated use emotion css
 */
export const DivGenerator = (className: string) => (p: HasChildren) => (
    <div className={`${className} ${p.className ? p.className : ''}`}>
        {p.children}
    </div>
);

export const nl2br = (str: string) =>
    str.split('\n').map((l, i) => (
        <span key={i}>
            {l}
            <br />
        </span>
    ));
