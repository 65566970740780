import Http = require('../../api/Http');
import CommonQueryActions = require('../actions/CommonQueryActions');
import Flux = require('dg-web-shared/lib/Flux');
import * as ServerStateSlice from './ServerStateSliceV2';

export type Language = Http.Language;

export type State = ServerStateSlice.State<Language[]>;

export class StateSlice extends ServerStateSlice.LegacyServerStateSlice<
    Language[]
> {
    key(): string {
        return 'common-LanguageState';
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.shouldFetch) {
            store.legacyUpdater(CommonQueryActions.receiveLanguages);
        }
    }
    parseBody(body: any): Language[] {
        return body || [];
    }
    getEmptyData(): Language[] {
        return [];
    }
}
