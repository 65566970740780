import * as Flux from 'dg-web-shared/lib/Flux';

export interface State {
    reminderTypesExpanded: boolean;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'account-current-login-CurrentLoginExpandedState';
    }

    sideEffects(): void {
        return;
    }

    getInitialState(): State {
        return {
            reminderTypesExpanded: false,
        };
    }

    toggleReminderTypes(): void {
        this.set((s: State): State => {
            s.reminderTypesExpanded = !s.reminderTypesExpanded;
            return s;
        });
    }
}
