import { css } from '@emotion/css';

import {
    generateState,
    localState,
    selectState,
    Updater,
} from 'dg-web-shared/lib/Flux';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { SingleSelection } from '../../../../tb-ui/inputs/SingleSelection';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../common/components/FullWidthBottomButton';
import { Localized } from '../../common/components/Localized';
import * as LayoutState from '../../layout/state/LayoutState';
import { Login } from '../../login/components/Login';
import {
    CityDropinContainerState,
    CityDropinList,
    selectCityDropinState,
} from '../../park-create/components/park-option/CityDropin';
import { CitiesState } from '../../park-create/components/ParkOptionList';
import * as CityDropinState from '../../park-create/state/CityDropinState';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { logAction } from '../../utils/ActionLog';
import { SignUpSlidein } from './components/SignUpSlidein';
import { HasEverLoggedInState } from './HasEverLoggedInState';
import { LandingPageFAQ } from './LandingPageFAQ';
import { LandingPageFooter } from './LandingPageFooter';
import {
    DesktopLandingPageHeader,
    MobileLandingPageHeader,
} from './LandingPageHeader';
import { LandingPageHowItWorks } from './LandingPageHowItWorks';
import {
    BlueBackdrop,
    BlueBackdropHeader,
    ButtonType,
    LoadAppOrSignUp,
} from './LandingPageShared';
import {
    DesktopBreakpointWidth,
    FooterHeight,
    HeaderHeight,
    screenHeightPercentile,
} from './LandingPageVars';
import { portalOrModalSlideIn } from './PortalSlideInOrModal';
import { ContentMode, SignUpSlideinState } from './state/SignUpSlideinState';
import { useState } from 'react';
import { isAndroid, isIos } from 'dg-web-shared/common/utils/BrowserOrigin';
import { isRunningInNativeWrapper } from '../../Native';

const moodImage = require('./assets/parking-emotion.jpg');

interface State {
    scroll: ScrollState.State;
    layout: LayoutState.State;
    hasEverLoggedIn: HasEverLoggedInState.State;
    signUpState: SignUpSlideinState.State;
}

export const LandingPage = selectState<{}, State>(
    s => ({
        scroll: ScrollState.get(s),
        layout: new LayoutState.StateSlice(s).state,
        hasEverLoggedIn: HasEverLoggedInState.get(s),
        signUpState: SignUpSlideinState.get(s),
    }),
    p => {
        return (
            <div
                className={css({
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflow: 'hidden',
                    backround: 'white',
                })}
            >
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    })}
                >
                    {p.layout.viewportWidth < DesktopBreakpointWidth && (
                        <MobileLandingPageHeader
                            bigLoginPossible={isRunningInNativeWrapper()}
                            viewportHeight={p.layout.viewportHeight}
                            scrollTop={p.scroll.top}
                            everScrolled={p.scroll.everScrolled}
                            hasEverLoggedIn={p.hasEverLoggedIn.hasEver}
                        />
                    )}
                    <div
                        className={css({
                            flexGrow: 1,
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            WebkitOverflowScrolling: 'touch',
                        })}
                        onScroll={e => {
                            const el = e.target;
                            const scrollTop = Math.max(
                                (el as any).scrollTop,
                                0,
                            );
                            p.update(s => {
                                const scrolledFarEnough =
                                    screenHeightPercentile(
                                        p.layout.viewportHeight,
                                        1 / 3,
                                    ) < scrollTop;
                                if (
                                    !p.scroll.everScrolled &&
                                    scrolledFarEnough
                                ) {
                                    logAction(s, 'landing-page-scrolled-down');
                                }
                                return ScrollState.stateWrite(s, {
                                    everScrolled:
                                        p.scroll.everScrolled ||
                                        scrolledFarEnough,
                                    top: scrollTop,
                                });
                            });
                        }}
                    >
                        <div>
                            {p.hasEverLoggedIn.hasEver ? (
                                <div
                                    className={css({
                                        paddingTop: '1px',
                                        background: Colors.white,
                                        height:
                                            p.layout.viewportHeight -
                                            FooterHeight -
                                            (p.layout.viewportWidth <
                                            DesktopBreakpointWidth
                                                ? HeaderHeight
                                                : 0),
                                    })}
                                >
                                    {!p.signUpState.loginSlideinOpen && (
                                        <LoginContent />
                                    )}
                                </div>
                            ) : (
                                <Content
                                    viewportHeight={p.layout.viewportHeight}
                                    viewportWidth={p.layout.viewportWidth}
                                    update={p.update}
                                    isInApp={isRunningInNativeWrapper()}
                                />
                            )}
                        </div>
                        <LandingPageFooter />
                    </div>
                    <SignUpSlidein />
                    {p.layout.viewportWidth > DesktopBreakpointWidth && (
                        <DesktopLandingPageHeader
                            hasEverLoggedIn={p.hasEverLoggedIn.hasEver}
                            update={p.update}
                        />
                    )}
                    <div
                        id={SlideInPortalId.UNAUTH_LANDING_PAGE}
                        className={css({ overflow: 'hidden' })}
                    />
                </div>
            </div>
        );
    },
);

const LoginContent = selectState(
    s => ({
        allState: s,
        layout: new LayoutState.StateSlice(s).state,
    }),
    p => {
        const [forgotPasswordVisible, setForgotPasswordVisible] =
            useState(false);

        const [email, setEmail] = useState('');

        return (
            <div
                className={css({
                    marginTop: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    [`@media(min-width: ${DesktopBreakpointWidth}px)`]: {
                        marginTop: '150px',
                    },
                })}
            >
                <div
                    className={css({
                        width: Math.min(p.layout.viewportWidth, 500),
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    })}
                >
                    <Login
                        openForgotPassword={() =>
                            setForgotPasswordVisible(true)
                        }
                        closeForgotPassword={() =>
                            setForgotPasswordVisible(false)
                        }
                        forgotPasswordVisible={forgotPasswordVisible}
                        email={email}
                        setEmail={setEmail}
                    />

                    {!forgotPasswordVisible ? (
                        <div
                            className={css({
                                marginTop: '50px',
                                padding: '0 72px',
                                textAlign: 'center',
                                ...Typo.robotoRegular,
                                fontSize: '15px',
                                color: Colors.blue,
                                fontWeight: 500,
                            })}
                        >
                            <Localized
                                de="Noch kein Konto?"
                                fr="Pas encore un compte?"
                                it="Non avete ancora un conto?"
                                en="You don't have an account yet?"
                            />
                            <FullWidthBottomButton
                                color={FullWidthBottomButtonColor.WHITE}
                                onClick={() => {
                                    p.update(SignUpSlideinState.stateWrite, {
                                        loginSlideinOpen: true,
                                        contentMode: ContentMode.SIGNUP,
                                    });
                                    p.update(s =>
                                        logAction(
                                            s,
                                            'landing-page-click-sign-up',
                                        ),
                                    );
                                }}
                                label={{
                                    de: 'Hier registrieren',
                                    fr: 'Enregistrez-vous ici',
                                    it: 'Registratevi qui',
                                    en: 'Sign up here',
                                }}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    },
);

export namespace ScrollState {
    export interface State {
        top: number;
        everScrolled: boolean;
    }

    export const { get, stateWrite } = generateState<State>(
        'landing-page-scroll-pos',
        {
            top: 0,
            everScrolled: false,
        },
    );
}

export const Content = (p: {
    viewportHeight: number;
    viewportWidth: number;
    update: Updater;
    isInApp: boolean;
}) => {
    const android = isAndroid();
    const iOs = isIos();
    const showLoadApp = !p.isInApp && (android || iOs);
    const isDesktop = p.viewportWidth > DesktopBreakpointWidth;
    return (
        <>
            <div
                className={css({
                    height:
                        screenHeightPercentile(
                            p.viewportHeight,
                            isDesktop ? 1 / 2.2 : 1 / 3,
                        ) - 20,
                    overflow: 'hidden',
                    marginLeft: isDesktop ? '0' : '-25%',
                })}
            >
                <img
                    src={moodImage}
                    width={isDesktop ? '100%' : '150%'}
                    className={css({
                        marginTop: isDesktop ? '-10%' : '-17%',
                    })}
                />
            </div>
            <div
                className={css({
                    position: 'relative',
                    padding: '0 20px',
                    height:
                        screenHeightPercentile(
                            p.viewportHeight,
                            isDesktop ? 1 / 2.5 : 1 / 3,
                        ) - 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    background: Colors.white,
                })}
            >
                <PromotionButton
                    viewportWidth={p.viewportWidth}
                    showLoadApp={showLoadApp}
                    update={p.update}
                />

                <Claim>
                    <Localized
                        de={
                            <span>
                                Grösste Abdeckung <br /> in der Schweiz.
                            </span>
                        }
                        fr={
                            <span>
                                La plus grande couverture <br /> en Suisse.
                            </span>
                        }
                        it={
                            <span>
                                La maggiore diffusione <br /> in Svizzera.
                            </span>
                        }
                        en={
                            <span>
                                Largest coverage <br /> in Switzerland.
                            </span>
                        }
                    />
                </Claim>
                <Claim>
                    <Localized
                        de={
                            <span>
                                Einziger Anbieter <br /> auch für Parkhäuser.
                            </span>
                        }
                        fr={
                            <span>
                                Le seule aussi pour
                                <br />
                                les parking à barrières.
                            </span>
                        }
                        it={
                            <span>
                                L'unica soluzione
                                <br />
                                anche per gli autosili.
                            </span>
                        }
                        en={
                            <span>
                                The only one also
                                <br />
                                for park garages.
                            </span>
                        }
                    />
                </Claim>
            </div>
            <BlueBackdrop image={'relief'}>
                <BlueBackdropHeader>
                    <Localized
                        de="Verfügbarkeit prüfen"
                        fr="Vérifier la disponibilité"
                        it="Verifica disponibilità"
                        en="Check availability"
                    />
                </BlueBackdropHeader>
                <CityList viewportWidth={p.viewportWidth} />
                <br />
                <LoadAppOrSignUp
                    update={p.update}
                    showLoadApp={showLoadApp}
                    type={ButtonType.white}
                />
            </BlueBackdrop>
            <LandingPageHowItWorks
                viewportWidth={p.viewportWidth}
                update={p.update}
                showLoadApp={showLoadApp}
                isInApp={p.isInApp}
            />
            <LandingPageFAQ update={p.update} showLoadApp={showLoadApp} />
        </>
    );
};

interface CityListState {
    cities: CitiesState.State;
}

const CityList = selectState<
    { viewportWidth: number },
    CityListState & CityDropinContainerState
>(
    s => {
        const dropinState = selectCityDropinState(s);
        const location = dropinState.geolocation;
        return {
            cities: CitiesState.get(s, {
                location: location.currentLocation,
                isAuthed: false,
            }),
            ...dropinState,
        };
    },
    p => {
        const cities = p.cities.data;
        return (
            <div>
                <SingleSelection
                    labelText=""
                    selection={
                        cities ? (
                            <Localized
                                de={`${cities.length} Standorte`}
                                fr={`${cities.length} sites`}
                                it={`${cities.length} ubicazioni`}
                                en={`${cities.length} locations`}
                            />
                        ) : (
                            <Localized
                                de={'PLZ / Ort'}
                                fr={`NPA / Localité`}
                                it={`NAP / Località`}
                                en={`ZIP / City`}
                            />
                        )
                    }
                    onClick={() => {
                        p.update(s => {
                            new CityDropinState.StateSlice(s).setSortField(
                                CityDropinState.SortField.Distance,
                            );
                            new CityDropinState.StateSlice(s).setSortAscending(
                                true,
                            );
                            new CityDropinState.StateSlice(s).setOpen(true);

                            logAction(s, 'landing-page-open-city-dropin');
                            return 'open-unauth-city-dropin';
                        });
                    }}
                    context={InputContext.inverted}
                />
                {cities && (
                    <CityListSlideIn
                        open={p.cityDropin.open}
                        title={
                            <Localized
                                de={`${cities.length} Standorte`}
                                fr={`${cities.length} sites`}
                                it={`${cities.length} ubicazioni`}
                                en={`${cities.length} locations`}
                            />
                        }
                        onClose={() => {
                            p.update(s => {
                                new CityDropinState.StateSlice(s).setOpen(
                                    false,
                                );
                                return 'close-unauth-city-dropin';
                            });
                        }}
                        citiesData={cities}
                        {...p}
                        portal={SlideInPortalId.UNAUTH_LANDING_PAGE}
                    />
                )}
            </div>
        );
    },
);

interface CityListSlideInProps {
    update: Updater;
    citiesData: Readonly<CitiesState.City[]>;
}

const CityListSlideIn = portalOrModalSlideIn<
    CityDropinContainerState & CityListSlideInProps
>(p => {
    return <CityDropinList {...p} cities={p.citiesData} noZones={true} />;
});

const Claim = (p: { children?: React.ReactNode }) => (
    <h1
        className={css({
            display: 'block',
            fontSize: '28px',
            margin: 0,
            '@media(max-height: 600px)': {
                fontSize: '24px',
            },
            '@media(max-height: 480px)': {
                fontSize: '22px',
            },
            [`@media(min-width: ${DesktopBreakpointWidth}px)`]: {
                fontSize: '50px',
                lineHeight: '1.1',
                fontWeigth: 900,
            },
            ...Typo.robotoBlack,
            color: Colors.darkblue,
        })}
    >
        <div
            className={css({
                maxWidth: '500px',
                marginLeft: 'auto',
                marginRight: 'auto',
            })}
        >
            {p.children}
        </div>
    </h1>
);

interface PromotionButtonState {
    open: boolean;
}

const PromotionButton = localState<
    {
        update: Updater;
        viewportWidth: number;
        showLoadApp: boolean;
    },
    PromotionButtonState
>({ open: false }, p => {
    const isMobile = p.viewportWidth < DesktopBreakpointWidth;

    const contentMargin = (p.viewportWidth - 500) / 2;
    return (
        <>
            <div
                className={css({
                    position: 'absolute',
                    top: -70,
                    right: isMobile
                        ? 20
                        : Math.max(contentMargin - 200 - 100, 48),
                    width: isMobile ? '98px' : '200px',
                    height: isMobile ? '98px' : '200px',
                    borderRadius: '50%',
                    transform: 'rotate(-10deg)',
                    textAlign: 'center',
                    fontSize: isMobile ? '13px' : '24px',
                    paddingTop: isMobile ? '22px' : '40px',
                    paddingLeft: isMobile ? '2px' : '4px',
                    ...Typo.robotoMedium,
                    fontStyle: 'italic',
                    color: Colors.white,
                    letterSpacing: isMobile ? '1.2px' : '2.3px',
                    background: 'linear-gradient(225deg, #9e160e, #ff002f)',
                })}
            >
                <Localized
                    de="an über"
                    fr="dans plus"
                    it="in più di"
                    en="at over"
                />{' '}
                <br />{' '}
                <span
                    className={css({
                        fontSize: isMobile ? '17px' : '36px',
                        padding: '4px 0',
                    })}
                >
                    {' '}
                    <Localized de="950" fr="950" it="950" en="950" />
                </span>
                <br />
                <span>
                    <Localized
                        de="Standorten"
                        fr="sites"
                        it="località"
                        en="locations"
                    />{' '}
                </span>
            </div>
        </>
    );
});
