import { useState } from 'react';
import {
    DelimiterVariant,
    TextDelimiter,
} from '../../../common/components/OrDelimiter';
import {
    RecentItem,
    RecentItemType,
} from '../../../transactions-list/TransactionsListContext';
import { InputContext } from '../../../../../tb-ui/inputs/InputContext';
import { SingleSelection } from '../../../../../tb-ui/inputs/SingleSelection';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import { Localized } from '../../../common/components/Localized';
import { css } from '@emotion/css';
import { Typo } from '../../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { ModalQuestionBox } from '../../../ui/modals/Confirmable';
import { useRecentTransactionsRequest } from '../../../account/current-login/RecentsToggleForm';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import * as ParkCreateActions from '../../actions/ParkCreateActions';
import { usePermitPayloadContext } from '../permit/PermitPayloadContext';
import { logAction } from '../../../utils/ActionLog';

export function RecentsParkCreate({
    recentTransactions,
    refetchTransactionList,
}: {
    recentTransactions: RecentItem[];
    refetchTransactionList: () => void;
}) {
    const update = useUpdate();
    const { setSelectedPermitTypeId } = usePermitPayloadContext();
    const [disableRequestState, disableRecents] = useRecentTransactionsRequest(
        () => {
            setShowDisableRecentsDialog(false);
            refetchTransactionList();
        },
    );
    const [clearRequestState, clearRecents] = useServerWrite<
        null,
        unknown,
        unknown
    >(() => ({
        url: 'ui-api/customer-account/recents/clear',
        method: RequestMethod.PUT,
    }));
    useServerSuccessEffect(clearRequestState, () => {
        refetchTransactionList();
        setShowClearRecentsDialog(false);
    });

    const [showDisableRecentsDialog, setShowDisableRecentsDialog] =
        useState(false);
    const [showClearRecentsDialog, setShowClearRecentsDialog] = useState(false);

    return (
        <>
            <TextDelimiter variant={DelimiterVariant.WHITE}>
                <Localized
                    de="Kürzlich verwendet"
                    fr="Récents"
                    it="Recenti"
                    en="Recently used"
                />
            </TextDelimiter>
            {recentTransactions.map(transaction => (
                <SingleSelection
                    key={getTransactionKey(transaction)}
                    selection={transaction.name}
                    context={InputContext.inverted}
                    onClick={() => {
                        if (transaction.type === RecentItemType.PERMIT) {
                            setSelectedPermitTypeId(transaction.permittypeId);
                        }
                        update(store => {
                            logAction(
                                store,
                                'recents-park-create-select',
                                transaction,
                            );
                            ParkCreateActions.setCity(
                                store,
                                transaction.zipCode,
                            );
                            switch (transaction.type) {
                                case RecentItemType.ZONE_ENFORCED:
                                    ParkCreateActions.setParkOption(
                                        store,
                                        transaction.zoneId,
                                    );
                                    ParkCreateActions.parkVariantSelectLicensePlateTransaction(
                                        store,
                                    );
                                    break;
                                case RecentItemType.PERMIT:
                                    ParkCreateActions.parkVariantSelectPermit(
                                        store,
                                        false,
                                    );
                                    break;
                            }
                            return 'recents-select';
                        });
                    }}
                    compactHeight={999999} // we want to be always compact
                    iconRight="plus"
                />
            ))}
            {recentTransactions.length < 1 ? (
                <div
                    className={css({
                        marginTop: '28px',
                        textAlign: 'center',
                        ...Typo.robotoLightItalic,
                        color: Colors.white,
                    })}
                >
                    <Localized
                        de="Kürzlich verwendete Zonen und Bewilligungen werden hier angezeigt"
                        fr="Les zones et les autorisations récemment utilisées sont affichées ici"
                        it="Le zone e le autorizzazioni utilizzate di recente verranno mostrate qui"
                        en="Recently used zones and permits are showed here"
                    />
                </div>
            ) : (
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '16px',
                        marginBottom: '8px',
                        ...Typo.caption,
                        fontSize: '14px',
                        color: Colors.rgba(Colors.action_b, 0.6),
                        textDecoration: 'underline',
                    })}
                >
                    <Clickable
                        element="div"
                        onClick={() => setShowDisableRecentsDialog(true)}
                    >
                        <Localized
                            de="Deaktivieren"
                            fr="Désactiver"
                            it="Disattiva"
                            en="Deactivate"
                        />
                    </Clickable>
                    <Clickable
                        element="div"
                        onClick={() => setShowClearRecentsDialog(true)}
                    >
                        <Localized
                            de="Löschen"
                            fr="Effacer"
                            it="Cancella"
                            en="Clear"
                        />
                    </Clickable>
                </div>
            )}
            {showDisableRecentsDialog && (
                <ModalQuestionBox
                    titleCaption={
                        <Localized
                            de="Kürzlich verwendet deaktivieren"
                            fr="Désactiver les récents"
                            it="Disattiva recenti"
                            en="Disable recents"
                        />
                    }
                    confirmCallback={() => {
                        disableRecents({ showRecents: false });
                        logAction(null, 'recents-park-create-disable', null);
                    }}
                    cancelCallback={() => setShowDisableRecentsDialog(false)}
                    isPending={
                        disableRequestState.status === RequestStatus.PENDING
                    }
                >
                    <p>
                        <Localized
                            de="Möchten Sie die Anzeige der in der App kürzlich verwendeten Zonen und Bewilligungen deaktivieren?"
                            fr="Voulez-vous désactiver l'affichage des zones et les autorisations récemment utilisées dans l'app?"
                            it="Vuole disattivare la visualizzazione delle zone e delle autorizzazioni utilizzate di recente nell'app?"
                            en="Would you like to deactivate the display of the recently used zones and permits?"
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wenn Sie diese Funktion wieder aktivieren möchten, finden Sie sie unter Konto > Benutzerprofil > Kürzlich verwendete."
                            fr="Si vous souhaitez réactiver cette fonction, vous la trouverez sous Compte > Profil utilisateur > Récents."
                            it="Se volesse riattivare questa funzione, la trova sotto Conto > Profilo utente > Recenti."
                            en="If you would like to reactivate this function, you will find it under Account > User Profile > Recents."
                        />
                    </p>
                </ModalQuestionBox>
            )}
            {showClearRecentsDialog && (
                <ModalQuestionBox
                    titleCaption={
                        <Localized
                            de="Kürzlich verwendete löschen"
                            fr="Effacer les récents"
                            it="Cancella recenti"
                            en="Clear recents"
                        />
                    }
                    confirmCallback={() => {
                        clearRecents(null);
                        logAction(null, 'recents-park-create-clear', null);
                    }}
                    cancelCallback={() => setShowClearRecentsDialog(false)}
                    isPending={
                        clearRequestState.status === RequestStatus.PENDING
                    }
                >
                    <p>
                        <Localized
                            de="Möchten Sie die kürzlich verwendeten Zonen und Bewilligungen aus dieser Ansicht löschen?"
                            fr="Voulez-vous supprimer de cette liste les zones et les autorisations récemment utilisées?"
                            it="Vuole cancellare le zone e le autorizzazioni utilizzate di recente da questa lista?"
                            en="Do you want to clear the recently used zones and permits from this view?"
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wenn sie in Zukunft wieder verwendet werden, werden sie wieder hier angezeigt."
                            fr="S'ils seront réutilisés à l'avenir, ils apparaîtront à nouveau ici."
                            it="Se in futuro verrano usate di nuovo, appariranno di nuovo qui."
                            en="If they will be used again in the future, they will appear here again."
                        />
                    </p>
                </ModalQuestionBox>
            )}
        </>
    );
}

function getTransactionKey(transaction: RecentItem) {
    switch (transaction.type) {
        case RecentItemType.ZONE_ENFORCED:
            return `${transaction.type}-${transaction.zoneId}`;
        case RecentItemType.PERMIT:
            return `${transaction.type}-${transaction.permittypeId}`;
    }
}
