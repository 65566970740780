import { useState } from 'react';
import {
    FlowStateTicketApproved,
    PaymentModalState,
    StartPaymentResponse,
} from './QuickCheckoutTypes';
import {
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    PaymentStateModal,
    RevokePaymentErrorModal,
    RevokePaymentModal,
} from './QuickCheckoutModals';
import {
    TicketPlusRevokeApprovalLink,
    TicketPlusTicketApproved,
} from '../../common/components/ticketplus/TicketPlusTicketApproved';
import { useDocumentVisibilityEffect } from 'dg-web-shared/common/hooks/useDocumentVisibility';
import { useNavigate } from 'react-router-dom';

type RefetchFlowState = { refetchFlowState: () => void };

export const QuickCheckoutFlowTicketApproved = ({
    ticket,
    ticketId,
    refetchFlowState,
    zoneInfo,
    paymentModalState,
    onPaymentStateModalConfirm,
}: FlowStateTicketApproved &
    RefetchFlowState & {
        ticketId: string;
        paymentModalState?: PaymentModalState;
        onPaymentStateModalConfirm: () => void;
    }) => {
    const [revokeApprovalState, writeRevokeApprovalState] = useServerWrite<
        { ticket: string },
        StartPaymentResponse,
        unknown
    >(() => ({
        url: `/ui-api/quickcheckout/ticket/approval/remove`,
    }));
    useServerSuccessEffect(revokeApprovalState, refetchFlowState);
    const [revokePaymentErrorModalVisible, setRevokePaymentErrorModalVisible] =
        useState(false);
    const [revokePaymentModalVisible, setRevokePaymentModalVisible] =
        useState(false);
    useServerErrorEffect(revokeApprovalState, () => {
        setRevokePaymentErrorModalVisible(true);
        setRevokePaymentModalVisible(false);
    });
    useDocumentVisibilityEffect(visibilityState => {
        if (visibilityState === 'visible') {
            refetchFlowState();
        }
    });
    const navigate = useNavigate();
    return (
        <>
            {paymentModalState !== undefined && (
                <PaymentStateModal
                    state={paymentModalState}
                    onConfirm={onPaymentStateModalConfirm}
                />
            )}
            {revokePaymentModalVisible && (
                <RevokePaymentModal
                    onCancel={() => setRevokePaymentModalVisible(false)}
                    onConfirm={() =>
                        writeRevokeApprovalState({ ticket: ticketId })
                    }
                />
            )}
            {revokePaymentErrorModalVisible && (
                <RevokePaymentErrorModal
                    onConfirm={() => {
                        refetchFlowState();
                        setRevokePaymentErrorModalVisible(false);
                    }}
                />
            )}
            <TicketPlusTicketApproved
                ticketApiString={ticket.ticketApiString}
                ticketState={ticket.state}
                ticketCreatedAt={ticket.createdAt}
                zoneName={zoneInfo.name}
                onOpenZoneInfo={() => navigate('parking-details')}
            />
            <TicketPlusRevokeApprovalLink
                onClick={() => setRevokePaymentModalVisible(true)}
            />
        </>
    );
};
