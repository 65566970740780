export const FooterHeight = 48;
export const HeaderHeight = 64;
export const DesktopBreakpointWidth = 800;

export const screenHeightPercentile = (
    viewportHeight: number,
    fraction: number,
) => {
    return viewportHeight * fraction;
};

export const scrollTime = 350;
export const scrollTransition = `transform ${scrollTime}ms`;
