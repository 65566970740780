import * as Flux from 'dg-web-shared/lib/Flux';

import * as SettingsState from '../../../common/state/SettingsState';
import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { Helpdesk } from './Helpdesk';
import { Address } from './Address';
import { texts as newsletterUnsubscribePageTexts } from '../i18n/NewsletterUnsubscribePageTexts';
import { css } from '@emotion/css';
import {
    useServerFetch,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import * as superagent from 'superagent';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { Button } from 'semantic-ui-react';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import CurrentLogin = CurrentLoginState.CurrentLogin;

export interface NewsletterUnsubscribePageTexts {
    button: (email: string) => string;
    label: () => string;
    success: () => string;
    failure: () => string;
}

export interface NewsletterUnsubscribeState {
    hash: string | null;
    email: string | null;
    list: string | null;
    valid: boolean;
}

export class StateSlice extends Flux.StateSlice<NewsletterUnsubscribeState> {
    key(): string {
        return 'pages-NewsletterUnsubscribePage';
    }

    getInitialState(): NewsletterUnsubscribeState {
        return {
            hash: null,
            email: null,
            list: null,
            valid: false,
        };
    }

    sideEffects(): null {
        return null;
    }

    update(props: NewsletterUnsubscribeState): void {
        this.set(
            (s: NewsletterUnsubscribeState): NewsletterUnsubscribeState => {
                s.hash = props.hash;
                s.email = props.email;
                s.list = props.list;
                s.valid =
                    props.hash != null &&
                    props.email != null &&
                    props.list != null;
                return s;
            },
        );
    }
}

export const NewsletterUnsubscribePage = () => {
    const { storeState } = Flux.useStore(s => ({
        unsubscribeParams: new StateSlice(s).state,
        settings: new SettingsState.StateSlice(s).state,
        allState: s,
    }));

    const [writeState, unsubscribe] = useServerWrite<
        NewsletterUnsubscribeState,
        {}
    >({
        req: (args: NewsletterUnsubscribeState) => {
            return args.valid
                ? superagent.post(`/ui-api/newsletter/unsubscribe`).send(args)
                : superagent
                      .put('/ui-api/customer-account/current-login-v2')
                      .send({
                          ...userState.data,
                          newsletterStatus: 'UNSUBSCRIBED_BY_US',
                      });
        },
    });

    const [userState] = useServerFetch<CurrentLogin, {}>(
        {
            request: () =>
                superagent.get('/ui-api/customer-account/current-login-v2'),
        },
        {},
    );

    const texts = newsletterUnsubscribePageTexts[storeState.settings.language];

    const canUnsubscribe =
        storeState.unsubscribeParams.valid ||
        userState.status === RequestStatus.SUCCESS;
    const mayUnsubscribe =
        canUnsubscribe || userState.status !== RequestStatus.ERROR;

    const email = storeState.unsubscribeParams.valid
        ? storeState.unsubscribeParams.email!
        : userState.status === RequestStatus.SUCCESS && userState.data.email
          ? userState.data.email
          : 'impossible state';

    return (
        <StaticPage allState={storeState.allState}>
            <LeftColumn allState={storeState.allState}>
                <div className={css({ fontFamily: 'roboto' })}>
                    <h1>{texts.label()}</h1>
                    {!canUnsubscribe && mayUnsubscribe ? (
                        <div>Loading...</div>
                    ) : null}
                    {canUnsubscribe &&
                    (writeState.status === RequestStatus.NEVER_EXECUTED ||
                        writeState.status !== RequestStatus.SUCCESS) ? (
                        <NewsletterUnsubscribeForm
                            email={email}
                            texts={texts}
                            onUnsubscribe={() =>
                                unsubscribe(storeState.unsubscribeParams)
                            }
                        />
                    ) : null}
                    {writeState.status === RequestStatus.SUCCESS ? (
                        <Message text={texts.success()} />
                    ) : null}
                    {writeState.status === RequestStatus.ERROR ||
                    !mayUnsubscribe ? (
                        <Message text={texts.failure()} />
                    ) : null}
                </div>
            </LeftColumn>
            <RightColumn allState={storeState.allState}>
                <Helpdesk allState={storeState.allState} />
                <Address allState={storeState.allState} />
            </RightColumn>
        </StaticPage>
    );
};

const Message = (p: { text: string }) => (
    <div
        className={css({
            marginBottom: '20px',
        })}
    >
        <label
            className={css({
                marginBottom: '10px',
                fontWeight: 'bold',
            })}
        >
            {p.text}
        </label>
    </div>
);

export const NewsletterUnsubscribeForm = (p: {
    email: string;
    texts: NewsletterUnsubscribePageTexts;
    onUnsubscribe: () => any;
}) => (
    <Button onClick={() => p.onUnsubscribe()}>
        <Button.Content>{p.texts.button(p.email || '')}</Button.Content>
    </Button>
);
