import * as Flux from 'dg-web-shared/lib/Flux';
import { Language, languageFromString } from '../i18n/Text';

export interface State {
    language: Language;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'common-SettingsState';
    }

    sideEffects(): void {
        return;
    }

    getInitialState(): State {
        return {
            language: languageFromString(navigator.language.slice(0, 2)),
        };
    }
}

export const useSettingsStateSlice = (): [
    State,
    (t: Flux.Store, state: Partial<State>) => void,
] => {
    const { storeState } = Flux.useStore(s => new StateSlice(s).state);
    return [
        storeState,
        (s: Flux.Store, state: Partial<State>) =>
            new StateSlice(s).set(s => ({ ...s, state })),
    ];
};
