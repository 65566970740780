import { css } from '@emotion/css';

import * as Flux from 'dg-web-shared/lib/Flux';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2';
import * as Icons48 from 'dg-web-shared/ui/icons/Icons48';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../../account/root/components/PortalSlidein';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import * as Text from '../../common/i18n/Text';
import { Colors } from 'dg-web-shared/ui/vars';
import * as ParkCreateActions from '../actions/ParkCreateActions';
import * as ParkCreateTexts from '../i18n/ParkCreateTexts';
import * as ParkOptionListState from '../state/ParkOptionListState';
import { ParkVariant } from '../state/ParkOptionListState';
import { CitiesState, ZonesOfCityState } from './ParkOptionList';
import { PermitBridge } from './permit/Permit';
import { Transaction } from './zone-transaction/Transaction';
import { InlineInfoBox, ArrowPosition } from '../../ui/modals/Confirmable';
import { Localized } from '../../common/components/Localized';
import { paper } from '../../../../tb-ui/paper';
import { Typo } from '../../style/typo';
import styled from '@emotion/styled';
import React from 'react';
import { IconBig } from '../../../../tb-ui/icons/IconBig';
import { Clickable } from 'dg-web-shared/ui/Clickable';

export interface Texts {
    ParkVariant: Text.Translation;
    Transaction: Text.Translation;
    BadgeEnterExit: Text.Translation;
    Permit: Text.Translation;
    SelectZoneInfoTitle: Text.Translation;
    SelectZoneInfoBody: (v: ParkVariant) => string;
}

interface Props {
    language: string;
    selectedCity: CitiesState.City;
    zoneEnforcedCount: number;
    barrierGateZonesCount: number;
    permitsCount: number;
    selectedZone: ZonesOfCityState.Zone | null;
    selectedVariant: ParkOptionListState.ParkVariant | null;
    update: Flux.Updater;
    allState: Flux.Store;
}

interface ElementProps extends Props {
    clickedVariant: Maybe<ParkVariant>;
    setClickedVariant: (variant: ParkVariant) => void;
}

const ParkVariantButtonSpacing = styled.div({
    marginRight: '8px',
    marginBottom: '8px',
});

export function VariantButton({
    disabled,
    onClick,
    text,
    icon,
}: {
    disabled: boolean;
    onClick: () => void;
    text: string | JSX.Element;
    icon: JSX.Element;
}) {
    const buttonHoverStyle = {
        background: disabled ? Colors.white : Colors.lightblue,
        color: disabled ? Colors.blue : Colors.white,
    };
    return (
        <Clickable
            element="div"
            className={css({
                ...paper(disabled ? 0 : 2),
                opacity: disabled ? 0.6 : 1,
                backgroundColor: Colors.white,
                color: Colors.blue,
                borderRadius: '2px',
                display: 'flex',
                paddingRight: '24px',
                paddingLeft: '4px',
                '@media(hover: hover)': {
                    ':hover': buttonHoverStyle,
                },
                '@media(hover: none)': {
                    ':active': buttonHoverStyle,
                },
            })}
            onClick={onClick}
        >
            {icon}
            <span
                className={css({
                    paddingTop: '15px',
                    ...Typo.body,
                })}
            >
                {text}
            </span>
        </Clickable>
    );
}

const LicensePlateTransaction = (p: ElementProps): JSX.Element | null => {
    const texts = ParkCreateTexts.parkVariantSelectionTexts[p.language];
    const cityAllowsTransactions = p.zoneEnforcedCount > 0;

    const parkOptionSelected = !!p.selectedZone;
    const optionAllowsTransaction = p.selectedZone
        ? p.selectedZone.variants.indexOf(
              ZonesOfCityState.ParkOptionVariant.zoneEnforcedCheckin,
          ) > -1
        : false;
    if (
        (!parkOptionSelected && cityAllowsTransactions) ||
        optionAllowsTransaction
    ) {
        return (
            <ParkVariantButtonSpacing>
                <VariantButton
                    disabled={!optionAllowsTransaction}
                    icon={<IconBig icon={Icons48.onstreet} />}
                    text={texts.Transaction()}
                    onClick={() => {
                        if (!optionAllowsTransaction) {
                            p.setClickedVariant('licensePlateTransaction');
                        } else {
                            p.update(
                                ParkCreateActions.parkVariantSelectLicensePlateTransaction,
                            );
                        }
                    }}
                />
            </ParkVariantButtonSpacing>
        );
    } else {
        return null;
    }
};

const BadgeTransaction = (p: ElementProps): JSX.Element | null => {
    const texts = ParkCreateTexts.parkVariantSelectionTexts[p.language];
    const parkOptionSelected = !!p.selectedZone;
    const cityAllowsBadgeTransactions = p.barrierGateZonesCount > 0;
    const optionAllowsBadgeTransaction = p.selectedZone
        ? p.selectedZone.variants.indexOf(
              ZonesOfCityState.ParkOptionVariant.barrierGateCheckin,
          ) > -1
        : false;
    if (
        (!parkOptionSelected && cityAllowsBadgeTransactions) ||
        optionAllowsBadgeTransaction
    ) {
        return (
            <ParkVariantButtonSpacing>
                <VariantButton
                    disabled={!optionAllowsBadgeTransaction}
                    icon={<IconBig icon={Icons48.offstreet} />}
                    text={texts.BadgeEnterExit()}
                    onClick={() => {
                        if (optionAllowsBadgeTransaction) {
                            p.update(
                                ParkCreateActions.parkVariantSelectBadgeTransaction,
                            );
                        }
                    }}
                />
            </ParkVariantButtonSpacing>
        );
    } else {
        return null;
    }
};

const Permit = (p: ElementProps): JSX.Element | null => {
    const texts = ParkCreateTexts.parkVariantSelectionTexts[p.language];
    const parkOptionSelected = !!p.selectedZone;
    const cityPermits = p.permitsCount > 0;
    const optionAllowsPermit = p.selectedZone
        ? p.selectedZone.variants.indexOf(
              ZonesOfCityState.ParkOptionVariant.permit,
          ) > -1
        : false;
    if ((!parkOptionSelected && cityPermits) || optionAllowsPermit) {
        return (
            <ParkVariantButtonSpacing>
                <VariantButton
                    disabled={false}
                    text={texts.Permit()}
                    icon={<IconBig icon={Icons48.permit} />}
                    onClick={() => {
                        if (p.selectedZone) {
                            p.update(
                                ParkCreateActions.parkVariantSelectPermit,
                                false,
                            );
                        } else {
                            p.update(
                                ParkCreateActions.parkVariantSelectCityWidePermit,
                            );
                        }
                    }}
                />
            </ParkVariantButtonSpacing>
        );
    } else {
        return null;
    }
};

const SelectZoneNotification = (p: ElementProps) => {
    const texts = ParkCreateTexts.parkVariantSelectionTexts[p.language];
    if (!p.selectedZone && isDefined(p.clickedVariant)) {
        const arrowPosition =
            p.clickedVariant === 'licensePlateTransaction'
                ? ArrowPosition.left
                : ArrowPosition.middle;
        return (
            <InlineInfoBox
                titleCaption={texts.SelectZoneInfoTitle()}
                arrowPosition={arrowPosition}
            >
                <p>{texts.SelectZoneInfoBody(p.clickedVariant)}</p>
            </InlineInfoBox>
        );
    } else {
        return null;
    }
};

interface State {
    clickedVariant: Maybe<ParkVariant>;
}

export class ParkVariantSelection extends React.Component<Props, State> {
    constructor(p: Props) {
        super(p);
        this.state = { clickedVariant: null };
    }
    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        /*
           we have to reset the clicked variant state if something changes to
         */
        const currentId = this.props.selectedZone
            ? this.props.selectedZone.id
            : null;
        const nextId = nextProps.selectedZone
            ? nextProps.selectedZone.id
            : null;
        if (
            currentId !== nextId ||
            this.props.selectedCity.zipCode !== nextProps.selectedCity.zipCode
        ) {
            this.setState({ clickedVariant: null });
        }
    }
    render() {
        const additionalProps = {
            clickedVariant: this.state.clickedVariant,
            setClickedVariant: (clickedVariant: ParkVariant) =>
                this.setState({ clickedVariant }),
        };
        const isOffstreet =
            this.props.selectedZone?.variants.indexOf(
                ZonesOfCityState.ParkOptionVariant.zoneEnforcedCheckin,
            ) === -1;
        return (
            <div>
                <div
                    className={css({
                        display: 'flex',
                        flexWrap: 'wrap',
                    })}
                >
                    <LicensePlateTransaction
                        {...this.props}
                        {...additionalProps}
                    />
                    <Permit {...this.props} {...additionalProps} />
                    <BadgeTransaction {...this.props} {...additionalProps} />
                </div>
                <SelectZoneNotification {...this.props} {...additionalProps} />
                <PermitSlideIn
                    headerColor="darkBlue"
                    title={GeneralTexts.texts[this.props.language].Permit()}
                    onClose={() =>
                        this.props.update(
                            ParkOptionListState.Selection.setVariant,
                            null,
                        )
                    }
                    contentCssClass={css({
                        background: Colors.blue,
                        height: '100%',
                        padding: '10px 20px',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        WebkitOverflowScrolling: 'touch',
                    })}
                    allState={this.props.allState}
                    portal={SlideInPortalId.PARK_CREATE}
                    open={
                        this.props.selectedVariant === 'cityWidePermit' ||
                        this.props.selectedVariant === 'permit'
                    }
                    language={this.props.language}
                    selectedZone={this.props.selectedZone}
                    selectedCity={this.props.selectedCity}
                />
                <TransactionSlideIn
                    headerColor="darkBlue"
                    title={
                        isOffstreet ? (
                            <Localized
                                de="Parking-Details"
                                fr="Détails du parking"
                                it="Dettagli parcheggio"
                                en="Parking details"
                            />
                        ) : (
                            <Localized
                                de="Parkvorgang"
                                fr="Procédure de stationnement"
                                it="Procedura di parcheggio"
                                en="Parking procedure"
                            />
                        )
                    }
                    onClose={() =>
                        this.props.update(
                            ParkOptionListState.Selection.setVariant,
                            null,
                        )
                    }
                    portal={SlideInPortalId.PARK_CREATE}
                    open={
                        this.props.selectedVariant ===
                            'licensePlateTransaction' ||
                        this.props.selectedVariant === 'badgeTransaction'
                    }
                    selectedZone={this.props.selectedZone}
                    isOffstreet={isOffstreet}
                />
            </div>
        );
    }
}

interface PermitSlideInProps {
    allState: Flux.Store;
    language: string;
    selectedCity: CitiesState.City;
    selectedZone: ZonesOfCityState.Zone | null;
}
export const PermitSlideIn = portalSlideIn<PermitSlideInProps>(
    (p: PermitSlideInProps) => {
        return (
            <PermitBridge
                selectedZipCode={p.selectedCity.zipCode}
                selectedZoneId={p.selectedZone ? p.selectedZone.id : null}
            />
        );
    },
);

interface TransactionSlideInProps {
    selectedZone: ZonesOfCityState.Zone | null;
    isOffstreet: boolean;
}

export const TransactionSlideIn = portalSlideIn<TransactionSlideInProps>(
    (p: TransactionSlideInProps) => {
        return p.selectedZone ? (
            <div
                className={css({
                    background: Colors.blue,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px 24px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    WebkitOverflowScrolling: 'touch',
                })}
            >
                <Transaction
                    zone={p.selectedZone}
                    isOffstreet={p.isOffstreet}
                />
            </div>
        ) : null;
    },
);
