import * as superagent from 'superagent';
import { localState, selectState, Updater } from 'dg-web-shared/lib/Flux';
import {
    generateWriteStateSlice,
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { requestV2 } from '../../AsyncRequest';
import { Localized } from '../../common/components/Localized';
import { logAction } from '../../utils/ActionLog';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import { triggerAccountBalanceSliceUpdate } from './triggerAccountBalanceSliceUpdate';
import { InlineInfoBox, InlineErrorBox } from '../../ui/modals/Confirmable';
import { MainMenuItemBlock } from '../root/components/ItemBlock';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';

export const VoucherTopUp = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <MainMenuItemBlock
                onClick={() => setOpen(true)}
                title={
                    <Localized
                        de="Gutschein einlösen"
                        fr="Valider le bon"
                        it="Riscuoti buono"
                        en="Redeem voucher"
                    />
                }
            />
            <VoucherTopUpSlideIn
                onClose={() => setOpen(false)}
                open={open}
                portal={SlideInPortalId.USER_ACCOUNT}
                title={
                    <Localized
                        {...{
                            de: 'Gutschein einlösen',
                            fr: 'Valider le bon',
                            it: 'Riscuoti buono',
                            en: 'Redeem voucher',
                        }}
                    />
                }
            />
        </>
    );
};

export const VoucherTopUpSlideIn = portalSlideIn(VoucherTopUpStatelessWrapper);

function VoucherTopUpStatelessWrapper(props: Props) {
    return <VoucherTopUpSlideInContent {...props} />;
}

const VoucherTopUpSlideInContent = selectState<
    Props,
    { remoteRequest: RedeemVoucherRemoteRequestState.State }
>(
    store => ({
        remoteRequest: RedeemVoucherRemoteRequestState.get(store),
    }),
    props => {
        switch (props.remoteRequest.status) {
            case RequestStatus.NEVER_EXECUTED:
                return (
                    <VoucherTopUpForm
                        onSubmit={makeHttpPost(props.update)}
                        onCancel={props.onClose}
                    />
                );

            case RequestStatus.PENDING:
                return <Spinner />;

            case RequestStatus.ERROR:
                return (
                    <>
                        <br />
                        <InlineErrorBox>
                            <p>
                                <Localized
                                    de="Der Gutschein konnte nicht eingelöst werden."
                                    fr="Le bon ne peut pas être validé."
                                    it="Il buono non ha potuto essere riscosso."
                                    en="The voucher could not be redeemed."
                                />
                            </p>
                        </InlineErrorBox>
                    </>
                );

            case RequestStatus.SUCCESS:
                return (
                    <>
                        <br />
                        <InlineInfoBox
                            titleCaption={
                                <Localized
                                    de="Bestätigung"
                                    fr="Confirmation"
                                    it="Conferma"
                                    en="Confirmation"
                                />
                            }
                        >
                            <p>
                                <Localized
                                    de="Der Gutschein wurde eingelöst."
                                    fr="Le bon a été retiré."
                                    it="Il buono è stato riscosso."
                                    en="The voucher has been redeemed."
                                />
                            </p>
                        </InlineInfoBox>
                    </>
                );
        }
    },
    {
        willUnmount(store) {
            if (
                RedeemVoucherRemoteRequestState.get(store).status ===
                RequestStatus.SUCCESS
            ) {
                triggerAccountBalanceSliceUpdate(store.update);
            }

            store.update(RedeemVoucherRemoteRequestState.reset);
        },
    },
);

const VoucherTopUpForm = localState<
    {
        onSubmit: (voucherNumber: string) => void;
        onCancel: () => void;
    },
    { voucherNumber: string }
>({ voucherNumber: '' }, props => {
    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: props.onCancel,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Einlösen"
                        fr="Valider"
                        it="Riscuoti"
                        en="Redeem"
                    />
                ),
                onClick: props.onSubmit.bind(null, props.state.voucherNumber),
                disabled: props.state.voucherNumber === '',
            }}
        >
            <br />
            <InlineInfoBox
                titleCaption={
                    <Localized
                        de="Datenschutz-Information"
                        fr="Information sur la privacy"
                        it="Informazione sulla privacy"
                        en="Privacy info"
                    />
                }
            >
                <p>
                    <Localized
                        de="Der Sponsor des Gutscheins (auf dem Gutschein angegeben) erhält eine Einlösungsbestätigung, die folgende Daten über Ihr Parkingpay-Konto enthält:"
                        fr="Le sponsor du bon (indiqué sur le bon) recevra une confirmation de validation contenant les données suivantes concernant votre compte Parkingpay:"
                        it="Lo sponsor del buono (indicato sullo stesso) riceverà una conferma della riscossione, contenente i seguenti dati relativi al suo conto Parkingpay:"
                        en="The sponsor of the voucher (shown on the voucher) will receive a confirmation of redemption, containing the following data about your Parkingpay account:"
                    />
                    <ul>
                        <li>
                            <Localized
                                de="Kontonummer"
                                fr="numèro de compte"
                                it="numero di conto"
                                en="account number"
                            />
                        </li>
                        <li>
                            <Localized
                                de="Kontoinhaber"
                                fr="titulaire du compte"
                                it="titolare del conto"
                                en="account owner"
                            />
                        </li>
                    </ul>
                </p>
            </InlineInfoBox>
            <TextField
                onChange={voucherNumber => {
                    props.setState({ voucherNumber });
                }}
                value={props.state.voucherNumber}
                labelText={
                    <Localized
                        de="Gutschein Nummer"
                        fr="Numéro du bon"
                        it="Codice del buono"
                        en="Voucher number"
                    />
                }
                context={InputContext.form}
            />
        </SlideInForm>
    );
});

interface Props {
    onClose: () => void;
}

function makeHttpPost(update: Updater): (voucherNumber: string) => void {
    return voucherNr => {
        update(store => logAction(store, 'topup-redeem-voucher', voucherNr));
        update(
            requestV2(
                () =>
                    superagent
                        .post('/ui-api/customer-account/deposits/voucher')
                        .send({
                            voucherNr,
                        }),
                RedeemVoucherRemoteRequestState.setResponse,
            ),
        );
    };
}

namespace RedeemVoucherRemoteRequestState {
    export type State = ServerRequestState<string>;

    export const { get, setResponse, reset } = generateWriteStateSlice<string>({
        key: 'voucher-top-up-redeem-remote-request',
    });
}
