import { useEffect, useRef } from 'react';
import {
    RequestStatus,
    useServerErrorEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { PaymentAbortionReason } from './QuickCheckoutTypes';
import { useNavigate } from 'react-router-dom';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import { Spinner } from 'dg-web-shared/ui/Spinner';

type PaymentState =
    | 'INITIALIZED'
    | 'CREATED'
    | 'AUTHORIZED'
    | 'SETTLED'
    | 'REFUNDED'
    | 'ABORTED';

type PaymentStateResponse = {
    state: PaymentState;
    paymentAbortionReason?: string;
};

export const QuickCheckoutPaymentCallback = ({
    paymentAttemptId,
    ticketId,
    onPaymentSuccessful,
    onPaymentAborted,
    onPaymentRejected,
}: {
    paymentAttemptId: string;
    ticketId: string;
    onPaymentSuccessful: () => void;
    onPaymentAborted: () => void;
    onPaymentRejected: () => void;
}) => {
    const [paymentServerState, fetchPaymentServerState] = useServerFetch<
        PaymentStateResponse,
        { paymentAttemptId: string }
    >(
        ({ paymentAttemptId: id }) => ({
            url: `/ui-api/quickcheckout/payment/${id}/state`,
        }),
        { paymentAttemptId },
    );

    const navigate = useNavigate();
    const gotoTicketPage = (ticketId: string) =>
        navigate(`/quickcheckout/ticket/${ticketId}`);

    const paymentAborted = () => {
        onPaymentAborted();
        gotoTicketPage(ticketId);
    };
    const paymentRejected = () => {
        onPaymentRejected();
        gotoTicketPage(ticketId);
    };
    const paymentSuccessful = () => {
        onPaymentSuccessful();
        gotoTicketPage(ticketId);
    };

    const timeoutId = useRef<number>();
    useEffect(() => {
        if (paymentServerState.status === RequestStatus.SUCCESS) {
            switch (paymentServerState.data?.state) {
                case 'INITIALIZED':
                case 'CREATED':
                    timeoutId.current = window.setTimeout(
                        () => fetchPaymentServerState(),
                        1000,
                    );
                    break;
                case 'SETTLED':
                    paymentSuccessful();
                    break;
                default:
                    if (
                        paymentServerState.data.paymentAbortionReason &&
                        paymentServerState.data.paymentAbortionReason ===
                            PaymentAbortionReason[PaymentAbortionReason.FAILURE]
                    ) {
                        paymentRejected();
                    } else {
                        paymentAborted();
                    }
            }
        }

        return () => {
            timeoutId.current !== undefined &&
                window.clearTimeout(timeoutId.current);
        };
    }, [paymentServerState]);

    useServerErrorEffect(paymentServerState, () => {
        paymentAborted();
    });

    return <Spinner />;
};
