import * as WriteStateSlice from '../../../common/state/WriteStateSlice';
import * as Flux from 'dg-web-shared/lib/Flux';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import { Duration, TimeSelectionType } from 'dg-web-shared/dto/PermitTimeUnit';

import { EntityData } from '../shared/EntityData';
import { ClearanceRequestCustomField } from '../../../../../operator-app/src/common/models/ClearanceRequest';

export namespace TypeData {
    export interface Zone {
        id: number;
        extZoneCode: number;
        name: string;
        zipCode: string;
        city: string;
        offstreet: boolean;
    }

    export interface Type {
        permitTypeId: number;
        description: string;
        operatorId: number;
        operatorName: string;
        operatorContactDetails: string | null;
        maxEntities: number;
        timeSelectionType: TimeSelectionType;
        selectZones: 'MULTIPLE_CHOICE' | 'FIXED_ZONES' | 'SINGLE_CHOICE';
        entityData: EntityData[];
        clearanceRequestCustomFields: ClearanceRequestCustomField[];
        isOffstreet: boolean;
        zones: Zone[];
        purchaseState: 'onlyCounter' | 'purchaseable';
    }
}

export namespace DateValidation {
    export interface DateValidation {
        validFrom: string | null;
        fromRange: string;
        addInfo: string | null;
        selectionType: 'duration' | 'from_to' | undefined;
        error: DateValidationError | undefined;
        showDateChangeWarning: boolean;
    }

    interface OutsideClearanceRangeError extends DateValidation {
        error: DateValidationError.OUTSIDE_CLEARANCE_RANGE;
        entityId: Maybe<number>;
    }

    export interface WithDuration extends DateValidation {
        selectionType: 'duration';
        allowedDurations: Duration[];
    }

    export interface WithFromTo extends DateValidation {
        selectionType: 'from_to';
        toRange: string;
    }

    export type Data = OutsideClearanceRangeError | WithDuration | WithFromTo;

    export type State = ContextualServerStateSlice.ContextualState<Data>;

    const enum DateValidationError {
        OUTSIDE_CLEARANCE_RANGE = 'OUTSIDE_CLEARANCE_RANGE',
    }
}

export function resetAllSlices(store: Flux.Store): void {
    InfoBlock.reset(store);
    Purchase.reset(store);
}

export class WhitelistApplicationStateSlice extends WriteStateSlice.StateSlice {
    key(): string {
        return 'park-create-WhitelistApplicationZonePermitPurchaseState';
    }
}

export namespace Purchase {
    export type State = WriteStateSlice.State;
    export const { get, setResponse, reset } = WriteStateSlice.generate(
        'park-create-PurchasePermitPurchaseState',
    );
}

export namespace InfoBlock {
    export interface State {
        forcePurchase: boolean;
    }

    export const { get, stateWrite, reset } = Flux.generateState<State>(
        'park-create-InfoBlockZonePermitPurchaseState',
        { forcePurchase: false },
    );
}
