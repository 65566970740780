import { getOrElse, Maybe } from './MaybeV2';
import * as String from './String';
import { stripNonNumericCharactersAllowPointAndComma } from './StringConversions';

export const autoFormat = (value: Maybe<string>): string =>
    stripNonNumericCharactersAllowPointAndComma(
        getOrElse(value, '').replace(',', '.') as string,
    );

export enum MonetaryValueState {
    INVALID_AMOUNT,
}

export const getStates = (value: string): MonetaryValueState[] => {
    const states: MonetaryValueState[] = [];
    if (!String.Validators.isNumeric(value)) {
        states.push(MonetaryValueState.INVALID_AMOUNT);
    }

    if (value.split('.').length > 2) {
        // if more than 1 point
        states.push(MonetaryValueState.INVALID_AMOUNT);
    }

    if (value.split('.').length === 2) {
        // if we have decimals
        const decimals = value.split('.')[1];

        if (decimals.length > 2) {
            states.push(MonetaryValueState.INVALID_AMOUNT);
        }

        if (decimals.length === 2 && +decimals % 5 !== 0) {
            // if decimals don't divide by 5
            states.push(MonetaryValueState.INVALID_AMOUNT);
        }
    }

    return states;
};
