import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { Message } from 'dg-web-shared/lib/Localized';

export type PermitReceiptSuccessResponse = {
    contractId: string;
    externalId: string;
    startTime: string;
    endTime: string;
    licensePlateNr: string;
    licensePlateType: LicensePlateType;
    licensePlateCountry: string;
    amount: number;
    name: string;
    operatorName: string;
    zones: Zone[];
    additionalInfo: string | null;
    operatorContactDataMarkdown: string | null;
    permitIsChangeable: boolean;
    operatorEditPolicyText: Message;
    receiptSentToEmail: string | null;
};
export type PermitReceiptRequestContext = { externalId: string };
export type PermitReceiptErrorResponse = { state: PermitReceiptErrorState };

type Zone = {
    id: number;
    name: string;
    zipCode: string;
    city: string;
    extZoneCode: number;
    needsPermitToStartTransaction: number;
};
export const enum PermitReceiptErrorState {
    NOT_FOUND = 'NOT_FOUND',
    NOT_ALLOWED = 'NOT_ALLOWED',
}
