import { generateState, Store } from 'dg-web-shared/lib/Flux';
import { getBridge } from '../../Native';
import { CurrentLoginState } from './CurrentLoginState';

export namespace FCMTokenState {
    export interface State {
        token: string | null;
        status: boolean | null;
    }

    const generated = generateState<State>('fcm-token-sate', () => {
        try {
            const bridge = getBridge();
            if (bridge && bridge.requestFCMToken) {
                bridge.requestFCMToken();
            }
        } catch (e) {
            // noop
        }

        return {
            token: null,
            status: null,
        };
    });

    export const get = generated.get;
    export const stateWrite = (store: Store, state: Partial<State>): string => {
        CurrentLoginState.recheckFcmToken(store, true);
        return generated.stateWrite(store, state);
    };
}
