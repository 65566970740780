import { useStore } from 'dg-web-shared/lib/Flux';
import { getLicensePlateStates } from 'dg-web-shared/lib/LicensePlateValidation';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import { portalSlideIn } from '../root/components/PortalSlidein';
import {
    EditableLicensePlate,
    LicensePlateEditFields,
} from './VehicleLicensePlateEdit';
import { VehicleSlideInForm } from './VehicleSlideInForm';
import {
    CreateVehicle,
    UpdateVehicle,
    Vehicle,
    Vehicles,
    VehiclesMeta,
} from './VehicleState';
import { InlineAlertBox } from '../../ui/modals/Confirmable';
import { VehicleVisibility } from './VehicleVisibility';
import * as LoginsState from '../logins/state/LoginsState';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { useState } from 'react';
import {
    LabeledToggle,
    ToggleType,
} from '../../../../tb-ui/toggle/LabeledToggle';
import { Infobox, InfoboxText } from '../../account-setup/components/Infobox';
import { updateTransactionlistWarnings } from '../../transactions-list/state/TransactionsListState';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';

interface Props {
    country?: string | null;
    type?: LicensePlateType | null;
    licensePlateNr?: string | null;
    onSuccess: (res: UpdateVehicle.CreateResponse, data: CreateVehicle) => void;
    loginId?: number | null;
}

export const VehicleAdd = portalSlideIn<Props>(props => {
    const [description, setDescription] = useState('');
    const [loginId, setLoginId] = useState(props.loginId || null);
    const [editableLicensePlate, setEditableLicensePlate] =
        useState<EditableLicensePlate>({
            licensePlateCountryId: props.country || 'CH',
            licensePlateNr: props.licensePlateNr || '',
            licensePlateType: props.type || LicensePlateType.CAR,
        });
    const [showDescriptionErrors, setShowDescriptionErrors] = useState(false);
    const [showLicensePlateErrors, setShowLicensePlateErrors] = useState(false);
    const [addBadgeToVehicle, setAddBadgeToVehicle] = useState(false);
    const refetchTransactionList = useTransactionListRefetch();

    const { storeState, update } = useStore(store => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            vehicleMeta: VehiclesMeta.get(store),
            vehicles: Vehicles.get(store),
            selectedVehicle: UpdateVehicle.get(store),
            logins: new LoginsState.StateSlice(store).state,
        };
    });

    const considerOperatorNames = runningLpContractOperatorNames(
        storeState.vehicles.data,
    );

    return (
        <VehicleSlideInForm
            disabled={
                storeState.selectedVehicle.status === RequestStatus.PENDING
            }
            onCancel={props.onClose}
            disabledPrimary={
                getLicensePlateStates(editableLicensePlate).length > 0
            }
            onSave={() => {
                setShowDescriptionErrors(true);
                setShowLicensePlateErrors(true);
                update(store => {
                    UpdateVehicle.post(
                        store,
                        {
                            licensePlateNr: editableLicensePlate.licensePlateNr,
                            loginId: loginId,
                            type: editableLicensePlate.licensePlateType,
                            country: editableLicensePlate.licensePlateCountryId,
                            description: description,
                            addBadgeToVehicle: addBadgeToVehicle,
                        },
                        (res, data) => {
                            refetchTransactionList();
                            updateTransactionlistWarnings(update);
                            props.onClose();
                            props.onSuccess(res, data);
                        },
                    );
                    return 'post-UpdateVehicle';
                });
            }}
            selectedVehicle={storeState.selectedVehicle}
        >
            {considerOperatorNames.length > 0 && (
                <ExistingPermitsWarning operatorNames={considerOperatorNames} />
            )}

            <TextField
                labelText={
                    <Localized
                        de="Beschreibung"
                        fr="Description"
                        it="Descrizione"
                        en="Description"
                    />
                }
                value={description}
                onBlur={() => setShowDescriptionErrors(true)}
                errorText={
                    showDescriptionErrors && description.length === 0 ? (
                        <Localized
                            de="Die Beschreibung darf nicht leer sein."
                            fr="La description ne peut pas être vide."
                            it="La descrizione non può essere vuota."
                            en="The description cannot be empty."
                        />
                    ) : (
                        ''
                    )
                }
                onChange={value => {
                    setDescription(value);
                }}
            />
            <LicensePlateEditFields
                portal={props.portal}
                lang={storeState.settings.language}
                licensePlateCountries={
                    storeState.vehicleMeta.data.licensePlateCountries
                }
                licensePlateTypes={
                    storeState.vehicleMeta.data.licensePlateTypes
                }
                licensePlate={editableLicensePlate}
                stateWrite={setEditableLicensePlate}
                updateVehicle={storeState.selectedVehicle}
                showLicensePlateErrors={showLicensePlateErrors}
                onLicensePlateBlur={() => setShowLicensePlateErrors(true)}
                onClose={() => {
                    update(store => {
                        UpdateVehicle.reset(store);
                        props.onClose();
                        return 'reset-vehicle';
                    });
                }}
            />
            <div>
                <LabeledToggle
                    type={ToggleType.checkbox}
                    label={
                        <Localized
                            de="Badge zum Fahrzeug hinzufügen"
                            fr="Ajouter un badge au véhicule"
                            it="Aggiungere un badge al veicolo"
                            en="Add a badge to the vehicle"
                        />
                    }
                    onClick={() => setAddBadgeToVehicle(!addBadgeToVehicle)}
                    selected={addBadgeToVehicle}
                />
                <Infobox>
                    <InfoboxText>
                        <Localized
                            de="Der Badge ist eine kontaktlose Karte, die in Parkhäusern mit Schranken verwendet werden kann."
                            fr="Le badge est une carte sans contact qui peut être utilisée dans les parkings avec barrières."
                            it="Il badge è una carta senza contatto che può essere utilizzata nei parcheggi con barriere."
                            en="The badge is a contactless card that can be used in car parks with barriers."
                        />
                    </InfoboxText>
                </Infobox>
            </div>
            {storeState.logins.data.length > 1 && (
                <VehicleVisibility
                    vehicle={null}
                    loginId={loginId}
                    setLoginId={loginId => setLoginId(loginId)}
                />
            )}
        </VehicleSlideInForm>
    );
});

function ExistingPermitsWarning(props: { operatorNames: string[] }) {
    const namesText = props.operatorNames.join(', ');

    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Vorhandene Bewilligungen"
                    fr="Autorisations existantes"
                    it="Autorizzazioni esistenti"
                    en="Existing permits"
                />
            }
        >
            <p>
                <Localized
                    de={
                        <div>
                            Mindestens eines der vorhandenen Fahrzeuge ist bei
                            den folgenden Betreibern (z.B. für eine Bewilligung
                            oder einen Sondertarif) registriert:{' '}
                            <b>{namesText}</b>.
                            <br />
                            <br />
                            Das neue Fahrzeug, das Sie hier unten anlegen, wird{' '}
                            <b>NICHT automatisch hinzugefügt</b>.
                            <br />
                            <br />
                            Falls erforderlich, wenden Sie sich bitte an die
                            oben genannten Betreiber.
                        </div>
                    }
                    fr={
                        <div>
                            Au moins un des véhicules existants est enregistré
                            (par exemple, pour une autorisation ou un tarif
                            spécial) auprès des exploitants suivants:{' '}
                            <b>{namesText}</b>.
                            <br />
                            <br />
                            Le nouveau véhicule que vous créez ici va{' '}
                            <b>PAS être ajouté automatiquement</b>.
                            <br />
                            <br />
                            Si nécessaire, nous vous prions donc de contacter
                            les exploitants mentionnés ci-dessus.
                        </div>
                    }
                    it={
                        <div>
                            Almeno uno dei veicoli esistenti è registrato (p.es.
                            per un&apos;autorizzazione o per una tariffa
                            agevolata) presso i seguenti gestori:{' '}
                            <b>{namesText}</b>.
                            <br />
                            <br />
                            Il nuovo veicolo che inserirà qui di seguito{' '}
                            <b>NON verrà aggiunto automaticamente</b>.
                            <br />
                            <br />
                            Se necessario, la preghiamo quindi di contattare i
                            gestori sopraindicati.
                        </div>
                    }
                    en={
                        <div>
                            At least one of the existing vehicles is registered
                            (e.g. for a permit or a special tariff) by the
                            following operators: <b>{namesText}</b>.
                            <br />
                            <br />
                            The new vehicle you will now create, will{' '}
                            <b>NOT automatically be added</b>.
                            <br />
                            <br />
                            If necessary, please contact the above mentioned
                            operator.
                        </div>
                    }
                />
            </p>
        </InlineAlertBox>
    );
}

function runningLpContractOperatorNames(vehicles: Vehicle[]): string[] {
    return Array.from(
        vehicles
            .map(v => v.runningLpContractOperatorNames)
            .reduce((agg, names) => {
                names.forEach(name => {
                    agg.add(name);
                });

                return agg;
            }, new Set<string>()),
    ).sort();
}
