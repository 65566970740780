import { LabeledText } from '../../../../tb-ui/typo/LabeledText';
import { InputContext } from '../../../../tb-ui/inputs/InputContext';
import { JsxMessage, Message } from 'dg-web-shared/lib/Localized';
import {
    IndentedList,
    IndentedListData,
} from 'dg-web-shared/common/components/IndentedList';

export function IndentedListParkingPay({
    title,
    entries,
    context,
}: {
    title: string | JsxMessage | Message;
    entries: IndentedListData;
    context?: InputContext;
}) {
    return (
        <LabeledText
            label={title}
            key="zones"
            context={context ?? InputContext.regular}
        >
            <IndentedList entries={entries} />
        </LabeledText>
    );
}
