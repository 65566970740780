import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import { Localized } from '../../common/components/Localized';
import { css } from '@emotion/css';
import { Typo } from '../../style/typo';
import { PermitDetail } from '../state/TransactionsListState';
import { DateTime } from 'luxon';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats';
import { Colors } from 'dg-web-shared/ui/vars';
import { Icon16 } from 'dg-web-shared/ui/icons/Icon';

const secondaryText = {
    fontSize: '15px',
    lineHeight: '16px',
    letterSpacing: '0.015em',
};

export function LicensePlateEntity({
    licensePlate,
    permit,
}: {
    licensePlate: PermitDetail.PermitDetailLicensePlate;
    permit: PermitDetail.Permit;
}) {
    return (
        <div
            className={css({
                alignItems: 'flex-end',
                display: 'flex',
            })}
        >
            <div
                className={css({
                    marginRight: '6px',
                    lineHeight: '16px',
                })}
            >
                {licensePlate.licensePlateNr}
            </div>
            <div
                className={css({
                    padding: '0 3px',
                    minWidth: 0, // min with to make div shrink below size of text
                    overflow: 'hidden',
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 0, // force to be the only shrinking element
                    textOverflow: 'ellipsis',
                    ...Typo.caption,
                })}
            >
                {licensePlate.remarks || ''}
            </div>

            {licensePlate.explicitCheckin ? (
                <ExplicitCheckinInfoData
                    explicitCheckin={licensePlate.explicitCheckin}
                />
            ) : (
                <ActionRequiredInfoData
                    licensePlate={licensePlate}
                    permit={permit}
                />
            )}
        </div>
    );
}

function ExplicitCheckinInfoData({
    explicitCheckin,
}: {
    explicitCheckin:
        | PermitDetail.ExplicitCheckinNotRunning
        | PermitDetail.ExplicitCheckinRunning;
}) {
    if (explicitCheckin.running) {
        return (
            <>
                <div className={css({ width: '26px', paddingRight: '10px' })}>
                    <div className={css({ color: '#468033' })}>
                        <Icon16 icon={Icons16.indicatorCircle} />
                    </div>
                </div>
                <div className={css(secondaryText)}>
                    <Localized
                        de="bis "
                        fr="jusqu'à "
                        it="fino alle "
                        en="until "
                    />
                    {DateTime.fromISO(explicitCheckin.runningUntil).toFormat(
                        LuxonDateStringFormats.HOUR_MINUTE,
                    )}
                </div>
            </>
        );
    } else if (explicitCheckin.checkinPossible) {
        return (
            <div className={css(Typo.action)}>
                <Localized
                    de="Aktivieren"
                    fr="Activer"
                    it="Attivare"
                    en="Activate"
                />
            </div>
        );
    } else {
        return null;
    }
}

function ActionRequiredInfoData({
    licensePlate,
    permit,
}: {
    licensePlate: PermitDetail.PermitDetailLicensePlate;
    permit: PermitDetail.Permit;
}) {
    if (licensePlate.isMissingVehicleButRequired) {
        return (
            <div className={css({ ...Typo.action, color: Colors.error })}>
                <Localized
                    de="Fahrzeug hinzufügen"
                    fr="Ajouter véhicule"
                    it="Aggiungi veicolo"
                    en="Add vehicle"
                />
            </div>
        );
    }

    if (licensePlate.isMissingBadgeButRequired) {
        return (
            <div
                className={css({
                    ...Typo.action,
                    color: permit.badgeRequired ? Colors.error : Colors.blue,
                })}
            >
                <Localized
                    de="Badge hinzufügen"
                    fr="Ajouter un badge"
                    it="Aggiungi badge"
                    en="Add badge"
                />
            </div>
        );
    }

    return null;
}
