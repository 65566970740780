import * as Flux from 'dg-web-shared/lib/Flux';
import * as HttpResponse from 'dg-web-shared/lib/HttpResponse';
import { getOrElse } from 'dg-web-shared/lib/MaybeV2';
import { Gender } from '../../account-setup/state/AccountSetupState';
import * as Http from '../../api/Http';
import * as AsyncRequest from '../../AsyncRequest';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as WriteStateSlice from '../../common/state/WriteStateSlice';
import * as LoginsState from './state/LoginsState';
import { GetLoginData } from './state/LoginsState';

type LoginRole = CurrentLoginState.LoginRole;

export namespace EditFormState {
    export function httpPutLogin(args: {
        server: GetLoginData;
        edit: State;
        onSuccess: () => void;
    }): Flux.Write {
        const { server, edit } = args;
        return AsyncRequest.request(
            Http.putLogin({
                id: server.id,
                email: getOrElse(edit.email, server.email),
                roleId: getOrElse(edit.role, server.roleId),
                firstName: getOrElse(edit.firstName, server.firstName),
                lastName: getOrElse(edit.lastName, server.lastName),
                gender: getOrElse(edit.gender, server.gender),
                deactivated: getOrElse(edit.deactivated, server.deactivated),
            }),

            (store: Flux.Store, res: HttpResponse.Response): void => {
                new ResponseStateSlice(store).writeResponse(res);

                if (res.statusCode.cls.success) {
                    new LoginsState.StateSlice(store).reset();
                    new ResponseStateSlice(store).reset();
                    new StateSlice(store).reset();
                    args.onSuccess();
                }
            },
        );
    }

    export function writeEmail(email: string): Flux.Write {
        return (store: Flux.Store): void => {
            new StateSlice(store).setEmail(email);
        };
    }
    export function writeGender(gender: Gender): Flux.Write {
        return (store: Flux.Store): void => {
            new StateSlice(store).setGender(gender);
        };
    }

    export function writeRole(role: LoginRole): Flux.Write {
        return (store: Flux.Store): void => {
            new StateSlice(store).setRole(role);
        };
    }

    export function writeDeactivated(deactivated: boolean): Flux.Write {
        return (store: Flux.Store): void => {
            new StateSlice(store).setDeactivated(deactivated);
        };
    }

    export function writeFirstName(firstName: string): Flux.Write {
        return (store: Flux.Store): void => {
            new StateSlice(store).setFirstName(firstName);
        };
    }

    export function writeLastName(lastName: string): Flux.Write {
        return (store: Flux.Store): void => {
            new StateSlice(store).setLastName(lastName);
        };
    }

    interface State {
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        role: LoginRole | null;
        gender: Gender | null;
        deactivated: boolean | null;
    }

    export class StateSlice extends Flux.StateSlice<State> {
        key(): string {
            return 'logins-login-EditState';
        }
        sideEffects(): void {
            return;
        }

        getInitialState(): State {
            return {
                email: null,
                firstName: null,
                lastName: null,
                role: null,
                deactivated: null,
                gender: null,
            };
        }

        setEmail(email: string): void {
            this.set((s: State): State => {
                s.email = email;
                return s;
            });
        }
        setGender(gender: Gender): void {
            this.set((s: State): State => {
                s.gender = gender;
                return s;
            });
        }
        setFirstName(firstName: string): void {
            this.set((s: State): State => {
                s.firstName = firstName;
                return s;
            });
        }

        setLastName(lastName: string): void {
            this.set((s: State): State => {
                s.lastName = lastName;
                return s;
            });
        }

        setRole(role: LoginRole): void {
            this.set((s: State): State => {
                s.role = role;
                return s;
            });
        }

        setDeactivated(deactivated: boolean): void {
            this.set((s: State): State => {
                s.deactivated = deactivated;
                return s;
            });
        }
    }

    export class ResponseStateSlice extends WriteStateSlice.StateSlice {
        key(): string {
            return 'account-login-EditResponseState';
        }
    }
}
