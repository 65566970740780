import * as Date from '../utils/Date';
import { DateTime } from 'luxon';

export function getPermitReceiptPdf(args: { id: number; key: string }): string {
    return `/ui-api/customer-account/transactions-list/permit-detail/${args.id}/pdf2?key=${args.key}`;
}

export interface PermitPostData {
    permitTypeId: number;
    licensePlateIds: number[];
    badgeIds: number[];
    from: DateTime | null;
    to: DateTime | null;
    time: number | null;
    durationType: string | null;
    zoneIds: number[];
    useReminder: boolean;
}

export interface AuthZonePermitPurchaseRequest {
    permitTypeId: number;
    licensePlateIds: number[];
    badgeIds: number[];
    from: string | null;
    to: string | null;
    time: number | null;
    durationType: string | null;
    zoneIds: number[];
    useReminder: boolean;
}

export function prepareForTransmission(
    descriptor: PermitPostData,
): AuthZonePermitPurchaseRequest {
    return {
        permitTypeId: descriptor.permitTypeId,
        licensePlateIds: descriptor.licensePlateIds,
        badgeIds: descriptor.badgeIds,
        from: descriptor.from
            ? Date.Formatter.isoString(descriptor.from)
            : null,
        to: descriptor.to
            ? Date.Formatter.isoYearMonthDay(descriptor.to)
            : null,
        time: descriptor.time,
        durationType: descriptor.durationType,
        zoneIds: descriptor.zoneIds,
        useReminder: descriptor.useReminder,
    };
}

export interface LicensePlate {
    licensePlateNr: string;
    countryId: string;
    typeId: number;
}
