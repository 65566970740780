import { DateTime } from 'luxon';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice';
import { ClearanceRequestCustomField } from '../../../../../operator-app/src/common/models/ClearanceRequest';
import * as Http from '../../../api/Http';
import { requestV2 } from '../../../AsyncRequest';
import { LicensePlate } from '../shared/EntityData';

export namespace ParkTransaction {
    export interface Zone {
        permitZoneId: number;
        zoneCode: string;
        name: string;
        zipCode: string;
        cityName: string;
        operatorName: string;
        operatorContactDetails: string | null;
        operatorParkingpayContactDetails: string | null;
        operatorId: number;
        licensePlateData: LicensePlate[];
        clearancePermitTypeId: number | null | undefined;
        allowedHours: number[];
        clearanceRequestCustomFields: ClearanceRequestCustomField[];
        additionalPaymentProhibited: boolean;
    }

    interface Context {
        permitZoneId: number;
    }
    export type State = ContextualServerStateSlice.ContextualState<Zone>;

    export const { get, refetchSameContext, getState } =
        ContextualServerStateSlice.generateContextualState<Context, Zone>({
            key: 'TransactionState-StartStopZone',
            requestGenerator: Http.getCheckinZone,
            request: requestV2,

            parseBody: (body: any): Zone =>
                Object.assign({}, body, {
                    licensePlateData: body.licensePlateData.map((lp: any) =>
                        lp.previousSignificantParkEnd
                            ? Object.assign({}, lp, {
                                  previousSignificantParkEnd: DateTime.fromISO(
                                      lp.previousSignificantParkEnd,
                                  ),
                              })
                            : lp,
                    ),
                }),
        });
}

export namespace OffstreetZone {
    export interface Zone {
        zipCode: string;
        city: string;
        name: string;
        operatorName: string;
        entryLimit: number | null;
        publicLpr: boolean;
        publicBadge: boolean;
        allowTicketPayment: boolean;
        customerCommission: boolean | undefined;
    }

    interface Context {
        zoneId: number;
    }

    export type State = ContextualServerStateSlice.ContextualState<Zone>;

    export const { get, refetchSameContext, getState } =
        ContextualServerStateSlice.generateContextualState<Context, Zone>({
            key: 'TransactionState-OffstreetZone',
            requestGenerator: Http.getOffstreetZoneTransactionInfo,
            request: requestV2,
            parseBody: (body: any): Zone => body,
        });
}
