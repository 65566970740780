import {
    PaymentCategory,
    PaymentRequestMode,
} from '../../../common/payment/Payment';
import { v4 as randomUuid } from 'uuid';
import {
    PermitPostData,
    prepareForTransmission,
} from '../../../api/PermitHttp';
import { PaymentStateType, ResponseState } from './PaymentBase';
import {
    RequestStatus,
    ServerRequestState,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    PermitPurchaseRequest,
    PermitPurchaseResponse,
    PermitPurchaseResponseType,
} from '../../../park-create/components/permit/PermitBuy';
import { IDEMPOTENCE_TOKEN_HTTP_HEADER_NAME } from '../../../AsyncRequest';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import {
    navigateToPermitPurchaseConfirmation,
    refreshStateSlicesOnSuccessfulPermitPurchase,
} from '../../../park-create/actions/PermitActions';
import { useTransactionListRefetch } from '../../../transactions-list/TransactionsListContext';
import { DEVICE_UUID } from '../../../utils/ActionLog';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';

export namespace PermitPaymentRequest {
    export interface PermitPaymentRequestPayload {
        mode: PaymentRequestMode;
        permitData: PermitPostData;
        language: string;
        paymentCategory: PaymentCategory;
        amount: number | null;
        useExistingAlias: boolean;
    }

    export function use(): [
        ResponseState,
        (p: PermitPaymentRequestPayload) => void,
    ] {
        const update = useUpdate();
        const language = useParkingpayLanguage();
        const refetchTransactionList = useTransactionListRefetch();

        const [permitPurchaseState, purchasePermitNow] = useServerWrite<
            PermitPurchaseRequest,
            PermitPurchaseResponse,
            never
        >(() => {
            const headers: { [p: string]: string } = {};
            headers[IDEMPOTENCE_TOKEN_HTTP_HEADER_NAME] = randomUuid();
            return {
                url: '/ui-api/customer-account/permits/buy',
                headers: headers,
            };
        });

        useServerSuccessEffect(permitPurchaseState, data => {
            if (data.type === PermitPurchaseResponseType.COMPLETE) {
                refetchTransactionList();
                update(s => {
                    refreshStateSlicesOnSuccessfulPermitPurchase(s);
                    navigateToPermitPurchaseConfirmation(
                        s,
                        data.contractId,
                        true,
                    );
                    return 'refresh-state';
                });
            } else if (data.type === PermitPurchaseResponseType.REDIRECT) {
                window.location.href = data.redirectUri;
            }
        });

        return [
            deriveResponseState(permitPurchaseState),
            (p: PermitPaymentRequestPayload) => {
                purchasePermitNow({
                    permitRequest: prepareForTransmission(p.permitData),
                    paymentCategory:
                        p.paymentCategory === PaymentCategory.UNKNOWN
                            ? null
                            : p.paymentCategory,
                    amount: p.amount,
                    baseUri: `${window.location.protocol}//${window.location.host}`,
                    deviceUUID: DEVICE_UUID,
                    language: language,
                    useExistingAlias: p.useExistingAlias,
                });
            },
        ];
    }
}

function deriveResponseState(
    state: ServerRequestState<PermitPurchaseResponse, never>,
): ResponseState {
    if (state.status === RequestStatus.SUCCESS) {
        return {
            state: PaymentStateType.DIRECT_TO_ALIAS_SUCCESS,
        };
    } else if (state.status === RequestStatus.ERROR) {
        return {
            state: PaymentStateType.GENERIC_ERROR,
            httpStatusCode: state.httpStatusCode,
            apiErrorCode: null,
        };
    } else if (state.status === RequestStatus.PENDING) {
        return {
            state: PaymentStateType.PENDING,
        };
    } else {
        return {
            state: PaymentStateType.NEVER_EXECUTED,
        };
    }
}
