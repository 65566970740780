import { css } from '@emotion/css';

import { useState } from 'react';
import { Updater, useStore } from 'dg-web-shared/lib/Flux';
import { languageFromString } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../../common/components/Localized';
import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import * as SettingsState from '../../../common/state/SettingsState';
import { Colors } from 'dg-web-shared/ui/vars';
import {
    PaymentCategory,
    PaymentRequestMode,
} from '../../../common/payment/Payment';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../../root/components/PortalSlidein';
import { AmountLabel, AmountsChoiceGrid } from './AmountsChoiceGrid';
import { TopUpPaymentLogic } from './TopUpPaymentLogic';
import { ProceedButton } from './ProceedButton';
import { DirectPayButton } from './DirectPayButton';
import { DirectPayWarningAlertBox } from './DirectPayWarning';
import {
    InlineAlertBox,
    InlineErrorBox,
    ModalSuccessBox,
} from '../../../ui/modals/Confirmable';
import { PaymentStateType, ShowPaymentState } from './PaymentBase';
import { TopUpPaymentRequest } from './TopUpPaymentRequest';
import {
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { AdditionalInfo, LimitData, LimitType } from '../BankTransferTopUp';
import { ErrorBlock } from '../../root/components/ErrorBlock';
import { GenericErrorBody } from '../../../ui/modals/HandleHttpStati';
import * as MetaServerState from '../../meta/state/MetaServerState';
import { CustomerAccountType } from '../../../api/CustomerAccountType';
import {
    PaymentOriginInfoType,
    PaymentOriginType,
} from '../../../park-create/components/add-payment-method/AddPaymentMethodParkCreateMenu';
import { Typo } from '../../../style/typo';
import topUpAmounts = TopUpPaymentLogic.topUpAmounts;
import PaymentRequestPayload = TopUpPaymentRequest.PaymentRequestPayload;
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import { useServerFetch } from '../../../hooks/ServerStateHooks';
import { Spinner } from 'dg-web-shared/ui/Spinner';

function TopUpPayment({
    paymentCategory,
    paymentRequestMode,
    onDirectEPaymentSuccess,
    onClose,
    paymentOriginInfo,
}: {
    portal: SlideInPortalId;
    paymentCategory: PaymentCategory;
    paymentRequestMode: PaymentRequestMode;
    onDirectEPaymentSuccess: () => void;
    onClose: () => void;
    paymentOriginInfo: PaymentOriginInfoType;
}) {
    return (
        <TopUp
            mode={paymentRequestMode}
            category={paymentCategory}
            onDirectEpaymentSuccess={() => {
                onClose();
                onDirectEPaymentSuccess();
            }}
            onClose={onClose}
            paymentOriginInfo={paymentOriginInfo}
        />
    );
}

export const TopUpPaymentSlideIn = portalSlideIn(TopUpPayment);

function createTopupPaymentPayload(
    payload: Omit<PaymentRequestPayload, 'uiPaymentOrigin' | 'ticketId'>,
    paymentOriginInfo: PaymentOriginInfoType,
): PaymentRequestPayload {
    switch (paymentOriginInfo.type) {
        case PaymentOriginType.CHECKIN_BY_TICKET:
            return {
                ...payload,
                uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_TICKET,
                ticketId: paymentOriginInfo.ticketId,
            };
        case PaymentOriginType.ACCOUNT:
            return {
                ...payload,
                uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_ACCOUNT,
            };
        case PaymentOriginType.CHECKIN_BY_PLATE:
            return {
                ...payload,
                uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_CHECKIN,
            };
        case PaymentOriginType.PERMIT:
            return {
                ...payload,
                uiPaymentOrigin: UiPaymentOrigin.TOPUP_FROM_PERMIT,
            };
    }
}

function TopUp({
    mode,
    category,
    onDirectEpaymentSuccess,
    onClose,
    paymentOriginInfo,
}: {
    mode: PaymentRequestMode;
    category: PaymentCategory;
    onDirectEpaymentSuccess: () => void;
    onClose: () => void;
    paymentOriginInfo: PaymentOriginInfoType;
}) {
    const { storeState, update } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        accountBalance: new AccountBalanceState.StateSlice(store).state,
    }));

    const [remoteRequest, sendRemoteRequest] = TopUpPaymentRequest.use();
    const language = languageFromString(storeState.settings.language);

    switch (remoteRequest.state) {
        case PaymentStateType.NEVER_EXECUTED:
            return (
                <AmountsChoiceForm
                    paymentRequestMode={mode}
                    paymentCategory={category}
                    onSubmit={(amount: number) => {
                        const payloadBase: Omit<
                            PaymentRequestPayload,
                            'uiPaymentOrigin' | 'ticketId'
                        > = {
                            paymentCategory: category,
                            amount,
                            language,
                            mode,
                        };
                        sendRemoteRequest(
                            createTopupPaymentPayload(
                                payloadBase,
                                paymentOriginInfo,
                            ),
                        );
                    }}
                    currentBalanceChf={storeState.accountBalance.data.balance}
                    update={update}
                    isTopUpFromAccount={
                        paymentOriginInfo.type === PaymentOriginType.ACCOUNT
                    }
                />
            );
        case PaymentStateType.DIRECT_TO_ALIAS_SUCCESS:
            return (
                <PaymentSuccess
                    update={update}
                    onClose={onDirectEpaymentSuccess}
                />
            );
        default:
            return (
                <ShowPaymentState
                    remoteRequest={remoteRequest}
                    category={category}
                    mode={mode}
                    onClose={onClose}
                />
            );
    }
}

const PaymentSuccess = (props: { update: Updater; onClose: () => void }) => (
    <ModalSuccessBox
        titleCaption={
            <Localized
                de="Zahlung abgeschlossen"
                fr="Paiement effectué"
                it="Pagamento eseguito"
                en="Payment executed"
            />
        }
        confirmCallback={() => {
            props.onClose();
            props.update(s => {
                new AccountBalanceState.StateSlice(s).reset();
                return 'reset-account-balance-after-direct-payment';
            });
        }}
    >
        <p>
            <Localized
                de="Die Online-Zahlung war erfolgreich und Ihr Parkingpay-Konto wurde aufgeladen."
                fr="Le paiement en ligne a réussi et votre compte Parkingpay a été rechargé."
                it="Il pagamento online è stato eseguito con successo e il suo conto Parkingpay è stato ricaricato."
                en="The e-payment was successful and your Parkingpay account was topped up."
            />
        </p>
    </ModalSuccessBox>
);

const AmountsChoiceForm = (props: FormProps) => {
    const [selectedAmount, setSelectedAmount] = useState(
        TopUpPaymentLogic.defaultAmountChf(props.currentBalanceChf),
    );
    const [directPurchaseWarning, toggleDirectPurchaseWarning] =
        useState(false);
    const [limit] = useServerFetch<LimitData, object>(
        () => ({
            url: `/ui-api/customer-account/deposits/limits`,
        }),
        {},
    );

    const smallestTopup = Math.min(...topUpAmounts());

    useServerSuccessEffect(limit, data => {
        if (
            data.amountToLimit < selectedAmount &&
            data.amountToLimit >= smallestTopup
        ) {
            setSelectedAmount(smallestTopup);
        }
    });
    const submit = () => {
        props.onSubmit(selectedAmount);
    };
    switch (limit.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.PENDING:
            return <Spinner />;
        case RequestStatus.ERROR:
            return <ErrorBlock text={<GenericErrorBody />} />;
        case RequestStatus.SUCCESS: {
            const disabledAmountSelected =
                selectedAmount > limit.data.amountToLimit;
            if (limit.data.amountToLimit >= smallestTopup) {
                return (
                    <div
                        className={css({
                            backgroundColor: Colors.form,
                            minHeight: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        })}
                    >
                        <div className={css({ padding: '10px 24px 0 24px' })}>
                            <AmountLabel
                                text={{
                                    de: 'BETRAG (in CHF)',
                                    fr: 'MONTANT (en CHF)',
                                    it: 'IMPORTO (in CHF)',
                                    en: 'AMOUNT (in CHF)',
                                }}
                            />
                            <AmountsChoiceGrid
                                selectedAmount={selectedAmount}
                                accountBalanceChf={props.currentBalanceChf}
                                setSelectedAmount={setSelectedAmount}
                                amountToLimit={limit.data.amountToLimit}
                            />
                            {props.isTopUpFromAccount && (
                                <div
                                    className={css({
                                        ...Typo.body,
                                        paddingTop: '48px',
                                    })}
                                >
                                    <div>
                                        <Localized
                                            {...{
                                                de: 'Tipp',
                                                fr: 'Conseil',
                                                it: 'Consiglio',
                                                en: 'Tip',
                                            }}
                                        />
                                    </div>
                                    <p
                                        className={css({
                                            ...Typo.tBody,
                                        })}
                                    >
                                        <Localized
                                            de="Wenn Sie eine Bewilligung mit einem anderen Betrag kaufen wollen, können Sie sie direkt beim Kauf bezahlen, ohne das Konto zuerst aufladen zu müssen."
                                            fr="Si vous voulez acheter une autorisation avec un montant différent, vous pouvez la payer directement au moment de l'achat, sans avoir à alimenter le compte au préalable."
                                            it="Se desidera acquistare un'autorizzazione con un importo differente, è possibile pagarla direttamente al momento dell'acquisto, senza dover prima caricare il conto."
                                            en="If you want to buy a permit with a different amount, you can pay it directly at the time of purchase, without having to top up the account first."
                                        />
                                    </p>
                                </div>
                            )}
                            {disabledAmountSelected && (
                                <IdentificationRequiredETopup
                                    limitType={limit.data.limitType}
                                />
                            )}
                        </div>

                        <div className={css({ padding: '24px' })}>
                            {props.paymentRequestMode ===
                            PaymentRequestMode.DIRECT_TO_ALIAS ? (
                                <>
                                    <DirectPayButton
                                        disabled={disabledAmountSelected}
                                        onClick={() =>
                                            toggleDirectPurchaseWarning(true)
                                        }
                                    />
                                    {directPurchaseWarning && (
                                        <DirectPayWarningAlertBox
                                            paymentCategory={
                                                props.paymentCategory
                                            }
                                            onConfirm={submit}
                                            onCancel={() =>
                                                toggleDirectPurchaseWarning(
                                                    false,
                                                )
                                            }
                                        />
                                    )}
                                </>
                            ) : (
                                <ProceedButton
                                    disabled={
                                        TopUpPaymentLogic.topUpAmounts().indexOf(
                                            selectedAmount,
                                        ) === -1 || disabledAmountSelected
                                    }
                                    onClick={submit}
                                />
                            )}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={css({ padding: '10px 24px 0 24px' })}>
                        <IdentificationRequiredETopup
                            limitType={limit.data.limitType}
                        />
                    </div>
                );
            }
        }
    }
};

function IdentificationRequiredETopup({ limitType }: { limitType: LimitType }) {
    const meta = useStore(s => ({
        metaServer: new MetaServerState.StateSlice(s).state,
    })).storeState.metaServer;
    const isCompany =
        meta.data.customerAccountType === CustomerAccountType.COMPANY;
    const isLimitTypeHard = limitType === LimitType.HARD;
    return (
        <div className={css({ paddingTop: '20px' })}>
            {isLimitTypeHard ? (
                <InlineErrorBox
                    titleCaption={
                        <Localized
                            de="Prepaid-Limit"
                            fr="Limite prépayée"
                            it="Limite prepagato"
                            en="Prepaid limit"
                        />
                    }
                >
                    <p>
                        <Localized
                            de={`Sie haben das Limit von CHF ${
                                isCompany ? '4’000' : '2’000'
                            } pro Jahr im Prepaid-Modus erreicht. Um Parkingpay weiterhin nutzen zu können, müssen Sie eine andere Zahlungsweise wählen.`}
                            fr={`Vous avez atteint la limite de CHF ${
                                isCompany ? '4’000' : '2’000'
                            } par an en mode prépayé. Pour continuer à utiliser Parkingpay, vous devez choisir un autre mode de paiement.`}
                            it={`Ha raggiunto il limite di CHF ${
                                isCompany ? '4’000' : '2’000'
                            } all’anno della modalità prepagata, per poter continuare ad utilizzare Parkingpay deve scegliere un’altra modalità di pagamento.`}
                            en={`You have reached the limit of CHF ${
                                isCompany ? '4’000' : '2’000'
                            } per year in prepaid mode. In order to continue using Parkingpay, you must choose another payment method.`}
                        />
                    </p>
                    <AdditionalInfo isCompany={isCompany} />
                </InlineErrorBox>
            ) : (
                <InlineAlertBox
                    titleCaption={
                        <Localized
                            de="Prepaid-Limit"
                            fr="Limite prépayée"
                            it="Limite prepagato"
                            en="Prepaid limit"
                        />
                    }
                >
                    <p>
                        {isCompany ? (
                            <Localized
                                de="Sie haben das Limit von CHF 100 pro Jahr im Prepaid-Modus erreicht. Um weiter aufzuladen, müssen Sie die UID der Firma registrieren (Konto → Konto/Adresse → Inhaber)."
                                fr="Vous avez atteint la limite de CHF 100 CHF par an en mode prépayé. Pour continuer à recharger, vous devez enregistrer le IDE de votre entreprise (compte → compte/adresse → titulaire)."
                                it="Ha raggiunto il limite di CHF 100 all’anno della modalità prepagata, per poter continuare a ricaricare deve registrare l’IDI della sua impresa (conto → conto/indirizzo → titolare)."
                                en="You have reached the limit of CHF 100 per year in prepaid mode. In order to continue topping up, you must register the UID of your company (account → account/address → owner)."
                            />
                        ) : (
                            <Localized
                                de="Sie haben das Limit von CHF 100 pro Jahr im Prepaid-Modus erreicht. Um weiter aufzuladen, müssen Sie Ihre Mobiltelefonnummer registrieren (Konto → Benutzerprofil → Mobiltelefon)."
                                fr="Vous avez atteint la limite de CHF 100 CHF par an en mode prépayé. Pour continuer à recharger, vous devez enregistrer votre numéro de téléphone mobile (compte → profil utilisateur → portable)."
                                it="Ha raggiunto il limite di CHF 100 all’anno della modalità prepagata, per poter continuare a ricaricare deve registrare il suo numero di cellulare (conto → profilo utente → cellulare)."
                                en="You have reached the limit of CHF 100 per year in prepaid mode. In order to continue topping up, you must register your mobile phone number (account → user profile → mobile)."
                            />
                        )}
                    </p>
                    <p>
                        <Localized
                            de="Alternativ können Sie auch eine andere Zahlungsweise wählen."
                            fr="Vous pouvez également choisir un autre mode de paiement."
                            it="In alternativa può scegliere un’altra modalità di pagamento."
                            en="Alternatively, you may choose another payment method."
                        />
                    </p>
                    <AdditionalInfo isCompany={isCompany} />
                </InlineAlertBox>
            )}
        </div>
    );
}

interface FormProps {
    update: Updater;
    onSubmit: (selectedAmount: number) => void;
    currentBalanceChf: number;
    paymentRequestMode: PaymentRequestMode;
    paymentCategory: PaymentCategory;
    isTopUpFromAccount: boolean;
}
