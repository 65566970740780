import * as GeneralTexts from '../../../common/i18n/GeneralTexts';
import * as Text from '../../../common/i18n/Text';

export const generalTexts: Text.Translations<Meta.GeneralTexts> = {
    de: {
        AccountMetaEditTitle: () => 'Konto / Adresse',
        ShippingAddress: () => GeneralTexts.addressTexts.de.ShippingAddress(),
        MandatoryField: () => GeneralTexts.texts.de.MandatoryField(),
        HttpError: () => GeneralTexts.texts.de.ErrorTryAgainOrHelpdesk(),
    },
    fr: {
        AccountMetaEditTitle: () => 'Compte / Adresse',
        ShippingAddress: () => GeneralTexts.addressTexts.fr.ShippingAddress(),
        MandatoryField: () => GeneralTexts.texts.fr.MandatoryField(),
        HttpError: () => GeneralTexts.texts.fr.ErrorTryAgainOrHelpdesk(),
    },
    it: {
        AccountMetaEditTitle: () => 'Conto / Indirizzo',
        ShippingAddress: () => GeneralTexts.addressTexts.it.ShippingAddress(),
        MandatoryField: () => GeneralTexts.texts.it.MandatoryField(),
        HttpError: () => GeneralTexts.texts.it.ErrorTryAgainOrHelpdesk(),
    },
    en: {
        AccountMetaEditTitle: () => 'Account / Address',
        ShippingAddress: () => GeneralTexts.addressTexts.en.ShippingAddress(),
        MandatoryField: () => GeneralTexts.texts.en.MandatoryField(),
        HttpError: () => GeneralTexts.texts.en.ErrorTryAgainOrHelpdesk(),
    },
};

export const shippingTexts: Text.Translations<ShippingAddressTexts> = {
    de: {
        Street: () => GeneralTexts.addressTexts.de.Street1(),
        StreetAdditionalInfo: () =>
            GeneralTexts.addressTexts.de.StreetAdditionalInfo(),
        ZipCode: () => GeneralTexts.addressTexts.de.ZipCode(),
        City: () => GeneralTexts.addressTexts.de.City(),
        Country: () => GeneralTexts.addressTexts.de.Country(),
        InvoiceOnly: () => GeneralTexts.addressTexts.de.InvoiceOnly(),
        LikeAddress: () => 'Wie die Hauptadresse',
    },
    fr: {
        Street: () => GeneralTexts.addressTexts.fr.Street1(),
        StreetAdditionalInfo: () =>
            GeneralTexts.addressTexts.fr.StreetAdditionalInfo(),
        ZipCode: () => GeneralTexts.addressTexts.fr.ZipCode(),
        City: () => GeneralTexts.addressTexts.fr.City(),
        Country: () => GeneralTexts.addressTexts.fr.Country(),
        InvoiceOnly: () => GeneralTexts.addressTexts.fr.InvoiceOnly(),
        LikeAddress: () => "Comme l'adresse principale",
    },
    it: {
        Street: () => GeneralTexts.addressTexts.it.Street1(),
        StreetAdditionalInfo: () =>
            GeneralTexts.addressTexts.it.StreetAdditionalInfo(),
        ZipCode: () => GeneralTexts.addressTexts.it.ZipCode(),
        City: () => GeneralTexts.addressTexts.it.City(),
        Country: () => GeneralTexts.addressTexts.it.Country(),
        InvoiceOnly: () => GeneralTexts.addressTexts.it.InvoiceOnly(),
        LikeAddress: () => "Come l'indirizzo principale",
    },
    en: {
        Street: () => GeneralTexts.addressTexts.en.Street1(),
        StreetAdditionalInfo: () =>
            GeneralTexts.addressTexts.en.StreetAdditionalInfo(),
        ZipCode: () => GeneralTexts.addressTexts.en.ZipCode(),
        City: () => GeneralTexts.addressTexts.en.City(),
        Country: () => GeneralTexts.addressTexts.en.Country(),
        InvoiceOnly: () => GeneralTexts.addressTexts.en.InvoiceOnly(),
        LikeAddress: () => 'Same as the main address',
    },
};

export const correspondenceTexts: Text.Translations<CorrespondenceTexts> = {
    de: {
        CorrespondenceLanguage: () =>
            GeneralTexts.addressTexts.de.CorrespondenceLanguage(),
    },
    fr: {
        CorrespondenceLanguage: () =>
            GeneralTexts.addressTexts.fr.CorrespondenceLanguage(),
    },
    it: {
        CorrespondenceLanguage: () =>
            GeneralTexts.addressTexts.it.CorrespondenceLanguage(),
    },
    en: {
        CorrespondenceLanguage: () =>
            GeneralTexts.addressTexts.en.CorrespondenceLanguage(),
    },
};

export const addressTexts: Text.Translations<AddressTexts> = {
    de: {
        FirstName: () => GeneralTexts.addressTexts.de.FirstName(),
        LastName: () => GeneralTexts.addressTexts.de.LastName(),
        Street: () => GeneralTexts.addressTexts.de.Street1(),
        Company1: () => GeneralTexts.addressTexts.de.Company1(),
        Company2: () => GeneralTexts.addressTexts.de.Company2(),
        ZipCode: () => GeneralTexts.addressTexts.de.ZipCode(),
        City: () => GeneralTexts.addressTexts.de.City(),
        Country: () => GeneralTexts.addressTexts.de.Country(),
    },
    fr: {
        FirstName: () => GeneralTexts.addressTexts.fr.FirstName(),
        LastName: () => GeneralTexts.addressTexts.fr.LastName(),
        Street: () => GeneralTexts.addressTexts.fr.Street1(),
        Company1: () => GeneralTexts.addressTexts.fr.Company1(),
        Company2: () => GeneralTexts.addressTexts.fr.Company2(),
        ZipCode: () => GeneralTexts.addressTexts.fr.ZipCode(),
        City: () => GeneralTexts.addressTexts.fr.City(),
        Country: () => GeneralTexts.addressTexts.fr.Country(),
    },
    it: {
        FirstName: () => GeneralTexts.addressTexts.it.FirstName(),
        LastName: () => GeneralTexts.addressTexts.it.LastName(),
        Street: () => GeneralTexts.addressTexts.it.Street1(),
        Company1: () => GeneralTexts.addressTexts.it.Company1(),
        Company2: () => GeneralTexts.addressTexts.it.Company2(),
        ZipCode: () => GeneralTexts.addressTexts.it.ZipCode(),
        City: () => GeneralTexts.addressTexts.it.City(),
        Country: () => GeneralTexts.addressTexts.it.Country(),
    },
    en: {
        FirstName: () => GeneralTexts.addressTexts.en.FirstName(),
        LastName: () => GeneralTexts.addressTexts.en.LastName(),
        Street: () => GeneralTexts.addressTexts.en.Street1(),
        Company1: () => GeneralTexts.addressTexts.en.Company1(),
        Company2: () => GeneralTexts.addressTexts.en.Company2(),
        ZipCode: () => GeneralTexts.addressTexts.en.ZipCode(),
        City: () => GeneralTexts.addressTexts.en.City(),
        Country: () => GeneralTexts.addressTexts.en.Country(),
    },
};

export const deleteAccountTexts: Text.Translations<DeleteAccountTexts> = {
    de: {
        WrongPassword: () => 'Falsches Passwort',
        Password: () => GeneralTexts.texts.de.Password(),
        ErrorIbanMissing: () =>
            'Für die Rückzahlung vom Restsaldo müssen Sie zuerst ein Bank-/Postkonto hinterlegen',
        ErrorGotVoucherCredit: () =>
            'Ihr Parkingpay-Konto kann derzeit nicht gekündigt werden, da Ihr Guthaben aus Gutscheinen noch nicht konsumiert wurde',
        ErrorNegativeAccountBalance: () =>
            'Aufgrund des negativen Saldos kann Ihr Konto derzeit nicht gekündig werden',
        HttpError: () => GeneralTexts.texts.de.ErrorTryAgainOrHelpdesk(),
        Iban: () => 'IBAN',
        Bic: () => 'BIC',
    },
    fr: {
        WrongPassword: () => 'Mot de passe incorrect',
        Password: () => GeneralTexts.texts.fr.Password(),
        ErrorIbanMissing: () =>
            'Pour le remboursement du solde résiduel vous devez spécifier un compte bancaire/postal',
        ErrorGotVoucherCredit: () =>
            'Votre compte Parkingpay ne peut pas être résilié, car votre avoir issu des bons n’a pas encore été utilisé',
        ErrorNegativeAccountBalance: () =>
            'À cause du solde negativ au moment votre compte ne peut pas être résilié',
        HttpError: () => GeneralTexts.texts.fr.ErrorTryAgainOrHelpdesk(),
        Iban: () => 'IBAN',
        Bic: () => 'BIC',
    },
    it: {
        WrongPassword: () => 'Password errata',
        Password: () => GeneralTexts.texts.it.Password(),
        ErrorIbanMissing: () =>
            'Per il rimborso del saldo rimanente dovete prima specificare un conto bancario/postale',
        ErrorGotVoucherCredit: () =>
            'Al momento non è possibile disdire il conto Parkingpay, perché il credito derivante da buoni non è ancora stato completamente utilizzato',
        ErrorNegativeAccountBalance: () =>
            'A causa del saldo negativo al momento il vostro conto non può essere disdetto',
        HttpError: () => GeneralTexts.texts.it.ErrorTryAgainOrHelpdesk(),
        Iban: () => 'IBAN',
        Bic: () => 'BIC',
    },
    en: {
        WrongPassword: () => 'Wrong password',
        Password: () => GeneralTexts.texts.en.Password(),
        ErrorIbanMissing: () =>
            'For the refund of the remaining balance, you must provide a bank/postal account.',
        ErrorGotVoucherCredit: () =>
            "At the moment it's not possible to delete the Parkingpay account, because the credit from the vouchers has not yet been fully used.",
        ErrorNegativeAccountBalance: () =>
            'Due to the negative balance at the moment your account can not be deleted.',
        HttpError: () => GeneralTexts.texts.en.ErrorTryAgainOrHelpdesk(),
        Iban: () => 'IBAN',
        Bic: () => 'BIC',
    },
};

export const accountNr: Text.Translations<AccountNrTexts> = {
    de: {
        AccountNr: () => 'Kontonummer',
        PrivateAccount: () => GeneralTexts.texts.de.PrivateAccount(),
        CompanyAccount: () => GeneralTexts.texts.de.CompanyAccount(),
    },
    fr: {
        AccountNr: () => 'Numéro de compte',
        PrivateAccount: () => GeneralTexts.texts.fr.PrivateAccount(),
        CompanyAccount: () => GeneralTexts.texts.fr.CompanyAccount(),
    },
    it: {
        AccountNr: () => 'Numero di conto',
        PrivateAccount: () => GeneralTexts.texts.it.PrivateAccount(),
        CompanyAccount: () => GeneralTexts.texts.it.CompanyAccount(),
    },
    en: {
        AccountNr: () => 'Account number',
        PrivateAccount: () => GeneralTexts.texts.en.PrivateAccount(),
        CompanyAccount: () => GeneralTexts.texts.en.CompanyAccount(),
    },
};

export namespace Meta {
    export type GeneralTexts = {
        AccountMetaEditTitle: Text.Translation;
        ShippingAddress: Text.Translation;
        MandatoryField: Text.Translation;
        HttpError: Text.Translation;
    };
}

export type AccountNrTexts = {
    AccountNr: Text.Translation;
    PrivateAccount: Text.Translation;
    CompanyAccount: Text.Translation;
};

type AddressTexts = {
    FirstName: Text.Translation;
    LastName: Text.Translation;
    Street: Text.Translation;
    Company1: Text.Translation;
    Company2: Text.Translation;
    ZipCode: Text.Translation;
    City: Text.Translation;
    Country: Text.Translation;
};

export type ShippingAddressTexts = {
    Street: Text.Translation;
    StreetAdditionalInfo: Text.Translation;
    ZipCode: Text.Translation;
    City: Text.Translation;
    Country: Text.Translation;
    InvoiceOnly: Text.Translation;
    LikeAddress: Text.Translation;
};

type CorrespondenceTexts = {
    CorrespondenceLanguage: Text.Translation;
};

type DeleteAccountTexts = {
    Password: Text.Translation;
    ErrorIbanMissing: Text.Translation;
    ErrorGotVoucherCredit: Text.Translation;
    ErrorNegativeAccountBalance: Text.Translation;
    HttpError: Text.Translation;
    Iban: Text.Translation;
    Bic: Text.Translation;
    WrongPassword: Text.Translation;
};
