import { selectState, Updater } from 'dg-web-shared/lib/Flux';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { Localized } from '../../../common/components/Localized';
import * as SettingsState from '../../../common/state/SettingsState';
import * as LayoutState from '../../../layout/state/LayoutState';
import { Signup } from '../../../signup/components/Signup';
import * as SignupState from '../../../signup/state/SignupState';
import { closeSignupSlideIn } from '../actions/SignUpSlideInActions';
import { HasEverLoggedInState } from '../HasEverLoggedInState';
import { portalOrModalSlideIn } from '../PortalSlideInOrModal';
import * as LoginSlideinState from '../state/SignUpSlideinState';
import { ContentMode } from '../state/SignUpSlideinState';

export const SignUpSlidein = selectState(
    s => ({
        settings: new SettingsState.StateSlice(s).state,
        layout: new LayoutState.StateSlice(s).state,
        loginSlidein: LoginSlideinState.SignUpSlideinState.get(s),
        signupResponse: SignupState.SignupResponse.get(s),
        hasEverLoggedIn: HasEverLoggedInState.get(s),
    }),
    p => {
        const backToSignUp = LoginSlideinState.makeSwitchTo(
            p.update,
            ContentMode.SIGNUP,
        );

        return (
            <SignUpContent
                portal={SlideInPortalId.UNAUTH_LANDING_PAGE}
                onClose={() => {
                    switch (p.loginSlidein.contentMode) {
                        case ContentMode.SIGNUP:
                            p.update(closeSignupSlideIn);
                            break;

                        case ContentMode.TERMS_OF_SERVICE:
                        case ContentMode.PRIVACY_POLICY:
                            backToSignUp();
                            break;
                    }
                }}
                update={p.update}
                viewportWidth={p.layout.viewportWidth}
                open={p.loginSlidein.loginSlideinOpen}
                hasEverLoggedIn={p.hasEverLoggedIn.hasEver}
                contentMode={p.loginSlidein.contentMode}
                title={
                    <Localized
                        de="Kontoeröffnung"
                        fr="Ouverture de compte"
                        it="Apertura conto"
                        en="Sign up"
                    />
                }
            />
        );
    },
);

const SignUpContent = portalOrModalSlideIn<{
    update: Updater;
    contentMode: ContentMode;
    hasEverLoggedIn: boolean;
}>(p => {
    return <Signup showLoginHint={p.hasEverLoggedIn} />;
});
