import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import { ServerRequestState } from 'dg-web-shared/lib/hooks/ServerStateHooks';

import { getErrorMessagesFromRequest } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage';
import { Localized } from '../../common/components/Localized';
import { ModalErrorBox } from '../../ui/modals/Confirmable';

export function QuickCheckoutRequestErrorMessage<D, ED extends ValidationData>({
    requestState,
    onConfirm,
    title,
}: {
    requestState: ServerRequestState<D, ED>;
    onConfirm: () => void;
    title?: React.ReactNode;
}) {
    const errorMessages = getErrorMessagesFromRequest(requestState);

    if (!errorMessages) {
        return null;
    }

    return (
        <ModalErrorBox titleCaption={title} confirmCallback={onConfirm}>
            {errorMessages.map(errorMessage => (
                <p key={errorMessage.de}>
                    <Localized {...errorMessage} />
                </p>
            ))}
        </ModalErrorBox>
    );
}
