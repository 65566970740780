import * as Sort from 'dg-web-shared/lib/Sort';
import { Language } from 'dg-web-shared/lib/Text';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import * as Http from '../../api/Http';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import * as Array from '../../utils/Array';
import * as Date from '../../utils/Date';
import * as NumberFormatter from '../../utils/NumberFormatter';
import { portalSlideIn } from '../root/components/PortalSlidein';
import { Colors } from 'dg-web-shared/ui/vars';
import { css } from '@emotion/css';
import { Typo } from '../../style/typo';
import { Localized } from '../../common/components/Localized';
import { DateTime } from 'luxon';
import { ParkingpayAsyncLoadedSection } from '../../common/components/ParkingpayAsyncLoadedSection';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import React from 'react';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export const MonthlyReportsListSlideIn =
    portalSlideIn<MonthlyReportsListProps>(MonthlyReportsList);

interface MonthlyReportsListProps {
    language: Language;
    accountBalance: AccountBalanceState.Balance;
    onMonthClick: (yyyyMM: string) => void;
}

function MonthlyReportsList({
    language,
    accountBalance,
    onMonthClick,
}: MonthlyReportsListProps) {
    const [monthlyReportState] = useServerFetch<
        MonthlyReport[],
        Record<string, never>
    >(
        () => ({
            url: `/ui-api/customer-account/deposits/monthly-reports-v2`,
        }),
        {},
    );

    return (
        <ParkingpayAsyncLoadedSection
            state={monthlyReportState}
            showSpinnerOnRefetch
            renderSuccess={monthlyReports => (
                <>
                    <MonthlyReportsHeader />
                    <MonthlyReportsItems
                        language={language}
                        monthlyReports={assembleReports(
                            monthlyReports,
                            accountBalance.balance,
                        )}
                        onMonthClick={onMonthClick}
                    />
                </>
            )}
        />
    );
}

interface MonthlyReport {
    id: number;
    month: string;
    movement: number;
    accountBalance: number;
    key: string | null;
}

function assembleReports(
    reports: MonthlyReport[],
    balance: number,
): MonthlyReport[] {
    const reportsCopy = reports.slice();
    const thisMonth = Date.Formatter.isoYearMonth(DateTime.now());
    const thisMonthReport = reportsCopy.find(
        (r: MonthlyReport) => r.month === thisMonth,
    );
    if (!thisMonthReport) {
        reportsCopy.push({
            id: undefined,
            month: thisMonth,
            amount: undefined,
            accountBalance: balance,
        } as any);
    }
    reportsCopy.sort(compareReportsByMonth);
    return reportsCopy;
}

function compareReportsByMonth(a: MonthlyReport, b: MonthlyReport): number {
    return Sort.arithmeticCompare(
        DateTime.fromISO(a.month),
        DateTime.fromISO(b.month),
        false,
    );
}

interface MonthlyReportsItemsProps {
    monthlyReports: MonthlyReport[];
    language: Language;
    onMonthClick: (yyyyMM: string) => void;
}

function MonthlyReportsHeader() {
    return (
        <div
            className={css({
                padding: '0 16px 0 24px',
                height: '48px',
                borderTop: `1px solid ${Colors.rgba(Colors.black, 0.08)}`,
                borderBottom: `1px solid ${Colors.rgba(Colors.black, 0.08)}`,
            })}
        >
            <div
                className={css({
                    marginRight: '112px',
                    '&::before': { content: `' '`, display: 'table' },
                    '&::after': {
                        content: `' '`,
                        display: 'table',
                        clear: 'both',
                    },
                    ...Typo.caption,
                    color: Colors.rgba(Colors.action_w, 0.8),
                    marginTop: `${21 - Typo.sCaptionTopCorrectionPx}px`,
                    fontSize: '15px',
                    fontWeight: 500,
                })}
            >
                <div
                    className={css({
                        width: '60%',
                        paddingRight: '10%',
                        float: 'right',
                        textAlign: 'right',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    })}
                >
                    <Localized
                        de="Gebühren (CHF)"
                        fr="Taxes (CHF)"
                        it="Tasse (CHF)"
                        en="Fees (CHF)"
                    />
                </div>
                <div className={css({ float: 'left' })}>
                    {<Localized de="Monat" fr="Mois" it="Mese" en="Month" />}
                </div>
            </div>
        </div>
    );
}

class MonthlyReportsItems extends React.Component<MonthlyReportsItemsProps> {
    renderYearHeader(year: number): JSX.Element {
        return (
            <div
                className={css({
                    padding: `${Typo.bodyTopCorrection}px 16px 0 24px`,
                    background: Colors.content,
                    height: '30px',
                    ...Typo.body,
                    color: Colors.blue,
                    fontWeight: 'bold',
                })}
            >
                {year}
            </div>
        );
    }

    renderYearMonths(reports: MonthlyReport[]): React.ReactNode {
        return reports.map((r: MonthlyReport, index: number) => {
            return (
                <MonthlyReportsItem
                    key={`monthly-${r.month}`}
                    id={r.id}
                    month={DateTime.fromISO(r.month)}
                    movement={r.movement}
                    apiKey={r.key || ''}
                    language={this.props.language}
                    index={index}
                    onClick={this.props.onMonthClick}
                />
            );
        });
    }

    renderReports(): React.ReactNode {
        const reports = this.props.monthlyReports;
        const years = Array.uniq(
            reports.map((r: MonthlyReport) => {
                return DateTime.fromISO(r.month).year;
            }),
        );
        return years.map((y: number) => {
            const months = reports.filter(
                (v: MonthlyReport) => y === DateTime.fromISO(v.month).year,
            );
            return (
                <div key={`year-${y}`}>
                    {this.renderYearHeader(y)}
                    {this.renderYearMonths(months)}
                </div>
            );
        });
    }

    render() {
        return <>{this.renderReports()}</>;
    }
}

interface MonthlyReportsItemProps {
    id: number;
    month: DateTime;
    apiKey: string;
    movement: number;
    language: Language;
    index: number;
    onClick: (yyyyMM: string) => void;
}

class MonthlyReportsItem extends React.Component<MonthlyReportsItemProps> {
    renderDetailIcon(): JSX.Element {
        return (
            <Clickable
                element="div"
                className={css({
                    width: '48px',
                    height: '48px',
                    padding: '12px',
                    float: 'left',
                    color: Colors.blue,
                })}
                onClick={() => {
                    this.props.onClick(
                        Date.Formatter.isoYearMonth(this.props.month),
                    );
                }}
            >
                <Icon icon="list" />
            </Clickable>
        );
    }

    renderReportIcon(month: DateTime, key: string): JSX.Element | null {
        if (!month || !this.props.id) {
            return null;
        }

        return (
            <a
                target="_blank"
                href={Http.getMonthlyReportPDFURL(month, key)}
                className={css({
                    width: '48px',
                    height: '48px',
                    padding: '12px',
                    float: 'left',
                    color: Colors.blue,
                })}
                rel="noreferrer"
            >
                <Icon icon="description" />
            </a>
        );
    }

    render() {
        return (
            <div
                className={css({
                    '&::before': { content: `' '`, display: 'table' },
                    '&::after': {
                        content: `' '`,
                        display: 'table',
                        clear: 'both',
                    },
                    padding: '0 16px 0 24px',
                    height: '48px',
                    borderBottom: `1px solid ${Colors.rgba(
                        Colors.black,
                        0.08,
                    )}`,
                })}
            >
                <div
                    className={css({
                        '&::before': { content: `' '`, display: 'table' },
                        '&::after': {
                            content: `' '`,
                            display: 'table',
                            clear: 'both',
                        },
                        float: 'right',
                        width: '96px',
                    })}
                >
                    {this.renderDetailIcon()}
                    {this.renderReportIcon(this.props.month, this.props.apiKey)}
                </div>
                <div
                    className={css({
                        marginRight: '112px',
                        overflow: 'hidden',
                    })}
                >
                    <div
                        className={css({
                            ...Typo.body,
                            marginTop: `${18 - Typo.bodyTopCorrection}px`,
                            color: Colors.blue,
                            width: '50%',
                            fontWeight: 400,
                            paddingRight: '10%',
                            float: 'right',
                            textAlign: 'right',
                        })}
                    >
                        {NumberFormatter.numberToPrice(
                            this.props.movement,
                            false,
                        )}
                    </div>
                    <div
                        className={css({
                            ...Typo.body,
                            marginTop: `${18 - Typo.bodyTopCorrection}px`,
                            color: Colors.blue,
                            width: '50%',
                            fontWeight: 400,
                        })}
                    >
                        {Date.Formatter.monthName(
                            this.props.month,
                            (Date.Formatter.Locale as any)[this.props.language],
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
