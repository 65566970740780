import { Colors } from 'dg-web-shared/ui/vars';

export enum InputContext {
    regular = 1,
    form,
    inverted,
    activeblue,
    quickcheckout,
}

export function inputContextToColor(context: InputContext): string {
    switch (context) {
        case InputContext.activeblue:
            return Colors.darkblue;
        case InputContext.form:
            return Colors.action_f;
        case InputContext.inverted:
            return Colors.action_b;
        case InputContext.regular:
            return Colors.action_w;
        case InputContext.quickcheckout:
            return Colors.white;
    }
}
