import moment from 'moment';
import { ParkCreateDatePickerSlidein } from '../../../ui/slidein/DatePickerSlidein';
import { SingleSelectionSlideinString } from '../../../ui/slidein/SingleSelectionSlidein';
import { validityIntervalTexts } from '../../i18n/PermitTexts';
import * as Date from 'dg-web-shared/lib/Date';
import * as PermitPurchaseState from '../../state/permit/PermitPurchaseState';
import { Duration, DurationType } from 'dg-web-shared/dto/PermitTimeUnit';
import * as Text from '../../../common/i18n/Text';
import * as PermitTexts from '../../i18n/PermitTexts';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { DateTime } from 'luxon';

export interface Texts {
    FromDate: Text.Translation;
    ToDate: Text.Translation;
    ExpirationDate: Text.Translation;
    Duration: Text.Translation;
    Hours: (i: number) => string;
    Days: (i: number) => string;
    Weeks: (i: number) => string;
    Months: (i: number) => string;
    Years: (i: number) => string;
}

export function getDurationOptionDisplayText(
    t: number,
    timeUnit: DurationType,
    texts: Texts,
): string {
    switch (timeUnit) {
        case 'hour':
            return `${t} ${texts.Hours(t)}`;
        case 'day':
            return `${t} ${texts.Days(t)}`;
        case 'week':
            return `${t} ${texts.Weeks(t)}`;
        case 'month':
            return `${t} ${texts.Months(t)}`;
        case 'year':
            return `${t} ${texts.Years(t)}`;
        default:
            return '';
    }
}

export function FromDateSlidein({
    data,
    language,
    selectedDate,
    open,
    onSelect,
    onClose,
}: {
    data?: PermitPurchaseState.DateValidation.DateValidation | null;
    language: string;
    open: boolean;
    selectedDate: DateTime | null;
    onSelect: (d: DateTime) => void;
    onClose: () => void;
}) {
    if (!data) {
        return null;
    }
    const fromRange = Date.Parser.rangeFromIsoString(data.fromRange);
    if (!fromRange) {
        return null;
    }
    return (
        <ParkCreateDatePickerSlidein
            currentDate={moment()}
            language={language}
            onSelect={d => onSelect(DateTime.fromISO(d.toISOString()))}
            onClose={onClose}
            open={open}
            selectedDate={selectedDate ? moment(selectedDate.toISO()) : null}
            title={validityIntervalTexts[language].FromDate()}
            minDate={fromRange.start}
            maxDate={fromRange.end}
        />
    );
}

export function DurationSlidein({
    allowedDurations,
    language,
    duration,
    durationType,
    open,
    onClose,
    onSelect,
}: {
    open: boolean;
    onClose: () => void;
    onSelect: (id: string) => void;
    allowedDurations?: Duration[];
    language: string;
    duration: number | null;
    durationType: DurationType | null;
}) {
    const texts = PermitTexts.validityIntervalTexts[language];
    const label = validityIntervalTexts[language].Duration();

    if (!allowedDurations || allowedDurations.length === 0) {
        return null;
    }

    return (
        <SingleSelectionSlideinString
            portalId={SlideInPortalId.PARK_CREATE}
            label={label}
            options={allowedDurations.map(d => ({
                id: d.uniqueId,
                displayText: getDurationOptionDisplayText(
                    d.quantity,
                    d.type,
                    texts,
                ),
            }))}
            selection={
                duration && durationType ? `${durationType}-${duration}` : null
            }
            onSelect={onSelect}
            onClose={onClose}
            open={open}
        />
    );
}
