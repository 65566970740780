import * as LayoutState from '../state/LayoutState';
import { ThreeColumnsProperties } from './ThreeColumnsProperties';

interface ApplicationLayoutInputs {
    layout: LayoutState.State;
}

export class ApplicationLayout {
    private _s: ApplicationLayoutInputs;
    private _columnProps: ThreeColumnsProperties;

    constructor(s: ApplicationLayoutInputs) {
        this._s = s;
        this._columnProps = new ThreeColumnsProperties(s);
    }

    get minContainerSpacing(): number {
        if (this._columnProps.variant.oneColumn) {
            return 0;
        } else {
            return this._s.layout.viewportWidth < 848 ? 48 : 64;
        }
    }

    get columnWidth(): number {
        const columnWidth: number = Math.floor(
            (this._s.layout.viewportWidth -
                2 * this.minContainerSpacing -
                (this._columnProps.numColumns - 1) *
                    this._columnProps.columnSpacing) /
                this._columnProps.numColumns,
        );

        if (this._columnProps.variant.oneColumn) {
            return columnWidth;
        } else {
            return Math.min(480, columnWidth);
        }
    }

    get columnsContainerWidth(): number {
        if (this._columnProps.variant.oneColumn) {
            return this.columnWidth;
        } else {
            return 3 * this.columnWidth + 2 * this._columnProps.columnSpacing;
        }
    }
}
