import * as Flux from 'dg-web-shared/lib/Flux';
import { ResponsiveProperties } from '../utils/ResponsiveProperties';
import { LogoSloganVariant } from '../utils/LogoSloganVariant';
import { ThreeColumnsProperties } from '../utils/ThreeColumnsProperties';
import { responsiveVariant } from '../utils/responsiveVariant';
import * as LayoutState from '../state/LayoutState';
import * as SettingsState from '../../common/state/SettingsState';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { LayoutVariantOption } from './LayoutContainer';

interface LayoutBackgroundState {
    layout: LayoutState.State;
    settings: SettingsState.State;
}
export class LayoutBackground extends Flux.ContainerWithProps<
    { layoutVariant: LayoutVariantOption } & Flux.ContainerProps,
    LayoutBackgroundState
> {
    static displayName = 'LayoutBackground';
    stateSelector(): LayoutBackgroundState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }
    render() {
        const responsiveProperties = new ResponsiveProperties(this.state);
        const columnProperties = new ThreeColumnsProperties(this.state);
        const logoSloganVariant = new LogoSloganVariant(
            this.state,
            this.props.layoutVariant,
        );

        if (responsiveProperties.mobile) {
            return null;
        }

        return (
            <div
                className={css([
                    {
                        backgroundColor: Colors.background,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: '0 80px 0 80px',
                    },
                    columnProperties.variant.toString() === 'ThreeColumns' && [
                        {
                            top: '168px',
                        },
                        this.props.layoutVariant ===
                            LayoutVariantOption.Application && [
                            responsiveVariant(this.state) ===
                                'DesktopLarge' && {
                                top: '136px',
                            },
                            responsiveVariant(this.state) === 'Desktop' && {
                                top: '112px',
                            },
                        ],
                    ],
                    columnProperties.variant.toString() === 'OneColumn' && {
                        margin: 0,
                        top: '112px',
                    },
                ])}
                data-layout-variant={this.props.layoutVariant.toString()}
                data-columns-variant={columnProperties.variant.toString()}
                data-logoslogan-variant={logoSloganVariant.toString()}
                data-responsive-variant={responsiveVariant(this.state)}
            />
        );
    }
}
