import { PermitType } from '../../state/permit/PermitTypeModels';
import * as Text from '../../../common/i18n/Text';
import * as DropinTexts from '../../i18n/DropinTexts';
import {
    SlideInContentFlexColumn,
    SlideInContentItem,
    SlideInContentItemText,
} from '../../../ui/slidein/SlideIn';
import { ParkCreatePortalSlideIn } from '../../ParkCreateSlideIn';

export interface Texts {
    Title: Text.Translation;
    Name: Text.Translation;
}

interface Props {
    selectedPermitTypeId: number | null;
    permitTypes: Readonly<PermitType[]>;
    language: string;
    open: boolean;
    onClose: () => void;
    onSelect: (permitId: number) => void;
}

export function PermitTypeSlideIn({
    open,
    onClose,
    onSelect,
    language,
    permitTypes,
    selectedPermitTypeId,
}: Props) {
    const texts = DropinTexts.permitTypeDropinTexts[language];

    return (
        <ParkCreatePortalSlideIn
            open={open}
            title={texts.Title()}
            onClose={onClose}
        >
            {permitTypes.map((permitType: PermitType) => (
                <SlideInContentItem
                    onClick={() => onSelect(permitType.id)}
                    selected={selectedPermitTypeId === permitType.id}
                    key={permitType.id.toString()}
                >
                    <SlideInContentFlexColumn>
                        <SlideInContentItemText>
                            {permitType.description}
                        </SlideInContentItemText>
                    </SlideInContentFlexColumn>
                </SlideInContentItem>
            ))}
        </ParkCreatePortalSlideIn>
    );
}
