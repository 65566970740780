import * as LayoutState from '../state/LayoutState';
import { ThreeColumnsProperties } from './ThreeColumnsProperties';

interface ColumnsVariantInputs {
    layout: LayoutState.State;
}
export function columnsVariant(s: ColumnsVariantInputs): string {
    const columnProps = new ThreeColumnsProperties(s);
    return columnProps.variant.toString();
}
