import { css } from '@emotion/css';
import moment from 'moment';

import * as Flux from 'dg-web-shared/lib/Flux';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import * as Punctuation from 'dg-web-shared/lib/Punctuation';
import { BasicButton } from '../../../../tb-ui/buttons/BasicButton';
import { ButtonRow } from '../../../../tb-ui/buttons/ButtonRow';
import { LabeledText } from '../../../../tb-ui/typo/LabeledText';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import * as Http from '../../api/Http';
import { texts } from '../../common/i18n/GeneralTexts';
import * as SettingsState from '../../common/state/SettingsState';
import { fromOldNotation } from '../../Translate';
import { Formatter, Parser } from '../../utils/Date';
import { expiredParkTransactionTexts } from '../i18n/TransactionsListTexts';
import * as TransactionsListState from '../state/TransactionsListState';
import { PriceDetailsInfoBox } from '../components/TransactionsList';
import { useState } from 'react';
import { TransactionCost } from 'dg-web-shared/model/TransactionList';

const infoButtonIconBits = require('../../../assets/info_button.svg');

const getDateTime = (m: moment.Moment | null | undefined) =>
    m ? Formatter.dayMonthYearHourMinute(m) : Punctuation.EmDash;

export const TerminatedCheckinByPlate = Flux.selectState(
    (
        store,
        _: {
            transaction: TransactionsListState.CheckinByPlateDetailState.TerminatedCheckinByPlate;
        },
    ) => ({
        settings: new SettingsState.StateSlice(store).state,
    }),
    p => (
        <div
            className={css({
                padding: '24px',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
            })}
        >
            <LabeledText
                label={fromOldNotation('Zone', expiredParkTransactionTexts)}
            >
                {`${p.transaction.extZoneCode ?? ''}  ${
                    p.transaction.zoneName
                }`}
            </LabeledText>
            <LabeledText label={fromOldNotation('City', texts)}>
                {`${p.transaction.zipCode}  ${p.transaction.city}`}
            </LabeledText>
            <LabeledText
                label={fromOldNotation(
                    'LicensePlate',
                    expiredParkTransactionTexts,
                )}
            >
                {`${p.transaction.licensePlate.licensePlateNr}  ${
                    p.transaction.licensePlate.remarks
                        ? `(${p.transaction.licensePlate.remarks})`
                        : ''
                }`}
            </LabeledText>
            <LabeledText
                label={fromOldNotation(
                    'StartDate',
                    expiredParkTransactionTexts,
                )}
            >
                {getDateTime(Parser.isoToMaybeMoment(p.transaction.begin))}
            </LabeledText>
            <LabeledText
                label={fromOldNotation('EndDate', expiredParkTransactionTexts)}
            >
                {getDateTime(
                    p.transaction.end
                        ? Parser.isoToMaybeMoment(p.transaction.end)
                        : null,
                )}
            </LabeledText>
            <LabeledText
                label={fromOldNotation(
                    'ParkDuration',
                    expiredParkTransactionTexts,
                )}
            >
                {Formatter.getDuration(
                    p.transaction.begin,
                    p.transaction.end
                        ? p.transaction.end
                        : moment().toISOString(),
                    Formatter.durationTexts[p.settings.language],
                )}
            </LabeledText>
            <PaidAmount
                cost={p.transaction.cost}
                portal={SlideInPortalId.TRANSACTION_LIST}
            />
            <LabeledText label={fromOldNotation('Operator', texts)}>
                {`${p.transaction.operatorName}`}
            </LabeledText>
            <ButtonRow>
                <a
                    target="_blank"
                    href={Http.getContractReceiptPDFURL(
                        p.transaction.contractId.toString(),
                        p.transaction.key,
                    )}
                    rel="noreferrer"
                >
                    <BasicButton
                        negative={true}
                        label={expiredParkTransactionTexts[
                            p.settings.language
                        ].DownloadReceipt()}
                        onClick={null}
                    />
                </a>
            </ButtonRow>
        </div>
    ),
);

export function PaidAmount(props: {
    cost: TransactionCost;
    portal: SlideInPortalId;
}) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <>
            <div className={css({ display: 'flex' })}>
                <LabeledText
                    label={fromOldNotation(
                        'ParkPriceTotal',
                        expiredParkTransactionTexts,
                    )}
                >
                    {NumberFormatter.numberToPrice(props.cost.paidAmount)}
                </LabeledText>

                {!!props.cost.paidAmount && (
                    <a
                        className={css({
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            paddingLeft: '16px',
                            paddingBottom: '24px',
                            cursor: 'pointer',
                        })}
                        onClick={setShowDetails.bind(null, true)}
                    >
                        <img src={infoButtonIconBits} />
                    </a>
                )}
            </div>

            {showDetails && (
                <PriceDetailsInfoBox
                    cost={props.cost}
                    onClose={() => setShowDetails(false)}
                />
            )}
        </>
    );
}
