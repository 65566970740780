import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { Localized } from '../../common/components/Localized';
import { Message } from 'dg-web-shared/lib/Localized';
import { Modal } from '../../common/components/Modal';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24';
import { paper } from '../../../../tb-ui/paper';
import { Typo } from '../../../../tb-ui/typo';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { Icon24 } from 'dg-web-shared/ui/icons/Icon';

export enum ButtonText {
    YES = 'yes',
    NO = 'no',
    CANCEL = 'cancel',
    CONTINUE = 'continue',
    DELETE = 'delete',
    OK = 'ok',
    CLOSE = 'close',
    EDIT = 'edit',
    SHOW = 'show',
    TOPUP = 'topup',
    RESEND = 'resend',
    ADD = 'add',
}

export enum ArrowPosition {
    left,
    right,
    middle,
    none,
}

export enum Color {
    darkblue,
    blue,
    error,
    green,
    yellow,
    lightblue,
}

function getConfirmableColor(color: Color) {
    switch (color) {
        case Color.yellow:
            return Colors.yellow;
        case Color.blue:
            return Colors.blue;
        case Color.darkblue:
            return Colors.darkblue;
        case Color.error:
            return Colors.error;
        case Color.green:
            return Colors.grassGreen;
        case Color.lightblue:
            return Colors.lightblue;
    }
}

export interface ModalButton {
    caption: React.ReactNode;
    callback: () => void;
    disabled?: boolean;
}

export interface ModalTitle {
    caption: React.ReactNode;
    color: Color;
}

const borderStyle = `1px solid #cccccc`;

interface ConfirmableProps {
    title: ModalTitle;
    confirm?: ModalButton;
    cancel?: ModalButton;
    onClose?: () => void;
    children?: React.ReactNode;
    isPending?: boolean;
    arrowPosition?: ArrowPosition;
    portal?: SlideInPortalId;
}

function Confirm(props: ConfirmableProps) {
    const confirmableColor = getConfirmableColor(props.title.color);
    return (
        <div>
            {props.arrowPosition !== undefined &&
                props.arrowPosition !== ArrowPosition.none && (
                    <div
                        className={css({
                            width: '0px',
                            height: '0px',
                            borderStyle: 'solid',
                            borderWidth: '0 11px 11px 11px',
                            position: 'relative',
                            borderColor: `transparent transparent ${confirmableColor} transparent`,
                            left:
                                props.arrowPosition === ArrowPosition.middle
                                    ? '45%'
                                    : props.arrowPosition === ArrowPosition.left
                                      ? '24px'
                                      : undefined,
                            right:
                                props.arrowPosition === ArrowPosition.right
                                    ? '24px'
                                    : undefined,
                        })}
                    />
                )}

            <div
                className={css([
                    {
                        ...paper(2),
                        fontSize: '16px',
                        position: 'relative',
                        background: Colors.white,
                        minWidth: '300px',
                        maxWidth: '452px',
                        width: '100%',
                        overflow: 'hidden',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        marginBottom: '24px',
                    },
                    props.arrowPosition === ArrowPosition.none && {
                        marginTop: '11px',
                    },
                ])}
            >
                <div
                    className={css({
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: confirmableColor,
                    })}
                >
                    <div
                        className={css({
                            color: Colors.white,
                            flex: 1,
                            paddingLeft: '20px',
                            ...Typo.heading4,
                        })}
                    >
                        {props.title.caption}
                    </div>
                    {props.onClose && (
                        <Clickable
                            element="div"
                            className={css({
                                width: '48px',
                                color: Colors.white,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            })}
                            onClick={props.onClose}
                        >
                            <Icon24 icon={Icons24.clear_b} />
                        </Clickable>
                    )}
                </div>
                <div
                    className={css({
                        ...Typo.robotoRegular,
                        position: 'relative',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        flexGrow: 1,
                        padding: '5px 20px',
                        lineHeight: '22px',
                        overflow: 'hidden',
                        minHeight: '80px',
                        color: Colors.typo1,
                    })}
                >
                    {props.isPending ? (
                        <div
                            className={css({
                                background: Colors.white,
                                height: 'calc(100% - 10px)',
                                width: 'calc(100% - 40px)',
                                position: 'absolute',
                            })}
                        >
                            <Spinner centerVertically={true} />
                        </div>
                    ) : null}
                    {props.children}
                </div>
                <div
                    className={css({
                        display: 'flex',
                    })}
                >
                    {props.cancel && (
                        <Button
                            buttonRightExists={!!props.confirm}
                            disabled={
                                !!props.isPending || props.cancel.disabled
                            }
                            {...props.cancel}
                        />
                    )}
                    {props.confirm && (
                        <Button
                            drawLeftBorder={!!props.cancel}
                            fontWeight={800}
                            disabled={
                                !!props.isPending || props.confirm.disabled
                            }
                            {...props.confirm}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function Button(
    props: ModalButton & {
        fontWeight?: number;
        drawLeftBorder?: boolean;
        buttonRightExists?: boolean;
    },
) {
    return (
        <Clickable
            element="div"
            disabled={props.disabled}
            className={css({
                flex: '1 0 50%',
                color: props.disabled ? Colors.disabledButtonGrey : Colors.blue,
                fontWeight: props.fontWeight ? props.fontWeight : 400,
                fontSize: props.fontWeight ? '17px' : '16px',
                textTransform: 'uppercase',
                lineHeight: '50px',
                textAlign: 'center',
                borderTop: borderStyle,
                borderLeft: props.drawLeftBorder ? borderStyle : 'none',
                whiteSpace: 'nowrap',
                cursor: props.disabled ? 'auto' : 'pointer',
                ':active': props.disabled
                    ? {}
                    : {
                          color: Colors.white,
                          backgroundColor: Colors.lightblue,
                          backgroundSize: '100%',
                      },
            })}
            onClick={props.callback}
        >
            {props.caption}
        </Clickable>
    );
}

export function InlineComfirmable(props: ConfirmableProps) {
    return (
        <div
            className={css({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...Typo.robotoRegular,
            })}
        >
            <Confirm {...props} />
        </div>
    );
}

function ModalConfirmable(props: ConfirmableProps) {
    return (
        <Modal portal={props.portal || SlideInPortalId.FULL_SCREEN_MODAL}>
            <div
                className={css({
                    position: 'absolute',
                    padding: '0px 24px 0px 24px',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    transition: 'opacity 0.5s, visibility 0s 0.5s',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(4, 4, 15, 0.7)',
                    zIndex: 100, // Needs to be higher than TopNav items (or any other items),
                    ...Typo.robotoRegular,
                })}
            >
                <Confirm {...props} />
            </div>
        </Modal>
    );
}

interface NotificationBoxProps {
    titleCaption?: React.ReactNode;
    confirmCaption?: ButtonText;
    confirmCallback: () => void;
    confirmDisabled?: boolean;
    children: React.ReactNode;
    isPending?: boolean;
    portal?: SlideInPortalId;
}

interface QuestionBoxProps {
    titleCaption: React.ReactNode;
    confirmCaption?: ButtonText;
    confirmCallback: () => void;
    cancelCaption?: ButtonText;
    cancelCallback?: () => void;
    children: React.ReactNode;
    isPending?: boolean;
    arrowPosition?: ArrowPosition;
    portal?: SlideInPortalId;
}

interface InlineBoxProps {
    titleCaption?: React.ReactNode;
    children: React.ReactNode;
    arrowPosition?: ArrowPosition;
    onClose?: () => void;
}

interface InlineBoxWithButtonPros {
    titleCaption?: React.ReactNode;
    confirmCaption?: ButtonText;
    confirmCallback?: () => void;
    children: React.ReactNode;
    arrowPosition?: ArrowPosition;
}

interface FixedButtonTextBoxProps {
    titleCaption: React.ReactNode;
    confirmCallback: () => void;
    cancelCallback: () => void;
    children: React.ReactNode;
    isPending?: boolean;
    arrowPosition?: ArrowPosition;
    portal?: SlideInPortalId;
}

function standardText(type: ButtonText): Message {
    switch (type) {
        case ButtonText.YES:
            return { de: 'Ja', fr: 'Oui', it: 'Sì', en: 'Yes' };

        case ButtonText.NO:
            return { de: 'Nein', fr: 'Non', it: 'No', en: 'No' };

        case ButtonText.CANCEL:
            return {
                de: 'Abbrechen',
                fr: 'Annuler',
                it: 'Annulla',
                en: 'Cancel',
            };

        case ButtonText.CONTINUE:
            return {
                de: 'Weiter',
                fr: 'Continuer',
                it: 'Continua',
                en: 'Proceed',
            };

        case ButtonText.DELETE:
            return {
                de: 'Löschen',
                fr: 'Effacer',
                it: 'Elimina',
                en: 'Delete',
            };
        case ButtonText.OK:
            return {
                de: 'OK',
                fr: 'OK',
                it: 'OK',
                en: 'OK',
            };
        case ButtonText.CLOSE:
            return {
                de: 'Schliessen',
                fr: 'Fermer',
                it: 'Chiudi',
                en: 'Close',
            };
        case ButtonText.EDIT:
            return {
                de: 'Ändern',
                fr: 'Modifier',
                it: 'Modifica',
                en: 'Modify',
            };
        case ButtonText.SHOW:
            return {
                de: 'Anzeigen',
                fr: 'Afficher',
                it: 'Mostra',
                en: 'Show',
            };
        case ButtonText.TOPUP:
            return {
                de: 'Laden',
                fr: 'Alimenter',
                it: 'Ricarica',
                en: 'Topup',
            };
        case ButtonText.RESEND:
            return {
                de: 'Erneut senden',
                fr: 'Renvoyer',
                it: 'Ripeti invio',
                en: 'Resend',
            };
        case ButtonText.ADD:
            return {
                de: 'Hinzufügen',
                fr: 'Ajouter',
                it: 'Aggiungi',
                en: 'Add',
            };
    }
}

function chooseText(
    custom: ButtonText | undefined | null,
    standard: ButtonText,
): React.ReactNode {
    if (!custom) {
        return <Localized {...standardText(standard)} />;
    }

    return <Localized {...standardText(custom as ButtonText)} />;
}

export function ModalInfoBox(props: NotificationBoxProps) {
    return (
        <ModalConfirmable
            title={{
                caption: props.titleCaption || (
                    <Localized de="Info" fr="Info" it="Info" en="Info" />
                ),
                color: Color.blue,
            }}
            confirm={{
                caption: chooseText(props.confirmCaption, ButtonText.OK),
                callback: props.confirmCallback,
                disabled: props.confirmDisabled,
            }}
            isPending={props.isPending}
            portal={props.portal}
        >
            {props.children}
        </ModalConfirmable>
    );
}

export function ModalAlertBox(props: NotificationBoxProps) {
    return (
        <ModalConfirmable
            title={{
                caption: props.titleCaption || (
                    <Localized
                        de="Warnung"
                        fr="Avertissement"
                        it="Avviso"
                        en="Warning"
                    />
                ),
                color: Color.yellow,
            }}
            confirm={{
                caption: chooseText(props.confirmCaption, ButtonText.OK),
                callback: props.confirmCallback,
                disabled: props.confirmDisabled,
            }}
            isPending={props.isPending}
            portal={props.portal}
        >
            {props.children}
        </ModalConfirmable>
    );
}

export function ModalErrorBox(props: NotificationBoxProps) {
    return (
        <ModalConfirmable
            title={{
                caption: props.titleCaption || (
                    <Localized de="Fehler" fr="Erreur" it="Errore" en="Error" />
                ),
                color: Color.error,
            }}
            confirm={{
                caption: chooseText(props.confirmCaption, ButtonText.OK),
                callback: props.confirmCallback,
                disabled: props.confirmDisabled,
            }}
            isPending={props.isPending}
            portal={props.portal}
        >
            {props.children}
        </ModalConfirmable>
    );
}

export function ModalSuccessBox(props: NotificationBoxProps) {
    return (
        <ModalConfirmable
            title={{ caption: props.titleCaption, color: Color.green }}
            confirm={{
                caption: chooseText(props.confirmCaption, ButtonText.OK),
                callback: props.confirmCallback,
                disabled: props.confirmDisabled,
            }}
            isPending={props.isPending}
            portal={props.portal}
        >
            {props.children}
        </ModalConfirmable>
    );
}

export function ModalQuestionBox(props: QuestionBoxProps) {
    return (
        <ModalConfirmable
            title={{ caption: props.titleCaption, color: Color.yellow }}
            confirm={{
                caption: chooseText(props.confirmCaption, ButtonText.YES),
                callback: props.confirmCallback,
            }}
            cancel={
                props.cancelCallback !== undefined
                    ? {
                          caption: chooseText(
                              props.cancelCaption,
                              ButtonText.NO,
                          ),
                          callback: props.cancelCallback,
                      }
                    : undefined
            }
            isPending={props.isPending}
            portal={props.portal}
        >
            {props.children}
        </ModalConfirmable>
    );
}

export function ModalDeleteQuestionBox(props: FixedButtonTextBoxProps) {
    return (
        <ModalQuestionBox
            {...props}
            confirmCaption={ButtonText.DELETE}
            cancelCaption={ButtonText.CANCEL}
            portal={props.portal}
        />
    );
}

export function InlineQuestionBox(props: QuestionBoxProps) {
    return (
        <InlineComfirmable
            title={{ caption: props.titleCaption, color: Color.yellow }}
            confirm={{
                caption: chooseText(props.confirmCaption, ButtonText.YES),
                callback: props.confirmCallback,
            }}
            cancel={
                props.cancelCallback !== undefined
                    ? {
                          caption: chooseText(
                              props.cancelCaption,
                              ButtonText.NO,
                          ),
                          callback: props.cancelCallback,
                      }
                    : undefined
            }
            isPending={props.isPending}
            arrowPosition={props.arrowPosition}
        >
            {props.children}
        </InlineComfirmable>
    );
}

export function InlineErrorBox(props: InlineBoxWithButtonPros) {
    return (
        <InlineComfirmable
            title={{
                caption: props.titleCaption || (
                    <Localized de="Fehler" fr="Erreur" it="Errore" en="Error" />
                ),
                color: Color.error,
            }}
            confirm={
                props.confirmCallback !== undefined
                    ? {
                          caption: chooseText(
                              props.confirmCaption,
                              ButtonText.CANCEL,
                          ),
                          callback: props.confirmCallback,
                      }
                    : undefined
            }
            arrowPosition={props.arrowPosition}
        >
            {props.children}
        </InlineComfirmable>
    );
}

export function InlineInfoBox(props: InlineBoxProps) {
    return (
        <InlineComfirmable
            title={{
                caption: props.titleCaption || (
                    <Localized de="Info" fr="Info" it="Info" en="Info" />
                ),
                color: Color.darkblue,
            }}
            arrowPosition={props.arrowPosition}
            onClose={props.onClose}
        >
            {props.children}
        </InlineComfirmable>
    );
}

export function InlineAlertBox(props: InlineBoxProps) {
    return (
        <InlineComfirmable
            title={{
                caption: props.titleCaption || (
                    <Localized
                        de="Warnung"
                        fr="Avertissement"
                        it="Avviso"
                        en="Warning"
                    />
                ),
                color: Color.yellow,
            }}
            arrowPosition={props.arrowPosition}
        >
            {props.children}
        </InlineComfirmable>
    );
}
