import { Message } from '../../../lib/Localized';

export function joinMessage(messages: Message[], separator = ''): Message {
    return messages.reduce(
        (joinedMessage, currentMessage, currentIndex) => {
            if (currentIndex === 0) {
                return {
                    de: currentMessage.de,
                    fr: currentMessage.fr,
                    it: currentMessage.it,
                    en: currentMessage.en,
                };
            }

            return {
                de: joinedMessage.de + separator + currentMessage.de,
                fr: joinedMessage.fr + separator + currentMessage.fr,
                it: joinedMessage.it + separator + currentMessage.it,
                en: joinedMessage.en + separator + currentMessage.en,
            };
        },
        { de: '', fr: '', it: '', en: '' } as Message,
    );
}

export function capitalizeFirstLetterOfMessage(message: Message): Message {
    return {
        de: capitalizeFirstLetter(message.de),
        fr: capitalizeFirstLetter(message.fr),
        it: capitalizeFirstLetter(message.it),
        en: capitalizeFirstLetter(message.en),
    };
}

function capitalizeFirstLetter(string: string) {
    if (string.length === 0) {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}
