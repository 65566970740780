import { useStore } from 'dg-web-shared/lib/Flux';
import { Language } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../common/components/Localized';
import * as AccountBalanceState from '../../common/state/AccountBalanceState';
import { Formatter } from '../../utils/Date';
import { numberToPrice } from '../../utils/NumberFormatter';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { MonthlyReportsListSlideIn } from './MonthlyReportsList';
import { StatementDetailsSlideIn } from './StatementDetails';
import { usePaymentTypeState } from '../../common/state/PaymentTypeState';
import { PaymentMethodEnum } from '../../api/Http';
import { MainMenuItemBlock } from '../root/components/ItemBlock';
import { Colors } from 'dg-web-shared/ui/vars';
import { css } from '@emotion/css';
import { useState } from 'react';
import { DateTime } from 'luxon';

export type MonthReportsMenuView = MenuView | ListView | DetailsView;

type MenuView = { tag: ViewTag.MENU };
type ListView = { tag: ViewTag.LIST };
type DetailsView = { tag: ViewTag.DETAILS; yyyyMM: string };

enum ViewTag {
    MENU,
    LIST,
    DETAILS,
}

export function MonthlyReportsMenu({
    language,
    accountBalance,
}: {
    language: Language;
    accountBalance: AccountBalanceState.Balance;
}) {
    const [overviewSlideInOpen, setOverviewSlideInOpen] = useState(false);
    const [detailsSlideInOpen, setDetailsSlideInOpen] = useState(false);
    const [selectedDetailMonth, setSelectedDetailMonth] = useState<string>('');
    return (
        <div>
            <ListDisplayTrigger onClick={() => setOverviewSlideInOpen(true)} />
            <MonthlyReportsListSlideIn
                language={language}
                open={overviewSlideInOpen}
                portal={SlideInPortalId.USER_ACCOUNT}
                title={<Title />}
                onClose={() => setOverviewSlideInOpen(false)}
                accountBalance={accountBalance}
                onMonthClick={yyyyMM => {
                    setSelectedDetailMonth(yyyyMM);
                    setDetailsSlideInOpen(true);
                }}
            />
            <StatementDetailsSlideIn
                language={language}
                yyyyMM={selectedDetailMonth}
                open={detailsSlideInOpen}
                portal={SlideInPortalId.USER_ACCOUNT}
                title={detailsTitle(language, selectedDetailMonth)}
                onClose={() => setDetailsSlideInOpen(false)}
            />
        </div>
    );
}

interface ListDisplayTriggerProps {
    onClick: () => void;
}

const ListDisplayTrigger = (props: ListDisplayTriggerProps) => {
    const { storeState } = useStore(store => ({
        accountBalance: new AccountBalanceState.StateSlice(store).state,
    }));

    const [paymentType] = usePaymentTypeState();
    if (!paymentType.data || !storeState.accountBalance.data) {
        return null;
    }
    const balanceText = storeState.accountBalance.data
        ? numberToPrice(storeState.accountBalance.data.balance)
        : '-';

    const isDirect =
        paymentType.data.activePaymentMethod.activePaymentType ===
        PaymentMethodEnum.DIRECT;

    const zeroSaldoDirect =
        isDirect && storeState.accountBalance.data.balance === 0;

    const negativeSaldoDirect =
        isDirect && storeState.accountBalance.data.balance < 0;

    return (
        <MainMenuItemBlock
            onClick={props.onClick}
            title={<Title />}
            rightTitle={
                zeroSaldoDirect ? null : (
                    <span
                        className={
                            negativeSaldoDirect
                                ? css`
                                      color: ${Colors.red};
                                  `
                                : ''
                        }
                    >
                        <Localized
                            de="Saldo"
                            fr="Solde"
                            it="Saldo"
                            en="Balance"
                        />{' '}
                        {balanceText}
                    </span>
                )
            }
        />
    );
};

function Title() {
    return (
        <Localized
            de="Kontoauszug"
            fr="Relevé de compte"
            it="Estratto conto"
            en="Account statement"
        />
    );
}

function detailsTitle(language: Language, yyyyMM: string): string {
    return Formatter.monthNameYear(
        DateTime.fromISO(yyyyMM),
        (Formatter.Locale as any)[language],
    );
}
