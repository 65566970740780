import { generatePersistentStateSlice } from 'dg-web-shared/lib/PersistentStateSlice';

export const HAS_EVER_LOGGED_IN_STATE_KEY = 'unauth-landing-has-ever-logged-in';

export namespace HasEverLoggedInState {
    export interface State {
        hasEver: boolean;
    }
    export const { get, stateWrite } = generatePersistentStateSlice(
        HAS_EVER_LOGGED_IN_STATE_KEY,
        {
            hasEver: false,
        },
    );
}
