import styled from '@emotion/styled';

import { Typo } from '../../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { TicketProcessIcon } from 'dg-web-shared/ui/icons/IconsScalable';
import { QuickCheckoutConstants } from '../../../quickcheckout/common/QuickCheckoutConstants';

export type TicketLayoutParkingpay = 'ticketplus' | 'contractview';
export const TicketLayout = styled.div(
    (props: { type: TicketLayoutParkingpay }) =>
        ({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            background: getBackgroundColor(props.type),
            color: props.type === 'contractview' ? Colors.black : Colors.white,
            ...Typo.body,
        }) as const,
);

function getBackgroundColor(type: TicketLayoutParkingpay) {
    switch (type) {
        case 'contractview':
            return Colors.white;
        case 'ticketplus':
            return Colors.blue;
    }
}

export const TicketContent = styled.div({
    flexGrow: 1,
    padding: `0 ${QuickCheckoutConstants.BASE_PADDING}`,
    overflow: 'auto',
    flexShrink: 1,
});

export const labelStyle = {
    padding: '20px 0 5px 0',
    '&:first-child': {
        paddingTop: QuickCheckoutConstants.BASE_PADDING,
    },
    opacity: 0.8,
    flexShrink: 0,
    ...Typo.caption,
};
export const TicketInfoHeader = styled.div(labelStyle);

export const InfoFieldWithIcon = ({
    children,
    onClick,
}: {
    children: React.ReactNode;
    onClick: () => void;
}) => (
    <div
        style={{
            justifyContent: 'space-between',
            display: 'flex',
        }}
    >
        {children}
        <Clickable
            element="div"
            className={css({ height: '22px' })}
            onClick={onClick}
        >
            <MoreHorizIcon />
        </Clickable>
    </div>
);

export const TicketContentText = styled.div({
    padding: '8px 0',
});

export const TicketTitle = ({
    children,
    paddingBottom,
}: {
    children: React.ReactNode;
    paddingBottom?: boolean;
}) => (
    <div
        className={css({
            paddingTop: '32px',
            textAlign: 'center',
            paddingBottom: paddingBottom ? '16px' : undefined,
            ...Typo.heading4,
        })}
    >
        {children}
    </div>
);
export const TicketSubtitle = styled.div({
    paddingTop: '24px',
});

export const TicketApiString = ({
    ticketApiString,
}: {
    ticketApiString: string;
}) => (
    <div
        className={css({
            textAlign: 'center',
            opacity: 0.8,
            ...Typo.tBody,
        })}
    >
        <p>Ticket-ID {ticketApiString}</p>
    </div>
);

export const ProcessStep = ({
    icon,
    children,
}: {
    icon: 'ticket' | 'exitStation' | 'barrier';
    children: React.ReactNode;
}) => (
    <div
        className={css({
            display: 'flex',
            paddingTop: '16px',
        })}
    >
        <div
            className={css({
                width: 54,
                height: 54,
                position: 'relative',
                flexShrink: 0,
                marginTop: 14,
                marginRight: 24,
            })}
        >
            <TicketProcessIcon icon={icon} size={'small'} />
        </div>
        <TicketContentText>{children}</TicketContentText>
    </div>
);

export const TicketFooterContainer = styled.div({
    padding: '0 28px 28px 28px',
    bottom: '28px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.45)',
    backgroundColor: Colors.darkblue,
});
