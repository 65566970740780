import * as Sentry from '@sentry/browser';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Response, StatusCode } from 'dg-web-shared/lib/HttpResponse';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import { ApiError } from '../../api/ApiError';

/**
 * @Deprecated Use validation error responses not magic numbers.
 */
export enum ResponseCode {
    NONE = -99999,
    NOT_PROVIDED = -9999,

    ERR_INTERN = 0,

    // OK
    API_TX_OK = 1,

    ERR_OK_CANCELED = 2,
    ERR_OK_STOPPED = 3,
    ERR_OK_STATUS_LOGGED_IN = 4,
    ERR_OK_STATUS_NOT_LOGGED_IN = 5,
    ERR_OK_NO_PARKING_TIME = 8,

    // return codes for general errors
    ERR_INPUT_ERROR = -1,
    ERR_NOT_ACTIVATED_YET = -4,
    ERR_NOT_REGISTERED = -5,
    ERR_WRONG_LICENSE_NUMBER = -6,

    // return codes for errors while starting a TX
    ERR_WRONG_TELNR = -11,
    ERR_WRONG_SMS_CODE = -12,
    ERR_USER_BLOCKED = -13,
    ERR_ALREADY_LOGGED_IN = -14,
    ERR_WRONG_IVR_CODE = -15,
    ERR_NO_PARKING_TIME = -16,
    ERR_CHECKIN_NOT_ALLOWED = -17,
    ERR_MINUTES_NOT_ALLOWED = -18,
    // ERR_WRONG_DURATION = -19,
    ERR_MIN_DURATION = -20,
    ERR_MAX_DURATION = -21,
    ERR_NOT_ENOUGH_BALANCE_MIN = -22,
    ERR_NOT_ENOUGH_BALANCE_TX = -23,

    // return codes for errors while stopping a TX
    ERR_NOT_LOGGED_IN = -31,
    // ERR_TOO_MANY_LOGGED_IN = -32,
    ERR_MINUTE_CHECKOUT_NOT_ALLOWED = -33,
    // ERR_CANCEL_NOT_POSSIBLE = -34,
    ERR_TX_WILL_END = -35, // already stopped, but not finished yet.
    ERR_TRANSACTION_NOT_ALLOWED = -40,
    ERR_VIGNETTE_REQUIRED = -41,

    // Errors
    ERROR_JSON_PARSE_ERROR = -100,
    ERROR_BODY_EMPTY = -114,
    ERROR_INTERNAL_ERROR = -102,
    NAVBITS_NOT_SET = -163,

    // Additional Errors
    BADGE_NOT_FOUND = -103,
    LOGIN_NOT_FOUND = -104,
    CUSTOMER_NOT_FOUND = -105,

    ESR_ORDERED_TOO_EARLY = -429,

    // Voucher ERRORS
    VOUCHER_UNKNOWN = -106,
    VOUCHER_ALREADY_USED = -107,
    ERR_VOUCHER_NOT_VALID_YET = -108,
    ERR_VOUCHER_NOT_VALID_ANYMORE = -109,
    ERR_VOUCHERCODE_MANDATORY = -123,

    // BankAccount ERRORS
    BANKACCOUNT_TYPE_MANDANTORY = -110,
    BANKACCOUNT_IBAN_MANDANTORY = -111,
    BANKACCOUNT_NOT_FOUND = -112,
    INVALID_IBAN = -113,

    // /license-plate ERRORS
    INVALID_LPID = -118,
    REMARK_TOO_LONG = -119,
    REMARK_MANDATORY = -210,
    CHANGEABLE_PLATE_MANDATORY = -211,

    LICENSENUMBER_MANDANTORY = -124,
    LICENSENUMBER_SENDLABEL_MANDANTORY = -125,
    LICENSENUMBER_ALREADY_EXISTS = -126,
    LICENSENUMBER_TYPEID_MANDANTORY = -127,
    LICENSENUMBER_COUNTRYID_MANDANTORY = -128,

    // Login ERRORS
    LOGIN_ID_INVALID = -120,
    LOGIN_ROLE_MANDANTORY = -129,

    BADGE_ACTIVE_MANDANTORY = -121,
    BADGE_REMARKS_MANDANTORY = -122,

    // Change Password ERRORS
    OLD_PW_MANDATORY = -133,
    NEW_PW_MANDATORY = -134,
    OLD_PW_INVALID = -135,

    // Meta Data ERRORS
    METADATA_ACCOUNTTYPE_MANDATORY = -136,
    METADATA_LANGUAGE_MANDATORY = -137,
    METADATA_GENDER_MANDATORY = -138,

    // Zona-Transaction Errors
    FROM_MANDANTORY = -139,
    FROM_INVALID = -140,
    TO_MANDANTORY = -141,
    TO_INVALID = -142,
    TXQUERY_PERIOD_TOO_BIG = -143,
    TO_BEFORE_FROM = -144,

    ZONA_TX_PLZ_MANDATORY = -145,
    ZONA_TX_ZONCODE_MANDATORY = -146,
    ZONA_TX_LICENSPLATE_MANDATORY = -147,
    ZONA_TX_CALC_ONLY_MANDATORY = -148,
    ZONA_TX_LP_NOT_FOUND = -149,
    ZONA_TX_ZONECODE_INVALID = -152,

    STOP_ZONA_TX_PID_NOT_FOUND = -150,
    STOP_ZONA_TX_PID_INVALID = -151,

    LABELNR_INVALID = -209,
    LABELNR_EXISTS = -153,
    CARID_NOT_EXISTS = -154,

    LICENSEPLATE_INVALID = -155,
    DURATION_INVALID = -156,

    PERMITTYPE_ID_INVALID = -157,

    INVALID_URL = -158,

    // Whitelist Errors
    CUSTOMERACCOUNTNR_MANDATORY = -159,

    PERMITGRANT_ENTRY_EXISTS = -160,
    WHITELIST_PERSONAL_NUMBER_MANDATORY = -161,
    WHITELIST_CONTRACT_NUMBER_MANDATORY = -162,

    WHITELIST_INVALID_DELETE_COMMAND = -164,
    NO_WHITELIST_ENTRY_FOUND = -165,

    LP_EXISTS = -166,
    LABEL_FOR_CAR_EXISTS = -167,
    FIELD_REQUIRED = -168,

    // Permit PURCHASE ERRORS
    TIMEUNIT_NOT_ALLOWED = -169,
    TIMEUNIT_INVALID = -170,

    TIME_INVALID = -171,
    TO_DATE_INVALID = -172,
    PERMIT_REQUEST_GENERAL_INVALID = -173,
    PERMIT_CALC_ENDDATE_ERROR = -175,

    // Permit Storno
    PERMIT_STORNO_PERMIT_NOT_FOUND = -176,
    PERMIT_STORNO_NOT_PAID_BY_CUSTOMER = -177,
    PERMIT_STORNO_NO_PRICE = -178,
    PERMIT_STORNO_PERMITID_INVALID = -179,
    PERMIT_STORNO_PERMIT_ALRDY_CANCELED = -180,

    // Zone-Permit ERRORS
    PERMIT_PURCHASE_NO_LP = -181,
    PERMIT_PURCHASE_TOO_MUCH_LP = -182,

    PERMIT_PURCHASE_NO_ZONE_LIST = -183,
    PERMIT_PURCHASE_PAYMENT_NOT_AUTHORIZED = -184,
    PERMIT_PURCHASE_LABEL_REQUIRED = -185,
    PERMIT_PURCHASE_LP_NOT_ON_WHITEIST = -186,

    INVOICE_ID_NOT_FOUND = -187,

    EMAIL_NOT_FOUND = -188,
    TOKEN_MANDATORY = -189,
    CHANGE_PWD_INVALID_TOKEN = -190,

    WHITELIST_EMAIL_OR_TELNR_MANDATORY = -191,
    WHITELIST_EMAIL_ALREADY_REGISTERED = -192,
    WHITELIST_TELNR_ALREADY_REGISTERED = -193,
    WHITELIST_TELNR_OR_EMAIL = -194,

    AMOUNT_INVALID = -195,

    BADGETYPE_INVALID = -196,

    REMINDER_EMAILACTIVE_MISSING = -197,
    REMINDER_PHONEACTIVE_MISSING = -198,
    REMINDER_AMOUNTTRHESHOLD_MISSING = -199,

    REMINDER_DAYSTRHESHOLD_MISSING = -300,
    REMINDER_EXPIRYTRESHOLD_MISSING = -304,
    REMINDER_STARTISACTIVE_MISSING = -305,
    REMINDER_PHONEOREMAIL_MANDATORY = -309,
    REMINDER_DURATIONACTIVE_MISSING = -337,
    REMINDER_DURATIONTRESHOLD_MISSING = -338,
    REMINDER_EXPIRYACTIVE_MISSING = -339,

    INVALID_ISO_YEAR_MONTH = -306,

    PERMITDAYSBEFOREEXPIRY_MANDANTORY = -307,
    REMINDERMINUTESBEFOREEXPIRY_MANDANTORY = -308,

    EMAIL_INVALID = -310,

    PERMIT_SINGLE_ZONE_CHOICE_ERROR = -311,
    ZONE_NOT_ALLOWED = -312,
    PERMIT_MULTIPLE_ZONE_CHOICE_ERROR = -313,
    PERMIT_FIXED_ZONE_CHOICE_ERROR = -314,

    CUSTOMER_MIGRATION_EMAIL_EQ_USERNAME = -315,

    VOUCHERNR_MANDATORY = -316,
    VOUCHER_ERR_INTERN = -317,
    VOUCHER_ERR_VOUCHER_UNKNOWN = -318,
    VOUCHER_ERR_VOUCHER_ALREADY_USED = -319,
    VOUCHER_ERR_VOUCHER_NOT_VALID_YET = -320,
    VOUCHER_ERR_VOUCHER_NOT_VALID_ANYMORE = -321,

    ZONEID_INVALID = -322,
    MANDANT_ZONE_NOT_FOUND = -323,
    NO_DURATION_ZONE = -324,
    NO_CHECKIN_ZONE = -325,

    DELETE_NOT_POSSIBLE_BOOKINGS = -326,

    REG_COMPANY1_MANDATORY = -327,
    NO_PRIVILEGES = -328,

    REG_LINE1_MANDATORY = -331,
    REG_LINE2_MANDATORY = -332,

    // Whitelist Request
    WHITELIST_REQUEST_PERMITTYPE_MANDATORY = -329,
    WHITELIST_REQUEST_LPIDS_MANDATORY = -330,

    ACTIVATE_CUSTOMER_ALRDY_ACTIVATED = -212,
    ACTIVATE_CUSTOMER_TOKEN_INVALID = -213,

    // GATE-PERMITS
    GATEPERMIT_PURCHASE_NO_BADGE_ID = -333,
    GATEPERMIT_BADGE_NOT_ON_WHITELIST = -334,
    GATEPERMIT_PERMIT_ALREADY_ACTIVE = -335,
    PERMIT_ALREADY_ACTIVE = -336,

    // Permit Fehlermeldungen
    ERR_INVALID_FROM_DATE = -201,
    ERR_INVALID_TO_DATE = -202,
    ERR_TO_DATE_SMALLER_FROM = -203,
    ERR_TO_DATE_NOT_IN_FUTURE = -204,
    ERR_MIN_DAYS = -205,
    ERR_MAX_DAYS = -206,
    ERR_MIN_START_DATE = -207,

    // Fehlermeldungen für PrepaidCard Register
    ERR_INVALID_MANDANT_ID = -301,
    ERR_BAGDE_ALRDY_REGISTERED = -302,
    ERR_BAGDE_INVALID = -303,

    ERR_INVALID_PASSWORD = -101,

    GATEPERMIT_LIMIT_REACHED = -340,
    PERMIT_PURCHASE_LICENSE_GROUP_NOT_ALLOWED = -208,

    // Register Errors
    LASTNAME_MANDATORY = -701,
    FIRSTNAME_MANDATORY = -702,
    EMAIL_MANDATORY = -703,
    PWD_MANDATORY = -704,
    LANGUAGE_MANDATORY = -705,
    BADGE_INVALID = -706,
    LABEL_WITHOUT_CAR = -707,
    EMAIL_NOT_UNIQUE = -708,
    INVALID_LP = -709,
    INVALID_BADGE = -710,
    BADGETYPE_MANDATORY = -711,
    BADGE_NOT_UNIQUE = -712,
    ADRESS_MANDATORY = -713,
    ZIP_MANDATORY = -714,
    CITY_MANDATORY = -715,
    COUNTRY_MANDATORY = -716,
    LP_MANDATORY = -717,
    MOBILE_NOT_UNIQUE = -718,
    REG_LOGIN_OBJECT_MANDATORY = -719,
    REG_LICENSE_OBJECT_MANDATORY = -720,
    REG_ADRESS_OBJECT_MANDATORY = -721,
    LP_COUNTRY_MANDATORY = -722,
    LP_TYPE_MANDATORY = -723,
    ACCOUNTTYPE_MANDATORY = -724,
    CORRESPONDLANGUAGE_MANDATORY = -725,
    SENDBADGE_MANDATORY = -726,

    APIERROR_CANCEL_ACCOUNT_NEGATIVE_ACCOUNT_BALANCE = -350,
    APIERROR_CANCEL_ACCOUNT_IBAN_MANDATORY = -351,
    APIERROR_CANCEL_ACCOUNT_VOUCHERCREDITS = -349,
    CANCEL_ACCOUNT_WRONG_PW = -365,
    EMAIL_NOT_UNIQUE_EDIT = -2016,
    WHITELIST_MISSING = -2020,
    CLEARANCE_REQUEST_ALLREADY_EXISTS = -2031,
    ERROR_ACTIVATING_CUSTOMER = -2032,
}

export interface State<D = any> {
    pending: boolean;
    statusCode: StatusCode;
    responseCode: ResponseCode;
    apiError?: Maybe<ApiError>;
    attempts: number;
    data: D;
}

function captureError(s: State, res: Response): void {
    if (!res.statusCode.cls.error || res.statusCode.badRequest) {
        return;
    }
    if (res.statusCode.timeout) {
        Sentry.captureMessage('Request Timeout');
        return;
    }
    const payload = (<any>res.request)._data;

    Sentry.withScope(scope => {
        scope.setContext('request data', {
            value: {
                payload: JSON.stringify(payload, null, 2),
                body: JSON.stringify(res.body, null, 2),
            },
        });
        Sentry.captureMessage(
            `${res.request.url} ${res.statusCode.statusCode} ${s.responseCode}`,
        );
    });
}

export function writeResponseToState(s: State, res: Response): void {
    if (res.pending) {
        s.pending = true;
    } else {
        s.pending = false;
        s.statusCode = res.statusCode;
        s.responseCode =
            res.body && res.body.code
                ? res.body.code
                : ResponseCode.NOT_PROVIDED;
        s.apiError =
            res.body && res.body.code && res.body.message
                ? res.body.message
                : null;
        s.attempts += 1;
        s.data = res.body;
    }
    if (!s.statusCode.unauthorized && !s.statusCode.noResponse) {
        captureError(s, res);
    }
}

export abstract class StateSlice extends Flux.StateSlice<State> {
    sideEffects(): void {
        return;
    }

    getInitialState(): State {
        return {
            pending: false,
            statusCode: new StatusCode(null),
            responseCode: ResponseCode.NONE,
            attempts: 0,
            data: null,
        };
    }

    writeResponse(res: Response): void {
        this.set((s: State): State => {
            writeResponseToState(s, res);
            return s;
        });
    }
}

interface GeneratorFunctions<D> {
    get: (store: Flux.Store) => State<D>;
    reset: (store: Flux.Store) => void;
    setResponse: (store: Flux.Store, res: Response) => void;
}

export const generate = <D = any>(key: string): GeneratorFunctions<D> => {
    class GenServerStateSlice extends StateSlice {
        key(): string {
            return key;
        }
    }

    return {
        get: (store: Flux.Store): State<D> =>
            new GenServerStateSlice(store).state,
        reset: (store: Flux.Store): void =>
            new GenServerStateSlice(store).reset(),
        setResponse: (store: Flux.Store, res: Response): void =>
            new GenServerStateSlice(store).writeResponse(res),
    };
};
