import { Slope } from './Tariff';

/**
 * Calculates the cost for a given parking time and tariff definition.
 * Used by Parkingpay logic
 */

class CurveTimeWalker {
    private walkedRappen: number;
    private targetMillis: number;
    private walkedMiliSecs: number;
    fullyConsumed: boolean;

    constructor(targetMillis: number) {
        this.walkedRappen = 0;
        this.targetMillis = targetMillis;
        this.walkedMiliSecs = 0;
        this.fullyConsumed = false;
    }

    private get millisRequiredToTarget(): number {
        return this.targetMillis - this.walkedMiliSecs;
    }

    /**
     * walks over one given curve segment and internally tracks the used currency amount and the awarded parking time
     * @param slope
     * @returns {number} the amount of milliseconds of parking time awarded
     *                   while walking the slope with the leftover currency amount
     */
    public walkSlope(slope: Slope): number {
        let walkedRappenOnSlope = 0;
        if (
            Math.round(slope.miliSecs) <=
            Math.round(this.millisRequiredToTarget)
        ) {
            this.walkedMiliSecs += slope.miliSecs;
            walkedRappenOnSlope = slope.rappen;
        } else {
            // lets just set walkedMillis to targetMillis to prevent floating point math errors
            walkedRappenOnSlope = Math.round(
                slope.rappen * (this.millisRequiredToTarget / slope.miliSecs),
            );
            this.walkedMiliSecs = this.targetMillis;
            this.fullyConsumed = true;
        }
        this.walkedRappen += walkedRappenOnSlope;
        return this.walkedRappen;
    }

    public get rappen(): number {
        return this.walkedRappen;
    }
}

export interface TariffTimeResult {
    parkingTime: number;
    rappen: number;
    maxTimeReached: boolean;
}

export function calculateAmountByTimeFromCurve(
    milliSeconds: number,
    curve: Slope[],
): TariffTimeResult {
    // initialize to true and set to false if we break before the end
    let maxTimeReached = true;

    const walker = new CurveTimeWalker(milliSeconds);
    for (const slope of curve) {
        walker.walkSlope(slope);
        if (walker.fullyConsumed) {
            // fully consumed is only set it the slope was longer than the remaining milli seconds
            // so if it had the same length (i.e. max time was reached if last segment), the loop will not break
            maxTimeReached = false;
            break;
        }
    }
    return {
        parkingTime: milliSeconds,
        rappen: walker.rappen,
        maxTimeReached: maxTimeReached,
    };
}
