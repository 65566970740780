import { css } from '@emotion/css';

import { Message } from 'dg-web-shared/lib/Localized';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { Localized } from '../../common/components/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { DeleteSavedCreditCardState } from '../shared/SavedCreditCardAliasState';
import { ModalDeleteQuestionBox } from '../../ui/modals/Confirmable';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { useState } from 'react';
import { SelectedPaymentFlow } from '../../common/payment/Payment';
import { navCategoryForPaymentAlias } from './EPaymentMode';
import { SelectedCardItemBlock } from '../root/components/ItemBlock';
import { expiredLabel, failedLabel } from '../payment-method/DirectMethod';
import {
    CreditCardAlias,
    PaymentCardType,
} from 'dg-web-shared/model/PaymentAlias';
import { Icon } from 'dg-web-shared/ui/icons/Icon';

export const EPaymentWithSavedCCAlias = ({
    alias,
    portal,
    onClick,
}: {
    alias: CreditCardAlias;
    portal: SlideInPortalId;
    onClick: (p: SelectedPaymentFlow) => void;
}) => {
    const [deleteAliasState, deleteAlias] = DeleteSavedCreditCardState.use();

    const aliasIsFailed = alias.lastDirectFailed;
    const aliasIsExpired = alias.expiration?.isExpired;

    const expirationDate = alias?.expiration
        ? `${padMonth(alias.expiration.month)} / ${alias.expiration.year}`
        : undefined;

    return (
        <div
            className={css({
                display: 'flex',
                width: '100%',
                alignContent: 'right',
            })}
        >
            <SelectedCardItemBlock
                onClick={() => onClick(navCategoryForPaymentAlias(alias))}
                title={
                    alias.paymentMethod !== PaymentCardType.TWI
                        ? alias.maskedCreditCardNumber
                        : 'TWINT'
                }
                subTitle={
                    aliasIsExpired
                        ? expiredLabel
                        : aliasIsFailed
                          ? failedLabel
                          : expirationDate
                }
                icon={alias.paymentMethod.toLowerCase()}
            />
            <DeleteSavedCCAliasButton
                portal={portal}
                ccAliasDeleteRequest={deleteAliasState}
                deleteCCAlias={deleteAlias}
            />
        </div>
    );
};

interface DeleteSavedCCAliasButtonProps {
    portal: SlideInPortalId;
    ccAliasDeleteRequest: DeleteSavedCreditCardState.State;
    deleteCCAlias: () => void;
}

const DeleteSavedCCAliasButton = (props: DeleteSavedCCAliasButtonProps) => {
    const [displayConfirmationBox, setDisplayConfirmationBox] = useState(false);
    return (
        <div
            className={css({
                alignSelf: 'stretch',
                borderLeft: `solid 1px ${Colors.rgba(Colors.black, 0.08)}`,
                borderBottom: `solid 1px ${Colors.rgba(Colors.black, 0.08)}`,
                display: 'flex',
                alignItems: 'center',
                width: '60px',
                ':hover': { backgroundColor: Colors.contentHover },
            })}
        >
            {displayConfirmationBox && (
                <DeleteConfirmQuestion
                    onConfirm={() => {
                        props.deleteCCAlias();
                        setDisplayConfirmationBox(false);
                    }}
                    onCancel={() => setDisplayConfirmationBox(false)}
                />
            )}

            <EpaymentManipulationButton
                {...props}
                onClick={() => {
                    setDisplayConfirmationBox(true);
                }}
            />
        </div>
    );
};

function EpaymentManipulationButton(
    props: DeleteSavedCCAliasButtonProps & { onClick: () => void },
): JSX.Element | null {
    switch (props.ccAliasDeleteRequest.status) {
        case RequestStatus.NEVER_EXECUTED:
            return (
                <Clickable
                    element="div"
                    onClick={props.onClick}
                    className={css({
                        padding: '0 18px',
                        color: Colors.blue,
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    })}
                >
                    <Icon icon="garbagebin" />
                </Clickable>
            );

        case RequestStatus.PENDING:
            return (
                <div
                    className={css({
                        padding: '0 8px',
                        color: '#999',
                        cursor: 'default',
                    })}
                >
                    <Icon icon="hourglass" />
                </div>
            );

        case RequestStatus.ERROR:
            return (
                <div
                    className={css({
                        padding: '0 8px',
                        color: Colors.error,
                        cursor: 'default',
                    })}
                >
                    <Icon icon="error" />
                </div>
            );

        case RequestStatus.SUCCESS:
            return (
                <div
                    className={css({
                        padding: '0 8px',
                        color: Colors.confirmation,
                        cursor: 'default',
                    })}
                >
                    <Icon icon="checkmark" />
                </div>
            );
        default:
            return null;
    }
}

const messages: Messages = {
    confirmCCAliasDeletionText1: {
        de: 'Im Parkingpay-System wird eine sichere und anonyme Referenz auf das Zahlungsmittel gespeichert; alle Details sind nur im Bankrechenzentrum unseres Zahlungsanbieters gespeichert.',
        fr: 'Seule une référence sécurisée et anonyme au moyenne de paiement est stockée dans le système Parkingpay; tous les détails du moyenne de paiement ne sont stockées que dans le centre de traitement des données bancaires de notre prestataire de paiement.',
        it: "Nel sistema Parkingpay viene memorizzato un riferimento sicuro e anonimo al mezzo di pagamento; tutti i dettagli relativi a quest'ultimo sono memorizzati solo nel centro d'elaborazione dati di tipo bancario del nostro provider di pagamenti.",
        en: 'Only a secure and anonymous reference to the payment method is stored in the Parkingpay system; all details are only stored in the bank datacenter of our payment provider.',
    },
    confirmCCAliasDeletionText2: {
        de: 'Möchten Sie diese Referenz trotzdem löschen? Auf diese Weise müssen Sie bei der nächsten Verwendung alle Details erneut eingeben.',
        fr: "Voulez-vous quand même supprimer cette référence? De cette façon, vous devrez entrer à nouveau tous les détails la prochaine fois que vous voudrez l'utiliser.",
        it: 'Vuole eliminare comunque questo riferimento? Così facendo dovrà inserire di nuovo tutti i dettagli la prossima volta che vorrà utilizzarlo.',
        en: 'Would you still like to delete this reference? In this way, you will have to re-enter all the details the next time you use it.',
    },
};

interface Messages {
    confirmCCAliasDeletionText1: Message;
    confirmCCAliasDeletionText2: Message;
}

export function padMonth(x: number): string {
    return `${x < 10 ? '0' : ''}${x}`;
}

export function DeleteConfirmQuestion(p: {
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <ModalDeleteQuestionBox
            confirmCallback={p.onConfirm}
            cancelCallback={p.onCancel}
            titleCaption={
                <Localized
                    de="Referenz löschen"
                    fr="Supprimer référence"
                    it="Elimina riferimento"
                    en="Delete reference"
                />
            }
        >
            <p>
                <Localized {...messages.confirmCCAliasDeletionText1} />
            </p>
            <p>
                <Localized {...messages.confirmCCAliasDeletionText2} />
            </p>
        </ModalDeleteQuestionBox>
    );
}
