import { css } from '@emotion/css';

import * as superagent from 'superagent';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    RequestStatus,
    useServerFetch,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../../common/components/FullWidthBottomButton';
import { Localized } from '../../../common/components/Localized';
import { Typo } from '../../../style/typo';
import { Address } from './Address';
import { Helpdesk } from './Helpdesk';
import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { InlineErrorBox } from '../../../ui/modals/Confirmable';
import { useParams } from 'react-router';
import { GenericError } from '../../../account/logins/EditForm';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export function ReactivateTerminatedAccountPage() {
    const { store } = useStore(() => null);
    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <Content />
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}

function Content(): JSX.Element {
    const { token } = useParams();

    const [httpGet] = useServerFetch<
        AccountToReactivateResponse,
        { token: string }
    >(
        ({ token }) => ({
            url: `/ui-api/account-to-reactivate/${token}`,
        }),
        token ? { token } : null,
    );

    if (!token) {
        return <GenericError />;
    }

    switch (httpGet.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.PENDING:
            return <Spinner />;

        case RequestStatus.ERROR:
            return (
                <ErrorOnHttpGetAccountToReactivate
                    httpStatusCode={httpGet.httpStatusCode}
                />
            );

        case RequestStatus.SUCCESS:
            return <ReactivationForm token={token} account={httpGet.data} />;
    }
}

function ErrorOnHttpGetAccountToReactivate(props: { httpStatusCode: number }) {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            }
        >
            <p>
                {props.httpStatusCode === 404 ? (
                    <Localized
                        de="Die URL-Adresse für die Reaktivierung ist ungültig."
                        fr="L'adresse URL pour la réactivation n'est pas valable."
                        it="L'indirizzo URL per la riattivazione non è valido."
                        en="The URL address for the reactivation is invalid."
                    />
                ) : (
                    <Localized
                        de={`HTTP ${props.httpStatusCode}`}
                        fr={`HTTP ${props.httpStatusCode}`}
                        it={`HTTP ${props.httpStatusCode}`}
                        en={`HTTP ${props.httpStatusCode}`}
                    />
                )}
            </p>
        </InlineErrorBox>
    );
}

function ReactivationForm(props: {
    token: string;
    account: AccountToReactivateResponse;
}) {
    const [httpPost, doHttpPost] = useServerWrite({
        req: (body: ReactivationRequest) =>
            superagent.post('/ui-api/reactivate-account').send(body),

        onResponse: res => {
            if (
                res.status === RequestStatus.SUCCESS &&
                props.account.clientGuiType === ClientGuiType.WEB
            ) {
                window.location.href = '/';
            }
        },
    });

    return (
        <div className={css({ maxWidth: '480px', ...Typo.body })}>
            {httpPost.status !== RequestStatus.SUCCESS && (
                <>
                    <Explanation account={props.account} />
                </>
            )}

            {httpPost.status === RequestStatus.ERROR && (
                <ErrorOnHttpPostReactivation
                    httpStatusCode={httpPost.httpStatusCode}
                />
            )}

            {httpPost.status === RequestStatus.PENDING && <Spinner />}

            {httpPost.status !== RequestStatus.SUCCESS && (
                <div className={css({ marginTop: '24px' })}>
                    <FullWidthBottomButton
                        color={FullWidthBottomButtonColor.BLUE}
                        label={
                            props.account.clientGuiType === ClientGuiType.WEB
                                ? {
                                      de: 'Konto reaktivieren und einloggen',
                                      fr: 'Réactiver le compte et se connecter',
                                      it: 'Riattiva il conto e accedi',
                                      en: 'Reactivate account and sign in',
                                  }
                                : {
                                      de: 'Konto reaktivieren',
                                      fr: 'Réactiver le compte',
                                      it: 'Riattiva il conto',
                                      en: 'Reactivate account',
                                  }
                        }
                        disabled={httpPost.status === RequestStatus.PENDING}
                        onClick={() => {
                            doHttpPost({ token: props.token });
                        }}
                    />
                </div>
            )}

            {httpPost.status === RequestStatus.SUCCESS && (
                <SuccessfullyReactivated account={props.account} />
            )}
        </div>
    );
}

function Explanation(props: { account: AccountToReactivateResponse }) {
    return (
        <p>
            <Localized
                de={`Um das Parkingpay-Konto #${props.account.customerNumber} wieder zu aktivieren, drücken Sie bitte den Knopf hier unten.`}
                fr={`Pour réactiver le compte Parkingpay #${props.account.customerNumber} veuillez appuyer sur le bouton ci-dessous.`}
                it={`Per riattivare il conto Parkingpay #${props.account.customerNumber} premere il pulsante qui sotto.`}
                en={`In order to reactivate the Parkingpay account #${props.account.customerNumber} please press the button below.`}
            />
        </p>
    );
}

function SuccessfullyReactivated(props: {
    account: AccountToReactivateResponse;
}) {
    return (
        <p>
            <Localized
                de={`Das Parkingpay-Konto #${props.account.customerNumber} wurde reaktiviert. Sie können sich nun mit Ihrer E-Mail-Adresse «${props.account.adminLoginEmail}» in der Parkingpay App anmelden.`}
                fr={`Le compte Parkingpay #${props.account.customerNumber} a été réactivé. Vous pouvez maintenant vous connecter dans l'appli Parkingpay avec votre e-mail «${props.account.adminLoginEmail}».`}
                it={`Il conto Parkingpay #${props.account.customerNumber} è stato riattivato. Ora può accedere all'app Parkingpay con il suo indirizzo e-mail «${props.account.adminLoginEmail}».`}
                en={`The Parkingpay account #${props.account.customerNumber} has been reactivated. You can now sign into the Parkingpay App with your e-mail address «${props.account.adminLoginEmail}».`}
            />
        </p>
    );
}

function ErrorOnHttpPostReactivation(props: { httpStatusCode: number }) {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            }
        >
            <p>
                <Localized
                    de={`HTTP ${props.httpStatusCode}`}
                    fr={`HTTP ${props.httpStatusCode}`}
                    it={`HTTP ${props.httpStatusCode}`}
                    en={`HTTP ${props.httpStatusCode}`}
                />
            </p>
        </InlineErrorBox>
    );
}

type AccountToReactivateResponse = {
    customerNumber: number;
    customerName: string;
    adminLoginEmail: string;
    clientGuiType: ClientGuiType;
};

type ReactivationRequest = {
    token: string;
};

enum ClientGuiType {
    NATIVE = 'NATIVE',
    WEB = 'WEB',
}
