import { LeftColumn, RightColumn, StaticPage } from './StaticPage';
import { Helpdesk } from './Helpdesk';
import { Address } from './Address';
import { useStore } from 'dg-web-shared/lib/Flux';
import { css } from '@emotion/css';
import { Legal } from 'dg-web-shared/lib/legal/legal';

import ReactMarkdown from 'react-markdown';
import { Typo } from '../../../style/typo';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';

export function LegalPage() {
    const { store } = useStore(() => null);
    const language = useParkingpayLanguage();

    return (
        <StaticPage allState={store}>
            <LeftColumn allState={store}>
                <div
                    className={css({
                        ...Typo.body,

                        h1: {
                            fontSize: 22,
                            margin: '12px 0',
                        },
                        h2: {
                            fontSize: 12,
                            margin: 0,
                        },
                        h3: {
                            fontSize: 18,
                            marginTop: '12px',
                        },
                        h4: {
                            fontSize: 16,
                            marginTop: '8px',
                            marginBottom: 0,
                        },
                    })}
                >
                    <ReactMarkdown>
                        {Legal.privacyPolicyMarkdown[language]}
                    </ReactMarkdown>
                </div>
            </LeftColumn>
            <RightColumn allState={store}>
                <Helpdesk allState={store} />
                <Address allState={store} />
            </RightColumn>
        </StaticPage>
    );
}
