export enum VehicleLicensePlateType {
    CAR = 'CAR',
    MOTORCYCLE = 'MOTORCYCLE',
}

export interface ParkingaboVehicle {
    customerTenantCarId: string;
    identificationQrCodeId: string | null;
    description: string;
    licensePlateNr: string | null;
    type: VehicleLicensePlateType | null;
    country: string | null;
    badgeId: number | null;
    badgeLabelNr: string | null;
}

export interface ParkingaboVehicleWithLicensePlate extends ParkingaboVehicle {
    licensePlateNr: string;
    type: VehicleLicensePlateType;
    country: string;
}

export function isVehicleWithLicensePlate(
    v: ParkingaboVehicle,
): v is ParkingaboVehicleWithLicensePlate {
    return v.country !== null && v.type !== null && v.licensePlateNr !== null;
}

export enum BarrierGateLicensePlateCheckInOption {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    OCCUPIED = 'OCCUPIED',
    NOT_ALLOWED_TOO_SHORT = 'NOT_ALLOWED_TOO_SHORT',
    NOT_ALLOWED_MOTORCYCLE = 'NOT_ALLOWED_MOTORCYCLE',
    NOT_ALLOWED_DIPLOMATIC = 'NOT_ALLOWED_DIPLOMATIC',
}
