import { LoginFormBlock } from './LoginFormBlock';

import { ForgotPassword } from './ForgotPasswordBlock';

type SetEmail = (email: string) => void;

interface LoginProps {
    openForgotPassword: () => void;
    closeForgotPassword: () => void;
    forgotPasswordVisible: boolean;
    email: string;
    setEmail: SetEmail;
}
export function Login(p: LoginProps) {
    if (p.forgotPasswordVisible) {
        return (
            <div>
                <ForgotPassword
                    closeForgotPassword={p.closeForgotPassword}
                    email={p.email}
                    setEmail={p.setEmail}
                />
            </div>
        );
    } else {
        return (
            <div>
                <LoginFormBlock
                    email={p.email}
                    setEmail={p.setEmail}
                    openForgotPassword={p.openForgotPassword}
                />
            </div>
        );
    }
}
