import { css } from '@emotion/css';

export interface IndentedListData {
    [key: string]: string[];
}

/**
 * This is the minimum information the zone data should have (Typically we would pass a larger
 * zone object which has different additional attributes depending on the
 * application (e.g. Parkingpay vs TWINT)
 */
interface GenericZone {
    extZoneCode: number;
    name: string;
    zipCode: string;
    city: string;
}

/**
 * IndentedList takes a map of "title-line" to "list-entries":
 * Example:
 * "title-line1" ->
 *                  "entry 1"
 *                  "entry 2"
 *
 * "title-line2" ->
 *                  "entry 1"
 *                  "entry 2"
 *
 * Creates an indented block like so:
 * title
 * "title-line1"
 *      "entry 1"
 *      "entry 2"
 * "title-line2"
 *      "entry 1"
 *      "entry 2"
 */
export function IndentedList({ entries }: { entries: IndentedListData }) {
    return (
        <>
            {Object.keys(entries).map((key: string): JSX.Element => {
                return (
                    <div key={key}>
                        {key}
                        <div
                            className={css({
                                paddingLeft: '16px',
                            })}
                        >
                            {entries[key].map((entry: string) => {
                                return <div key={entry}> {entry}</div>;
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
}

function sortZonesByZoneNr(zone1: GenericZone, zone2: GenericZone): number {
    if (zone1.extZoneCode < zone2.extZoneCode) {
        return -1;
    }
    if (zone1.extZoneCode === zone2.extZoneCode) {
        return 0;
    }
    return 1;
}

export function processZones(zones: GenericZone[]): IndentedListData | null {
    if (zones.length === 0) {
        return null;
    }
    const locationBlockByTitle: IndentedListData = {};

    zones.sort(sortZonesByZoneNr);
    zones.forEach((z: GenericZone) => {
        const key = z.zipCode.slice(0, 4) + ' ' + z.city;
        const entries = locationBlockByTitle[key];
        if (entries) {
            entries.push(
                `${!z.extZoneCode ? '' : z.extZoneCode + ' | '}${z.name}`,
            );
        } else {
            locationBlockByTitle[key] = [
                `${!z.extZoneCode ? '' : z.extZoneCode + ' | '}${z.name}`,
            ];
        }
    });

    return locationBlockByTitle;
}
