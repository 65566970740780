import {
    LicensePlateType,
    PermitPurchaseFormFields,
} from './QuickCheckoutPermittypeTypes';
import { QuickCheckoutSlideIn } from '../common/QuickCheckoutSlideIn';
import {
    QuickCheckoutContent,
    QuickCheckoutHeader,
} from '../common/QuickCheckoutLayout';
import { Localized } from '../../common/components/Localized';

import { Car, Motorcycle } from 'dg-web-shared/ui/icons/IconsScalable';
import { Controller } from 'react-hook-form';
import { usePermittypeFormOutletContext } from './QuickCheckoutPermittypeForm';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { RadioElementQuickCheckout } from '../common/QuickCheckoutUiComponents';

export function QuickCheckoutPermittypeTypeSelectionSlideIn() {
    const { control } = usePermittypeFormOutletContext();
    const navigate = useNavigate();

    return (
        <Controller
            name={PermitPurchaseFormFields.licensePlateType}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
                <TypeSelectionSlideIn
                    selectedLicensePlateType={value}
                    setSelectedLicensePlateType={onChange}
                    onClose={() => navigate('..')}
                />
            )}
        />
    );
}

function TypeSelectionSlideIn({
    selectedLicensePlateType,
    setSelectedLicensePlateType,
    onClose,
}: {
    selectedLicensePlateType: LicensePlateType;
    setSelectedLicensePlateType: (licensePlateType: LicensePlateType) => void;
    onClose: () => void;
}) {
    const [isOpen, setOpen] = useState(true);
    return (
        <QuickCheckoutSlideIn open={isOpen} onClosed={onClose}>
            <QuickCheckoutHeader onClose={() => setOpen(false)}>
                <Localized
                    de="Fahrzeugtyp"
                    fr="Type de véhicule"
                    it="Tipo di veicolo"
                    en="Vehicle type"
                />
            </QuickCheckoutHeader>
            <QuickCheckoutContent padding={'x-only'}>
                <RadioElementQuickCheckout
                    toggled={selectedLicensePlateType == LicensePlateType.CAR}
                    iconElement={<Car />}
                    onClick={() => {
                        setSelectedLicensePlateType(LicensePlateType.CAR);
                        setOpen(false);
                    }}
                >
                    <Localized
                        de="Motorwagen"
                        fr="Voiture"
                        it="Automobile"
                        en="Car"
                    />
                </RadioElementQuickCheckout>
                <RadioElementQuickCheckout
                    toggled={
                        selectedLicensePlateType === LicensePlateType.MOTORCYCLE
                    }
                    iconElement={<Motorcycle />}
                    onClick={() => {
                        setSelectedLicensePlateType(
                            LicensePlateType.MOTORCYCLE,
                        );
                        setOpen(false);
                    }}
                >
                    <Localized
                        de="Motorrad"
                        fr="Moto"
                        it="Motocicletta"
                        en="Motorcycle"
                    />
                </RadioElementQuickCheckout>
            </QuickCheckoutContent>
        </QuickCheckoutSlideIn>
    );
}
