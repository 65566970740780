import { Localized } from '../Localized';
import {
    ProcessStep,
    TicketContent,
    TicketContentText,
    TicketInfoHeader,
    TicketSubtitle,
} from './TicketPlusLayout';
import { ButtonText, ModalErrorBox } from '../../../ui/modals/Confirmable';

export const TicketPlusClaimed = () => (
    <TicketContent>
        <TicketInfoHeader>
            <Localized de="Parking" fr="Parking" it="Parcheggio" en="Parking" />
        </TicketInfoHeader>
        <TicketSubtitle>
            <Localized
                de="Ticket bereits freigegeben"
                fr="Ticket déjà approuvé"
                it="Ticket già approvato"
                en="Ticket already released"
            />
        </TicketSubtitle>
        <TicketContentText>
            <ProcessStep icon="ticket">
                <Localized
                    de="Die automatische Zahlung dieses Tickets wurde bereits mit einer anderen App aktiviert."
                    fr="Le paiement automatique de ce ticket a déjà été activé avec une autre app."
                    it="Il pagamento automatico di questo ticket è già stato attivato con un'altra app."
                    en="The automatic payment of this ticket has already been activated with another app."
                />
            </ProcessStep>
        </TicketContentText>
    </TicketContent>
);

export function TicketPlusClaimedModal({ onClose }: { onClose: () => void }) {
    return (
        <ModalErrorBox
            titleCaption={
                <Localized
                    de="Ticket bereits freigegeben"
                    fr="Ticket déjà approuvé"
                    it="Ticket già approvato"
                    en="Ticket already released"
                />
            }
            confirmCaption={ButtonText.CANCEL}
            confirmCallback={() => onClose()}
        >
            <p>
                <Localized
                    de="Die automatische Zahlung dieses Tickets wurde bereits mit einer anderen App aktiviert."
                    fr="Le paiement automatique de ce ticket a déjà été activé avec une autre app."
                    it="Il pagamento automatico di questo ticket è già stato attivato con un'altra app."
                    en="The automatic payment of this ticket has already been activated with another app."
                />
            </p>
        </ModalErrorBox>
    );
}
