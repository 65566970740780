import * as Flux from 'dg-web-shared/lib/Flux';
import * as LayoutState from '../state/LayoutState';
import { columnsVariant } from '../utils/columnsVariant';
import { columnsContainer } from '../utils/columnsContainer';
import { css } from '@emotion/css';

export interface LayoutContainerProps {
    children?: React.ReactNode;
    allState: Flux.Store;
    layoutVariant: LayoutVariantOption;
}
export interface LayoutContainerState {
    layout: LayoutState.State;
}

export enum LayoutVariantOption {
    Application,
    StaticPage,
}
export class LayoutContainer extends Flux.ContainerWithProps<
    LayoutContainerProps,
    LayoutContainerState
> {
    static displayName = 'LayoutContainer';

    stateSelector(): LayoutContainerState {
        return {
            layout: new LayoutState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        const layoutContainerStyles: any = {
            width: columnsContainer.width(this.state, this.props.layoutVariant),
        };
        return (
            <div
                className={css([
                    {
                        margin: 'auto',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                    columnsVariant(this.state) === 'OneColumn' && {
                        overflowX: 'hidden',
                        overFlowY: 'auto',
                        WebkitOverflowScrolling: 'touch',
                    },
                ])}
                style={layoutContainerStyles}
            >
                {this.props.children}
            </div>
        );
    }
}
