import { useState } from 'react';
import { Ticket } from './TicketSlideIn';
import {
    ButtonText,
    ModalErrorBox,
    ModalQuestionBox,
} from '../../ui/modals/Confirmable';
import * as TicketPlusTicketApproved from '../../common/components/ticketplus/TicketPlusTicketApproved';
import * as TicketPlusModalTexts from '../../common/components/ticketplus/TicketPlusModalTexts';
import { TicketLayout } from '../../common/components/ticketplus/TicketPlusLayout';
import {
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useDocumentVisibilityEffect } from 'dg-web-shared/common/hooks/useDocumentVisibility';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';

export function TicketApproved({
    ticketData,
    openZoneInfo,
    onTicketReset,
    refetchTicketState,
}: {
    ticketData: Ticket;
    openZoneInfo: () => void;
    onTicketReset: () => void;
    refetchTicketState: () => void;
}) {
    const [ticketResetState, fetchTicketReset] = useServerWrite<
        { ticketId: string },
        null
    >(() => ({
        url: `/ui-api/customer-account/ticket/reset`,
    }));
    const refetchTransactionList = useTransactionListRefetch();

    useDocumentVisibilityEffect(visibilityState => {
        if (visibilityState === 'visible') {
            refetchTicketState();
            refetchTransactionList();
        }
    });

    useServerSuccessEffect(ticketResetState, () => {
        onTicketReset();
    });

    useServerErrorEffect(ticketResetState, () => {
        setOpenResetTicketModal(false);
        setOpenTicketResetFailedModal(true);
    });

    const [openResetTicketModal, setOpenResetTicketModal] = useState(false);
    const [openTicketResetFailedModal, setOpenTicketResetFailedModal] =
        useState(false);

    return (
        <TicketLayout type="ticketplus">
            <TicketPlusTicketApproved.TicketPlusTicketApproved
                ticketState={ticketData.state}
                ticketApiString={ticketData.apiString}
                ticketCreatedAt={ticketData.createdAt}
                onOpenZoneInfo={openZoneInfo}
                zoneName={ticketData.zone.name}
            />

            <TicketPlusTicketApproved.TicketPlusRevokeApprovalLink
                onClick={() => setOpenResetTicketModal(true)}
            />

            {openResetTicketModal && (
                <ModalQuestionBox
                    titleCaption={
                        TicketPlusModalTexts.deactivatePaymentModalTitle
                    }
                    confirmCaption={ButtonText.YES}
                    confirmCallback={() => {
                        setOpenResetTicketModal(false);
                        fetchTicketReset({ ticketId: ticketData.ticketId });
                    }}
                    cancelCaption={ButtonText.NO}
                    cancelCallback={() => setOpenResetTicketModal(false)}
                >
                    <p>{TicketPlusModalTexts.deactivatePaymentModalMessage}</p>
                </ModalQuestionBox>
            )}
            {openTicketResetFailedModal && (
                <ModalErrorBox
                    confirmCallback={() => {
                        refetchTicketState();
                        setOpenTicketResetFailedModal(false);
                    }}
                >
                    <p>
                        {
                            TicketPlusModalTexts.deactivatePaymentFailedModalMessage
                        }
                    </p>
                </ModalErrorBox>
            )}
        </TicketLayout>
    );
}
