import { useContext, useEffect } from 'react';
import { ServerFetchState } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useServerFetch } from '../hooks/ServerStateHooks';
import React from 'react';

export type TransactionList = {
    active: ActiveListItem[];
    needTransaction: ActiveListItem[];
    future: ActiveListItem[];
    expired: ExpiredListItem[];
    recents: RecentsData;
};

export type ActiveListItem = OnstreetCheckin | Permit | PendingTicket;
export type ExpiredListItem =
    | OnstreetCheckin
    | OffstreetCheckin
    | Permit
    | PaidTicket;

const enum TransactionItemType {
    parkTransaction = 'parkTransaction',
    offstreetTransaction = 'offstreetTransaction',
    permit = 'permit',
    pendingTicket = 'pendingTicket',
    paidTicket = 'paidTicket',
}

export type OnstreetCheckin = {
    contractId: number;
    type: TransactionItemType.parkTransaction;
    zoneName: string;
    extZoneCode: number | null;
    licensePlate: string;
    licensePlateId: number;
    price: number;
};

export type OffstreetCheckin = {
    contractId: number;
    type: TransactionItemType.offstreetTransaction;
    zoneName: string;
    badgeLabelNr: string;
    price: number;
};

export type Permit = {
    contractId: number;
    type: TransactionItemType.permit;
    licensePlates: string[];
    badges: string[];
    validFrom: string;
    validTo: string;
    permitTypeName: string;
    showWarning: boolean;
};

export type Ticket = {
    type: TransactionItemType.pendingTicket | TransactionItemType.paidTicket;
    ticketString: string;
    ticketApiString: string;
    zoneName: string;
};

export type PendingTicket = {
    type: TransactionItemType.pendingTicket;
} & Ticket;

export type PaidTicket = {
    price: number;
    type: TransactionItemType.paidTicket;
} & Ticket;

export type RecentsData = {
    showRecents: boolean;
    recentsList: RecentItem[];
};

export type RecentItem = RecentZoneEnforcedItem | RecentPermitItem;

type RecentZoneEnforcedItem = {
    type: RecentItemType.ZONE_ENFORCED;
    zoneId: number;
} & RecentItemBase;

type RecentPermitItem = {
    type: RecentItemType.PERMIT;
    permittypeId: number;
} & RecentItemBase;

type RecentItemBase = {
    zipCode: string;
    name: string;
};

export const enum RecentItemType {
    PERMIT = 'PERMIT',
    ZONE_ENFORCED = 'ZONE_ENFORCED',
}

const TransactionListContext = React.createContext<ServerFetchState<
    TransactionList,
    never
> | null>(null);

export function TransactionListProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const transactionListFetchState = useServerFetch<
        TransactionList,
        Record<string, never>,
        never
    >(
        () => ({
            url: `/ui-api/customer-account/transactions-list`,
        }),
        {},
    );

    useEffect(() => {
        const interval = setInterval(() => {
            transactionListFetchState[1]();
        }, 60 * 1000);
        return () => clearInterval(interval);
    }, [transactionListFetchState]);

    return (
        <TransactionListContext.Provider value={transactionListFetchState}>
            {children}
        </TransactionListContext.Provider>
    );
}

export function useTransactionList() {
    const context = useContext(TransactionListContext);
    if (context === null) {
        throw new Error(
            'Transaction list context is null. Hook is used outside of context provider.',
        );
    }
    return context;
}

export function useTransactionListRefetch() {
    const context = useContext(TransactionListContext);
    if (context === null) {
        throw new Error(
            'Transaction list context is null. Hook is used outside of context provider.',
        );
    }
    return context[1];
}
