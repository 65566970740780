import * as Base64 from 'base-64';

export const basicAuthHeader = (user: string, pass: string): string =>
    'Basic ' + b64EncodeUnicode(user + ':' + pass);

/*
https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_.22Unicode_Problem.22
*/
export function b64EncodeUnicode(str: string): string {
    return Base64.encode(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function (_match: any, p1: string): string {
                return String.fromCharCode(('0x' + p1) as any);
            },
        ),
    );
}
