import * as emailValidator from 'email-validator';

import { css } from '@emotion/css';
import * as Flux from 'dg-web-shared/lib/Flux';
import { getOrElse, isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers';
import { Translation } from 'dg-web-shared/lib/Text';
import { TextField } from '../../../../tb-ui/inputs/TextField';
import {
    LabeledToggle,
    ToggleType,
} from '../../../../tb-ui/toggle/LabeledToggle';
import * as Http from '../../api/Http';
import * as AsyncRequest from '../../AsyncRequest';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../common/components/FullWidthBottomButton';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import * as LayoutState from '../../layout/state/LayoutState';
import { ResponsiveProperties } from '../../layout/utils/ResponsiveProperties';
import { closeSignupSlideIn } from '../../pages/home-unauthenticated/actions/SignUpSlideInActions';
import { HasEverLoggedInState } from '../../pages/home-unauthenticated/HasEverLoggedInState';
import * as LoginSlideinState from '../../pages/home-unauthenticated/state/SignUpSlideinState';
import { ContentMode } from '../../pages/home-unauthenticated/state/SignUpSlideinState';
import * as ParkOptionListState from '../../park-create/state/ParkOptionListState';
import { Typo } from '../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { showActivationLinkInSignup } from '../../utils/Configuration';
import {
    signupErrorTexts,
    signupSuccessTexts,
    signupTexts,
} from '../i18n/SignupTexts';
import * as SignupState from '../state/SignupState';
import {
    PasswordStrengthIndicator,
    passwordToShort,
} from '../../account/current-login/PasswordEditForm';

export interface SignupTexts {
    emailLabel: Translation;
    passwordLabel: Translation;
    passwordRepeatedLabel: Translation;
    acceptTos: (props: {
        onClickTermsOfService: () => void;
        onClickPrivacyPolicy: () => void;
    }) => JSX.Element;
    acceptTosDesktop: () => JSX.Element;
    contestAccepted: () => JSX.Element;
    signupButtonLabel: Translation;
    backToLogin: Translation;
}

export interface SignupErrorTexts {
    requiredField: Translation;
    invalidEmail: Translation;
    emailNotUnique: Translation;
    emailFieldsDoNotMatch: Translation;
    passwordFieldsDoNotMatch: Translation;
    signupFailure: Translation;
}

export interface SignupSuccessTexts {
    signupSuccess: Translation;
    activateAccount: Translation;
}

interface SignupState {
    settings: SettingsState.State;
    signup: SignupState.Signup.State;
    signupResponse: SignupState.SignupResponse.State;
    layout: LayoutState.State;
    parkOptionSelection: ParkOptionListState.Selection.State;
}
const texts = (s: SignupState) => signupTexts[s.settings.language];
const errorTexts = (s: SignupState) => signupErrorTexts[s.settings.language];

interface SignupSuccessProps {
    language: string;
    activationToken: Maybe<string>;
}
const ActivationToken = (p: SignupSuccessProps) =>
    showActivationLinkInSignup() && isDefined(p.activationToken) ? (
        <div
            className={css({
                ...Typo.body,
                padding: '25px',
                color: Colors.blue,
            })}
        >
            <a
                className={css({
                    color: Colors.blue,
                    textDecoration: 'underline',
                })}
                href={`/account-activated/${p.activationToken}`}
            >
                {signupSuccessTexts[p.language].activateAccount()}
            </a>
        </div>
    ) : null;

const SignupSuccess = (p: SignupSuccessProps) => (
    <div>
        <div
            className={css({
                ...Typo.body,
                padding: '25px',
                color: Colors.blue,
            })}
        >
            {signupSuccessTexts[p.language].signupSuccess()}
        </div>
        <ActivationToken {...p} />
    </div>
);

const signupAction = AsyncRequest.requestV2(
    Http.Signup.signup,
    (store, response): string => {
        SignupState.SignupResponse.setResponse(store, response);
        if (response.statusCode.cls.success) {
            HasEverLoggedInState.stateWrite(store, { hasEver: true });
            SignupState.Signup.reset(store);
        } else {
            SignupState.Signup.stateWrite(store, { showErrors: true });
        }
        return 'Signup-signupAction';
    },
);
const signupPayload = (s: SignupState): Http.Signup.SignupDTO => ({
    email: s.signup.email ?? '',
    password: s.signup.password ?? '',
    language: s.settings.language,
    selectedZipCode: s.parkOptionSelection.selectedZipCode,
    selectedParkOptionId: s.parkOptionSelection.selectedParkOptionId,
    selectedParkVariant: s.parkOptionSelection.variant,
    selectedPermitTypeId: null,
    subscribeToNewsletter: s.signup.subscribeToNewsletter,
});
const emailFieldValid = (email: Maybe<string>) =>
    isDefined(email) && emailValidator.validate(email);
const passwordFieldValid = (password: Maybe<string>) =>
    isDefined(password) && password.length > 0;
const formValid = (
    signup: SignupState.Signup.State,
    hasContest: boolean,
    passwordErrorText: string | JSX.Element | null,
) =>
    signup.tosAccepted &&
    emailFieldValid(signup.email) &&
    passwordFieldValid(signup.password) &&
    passwordFieldValid(signup.passwordRepeated) &&
    signup.password === signup.passwordRepeated &&
    passwordErrorText === null &&
    (!hasContest || signup.contestAccepted);

const emailErrorText = (
    email: Maybe<string>,
    signupResponse: SignupState.SignupResponse.State,
    errorTexts: SignupErrorTexts,
) => {
    return isDefined(signupResponse.apiError) &&
        signupResponse.apiError === 'EMAIL_NOT_UNIQUE'
        ? errorTexts.emailNotUnique()
        : !isDefined(email) || email.length === 0
          ? errorTexts.requiredField()
          : !emailValidator.validate(email)
            ? errorTexts.invalidEmail()
            : null;
};

const passwordErrorText = (
    password: Maybe<string>,
    errorTexts: SignupErrorTexts,
) =>
    !isDefined(password) || password.length === 0
        ? errorTexts.requiredField()
        : passwordToShort(password);

const repeatedFieldError = (
    fieldRepeated: Maybe<string>,
    field: Maybe<string>,
    requiredErrorText: string,
    nomatchErrorText: string,
) =>
    !isDefined(fieldRepeated) || fieldRepeated.length === 0
        ? requiredErrorText
        : fieldRepeated !== field
          ? nomatchErrorText
          : null;

const writeEmail = (store: Flux.Store, email: string) => {
    SignupState.Signup.stateWrite(store, { email: email });
    SignupState.SignupResponse.reset(store);
    return 'Signup-writeEmail';
};

const SignupError = (p: SignupState) => (
    <Conditional
        c={
            isDefined(p.signupResponse.apiError) &&
            p.signupResponse.apiError !== 'EMAIL_NOT_UNIQUE'
        }
    >
        <div
            className={css({
                ...Typo.body,
                padding: '0 0 25px 0',
                color: Colors.blue,
            })}
        >
            {errorTexts(p).signupFailure()}
        </div>
    </Conditional>
);

export const Signup = Flux.selectState<{ showLoginHint: boolean }, SignupState>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        signup: SignupState.Signup.get(store),
        signupResponse: SignupState.SignupResponse.get(store),
        layout: new LayoutState.StateSlice(store).state,
        parkOptionSelection: ParkOptionListState.Selection.get(store),
    }),
    p => {
        const isMobile = new ResponsiveProperties(p).mobile;
        const showContest = p.parkOptionSelection.selectedParkOptionId === 1817;
        const newPasswordErrorText = passwordErrorText(
            p.signup.password,
            errorTexts(p),
        );
        return p.signupResponse.statusCode.cls.success ? (
            <SignupSuccess
                language={p.settings.language}
                activationToken={p.signupResponse.data.activationToken}
            />
        ) : (
            <div
                className={css({
                    padding: '25px',
                })}
            >
                <TextField
                    tabIndex={0}
                    labelText={texts(p).emailLabel()}
                    inputType="email"
                    name="email"
                    autocomplete="email"
                    value={getOrElse(p.signup.email, '')}
                    onChange={v => p.update(writeEmail, v)}
                    errorText={
                        p.signup.showErrors
                            ? emailErrorText(
                                  p.signup.email,
                                  p.signupResponse,
                                  errorTexts(p),
                              )
                            : null
                    }
                />
                <TextField
                    tabIndex={0}
                    inputType="password"
                    labelText={texts(p).passwordLabel()}
                    value={getOrElse(p.signup.password, '')}
                    onChange={v =>
                        p.update(SignupState.Signup.stateWrite, {
                            password: v,
                        })
                    }
                    errorText={
                        p.signup.showErrors ? newPasswordErrorText : null
                    }
                />
                <TextField
                    tabIndex={0}
                    inputType="password"
                    labelText={texts(p).passwordRepeatedLabel()}
                    value={getOrElse(p.signup.passwordRepeated, '')}
                    onChange={v =>
                        p.update(SignupState.Signup.stateWrite, {
                            passwordRepeated: v,
                        })
                    }
                    errorText={
                        p.signup.showErrors
                            ? repeatedFieldError(
                                  p.signup.passwordRepeated,
                                  p.signup.password,
                                  errorTexts(p).requiredField(),
                                  errorTexts(p).passwordFieldsDoNotMatch(),
                              )
                            : null
                    }
                />
                <PasswordStrengthIndicator
                    password={p.signup.password || null}
                />
                <LabeledToggle
                    label={
                        <Localized
                            de="Newsletter abonnieren (1-2 Mal pro Monat)"
                            fr="S'abonner à la newsletter (1-2 fois par mois)"
                            it="Abbonati alla newsletter (1-2 volte al mese)"
                            en="Subscribe to newsletter (1-2 times per month)"
                        />
                    }
                    onClick={() =>
                        p.update(SignupState.Signup.stateWrite, {
                            subscribeToNewsletter:
                                !p.signup.subscribeToNewsletter,
                        })
                    }
                    selected={p.signup.subscribeToNewsletter}
                    type={ToggleType.checkbox}
                />
                <LabeledToggle
                    label={
                        isMobile
                            ? texts(p).acceptTos({
                                  onClickTermsOfService:
                                      LoginSlideinState.makeSwitchTo(
                                          p.update,
                                          ContentMode.TERMS_OF_SERVICE,
                                      ),
                                  onClickPrivacyPolicy:
                                      LoginSlideinState.makeSwitchTo(
                                          p.update,
                                          ContentMode.PRIVACY_POLICY,
                                      ),
                              })
                            : texts(p).acceptTosDesktop()
                    }
                    onClick={() =>
                        p.update(SignupState.Signup.stateWrite, {
                            tosAccepted: !p.signup.tosAccepted,
                        })
                    }
                    selected={p.signup.tosAccepted}
                    error={p.signup.showErrors && !p.signup.tosAccepted}
                    type={ToggleType.checkbox}
                />
                {showContest && (
                    <LabeledToggle
                        label={texts(p).contestAccepted()}
                        onClick={() =>
                            p.update(SignupState.Signup.stateWrite, {
                                contestAccepted: !p.signup.contestAccepted,
                            })
                        }
                        selected={p.signup.contestAccepted}
                        error={p.signup.showErrors && !p.signup.contestAccepted}
                        type={ToggleType.checkbox}
                    />
                )}
                <br />
                <SignupError {...p} />
                <FullWidthBottomButton
                    color={FullWidthBottomButtonColor.BLUE}
                    onClick={() =>
                        formValid(
                            p.signup,
                            showContest,
                            newPasswordErrorText,
                        ) && !p.signupResponse.pending
                            ? p.update(signupAction, signupPayload(p))
                            : p.update(SignupState.Signup.stateWrite, {
                                  showErrors: true,
                              })
                    }
                    label={{
                        de: 'Registrieren',
                        fr: "S'enregistrer",
                        it: 'Registrarsi',
                        en: 'Sign up',
                    }}
                />
                {p.showLoginHint && (
                    <div
                        className={css({
                            marginTop: '50px',
                            padding: '0 48px',
                            textAlign: 'center',
                            ...Typo.robotoRegular,
                            fontSize: '15px',
                            color: Colors.blue,
                            fontWeight: 500,
                        })}
                    >
                        <Localized
                            de="Haben Sie schon ein Konto?"
                            fr="Vous avez déjà un compte?"
                            it="Avete già un conto?"
                            en="Do you already have an account?"
                        />
                        <FullWidthBottomButton
                            color={FullWidthBottomButtonColor.WHITE}
                            onClick={() => {
                                p.update(HasEverLoggedInState.stateWrite, {
                                    hasEver: true,
                                });
                                p.update(closeSignupSlideIn);
                            }}
                            label={{
                                de: 'Hier anmelden',
                                fr: 'Connectez-vous ici',
                                it: 'Accedete qui',
                                en: 'Sign in here',
                            }}
                        />
                    </div>
                )}
            </div>
        );
    },
);
