export type ContractQuotaInfos =
    | ContractQuotaInfosPerEntity
    | ContractQuotaInfosOverlapping;

export enum ContractQuotaInfoType {
    CONTRACT_PURCHASE_PER_ENTITY = 'CONTRACT_PURCHASE_PER_ENTITY',
    CONTRACT_PURCHASE_OVERLAPPING = 'CONTRACT_PURCHASE_OVERLAPPING',
}

export interface ContractQuotaInfosPerEntity {
    type: ContractQuotaInfoType.CONTRACT_PURCHASE_PER_ENTITY;
    days: number;
    entityType: 'BADGE' | 'LICENSE_PLATE';
    period: 'CALENDAR_MONTH' | 'CALENDAR_YEAR';
    quotaReachingEntities: ContractQuotaInfosPerEntityEntityInfo[];
}

export interface ContractQuotaInfosPerEntityEntityInfo {
    description: string;
    daysLeft: number;
    notEnoughQuota: boolean;
}

export interface ContractQuotaInfosOverlapping {
    type: ContractQuotaInfoType.CONTRACT_PURCHASE_OVERLAPPING;
}
