import {
    RequestStatus,
    ServerRequestState,
} from '../../../lib/hooks/ServerStateHooks';

import {
    ValidationData,
    isFormGlobalViolationEntry,
} from '../../../lib/forms/FormValidationHelpers';
import { Localized } from '../../hooks/LanguageProvider';
import { ErrorAlert } from './ErrorAlert';

export function BackendRequestErrorMessage<D, ED extends ValidationData>({
    requestState,
}: {
    requestState: ServerRequestState<D, ED>;
}) {
    const errorMessages = getErrorMessagesFromRequest(requestState);

    if (!errorMessages) {
        return null;
    }

    return (
        <>
            {errorMessages.map((errorMessage, i) => (
                <ErrorAlert key={i}>
                    <Localized {...errorMessage} />
                </ErrorAlert>
            ))}
        </>
    );
}

export function getErrorMessagesFromRequest<D, ED extends ValidationData>(
    requestState: ServerRequestState<D, ED>,
) {
    const isError = requestState.status === RequestStatus.ERROR;

    if (isError && !!requestState.data && !!requestState.data.violations) {
        const globalValidationError = requestState.data.violations.filter(
            isFormGlobalViolationEntry,
        );
        return globalValidationError.map(
            e => e.issue.humanReadableMessage.byLanguage,
        );
    }

    if (isError) {
        return [
            {
                de: `Fehler bei der Anfrage (Code ${requestState.httpStatusCode})`,
                fr: `Erreur dans la requête (code ${requestState.httpStatusCode})`,
                it: `Errore nella richiesta (codice ${requestState.httpStatusCode})`,
                en: `Request error (code ${requestState.httpStatusCode})`,
            },
        ];
    }

    return null;
}
