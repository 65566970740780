import * as Flux from 'dg-web-shared/lib/Flux';
import * as SettingsState from '../../common/state/SettingsState';
import * as TransactionsListState from '../state/TransactionsListState';
import { SlideIn } from '../../ui/slidein/SlideIn';
import { RunningCheckinByPlate } from './RunningCheckinByPlate';
import { TerminatedCheckinByPlate } from './TerminatedCheckinByPlate';
import { Translation } from 'dg-web-shared/lib/Text';
import { parkTransactionDetailTexts } from '../i18n/TransactionsListTexts';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import { Spinner } from 'dg-web-shared/ui/Spinner';

export interface ParkTransactionSlideInTexts {
    titleActiveTransaction: Translation;
    titleActiveTransactionDetail: Translation;

    titleExpiredTransaction: Translation;
}

const parkTransactionDetailId = (store: Flux.Store) =>
    TransactionsListState.Detail.get(store).parkTransactionId;

const texts = (p: { settings: SettingsState.State }) =>
    parkTransactionDetailTexts[p.settings.language];

const slideInTitle = (p: {
    parkTransactionDetail: TransactionsListState.CheckinByPlateDetailState.State;
    settings: SettingsState.State;
}) =>
    p.parkTransactionDetail.data
        ? p.parkTransactionDetail.data.isRunning
            ? texts(p).titleActiveTransaction()
            : texts(p).titleExpiredTransaction()
        : '';

const closeSlideIn = (store: Flux.Store) => {
    TransactionsListState.Detail.stateWrite(store, {
        parkTransactionId: null,
    });
    return 'closeSlideIn';
};

export const CheckinByPlateSlideIn = Flux.selectState(
    store => {
        const id = parkTransactionDetailId(store);
        return {
            id,
            settings: new SettingsState.StateSlice(store).state,
            transactionsListDetail: TransactionsListState.Detail.get(store),
            parkTransactionDetail:
                TransactionsListState.CheckinByPlateDetailState.get(
                    store,
                    id ? { id } : null,
                ),
        };
    },
    p => (
        <SlideIn
            title={slideInTitle(p)}
            open={!!p.id}
            onClose={() => p.update(closeSlideIn)}
        >
            {p.parkTransactionDetail.pending &&
            !p.parkTransactionDetail.data ? (
                <Spinner />
            ) : !p.parkTransactionDetail.data ? null : p.parkTransactionDetail
                  .data.isRunning ? (
                <RunningCheckinByPlate
                    isPrimaryTransactionInParkCreate={false}
                    portal={SlideInPortalId.TRANSACTION_LIST}
                    transaction={p.parkTransactionDetail.data}
                />
            ) : (
                <TerminatedCheckinByPlate
                    transaction={p.parkTransactionDetail.data}
                />
            )}
        </SlideIn>
    ),
);
