export enum ThreeColumnsVariantOption {
    OneColumn,
    ThreeColumns,
}

export class ThreeColumnsVariant {
    private _v: ThreeColumnsVariantOption;
    constructor(v: ThreeColumnsVariantOption) {
        this._v = v;
    }
    get oneColumn(): boolean {
        return this._v === ThreeColumnsVariantOption.OneColumn;
    }

    get threeColumns(): boolean {
        return this._v === ThreeColumnsVariantOption.ThreeColumns;
    }
    toString(): string {
        return ThreeColumnsVariantOption[this._v];
    }
}
