export const offstreetPermit = (
    <g>
        <path d="M32.9,23.3c0.2-0.2,0.4-0.5,0.4-0.9s-0.1-0.7-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.4h-2.1v2.6H32   C32.4,23.7,32.7,23.5,32.9,23.3 M35.7,22.4c0,1.1-0.4,2-1.1,2.7s-1.6,1.1-2.7,1.1h-2v4.6h-2.5V18.6h4.5c1.1,0,2,0.4,2.7,1.1   C35.3,20.4,35.7,21.3,35.7,22.4 M45.7,31.9V17.5c0-0.7-0.2-1.2-0.7-1.7c-0.5-0.4-1-0.7-1.7-0.7H24.1c-0.4,0-0.7,0.1-1,0.2   c2.3,2.5,3.5,5.6,3.6,9.4c-0.1,3.7-1.3,6.9-3.6,9.4c0.3,0.1,0.6,0.2,1,0.2h19.3c0.7,0,1.2-0.3,1.7-0.7   C45.6,33.2,45.7,32.6,45.7,31.9" />
        <path d="M21,25.1c-0.1-0.2-0.3-0.3-0.5-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2c0.4,0.7,0.6,1.6,0.6,2.5   c0,0.9-0.2,1.7-0.6,2.5c-0.1,0.2,0,0.4,0.1,0.5c0.2,0.1,0.4,0,0.5-0.1c0.5-0.9,0.7-1.9,0.7-2.9C21.7,26.9,21.4,25.9,21,25.1" />
        <path d="M22.2,24.3c-0.1-0.2-0.3-0.3-0.5-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2c0.5,1,0.8,2.1,0.8,3.2   c0,1.1-0.3,2.3-0.8,3.2c-0.1,0.2,0,0.4,0.1,0.5c0.2,0.1,0.4,0,0.5-0.1c0.6-1.1,0.9-2.4,0.9-3.6C23.1,26.7,22.8,25.4,22.2,24.3" />
        <path d="M19.3,25.7c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2c0.3,0.5,0.4,1.1,0.4,1.7c0,0.6-0.1,1.2-0.4,1.7   c-0.1,0.2,0,0.4,0.1,0.5c0.2,0.1,0.4,0,0.5-0.1c0.3-0.6,0.5-1.3,0.5-2.1c0-0.7-0.2-1.5-0.5-2.1C19.7,25.7,19.5,25.6,19.3,25.7" />
        <path d="M18.1,26.5c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9   c-0.1,0.2,0,0.4,0.1,0.5s0.4,0,0.5-0.1c0.2-0.4,0.3-0.8,0.3-1.3c0-0.5-0.1-0.9-0.3-1.3C18.5,26.4,18.2,26.4,18.1,26.5" />
        <path d="M7.2,21c-1.6,0.5-2.1,2.4-2.6,3.8c-0.3,0.8-1.8,4.9-2,5.6c-0.2,0.7-0.4,1.2,0,1.4c0,0,2.2-6,2.6-7.1   c0.5-1.3,0.8-2.7,2.2-3.2L7.2,21z" />
        <path d="M18.3,14.6c-0.3-0.7-1-1.1-1.7-0.8l-8.7,3.6c-0.7,0.3-1,1.1-0.7,1.8l1.2,3.4c0.1-0.1,0.3-0.3,0.5-0.4L7.7,19   c-0.2-0.4,0-0.9,0.4-1l8.7-3.6c0.4-0.2,0.8,0,1,0.5l1.9,5.3c0.2,0.4,0,0.9-0.4,1l-4.7,2c0,0.1-0.4,0.6-0.7,0.9l5.6-2.3   c0.6-0.3,1-1.1,0.7-1.8L18.3,14.6z" />
        <path d="M13.2,25.6c-0.1,0.3-1.7,2.5-2.3,2.7c-0.5,0.2-0.9,0-1.2,0.1c0.2-0.6,0.8-1.2,1.2-1.8c0.4-0.6,0.9-1.3,1.2-1.7   C12.6,24.6,13.4,25,13.2,25.6 M13.1,28.1c0,0.1,0.1,0.4-0.2,0.7c-0.5,0.5-1.5,1.4-1.9,1.5c-0.6,0.2-1-0.2-1.5-0.2   c-0.1-0.4,0.1-0.7,0.1-1c0.5-0.1,0.7,0.1,1.1,0c0.6-0.1,1-0.5,1.3-0.9c0.2-0.3,0.4-0.4,0.6-0.5C12.8,27.6,13,27.8,13.1,28.1    M14.5,20.8c-0.2-0.6-0.8-1.1-1.7-0.9C12,20,11.2,21,10.8,21.3c-0.4,0.3-3.2,2.9-3.5,3.2c-0.3,0.2-0.2,0.4,0.1,0.7   c1.4-1.2,3.6-3.3,4-3.7c0.4-0.4,1.1-1.1,1.6-1.2c0.5,0,0.9,0.3,1,0.8c0.2,0.7-0.5,1.3-0.9,1.8c-0.6,0.8-1.1,1.5-1.7,2.2   c-0.7,0.9-1.4,1.8-2,2.5c-0.7,1.2-0.5,2.8-1.2,3.9c-0.2,0.4-0.6,0.7-1,1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0.1-0.3,0.3,0,0.3h0.4   c0.9-0.7,1.5-1.3,1.8-2.4c0.5-0.1,1.2,0.6,1.9,0.3c0.3-0.1,1.7-1.3,2-1.5c0.5-0.5,0.5-1.2,0.2-1.8c-0.1-0.2-0.4-0.4-0.6-0.5   c0.2-0.1,0.5-0.6,0.9-1.1c0.4-0.5,0.2-1.1-0.3-1.5c-0.1-0.1-0.4-0.2-0.4-0.2s0.7-1,1-1.4C14.2,22.4,14.8,21.8,14.5,20.8" />
    </g>
);
export const onstreetPermit = (
    <g>
        <path d="M35.1,22.6c0.2-0.2,0.4-0.5,0.4-0.9s-0.1-0.7-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.4h-2.1V23h2.1   C34.6,23,34.9,22.8,35.1,22.6 M37.9,21.7c0,1.1-0.4,2-1.1,2.7c-0.7,0.7-1.6,1.1-2.7,1.1h-2v4.6h-2.5V17.9h4.5c1.1,0,2,0.4,2.7,1.1   C37.5,19.7,37.9,20.6,37.9,21.7 M46.5,31.2V16.8c0-0.7-0.2-1.2-0.7-1.7c-0.5-0.4-1-0.7-1.7-0.7H24.9c-0.4,0-0.7,0.1-1,0.2   c2.3,2.5,3.5,5.6,3.6,9.4c-0.1,3.7-1.3,6.9-3.6,9.4c0.3,0.1,0.6,0.2,1,0.2h19.3c0.7,0,1.2-0.3,1.7-0.7   C46.4,32.5,46.5,31.9,46.5,31.2" />
        <path d="M22,15.5c-2.3-2.3-5.1-3.4-8.5-3.5c-3.4,0.1-6.3,1.2-8.5,3.5c-2.3,2.3-3.4,5.1-3.5,8.5   c0.1,3.4,1.2,6.3,3.5,8.5c2.3,2.3,5.1,3.4,8.5,3.5c3.4-0.1,6.3-1.2,8.5-3.5c2.3-2.3,3.4-5.1,3.5-8.5C25.4,20.6,24.3,17.7,22,15.5    M11.1,29.4V18.6l7.2,5.4L11.1,29.4z" />
    </g>
);

export const offstreet = (
    <g transform="translate(12, 12)">
        <path d="M12.908.166c.538-.34 1.226-.14 1.538.446 1.834 3.45 2.804 7.392 2.804 11.4 0 3.998-.965 7.93-2.79 11.374-.312.588-1 .788-1.538.45-.537-.34-.723-1.09-.41-1.676 1.627-3.072 2.487-6.58 2.487-10.149 0-3.576-.865-7.092-2.5-10.17a1.303 1.303 0 0 1-.151-.613c0-.423.2-.835.56-1.062zM9.042 2.598c.538-.339 1.226-.14 1.539.446 1.443 2.715 2.206 5.816 2.206 8.968 0 3.141-.758 6.235-2.192 8.942-.31.587-.999.787-1.538.448-.537-.339-.722-1.088-.411-1.676 1.236-2.334 1.89-5.002 1.89-7.714 0-2.721-.657-5.397-1.903-7.737a1.306 1.306 0 0 1-.15-.615c0-.422.2-.834.56-1.062zM5.177 5.031c.538-.34 1.226-.14 1.538.446a13.941 13.941 0 0 1 1.609 6.535c0 2.286-.551 4.538-1.596 6.509-.31.586-.998.787-1.536.45-.54-.34-.722-1.09-.412-1.677a11.316 11.316 0 0 0 1.293-5.282c0-1.866-.451-3.7-1.304-5.304a1.302 1.302 0 0 1-.153-.615c0-.423.201-.834.561-1.062zM1.31 7.466c.538-.34 1.225-.142 1.539.443a8.747 8.747 0 0 1 1.012 4.102c0 1.43-.345 2.839-.999 4.076-.309.586-.997.788-1.537.45-.538-.338-.722-1.088-.413-1.674.457-.866.7-1.85.7-2.852 0-1.007-.247-2-.71-2.868a1.299 1.299 0 0 1-.152-.616c0-.423.2-.833.56-1.061z" />
    </g>
);

export const permitSmall = (
    <path d="M36,31.2V16.8c0-0.7-0.2-1.2-0.7-1.7c-0.5-0.4-1-0.7-1.7-0.7H14.4c-0.7,0-1.2,0.3-1.7,0.7   c-0.5,0.5-0.7,1-0.7,1.7v4.8c0.7,0,1.2,0.3,1.7,0.7s0.7,1,0.7,1.7c0,0.7-0.2,1.2-0.7,1.7s-1,0.7-1.7,0.7v4.8c0,0.7,0.2,1.2,0.7,1.7   s1,0.7,1.7,0.7h19.3c0.7,0,1.2-0.3,1.7-0.7C35.9,32.5,36,31.9,36,31.2 M26,21.7c0,1.1-0.4,2-1.1,2.7c-0.7,0.7-1.6,1.1-2.7,1.1h-2   v4.6h-2.5V17.9h4.5c1.1,0,2,0.4,2.7,1.1C25.6,19.7,26,20.6,26,21.7 M23.2,22.6c0.2-0.2,0.4-0.5,0.4-0.9s-0.1-0.7-0.4-0.9   c-0.2-0.2-0.5-0.4-0.9-0.4h-2.1V23h2.1C22.7,23,23,22.8,23.2,22.6" />
);
export const onstreetSmall = (
    <path d="M28.8,24l-7.2-5.4v10.8L28.8,24z M32.5,15.5c2.3,2.3,3.4,5.1,3.5,8.5c-0.1,3.4-1.2,6.3-3.5,8.5   c-2.3,2.3-5.1,3.4-8.5,3.5c-3.4-0.1-6.3-1.2-8.5-3.5c-2.3-2.3-3.4-5.1-3.5-8.5c0.1-3.4,1.2-6.3,3.5-8.5c2.3-2.3,5.1-3.4,8.5-3.5   C27.4,12.1,30.2,13.2,32.5,15.5" />
);
export const permit = (
    <path d="M40,33.6V14.4c0-0.9-0.3-1.6-0.9-2.3c-0.7-0.5-1.3-0.9-2.3-0.9H11.2c-0.9,0-1.6,0.4-2.3,0.9   C8.3,12.8,8,13.5,8,14.4v6.4c0.9,0,1.6,0.4,2.3,0.9c0.7,0.5,0.9,1.3,0.9,2.3c0,0.9-0.3,1.6-0.9,2.3c-0.7,0.7-1.3,0.9-2.3,0.9v6.4   c0,0.9,0.3,1.6,0.9,2.3c0.7,0.7,1.3,0.9,2.3,0.9h25.7c0.9,0,1.6-0.4,2.3-0.9C39.9,35.3,40,34.5,40,33.6 M26.7,20.9   c0,1.5-0.5,2.7-1.5,3.6c-0.9,0.9-2.1,1.5-3.6,1.5h-2.7v6.1h-3.3V15.9h6c1.5,0,2.7,0.5,3.6,1.5C26.1,18.3,26.7,19.5,26.7,20.9    M22.9,22.1c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.1-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5h-2.8v3.5h2.8C22.3,22.7,22.7,22.4,22.9,22.1" />
);
export const onstreet = (
    <path d="M35.3,12.7c3,3,4.6,6.8,4.7,11.3c-0.1,4.6-1.7,8.3-4.7,11.3c-3,3-6.8,4.6-11.3,4.7c-4.6-0.1-8.3-1.7-11.3-4.7   c-3-3-4.6-6.8-4.7-11.3c0.1-4.6,1.7-8.3,4.7-11.3c3-3,6.8-4.6,11.3-4.7C28.5,8.1,32.3,9.7,35.3,12.7 M30.4,24l-9.6-7.2v14.4   L30.4,24z" />
);

export const lpr = (
    <path d="M36,16l-8,6.36V16H12V32H28V25.59L36,32Zm6.85-6.83A3.8,3.8,0,0,1,44,12V36a3.8,3.8,0,0,1-1.13,2.81A4.17,4.17,0,0,1,40.05,40H7.94a4.17,4.17,0,0,1-2.82-1.22A3.8,3.8,0,0,1,4,36V12A3.8,3.8,0,0,1,5.13,9.22,4.18,4.18,0,0,1,7.94,8H40.06a4.18,4.18,0,0,1,2.81,1.22" />
);

export const rfid = (
    <g>
        <path d="M16.5,27.78h-1v6.66h1a2.23,2.23,0,0,0,1.84-.79A3.56,3.56,0,0,0,19,31.39v-.53a3.62,3.62,0,0,0-.63-2.31A2.22,2.22,0,0,0,16.5,27.78Z" />

        <path d="M12.41,14.92H10.76v3h1.66a1.7,1.7,0,0,0,1.2-.39A1.41,1.41,0,0,0,14,16.43a1.51,1.51,0,0,0-.4-1.11A1.67,1.67,0,0,0,12.41,14.92Z" />

        <path d="M42.87,9.22A4.18,4.18,0,0,0,40.06,8H7.94A4.18,4.18,0,0,0,5.13,9.22,3.8,3.8,0,0,0,4,12V36a3.8,3.8,0,0,0,1.13,2.81A4.17,4.17,0,0,0,7.94,40H40.06a4.17,4.17,0,0,0,2.82-1.22A3.8,3.8,0,0,0,44,36V12A3.8,3.8,0,0,0,42.87,9.22ZM10.76,36.08H8.7v-10h2.06Zm0-16.51v3.65H8.7v-10h3.71a4.19,4.19,0,0,1,2.73.79,2.72,2.72,0,0,1,1,2.23,3.07,3.07,0,0,1-.44,1.7,3,3,0,0,1-1.34,1.09l2.16,4.08v.1H14.27L12.4,19.57Zm10.3,11.76a5.21,5.21,0,0,1-.57,2.47,4.1,4.1,0,0,1-1.61,1.68,4.7,4.7,0,0,1-2.35.6H13.44v-10H16.5a4.66,4.66,0,0,1,2.35.59,4.13,4.13,0,0,1,1.62,1.69,5.2,5.2,0,0,1,.58,2.48Zm3.86-16.41H20.48v2.58h3.95v1.66H20.48v4.08H18.43v-10h6.49ZM28.2,29.17a.9.9,0,0,1-1.27.36,1,1,0,0,1-.34-1.35,4.84,4.84,0,0,0,0-4.59,1,1,0,0,1-.13-.49,1,1,0,0,1,.46-.85.9.9,0,0,1,1.27.36,6.92,6.92,0,0,1,0,6.57Zm3.18,2a.9.9,0,0,1-1.27.36,1,1,0,0,1-.34-1.35,9,9,0,0,0,0-8.5,1,1,0,0,1-.13-.49,1,1,0,0,1,.46-.85.9.9,0,0,1,1.27.36,11.06,11.06,0,0,1,0,10.48Zm1.92,2.31A1,1,0,0,1,33,32.09a13.11,13.11,0,0,0,0-12.41,1,1,0,0,1-.12-.49,1,1,0,0,1,.46-.85.9.9,0,0,1,1.27.36,15.19,15.19,0,0,1,0,14.38A.9.9,0,0,1,33.3,33.44ZM37.75,35a.9.9,0,0,1-1.27.36A1,1,0,0,1,36.15,34a17.23,17.23,0,0,0,0-16.32,1,1,0,0,1-.12-.49,1,1,0,0,1,.46-.85.9.9,0,0,1,1.27.36,19.31,19.31,0,0,1,0,18.29Z" />
    </g>
);

export const parkingLarge = (
    <g
        id="Landingpage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
    >
        <g
            id="iPhoneXS_Landingpage_02"
            transform="translate(-226.000000, -1420.000000)"
            fill="#FFFFFF"
            fillRule="nonzero"
        >
            <g id="Group-17" transform="translate(10.000000, 1400.000000)">
                <path
                    d="M264,44 C263.85,50.9 261.45,56.45 256.95,60.95 C252.45,65.45 246.75,67.85 240,68 C233.1,67.85 227.55,65.45 223.05,60.95 C218.55,56.45 216.15,50.75 216,44 C216.15,37.1 218.55,31.55 223.05,27.05 C227.55,22.55 233.25,20.15 240,20 C246.75,20.15 252.45,22.55 256.95,27.05 C261.45,31.55 263.85,37.25 264,44 Z M240.272727,31.2736166 L256.444048,37.0705895 L258,32.860219 L240.26666,26.5454545 L222.545455,32.860219 L224.101407,37.0705895 L240.272727,31.2736166 Z M247.05963,50.5405082 C248.48825,49.2480564 249.20256,47.5247874 249.20256,45.3707011 C249.20256,43.3602206 248.345388,41.6369515 247.05963,40.3444997 C245.773872,38.9084422 244.059528,38.1904134 241.916598,38.1904134 L233.344878,38.1904134 L233.344878,61.4545455 L238.059324,61.4545455 L238.059324,52.6945945 L241.916598,52.6945945 C244.059528,52.6945945 245.773872,51.83296 247.05963,50.5405082 Z M243.773804,47.0939701 C243.48808,47.5247874 242.916632,47.9556047 242.05946,47.9556047 L238.059324,47.9556047 L238.059324,42.9294033 L242.05946,42.9294033 C242.77377,42.9294033 243.345218,43.2166148 243.773804,43.6474321 C244.345252,44.0782493 244.488114,44.6526723 244.488114,45.3707011 C244.488114,46.0887299 244.20239,46.6631529 243.773804,47.0939701 Z"
                    id="Combined-Shape-Copy-3"
                />
            </g>
        </g>
    </g>
);

export const keyboard = (
    <path d="M40,14.87H35.49v4.51H40Zm0,6.87H35.49v4.51H40Zm-6.87-6.87H28.62v4.51h4.51Zm0,6.87H28.62v4.51h4.51Zm0,9.13H14.87v4.62H33.13Zm-20.62-16H8v4.51h4.51Zm0,6.87H8v4.51h4.51Zm2.36,4.51h4.51V21.74H14.87Zm0-6.87h4.51V14.87H14.87Zm6.87,6.87h4.51V21.74H21.74Zm0-6.87h4.51V14.87H21.74ZM45.58,9.29a4.37,4.37,0,0,1,1.29,3.22v23A4.41,4.41,0,0,1,42.36,40H5.64a4.41,4.41,0,0,1-4.51-4.51v-23A4.41,4.41,0,0,1,5.64,8H42.36a4.37,4.37,0,0,1,3.22,1.29" />
);
