import { AccountSetupAddressTexts } from '../components/AccountSetupAddress';
import Text = require('../../common/i18n/Text');

/* tslint:disable:max-line-length */
export const addressTexts: Text.Translations<AccountSetupAddressTexts> = {
    de: {
        gender: () => 'Anrede',
        genderMaleLabel: () => 'Herr',
        genderFemaleLabel: () => 'Frau',
        firstNameLabel: () => 'Vorname',
        lastNameLabel: () => 'Nachname',
        contactPerson: () => 'Kontaktperson',
        registerPrivateAccountLabel: () => 'Privatkunde',
        registerCompanyAccountLabel: () => 'Firmenkunde',
        registerCompanyAccountWarning: () =>
            'Sie dürfen ein Firmenkonto nur eröffnen, wenn Sie Zeichnungsberechtigte/r der Firma sind.',
        company1Label: () => 'Firmenname',
        company2Label: () => 'Zusatzteile Firma',
        companyUidLabel: () => 'Unternehmens-Identifikationsnummer (UID)',
        streetLabel: () => 'Adresse',
        zipCodeLabel: () => 'PLZ',
        cityLabel: () => 'Ort',
        countryLabel: () => 'Land',
        welcome1: () => 'Das Konto ist erfolgreich bestätigt.',
        welcome2: () => 'Sie können nun Ihr Konto einrichten.',
        logout: () => 'Abmelden',
    },
    fr: {
        gender: () => 'Titre',
        genderMaleLabel: () => 'Monsieur',
        genderFemaleLabel: () => 'Madame',
        firstNameLabel: () => 'Prénom',
        lastNameLabel: () => 'Nom',
        contactPerson: () => 'Personne de contact',
        registerPrivateAccountLabel: () => 'Compte privé',
        registerCompanyAccountLabel: () => 'Compte entreprise',
        registerCompanyAccountWarning: () =>
            'Vous ne pouvez ouvrir un compte entreprise seulement si vous êtes un signataire autorisé de la société.',
        company1Label: () => "Nom de l'entreprise",
        company2Label: () => 'Ligne suppl.',
        companyUidLabel: () => 'Numéro d’identification des entreprises (IDE)',
        streetLabel: () => 'Rue',
        zipCodeLabel: () => 'NPA',
        cityLabel: () => 'Lieu',
        countryLabel: () => 'Pays',
        welcome1: () => 'Le compte a été confirmé avec succès.',
        welcome2: () => 'Vous pouvez maintenant configurer votre compte.',
        logout: () => 'Quitter',
    },
    it: {
        gender: () => 'Titolo',
        genderMaleLabel: () => 'Signor',
        genderFemaleLabel: () => 'Signora',
        firstNameLabel: () => 'Nome',
        lastNameLabel: () => 'Cognome',
        contactPerson: () => 'Persona di contatto',
        registerPrivateAccountLabel: () => 'Conto privato',
        registerCompanyAccountLabel: () => 'Conto aziendale',
        registerCompanyAccountWarning: () =>
            'È consentito aprire un conto aziendale solo se si è un firmatario autorizzato della società.',
        company1Label: () => 'Nome azienda',
        company2Label: () => 'Riga suppl.',
        companyUidLabel: () => 'Numero d’identificazione delle imprese (IDI)',
        streetLabel: () => 'Indirizzo',
        zipCodeLabel: () => 'NAP',
        cityLabel: () => 'Località',
        countryLabel: () => 'Nazione',
        welcome1: () => 'Il conto è stato confermato con successo.',
        welcome2: () => 'Adesso potete configurare il vostro conto.',
        logout: () => 'Esci',
    },
    en: {
        gender: () => 'Gender',
        genderMaleLabel: () => 'Mr',
        genderFemaleLabel: () => 'Ms',
        firstNameLabel: () => 'Firstname',
        lastNameLabel: () => 'Lastname',
        contactPerson: () => 'Contact person',
        registerPrivateAccountLabel: () => 'Private account',
        registerCompanyAccountLabel: () => 'Company account',
        registerCompanyAccountWarning: () =>
            'You may only open a company account if you are an authorized signatory to the company.',
        company1Label: () => 'Company name',
        company2Label: () => 'Att. line',
        companyUidLabel: () => 'Company identification number (UID)',
        streetLabel: () => 'Address',
        zipCodeLabel: () => 'Zip',
        cityLabel: () => 'City',
        countryLabel: () => 'Country',
        welcome1: () => 'The account has been successfully confirmed.',
        welcome2: () => 'You can now configure your account.',
        logout: () => 'Logout',
    },
};
