import { InputContext } from '../InputContext';
import { Typo } from '../../typo';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import React from 'react';

interface Props {
    text: React.ReactChild;
    context: InputContext;
    key?: string;
}

export class InputError extends React.Component<Props> {
    render() {
        const context = this.props.context;
        return (
            <div
                className={css([
                    {
                        ...Typo.caption,
                        height: '20px',
                        fontSize: '12px',
                        paddingBottom: '4px',
                        cursor: 'default',
                    },
                    context === InputContext.regular && {
                        color: Colors.error,
                    },
                    context === InputContext.form && {
                        color: Colors.error,
                    },
                    context === InputContext.inverted && {
                        color: Colors.error_b,
                    },
                ])}
                data-context={InputContext[this.props.context]}
            >
                {this.props.text}
            </div>
        );
    }
}

export var Factory = React.createFactory<Props>(InputError);
