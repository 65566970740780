import * as LayoutState from '../state/LayoutState';
import {
    ThreeColumnsVariant,
    ThreeColumnsVariantOption,
} from './ThreeColumnsVariant';
import { ResponsiveProperties } from './ResponsiveProperties';

interface ThreeColumnsPropertiesInputs {
    layout: LayoutState.State;
}
export class ThreeColumnsProperties {
    private _s: ThreeColumnsPropertiesInputs;
    constructor(s: ThreeColumnsPropertiesInputs) {
        this._s = s;
    }
    get variant(): ThreeColumnsVariant {
        const responsiveProps = new ResponsiveProperties(this._s);
        if (responsiveProps.mobile) {
            return new ThreeColumnsVariant(ThreeColumnsVariantOption.OneColumn);
        } else {
            return new ThreeColumnsVariant(
                ThreeColumnsVariantOption.ThreeColumns,
            );
        }
    }
    get hasColumn1(): boolean {
        return this.variant.oneColumn || this.variant.threeColumns;
    }
    get numColumns(): number {
        if (this.variant.oneColumn) {
            return 1;
        } else {
            return 3;
        }
    }
    get columnSpacing(): number {
        return 32;
    }
}
