import { css } from '@emotion/css';

import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit';
import { Store, useStore } from 'dg-web-shared/lib/Flux';
import { languageFromString, Message } from 'dg-web-shared/lib/Localized';
import { PaymentMethodEnum } from '../../../api/Http';
import { PermitPostData } from '../../../api/PermitHttp';
import {
    FullWidthBottomButton,
    FullWidthBottomButtonColor,
} from '../../../common/components/FullWidthBottomButton';
import { Localized } from '../../../common/components/Localized';
import * as Text from '../../../common/i18n/Text';
import * as AccountBalanceState from '../../../common/state/AccountBalanceState';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as PaymentTypeState from '../../../common/state/PaymentTypeState';
import { Typo } from '../../../style/typo';
import { Colors } from 'dg-web-shared/ui/vars';
import { logAction } from '../../../utils/ActionLog';
import { navigateToPermitDetails } from '../../actions/PermitActions';
import * as PermitTexts from '../../i18n/PermitTexts';
import * as ParkOptionListState from '../../state/ParkOptionListState';
import * as PermitPurchaseState from '../../state/permit/PermitPurchaseState';
import { ErrorBlock } from '../ErrorBlock';
import {
    AddPaymentMethodParkCreateMenu,
    PaymentOriginType,
} from '../add-payment-method/AddPaymentMethodParkCreateMenu';
import {
    ArrowPosition,
    ButtonText,
    InlineErrorBox,
    InlineQuestionBox,
    ModalErrorBox,
    ModalInfoBox,
} from '../../../ui/modals/Confirmable';
import { DirectPayWarningAlertBox } from '../../../account/top-up-balance/e-payment-top-up/DirectPayWarning';
import { SavedCreditCardState } from '../../../account/shared/SavedCreditCardAliasState';
import { paymentCategoryFromAliasPaymentMethod } from '../../../account/top-up-balance/EPaymentMode';
import {
    PaymentCategory,
    PaymentRequestMode,
} from '../../../common/payment/Payment';
import { PermitPriceCalculation } from './PriceCalculations';
import { DateTime } from 'luxon';
import { PermitPaymentRequest } from '../../../account/top-up-balance/e-payment-top-up/PermitPaymentRequest';
import { PaymentStateType } from '../../../account/top-up-balance/e-payment-top-up/PaymentBase';
import { SavedCreditCardAliasResponseTag } from 'dg-web-shared/model/PaymentAlias';
import { useTransactionListRefetch } from '../../../transactions-list/TransactionsListContext';
import { useState } from 'react';

export interface Texts {
    PrintOutRequired: Text.Translation;
    NeedsVignette: Text.Translation;
    RequiresAccount: Text.Translation;
    Close: Text.Translation;
    ToGatePermit: Text.Translation;
    ApplicationNeededBadge: Text.Translation;
    TypeNotAllowed: Text.Translation;
    LpAlreadyRequested: (operator: string) => string;
}

interface Props {
    typeData: PermitPurchaseState.TypeData.Type;
    calc: PermitPriceCalculation;
    fromDate: DateTime | null;
    toDate: DateTime | null;
    durationType: DurationType | null;
    duration: number | null;
    entityIds: number[];
    language: string;
    useReminder: boolean;
    selectedZoneIds: number[];
}

export function closeInfo(store: Store): string {
    PermitPurchaseState.InfoBlock.stateWrite(store, { forcePurchase: true });
    PermitPurchaseState.Purchase.reset(store);
    return 'PermitActions-closeInfo';
}

export function PermitPurchase(p: Props) {
    const { storeState, update } = useStore(store => ({
        purchase: PermitPurchaseState.Purchase.get(store),
        infoBlock: PermitPurchaseState.InfoBlock.get(store),
    }));
    const refetchTransactionList = useTransactionListRefetch();

    if (storeState.purchase.statusCode.cls.error) {
        return (
            <ErrorBlock
                onClose={update.bind(p, closeInfo)}
                apiErrorCode={storeState.purchase.data?.code || null}
            />
        );
    }

    if (p.calc.overlappingPermitId && !storeState.infoBlock.forcePurchase) {
        if (!p.typeData.isOffstreet && p.calc.allowMultiPurchase) {
            return (
                <InlineQuestionBox
                    titleCaption={<Localized {...messages.permitExists} />}
                    confirmCallback={(): void => {
                        update(store =>
                            logAction(
                                store,
                                'force-permit-purchase-button',
                                null,
                            ),
                        );
                        update(closeInfo);
                    }}
                    confirmCaption={ButtonText.CONTINUE}
                    cancelCaption={ButtonText.SHOW}
                    cancelCallback={
                        !p.calc.overlappingIsForeign
                            ? () => {
                                  refetchTransactionList();
                                  update(
                                      navigateToPermitDetails,
                                      p.calc.overlappingPermitId,
                                  );
                              }
                            : undefined
                    }
                    arrowPosition={ArrowPosition.left}
                >
                    <p>
                        {!p.typeData.isOffstreet ? (
                            <Localized
                                de="Die Gültigkeit überschneidet sich mit einer bereits gekauften Bewilligung des selben Typs und für das selbe Kennzeichen. Wählen Sie einen anderen Gültigkeitszeitraum oder ein anderes Kennzeichen aus."
                                fr="La période de validité chevauche une autorisation, du même type et pour la même immatriculation, déjà achetée. Sélectionner une autre période de validité, ou une autre immatriculation."
                                it="La validità scelta si sovrappone a quella di un'autorizzazione, dello stesso tipo e per lo stesso numero di targa, già acquistata in precedenza. Selezionate un altro periodo di validità o un altro numero di targa."
                                en="The validity chosen is superimposed on that of a permit of the same type and for the same license plate, already purchased previously. Select another period of validity or another license plate."
                            />
                        ) : (
                            <Localized
                                de="Sie haben schon eine gültige Bewilligung für diesen Badge."
                                fr="Vous avez déjà une autorisation valable pour ce badge."
                                it="Esiste già un'autorizzazione valida per questo badge."
                                en="There is already a valid permit for this badge."
                            />
                        )}
                    </p>
                </InlineQuestionBox>
            );
        } else {
            return (
                <InlineErrorBox
                    titleCaption={<Localized {...messages.permitExists} />}
                    confirmCaption={ButtonText.SHOW}
                    confirmCallback={
                        !p.calc.overlappingIsForeign
                            ? () => {
                                  refetchTransactionList();
                                  update(
                                      navigateToPermitDetails,
                                      p.calc.overlappingPermitId,
                                  );
                              }
                            : undefined
                    }
                    arrowPosition={ArrowPosition.left}
                >
                    <p>
                        {!p.typeData.isOffstreet ? (
                            <Localized
                                de="Die Gültigkeit überschneidet sich mit einer bereits gekauften Bewilligung des selben Typs und für das selbe Kennzeichen. Wählen Sie einen anderen Gültigkeitszeitraum oder ein anderes Kennzeichen aus."
                                fr="La période de validité chevauche une autorisation, du même type et pour la même immatriculation, déjà achetée. Sélectionner une autre période de validité, ou une autre immatriculation."
                                it="La validità scelta si sovrappone a quella di un'autorizzazione, dello stesso tipo e per lo stesso numero di targa, già acquistata in precedenza. Selezionate un altro periodo di validità o un altro numero di targa."
                                en="The validity chosen is superimposed on that of a permit of the same type and for the same license plate, already purchased previously. Select another period of validity or another license plate."
                            />
                        ) : (
                            <Localized
                                de="Sie haben schon eine gültige Bewilligung für diesen Badge."
                                fr="Vous avez déjà une autorisation valable pour ce badge."
                                it="Esiste già un'autorizzazione valida per questo badge."
                                en="There is already a valid permit for this badge."
                            />
                        )}
                    </p>
                </InlineErrorBox>
            );
        }
    } else {
        return <PurchaseButton {...p} />;
    }
}

export const PurchaseButton = (props: Props) => {
    const { storeState, update } = useStore(store => ({
        purchase: PermitPurchaseState.Purchase.get(store),
        parkOptionSelection: ParkOptionListState.Selection.get(store),
        accountBalance: new AccountBalanceState.StateSlice(store).state,
        paymentType: new PaymentTypeState.StateSlice(store).state,
        currentLogin: CurrentLoginState.get(store),
        ccAliasGetRequest: SavedCreditCardState.get(store),
    }));
    const [displayTopUpMenu, setDisplayTopUpMenu] = useState(false);
    const [displayDirectPaymentWarning, setDisplayDirectPaymentWarning] =
        useState(false);

    const [buyDirectState, buyDirect] = PermitPaymentRequest.use();
    const texts = PermitTexts.permitPurchaseTexts[props.language];

    const loginRole = storeState.currentLogin.data
        ? storeState.currentLogin.data.loginRole
        : CurrentLoginState.LoginRole.RESTRICTED;

    const isDirect =
        storeState.paymentType.data.activePaymentMethod.activePaymentType ===
        PaymentMethodEnum.DIRECT;

    const purchaseRequiresTopUp = permitPurchaseRequiresTopUp(
        props,
        storeState.accountBalance,
        storeState.paymentType,
    );
    const isRestrictedAccount =
        loginRole === CurrentLoginState.LoginRole.RESTRICTED;
    const ableToTopup =
        purchaseRequiresTopUp && (!isRestrictedAccount || isDirect);
    const restrictedLoginInNeedForTopUp = !ableToTopup && purchaseRequiresTopUp;

    const paymentCategory =
        storeState.ccAliasGetRequest.data?.tag ===
        SavedCreditCardAliasResponseTag.FOUND
            ? paymentCategoryFromAliasPaymentMethod(
                  storeState.ccAliasGetRequest.data.creditCardAlias
                      .paymentMethod,
              )
            : PaymentCategory.UNKNOWN;

    const purchasePermit = () => {
        update(store =>
            logAction(store, 'purchase-permit-via-balance', {
                permitTypeId: props.typeData.permitTypeId,
                useReminder: props.useReminder,
                deposit: false,
                isRestrictedAccount,
            }),
        );

        buyDirect({
            permitData: permitPostData(props),
            amount: null,
            mode: PaymentRequestMode.DIRECT_TO_ALIAS,
            language: languageFromString(props.language),
            paymentCategory: PaymentCategory.UNKNOWN,
            useExistingAlias: isDirect,
        });
    };

    if (buyDirectState.state === PaymentStateType.PENDING) {
        return (
            <ModalInfoBox
                confirmCallback={() => null}
                isPending={true}
                confirmDisabled={true}
                titleCaption={
                    <Localized
                        de="Online-Zahlung"
                        fr="Paiement en ligne"
                        it="Pagamento online"
                        en="Online payment"
                    />
                }
            >
                <p>Processing</p>
            </ModalInfoBox>
        );
    } else if (buyDirectState.state === PaymentStateType.GENERIC_ERROR) {
        return (
            <ModalErrorBox confirmCallback={() => null}>
                <p>
                    <Localized
                        de="Online-Zahlung fehlgeschlagen"
                        fr="Paiement en ligne rejetée"
                        it="Pagamento online fallita"
                        en="Online payment failed"
                    />
                </p>
                <p>Code: {buyDirectState.httpStatusCode}</p>
            </ModalErrorBox>
        );
    } else if (buyDirectState.state === PaymentStateType.NEVER_EXECUTED) {
        return (
            <div className={css({ paddingBottom: '20px' })}>
                {props.calc.needsPrint && (
                    <div
                        className={css({
                            color: Colors.action_b,
                            fontSize: '13px',
                            lineHeight: '16px',
                            letterSpacing: '0.015em',
                            ...Typo.robotoRegular,
                        })}
                    >
                        {texts.PrintOutRequired()}
                    </div>
                )}
                {props.calc.transmitsAddressToOperator && (
                    <div
                        className={css({
                            color: Colors.action_b,
                            fontSize: '13px',
                            lineHeight: '16px',
                            letterSpacing: '0.015em',
                            ...Typo.robotoRegular,
                            paddingBottom: '8px',
                            paddingTop: '8px',
                        })}
                    >
                        <TransmitsAddressToOperatorText />
                    </div>
                )}
                {(ableToTopup || !purchaseRequiresTopUp) && (
                    <FullWidthBottomButton
                        color={
                            purchaseRequiresTopUp && !isRestrictedAccount
                                ? FullWidthBottomButtonColor.WHITE
                                : FullWidthBottomButtonColor.GREEN
                        }
                        onClick={() => {
                            if (!purchaseRequiresTopUp || isRestrictedAccount) {
                                if (isDirect) {
                                    setDisplayDirectPaymentWarning(true);
                                } else {
                                    purchasePermit();
                                }
                            } else {
                                update(s =>
                                    logAction(
                                        s,
                                        'show-payment-methods-from-permit',
                                    ),
                                );
                                setDisplayTopUpMenu(true);
                            }
                        }}
                        label={
                            purchaseRequiresTopUp && !isRestrictedAccount
                                ? messages.proceed
                                : messages.buy
                        }
                        disabled={storeState.purchase.pending}
                    />
                )}

                {restrictedLoginInNeedForTopUp && (
                    <InlineErrorBox
                        titleCaption={
                            <Localized {...messages.restrictedLoginTitle} />
                        }
                    >
                        <p>
                            <Localized
                                {...messages.restrictedLoginExplanation}
                            />
                        </p>
                    </InlineErrorBox>
                )}

                {displayDirectPaymentWarning && (
                    <DirectPayWarningAlertBox
                        paymentCategory={paymentCategory}
                        onConfirm={purchasePermit}
                        onCancel={() => setDisplayDirectPaymentWarning(false)}
                    />
                )}

                <AddPaymentMethodParkCreateMenu
                    open={displayTopUpMenu}
                    onClose={() => {
                        setDisplayTopUpMenu(false);
                    }}
                    paymentOriginInfo={{
                        type: PaymentOriginType.PERMIT,
                        permitPriceChf: props.calc.price,
                        currentBalanceChf:
                            storeState.accountBalance.data.balance,
                        permitPostData: permitPostData(props),
                    }}
                />
            </div>
        );
    }
    // on success this slide-in will close
    return <p />;
};

function permitPurchaseRequiresTopUp(
    props: Props,
    accountBalance: AccountBalanceState.State,
    paymentType: PaymentTypeState.State,
): boolean {
    const activePaymentMethod = paymentType.data.activePaymentMethod;
    if (
        activePaymentMethod.activePaymentType === PaymentMethodEnum.LSV ||
        activePaymentMethod.activePaymentType === PaymentMethodEnum.INVOICE ||
        activePaymentMethod.activePaymentType === PaymentMethodEnum.DIRECT
    ) {
        return false;
    }

    return props.calc.price > accountBalance.data.balance;
}

function permitPostData(props: Props): PermitPostData {
    return {
        permitTypeId: props.typeData.permitTypeId,
        from: props.fromDate,
        to: props.toDate,
        time: props.duration,
        durationType: props.durationType,
        useReminder: props.useReminder,
        licensePlateIds: props.typeData.isOffstreet ? [] : props.entityIds,
        badgeIds: props.typeData.isOffstreet ? props.entityIds : [],
        zoneIds: calcZoneIds(props.typeData, props.selectedZoneIds),
    };
}

function calcZoneIds(
    type: PermitPurchaseState.TypeData.Type,
    selectedZoneIds: number[],
): number[] {
    if (type.selectZones === 'FIXED_ZONES') {
        return type.zones.map(
            (z: PermitPurchaseState.TypeData.Zone): number => z.id,
        );
    } else {
        if (!type.isOffstreet) {
            return selectedZoneIds;
        } else {
            return [type.permitTypeId];
        }
    }
}

const messages: Messages = {
    buy: {
        de: 'Kaufen',
        fr: 'Acheter',
        it: 'Acquista',
        en: 'Purchase',
    },
    proceed: {
        de: 'Weiter',
        fr: 'Continuer',
        it: 'Avanti',
        en: 'Proceed',
    },
    restrictedLoginExplanation: {
        de: 'Der Saldo ist derzeit nicht ausreichend, um die gewählte Bewilligung zu bezahlen. Bitte wenden Sie sich an den Administrator Ihres Parkingpay-Kontos.',
        fr: "Le solde du compte est actuellement insuffisant pour payer l'autorisation sélectionnée. Veuillez contacter votre administrateur de compte Parkingpay.",
        it: "Il saldo del conto è al momento insufficiente per poter pagare l'autorizzazione selezionata. Voglia contattare l'amministratore del vostro conto Parkingpay.",
        en: 'The account balance is currently insufficient to pay for the selected authorisation. Please contact your Parkingpay account administrator.',
    },
    restrictedLoginTitle: {
        de: 'Saldo zu tief',
        fr: 'Solde insuffisant',
        it: 'Saldo insufficiente',
        en: 'Balance too low',
    },
    permitExists: {
        de: 'Bewilligung vorhanden',
        fr: 'Autorisation existante',
        it: 'Autorizzazione esistente',
        en: 'Existing permit',
    },
};

interface Messages {
    buy: Message;
    proceed: Message;
    restrictedLoginExplanation: Message;
    restrictedLoginTitle: Message;
    permitExists: Message;
}

export function TransmitsAddressToOperatorText() {
    return (
        <Localized
            de="Ihre Kontodaten (Name/Adresse) werden an den Betreiber weitergegeben."
            fr="Les détails de votre compte (nom/adresse) seront transmis à l'exploitant."
            it="I dati del suo conto (nome/indirizzo) verranno trasmessi al gestore."
            en="Your account details (name/address) will be forwarded to the operator."
        />
    );
}
