import {
    TicketContent,
    TicketContentText,
    TicketSubtitle,
} from '../../common/components/ticketplus/TicketPlusLayout';
import {
    ticketPaymentDisabledModalMessage,
    ticketPaymentDisabledModalTitle,
} from '../../common/components/ticketplus/TicketPlusModalTexts';

export const QuickCheckoutFlowTicketPaymentNotAllowed = () => (
    <TicketContent>
        <TicketSubtitle>{ticketPaymentDisabledModalTitle}</TicketSubtitle>
        <TicketContentText>
            {ticketPaymentDisabledModalMessage}
        </TicketContentText>
    </TicketContent>
);
