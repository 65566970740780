import { DateTime } from 'luxon';
import { ibanToDisplayFormat } from 'dg-web-shared/lib/StringConversions';
import {
    Translation,
    Translation1,
    Translation2,
    Translations,
} from 'dg-web-shared/lib/Text';

export interface PaymentMethodTexts {
    Activate: Translation;
    ApplyForInvoice: Translation;
    Cancel: Translation;
    RequestLSV: Translation;
    activatePrepay: Translation;
    howPrepaidWorks: Translation;
    howToTopUp: Translation;
    pressToActivatePrepaid: Translation;
    activeIban: Translation;
    changeAccount: Translation;
    directDebitAccountChangeRequested: Translation;
    directDebitChangeAccount: Translation;
    directDebitChangeAccountP1: Translation;
    directDebitChangeAccountP2: Translation;
    directDebitChangeAccountP3: Translation;
    directDebitRequestP1: Translation;
    directDebitRequestP2: Translation;
    directDebitRequestP3: Translation;
    directDebitRequested: Translation;
    directDebitReview: Translation;
    directDebitReviewWithIbanChangePendingP1: Translation1<DateTime>;
    directDebitReviewWithIbanChangePendingP2: Translation;
    directDebitReviewWithIbanConfirmation: Translation;
    directDebitSubmittedEarlierP1: Translation2<string, DateTime>;
    directDebitSubmittedEarlierP2: Translation;
    iban: Translation;
    ibanClearingNumberUnknown: Translation;
    ibanFieldIsRequired: Translation;
    ibanMustBeCHorLI: Translation;
    ibanNewValueIsTheSameAsTheOldOne: Translation;
    invoiceConfirmation: Translation;
    invoiceDurationTime: Translation;
    invoiceJustSubmitted: Translation;
    invoiceRequested: Translation;
    invoiceSubmittedEarlier: Translation1<DateTime>;
    newIban: Translation;
}

export const paymentMethodTexts: Translations<PaymentMethodTexts> = {
    de: {
        iban: () => 'IBAN',
        ibanFieldIsRequired: () => 'Die IBAN darf nicht leer sein',
        ibanMustBeCHorLI: () =>
            'Das Konto ist nicht in der Schweiz (CH) oder in Liechtenstein (LI)',
        ibanClearingNumberUnknown: () =>
            'Die IBAN ist ungültig (Clearing-Nummer unbekannt)',
        activatePrepay: () => 'Vorauszahlung',
        howPrepaidWorks: () =>
            `Bei dieser Zahlungsweise müssen Sie zuerst einen positiven Saldo auf Ihrem Konto haben, damit die Parkvorgänge bezahlt werden können. Die Bewilligungen dürfen auch direkt mit einer Online-Zahlung bezahlt werden.`,
        howToTopUp: () =>
            `Ihr Parkingpay-Konto kann unter KONTO > KONTO LADEN mit einer Online-Zahlung (E-Payment), einer Bank-/Postüberweisung oder einem Gutschein geladen werden.`,
        pressToActivatePrepaid: () =>
            `Drücken Sie die Taste AKTIVIEREN, um sofort zu dieser Zahlungsweise zu wechseln.`,
        Cancel: () => 'Abbrechen',
        Activate: () => 'Aktivieren',
        ApplyForInvoice: () => 'Auf Rechnung',
        invoiceRequested: () => 'Auf Rechnung',
        invoiceJustSubmitted: () =>
            'Wir haben Ihren Antrag zur Aktivierung erhalten.',
        invoiceSubmittedEarlier: paymentMethodRequestTimestamp =>
            `Ihr Antrag zur Aktivierung vom ${paymentMethodRequestTimestamp.toLocaleString()} ist noch pendent.`,
        invoiceDurationTime: () =>
            'Die Prüfung kann bis 3 Arbeitstage dauern. In der Zwischenzeit bleibt die Zahlungsweise Vorauszahlung aktiv.',
        invoiceConfirmation: () =>
            'Bei der Aktivierung erhalten Sie eine Bestätigung per E-Mail.',
        RequestLSV: () => 'Lastschrift (LSV)',
        directDebitRequestP1: () =>
            'Bei dieser Zahlungsweise werden die Parkgebühren während des ganzen Monats auf dem Parkingpay-Konto verrechnet; zu Beginn des Folgemonats wird der fällige Gesamtbetrag automatisch Ihrem Bankkonto belastet (nur Schweiz oder Liechtenstein).',
        directDebitRequestP2: () =>
            'Die Aktivierung erfolgt erst, nachdem wir von Ihrer Bank eine Bestätigung erhalten haben; diese verlangen, dass die unterschriebene Zahlungsermächtigung per Post versandt wird.',
        directDebitRequestP3: () =>
            'Erfassen Sie Ihre IBAN und drücken Sie die Taste BEANTRAGEN, um das zu unterzeichnende Dokument zu erhalten.',
        directDebitRequested: () => 'Lastschrift (LSV)',
        directDebitSubmittedEarlierP1: (iban, paymentMethodRequestTimestamp) =>
            `Ihr Antrag zur Aktivierung der Lastschrift beim Konto ${ibanToDisplayFormat(
                iban,
            )} vom ${paymentMethodRequestTimestamp.toLocaleString()} ist noch pendent. Haben Sie die Zahlungsermächtigung per Post versandt?`,
        directDebitSubmittedEarlierP2: () =>
            'Falls ja, dann müssen Sie noch ein bisschen Geduld haben. Der Prozess kann 7 bis 10 Arbeitstage dauern.',
        directDebitReview: () =>
            'Bei dieser Zahlungsweise werden die Parkgebühren während des ganzen Monats auf dem Parkingpay-Konto verrechnet; zu Beginn des Folgemonats wird der fällige Gesamtbetrag automatisch dem untenstehenden Konto belastet.',
        changeAccount: () => 'Konto ändern',
        activeIban: () => 'Konto (IBAN)',
        directDebitChangeAccount: () => 'Neues Konto für LSV',
        directDebitChangeAccountP1: () =>
            'Das neues Bankkonto wird erst aktiv sein, nachdem wir von Ihrer Bank eine Bestätigung erhalten haben; diese verlangen, dass die neue unterschriebene Zahlungsermächtigung per Post versandt wird.',
        directDebitChangeAccountP2: () =>
            'In der Zwischenzeit werden allfälligen Belastungen auf das bisherige Bankkonto gebucht. Falls dieses Konto nicht mehr aktiv ist, wechseln Sie zuerst die Zahlungsweise auf Vorauszahlung, bevor Sie das neue beantragen.',
        directDebitChangeAccountP3: () =>
            'Erfassen Sie das neue Bankkonto und drücken Sie die Taste BEANTRAGEN, um das zu unterzeichnende Dokument zu erhalten.',
        newIban: () => 'Neues Konto (IBAN)',
        ibanNewValueIsTheSameAsTheOldOne: () =>
            'Das ist das aktuell konfigurierten Konto. Bitte geben Sie einen anderen Wert an.',
        directDebitReviewWithIbanChangePendingP1: ibanChangeRequestTimestamp =>
            `Ihr Antrag zur Änderung des Bankkontos vom ${ibanChangeRequestTimestamp.toLocaleString()} ist noch pendent.`,
        directDebitReviewWithIbanChangePendingP2: () =>
            'Haben Sie die neue Zahlungsermächtigung per Post gesandt?',
        directDebitReviewWithIbanConfirmation: () =>
            'Bei der Aktivierung des neues Bankkontos erhalten Sie eine Bestätigung per E-Mail.',
        directDebitAccountChangeRequested: () => 'Lastschrift (LSV)',
    },
    fr: {
        iban: () => 'IBAN',
        ibanFieldIsRequired: () => "L'IBAN ne peut pas être vide",
        ibanMustBeCHorLI: () =>
            'Le compte doit être en Suisse (CH) ou au Liechtenstein (LI)',
        ibanClearingNumberUnknown: () =>
            "L'IBAN est invalide (numéro de clearing inconnu)",
        activatePrepay: () => 'Prépaiement',
        howPrepaidWorks: () =>
            `Avec ce mode de paiement, vous devez d’abord avoir un solde positif sur votre compte pour pouvoir payer le stationnement. Les autorisations peuvent être également payés directement avec un paiement en ligne.`,
        howToTopUp: () =>
            `Votre compte Parkingpay peut être alimenté sous COMPTE > ALIMENTER LE COMPTE avec un paiement en ligne, un virement bancaire/postal ou un voucher.`,
        pressToActivatePrepaid: () =>
            `Appuyez sur le bouton ACTIVER pour passer immédiatement à ce mode de paiement.`,
        Cancel: () => 'Annuler',
        Activate: () => 'Activer',
        ApplyForInvoice: () => 'Sur facture',
        invoiceRequested: () => 'Sur facture',
        invoiceJustSubmitted: () =>
            "Nous avons reçu votre demande d'activation.",
        invoiceSubmittedEarlier: paymentMethodRequestTimestamp =>
            `Votre demande d'activation du ${paymentMethodRequestTimestamp.toLocaleString()} est toujours en attente.`,
        invoiceDurationTime: () =>
            "Le contrôle peut prendre jusqu'à 3 jours ouvrables. En attendant, le mode prépaiement reste actif.",
        invoiceConfirmation: () =>
            "Dès l'activation vous recevrez une confirmation par e-mail.",
        RequestLSV: () => 'Prélèv. autom. (LSV)',
        directDebitRequestP1: () =>
            'Avec ce mode de paiement, pendant tout le mois, les taxes de stationnement sont comptabilisés sur le compte Parkingpay. Au début du mois suivant, le montant total dû sera débité sur votre compte bancaire (seulement en Suisse ou au Liechtenstein).',
        directDebitRequestP2: () =>
            "L'activation n'aura lieu qu'après réception de la confirmation de votre banque, qui exigent que l'autorisation de paiement signée soit envoyée par la poste.",
        directDebitRequestP3: () =>
            'Insérez votre numéro IBAN et appuyez sur le bouton DEMANDER pour recevoir le document à signer.',
        directDebitRequested: () => 'Prélèv. autom. (LSV)',
        directDebitSubmittedEarlierP1: (iban, paymentMethodRequestTimestamp) =>
            `Votre demande d'activation pour prélèvement automatique sur le compte ${ibanToDisplayFormat(
                iban,
            )} du ${paymentMethodRequestTimestamp.toLocaleString()} est toujours en attente. Avez-vous envoyé l'autorisation de paiement par la poste?`,
        directDebitSubmittedEarlierP2: () =>
            'Si oui, alors vous devez être patient. Le processus peut prendre 7 à 10 jours ouvrables.',
        directDebitReview: () =>
            'Avec ce mode de paiement, pendant tout le mois, les taxes de stationnement sont comptabilisés sur le compte Parkingpay; au début du mois suivant le montant total dû sera débité sur le compte bancaire ci-dessous.',
        changeAccount: () => 'Modifier le compte',
        activeIban: () => 'Compte (IBAN)',
        directDebitChangeAccount: () => 'Changer le compte pour LSV',
        directDebitChangeAccountP1: () =>
            "Le nouveaux compte bancaire sera actif seulement après réception de la confirmation de votre banque, qui exigent que l'autorisation de paiement signée soit envoyée par la poste.",
        directDebitChangeAccountP2: () =>
            'Entretemps tous les débis seront imputés au compte bancaire acutel. Si ce compte n\'existe plus, veuillez changer le mode de paiement en "Prépaiement" avant de faire la demande pour le nouveau compte.',
        directDebitChangeAccountP3: () =>
            'Insérez le nouveaux compte bancaire et appuyez sur le bouton DEMANDER pour recevoir le document à signer.',
        newIban: () => 'Nouveaux compte (IBAN)',
        ibanNewValueIsTheSameAsTheOldOne: () =>
            'Ceci est le compte actuellement configuré. Nous vous prions de saisir un compte différent.',
        directDebitReviewWithIbanChangePendingP1: ibanChangeRequestTimestamp =>
            `Votre demande du ${ibanChangeRequestTimestamp.toLocaleString()} pour la modification du compte bancaire est toujours en attente.`,
        directDebitReviewWithIbanChangePendingP2: () =>
            "Avez-vous envoyé l'autorisation de paiement par la poste?",
        directDebitReviewWithIbanConfirmation: () =>
            "Dès l'activation du nouveaux compte bancaire, vous recevrez une confirmation par e-mail.",
        directDebitAccountChangeRequested: () => 'Prélèv. autom. (LSV)',
    },
    it: {
        iban: () => 'IBAN',
        ibanFieldIsRequired: () => "L'IBAN non può essere vuoto",
        ibanMustBeCHorLI: () =>
            "Il conto dev'essere in Svizzera (CH) o nel Liechtenstein (LI)",
        ibanClearingNumberUnknown: () =>
            "L'IBAN non è valido (numero di clearing sconosciuto)",
        activatePrepay: () => 'Prepagata',
        howPrepaidWorks: () =>
            `Con questa modalità di pagamento, per poter pagare le soste orarie, dovete prima avere un saldo positivo sul vostro conto. Le autorizzazioni possono anche essere acquistate direttamente con un pagamento online.`,
        howToTopUp: () =>
            `Il vostro conto Parkingpay può essere caricato alla voce CONTO > RICARICARE IL CONTO con un pagamento online, un bonifico bancario/postale o un buono.`,
        pressToActivatePrepaid: () =>
            `Premete il tasto ATTIVA per passare subito a questa modalità di pagamento.`,
        Cancel: () => 'Annulla',
        Activate: () => 'Attiva',
        ApplyForInvoice: () => 'Contro fattura',
        invoiceRequested: () => 'Contro fattura',
        invoiceJustSubmitted: () =>
            "Abbiamo ricevuto la vostra richiesta per l'attivazione.",
        invoiceSubmittedEarlier: paymentMethodRequestTimestamp =>
            `La vostra richiesta del ${paymentMethodRequestTimestamp.toLocaleString()} per l'attivazione è ancora in sospeso.`,
        invoiceDurationTime: () =>
            'Per la verifica possono essere necessari fino a 3 giorni lavorativi. Nel frattempo rimane attiva la modalità prepagata.',
        invoiceConfirmation: () =>
            "Al momento dell'attivazione riceverete una conferma per e-mail.",
        RequestLSV: () => 'Addebito diretto (LSV)',
        directDebitRequestP1: () =>
            "Con questa modalità di pagamento, durante tutto il mese, le tasse di parcheggio vengono conteggiate sul conto Parkingpay; all'inizio del mese seguente l'importo totale dovuto viene addebitato sul vostro conto bancario (solo Svizzera o Liechtenstein).",
        directDebitRequestP2: () =>
            "L'attivazione avverrà solamente dopo che avremo ricevuto la conferma da parte della vostra banca, le quali richiedono l'invio per posta dell'autorizzazione di pagamento firmata.",
        directDebitRequestP3: () =>
            'Inserite il vostro IBAN e premete il tasto RICHIEDI per ricevere il documento da firmare.',
        directDebitRequested: () => 'Addebito diretto (LSV)',
        directDebitSubmittedEarlierP1: (iban, paymentMethodRequestTimestamp) =>
            `La vostra richiesta del ${paymentMethodRequestTimestamp.toLocaleString()} per l'attivazione dell'addebito diretto sul conto ${ibanToDisplayFormat(
                iban,
            )} è ancora in sospeso. Avete inviato per posta l'autorizzazione di pagamento?`,
        directDebitSubmittedEarlierP2: () =>
            "Se sì, allora dovete avere ancora un po' di pazienza. Il processo può richiedere da 7 a 10 giorni lavorati.",
        directDebitReview: () =>
            "Con questa modalità di pagamento, durante tutto il mese, le tasse di parcheggio vengono conteggiate sul conto Parkingpay; all'inizio del mese seguente l'importo totale dovuto viene addebitato sul conto bancario indicato qui sotto.",
        changeAccount: () => 'Modifica conto',
        activeIban: () => 'Conto (IBAN)',
        directDebitChangeAccount: () => 'Cambia conto per LSV',
        directDebitChangeAccountP1: () =>
            "Il nuovo conto bancario sarà attivo solamente dopo che avremo ricevuto la conferma da parte della vostra banca, le quali richiedono l'invio per posta dell'autorizzazione di pagamento firmata.",
        directDebitChangeAccountP2: () =>
            'Nel frattempo, eventuali addebiti verranno effettuati sul conto bancario attuale. Se questo conto non esiste più, vi preghiamo di cambiare la modalità di pagamento su "Prepagata", prima di effettuare la richiesta per il nuovo conto.',
        directDebitChangeAccountP3: () =>
            'Inserite il nuovo conto bancario e premete il tasto RICHIEDI per ricevere il documento da firmare.',
        newIban: () => 'Nuovo conto (IBAN)',
        ibanNewValueIsTheSameAsTheOldOne: () =>
            "Questo è il conto attualmente configurato. Vi preghiamo d'inserire un altro conto.",
        directDebitReviewWithIbanChangePendingP1: ibanChangeRequestTimestamp =>
            `La vostra richiesta del ${ibanChangeRequestTimestamp.toLocaleString()} per la modifica del conto bancario è ancora in sospeso.`,
        directDebitReviewWithIbanChangePendingP2: () =>
            "Avete inviato per posta l'autorizzazione di pagamento?",
        directDebitReviewWithIbanConfirmation: () =>
            "Al momento dell'attivazione del nuovo conto bancario riceverete una conferma per e-mail.",
        directDebitAccountChangeRequested: () => 'Addebito diretto (LSV)',
    },
    en: {
        iban: () => 'IBAN',
        ibanFieldIsRequired: () => 'IBAN cannot be empty',
        ibanMustBeCHorLI: () =>
            'Account must be in Switzerland (CH) or Liechtenstein (LI).',
        ibanClearingNumberUnknown: () =>
            'The IBAN is not valid (clearing number unknown)',
        activatePrepay: () => 'Prepaid',
        howPrepaidWorks: () =>
            `With this payment method, you must first have a positive balance on your account in order to be able to pay for hourly parking. Permits can also be purchased directly with an online payment.`,
        howToTopUp: () =>
            `Your Parkingpay account can be topped up under ACCOUNT > TOP UP with an online payment, a bank/postal transfer or a voucher.`,
        pressToActivatePrepaid: () =>
            `Press the ACTIVE button to switch to this payment method immediately.`,
        Cancel: () => 'Cancel',
        Activate: () => 'Activate',
        ApplyForInvoice: () => 'Invoice',
        invoiceRequested: () => 'Invoice',
        invoiceJustSubmitted: () =>
            'We have received your request for activation.',
        invoiceSubmittedEarlier: paymentMethodRequestTimestamp =>
            `Your request of ${paymentMethodRequestTimestamp.toLocaleString()} for activation is still pending.`,
        invoiceDurationTime: () =>
            'Verification may take up to 3 working days. In the meantime, the prepaid mode remains active.',
        invoiceConfirmation: () =>
            'At the time of activation you will receive a confirmation by e-mail.',
        RequestLSV: () => 'Direct debit (LSV)',
        directDebitRequestP1: () =>
            'With this payment method, parking fees are charged to your Parkingpay account throughout the month; at the beginning of the following month, the total amount due is debited to your bank account (Switzerland or Liechtenstein only).',
        directDebitRequestP2: () =>
            'Activation will only take place after we have received confirmation from your bank; they require the signed payment authorization to be sent by post.',
        directDebitRequestP3: () =>
            'Enter your IBAN and press the REQUEST button to receive the document to be signed.',
        directDebitRequested: () => 'Direct debit (LSV)',
        directDebitSubmittedEarlierP1: (iban, paymentMethodRequestTimestamp) =>
            `Your request of ${paymentMethodRequestTimestamp.toLocaleString()} for activation of direct debit to the account ${ibanToDisplayFormat(
                iban,
            )} is still pending. Did you send the payment authorization by post?`,
        directDebitSubmittedEarlierP2: () =>
            'If so, then you must have some more patience. The process can take 7 to 10 days.',
        directDebitReview: () =>
            'With this method of payment, during the whole month, parking fees are charged to the Parkingpay account; at the beginning of the following month the total amount due is debited to the bank account indicated below.',
        changeAccount: () => 'Change account',
        activeIban: () => 'Account (IBAN)',
        directDebitChangeAccount: () => 'Modify LSV account',
        directDebitChangeAccountP1: () =>
            'The new bank account will only be active once we have received confirmation from your bank; they require the signed payment authorisation to be sent by post.',
        directDebitChangeAccountP2: () =>
            'In the meantime, any debits will be made to the current bank account. If this account no longer exists, please change the payment method to «Prepaid» before applying for the new account.',
        directDebitChangeAccountP3: () =>
            'Enter your new bank account and press the REQUEST button to receive the document to be signed.',
        newIban: () => 'New account (IBAN)',
        ibanNewValueIsTheSameAsTheOldOne: () =>
            'This is the account currently configured. Please enter another account.',
        directDebitReviewWithIbanChangePendingP1: ibanChangeRequestTimestamp =>
            `Your request of ${ibanChangeRequestTimestamp.toLocaleString()} for the modification of the account is still pending.`,
        directDebitReviewWithIbanChangePendingP2: () =>
            'Did you send the payment authorization by post?',
        directDebitReviewWithIbanConfirmation: () =>
            'When we activate your new bank account, you will receive a confirmation by e-mail.',
        directDebitAccountChangeRequested: () => 'Direct debit (LSV)',
    },
};
